import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction } from "@reduxjs/toolkit";
import { Contact } from "interfaces/actions/IContact";
import { RootState } from "shared/store";
import contactSlice from "shared/store/contactSlice";

export const contactAction = contactSlice.actions;

export const fetchContact =
  (item: Contact): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const contactMapped = [...getState().contact.contacts, item];
    dispatch(contactAction.addContact(contactMapped));
    dispatch(contactAction.addSelectedContact(item));
  };

export const fetchSelectedContact =
  (item: Contact): ThunkAction<void, RootState, unknown, AnyAction> =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (dispatch, getState) => {
    dispatch(contactAction.addSelectedContact(item));
  };

export const fetchEditContact =
  (item: Contact): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const contactList = getState().contact.contacts;
    const contactMapped: Contact[] = [];
    contactList.filter((list: Contact) => {
      if (item.address === list.address) {
        contactMapped.push(item);
      } else {
        contactMapped.push(list);
      }
    });
    dispatch(contactAction.addContact(contactMapped));
  };
