import { ShareStore } from "@tkey/common-types";
import CustomAuth, { AggregateLoginParams, SubVerifierDetails } from "@toruslabs/customauth";
import MetadataStorageLayer, { getDeviceShare, getTorusShare, setDeviceShare } from "@toruslabs/metadata-helpers";
import { ExtraLoginOptions } from "@toruslabs/openlogin-utils";
import { privateToAddress } from "ethereumjs-util";
import log from "loglevel";
import configs from "shared/config";

import config from "./openloginconfig";
import { generateCustomAuthParams } from "./openloginutils";
import { TKEY_REQUIRE_MORE_INPUT } from "./openloginenums";
import { CustomAuthResult, KeyMode, LoginType, TouchIDPreferences } from "./openlogininterface";
import { getJoinedKey } from "./openloginutils";

import { doneAndRedirect, setUserInfoToStore, storeUserInfoToBackend } from "./openloginauthutil";
import { store } from "shared/store";
import { login, olTkeyModuleAction, setDeviceWebAuthnRegistered } from "shared/actions/olTkeyModuleAction";
import { addLoginRecord } from "shared/actions/olLoginPerfModuleAction";
import { olDeviceModuleAction, setTouchIDPreference } from "shared/actions/olDeviceModuleAction";
import { olAllDappModuleAction } from "shared/actions/olAllDappModuleAction";
import { olDappModuleAction } from "shared/actions/olDappModuleAction";

export default async function webAuthnLogin(
  customAuthResult: CustomAuthResult
): Promise<{ canContinue: boolean; displayError?: string; tKey?: string; oAuthPrivateKey?: string; walletKey?: string }> {
  const metadataStorage = new MetadataStorageLayer(config.metadataHost, store.getState().olUserModule.clientTimeOffset);
  // This is the private key from Torus nodes for webauthn verifier
  const { privateKey, userInfo, pubKey } = customAuthResult;
  const { ref, state, extraVerifierParams } = userInfo;
  const { oAuthAggregateVerifier, oAuthVerifierId, extraLoginOptions: internalLoginOptions = "{}" } = state;
  const extraLoginOptions = JSON.parse(internalLoginOptions);

  // Should never happen
  if (!ref) return { canContinue: false, displayError: `No ref found for webAuthnLogin` };
  const currentRefHex = Buffer.from(ref, "base64").toString("hex");
  const [torusShare, deviceShareStore] = await Promise.all([
    getTorusShare<{ privKey: string; walletKey?: string; nonce?: string; keyMode?: string }>(
      metadataStorage,
      privateKey,
      currentRefHex,
      getJoinedKey(oAuthAggregateVerifier, oAuthVerifierId)
    ),
    getDeviceShare<{ shareStore: string }>(metadataStorage, currentRefHex, getJoinedKey(oAuthAggregateVerifier, oAuthVerifierId))
  ]);
  const currentDappModule: any = store.getState().olAllDappModule.dappModules[store.getState().olUserModule.currentDappClientId];
  if (deviceShareStore?.shareStore && torusShare) {
    const walletKey = torusShare?.walletKey;
    try {
      store.dispatch(olTkeyModuleAction.updateState({ keyMode: (torusShare?.keyMode as KeyMode) || "v1" } as any));
      const parsedDeviceShareStore = ShareStore.fromJSON(JSON.parse(deviceShareStore.shareStore));
      const res = await setUserInfoToStore(customAuthResult, torusShare.privKey, torusShare.nonce || "", state);
      await storeUserInfoToBackend(res);
      const keys = await login({
        postboxKey: torusShare.privKey,
        shareStores: [parsedDeviceShareStore],
        deviceShareStore: parsedDeviceShareStore,
        dappShare: currentDappModule.dappShare
      });

      if (extraVerifierParams?.credId)
        store.dispatch(
          olDeviceModuleAction.addCredId({
            verifier: store.getState().olUserModule.userInfo.aggregateVerifier,
            verifierId: store.getState().olUserModule.userInfo.verifierId,
            credId: extraVerifierParams?.credId,
            confirmed: true
          })
        );

      const finalDeviceShareStore = store.getState().olTKeyModule.settingsPageData.deviceShare.share;
      if (finalDeviceShareStore && finalDeviceShareStore.polynomialID !== parsedDeviceShareStore.polynomialID) {
        await Promise.all([
          setDeviceShare(
            metadataStorage,
            currentRefHex,
            getJoinedKey(store.getState().olUserModule.userInfo.aggregateVerifier, store.getState().olUserModule.userInfo.verifierId),
            {
              shareStore: JSON.stringify(finalDeviceShareStore)
            }
          ),
          // Update WebAuthn device metadata
          setDeviceWebAuthnRegistered({ shareIndex: finalDeviceShareStore.share.shareIndex.toString("hex") })
        ]);
      } else {
        await setDeviceWebAuthnRegistered({ shareIndex: parsedDeviceShareStore.share.shareIndex.toString("hex") });
      }

      store.dispatch(
        olDeviceModuleAction.setTouchIDOnboarding({
          verifier: store.getState().olUserModule.userInfo.aggregateVerifier,
          verifierId: store.getState().olUserModule.userInfo.verifierId,
          onboarding: true
        })
      );

      const currentLoginConfig = currentDappModule.loginConfig[currentDappModule.currentLoginProvider];
      if (currentLoginConfig.walletVerifier) {
        store.dispatch(
          olDeviceModuleAction.setWalletTKeyOnboarding({
            verifier: currentLoginConfig.walletVerifier,
            verifierId: store.getState().olUserModule.userInfo.verifierId,
            onboarding: true
          })
        );
      }

      await setTouchIDPreference({
        verifier: store.getState().olUserModule.userInfo.aggregateVerifier,
        verifierId: store.getState().olUserModule.userInfo.verifierId,
        preference: TouchIDPreferences.ENABLED
      });

      store.dispatch(olDeviceModuleAction.setTouchIDRegistered());
      store.dispatch(olDappModuleAction.setTouchIDPreference(TouchIDPreferences.ENABLED));
      store.dispatch(
        olAllDappModuleAction.setTouchIDPreference({
          clientId: store.getState().olUserModule.currentDappClientId,
          touchIDPreference: TouchIDPreferences.ENABLED
        })
      );
      const customAuth = new CustomAuth({
        baseUrl: window.location.origin,
        redirectPathName: "auth",
        uxMode: "redirect",
        network: config.torusNetwork,
        enableLogging: config.logLevel !== "error",
        locationReplaceOnRedirect: true,
        // v2
        metadataUrl: config.metadataHost,
        enableOneKey: true,
        // networkUrl: config.networkUrl,
        storageServerUrl: config.storageServerUrl,
        web3AuthClientId: store.getState().olUserModule.currentDappClientId || (config.openloginDappModuleKey as string)
      });
      if (currentDappModule.getWalletKey && !torusShare.walletKey) {
        // send to OAuth login
        const { popupWindow, pid } = state;
        const localConfig = currentDappModule.loginConfig[currentDappModule.currentLoginProvider];
        const customAuthArgs = generateCustomAuthParams(
          {
            ...localConfig,
            jwtParameters: {
              ...localConfig.jwtParameters,
              ...extraLoginOptions
            }
          },
          {
            client: currentDappModule.clientId,
            currentLoginProvider: currentDappModule.currentLoginProvider,
            popupWindow: popupWindow || "",
            pid: pid || "",
            ref: currentRefHex,
            webAuthnPubKeyX: pubKey?.pub_key_X || "",
            webAuthnPubKeyY: pubKey?.pub_key_Y || "",
            whiteLabel: JSON.stringify(currentDappModule.whiteLabel),
            keyMode: store.getState().olTKeyModule.keyMode,
            isCustomVerifier: currentDappModule.isCustomVerifier.toString()
          }
        );

        await customAuth.init({ skipInit: true });
        if (localConfig.verifierSubIdentifier) {
          await customAuth.triggerAggregateLogin(customAuthArgs as AggregateLoginParams);
        } else {
          await customAuth.triggerLogin(customAuthArgs as SubVerifierDetails);
        }
        return { canContinue: false };
      }
      await addLoginRecord({
        walletAddress: torusShare.walletKey
          ? privateToAddress(Buffer.from(torusShare.walletKey.padStart(64, "0"), "hex")).toString("hex")
          : undefined,
        verifier: store.getState().olUserModule.userInfo.aggregateVerifier,
        verifierId: store.getState().olUserModule.userInfo.verifierId,
        loginRoute: "auth",
        clientId: state.client,
        dappUrl: currentDappModule.redirectUrl,
        shareIndex: parsedDeviceShareStore.share.shareIndex.toString("hex"),
        webauthnEnabled: true,
        isWebauthnLogin: true,
        isLoginCompleted: true,
        loginType: store.getState().olTKeyModule.keyMode as LoginType,
        mobileOrigin: currentDappModule.mobileOrigin,
        sessionId: currentDappModule.sessionId
      });
      return {
        canContinue: true,
        tKey: keys[0].privKey,
        oAuthPrivateKey: torusShare.privKey,
        walletKey: store.getState().olTKeyModule.keyMode === "v1" ? torusShare.walletKey : keys[0].privKey
      };
    } catch (error: unknown) {
      // if device share is deleted
      if (error instanceof Error && error.message?.includes(TKEY_REQUIRE_MORE_INPUT)) {
        log.warn(error);

        await addLoginRecord({
          walletAddress: walletKey ? privateToAddress(Buffer.from(walletKey.padStart(64, "0"), "hex")).toString("hex") : undefined,
          verifier: store.getState().olUserModule.userInfo.aggregateVerifier,
          verifierId: store.getState().olUserModule.userInfo.verifierId,
          loginRoute: "auth",
          clientId: state.client,
          dappUrl: currentDappModule.redirectUrl,
          loginType: store.getState().olTKeyModule.keyMode as LoginType,
          mobileOrigin: currentDappModule.mobileOrigin,
          sessionId: currentDappModule.sessionId
        });
        doneAndRedirect("TKeyInput", {
          popupWindow: state.popupWindow || "",
          disableAlwaysSkip: !!state.disableAlwaysSkip,
          pid: state.pid,
          extraLoginOptions: state.extraLoginOptions || ""
        });
        return { canContinue: false };
      }
      log.error(error, "fast login failed");
      return { canContinue: false, displayError: "fast login failed" };
    }
  } else {
    // This should never occur. this is unhandled fast login
    log.warn("unhandled fast login");
    // This disables fast login for the current dapp
    currentDappModule.updateState({ touchIDPreference: TouchIDPreferences.DISABLED });
    const localConfig = configs.loginConfig[currentDappModule.currentLoginProvider];
    // This block disables touch id for the current verifier + verifier id combo
    let lastVerifierId = store.getState().olDeviceModule.verifierToLastLoggedInVerifierID[localConfig.verifier];
    store.dispatch(olDeviceModuleAction.setLastLoggedInVerifier("" as any));
    store.dispatch(olDeviceModuleAction.setLastLoggedIn({ verifier: localConfig.verifier, verifierId: "" }));
    if ((extraLoginOptions as ExtraLoginOptions)?.login_hint) {
      lastVerifierId = (extraLoginOptions as ExtraLoginOptions)?.login_hint || lastVerifierId;
      if (lastVerifierId)
        store.dispatch(
          olDeviceModuleAction.setTouchIDPreferenceState({
            verifier: localConfig.verifier,
            verifierId: lastVerifierId,
            preference: TouchIDPreferences.DISABLED
          })
        );
    }
    // send to OAuth login
    const { popupWindow, pid } = state;
    const customAuthArgs = generateCustomAuthParams(
      {
        ...localConfig,
        jwtParameters: {
          ...localConfig.jwtParameters,
          ...extraLoginOptions
        }
      },
      {
        client: currentDappModule.clientId,
        currentLoginProvider: currentDappModule.currentLoginProvider,
        popupWindow: popupWindow || "",
        pid: pid || "",
        ref: currentRefHex,
        webAuthnPubKeyX: pubKey?.pub_key_X || "",
        webAuthnPubKeyY: pubKey?.pub_key_Y || "",
        whiteLabel: JSON.stringify(currentDappModule.whiteLabel),
        keyMode: store.getState().olTKeyModule.keyMode,
        isCustomVerifier: currentDappModule.isCustomVerifier.toString()
      }
    );

    const customAuth = new CustomAuth({
      baseUrl: window.location.origin,
      redirectPathName: "auth",
      uxMode: "redirect",
      network: config.torusNetwork,
      enableLogging: config.logLevel !== "error",
      locationReplaceOnRedirect: true,
      // v2
      metadataUrl: config.metadataHost,
      enableOneKey: true,
      // networkUrl: config.networkUrl,
      storageServerUrl: config.storageServerUrl,
      web3AuthClientId: store.getState().olUserModule.currentDappClientId || (config.openloginDappModuleKey as string)
    });

    await customAuth.init({ skipInit: true });
    if (localConfig.verifierSubIdentifier) {
      await customAuth.triggerAggregateLogin(customAuthArgs as AggregateLoginParams);
    } else {
      await customAuth.triggerLogin(customAuthArgs as SubVerifierDetails);
    }
    return { canContinue: false };
  }
}
