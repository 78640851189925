import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Point } from "@tkey/common-types";
import { TKey } from "@tkey/core";
import { KeyDetails, SettingsPageData, TkeyModule } from "interfaces/actions/IOITKeyModule";
import { KeyMode, PendingShareTransferRequest } from "shared/utils/openlogininterface";

interface ITkeyModuleState {
  tKey: TKey;
  postboxKey: string;
  settingsPageData: SettingsPageData;
  parsedShareDescriptions: unknown[];
  keyDetails: KeyDetails;
  requestStatusCheckId?: number;
  error?: string;
  shareTransferRequests?: PendingShareTransferRequest[];
  success?: string;
  currentEncPubKeyX?: string;
  initialized: boolean;
  tKeyPrivKey: string;
  keyMode: KeyMode;
}

interface IAdditionalSecurity {
  recoveryEmail: string;
}

const initTkeyState: TkeyModule = {
  creationFactor: "",
  initialized: false,
  keyDetails: {
    pubKey: "",
    requiredShares: 0,
    shareDescriptions: {},
    threshold: 0,
    totalShares: 0
  },
  parsedShareDescriptions: [],
  postboxKey: "",
  requestStatusCheckId: 0,
  settingsPageData: {
    allDeviceShares: [],
    deviceShare: {
      available: false,
      share: ""
    },
    exportedEmailShares: [],
    otherShares: [],
    passwordShare: {
      available: false
    },
    threshold: "",
    webauthnDeviceShares: []
  },
  tKey: new TKey({ manualSync: true }),
  tKeyPrivKey: "",
  additionalSecurity: "",
  keyMode: "1/1",
  currentEncPubKeyX: "",
  error: "",
  shareTransferRequests: [],
  success: ""
};
const tKeySlice = createSlice({
  name: "olTkeyModule",
  initialState: initTkeyState,
  reducers: {
    updateState(state, action: PayloadAction<Partial<ITkeyModuleState>>) {
      const {
        tKey,
        settingsPageData,
        parsedShareDescriptions,
        keyDetails,
        requestStatusCheckId,
        error,
        shareTransferRequests,
        success,
        postboxKey,
        currentEncPubKeyX,
        initialized,
        tKeyPrivKey,
        keyMode
      } = action.payload;
      if (tKey) {
        if (JSON.stringify(tKey).length > 7000000) tKey["_localMetadataTransitions"] = [[], []]; // Avoiding error because of more than 10MB
      }
      if (postboxKey) state.postboxKey = postboxKey;
      if (tKey) state.tKey = tKey;
      if (settingsPageData) state.settingsPageData = { ...settingsPageData };
      if (parsedShareDescriptions) state.parsedShareDescriptions = [...parsedShareDescriptions];
      if (keyDetails) state.keyDetails = { ...keyDetails };
      if (requestStatusCheckId) state.requestStatusCheckId = requestStatusCheckId;
      if (error) state.error = error;
      if (shareTransferRequests) state.shareTransferRequests = [...shareTransferRequests];
      if (success) state.success = success;
      if (currentEncPubKeyX) state.currentEncPubKeyX = currentEncPubKeyX;
      if (initialized !== undefined) state.initialized = initialized;
      if (keyMode !== undefined) state.keyMode = keyMode;
      if (tKeyPrivKey) state.tKeyPrivKey = tKeyPrivKey;
      if (keyMode) state.keyMode = keyMode;
    },
    setAdditionalSecurity(state, action: PayloadAction<IAdditionalSecurity | null>) {
      state.additionalSecurity = action.payload;
    },
    setCreationFactor(state, action: PayloadAction<{ factor: string }>) {
      state.creationFactor = action.payload.factor;
    },
    logout(state): void {
      if (state.requestStatusCheckId) clearInterval(state.requestStatusCheckId);
      state.requestStatusCheckId = 0;
      state.tKey = new TKey({ manualSync: true });
      state.postboxKey = "";
      state.settingsPageData = {
        deviceShare: { available: false },
        allDeviceShares: [],
        webauthnDeviceShares: {},
        exportedEmailShares: {},
        otherShares: [],
        passwordShare: { available: false },
        threshold: ""
      };
      state.initialized = false;
      state.tKeyPrivKey = "";
      state.parsedShareDescriptions = [];
      state.creationFactor = "";
      state.keyDetails = {
        pubKey: new Point("0", "0"),
        requiredShares: 0,
        threshold: 0,
        totalShares: 0,
        shareDescriptions: {}
      };
    }
  }
});

export default tKeySlice;
