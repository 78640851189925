// @ts-nocheck
import { IonAvatar, IonText, IonButton, IonIcon } from "@ionic/react";
// import { HeaderHistory } from "components/account";
// import AccountHistoryContent from "components/account/AccountHistoryContent";
import ProfilePicture from "features/account/components/ProfilePicture";
import { UpbondUserInfo } from "shared/store/walletSlice";
import { copyToClipboard } from "shared/utils/coreUtils";
import { useTranslation } from "react-i18next";
import { addOutline, copyOutline, qrCodeOutline } from "ionicons/icons";
import { TransactionItems } from "interfaces/actions/IHistoryTransaction";
import { MoralisTransactionResult } from "interfaces/actions/moralis/IMoralisHistoryTransaction";
import { IAstarUseState, IMoralisUseState } from "interfaces/libs/IAccountHistory";

type Props = {
  user: UpbondUserInfo;
  account: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  astarItems: IAstarUseState;
  moralisItems: IMoralisUseState;
  handleModal: (data: TransactionItems, isSeeDetail: boolean) => void;
  handleModalMoralis: (data: MoralisTransactionResult, isSeeDetail: boolean) => void;
  isAstar: boolean;
  setOpenModalHistory: (open: boolean) => void;
  setOpenModalPurchase: (open: boolean) => void;
  setOpenModalQRCode: (open: boolean) => void;
};

const HistoryContentMb = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center">
      <IonAvatar class="min-w-[60px] min-h-[60px] self-center">
        <ProfilePicture userData={props.user} />
      </IonAvatar>
      <IonText color={"dark"} className="font-bold text-center">
        <h2 className="text-2xl py-2">
          <strong>{props.user?.name}</strong>
        </h2>
        <div className="account-address">{props.account}</div>
      </IonText>
      <div className="flex justify-center">
        <IonButton
          shape="round"
          className="copy-setting font-bold cursor-pointer flex items-center text-sm"
          onClick={() => {
            copyToClipboard(props.account);
            props.setOpen(!props.open);
          }}>
          <IonIcon slot="start" icon={copyOutline} />
          {t("account.copy")}
        </IonButton>
        {props.open && (
          <div className="tooltip-set" style={{ left: localStorage.getItem("lang") === "en" ? -100 : -70 }}>
            <div className="bottom" style={{ minWidth: localStorage.getItem("lang") === "en" ? 128 : 210 }}>
              {t("account.copiedToClipboard")}
              <i></i>
            </div>
          </div>
        )}
        <IonButton
          shape="round"
          className="copy-setting font-bold cursor-pointer flex items-center text-sm"
          onClick={() => props.setOpenModalQRCode(true)}
          style={{ marginLeft: 12 }}>
          <IonIcon slot="start" icon={qrCodeOutline} />
          {t("account.receive")}
        </IonButton>
      </div>

      <div className="flex justify-center">
        <IonButton
          shape="round"
          color="primary"
          className="btn-cash w-[122px] font-bold h-[36px] p-0 m-auto mt-[11px] mb-2 flex items-center text-sm"
          onClick={() => props.setOpenModalPurchase(true)}>
          <IonIcon slot="start" icon={addOutline} />
          {t("account.add")}
        </IonButton>
      </div>
      {/* <IonList lines="full" className="list">
        <HeaderHistory />
        <AccountHistoryContent
          astarItems={props.astarItems}
          handleModalAstar={props.handleModal}
          handleModalMoralis={props.handleModalMoralis}
          isAstar={props.isAstar}
          moralisItems={props.moralisItems}
          setOpenModalHistory={props.setOpenModalHistory}
        />
      </IonList> */}
    </div>
  );
};

export default HistoryContentMb;
