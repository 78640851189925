/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useIonRouter } from "@ionic/react";
import ModalHistory from "features/account/components/ModalHistory";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import { TransactionItems } from "interfaces/actions/IHistoryTransaction";
import { IChains, IToken } from "interfaces/actions/IToken";
import { MoralisTransactionResult } from "interfaces/actions/moralis/IMoralisHistoryTransaction";
import { IAstarUseState, IMoralisUseState } from "interfaces/libs/IAccountHistory";
import { useEffect, useState } from "react";
import { fetchHistory, historytransactionAction } from "shared/actions/historyTransactionAction";
import { uniqueArray } from "shared/utils/coreUtils";
import { numberParse } from "shared/utils/etherUtils";
import { defaultLogo } from "helpers/httpHelpers";
import { ethers } from "ethers";
import useProvider from "hooks/useProvider";
import { listChain } from "shared/config/chains";
import ERC20ABI from "abis/erc20.json";
import DetailNetworkMainMb from "features/detailNetwork/components/DetailNetworkMain/mb";
import DetailNetworkMainPc from "features/detailNetwork/components/DetailNetworkMain/pc";
import { getBestRpc } from "shared/utils/filterRpc";

const DetailNetworkPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { breakpoint } = useTheme();
  const history = useIonRouter();

  const currentchain = localStorage.getItem("chain");
  const stateBalance = useAppSelector((state) => state.balance);
  const { history: stateHistory, isHistoryLoading, doneCalculate } = useAppSelector((state) => state.history);
  const { userInfo: _user, selectedAddress: account } = useAppSelector((state) => state.wallet);
  const historyChain = useAppSelector((state) => state.historyChain.historyChain);

  const [openModalHistory, setOpenModalHistory] = useState<boolean>(false);
  const [openExplorer, setOpenExplorer] = useState<boolean>(false);

  const [_isSeeDetail, setIsSeeDetail] = useState<boolean>(false);
  const [isAstar, setIsAstar] = useState<boolean>(false);
  const [astarItems, setAstarItems] = useState<IAstarUseState>({
    haveMore: false,
    items: [] as TransactionItems[],
    allItems: [] as TransactionItems[]
  });
  const [moralisItems, setMoralisItems] = useState<IMoralisUseState>({
    haveMore: false,
    items: [],
    allItems: []
  });
  const [latest10, setLatest10] = useState<MoralisTransactionResult[]>([]);
  const [haveMore, setHaveMore] = useState<boolean>(false);
  const [modalHistoryLoading, setModalHistoryLoading] = useState<boolean>(false);
  const [detailsLoading, setDetailsLoading] = useState<boolean>(true);

  const [ether, setEther] = useState<any>(null);
  const [counter, setCounter] = useState(0);

  const { provider } = useProvider();

  useEffect(() => {
    if (account && stateBalance && currentchain) {
      account && stateBalance && dispatch(fetchHistory(account, JSON.parse(currentchain)));
    }
  }, [currentchain]);

  /* call the `filteredHistory` function when either the `isHistoryLoading` or `doneCalculate`
  state variables change. This is likely used to update the component's display based on
  changes to the history data or calculation results. */
  useEffect(() => {
    const getHistory = async () => {
      setDetailsLoading(true);
      await filteredHistoryLatest10();
      await filteredHistory();
    };
    getHistory();
  }, [currentchain, isHistoryLoading, doneCalculate]);

  useEffect(() => {
    if (provider) {
      listChain.forEach((chain) => {
        if (historyChain.chainName === chain.chainName) {
          setBestProvider(chain);
        }
      });
    }
  }, [provider, historyChain]);

  const setBestProvider = async (chain: IChains) => {
    const bestRpc = await getBestRpc(chain.chain, chain.rpcUrl);
    setEther(new ethers.providers.JsonRpcProvider(bestRpc));
  };

  const accountLowerCase = account ? account.toLowerCase() : "Loading ...";

  const filteredHistory = async () => {
    setCounter(counter + 1);
    // setModalHistoryLoading(true);
    const history = uniqueArray(stateHistory);
    const byChainId =
      history && history.length > 0 ? history.find((groupHistory: any) => groupHistory.chain_id === `${historyChain.chain}`) : [];
    /* checking if a certain chain ID is equal to "592". If it is, it sets some state
    variables related to Astar transactions and dispatches an action to remove loading. If it is
    not, it sets some state variables related to Moralis transactions and dispatches an action to
    remove loading. The state variables include whether there are more transactions to load, a
    subset of the transactions to display, and all of the transactions. */
    if (byChainId?.chain_id === "592") {
      let haveMore = false;
      let items: TransactionItems[] = [];
      let allItems: TransactionItems[] = [];
      if (byChainId && byChainId?.items && byChainId?.items?.length > 0) {
        haveMore = byChainId?.items?.length > 10;
        items = byChainId?.items?.slice(0, 10);
        allItems = byChainId.items;
      }
      setAstarItems({
        haveMore,
        allItems,
        items
      });
      setIsAstar(true);
      if (doneCalculate) {
        setTimeout(() => {
          dispatch(historytransactionAction.setRemoveLoading());
        }, 500);
      }
      setModalHistoryLoading(false);
    } else {
      let haveMore = false;
      let items: MoralisTransactionResult[] = [];
      let allItems: MoralisTransactionResult[] = [];
      if (byChainId && byChainId?.items && byChainId?.items?.length > 0) {
        haveMore = byChainId?.items?.length > 10;
        items = byChainId?.items?.slice(0, 10);
        allItems = byChainId?.items;
      }
      for (const [idx, ele] of allItems.entries()) {
        try {
          if (ele.input && ele.input !== "0x" && !Number(ele.value)) {
            const res = await ether.getTransactionReceipt(ele.hash);
            if (res) {
              for (const log of res.logs) {
                if (log.topics[0] === "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef") {
                  const abiInter = new ethers.utils.Interface(ERC20ABI.abi);
                  const result = abiInter.decodeEventLog("Transfer", log.data, log.topics);
                  if (result) {
                    ele.to_address = result[1];
                    ele.value = result[2];
                  }
                }
              }
            } else {
              console.log("Transaction receipt not found.");
            }
          }
        } catch (err) {
          console.log(err);
        }
        if (idx % 10 === 0) setMoralisItems({ items, haveMore, allItems });
      }
      setMoralisItems({
        items,
        haveMore,
        allItems
      });
      setIsAstar(false);
      if (doneCalculate) {
        setTimeout(() => {
          dispatch(historytransactionAction.setRemoveLoading());
        }, 500);
      }
      setModalHistoryLoading(false);
    }
  };

  const filteredHistoryLatest10 = async () => {
    const history = uniqueArray(stateHistory);
    const byChainId =
      history && history.length > 0 ? history.find((groupHistory: any) => groupHistory.chain_id === `${historyChain.chain}`) : [];
    /* checking if a certain chain ID is equal to "592". If it is, it sets some state
    variables related to Astar transactions and dispatches an action to remove loading. If it is
    not, it sets some state variables related to Moralis transactions and dispatches an action to
    remove loading. The state variables include whether there are more transactions to load, a
    subset of the transactions to display, and all of the transactions. */
    if (byChainId?.chain_id === "592") {
      let haveMore = false;
      let items: TransactionItems[] = [];
      let allItems: TransactionItems[] = [];
      if (byChainId && byChainId?.items && byChainId?.items?.length > 0) {
        haveMore = byChainId?.items?.length > 10;
        items = byChainId?.items?.slice(0, 10);
        allItems = byChainId.items;
      }
      setAstarItems({
        haveMore,
        allItems,
        items
      });
      setIsAstar(true);
      if (doneCalculate) {
        setTimeout(() => {
          setDetailsLoading(false);
          dispatch(historytransactionAction.setRemoveLoading());
        }, 500);
      }
    } else {
      let haveMore = false;
      let items: MoralisTransactionResult[] = [];
      // const allItems: MoralisTransactionResult[] = [];
      if (byChainId && byChainId?.items && byChainId?.items?.length > 0) {
        haveMore = byChainId?.items?.length > 10;
        items = byChainId?.items?.slice(0, 10);
        // allItems = byChainId?.items;
      }
      setHaveMore(haveMore);
      for (const [_, ele] of items.entries()) {
        try {
          if (ele.input !== "0x") {
            const res = await ether.getTransactionReceipt(ele.hash);
            if (res) {
              for (const log of res.logs) {
                if (log.topics[0] === "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef") {
                  const abiInter = new ethers.utils.Interface(ERC20ABI.abi);
                  const result = abiInter.decodeEventLog("Transfer", log.data, log.topics);
                  if (result) {
                    ele.to_address = result[1];
                    ele.value = result[2];
                  }
                }
              }
            } else {
              console.log("Transaction receipt not found.");
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
      setLatest10(items);
      setIsAstar(false);
      if (doneCalculate) {
        setTimeout(() => {
          setDetailsLoading(false);
          dispatch(historytransactionAction.setRemoveLoading());
        }, 500);
      }
    }
  };

  const getNext10 = async (idx: number, ev: any) => {
    setLatest10(moralisItems.allItems.slice(0, idx + 10));
    setTimeout(() => ev.target.complete(), 500);
  };

  const handleModal = (data: TransactionItems, isSeeDetail: boolean) => {
    setOpenExplorer(!openExplorer);
    setIsSeeDetail(isSeeDetail);
  };

  const handleModalMoralis = (data: MoralisTransactionResult, isSeeDetail: boolean) => {
    setOpenExplorer(!openExplorer);
    setIsSeeDetail(isSeeDetail);
  };

  const ViewBalance = () => {
    const typenet = localStorage.getItem("chain");
    if (typenet) {
      const chaindata = JSON.parse(typenet);
      if (chaindata.type === "cryptocurrency") {
        return stateBalance?.token?.map((el: IToken) => {
          if (el.chain_id === historyChain?.chain && el.contract_address === historyChain.address) {
            return `${numberParse(el.balance, el.contract_decimals)} ${el.contract_ticker_symbol}`;
          }
        });
      } else {
        return stateBalance?.coin?.map((el: IToken) => {
          if (el.chain_id === historyChain?.chain) {
            return `${numberParse(el.balance, el.contract_decimals)} ${el.contract_ticker_symbol}`;
          }
        });
      }
    } else {
      return "";
    }
  };

  return (
    <>
      {breakpoint < 1024 ? (
        <DetailNetworkMainMb
          historyChain={historyChain}
          defaultLogo={defaultLogo}
          ViewBalance={ViewBalance}
          history={history}
          astarItems={astarItems}
          handleModal={handleModal}
          handleModalMoralis={handleModalMoralis}
          isAstar={isAstar}
          latest10={latest10}
          haveMore={haveMore}
          setOpenModalHistory={setOpenModalHistory}
          detailsLoading={detailsLoading}
        />
      ) : (
        <DetailNetworkMainPc />
      )}

      {/* Modal All History */}
      <ModalHistory
        popUpSliderLoading={modalHistoryLoading}
        isOpen={openModalHistory}
        closeModal={() => setOpenModalHistory(false)}
        moralisHistoryData={latest10}
        account={accountLowerCase}
        handlePresentMoralis={handleModalMoralis}
        handlePresent={handleModal}
        historyData={astarItems.allItems}
        isAstarNetwork={isAstar}
        getNext10={getNext10}
      />
    </>
  );
};

export default DetailNetworkPage;
