// @ts-nocheck
import { getPlatforms, IonButton, IonList, IonModal } from "@ionic/react";
import { getSdkError, parseUri } from "@walletconnect/utils";
import ModalWalletConnect from "components/walletConnect/ModalWalletConnect";
import useNetwork from "hooks/useNetwork";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
// import useWeb3 from "hooks/useWeb3";
import { IChains } from "interfaces/actions/IToken";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { walletConnectAction } from "shared/actions/walletConnectAction";
import { listChain } from "shared/config/chains";
import { rejectEIP155Request } from "shared/utils/walletconnect/EIP155RequestHandlerUtil";
import { createLegacySignClient, deleteCachedLegacySession, legacySignClient } from "shared/utils/walletconnect/LegactWalletConnectUtil";
import { web3wallet } from "shared/utils/walletconnect/WalletConnectUtil";
import DisconnectModal from "features/application/components/disconnectModal";
import Scanner from "features/application/components/scanner";
import ApplicationMainMb from "features/application/components/ApplicationMain/mb";
import ApplicationMainPc from "features/application/components/ApplicationMain/pc";
// import PendingComponent from "features/home/components/PendingComponent";

const ApplicationPage = () => {
  const dispatch = useAppDispatch();
  const walletConnect = useAppSelector((state) => state.walletConnect);
  const wallet = useAppSelector((state) => state.wallet);

  const [openDisconnectModal, setOpenDisconnectModal] = useState(false);
  const [isDisconnect, setIsDisconnect] = useState(false);
  const [errorConnect, setErrorConnect] = useState(false);
  const [openScan, setOpenScan] = useState(false);
  const [uri, setUri] = useState("");
  const [platforms, setPlatforms] = useState("web");
  const [modalStatus, setModalStatus] = useState(false);
  const [initialChain, setInitialChain] = useState<IChains>();

  const { t } = useTranslation();
  const { breakpoint } = useTheme();
  const { changeNetwork } = useNetwork();

  const modal = useRef<HTMLIonModalElement>(null);
  // const account = useWeb3().accounts[0];
  const customAlertOptions = {
    translucent: true,
    header: t("settings.selectNetwork"),
    cssClass: "network-alert",
    buttons: [t("settings.save")],
    mode: "ios",
    animated: true
  };

  const openQrScanner = () => {
    const platform = getPlatforms();
    if (platform[0] === "tablet" || platform[0] === "desktop") {
      setPlatforms("web");
    } else {
      setPlatforms("mobile");
    }
    setOpenScan(true);
  };

  async function onConnect(uri: string) {
    try {
      const { version } = parseUri(uri);
      // Route the provided URI to the v1 SignClient if URI version indicates it, else use v2.
      if (version === 1) {
        setModalStatus(true);
        createLegacySignClient(dispatch, { uri });
      } else if (!isNaN(version)) {
        setModalStatus(true);
        await web3wallet.pair({ uri });
      }
    } catch (err: any) {
      dispatch(walletConnectAction.setConnect(false));
      setErrorConnect(true);
      setUri("");
    } finally {
      setUri("");
    }
  }

  const handleDisconect = () => {
    setOpenDisconnectModal(false);
    setUri("");
  };

  // walletConnect
  useEffect(() => {
    const localStorageUri = localStorage.getItem("walletconnect");
    if (localStorageUri) {
      onConnect(localStorageUri);
      dispatch(walletConnectAction.setConnect(true));
    }
  }, []);

  const requestSession = walletConnect.data?.legacyRequestSession;
  const getCurrentProvider = async (chainLocalStorage: any) => {
    // const currentProvider = await networkController.getCurrentChainId(); //please don't remove
    if (chainLocalStorage) {
      listChain.filter((data) => data.chain == chainLocalStorage && setInitialChain(data));
    }
  };

  useEffect(() => {
    const getLocal = localStorage.getItem("walletconnect") !== null && JSON.parse(localStorage.getItem("walletconnect") || "");
    getLocal && getCurrentProvider(getLocal.chainId);
  }, [requestSession, walletConnect.open]);

  const changeChainId = async (event: any) => {
    // DON't DISTURB THIS CODE !!!
    if (walletConnect.connected === true) {
      /**
       *
       * Tell to another team if you change this row
       */
      await changeNetwork(event.target.value);
      legacySignClient.updateSession({
        accounts: [wallet.selectedAddress],
        chainId: event.target.value
      });
    } else if (walletConnect.connected === false) {
      await changeNetwork(event.target.value);
    }
  };

  const disconnectFromDaaps = async () => {
    dispatch(walletConnectAction.setConnect(false));

    if (web3wallet) {
      Object.values(web3wallet.getActiveSessions()).map(async (item: any) => {
        await Promise.all(
          Object.values(web3wallet?.getPendingSessionRequests()).map(async (pending: any) => {
            if (item.topic == pending.topic) {
              const response = rejectEIP155Request(pending);
              await web3wallet.respondSessionRequest({
                topic: pending.topic,
                response
              });
            }
          })
        );
        web3wallet.disconnectSession({ topic: item.topic, reason: getSdkError("USER_DISCONNECTED") });
      });

      Object.values(web3wallet.core.pairing.getPairings()).map((item: any) => {
        web3wallet.disconnectSession({ topic: item.topic, reason: getSdkError("USER_DISCONNECTED") });
      });
    }
    legacySignClient?.killSession();
    deleteCachedLegacySession();

    dispatch(walletConnectAction.setOpen({ open: false, view: {} as any, data: {}, connected: false }));
  };

  return (
    <>
      <Scanner isScanContact={false} setScanResult={""} platform={platforms} openScanner={openScan} closeScanner={setOpenScan} />

      {/* Connect Session Modal */}
      <IonModal backdropDismiss={false} id="modal-wrapper-wallet-connect" ref={modal} trigger="open-modal" isOpen={modalStatus}>
        <div className="wrapper">
          <IonList lines="none">
            <ModalWalletConnect openModalWalletConnect={modalStatus} closeModalWalletConnect={setModalStatus} />
          </IonList>
        </div>
      </IonModal>

      <DisconnectModal
        openModal={openDisconnectModal}
        closeModal={handleDisconect}
        disconnectFromDaaps={disconnectFromDaaps}
        disconnected={isDisconnect}
        closeAfterDisconnect={setIsDisconnect}
      />

      {/* Error Connect Modal */}
      <IonModal className="disconnect-modal text-center" isOpen={errorConnect} trigger="open-modal">
        <div className="font-roboto">
          <h1 className="text-base font-semibold my-4">{t("settings.connectionFailed")}</h1>
          <p className="font-normal text-sm leading-4 my-4">{t("settings.networkError")}.</p>
          <IonList className="disconnect-modal-button-list" lines="none">
            <IonButton
              expand="block"
              className="disconnect-close font-medium text-[15px] leading-[18px] text-[#4d4082] m-auto flex mb-5 w-[98%]"
              onClick={() => setErrorConnect(false)}>
              {t("settings.close")}
            </IonButton>
          </IonList>
        </div>
      </IonModal>

      {/* Application Page */}
      {breakpoint < 1024 ? (
        <ApplicationMainMb
          listChain={listChain}
          legacySignClient={legacySignClient}
          customAlertOptions={customAlertOptions}
          changeChainId={changeChainId}
          initialChain={initialChain}
          uri={uri}
          setUri={setUri}
          onConnect={onConnect}
          setOpenDisconnectModal={setOpenDisconnectModal}
          openQrScanner={openQrScanner}
        />
      ) : (
        <ApplicationMainPc
          listChain={listChain}
          legacySignClient={legacySignClient}
          customAlertOptions={customAlertOptions}
          changeChainId={changeChainId}
          initialChain={initialChain}
          uri={uri}
          setUri={setUri}
          onConnect={onConnect}
          setOpenDisconnectModal={setOpenDisconnectModal}
          openQrScanner={openQrScanner}
        />
      )}
    </>
  );
};

export default ApplicationPage;
