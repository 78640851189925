import { AnyAction, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import format from "date-fns/format";
import { IAstarItems, IHistoryGroup, IHistoryTransactionArray } from "interfaces/actions/IHistoryTransaction";
import { IChains } from "interfaces/actions/IToken";
import { MoralisTransactionResult } from "interfaces/actions/moralis/IMoralisHistoryTransaction";
import historyServices from "services/HistoryTransactionServices";
import moralisServices from "services/MoralisServices";
import { RootState } from "shared/store";
import balanceSlice from "shared/store/balanceSlice";
import historyTransactionSlice from "shared/store/historyTrasanctionSlice";
import { numberParse } from "shared/utils/etherUtils";
export const balanceAction = balanceSlice.actions;
export const historytransactionAction = historyTransactionSlice.actions;
export const fetchHistory =
  (walletAddress: string, chainData: IChains): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(historytransactionAction.reset());
    getAllTransactionHistory(dispatch, getState, chainData, walletAddress);
  };

export const getAllTransactionHistory = async (
  dispatch: ThunkDispatch<{ history: IHistoryTransactionArray }, unknown, AnyAction>,
  getState: () => { history: IHistoryTransactionArray },
  chains: IChains,
  walletAddress: string
) => {
  const nativeTicker: { [key: string]: string } = {
    amoy: "TMATIC",
    polygon: "MATIC",
    eth: "ETH",
    goerli: "ETH",
    sepolia: "ETH"
  };
  try {
    if (chains.chain.toString() === "592") {
      let response = await historyServices.getTransactionHistory(walletAddress);
      response = response?.sort((a, b) => {
        if (a.block_timestamp > b.block_timestamp) {
          return -1;
        } else if (a.block_timestamp < b.block_timestamp) {
          return 1;
        } else {
          return 0;
        }
      });
      let mapHistory: IAstarItems[] = [];

      if (response) {
        mapHistory = response?.map((item: IAstarItems) => {
          if (!item?.contract_decimal) item.contract_decimal = 18;
          item.value =
            item.typeTx === "native"
              ? `${numberParse(item?.value, 18, 3)}`
              : `${numberParse(item?.transferErc20Value ? item?.transferErc20Value : "0", parseInt(item?.contract_decimal?.toString()))}`;
          item.logo_url = chains?.customLogo;
          item.chain_id = chains?.chain;
          item.custom_name = item?.typeTx !== "Erc20" ? chains?.ticker : item?.custom_name;
          item.contract_name = item?.typeTx !== "Erc20" ? chains?.customName : item?.contract_name;
          item.explorer = chains?.explore;
          item.block_timestamp =
            item?.block_timestamp < 253402300799 && item?.block_timestamp > 0
              ? format(new Date(item?.block_timestamp * 1000), "yyyy/MM/dd kk:mm:ss")
              : item.block_timestamp;
          return item;
        });
      }

      /* sorting the `mapHistory` array in descending order based on the `block_signed_at`
      property of each object. The `Promise.all` is used to wait for the sorting to complete before
      moving on to the next step. */
      await Promise.all(
        mapHistory.sort((x, y) => {
          return new Date(y?.block_timestamp).getTime() - new Date(x?.block_timestamp).getTime();
        })
      );

      const transactionHistoryMapped = await Promise.all([
        ...getState().history.history,
        { chain_id: chains?.chain, items: mapHistory }
      ] as IHistoryGroup[]);
      if (transactionHistoryMapped[0]?.items?.length > 0) {
        const getLastArr = transactionHistoryMapped[0]?.items?.slice(-1);
        Object.freeze(transactionHistoryMapped); /** fix error for cannot modify read only object property */
        Object.assign(getLastArr[0], { last: true });
        dispatch(historytransactionAction.setHistory(transactionHistoryMapped));
      } else {
        dispatch(historytransactionAction.setHistory(transactionHistoryMapped));
      }
    } else {
      const newChains = { ...chains, ticker: nativeTicker[chains?.chainName] };
      const moralisRes = await moralisServices.getTransactionHistory(newChains, walletAddress);

      let mapHistory: MoralisTransactionResult[] = [];
      if (moralisRes) {
        mapHistory = moralisRes?.data?.map((item: MoralisTransactionResult) => {
          item.chain_id = chains?.chain;
          item.contract_name = chains?.customName;
          item.explorer = chains?.explore;
          item.block_timestamp = format(new Date(item?.block_timestamp), "yyyy/MM/dd kk:mm:ss");
          return item;
        });
      }
      await Promise.all(
        mapHistory.sort((x, y) => {
          return new Date(y?.block_timestamp).getTime() - new Date(x?.block_timestamp).getTime();
        })
      );

      const transactionHistoryMapped = await Promise.all([
        ...getState().history.history,
        { chain_id: chains?.chain, items: mapHistory }
      ] as IHistoryGroup[]);

      transactionHistoryMapped && dispatch(historytransactionAction.setHistory(transactionHistoryMapped));
    }
  } catch (err) {
    console.log(err, "@getall err");
  }
};
