// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IonContent, IonPage } from "@ionic/react";
import { SecurityQuestionsModule } from "@tkey/security-questions";
import CustomAuth, { TorusKey } from "@toruslabs/customauth";
import { Loader } from "assets/icons";
import axios from "axios";
import BN from "bn.js";
import { ec as EC } from "elliptic";
import { privateToAddress } from "ethereumjs-util";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import log from "loglevel";
import React, { useRef } from "react";
import { PinInputActions, usePinInput } from "react-pin-input-hook";
import { useHistory, useLocation } from "react-router-dom";
import { setPreferencesAndRedirect } from "shared/actions/olDappModuleAction";
import { olDeviceModuleAction } from "shared/actions/olDeviceModuleAction";
import { UpdateLoginRecord, addOrUpdateLoginRecord, updateDeviceIndex } from "shared/actions/olLoginPerfModuleAction";
import {
  _init,
  addPassword,
  calculateSettingsPageData,
  changePassword,
  createNewTKey,
  ensureTKeyCreated,
  exportShare,
  getTkeyInstance,
  olTkeyModuleAction
} from "shared/actions/olTkeyModuleAction";
import { olUserModuleAction, postV2UpbondAccount, updateUserPersistedInfo } from "shared/actions/olUserModuleAction";
import { store } from "shared/store";
import { UpdateUserPayload } from "shared/utils/__generated__/graphql-types";
import { setAuthToken } from "shared/utils/apollo";
import { capitalizeFirstLetter } from "shared/utils/coreUtils";
import {
  OPENLOGIN_WALLET_MIGRATION,
  PASSWORD_QUESTION,
  SECURITY_QUESTIONS_MODULE_KEY,
  TKEY_ADD_PASSWORD_OP
} from "shared/utils/openloginenums";
import { LoginType, TorusUserInfo } from "shared/utils/openlogininterface";
import { updateUserInfo } from "shared/utils/openloginmutation";
import { getCustomDeviceInfo } from "shared/utils/tKeyUtils";
import config from "shared/utils/openloginconfig";
import { useTranslation } from "react-i18next";
import useTheme from "hooks/useTheme";
import DesktopInput from "./pc_input";
import "./style.css";

export interface TKeyAccount {
  privKey: string;
}

const RegisTkeyPage = () => {
  const { t } = useTranslation();
  const { breakpoint } = useTheme();
  const location = useLocation();
  const history = useHistory();
  const { olDappModule, olUserModule, olLoginPerfModule, olAllDappModule } = useAppSelector((state) => state);
  const hash = location.hash;
  const [disable, setDisabled] = React.useState<boolean>(true);
  const [focus, setFocus] = React.useState<boolean>(false);
  const [popupWindow, setPopupWindow] = React.useState<boolean>(false);
  const [pid, setPid] = React.useState<string>("");
  const [customAuthInstanceId, setCustomAuthInstanceId] = React.useState<string>("");
  const [extraLoginOptions, setExtraLoginOptions] = React.useState<any>({});
  const [upgrading, setUpgrading] = React.useState<boolean>(false);
  const [isV1, setIsV1] = React.useState(false);
  const [currentLoginProviderString, setCurrentLoginProviderString] = React.useState("");
  const [passwordInputLoading, setPasswordInputLoading] = React.useState<boolean>(false);
  const [recoveryPhrase, setRecoveryPhrase] = React.useState<string>("");
  const [shareIndexTod, setShareIndex] = React.useState<string>("");
  const [values, setValues] = React.useState(Array(4).fill(""));
  const [value, setValue] = React.useState<any>("");
  const [error, setError] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const ref = useRef<any>();
  const actionRef = React.useRef<PinInputActions>(null);

  const { fields } = usePinInput({
    values,
    onChange: (values) => {
      if (values.includes("")) {
        // Setting the error.
        setDisabled(true);
        setFocus(false);
        // We set the focus on the first empty field if `error: true`
        // was passed as a parameter in `options`.
      }
      setValues(values);
    },

    onComplete(value) {
      setValue(value);
      setFocus(true);
      setDisabled(false);
      ref.current.childNodes.forEach((el: any) => el.blur());
    },
    error,
    actionRef
  });

  const shareIndex = "";
  const dispatch = useAppDispatch();

  const customAuth = new CustomAuth({
    baseUrl: window.location.origin,
    redirectPathName: "register",
    uxMode: "redirect",
    network: process.env.REACT_APP_TORUS_NETWORK as any,
    enableLogging: true,
    // locationReplaceOnRedirect: true,

    // v2
    metadataUrl: config.metadataHost,
    enableOneKey: true,
    // networkUrl: config.networkUrl
    storageServerUrl: config.storageServerUrl,
    web3AuthClientId: (olUserModule.currentDappClientId as any) || (process.env.REACT_APP_CLIENT_ID as any)
  });

  const confirm = async () => {
    if (!confirmLoading) {
      await inputPassword(value);
    }
  };

  React.useEffect(() => {
    const init = async () => {
      await _init({ postboxKey: olUserModule.keyInfo.privateKey, tKeyJson: undefined, shareStores: [] });

      customAuth.torus.serverTimeOffset = olUserModule.clientTimeOffset;

      if (hash) {
        const hashUrl = new URL(`${window.location.origin}?${hash.slice(1)}`);
        setPid(hashUrl.searchParams.get("pid") as string);
        setPopupWindow(hashUrl.searchParams.get("popupWindow") === "true");
        setCustomAuthInstanceId(hashUrl.searchParams.get("customAuthInstanceId") as string);
        setExtraLoginOptions(JSON.parse(hashUrl.searchParams.get("extraLoginOptions") || "{}"));
        setUpgrading(hashUrl.searchParams.has("upgrading"));
        setIsV1(store.getState().olTKeyModule.keyMode === "v1");
      }
      await customAuth.init({ skipSw: true, skipInit: true });
      setAuthToken(olUserModule.authToken);
      const dappModulePath = olAllDappModule.dappModules[olUserModule.currentDappClientId];
      const currentLoginConfig = dappModulePath.customLoginConfig[dappModulePath.currentLoginProvider];

      if (currentLoginConfig && currentLoginConfig?.walletVerifier) {
        dispatch(
          olDeviceModuleAction.setWalletTKeyOnboarding({
            verifier: currentLoginConfig.walletVerifier,
            verifierId: olUserModule.userInfo.verifierId,
            onboarding: true
          })
        );
      }
    };
    init();
    return () => {
      logoutAndRedirect();
    };
  }, []);

  async function logoutAndRedirect() {
    olTkeyModuleAction.logout();
    olUserModuleAction.logout();
    history.push("/");
  }

  const finishing = async (_err: any) => {
    await addOrUpdateLoginRecord({
      clientId: olAllDappModule.dappModules[olUserModule.currentDappClientId].clientId,
      dappUrl: olAllDappModule.dappModules[olUserModule.currentDappClientId].embedWlRedirectUrl,
      verifier: olUserModule.userInfo.aggregateVerifier,
      verifierId: olUserModule.userInfo.verifierId,
      loginRoute: "regist-auth",
      loginType: "2/n" as LoginType,
      hasSkippedMfa: false,
      isLoginCompleted: true,
      errorStack: _err,
      mfaLevel: "default",
      mobileOrigin: olAllDappModule.dappModules[olUserModule.currentDappClientId].mobileOrigin,
      sessionId: olAllDappModule.dappModules[olUserModule.currentDappClientId].sessionId
    });

    await updateDeviceIndex({
      verifier: olUserModule.userInfo.aggregateVerifier,
      verifierId: olUserModule.userInfo.verifierId,
      deviceShareIndex: shareIndex
    });

    if (isV1) {
      if (customAuthInstanceId) customAuth.storageHelper.clearLoginDetailsStorage(customAuthInstanceId);
      setPreferencesAndRedirect({
        pid: pid,
        popupWindow: popupWindow,
        redirectUrl: `${window.location.origin}/end`,
        result: {
          tKey: store.getState().olTKeyModule.tKeyPrivKey,
          oAuthPrivateKey: olUserModule.keyInfo.privateKey,
          walletKey: store.getState().olTKeyModule.tKeyPrivKey
        },
        verifier: olUserModule.userInfo.aggregateVerifier,
        embedWlRedirectUrl: olAllDappModule.dappModules[olUserModule.currentDappClientId].embedWlRedirectUrl,
        verifierId: olUserModule.userInfo.verifierId,
        isUsingDirect: localStorage.getItem("isUsingDirect") ? localStorage.getItem("isUsingDirect") === "true" : false,
        dappRedirectUri: localStorage.getItem("dappRedirectUri")
          ? decodeURIComponent(localStorage.getItem("dappRedirectUri") as string)
          : ""
      });
      setPasswordInputLoading(false);
    } else {
      await createPasswordShare();
    }
  };

  const updateGlobalTKeyState = async () => {
    // TODO: updateTransitionToGlobal backend
    let _err: string | undefined;
    try {
      const setupDate = new Date();

      try {
        const localTKey = await getTkeyInstance();
        // In rehydration we always have privKey

        if (
          store.getState().olTKeyModule.tKeyPrivKey &&
          (store.getState().olTKeyModule.keyMode === "v1" || store.getState().olTKeyModule.keyMode === "2/n")
        ) {
          await localTKey.reconstructKey();

          store.dispatch(olTkeyModuleAction.updateState({ tKey: localTKey } as any));
        }
      } catch (error) {
        log.warn(error, "maybe reconstruct only in certain times");
      }

      const localTKey = await getTkeyInstance();
      await localTKey.syncLocalMetadataTransitions();
      await calculateSettingsPageData({ forceCheckOnDeviceShare: true });

      store.dispatch(olTkeyModuleAction.updateState({ keyMode: "2/n" }));
      store.dispatch(
        olUserModuleAction.setKeyInfo({
          ...olUserModule.keyInfo,
          metadataNonce: "0"
        })
      );

      const tkey_public_address = privateToAddress(Buffer.from(store.getState().olTKeyModule.tKeyPrivKey, "hex")).toString("hex");

      const userTkeyInfo: UpdateUserPayload = {
        tkey_public_address,
        tkey_backup_emails: Object.values(store.getState().olTKeyModule.settingsPageData.exportedEmailShares).length,
        tkey_threshold: store.getState().olTKeyModule.keyDetails.totalShares,
        tkey_password: store.getState().olTKeyModule.settingsPageData.passwordShare.available,
        wallet_public_address: olUserModule.walletKeyInfo.publicAddress,
        tkey_creation_factor: store.getState().olTKeyModule.creationFactor,
        tkey_backup_downloaded: true,
        tkey_backup_copied: true,
        tkey_backup_verified: true,
        mfa_time: olLoginPerfModule.totalMfaTimeTaken,
        backup_phrase_setup_at: setupDate.toISOString(),
        backup_phrase_setup_email: olUserModule.userInfo.email
      };

      await updateUserInfo(userTkeyInfo);
    } catch (error: any) {
      log.error("errorWhileUpdateTransitionData", error);
      if (String(error.message).includes("setMetadata")) {
        finishing(_err);
      } else {
        _err = (error as Error)?.stack || (error as Error).message || "Something went wrong";
        await UpdateLoginRecord({
          errorStack: _err,
          isLoginCompleted: true,
          mobileOrigin: olDappModule?.mobileOrigin
        });
      }
    } finally {
      await finishing(_err);
    }
  };

  const reconstructAfterTkeyModified = async (keys: TKeyAccount[]) => {
    if (isV1) {
      if (!keys) {
        logoutAndRedirect();
        return;
      }

      await updateGlobalTKeyState();
    }
  };

  const createPasswordShare = async () => {
    try {
      await ensureTKeyCreated({ keyInfo: olUserModule.keyInfo as TorusKey, userInfo: olUserModule.userInfo as TorusUserInfo });
      if (!store.getState().olTKeyModule.settingsPageData.passwordShare.available) {
        const localTKey = await getTkeyInstance();
        await (localTKey.modules[SECURITY_QUESTIONS_MODULE_KEY] as SecurityQuestionsModule).generateNewShareWithSecurityQuestions(
          value,
          PASSWORD_QUESTION
        );
        await localTKey.syncLocalMetadataTransitions();
        await calculateSettingsPageData();

        try {
          await updateUserPersistedInfo({
            payload: { tkey_password: true, tkey_threshold: store.getState().olTKeyModule.keyDetails.totalShares }
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        await changePassword(value);
      }

      // Get login provider (line, google, etc)
      // Login provider MUST BE upbond-xxx
      const provider_inp = olAllDappModule.dappModules[olUserModule.currentDappClientId].currentLoginProvider.split("upbond-")[1];

      const updatedUserData = await postV2UpbondAccount({
        provider: provider_inp,
        is_wallet_registered: true,
        wallet_version: "2.0.0",
        wallet_registered_at: new Date()
      });

      if (updatedUserData?.success) {
        const updatedData = updatedUserData?.data?.data;

        // Get identities index of provider
        if (updatedData?.identities) {
          const providerIndex = updatedData.identities.findIndex((p: { provider: string }) => p.provider === provider_inp);

          // Check registration condition based on provider

          if (updatedUserData?.data?.data?.identities) {
            olUserModuleAction.setUserWalletData({
              isRegistered: updatedUserData?.data?.data?.identities[providerIndex].is_wallet_registered as boolean,
              walletVersion: updatedUserData?.data?.data?.identities[providerIndex].wallet_version as string
            });
          }
        }

        await UpdateLoginRecord({
          isLoginCompleted: true,
          mobileOrigin: olAllDappModule.dappModules[olUserModule.currentDappClientId]?.mobileOrigin
        });

        console.log({
          pid: pid,
          popupWindow: popupWindow,
          redirectUrl: olAllDappModule.dappModules[olUserModule.currentDappClientId].redirectUrl,
          result: {
            tKey: store.getState().olTKeyModule.tKeyPrivKey,
            oAuthPrivateKey: olUserModule.keyInfo.privateKey,
            walletKey:
              store.getState().olTKeyModule.keyMode === "v1"
                ? olUserModule.walletKeyInfo.privateKey
                : store.getState().olTKeyModule.tKeyPrivKey
          },
          verifier: olUserModule.userInfo.aggregateVerifier,
          verifierId: olUserModule.userInfo.verifierId,
          embedWlRedirectUrl: olAllDappModule.dappModules[olUserModule.currentDappClientId].embedWlRedirectUrl,
          isUsingDirect: localStorage.getItem("isUsingDirect") ? localStorage.getItem("isUsingDirect") === "true" : false,
          dappRedirectUri: localStorage.getItem("dappRedirectUri")
            ? decodeURIComponent(localStorage.getItem("dappRedirectUri") as string)
            : ""
        });

        setPreferencesAndRedirect({
          pid: pid,
          popupWindow: popupWindow,
          redirectUrl: `${window.location.origin}/end`,
          result: {
            tKey: store.getState().olTKeyModule.tKeyPrivKey,
            oAuthPrivateKey: olUserModule.keyInfo.privateKey,
            walletKey:
              store.getState().olTKeyModule.keyMode === "v1"
                ? olUserModule.walletKeyInfo.privateKey
                : store.getState().olTKeyModule.tKeyPrivKey
          },
          verifier: olUserModule.userInfo.aggregateVerifier,
          verifierId: olUserModule.userInfo.verifierId,
          embedWlRedirectUrl: olAllDappModule.dappModules[olUserModule.currentDappClientId].embedWlRedirectUrl,
          isUsingDirect: localStorage.getItem("isUsingDirect") ? localStorage.getItem("isUsingDirect") === "true" : false,
          dappRedirectUri: localStorage.getItem("dappRedirectUri")
            ? decodeURIComponent(localStorage.getItem("dappRedirectUri") as string)
            : ""
        });
        setPasswordInputLoading(false);
      }
    } catch (error: any) {
      console.log("register v2: error while create password share", error);
      const _err = (error as Error)?.stack || (error as Error).message || "Something went wrong";
      await UpdateLoginRecord({
        errorStack: _err,
        isLoginCompleted: true,
        mobileOrigin: olAllDappModule.dappModules[olUserModule.currentDappClientId]?.mobileOrigin
      });
      if (customAuthInstanceId) customAuth.storageHelper.clearLoginDetailsStorage(customAuthInstanceId);
      setPreferencesAndRedirect({
        pid: pid,
        popupWindow: popupWindow,
        redirectUrl: olAllDappModule.dappModules[olUserModule.currentDappClientId].redirectUrl,
        result: {
          tKey: store.getState().olTKeyModule.tKeyPrivKey,
          oAuthPrivateKey: olUserModule.keyInfo.privateKey,
          walletKey: store.getState().olTKeyModule.tKeyPrivKey
        },
        verifier: olUserModule.userInfo.aggregateVerifier,
        verifierId: olUserModule.userInfo.verifierId,
        embedWlRedirectUrl: olAllDappModule.dappModules[olUserModule.currentDappClientId].embedWlRedirectUrl,
        isUsingDirect: localStorage.getItem("isUsingDirect") ? localStorage.getItem("isUsingDirect") === "true" : false,
        dappRedirectUri: localStorage.getItem("dappRedirectUri")
          ? decodeURIComponent(localStorage.getItem("dappRedirectUri") as string)
          : "",
        errMsg: `[UPBOND]: Identity | Your clock is out of sync`
      });
      setPasswordInputLoading(false);
    }
  };

  const createRecoveryPhrase = async () => {
    try {
      const localTKey = await getTkeyInstance();
      // In rehydration we always have privKey

      if (
        store.getState().olTKeyModule.tKeyPrivKey &&
        (store.getState().olTKeyModule.keyMode === "v1" || store.getState().olTKeyModule.keyMode === "2/n")
      ) {
        await localTKey.reconstructKey();

        dispatch(olTkeyModuleAction.updateState({ tKey: localTKey } as any));
      }
    } catch (error) {
      log.warn(error, "maybe reconstruct only in certain times");
    }
    let sharedIndex = shareIndex;
    if (!shareIndex) {
      const tKey = await getTkeyInstance();
      const { newShareIndex } = await tKey.generateNewShare();
      setShareIndex(newShareIndex.toString("hex"));
      sharedIndex = newShareIndex.toString("hex");
      dispatch(olTkeyModuleAction.updateState({ tKey }));
    }
    return exportShare(sharedIndex);
  };

  const saveDevice = async () => {
    try {
      const ec = new EC("secp256k1");
      const { metadataNonce } = olUserModule.keyInfo;
      const { privateKey } = olUserModule.keyInfo;
      const tkey = new BN(privateKey, "hex").add(new BN(metadataNonce, "hex")).umod(ec.curve.n);
      await createNewTKey({
        postboxKey: privateKey, // oauthKey
        importKey: tkey.toString(16, 64),
        loginProvider: currentLoginProviderString,
        verifierId: olUserModule.userInfo.email || olUserModule.userInfo.verifierId,
        customDeviceInfo: getCustomDeviceInfo()
      });
      const newRecoveryPharse = await createRecoveryPhrase();
      setRecoveryPhrase(newRecoveryPharse);
    } catch (error) {
      log.error("register v2: error while starting tkey creation", error);
      const _err = (error as Error)?.stack || (error as Error).message || "Something went wrong";
      await UpdateLoginRecord({
        errorStack: _err,
        isLoginCompleted: true,
        mobileOrigin: olAllDappModule.dappModules[olUserModule.currentDappClientId]?.mobileOrigin
      });
    } finally {
      await addOrUpdateLoginRecord({
        clientId: olAllDappModule.dappModules[olUserModule.currentDappClientId].clientId,
        dappUrl: olAllDappModule.dappModules[olUserModule.currentDappClientId].redirectUrl,
        verifier: olUserModule.userInfo.aggregateVerifier,
        verifierId: olUserModule.userInfo.verifierId,
        loginRoute: "regist-auth",
        loginType: store.getState().olTKeyModule.keyMode as LoginType,
        hasSkippedMfa: false,
        mobileOrigin: olAllDappModule.dappModules[olUserModule.currentDappClientId].mobileOrigin,
        sessionId: olAllDappModule.dappModules[olUserModule.currentDappClientId].sessionId,
        isLoginCompleted: true,
        errorStack: ""
      });
      await updateGlobalTKeyState();
    }
  };

  const inputPassword = async (value: string) => {
    let _err: string | undefined;
    const operationName = TKEY_ADD_PASSWORD_OP;
    window.performance.mark(`${operationName}_start`);

    setPasswordInputLoading(true);
    setConfirmLoading(true);
    try {
      if (!isV1) {
        await saveDevice();
      } else {
        const localTKey = await getTkeyInstance();

        try {
          // In rehydration we always have privKey

          if (
            store.getState().olTKeyModule.tKeyPrivKey &&
            (store.getState().olTKeyModule.keyMode === "v1" || store.getState().olTKeyModule.keyMode === "2/n")
          ) {
            await localTKey.reconstructKey();

            store.dispatch(olTkeyModuleAction.updateState({ tKey: localTKey } as any));
          }
        } catch (error) {
          log.warn(error, "maybe reconstruct only in certain times");
        }

        const { userInfo, keyInfo, authServiceInfo } = olUserModule;
        const tKeyHex = await createNewTKey({
          postboxKey: (await getTKeyCreationInputKeys()).postboxKey,
          loginProvider: capitalizeFirstLetter(userInfo.typeOfLogin),
          importKey: keyInfo.privateKey,
          verifierId: userInfo.verifierId,
          customDeviceInfo: getCustomDeviceInfo(),
          syncLocalTransitions: true,
          sub: authServiceInfo.sub,
          typeOfUser: "v1"
        });
        if (!store.getState().olTKeyModule.settingsPageData.passwordShare.available) {
          await (localTKey.modules[SECURITY_QUESTIONS_MODULE_KEY] as SecurityQuestionsModule).generateNewShareWithSecurityQuestions(
            value,
            PASSWORD_QUESTION
          );
          await localTKey.syncLocalMetadataTransitions();
          await calculateSettingsPageData();
          await updateUserPersistedInfo({
            payload: { tkey_password: true, tkey_threshold: store.getState().olTKeyModule.keyDetails.totalShares }
          });
        } else {
          const del_lead0 = Number(value);

          await changePassword(del_lead0.toString());
        }

        // Get login provider (line, google, etc)
        // Login provider MUST BE upbond-xxx
        const provider_inp = olAllDappModule.dappModules[olUserModule.currentDappClientId].currentLoginProvider.split("upbond-")[1];

        const updatedUserData: any = await axios.post(
          `${process.env.REACT_APP_LOGIN_UPBOND_DOMAIN}/customer-wallet`,
          {
            provider: provider_inp,
            is_wallet_registered: true,
            wallet_version: "2.0.0",
            wallet_registered_at: new Date()
          },
          {
            headers: {
              Authorization: `Bearer ${olUserModule.userInfo.accessToken}`
            }
          }
        );

        if (updatedUserData?.data) {
          // pass to home
          await reconstructAfterTkeyModified(tKeyHex);
        }
      }
      setConfirmLoading(false);
    } catch (error: unknown) {
      setPasswordInputLoading(false);
      console.log(error, "@onErrorInputPassword");
      _err = (error as Error)?.stack || (error as Error).message || "Something went wrong";
      await UpdateLoginRecord({
        errorStack: _err,
        isLoginCompleted: false,
        mobileOrigin: olAllDappModule.dappModules[olUserModule.currentDappClientId].mobileOrigin
      });
      setConfirmLoading(false);

      //   userModule.setToastMessage({ message: t("common.toast-registration-failed"), type: TOAST_TYPES.ERROR });
    }
  };

  /**
   * This gonna be a different process between V1 and V2 (not a upbond version but torus version)
   * - Version 1
   * a torus version that the privkey is reconstruct by the custom auth, then generate password share
   * using upbond ID / sub
   * - Version 2
   * a torus version that the privkey is reconstructed by oAuth login (line / google). in this case
   * the process need to ensure that tKey is already created in 2/n type of user
   *  tKey management docs:
   * - [tKey Management](https://docs.tor.us/customauth/compatibility-and-common-patterns/tkey)
   */

  const getTKeyCreationInputKeys = async () => {
    if (store.getState().olTKeyModule.keyMode === "v1") return { postboxKey: olUserModule.keyInfo.privateKey };
    return {
      postboxKey: customAuth.getPostboxKeyFrom1OutOf1(olUserModule.keyInfo.privateKey, olUserModule.keyInfo.metadataNonce),
      importKey: olUserModule.keyInfo.privateKey
    };
  };

  return (
    <IonPage className="xs500:max-w-[500px] w-screen h-full sm:h-screen flex justify-start sm:justify-center xs:items-center items-start px-8 flex-col sm:flex-row my-0 mx-auto">
      <IonContent>
        <div className="lg:flex-col lg:flex py-10 ">
          <h1 className="headline-2 self-start mb-2">{t("pin_input.regis_tkey_1")}</h1>
          <div className="h-[96px] rounded-s-md   bg-white  flex justify-start gap-1 w-[99%] shadow-[0_3px_3px_rgb(0,0,0,0.2)]">
            <div className="bg-red-600 w-[20px]  h-full rounded-s-md"></div>
            <p className="py-2 px-1 text-sm mt-2 text-left font-poppins font-normal">{t("pin_input.regis_tkey_2")}</p>
          </div>

          {breakpoint < 1024 ? (
            <div
              className="pin-input"
              ref={ref}
              onMouseEnter={() => setFocus(true)}
              onMouseLeave={() => (value.length < 4 ? setFocus(false) : "")}>
              {fields.map((fieldProps, index) => (
                <input
                  key={index}
                  id={`pin-${index + 1}`}
                  className={`pin-input__field font-poppins font-28  max-w-max w-full h-[80px] text-center otp-field-box--${index} ${
                    focus ? "border-2" : "border"
                  }  border-[#${focus ? "4d4082" : "d7d7d7"}]`}
                  style={{ borderWidth: focus ? 2 : "", borderColor: focus ? "#4d4082" : "" }}
                  maxLength={1}
                  {...fieldProps}
                  placeholder=""
                  onFocus={(e: any) => e.target.select()}
                />
              ))}
            </div>
          ) : (
            <DesktopInput setDisabled={setDisabled} setValue={setValue} />
          )}

          <>
            <button
              className="bconfirm-2 h-[48px] w-full rounded-3xl font-poppins flex justify-center items-center "
              onClick={confirm}
              disabled={disable}>
              {!passwordInputLoading ? t("pin_input.regis_tkey_3") : <img src={Loader} alt="spinner" className="spinner" />}
            </button>
          </>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RegisTkeyPage;
