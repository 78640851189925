import useTheme from "hooks/useTheme";
import { useTranslation } from "react-i18next";

// DONT DELETE THIS
import { useRef } from "react";
import { openloginUrl } from "shared/config/openlogin";
import LanguageMainMb from "features/settings/components/languageMain/mb";
import LanguageMainPc from "features/settings/components/languageMain/pc";

const LanguagePage = () => {
  const { i18n } = useTranslation();
  const { breakpoint } = useTheme();

  // DONT DELETE THIS
  const refref = useRef(null);
  const win = document.getElementById("openlogin-popup") as any;
  const handleSwitchLang = (val: string | undefined) => {
    localStorage.setItem("lang", val as string);
    i18n.changeLanguage(val);

    if (breakpoint >= 1024) {
      document.addEventListener("click", handler, true);
      window.location.reload();
    }

    // DONT DELETE THIS
    const newWin = win.contentWindow;
    newWin.postMessage({ name: "lang", val: val }, "*");
  };

  function handler(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <>
      {/* DONT DELETE THIS */}
      <iframe
        id="openlogin-popup"
        src={openloginUrl + "/locale.html"}
        name="iframe_openlogin_popup"
        height="0%"
        width="0%"
        ref={refref}
        style={{ width: "1px" }}
        className="iframe-body"
        title="Wallet Iframe"></iframe>

      {breakpoint < 1024 ? <LanguageMainMb handleSwitchLang={handleSwitchLang} /> : <LanguageMainPc handleSwitchLang={handleSwitchLang} />}
    </>
  );
};

export default LanguagePage;
