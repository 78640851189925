// @ts-nocheck
import { IonButton, IonList, IonModal, useIonLoading, useIonRouter } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import useWallet from "hooks/useWallet";
// import { chevronDown } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchTransferAmount } from "shared/actions/transferAction";
import { fromGweiToWeiHex, parseEther } from "shared/utils/etherUtils";
// import ContactBar from "features/transfer/components/ContactBar";
// import GasModal from "features/transfer/components/GasModal";
import ConfirmMainMb from "features/transfer/confirm/components/ConfirmMain/mb";
import ConfirmMainPc from "features/transfer/confirm/components/ConfirmMain/pc";
import ConfirmPopupMb from "features/transfer/confirm/components/ConfirmPopup/mb";
import ConfirmPopupPc from "features/transfer/confirm/components/ConfirmPopup/pc";

const ConfirmTransferPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useIonRouter();

  const transfer = useAppSelector((state) => state.transfer);
  // const contact = useAppSelector((state) => state.contact);
  const gas = useAppSelector((state) => state.gas);

  const { sendTransaction, changeEthProvider } = useWallet({}, false);

  const { breakpoint } = useTheme();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [amountToSend, setAmountToSend] = useState<string>("0");
  const [totalSend, setTotalSend] = useState<number>(0);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
  const [present, dismiss] = useIonLoading();
  const amountChanged = (e: any) => {
    let val = e.target.value;
    const expr = /^[0-9.,]*$/;
    if (!expr.test(e.target.value)) return;

    val = val.replace(/,/g, ".");
    if (val.includes(".")) {
      if (!/^\d+(\.\d+)?$/.test(val)) {
        setIsConfirmDisabled(true);
      } else {
        setIsConfirmDisabled(false);
      }
    } else {
      setIsConfirmDisabled(false);
    }

    const setTotal = parseFloat(val) + parseFloat(gas.totalFee);
    setAmountToSend(val);
    setTotalSend(setTotal);
  };
  const proceedTransfer = () => {
    setOpenConfirm(!openConfirm);
    dispatch(fetchTransferAmount(amountToSend.toString()));
  };

  const confirmTransaction = async () => {
    try {
      present({
        message: t("transfer.loading"),
        cssClass: "custom-loading"
      });
      setOpenConfirm(!openConfirm);
      const to = transfer.toAddress?.trim() || "";
      const value = parseEther(amountToSend);
      const maxFeePerGas = fromGweiToWeiHex(gas.maxBaseFee.toString());
      const maxPriorityFeePerGas = fromGweiToWeiHex(gas.maxPriorityFee.toString());
      const gasLimit = 21000;

      const tx = await sendTransaction({
        to,
        value,
        params: { gasLimit, maxFeePerGas, maxPriorityFeePerGas }
      });
      dismiss();

      if (tx) {
        breakpoint < 1024 ? history.push(`/transfer/success`, "forward", "push") : history.push(`/wallet/tf/success`, "forward", "push");
      }
    } catch (error: any) {
      dismiss();
      setErrorMessage(error);
      setOpenErrorModal(true);
    }
  };

  const changeChain = async () => {
    await changeEthProvider(transfer.detail?.chain_id as string);
  };

  useEffect(() => {
    transfer.detail?.chain_id && changeChain();
  }, [transfer.detail?.chain_id]);

  useEffect(() => {
    transfer.amount && setAmountToSend(transfer.amount);
  }, [transfer.amount]);

  useEffect(() => {
    if (!transfer.toAddress) history.push("/wallet/transfer", "forward", "push");
  }, []);

  return (
    <>
      {breakpoint < 1024 ? (
        <ConfirmMainMb
          isAndroid={isAndroid}
          amountToSend={amountToSend}
          amountChanged={amountChanged}
          isConfirmDisabled={isConfirmDisabled}
          proceedTransfer={proceedTransfer}
        />
      ) : (
        <ConfirmMainPc
          amountToSend={amountToSend}
          amountChanged={amountChanged}
          isConfirmDisabled={isConfirmDisabled}
          proceedTransfer={proceedTransfer}
        />
      )}
      {breakpoint < 1024 ? (
        <ConfirmPopupMb
          setOpenConfirm={setOpenConfirm}
          openConfirm={openConfirm}
          totalSend={totalSend}
          confirmTransaction={confirmTransaction}
        />
      ) : (
        <ConfirmPopupPc
          setOpenConfirm={setOpenConfirm}
          openConfirm={openConfirm}
          totalSend={totalSend}
          confirmTransaction={confirmTransaction}
        />
      )}

      <IonModal
        id="modal-error"
        className="bg-[#000000_75] shadow-none outline-none radius-none"
        trigger="open-modal"
        isOpen={openErrorModal}>
        <div className="p-4 bg-white rounded-[30px] text-center">
          <IonList lines="none">{t("transfer.pleaseTryAgain")}</IonList>
          <IonList lines="none" className="p-4">
            {t(`transfer.${errorMessage}`)}
          </IonList>
          <IonButton
            shape="round"
            color="primary"
            expand="full"
            onClick={async () => {
              setOpenErrorModal(false);
              await setTimeout(() => console.log(), 1000);
              if (errorMessage === "INVALID_ARGUMENT") history.push("/wallet/transfer", "forward", "push");
            }}>
            {t("account.ok")}
          </IonButton>
        </div>
      </IonModal>
    </>
  );
};

export default ConfirmTransferPage;
