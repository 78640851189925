// @ts-nocheck
import { IonItem, IonIcon, IonChip, IonModal, IonLabel, IonList, IonButton, useIonRouter } from "@ionic/react";
import { useAppSelector } from "hooks/useRedux";
import { getTickerTokenSymbol } from "shared/utils/gasUtils";
import { useTranslation } from "react-i18next";
import { ellipse, informationCircle, informationCircleOutline, optionsOutline, timeOutline } from "ionicons/icons";
import { GasModalProps } from "features/transfer/types";

const GasModalMb = (props: GasModalProps) => {
  const { t } = useTranslation();
  const history = useIonRouter();
  const transfer = useAppSelector((state) => state.transfer);

  return (
    <>
      <IonItem className="px-4" lines="none">
        <div>
          <div slot="start" className="flex items-center text-sm text-[#989898] font-normal leading-[19px]">
            {t("transfer.estimateTime")}
            <IonIcon
              onClick={() => props.setOpenEtimatePopHover(!props.openEstimatePopHover)}
              id="click-trigger"
              slot="start"
              color="dark"
              className="pl-[5px]"
              icon={informationCircleOutline}
            />
          </div>

          <div slot="start" className="flex items-center text-xs gap-[5px]">
            <IonIcon icon={ellipse} className="text-[#e4e4e4]" />
            {parseFloat(props.totalFee).toFixed(6)}
            {history.routeInfo.pathname == "/transfer/confirm" || history.routeInfo.pathname == "/wallet/tf/confirm"
              ? // ? tickerTransfer
                `${getTickerTokenSymbol(transfer.detail?.chain_id ?? "1")}`
              : null}{" "}
            <span className="font-normal leading-[19px] text-gray-text">{`~${props.estimateTime} ${t("transfer.sec")}`}</span>
          </div>
        </div>

        <IonChip slot="end" className="font-bold text-sm leading-[22px] text-blue-dark" onClick={() => props.setOpen(true)}>
          <IonIcon icon={timeOutline} className="text-blue-dark m-0" />
          <span>{props.selectedFeeSpeed}</span>
        </IonChip>
        <IonChip slot="end" className="m-0" onClick={() => props.setOpen(true)}>
          <IonIcon className="text-blue-dark m-0" icon={optionsOutline} />
        </IonChip>
      </IonItem>
      {props.openEstimatePopHover && (
        <div className="fixed bottom-[10px] right-[80px] w-[206px] z-50 bg-[#f9f9f9] shadow-itemListShadow rounded-[10px] p-[5px] text-xs leading-5">
          <div>
            <p className="text-10 text-[#5d5d5d] leading-[14px]">{t("transfer.estimateTimeIcon")}</p>
          </div>
        </div>
      )}
      <IonModal id="modal-gas-example" ref={props.modal} trigger="open-modal" isOpen={props.open}>
        <div className="p-4 bg-[#FFFFFF] rounded-[30px] font-roboto">
          <IonList lines="none">
            <div className="flex flex-row justify-between items-center p-2">
              <div className="flex flex-row justify-between items-center">
                <IonLabel className="font-medium p-[5px]" position="stacked">
                  {t("transfer.maxBaseFee")}
                </IonLabel>
                <IonIcon
                  onClick={() => props.setOpenMaxBaseFee(!props.openMaxBaseFee)}
                  slot="start"
                  color="dark"
                  size="small"
                  icon={informationCircleOutline}
                  className="text-base pointer"
                />
              </div>
              {props.openMaxBaseFee && (
                <div className="absolute top-[30px] right-[10vw] w-[80%] z-50 bg-[#f9f9f9] shadow-itemListShadow rounded-[10px] p-0.5 h-fit">
                  <p className="text-10 text-[#5d5d5d] leading-[14px] p-[5px]">{t("transfer.maxExplain")}</p>
                </div>
              )}
              <div className="bg-[#f9f9f9] rounded-[15px] mb-[5px] p-[5px]">
                <span>
                  {props.baseFee} &nbsp; {`${getTickerTokenSymbol(transfer.detail?.chain_id ?? "1")}`}
                </span>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center p-2">
              <div className="flex flex-row justify-between items-center">
                <IonLabel className="font-medium p-[5px]" position="stacked">
                  {t("transfer.priorityFee")}
                </IonLabel>
                <IonIcon
                  onClick={() => props.setOpenPriorityFee(!props.openPriorityFee)}
                  slot="start"
                  color="dark"
                  size="small"
                  icon={informationCircleOutline}
                />
              </div>
              {props.openPriorityFee && (
                <div className="absolute top-[30px] right-[10vw] w-[80%] z-50 bg-[#f9f9f9] shadow-itemListShadow rounded-[10px] p-0.5 h-fit">
                  <p className="text-10 text-[#5d5d5d] leading-[14px] p-[5px]">{t("transfer.priorityExplain")}</p>
                </div>
              )}
              <div className="bg-[#f9f9f9] rounded-[15px] mb-[5px] p-[5px]">
                <span>
                  {props.priorityFee} &nbsp; {`${getTickerTokenSymbol(transfer.detail?.chain_id ?? "1")}`}
                </span>
              </div>
            </div>

            <IonButton
              shape="round"
              slot="end"
              color={props.feeSpeed === "Normal" ? "primary" : "medium"}
              onClick={() => props.changeTypeFee("Normal")}
              className={props.feeSpeed === "Normal" ? "active" : "inactive"}>
              {t("transfer.normal")}
            </IonButton>
            <IonButton
              shape="round"
              slot="end"
              color={props.feeSpeed === "Fast" ? "primary" : "medium"}
              className={props.feeSpeed === "Fast" ? "active" : "inactive"}
              onClick={() => props.changeTypeFee("Fast")}>
              {t("transfer.fast")}
            </IonButton>
            <IonButton
              shape="round"
              slot="end"
              color={props.feeSpeed === "Instant" ? "primary" : "medium"}
              className={props.feeSpeed === "Instant" ? "active" : "inactive"}
              onClick={() => props.changeTypeFee("Instant")}>
              {t("transfer.urgent")}
            </IonButton>
          </IonList>
        </div>
        {props.openEstimatePopHoverConfirm && (
          <div className="absolute top-[120px] left-[120px] w-[80%] h-full z-50 text-xs">
            <div className="absolute top-[10px] right-[30vw] w-[80%] p-2 z-50 bg-[#f9f9f9] shadow-itemListShadow rounded-[10px]">
              <p className="text-10 text-[#5d5d5d] text-roboto leading-[14px] p-[5px]">{t("transfer.estimateTimeIcon")}</p>
            </div>
          </div>
        )}
        <div className="flex justify-between">
          <div slot="start" className="flex items-start mt-3 font-normal text-sm leading-[19px] text-[#FFFFFF]">
            <div className="text-[#FFFFFF] font-roboto ml-[5px]">
              <IonLabel>
                {t("transfer.estimateTime")}
                <p className="font-normal mt-0 leading-[19px] !text-white">{`~${props.estimateTime} ${t("transfer.sec")}`}</p>
              </IonLabel>
            </div>
            <IonIcon
              className="ml-[5px] flex mt-0.5"
              onClick={() => props.setOpenEstimatePopHoverConfirm(!props.openEstimatePopHoverConfirm)}
              id="click-trigger"
              icon={informationCircle}
            />
          </div>
          <IonButton
            className="mt-4 h-[30px]"
            shape="round"
            color="primary"
            expand="full"
            onClick={() => {
              props.setOpen(false);
              props.applyFeeSpeed();
            }}>
            {t("transfer.done")}
          </IonButton>
        </div>
      </IonModal>
    </>
  );
};

export default GasModalMb;
