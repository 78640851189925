import { getPublicCompressed } from "@toruslabs/eccrypto";
import log from "loglevel";
import config, { openLoginVerifiers } from "shared/utils/openloginconfig";
import { store } from "shared/store";
import olDappModuleSlice from "shared/store/olDappModuleSlice";
import { PREFERENCES_OP } from "shared/utils/openloginenums";
import { getExternalAuthToken, updateUserInfo } from "shared/utils/openloginmutation";
import { getJoinedKey, redirectToDapp } from "shared/utils/openloginutils";
import { subkey } from "@toruslabs/openlogin-subkey";
import { updateUserPersistedInfo } from "./olUserModuleAction";
import { SUPPORTED_KEY_CURVES } from "@toruslabs/openlogin";
import { hashMessage, signMessage } from "shared/utils/openloginsignmessage";
import { getED25519Key } from "@toruslabs/openlogin-ed25519";
import { exportDeviceShare, olLoginPerfModuleAction } from "./olTkeyModuleAction";
import { cloneDeep, merge, pickBy } from "lodash-es";
import { LoginConfig } from "@toruslabs/openlogin-jrpc";
import { TouchIDPreferences } from "shared/utils/openlogininterface";

export const olDappModuleAction = olDappModuleSlice.actions;

export const setPreferencesAndRedirect = async (params: {
  pid: string;
  redirectUrl: string;
  popupWindow: boolean;
  result: {
    tKey: string;
    oAuthPrivateKey: string;
    walletKey: string;
    tKeyString?: string;
  };
  verifier: string;
  verifierId: string;
  disableAlwaysSkip?: boolean;
  alwaysSkip?: boolean;
  isUsingDirect?: boolean;
  dappRedirectUri?: string;
  embedWlRedirectUrl?: string;
  errMsg?: string;
}): Promise<void> => {
  if (params.dappRedirectUri && params.isUsingDirect) {
    store.dispatch(
      olDappModuleAction.setDirectConf({
        directUrl: params.dappRedirectUri,
        usingDirect: params.isUsingDirect
      })
    );
  }

  const operationName = PREFERENCES_OP;
  window.performance.mark(`${operationName}_start`);

  const { redirectUrl, result, popupWindow, pid, alwaysSkip, disableAlwaysSkip, verifier, verifierId } = params;
  // dispatch(olDeviceModuleAction.setLastLoggedIn({ verifier, verifierId }));
  // dispatch(olDeviceModuleAction.setLastLoggedInVerifier({ verifier }));
  const { userInfo, walletKeyInfo } = store.getState().olUserModule;
  // get device share from here and return dapp share depending on login methods
  const { keyMode } = store.getState().olTKeyModule;
  const {
    email,
    aggregateVerifier,
    name,
    profileImage,
    typeOfLogin,
    verifier: userVerifier,
    verifierId: userVerifierId,
    idToken: oAuthIdToken,
    accessToken: oAuthAccessToken
  } = userInfo;
  let dappShare = "";
  if (store.getState().olDappModule.canSendDappShare) {
    // we can send dapp share in this case
    const exportedShare = await exportDeviceShare();
    dappShare = exportedShare;
  }

  const finalResult: Record<string, unknown> = {
    oAuthPrivateKey: result.oAuthPrivateKey as string,
    store: {
      touchIDPreference: store.getState().olDappModule.touchIDPreference,
      appState: store.getState().olDappModule.appState,
      email,
      aggregateVerifier,
      name,
      profileImage,
      typeOfLogin,
      verifier: userVerifier,
      verifierId: userVerifierId,
      dappShare,
      oAuthIdToken: store.getState().olDappModule.isCustomVerifier ? oAuthIdToken : "", // only send original id token for custom verifiers
      oAuthAccessToken: store.getState().olDappModule.isCustomVerifier ? oAuthAccessToken : "",
      isUsingDirect: params.isUsingDirect,
      dappRedirectUri: params.dappRedirectUri,
      embedWlRedirectUrl: params.embedWlRedirectUrl || params.dappRedirectUri
    }
  };

  let app_pub_key = "";
  let app_signature = "";

  if (result.tKey) {
    console.log("DappModule clientID", store.getState().olDappModule.clientId);
    console.log("UserModule clientID", store.getState().olUserModule?.userInfo?.state?.client);
    const scopedKey = subkey(result.tKey as string, Buffer.from(store.getState().olUserModule?.userInfo?.state?.client, "base64"));
    store.dispatch(
      olDappModuleAction.updateState({
        privateKey: scopedKey
      })
    );
    finalResult.privKey = scopedKey;
    finalResult.tKey = result.tKey;

    if (store.getState().olDappModule.curve === SUPPORTED_KEY_CURVES.ED25519) {
      const ed25519Key = getED25519Key(Buffer.from(scopedKey.padStart(64, "0"), "hex"));
      app_pub_key = ed25519Key.pk.toString("hex");
      app_signature = signMessage(ed25519Key.sk.toString("hex"), store.getState().olUserModule.challenge, SUPPORTED_KEY_CURVES.ED25519);
    } else {
      app_pub_key = getPublicCompressed(Buffer.from(scopedKey.padStart(64, "0"), "hex")).toString("hex");
      app_signature = signMessage(
        scopedKey,
        hashMessage(store.getState().olUserModule.challenge).toString("hex"),
        SUPPORTED_KEY_CURVES.SECP256K1
      );
    }

    const updateUserDappParams = {
      dapp_id: store.getState().olLoginPerfModule.dappId,
      dapp_public_key: app_pub_key,
      device_id: store.getState().olDeviceModule.verifierIDDeviceIDMap[getJoinedKey(aggregateVerifier || verifier, verifierId)] || ""
    };
    await updateUserDappInfo({ payload: updateUserDappParams });
  }

  if (store.getState().olDappModule.getWalletKey) {
    const { persistedUserInfo } = store.getState().olUserModule;
    // for v2 users who have enabled dual account mode via support settings
    if (persistedUserInfo?.v2_wallet_key_enabled && keyMode !== "v1") {
      finalResult.walletKey = walletKeyInfo.privateKey.padStart(64, "0");
    } else {
      finalResult.walletKey = result.walletKey || "";
    }
  }
  // resetting it back to `dapp_public_key` false as tkey is generated and disableAlwaysSkip is sent as true
  if (disableAlwaysSkip && finalResult.tKey) {
    await updateUserPersistedInfo({ payload: { always_skip_tkey: false } });
  } else if (alwaysSkip) {
    await updateUserPersistedInfo({ payload: { always_skip_tkey: true } });
  }

  if (app_pub_key && store.getState().olUserModule?.userInfo?.state?.client !== config.openloginDappModuleKey) {
    try {
      const oauth_pub_key = getPublicCompressed(Buffer.from((finalResult.oAuthPrivateKey as string).padStart(64, "0"), "hex")).toString(
        "hex"
      );
      if (!store.getState().olDappModule.sessionId) {
        throw new Error("SessionId is missing while fetching external token");
      }

      const sessionNonce = getPublicCompressed(
        Buffer.from((store.getState().olDappModule.sessionId as string).padStart(64, "0"), "hex")
      ).toString("hex");
      log.debug("session nonce", sessionNonce, store.getState().olDappModule.redirectUrl);
      const token = await getExternalAuthToken({
        client_id: store.getState().olUserModule?.userInfo?.state?.client,
        timeout: store.getState().olDappModule.sessionTime,
        app_public_key: app_pub_key,
        curve: store.getState().olDappModule.curve || "",
        email: userInfo.email,
        name: userInfo.name,
        verifier: userInfo.verifier,
        aggregate_verifier: userInfo.aggregateVerifier,
        verifier_id: userInfo.verifierId,
        profile_image: userInfo.profileImage,
        session_nonce: sessionNonce,
        oauth_public_key: oauth_pub_key,
        app_signed_message: app_signature
      });
      if (!token) {
        log.error("empty token found while fetching external auth token");
      } else {
        store.dispatch(olDappModuleAction.updateState({ idToken: token } as any));
        (finalResult.store as Record<string, string>).idToken = token;
      }
    } catch (error) {
      log.error("error while fetching external auth token", error);
    }
  }

  store.dispatch(
    olLoginPerfModuleAction.markRouteAndTime({
      route: "end",
      isEnd: true,
      operation: operationName
    })
  );
  // reinit loginPerf before redirecting
  store.dispatch(olLoginPerfModuleAction._reinit());
  store.dispatch(olLoginPerfModuleAction.resetDappId());

  //hardcode
  finalResult.loginConfig = config.loginConfig;

  await redirectToDapp(
    {
      redirectUrl,
      popupWindow,
      sessionTime: store.getState().olDappModule.sessionTime,
      sessionId: store.getState().olDappModule.sessionId,
      _sessionNamespace: store.getState().olDappModule._sessionNamespace,
      errorMsg: params.errMsg || undefined
    },
    { result: finalResult, pid }
  );
};

export const updateUserDappInfo = async (params: {
  payload: { dapp_public_key: string; dapp_id: number; device_id?: string };
  throwError?: boolean;
}) => {
  const { payload, throwError } = params;
  const { dapp_public_key, dapp_id } = payload;
  const newValues = pickBy({ dapp_public_key, dapp_id }, (val: unknown, key: string) => {
    const existingInfo = (store.getState().olDappModule.persistedUserdappInfo as Record<string, unknown>) || {};
    if (existingInfo[key] === val) {
      return false;
    }
    return true;
  });
  if (newValues && Object.keys(newValues).length > 0) {
    try {
      await updateUserInfo(payload);
      store.dispatch(olDappModuleAction.setUserDappPersistedInfo({ ...payload }));
    } catch (error) {
      if (throwError) throw error;
    }
  }
};

export const usingDirectConfig = () => {
  return store.getState().olDappModule.directConfig;
};

export const loginConfig = (): LoginConfig => {
  const localLoginConfig = cloneDeep(config.loginConfig);
  const finalConfig = merge(localLoginConfig, store.getState().olDappModule.customLoginConfig);
  return finalConfig;
};

export const canSendDappShare = (): boolean => {
  const { userInfo } = store.getState().olUserModule;
  const { tKeyPrivKey, keyMode } = store.getState().olTKeyModule;
  const { aggregateVerifier, verifier: userVerifier } = userInfo;
  const customVerifier = aggregateVerifier || userVerifier;
  // TODO: Maybe add client whitelist
  // check if tkey exists (!tkeyPrivKey)
  if (!tKeyPrivKey || keyMode === "1/1") {
    return false;
  }
  return !openLoginVerifiers.includes(customVerifier);
};

export const isCustomVerifier = (): boolean => {
  const isCustomVerifier = Object.keys(store.getState().olDappModule.customLoginConfig).includes(
    store.getState().olDappModule.currentLoginProvider
  );
  return isCustomVerifier;
};

export const logout = async (): Promise<void> => {
  // this.context.commit(`${USER_MODULE_KEY}/resetCurrentClientId`, { clientId: this.clientId }, { root: true });
  // this.context.commit(`${USER_MODULE_KEY}/logout`, {}, { root: true });
  // this.context.commit(`${TKEY_MODULE_KEY}/logout`, {}, { root: true });
  store.dispatch(
    olDappModuleAction.updateState({
      touchIDPreference: TouchIDPreferences.DISABLED,
      customLoginConfig: {},
      privateKey: "",
      clientId: "",
      redirectUrl: store.getState().olDappModule.redirectUrl, // do not clear this, or logout redirects wont work
      currentLoginProvider: "",
      whiteLabel: {},
      sessionId: "",
      sessionTime: 86400,
      dappShare: "",
      idToken: "",
      _sessionNamespace: ""
    })
  );
  // this.context.commit(`${DEVICE_MODULE_KEY}/setLastLoggedInVerifier`, "", { root: true });
};
