import MetadataStorageLayer, { setDeviceShare, setTorusShare } from "@toruslabs/metadata-helpers";
import { BN } from "bn.js";
import { privateToAddress } from "ethereumjs-util";
import log from "loglevel";

import config from "./openloginconfig";
import { store } from "shared/store";
import { OAUTH_CREDID_CACHE, TKEY_REQUIRE_MORE_INPUT } from "./openloginenums";
import { CustomAuthResult, LoginType, TKeyAccount } from "./openlogininterface";
import { capitalizeFirstLetter, getJoinedKey } from "./openloginutils";
import { checkIfTKeyExists, createNewTKey, login, olTkeyModuleAction } from "shared/actions/olTkeyModuleAction";
import { addLoginRecord } from "shared/actions/olLoginPerfModuleAction";
import { doneAndRedirect, setUserInfoToStore, storeUserInfoToBackend } from "./openloginauthutil";
import olDeviceModuleSlice from "shared/store/olDeviceModuleSlice";
import { loginConfig } from "shared/actions/olAllDappModuleAction";

export const olDeviceModuleAction = olDeviceModuleSlice.actions;

export async function addCredIdToMetadata(oAuthPrivateKey: string, currentCredId?: string): Promise<void> {
  const { olUserModule } = store.getState();
  const metadataStorage = new MetadataStorageLayer(config.metadataHost, olUserModule.clientTimeOffset);
  const oAuthPubKey = metadataStorage.generatePubKeyParams(oAuthPrivateKey);
  if (!currentCredId) return;
  let credIdList: string[] = [currentCredId];

  const serializedCurrCredId = await metadataStorage.getMetadata(oAuthPubKey, OAUTH_CREDID_CACHE);
  try {
    if (serializedCurrCredId) {
      const currentCredIdList = JSON.parse(serializedCurrCredId);
      if (currentCredIdList && Array.isArray(currentCredIdList)) {
        credIdList = currentCredIdList.concat(credIdList);
      }
    }
  } catch (error: unknown) {
    log.debug(error, "credIds serialization error");
  }
  credIdList = [...new Set(credIdList)];
  await metadataStorage.setMetadata(
    metadataStorage.generateMetadataParams(JSON.stringify(credIdList), oAuthPrivateKey),
    OAUTH_CREDID_CACHE
  );
}

async function registerWebAuthnUser(
  customAuthResult: CustomAuthResult,
  oAuthPrivateKey: string
): Promise<{ canContinue: boolean; displayError?: string; tKey?: string; oAuthPrivateKey?: string }> {
  const { olTKeyModule, olUserModule } = store.getState();
  const { dispatch } = store;
  const { userInfo, pubKey, typeOfUser } = customAuthResult;
  const { state, extraVerifierParams, ref } = userInfo;
  const { walletKey, client, nonce } = state;
  const metadataStorage = new MetadataStorageLayer(config.metadataHost, olUserModule.clientTimeOffset);
  // Should never happen
  if (!oAuthPrivateKey) return { canContinue: false, displayError: `No oAuthPrivateKey found for registerWebAuthnUser ${typeOfUser}` };
  if (!pubKey) return { canContinue: false, displayError: `No pubKey found for registerWebAuthnUser ${typeOfUser}` };
  if (!ref) return { canContinue: false, displayError: `No ref found for registerWebAuthnUser ${typeOfUser}` };
  const currentRefHex = Buffer.from(ref, "base64").toString("hex");

  const userInfoFn = async () => {
    const res = await setUserInfoToStore(customAuthResult, oAuthPrivateKey, nonce, state);
    await storeUserInfoToBackend(res);
  };

  const [{ success, shareStore }] = await Promise.all([
    checkIfTKeyExists(new BN(oAuthPrivateKey, "hex")),
    addCredIdToMetadata(oAuthPrivateKey, extraVerifierParams?.credId),
    userInfoFn()
  ]);

  dispatch(
    olDeviceModuleAction.setTouchIDOnboarding({
      verifier: olUserModule.userInfo.aggregateVerifier,
      verifierId: olUserModule.userInfo.verifierId,
      onboarding: true
    })
  );
  const currentDappModule = store.getState().olAllDappModule.dappModules[olUserModule.currentDappClientId];
  const currentLoginConfig = loginConfig(currentDappModule.clientId)[currentDappModule.currentLoginProvider];
  if (currentLoginConfig.walletVerifier) {
    dispatch(
      olDeviceModuleAction.setWalletTKeyOnboarding({
        verifier: currentLoginConfig.walletVerifier,
        verifierId: olUserModule.userInfo.verifierId,
        onboarding: true
      })
    );
  }
  if (extraVerifierParams?.credId)
    dispatch(
      olDeviceModuleAction.addCredId({
        verifier: olUserModule.userInfo.aggregateVerifier,
        verifierId: olUserModule.userInfo.verifierId,
        credId: extraVerifierParams?.credId,
        confirmed: true
      })
    );

  const keys: TKeyAccount[] = [];

  if (!success) {
    const loginProviderString = capitalizeFirstLetter(currentDappModule.currentLoginProvider);
    const [localKeys] = await Promise.all([
      createNewTKey({
        postboxKey: oAuthPrivateKey,
        loginProvider: loginProviderString,
        verifierId: olUserModule.userInfo.email || olUserModule.userInfo.verifierId
      }),
      setTorusShare(
        metadataStorage,
        pubKey,
        currentRefHex,
        getJoinedKey(olUserModule.userInfo.aggregateVerifier, olUserModule.userInfo.verifierId),
        {
          privKey: oAuthPrivateKey,
          walletKey,
          nonce,
          keyMode: olTKeyModule.keyMode
        }
      )
    ]);

    keys.push(...(localKeys as any));
    const deviceShareStore = olTKeyModule.settingsPageData.deviceShare.share;
    await setDeviceShare(
      metadataStorage,
      currentRefHex,
      getJoinedKey(olUserModule.userInfo.aggregateVerifier, olUserModule.userInfo.verifierId),
      {
        shareStore: JSON.stringify(deviceShareStore)
      }
    );
    return { canContinue: true, oAuthPrivateKey, tKey: keys[0].privKey };
  }
  if (shareStore) {
    // Add webauthn to existing OAuth tKey
    try {
      await setTorusShare(
        metadataStorage,
        pubKey,
        currentRefHex,
        getJoinedKey(olUserModule.userInfo.aggregateVerifier, olUserModule.userInfo.verifierId),
        {
          privKey: oAuthPrivateKey,
          walletKey,
          nonce,
          keyMode: olTKeyModule.keyMode
        }
      );
      const localKeys = await login({
        postboxKey: oAuthPrivateKey,
        shareStores: [shareStore],
        dappShare: currentDappModule.dappShare
      });
      keys.push(...localKeys);
      const deviceShareStore = olTKeyModule.settingsPageData.deviceShare.share;
      await setDeviceShare(
        metadataStorage,
        currentRefHex,
        getJoinedKey(olUserModule.userInfo.aggregateVerifier, olUserModule.userInfo.verifierId),
        {
          shareStore: JSON.stringify(deviceShareStore)
        }
      );
      return { canContinue: true, oAuthPrivateKey, tKey: keys[0].privKey };
    } catch (error: unknown) {
      // This Should never occur
      if (error instanceof Error && error.message?.includes(TKEY_REQUIRE_MORE_INPUT)) {
        log.warn(error);
        await addLoginRecord({
          walletAddress: walletKey ? privateToAddress(Buffer.from(walletKey.padStart(64, "0"), "hex")).toString("hex") : undefined,
          verifier: olUserModule.userInfo.aggregateVerifier,
          verifierId: olUserModule.userInfo.verifierId,
          loginRoute: "auth",
          clientId: client,
          dappUrl: currentDappModule.redirectUrl,
          loginType: olTKeyModule.keyMode as LoginType,
          mobileOrigin: currentDappModule.mobileOrigin,
          sessionId: currentDappModule.sessionId
        });
        doneAndRedirect("TKeyInput", {
          popupWindow: state.popupWindow || "",
          disableAlwaysSkip: !!state.disableAlwaysSkip,
          pid: state.pid,
          extraLoginOptions: state.extraLoginOptions || "",
          customAuthInstanceId: state.instanceId as string
        });
        return { canContinue: false };
      }
      log.error(error);
      throw error;
    }
  } else {
    // This would never happen
    return { displayError: "unhandled in webauthn", canContinue: false };
  }
}

export async function registerWebAuthnV2User(
  customAuthResult: CustomAuthResult
): Promise<{ canContinue: boolean; displayError?: string; tKey?: string; oAuthPrivateKey?: string }> {
  // In this block, we are adding webauthn to a v2 user
  // OAuthPrivateKey may need checks to subtract nonce.
  // If user already has a nonce, we should subtract it. Else, use it as is.

  const { userInfo } = customAuthResult;
  const { state } = userInfo;
  const { oAuthPrivateKey } = state;
  store.dispatch(olTkeyModuleAction.updateState({ keyMode: state.keyMode as LoginType } as any));
  return registerWebAuthnUser(customAuthResult, oAuthPrivateKey);
}

export async function registerWebAuthnV1User(
  customAuthResult: CustomAuthResult
): Promise<{ canContinue: boolean; displayError?: string; tKey?: string; oAuthPrivateKey?: string }> {
  // In this block, we are adding webauthn to a v1 user
  // OAuthPrivateKey would definitely be used as is.
  // If user already has a nonce, it's fine.. (it's v1 nonce)

  const { userInfo } = customAuthResult;
  const { state } = userInfo;
  const { oAuthPrivateKey } = state;
  store.dispatch(olTkeyModuleAction.updateState({ keyMode: "v1" } as any));
  return registerWebAuthnUser(customAuthResult, oAuthPrivateKey);
}
