// @ts-nocheck
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IonAvatar, IonItem, IonLabel, IonText } from "@ionic/react";
import useHistoryImage from "hooks/useHistoryImage";
import { IDisplayHistory } from "interfaces/libs/IAccountHistory";
import { IPropsAstarItemHistory } from "interfaces/ui/IMenu";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ItemHistory = ({ handlePresent, item, account }: IPropsAstarItemHistory) => {
  const { t } = useTranslation();
  const [historyAstarParams, setHistoryAstarParams] = useState<IDisplayHistory>({
    from: "",
    to: "",
    value: "",
    isNfts: false,
    images: "",
    caption: "",
    isPlus: false,
    symbol: "",
    nativeSymbol: ""
  });
  const { ImageData } = useHistoryImage({ displayHistory: historyAstarParams, AstarItemResult: item });

  useEffect(() => {
    if (item.typeTx === "Erc721") {
      setHistoryAstarParams({
        from: item.from_address,
        to: item.to_address,
        value: "",
        isNfts: true,
        images: item.nftLogo ? item.nftLogo : "",
        caption:
          item?.from_address.toLocaleLowerCase() !== account.toLocaleLowerCase() ||
          item?.from_address.toLocaleLowerCase() === "0x0000000000000000000000000000000000000000"
            ? t("account.receiveFrom")
            : t("account.sentTo"),
        isPlus: false,
        symbol: item.nftName ? item.nftName : "",
        nativeSymbol: ""
      });
    } else {
      setHistoryAstarParams({
        from: item.from_address,
        to: item.to_address,
        value: item.value,
        isNfts: false,
        images: item.logo_url,
        caption:
          item?.from_address.toLocaleLowerCase() !== account.toLocaleLowerCase() ||
          item?.from_address.toLocaleLowerCase() === "0x0000000000000000000000000000000000000000"
            ? t("account.receiveFrom")
            : t("account.sentTo"),
        isPlus: item.from_address.toLocaleLowerCase() !== account.toLocaleLowerCase() ? true : false,
        symbol: item.custom_name,
        nativeSymbol: item.custom_name
      });
    }
  }, [item]);

  return (
    <div onClick={handlePresent} className="mt-4">
      <IonText className="text-gray-400">{item.block_timestamp}</IonText>
      <IonItem className="p-0">
        <IonAvatar slot="start">
          <ImageData />
        </IonAvatar>
        <IonLabel>
          <div className="text-base">
            <>
              {localStorage.getItem("lang") === "en" ? (
                <>
                  {item?.from_address === "0x0000000000000000000000000000000000000000" ? (
                    <>{t("account.receiveFrom")}&nbsp; </>
                  ) : (
                    <>{historyAstarParams?.caption}&nbsp;</>
                  )}
                  <span className="margin-left">
                    {!historyAstarParams?.isNfts ? (
                      <strong>
                        {historyAstarParams?.from?.toLocaleLowerCase() === account?.toLocaleLowerCase()
                          ? historyAstarParams?.to?.substring(0, 10) + "..."
                          : historyAstarParams?.from?.substring(0, 10) + "..."}
                      </strong>
                    ) : (
                      <strong>
                        {historyAstarParams?.from?.toLocaleLowerCase() === account?.toLocaleLowerCase()
                          ? historyAstarParams?.to?.substring(0, 10) + "..."
                          : historyAstarParams?.from?.substring(0, 10) + "..."}
                      </strong>
                    )}
                  </span>
                </>
              ) : (
                <>
                  <span className="margin-left">
                    {!historyAstarParams?.isNfts ? (
                      <strong>
                        {historyAstarParams?.from?.toLocaleLowerCase() === account?.toLocaleLowerCase()
                          ? historyAstarParams?.to?.substring(0, 10) + "..."
                          : historyAstarParams?.from?.substring(0, 10) + "..."}
                      </strong>
                    ) : (
                      <strong>
                        {historyAstarParams?.from?.toLocaleLowerCase() === account?.toLocaleLowerCase()
                          ? historyAstarParams?.to?.substring(0, 10) + "..."
                          : historyAstarParams?.from?.substring(0, 10) + "..."}
                      </strong>
                    )}
                  </span>{" "}
                  {item?.from_address === "0x0000000000000000000000000000000000000000" ? (
                    <>&nbsp;{t("account.receiveFrom")} </>
                  ) : (
                    <>&nbsp; {historyAstarParams?.caption}</>
                  )}
                </>
              )}
            </>
          </div>
          <strong className="text-base">{historyAstarParams?.symbol}</strong>
        </IonLabel>
        <IonLabel slot="end" className="mb-0 text-base">
          <>{!historyAstarParams?.isNfts && <>{historyAstarParams?.isPlus ? " + " : " - "}</>}</>
          {historyAstarParams?.value} {historyAstarParams?.nativeSymbol}
        </IonLabel>
      </IonItem>
    </div>
  );
};

export default ItemHistory;
