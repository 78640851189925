import { useAppSelector } from "hooks/useRedux";
import PersonalInformationMainMb from "features/settings/components/personalInformationMain/mb";
import PersonalInformationMainPc from "features/settings/components/personalInformationMain/pc";
import useTheme from "hooks/useTheme";

const PersonalInformationPage = () => {
  const { userInfo: user } = useAppSelector((state) => state.wallet);
  const { breakpoint } = useTheme();

  return breakpoint < 1024 ? <PersonalInformationMainMb user={user} /> : <PersonalInformationMainPc user={user} />;
};

export default PersonalInformationPage;
