import { useCallback, useEffect, useRef, useState } from "react";
import { createWeb3Wallet } from "shared/utils/walletconnect/WalletConnectUtil";
import { useAppSelector } from "./useRedux";

export default function useInitialization() {
  const [initialized, setInitialized] = useState(false);
  const prevRelayerURLValue = useRef<string>("");
  const { relayerRegionURL } = useAppSelector((state) => state.wallet);
  const onInitialize = useCallback(async () => {
    try {
      await createWeb3Wallet(relayerRegionURL);
      prevRelayerURLValue.current = relayerRegionURL;

      setInitialized(true);
    } catch (err: unknown) {
      console.log(err);
    }
  }, [relayerRegionURL]);

  const onRelayerRegionChange = useCallback(() => {
    // try {
    //   signClient.core.relayer.restartTransport(relayerRegionURL);
    //   prevRelayerURLValue.current = relayerRegionURL;
    // } catch (err: unknown) {
    //   console.log(err);
    // }
  }, [relayerRegionURL]);

  useEffect(() => {
    if (!initialized) {
      onInitialize();
    }
    if (prevRelayerURLValue.current !== relayerRegionURL) {
      onRelayerRegionChange();
    }
  }, [initialized, onInitialize, relayerRegionURL, onRelayerRegionChange]);

  return initialized;
}
