import QrScanner from "qr-scanner";
import { useEffect, useRef } from "react";

interface ScannerProps {
  onScanSuccess: (decodedText: any) => void;
  onScanError: (errorMessage: any) => void;
  platform: any;
}

const Scan = ({ onScanSuccess, onScanError, platform }: ScannerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  let qrScanner: any;

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: platform === "web" ? "user" : "environment" } })
        .then(function (stream) {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
            qrScanner = new QrScanner(videoRef.current, (result) => onScanSuccess(result), {
              maxScansPerSecond: 30,
              highlightScanRegion: true,
              highlightCodeOutline: true
            });
            qrScanner.setInversionMode("both");
            qrScanner.start();
          }
        })
        .catch(function (err: Error | string) {
          onScanError(typeof err === "string" ? err : err.message);
        });
    }
    return () => {
      // Cleanup
      if (qrScanner) {
        qrScanner.stop();
        qrScanner.destroy();
        qrScanner = null;
      }
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        const tracks = stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });

        videoRef.current.srcObject = null;
      }
    };
  }, []);

  /* eslint-disable-next-line jsx-a11y/media-has-caption */
  return <video id="qr-video" ref={videoRef}></video>;
};

export default Scan;
