// @ts-nocheck
/* eslint-disable no-inner-declarations */
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import Logo from "assets/images/darklogo.png";
import { t } from "i18next";
import { IonCheckbox, IonDatetime, IonModal } from "@ionic/react";
import { logout } from "shared/actions/walletAction";
import { Toaster } from "react-hot-toast";

const DataConsentPage: any = () => {
  const { consentConfigurations } = useAppSelector((state) => state.wallet);
  const {
    configuration: { directConfig }
  } = useAppSelector((state) => state.embedState);
  const appDispatch = useAppDispatch();
  const embedState = useAppSelector((state) => state.embedState);

  const [didDeployed] = useState(false);
  const [isLoading] = useState<boolean>(false);
  const [consentData, setConsentData] = useState<any>({});
  const [_, setCheckedField] = useState<any>({});
  const [isDeclineConsent, setIsDeclineConsent] = useState<boolean>();
  const [openDate, setOpenDate] = useState(false);
  const [dateNow, setDateNow] = useState("0000-00-00");

  const isEnglish =
    (embedState.embedTheme && embedState.embedTheme.lang && embedState.embedTheme?.lang === "en") ||
    embedState.embedTheme?.lang === "en-US";

  const getLogo = () => {
    if (embedState.embedTheme && embedState.embedTheme.isActive && embedState.embedTheme.logo && embedState.embedTheme.logo !== "") {
      return embedState.embedTheme.logo;
    }
    return Logo;
  };

  const setInputData = (e: any, name: string) => {
    if (e.target) {
      setConsentData((curr: any) => ({
        ...curr,
        [name]: e.target.value
      }));
    } else {
      setConsentData((curr: any) => ({
        ...curr,
        [name]: e
      }));
    }
  };

  const declineConsent = () => {
    setIsDeclineConsent(true);
    appDispatch(logout());
    window.location.href = directConfig.redirectUrl;
  };

  useEffect(() => {
    const dateNow = new Date();
    const month = dateNow.getMonth();
    const date = dateNow.getDate();
    const dateFormat = dateNow.getFullYear() + "-" + (month <= 9 ? "0" + month : month) + "-" + (date <= 9 ? "0" + date : date);
    setDateNow(dateFormat);
  }, []);

  const translateBasicScope = (scopeName: string) => {
    switch (scopeName) {
      case "email": {
        return t("consentsForm.email");
      }
      case "name": {
        return t("consentsForm.name");
      }
      case "birthday": {
        return t("consentsForm.birthday");
      }
      case "address": {
        return t("consentsForm.address");
      }
      default: {
        return scopeName;
      }
    }
  };

  useEffect(() => {
    consentConfigurations.scope.forEach((scopeName) => {
      const isRequired = consentConfigurations.requiredScope.includes(scopeName);
      setCheckedField((curr: any) => {
        return {
          ...curr,
          [scopeName]: isRequired
        };
      });
    });
  }, []);

  return (
    !isDeclineConsent && (
      <div className="scrollbar-hide w-full h-screen flex justify-center overflow-y-scroll overflow-x-hidden">
        <Toaster />
        <div className="scrollbar-hide w-full h-max max-w-500 p-5 mb-[350px]">
          <div className="w-full grid place-content-center mb-7.5">
            <img className="max-w-[280px]" src={getLogo()} alt={"logo"} />
          </div>
          <h1 className="font-roboto font-bold text-lg leading-[1.2] mt-5">{t("consentsForm.consentPopupMessage")}</h1>
          {!didDeployed
            ? consentConfigurations.scope.map((scopeName) => {
                const isRequired = consentConfigurations.requiredScope.includes(scopeName);
                return (
                  <span key={scopeName}>
                    <span className="block !w-full !h-0.5 bg-DCDCDC rounded-[50px] mt-4.5"></span>
                    <div className="flex items-center justify-between mb-1.25 mt-5">
                      <div className="flex items-center">
                        <h2 className="font-roboto non-italic font-semibold text-base leading-[1.2] m-0">
                          {scopeName && isEnglish
                            ? `${scopeName[0].toUpperCase()}${scopeName.slice(1, scopeName.length)}`
                            : `${translateBasicScope(scopeName)}`}
                        </h2>
                        {isRequired && (
                          <div className="h-6.25 bg-E4E4E4 text-upbondBlue rounded-2lg ml-2 py-0.5 px-2 flex items-center justify-center">
                            <h2 className="font-roboto font-semibold m-0 text-sm leading-[1.2]">{t("consentsForm.required")}</h2>
                          </div>
                        )}
                      </div>
                      <IonCheckbox
                        value={scopeName}
                        onIonChange={(e) =>
                          setCheckedField((curr: any) => {
                            return { ...curr, [scopeName]: isRequired ? true : e.target && e.target.checked };
                          })
                        }
                        name={scopeName}
                        checked={isRequired}
                        className="before:w-6 before:h-6 before:scale-0 before:transition-transform before:duration-120ms before:ease-in-out before:border-2 before:border-solid before:border-upbondBlue"
                      />
                    </div>
                    {scopeName.toLowerCase() === "birthday" ? (
                      <>
                        <input
                          placeholder={t(`consentsForm.${scopeName}`) || ""}
                          onClick={() => setOpenDate(!openDate)}
                          value={consentData[scopeName]}
                          className="flex flex-row items-center py-2.5 px-2 gap-2.5 w-full h-[39px] bg-F9F9F9 rounded-lg outline-none border-none flex-none order-1 grow-0"
                          style={{ color: isLoading ? "#cfcfcf" : "" }}
                          disabled={isLoading}
                        />
                        <IonModal
                          className="modal-consent-datepicker w-full my-0 mx-auto"
                          initialBreakpoint={0.4}
                          breakpoints={[0, 0.4, 0.5, 0.75]}
                          handleBehavior="cycle"
                          isOpen={openDate}>
                          <div className="w-full flex flex-col items-center">
                            <IonDatetime
                              locale={!isEnglish ? "ja" : "en"}
                              value={consentData[scopeName]}
                              prefer-wheel="true"
                              presentation="date"
                              max={dateNow}
                              onIonChange={(e) => {
                                if (typeof e.detail.value === "string") setInputData(e.detail.value.split("T")[0], scopeName);
                              }}
                            />
                            <button
                              className="w-[90%] py-3.75 px-5 rounded-[50px] bg-upbondBlue text-E4E4E4 font-bold text-base mt-2.5"
                              onClick={() => {
                                setOpenDate(!openDate);
                              }}>
                              {t("consentsForm.selectDate")}
                            </button>
                          </div>
                        </IonModal>
                      </>
                    ) : scopeName.toLowerCase() === "email" ? (
                      <input
                        onChange={(e) => {
                          setInputData(e, scopeName);
                        }}
                        type="email"
                        className="flex flex-row items-center py-2.5 px-2 gap-2.5 w-full h-[39px] bg-F9F9F9 rounded-lg outline-none border-none flex-none order-1 grow-0"
                        placeholder={t(`consentsForm.${scopeName}`) || ""}
                        value={consentData[scopeName]}
                        style={{ color: isLoading ? "#cfcfcf" : "" }}
                        disabled={isLoading}
                      />
                    ) : (
                      <input
                        onChange={(e) => {
                          setInputData(e, scopeName);
                        }}
                        type="text"
                        className="flex flex-row items-center py-2.5 px-2 gap-2.5 w-full h-[39px] bg-F9F9F9 rounded-lg outline-none border-none flex-none order-1 grow-0"
                        placeholder={t(`consentsForm.${scopeName}`) || ""}
                        value={consentData[scopeName]}
                        style={{ color: isLoading ? "#cfcfcf" : "" }}
                        disabled={isLoading}
                      />
                    )}
                  </span>
                );
              })
            : consentConfigurations.scope.map((scopeName: string) => {
                const isRequired = consentConfigurations.requiredScope.includes(scopeName);
                return (
                  <span key={scopeName}>
                    <span className="block !w-full !h-0.5 bg-DCDCDC rounded-[50px] mt-4.5"></span>
                    <div className="flex items-center justify-between mb-1.25 mt-5">
                      <div className="flex items-center">
                        <h2 className="font-roboto non-italic font-semibold text-base leading-[1.2] m-0">
                          {scopeName && isEnglish
                            ? `${scopeName[0].toUpperCase()}${scopeName.slice(1, scopeName.length)}`
                            : `${translateBasicScope(scopeName)}`}
                        </h2>
                        {isRequired && (
                          <div className="h-6.25 bg-E4E4E4 text-upbondBlue rounded-2lg ml-2 py-0.5 px-2 flex items-center justify-center">
                            <h2 className="font-roboto font-semibold m-0 text-sm leading-[1.2]">{t("consentsForm.required")}</h2>
                          </div>
                        )}
                      </div>
                      <IonCheckbox
                        className="before:w-6 before:h-6 before:scale-0 before:transition-transform before:duration-120ms before:ease-in-out before:border-2 before:border-solid before:border-upbondBlue"
                        value={consentData[scopeName]}
                        onIonChange={(e) =>
                          setCheckedField((curr: any) => {
                            return { ...curr, [scopeName]: isRequired ? true : e.target && e.target.checked };
                          })
                        }
                        name={scopeName}
                        checked={isRequired}
                      />
                    </div>
                    {scopeName.toLowerCase() === "birthday" ? (
                      <>
                        <input
                          placeholder={t(`consentsForm.${scopeName}`) || ""}
                          onClick={() => setOpenDate(!openDate)}
                          value={consentData[scopeName]}
                          className="flex flex-row items-center py-2.5 px-2 gap-2.5 w-full h-[39px] bg-F9F9F9 rounded-lg outline-none border-none flex-none order-1 grow-0"
                          style={{ color: isLoading ? "#cfcfcf" : "" }}
                          disabled={isLoading}
                        />
                        <IonModal
                          className="modal-consent-datepicker w-full my-0 mx-auto"
                          initialBreakpoint={0.4}
                          breakpoints={[0, 0.4, 0.5, 0.75]}
                          handleBehavior="cycle"
                          isOpen={openDate}>
                          <div className="w-full flex flex-col items-center">
                            <IonDatetime
                              locale={!isEnglish ? "ja" : "en"}
                              value={consentData[scopeName]}
                              prefer-wheel="true"
                              presentation="date"
                              max={dateNow}
                              onIonChange={(e) => {
                                if (typeof e.detail.value === "string") setInputData(e.detail.value.split("T")[0], scopeName);
                              }}
                            />
                            <button
                              onClick={() => {
                                setOpenDate(!openDate);
                              }}>
                              {t("consentsForm.selectDate")}
                            </button>
                          </div>
                        </IonModal>
                      </>
                    ) : (
                      <input
                        onChange={(e) => {
                          setInputData(e, scopeName);
                        }}
                        type="text"
                        className="flex flex-row items-center py-2.5 px-2 gap-2.5 w-full h-[39px] bg-F9F9F9 rounded-lg outline-none border-none flex-none order-1 grow-0"
                        placeholder={t(`consentsForm.${scopeName}`) || ""}
                        value={consentData[scopeName]}
                        style={{ color: isLoading ? "#cfcfcf" : "" }}
                        disabled={isLoading}
                      />
                    )}
                  </span>
                );
              })}
        </div>
        <div className="fixed left-0 bottom-0 w-full py-3.5 px-5 bg-white z-[999] flex justify-center items-center flex-col">
          <button
            style={{ color: isLoading ? "#c4caca" : "", backgroundColor: isLoading ? "#f9f9fa" : "" }}
            disabled={isLoading}
            className="max-w-[420px] flex flex-row justify-center items-center py-2 px-4 gap-1 w-full h-12 bg-upbondBlue rounded-[100px] font-inter not-italic font-semibold text-base leading-4.75 text-white">
            {isLoading ? (
              <div className="flex items-center justify-center">
                <svg
                  className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#4B68AE]"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only text-black">Loading...</span>
              </div>
            ) : (
              t("consentsForm.agreeAndLogin")
            )}
          </button>
          <button
            style={{ color: isLoading ? "#8594b1" : "" }}
            disabled={isLoading}
            className="max-w-[420px] mt-2.5 flex flex-row justify-center items-center py-2 px-2.5 gap-1 w-full h-12 bg-none font-roboto not-italic font-semibold text-base leading-[140%] text-upbondBlue"
            onClick={declineConsent}>
            {t("consentsForm.cancel")}
          </button>
          <p className="!font-poppins font-normal text-sm my-4.5">Supported by UPBOND</p>
        </div>
      </div>
    )
  );
};

export default DataConsentPage;
