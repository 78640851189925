// @ts-nocheck
import { IonButton, IonContent, IonList, IonModal, IonPage } from "@ionic/react";
import { IMenu } from "interfaces/ui";
import { useTranslation } from "react-i18next";

const DisconnectModal = (props: IMenu) => {
  const { openModal, closeModal, disconnected, disconnectFromDaaps } = props;
  const { t } = useTranslation();
  return (
    <IonPage>
      <IonContent className="ion-padding">
        <IonModal className="disconnect-modal text-center" isOpen={openModal || disconnected} trigger="open-modal">
          <div className="font-roboto">
            <h1 className="text-base font-semibold my-4">
              {disconnected === true ? t("settings.connectionFailed") : t("settings.wouldYouLike")}
            </h1>
            <p className="font-normal text-sm leading-4 my-4">{t("settings.wouldYouLikeExplain")}</p>
            <IonList className="disconnect-modal-button-list flex justify-center gap-4" lines="none">
              {disconnected === false ? (
                <IonButton
                  className="disconnect-modal-cancel font-normal text-[#4d4082] text-[15px] leading-[18px] w-[126px] h-9 mt-0 mr-0 mb-4 ml-0"
                  onClick={closeModal}>
                  {t("settings.cancel")}
                </IonButton>
              ) : null}
              <IonButton
                onClick={() => {
                  disconnectFromDaaps();
                  closeModal();
                }}
                className={`${
                  disconnected === true
                    ? "disconnect-modal-cancel font-normal text-[15px] leading-[18px] text-[#4d4082] w-[126px] h-9 mt-0 mr-16 mb-4 ml-0"
                    : "disconnect-modal-dis font-normal text-[15px] leading-[18px] w-[126px] h-9 mt-0 mr-0 mb-4 ml-0"
                }`}>
                {disconnected === true ? t("settings.close") : t("settings.disconnect")}
              </IonButton>
            </IonList>
          </div>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default DisconnectModal;
