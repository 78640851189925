// @ts-nocheck
import { IonAvatar, IonButton, IonContent, IonIcon, IonImg, IonItem, IonLabel, IonModal } from "@ionic/react";
import { ConfirmNftPopupProps } from "features/transfer/confirmNft/types";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks/useRedux";
import { chevronDown } from "ionicons/icons";

const ConfirmNftPopupMb = (props: ConfirmNftPopupProps) => {
  const { t } = useTranslation();
  const transfer = useAppSelector((state) => state.transfer);
  const contact = useAppSelector((state) => state.contact);

  return (
    <IonModal
      className="modal-confirm-done"
      isOpen={props.openConfirm}
      onDidDismiss={() => {
        props.setOpenConfirm(false);
      }}
      initialBreakpoint={window.innerHeight < 600 ? 0.75 : window.innerHeight < 700 ? 0.65 : 0.6}
      breakpoints={[0, window.innerHeight < 600 ? 0.75 : window.innerHeight < 700 ? 0.65 : 0.6]}>
      <IonContent className="ion-padding">
        <IonItem className="shadow-itemListShadow rounded-2xl px-4 mb-4" lines="none">
          <IonLabel className="ps-4">
            <h1 className="!text-base font-semibold font-inter">{transfer.detail?.contract_name}</h1>
            <p className="!text-sm !text-black font-bold font-poppins">
              {props.amountToSend} {transfer.detail?.contract_ticker_symbol ?? "NFT"}
            </p>
          </IonLabel>
          <IonAvatar>
            {transfer.detail &&
              transfer.detail.nft_data &&
              transfer.detail.nft_data.map((ele, idx) => {
                if (idx === 0) {
                  return (
                    <IonImg
                      key={idx}
                      className="border border-black"
                      alt="nftLogo"
                      src={ele["external_data"]?.["image"] ?? window.location.origin + "/assets/noimg.png"}
                    />
                  );
                }
              })}
          </IonAvatar>
        </IonItem>
        <IonItem lines="none">
          <IonLabel className="font-bold ps-4">{t("transfer.to")}</IonLabel>
          <IonIcon icon={chevronDown} color="dark" />
        </IonItem>
        <IonItem className="shadow-itemListShadow rounded-2xl px-4 mb-4" lines="none">
          <IonLabel className="font-bold ps-4">{contact.selectedContact?.name}</IonLabel>
        </IonItem>

        <IonButton
          shape="round"
          color="primary"
          expand="full"
          onClick={() => {
            props.confirmTransaction();
          }}>
          {t("transfer.confirmTransfer")}
        </IonButton>
      </IonContent>
    </IonModal>
  );
};

export default ConfirmNftPopupMb;
