// @ts-nocheck
import { IonButton, IonList, IonModal, useIonLoading, useIonRouter } from "@ionic/react";
import ERC1155 from "abis/erc1155.json";
import ERC721 from "abis/erc721.json";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import useWallet from "hooks/useWallet";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchTransferAmount } from "shared/actions/transferAction";
import { fromGweiToWeiHex } from "shared/utils/etherUtils";
import ConfirmNftMainMb from "features/transfer/confirmNft/components/ConfirmNftMain/mb";
import ConfirmNftMainPc from "features/transfer/confirmNft/components/ConfirmNftMain/pc";
import ConfirmNftPopupMb from "features/transfer/confirmNft/components/ConfirmNftPopup/mb";
import ConfirmNftPopupPc from "features/transfer/confirmNft/components/ConfirmNftPopup/pc";

const ConfirmTransferNftPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useIonRouter();
  const transfer = useAppSelector((state) => state.transfer);
  const gas = useAppSelector((state) => state.gas);
  const { breakpoint } = useTheme();
  const { loading: isLoading, selectedAddress: accounts } = useAppSelector((state) => state.wallet);
  const { contractSend, changeEthProvider } = useWallet(
    {
      onSuccess() {
        console.log(`success get wallet`);
      },
      onError(error) {
        console.error(`error while get wallet:`, error);
      }
    },
    false
  );
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [amountToSend, setAmountToSend] = useState<string>("1");
  const [_totalSend, setTotalSend] = useState<number>(0);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [checkType, setCheckType] = useState<number>(0);
  const [present, dismiss] = useIonLoading();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") > -1;
  const amountChanged = (e: any) => {
    let val = e.target.value;
    if (val.charAt(0) === "0") {
      val = val.replace("0", "1");
    }

    if (isAndroid) {
      val = val.replace(/[^0-9]/g, "");
    }

    const setTotal = parseFloat(val) + parseFloat(gas.totalFee);
    setAmountToSend(val);
    setTotalSend(setTotal);
  };

  const proceedTransfer = () => {
    setOpenConfirm(!openConfirm);
    dispatch(fetchTransferAmount(amountToSend.toString()));
  };

  const confirmTransaction = async () => {
    try {
      if (!transfer.selectedNft) return;

      present({
        message: t("transfer.loading"),
        cssClass: "custom-loading"
      });

      setOpenConfirm(!openConfirm);
      const to = transfer.toAddress?.trim() || "";
      const from = accounts;
      const tokenId = transfer.selectedNft.token_id;
      const contract = transfer.detail?.contract_address;
      const maxFeePerGas = fromGweiToWeiHex(gas.maxBaseFee.toString());
      const maxPriorityFeePerGas = fromGweiToWeiHex(transfer.gas?.estimate.maxPriorityFeePerGas?.toString() || "0");
      const tx =
        checkType > 0
          ? await contractSend({
              contractAddress: contract || "",
              method: "safeTransferFrom",
              abi: ERC1155.abi,
              option: { maxFeePerGas, maxPriorityFeePerGas },
              param: [from, to, tokenId, amountToSend, "0x00"]
            })
          : await contractSend({
              contractAddress: contract || "",
              method: "transferFrom",
              abi: ERC721.abi,
              option: { maxFeePerGas, maxPriorityFeePerGas },
              param: [from, to, tokenId]
            });
      if (tx) {
        dismiss();
        setOpenConfirm(false);
        breakpoint < 1024 ? history.push(`/transfer/success`, "forward", "push") : history.push(`/wallet/tf/success`, "forward", "push");
      }
    } catch (error: any) {
      dismiss();
      if (error.code === "UNPREDICTABLE_GAS_LIMIT" && error.reason === "execution reverted: ERC1155: insufficient balance for transfer")
        setErrorMessage("INSUFFICIENT_FUNDS");
      else {
        setErrorMessage(error.code);
      }
      setOpenErrorModal(true);
    }
  };

  const changeChain = async () => {
    if (transfer.detail) await changeEthProvider(transfer.detail.chain_id);
  };

  useEffect(() => {
    transfer.detail?.chain_id && changeChain();
  }, [transfer.detail?.chain_id]);

  useEffect(() => {
    !isLoading && setIsConfirmDisabled(false);
  }, [isLoading]);

  useEffect(() => {
    if (transfer.selectedNft) {
      const checkType = transfer.selectedNft.supports_erc?.filter((item: string) => {
        if (item === "erc1155") return item;
      });
      setCheckType(checkType?.length);
    } else {
      history.push("/wallet/transfer", "forward", "push");
    }
  }, [transfer.selectedNft]);

  return (
    <>
      {breakpoint < 1024 ? (
        <ConfirmNftMainMb
          checkType={checkType}
          amountToSend={amountToSend}
          amountChanged={amountChanged}
          isConfirmDisabled={isConfirmDisabled}
          proceedTransfer={proceedTransfer}
        />
      ) : (
        <ConfirmNftMainPc
          checkType={checkType}
          amountToSend={amountToSend}
          amountChanged={amountChanged}
          isConfirmDisabled={isConfirmDisabled}
          proceedTransfer={proceedTransfer}
        />
      )}
      {breakpoint < 1024 ? (
        <ConfirmNftPopupMb
          setOpenConfirm={setOpenConfirm}
          openConfirm={openConfirm}
          amountToSend={amountToSend}
          confirmTransaction={confirmTransaction}
        />
      ) : (
        <ConfirmNftPopupPc
          setOpenConfirm={setOpenConfirm}
          openConfirm={openConfirm}
          amountToSend={amountToSend}
          confirmTransaction={confirmTransaction}
        />
      )}

      <IonModal id="modal-error" trigger="open-modal" isOpen={openErrorModal}>
        <div className="p-4 text-center">
          <IonList lines="none">{t("transfer.pleaseTryAgain")}</IonList>

          <IonList lines="none" className="text p-4">
            {t(`transfer.${errorMessage}`)}
          </IonList>
          <IonButton
            shape="round"
            color="primary"
            expand="full"
            onClick={async () => {
              setOpenErrorModal(false);
              await setTimeout(() => console.log(), 1000);
              if (errorMessage === "INVALID_ARGUMENT") history.push("/wallet/transfer", "forward", "push");
            }}>
            {t("account.ok")}
          </IonButton>
        </div>
      </IonModal>
    </>
  );
};

export default ConfirmTransferNftPage;
