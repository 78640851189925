import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Contact, IContacts } from "../../interfaces/actions/IContact";

const initContact: IContacts = {
  contacts: JSON.parse(localStorage.getItem("contacts") || "[]"),
  selectedContact: localStorage.getItem("selectedContact")
    ? JSON.parse(localStorage.getItem("selectedContact") as string)
    : {
        avatar: "",
        address: "",
        name: ""
      }
};
const contactSlice = createSlice({
  name: "contact",
  initialState: initContact,
  reducers: {
    addContact(state, action: PayloadAction<Contact[]>) {
      localStorage.setItem("contacts", JSON.stringify(action.payload));
      state.contacts = action.payload;
    },
    addSelectedContact(state, action: PayloadAction<Contact>) {
      localStorage.setItem("selectedContact", JSON.stringify(action.payload));
      state.selectedContact = action.payload;
    }
  }
});

export default contactSlice;
