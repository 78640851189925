import { get } from "@toruslabs/http-helpers";
import { AstarDefault } from "assets/icons";

export { get, patch, post, remove } from "@toruslabs/http-helpers";

export const promiseRace = (url: string, options?: RequestInit, timeout?: number) => {
  return Promise.race([
    get(url, options),
    new Promise((resolve, reject) => {
      const id = setTimeout(() => {
        clearTimeout(id);
        reject(new Error("timeout"));
      }, timeout);
    })
  ]);
};

export const reloadSrc = (e: any, chain_id: string) => {
  chain_id === "1" || chain_id === "11155111"
    ? (e.target.src = "https://etherscan.io/images/main/empty-token.png")
    : chain_id === "137" || chain_id === "80002"
    ? (e.target.src = "https://polygonscan.com/images/main/empty-token.png")
    : (e.target.src = AstarDefault);
};

export const defaultLogo = (chain_id: string): string => {
  const logo =
    chain_id === "1" || chain_id === "11155111"
      ? "https://etherscan.io/images/main/empty-token.png"
      : chain_id === "137" || chain_id === "80002"
      ? "https://polygonscan.com/images/main/empty-token.png"
      : AstarDefault;
  return logo;
};
