import Torus from "@toruslabs/torus.js";
import { isMain } from "shared/utils/coreUtils";
import UpbondController, { UpbondControllerOptions } from "controllers/UpbondController";
import NodeDetailManager from "@toruslabs/fetch-node-details";
import { ObjectMultiplex } from "@toruslabs/openlogin-jrpc";
import { showPopup } from "shared/actions/embedAction";
import { store } from "shared/store";
import Web3 from "web3";
import { preloadCommunicationMux } from "helpers/muxHelper";

function triggerUI(type: string, payload: { [x: string]: any }, request: any) {
  store.dispatch(showPopup({ payload, request }));
}

export type UpbondExtended = {
  upbondController?: UpbondController;
  nodeDetailManager?: NodeDetailManager;
  metamaskMux?: ObjectMultiplex;
  communicationMux?: ObjectMultiplex;
  instanceId?: string;
  web3?: Web3;
};

export function onloadHandler<T>(lib: T & Torus & UpbondExtended) {
  const defaultSelectedNetwork = store.getState().wallet.networkType;
  const upbondController = new UpbondController({
    initState: {
      NetworkController: {
        provider: defaultSelectedNetwork
      }
    },
    showUnconfirmedMessage: triggerUI.bind(window, "showUnconfirmedMessage"),
    unlockAccountMessage: triggerUI.bind(window, "unlockAccountMessage"),
    showUnapprovedTx: triggerUI.bind(window, "showUnapprovedTx"),
    openPopup: triggerUI.bind(window, "bindopenPopup"),
    storeProps: () => {
      const { wallet } = store.getState();
      return {
        selectedAddress: wallet.selectedAddress,
        wallet: wallet.wallet
      };
    }
  } as unknown as UpbondControllerOptions);

  lib.upbondController = upbondController;
  upbondController?.provider?.setMaxListeners(100);
  lib.web3 = new Web3(upbondController.provider);
  lib.nodeDetailManager = new NodeDetailManager({ network: "ropsten", proxyAddress: "0x6258c9d6c12ed3edda59a1a6527e469517744aa7" });

  upbondController.setupControllerConnection();

  if (isMain) return lib;
  const mux = preloadCommunicationMux();

  lib.metamaskMux = mux.metamaskMux;
  lib.communicationMux = mux.communicationMux;
  lib.communicationMux.setMaxListeners(50);

  const providerOutStream = lib.metamaskMux.getStream("provider");
  upbondController.setupProviderConnection(providerOutStream, mux.iframe);
  const publicConfOutStream = lib.metamaskMux.getStream("publicConfig");
  upbondController.setupPublicConfig(publicConfOutStream);

  return lib;
}
