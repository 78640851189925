import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { IChains } from "interfaces/actions/IToken";
import { listChain } from "shared/config/chains";
import { RootState } from "shared/store";
import historyChainSlice from "shared/store/historyChain.slice";

export const historyChainAction = historyChainSlice.actions;

export const fetchSelectedHistoryChain =
  (item: string | number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, _getState) => {
    const historyChain = listChain?.find((thisCurrency: IChains) => thisCurrency?.chain === item);
    if (historyChain) {
      localStorage.setItem("chain", JSON.stringify(historyChain));
      dispatch(historyChainAction.setChain(historyChain));
    }
  };
