import { setupIonicReact } from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import reportWebVitals from "reportWebVitals";
import { store } from "shared/store";
import { setupLogs } from "shared/utils/loglevelUtils";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { TKeyProvider } from "hooks/useCustomAuth";
import React from "react";
import { BrowserTracing } from "@sentry/browser";
import { initializeSentry } from "@upbond/upbond-gas-oracle/lib/services";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [
    new BrowserTracing(),
    new CaptureConsole({
      levels: ["error"]
    })
  ],
  attachStacktrace: true,
  beforeSend: (ev) => {
    if (window.location.hostname === "localhost") {
      return null;
    }
    return ev;
  }
});

initializeSentry(process.env.REACT_APP_SENTRY_DSN); // Initialize Sentry before importing other modules from the GasPriceOracle package

setupIonicReact({
  rippleEffect: false,
  mode: "ios",
  backButtonIcon: chevronBackOutline,
  animated: false
});
setupLogs();

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <TKeyProvider chain={process.env.REACT_APP_CHAIN as string}>
      <App />
    </TKeyProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
