/* eslint-disable @typescript-eslint/ban-ts-comment */
import createFilterMiddleware from "eth-json-rpc-filters";
import createSubscriptionManager from "eth-json-rpc-filters/subscriptionManager";
import { debounce } from "lodash";
import { createEngineStream, JRPCEngine, SafeEventEmitter, Substream } from "@toruslabs/openlogin-jrpc";
import ComposableObservableStore from "libs/ComposableObservableStore";
import createMethodMiddleware from "middlewares/createMethodMiddleware";
import createLoggerMiddleware from "middlewares/createLoggerMiddleware";
import { createOriginMiddleware } from "middlewares/createOriginMiddleware";
import log from "loglevel";
import NetworkController from "controllers/NetworkController";
import pump from "pump";
import { ObservableStore, storeAsStream } from "@metamask/obs-store";
import KeyringController from "./KeyringController";
import AccountTracker from "./AccountTracker";
import { MAINNET_CHAIN_ID, NOTIFICATION_NAMES, TRANSACTION_STATUSES } from "shared/enums";
import PreferencesController from "./PreferencesController";
import { RootState } from "shared/store";
import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { catchError, createRandomId } from "shared/utils/coreUtils";
import EncryptionPublicKeyManager from "./EncryptionPublicKeyManager";
import DecryptMessageManager from "./DecryptMessageManager";
import { providerAsMiddleware } from "eth-json-rpc-middleware";
import TypedMessageManager from "./TypedMessageManager";
import PersonalMessageManager from "./PersonalMessageManager";
import MessageManager from "./MessageManager";
import TransactionController from "./transactions/TransactionController";
import GasFeeController from "./gas/GasFeeController";
import CurrencyController from "./CurrencyController";
import TokenRatesController from "./TokenRatesController";
import DetectTokensController from "./DetectTokensController";
import { stripHexPrefix, toChecksumAddress } from "ethereumjs-util";
import { normalize } from "@metamask/eth-sig-util";
import { NetworkType } from "shared/actions/walletAction";
import { hexToUtf8 } from "web3-utils";

SafeEventEmitter.defaultMaxListeners = 100;

type TA<T = void, S = RootState, E = unknown> = ThunkAction<T, S, E, AnyAction>;

export type UpbondControllerOptions = {
  initState: {
    NetworkController: {
      provider: NetworkType;
    };
  };
  showUnconfirmedMessage?: any;
  unlockAccountMessage?: any;
  showUnapprovedTx?: any;
  openPopup?: any;
  storeProps: {
    selectedAddress: string[] | string;
    wallet: { [x: string]: any };
  };
  storeDispatch: TA;
  rehydrate?: () => void;
};

export default class UpbondController extends SafeEventEmitter {
  engine: any;
  permissionsController: any;
  keyringController: KeyringController;
  prefsController: PreferencesController;
  defaultMaxListeners: number;
  sendUpdate: any;
  memStore: ComposableObservableStore;
  activeControllerConnections: any;
  opts: any;
  store: any;
  networkController: NetworkController;
  provider: any;
  publicConfigStore: ObservableStore<string>;
  accountTracker: AccountTracker;
  blockTracker: any;
  isClientOpenAndUnlocked: any;
  encryptionPublicKeyManager: EncryptionPublicKeyManager;
  decryptMessageManager: DecryptMessageManager;
  typedMessageManager: TypedMessageManager;
  personalMessageManager: PersonalMessageManager;
  messageManager: MessageManager;
  txController: TransactionController;
  platform: any;
  gasFeeController: GasFeeController;
  currencyController: CurrencyController;
  detectTokensController: DetectTokensController;
  tokenRatesController: TokenRatesController;
  updateAndApproveTransaction: (txMeta: any) => Promise<void>;
  cancelTransaction: (txId: number) => Promise<void>;
  listener: any;

  /**
   * @constructor
   * @param {Object} opts
   */
  constructor(options: UpbondControllerOptions) {
    super();
    this.defaultMaxListeners = 100;
    this.listener = null;
    this.sendUpdate = debounce(this.privateSendUpdate.bind(this), 200);
    this.opts = options;

    this.store = new ComposableObservableStore();
    this.networkController = new NetworkController({
      provider: options?.initState.NetworkController.provider
    });

    this.activeControllerConnections = 0;
    this.initializeProvider();

    this.provider = this.networkController.getProviderAndBlockTracker()?.provider;
    this.blockTracker = this.networkController.getProviderAndBlockTracker()?.blockTracker;

    if (this.provider) {
      (this.provider as SafeEventEmitter).on("error", (err_) => {
        console.error("[error]: rpc error", err_);
      });
    }

    this.gasFeeController = new GasFeeController({
      interval: 15_000,
      getProvider: () => this.networkController.getProviderAndBlockTracker().provider,
      getCurrentNetworkEIP1559Compatibility: this.networkController.getEIP1559Compatibility.bind(this.networkController),
      getCurrentAccountEIP1559Compatibility: this.getCurrentAccountEIP1559Compatibility.bind(this),
      // legacyAPIEndpoint: 'https://gas-api.metaswap.codefi.network/networks/<chain_id>/gasPrices',
      // EIP1559APIEndpoint: 'https://gas-api.metaswap.codefi.network/networks/<chain_id>/suggestedGasFees',
      getCurrentNetworkLegacyGasAPICompatibility: () => {
        const chainId = this.networkController.getCurrentChainId();
        return chainId === MAINNET_CHAIN_ID;
      },
      getChainId: () => this.networkController.getCurrentChainId()
    });

    this.currencyController = new CurrencyController({
      initState: {}
    });

    this.accountTracker = new AccountTracker({
      provider: this.provider,
      blockTracker: this.blockTracker,
      getCurrentChainId: this.networkController.getCurrentChainId.bind(this.networkController)
    });

    this.on("controllerConnectionChanged", (activeControllerConnections) => {
      if (activeControllerConnections > 0) {
        this.accountTracker.start();
      } else {
        this.accountTracker.stop();
      }
    });

    this.on("error", (error) => {
      console.error(`[error]: provider error`, error);
    });

    this.keyringController = new KeyringController();

    this.prefsController = new PreferencesController({
      network: this.networkController,
      provider: this.provider,
      signMessage: this.keyringController.signMessage.bind(this.keyringController)
    } as any);

    this.txController = new TransactionController({
      getProviderConfig: this.networkController.getProviderConfig.bind(this.networkController),
      getCurrentNetworkEIP1559Compatibility: this.networkController.getEIP1559Compatibility.bind(this.networkController),
      getCurrentAccountEIP1559Compatibility: this.getCurrentAccountEIP1559Compatibility.bind(this),
      networkStore: this.networkController.networkStore,
      getCurrentChainId: this.networkController.getCurrentChainId.bind(this.networkController),
      preferencesStore: this.prefsController.store,
      txHistoryLimit: 40,
      // signs ethTx
      signTransaction: this.keyringController.signTransaction.bind(this.keyringController),
      provider: this.provider,
      blockTracker: this.blockTracker,
      getEIP1559GasFeeEstimates: this.gasFeeController.fetchGasFeeEstimates.bind(this.gasFeeController)
    });
    this.txController.on("newUnapprovedTx", (txMeta, request) => options?.showUnapprovedTx(txMeta, request));

    this.txController.on("tx:status-update", (txId, status) => {
      if (status === TRANSACTION_STATUSES.CONFIRMED || status === TRANSACTION_STATUSES.FAILED) {
        const txMeta = this.txController.txStateManager.getTransaction(txId);
        if (this.platform) {
          this.platform.showTransactionNotification(txMeta); // TODO: implement platform specific handlers
        }
      }
    });

    this.networkController.lookupNetwork();
    this.messageManager = new MessageManager();
    this.personalMessageManager = new PersonalMessageManager();
    this.typedMessageManager = new TypedMessageManager({
      getCurrentChainId: this.networkController.getCurrentChainId.bind(this.networkController)
    });
    this.encryptionPublicKeyManager = new EncryptionPublicKeyManager();
    this.decryptMessageManager = new DecryptMessageManager();

    this.store.updateStructure({
      NetworkController: this.networkController.store,
      PreferencesController: this.prefsController.store
    });

    this.detectTokensController = new DetectTokensController({
      network: this.networkController,
      provider: this.provider,
      preferencesStore: this.prefsController.store
    });

    this.tokenRatesController = new TokenRatesController({
      getChainId: this.networkController.getCurrentChainId.bind(this.networkController),
      currency: this.currencyController.store,
      tokensStore: this.detectTokensController.detectedTokensStore
    });

    this.on("update", (memState) => this._onStateUpdate(memState));

    this.memStore = new ComposableObservableStore(null, {
      NetworkController: this.networkController.store,
      AccountTracker: this.accountTracker.store,
      TxController: this.txController.memStore,
      DecryptMessageManager: this.decryptMessageManager.store,
      EncryptionPublicKeyManager: this.encryptionPublicKeyManager.store,
      PreferencesController: this.prefsController.store,
      PersonalMessageManager: this.personalMessageManager.store,
      TypesMessageManager: this.typedMessageManager.store,
      TokenRatesController: this.tokenRatesController.store
    });

    this.memStore.subscribe(this.sendUpdate.bind(this));
    this.engine = null;

    this.publicConfigStore = this.initPublicConfigStore();

    this.prefsController.on("addEtherscanTransactions", (txs, network) => {
      this.txController.addEtherscanTransactions(txs, network);
    });

    if (options?.rehydrate && typeof options?.rehydrate === "function") {
      setTimeout(() => {
        // @ts-ignore
        options.rehydrate();
      }, 500);
    }

    this.networkController.on("networkDidChange", () => {
      this.accountTracker._updateAccounts();
      this.prefsController.recalculatePastTx();
      this.prefsController.refetchEtherscanTx();
    });

    this.updateAndApproveTransaction = this.txController.updateAndApproveTransaction.bind(this.txController);
    this.cancelTransaction = this.txController.cancelTransaction.bind(this.txController);
    this.engine = null;
  }

  initializeProvider() {
    const providerOptions = {
      static: {
        eth_syncing: false,
        web3_clientVersion: `Torus/v2`
      },
      version: `v2`,
      getAccounts: () =>
        this.prefsController.store.getState().selectedAddress ? [this.prefsController.store.getState().selectedAddress] : [],
      processTransaction: this.newUnapprovedTransaction.bind(this),
      processEthSignMessage: this.newUnsignedMessage.bind(this),
      processTypedMessage: this.newUnsignedTypedMessage.bind(this),
      processTypedMessageV3: this.newUnsignedTypedMessage.bind(this),
      processTypedMessageV4: this.newUnsignedTypedMessage.bind(this),
      processPersonalMessage: this.newUnsignedPersonalMessage.bind(this),
      getPendingNonce: this.getPendingNonce.bind(this),
      getPendingTransactionByHash: (hash: any) => {
        if (this.txController) {
          return (this.txController as TransactionController).getTransactions({
            searchCriteria: {
              hash,
              status: TRANSACTION_STATUSES.SUBMITTED
            }
          })[0] as any;
        }
      },
      processEncryptionPublicKey: this.newUnsignedEncryptionPublicKey.bind(this),
      processDecryptMessage: this.newUnsignedDecryptMessage.bind(this)
    };
    const providerProxy = this.networkController.initializeProvider(providerOptions);
    return providerProxy;
  }

  newUnsignedDecryptMessage(messageParameters: any, request: any) {
    const messageId = createRandomId();
    const promise = this.decryptMessageManager.addUnapprovedMessageAsync(messageParameters, request, messageId);
    this.sendUpdate();
    this.opts.showUnconfirmedMessage(messageId, request);
    return promise;
  }

  async initKeyring(keyArray: any[], addresses: string[]) {
    if (addresses.length > 0) {
      this.setSelectedAddress(addresses[0]);
    }
    await Promise.all([this.keyringController.deserialize(keyArray), this.accountTracker.syncWithAddresses(addresses)]);
  }

  unlock() {
    if (this.prefsController.store.getState().selectedAddress) {
      this.notifyAllConnections({
        method: NOTIFICATION_NAMES.unlockStateChanged,
        params: {
          isUnlocked: true,
          accounts: [this.prefsController.store.getState().selectedAddress]
        }
      });
    }
  }

  setSelectedAddress(address: string) {
    this.prefsController.setSelectedAddress(address);
    this.unlock();
  }

  newUnsignedEncryptionPublicKey(messageParameters: any, request: any) {
    const messageId = createRandomId();
    const promise = this.encryptionPublicKeyManager.addUnapprovedMessageAsync(messageParameters, request, messageId);
    this.sendUpdate();
    this.opts.showUnconfirmedMessage(messageId, request);
    return promise;
  }

  newUnsignedTypedMessage(messageParameters: any, request: any, messageVersion: any) {
    const messageId = createRandomId();
    const promise = this.typedMessageManager.addUnapprovedMessageAsync(messageParameters, request, messageVersion, messageId);
    this.sendUpdate();
    this.opts.showUnconfirmedMessage(messageId, request);
    return promise;
  }

  async getPendingNonce(address: any) {
    const { nonceDetails, releaseLock } = await this.txController.nonceTracker.getNonceLock(address);
    const pendingNonce = nonceDetails.params.highestSuggested;

    releaseLock();
    return pendingNonce;
  }

  async getNextNonce(address: any) {
    const nonceLock = await this.txController.nonceTracker.getNonceLock(address);
    nonceLock.releaseLock();
    return nonceLock.nextNonce;
  }

  newUnsignedPersonalMessage(messageParameters: any, request: any) {
    const messageId = createRandomId();
    const promise = this.personalMessageManager.addUnapprovedMessageAsync(messageParameters, request, messageId);
    this.sendUpdate();
    this.opts.showUnconfirmedMessage(messageId, request);
    return promise;
  }

  newUnsignedMessage(messageParameters: any, request: any) {
    const messageId = createRandomId();
    const promise = this.messageManager.addUnapprovedMessageAsync(messageParameters, request, messageId);
    this.sendUpdate();
    this.opts.showUnconfirmedMessage(messageId, request);
    return promise;
  }

  async newUnapprovedTransaction(txParameters: any, request: any) {
    return this.txController.newUnapprovedTransaction(txParameters, request);
  }

  _onStateUpdate(newState: any) {
    this.isClientOpenAndUnlocked = newState.isUnlocked;
    this.notifyAllConnections({
      method: NOTIFICATION_NAMES.chainChanged,
      params: this.getProviderNetworkState(newState)
    });
  }

  notifyAllConnections(payload: any) {
    const getPayload = () => payload;
    if (this.engine) {
      this.engine.emit("notification", getPayload());
    }
  }

  initPublicConfigStore() {
    // get init state
    // setting stringified state  to keep it compatible with old versions of torus-embed
    const publicConfigStore = new ObservableStore("{}");

    const { networkController } = this;

    // setup memStore subscription hooks
    this.on("update", updatePublicConfigStore);
    updatePublicConfigStore(this.getState());

    function updatePublicConfigStore(memState: any) {
      const chainId = networkController.getCurrentChainId();
      if (memState.network !== "loading") {
        publicConfigStore.putState(selectPublicState(chainId, memState));
      }
    }

    function selectPublicState(
      chainId: number | string,
      {
        isUnlocked,
        network,
        selectedAddress
      }: {
        isUnlocked: boolean;
        network: string;
        selectedAddress: string;
      }
    ) {
      return JSON.stringify({
        isUnlocked,
        chainId,
        networkVersion: network,
        selectedAddress
      });
    }

    return publicConfigStore;
  }

  setupTrustedCommunication(outStream: any, originDomain: any) {
    // connect features
    this.setupControllerConnection();
    // to fix test cases
    this.setupProviderConnection(outStream, originDomain);
  }

  setupPublicConfig(outStream: symbol | Substream) {
    const configStream = storeAsStream(this.publicConfigStore);
    pump(configStream, outStream as any, (error) => {
      configStream.destroy();
      if (error) log.error(error);
    });
  }

  setupControllerConnection() {
    this.activeControllerConnections += 1;
    this.emit("controllerConnectionChanged", this.activeControllerConnections);
  }

  setupProviderConnection(outStream: any, sender: any) {
    // break violently
    const senderUrl = new URL(sender);
    const engine = this.setupProviderEngine({ origin: senderUrl.hostname, location: sender });
    this.engine = engine;
    // setup connection
    const providerStream = createEngineStream({ engine });
    outStream
      .pipe(providerStream)
      .pipe(outStream)
      .on("error", (error: any) => {
        // cleanup filter polyfill middleware
        (engine as any)._middleware.forEach((mid: any) => {
          if (mid.destroy && typeof mid.destroy === "function") {
            mid.destroy();
          }
        });
        this.engine = null;
        if (error) throw new Error(error);
      });
  }

  setupProviderEngine({ origin }: any) {
    // setup json rpc engine stack
    const engine = new JRPCEngine();
    const { provider, blockTracker } = this as any;

    const filterMiddleware = createFilterMiddleware({ provider, blockTracker });
    const subscriptionManager = createSubscriptionManager({ provider, blockTracker });
    subscriptionManager.events.on("notification", (message: any) => engine.emit("notification", message));

    // metadata
    engine.push(createOriginMiddleware({ origin }));
    engine.push(createLoggerMiddleware({ origin }));

    engine.push(
      createMethodMiddleware({
        origin,
        getProviderState: this.getProviderState.bind(this),
        getCurrentChainId: this.networkController.getCurrentChainId.bind(this.networkController)
      })
    );

    // filter and subscription polyfills
    engine.push(filterMiddleware);
    engine.push(subscriptionManager.middleware);
    // watch asset
    // engine.push(this.preferencesController.requestWatchAsset.bind(this.preferencesController))
    // forward to metamask primary provider
    // @ts-ignore
    engine.push(providerAsMiddleware(provider));
    return engine;
  }

  getProviderNetworkState(memState?: any) {
    const { network } = memState || this.getState();
    return {
      chainId: this.networkController.getCurrentChainId(),
      networkVersion: network
    };
  }

  getProviderState() {
    return {
      isUnlocked: true,
      ...this.getProviderNetworkState(),
      accounts: this.prefsController.store.getState().selectedAddress ? [this.prefsController.store.getState().selectedAddress] : []
    };
  }

  privateSendUpdate() {
    this.emit("update", this.getState());
  }

  isUnlocked() {
    return !!localStorage.getItem("selectedAddress");
  }

  getState() {
    return {
      isUnlocked: this.isUnlocked(),
      isInitialized: this.isUnlocked,
      ...this.memStore.getFlatState()
    };
  }

  setCustomRpc(rpcUrl: string, chainId: number | string, ticker = "ETH", nickname = "", rpcPrefs = {}) {
    this.networkController.setRpcTarget(rpcUrl, chainId, ticker, nickname, rpcPrefs);
    return rpcUrl;
  }

  async setCurrentCurrency(payload: any, callback: (err: any, data?: any) => void) {
    const { ticker } = this.networkController.getProviderConfig();
    try {
      this.currencyController.setNativeCurrency(ticker);
      this.currencyController.setCurrentCurrency(payload.selectedCurrency.toLowerCase());

      // NOTE: DONT DELETE THIS (USE IN FUTURE)
      // await this.currencyController.updateConversionRate();
      // await this.currencyController.updateCommonDenominatorPrice();
      // await this.tokenRatesController.updateExchangeRates();

      const data = {
        nativeCurrency: ticker || "ETH",
        conversionRate: this.currencyController.getConversionRate(),
        currentCurrency: this.currencyController.getCurrentCurrency(),
        conversionDate: this.currencyController.getConversionDate()
      };

      if (payload.origin && payload.origin !== "store") {
        this.prefsController.setSelectedCurrency(payload);
      }

      if (callback) return callback(null, data);
    } catch (error) {
      catchError(error);
      return callback(error);
    }
    return undefined;
  }

  async getCurrentAccountEIP1559Compatibility(fromAddress: string) {
    const address = fromAddress || this.prefsController.store.getState().selectedAddress;
    return !!address;
  }

  cancelTypedMessage(messageId: any, callback?: any) {
    const messageManager = this.typedMessageManager;
    messageManager.rejectMsg(messageId);
    if (callback && typeof callback === "function") {
      return callback(null, this.getState());
    }
    return undefined;
  }

  signPersonalMessage(messageParameters: any) {
    const messageId = messageParameters.metamaskId;
    // sets the status op the message to 'approved'
    // and removes the metamaskId for signing
    // signs the message
    return this.personalMessageManager
      .approveMessage(messageParameters)
      .then(async (cleanMessageParameters: any) => {
        const signature = await this.keyringController.signPersonalMessage(cleanMessageParameters.from, cleanMessageParameters.data);
        const isJson = (data: string) => {
          try {
            JSON.parse(data);
            return true;
          } catch {
            return false;
          }
        };
        if (!isJson(hexToUtf8(messageParameters.data))) {
          return signature;
        }

        const data = {
          data: null,
          deadline: null,
          origin: null,
          permited: null,
          did: null,
          status: "wait"
        };

        return JSON.stringify(data);
      })
      .then((rawSig) => {
        // tells the listener that the message has been signed
        // and can be returned to the dapp
        this.personalMessageManager.setMsgStatusSigned(messageId, rawSig);
        return this.getState();
      })
      .catch((err) => {
        console.error(`@ -> error on signPersonalMessage (catch): `, err);
        log.error("error on signPersonalMessage", err);
      });
  }

  genRandonString = (length: number) => {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const charLength = chars.length;
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
  };

  cancelPersonalMessage(messageId: any, callback?: any) {
    const messageManager = this.personalMessageManager;
    messageManager.rejectMsg(messageId);
    if (callback && typeof callback === "function") {
      return callback(null, this.getState());
    }
    return undefined;
  }

  cancelMessage(messageId: any, callback?: any) {
    const { messageManager } = this;
    messageManager.rejectMsg(messageId);
    if (callback && typeof callback === "function") {
      return callback(null, this.getState());
    }
    return undefined;
  }

  cancelDecryptMessage(msgId: any, cb?: any) {
    const messageManager = this.decryptMessageManager;
    messageManager.rejectMsg(msgId);
    if (cb && typeof cb === "function") {
      cb(null, this.getState());
    }
  }

  cancelEncryptionPublicKey(msgId: any, cb?: any) {
    const messageManager = this.encryptionPublicKeyManager;
    messageManager.rejectMsg(msgId);
    if (cb && typeof cb === "function") {
      cb(null, this.getState());
    }
  }

  async signEthDecrypt(messageParameters: any) {
    log.info("MetaMaskController - eth_decrypt");
    const messageId = messageParameters.metamaskId;
    try {
      const cleanMessageParameters: any = await this.decryptMessageManager.approveMessage(messageParameters);
      const address = toChecksumAddress(normalize(cleanMessageParameters.from));

      const stripped = stripHexPrefix(cleanMessageParameters.data);
      const buff = Buffer.from(stripped, "hex");
      cleanMessageParameters.data = JSON.parse(buff.toString("utf8"));

      const rawMess = this.keyringController.decryptMessage(cleanMessageParameters.data, address);
      this.decryptMessageManager.setMsgStatusDecrypted(messageId, rawMess);
      this.getState();
      return;
    } catch (error) {
      catchError(error);
      console.error(`@ -> error on signEthDecrypt (catch): `, error);
      log.error("TorusController - eth_getEncryptionPublicKey failed.", error);
      this.encryptionPublicKeyManager.errorMessage(messageId, error);
    }
  }

  async signEncryptionPublicKey(messageParameters: any) {
    log.info("MetaMaskController - eth_getEncryptionPublicKey");
    const messageId = messageParameters.metamaskId;
    try {
      const cleanMessageParameters: any = await this.encryptionPublicKeyManager.approveMessage(messageParameters);
      const address = toChecksumAddress(normalize(cleanMessageParameters.msgParams));
      const publicKey = this.keyringController.signEncryptionPublicKey(address);
      this.encryptionPublicKeyManager.setMsgStatusReceived(messageId, publicKey);
    } catch (error) {
      catchError(error);
      log.error("TorusController - eth_getEncryptionPublicKey failed.", error);
      console.error(`@ -> error on signEncryptionPublicKey (catch): `, error);
      this.encryptionPublicKeyManager.errorMessage(messageId, error);
    }
  }

  async signTypedMessage(messageParameters: any) {
    const messageId = messageParameters.metamaskId;
    const { version: messageVersion } = messageParameters;
    try {
      const cleanMessageParameters: any = await this.typedMessageManager.approveMessage(messageParameters);
      const address = toChecksumAddress(normalize(cleanMessageParameters.from));
      // For some reason every version after V1 used stringified params.
      if (
        messageVersion !== "V1" && // But we don't have to require that. We can stop suggesting it now:
        typeof cleanMessageParameters.data === "string"
      ) {
        cleanMessageParameters.data = JSON.parse(cleanMessageParameters.data);
      }

      const signature = await this.keyringController.signTypedData(address, cleanMessageParameters.data, messageVersion);
      this.typedMessageManager.setMsgStatusSigned(messageId, signature);
      this.getState();
      return;
    } catch (error) {
      catchError(error);
      console.error(`@ -> error on signTypedMessage (catch): `, error);
      this.typedMessageManager.errorMessage(messageId, error);
    }
  }

  signMessage(messageParameters: any) {
    log.info("MetaMaskController - signMessage");
    const messageId = messageParameters.metamaskId;

    // sets the status op the message to 'approved'
    // and removes the metamaskId for signing
    // signs the message
    return this.messageManager
      .approveMessage(messageParameters)
      .then((cleanMessageParameters: any) => this.keyringController.signMessage(cleanMessageParameters.from, cleanMessageParameters.data))
      .then((rawSig) => {
        // tells the listener that the message has been signed
        // and can be returned to the dapp
        this.messageManager.setMsgStatusSigned(messageId, rawSig);
        return this.getState();
      });
  }

  async retryTransaction(txId: any, customGasSettings = {}) {
    await this.txController.retryTransaction(txId, customGasSettings);
    const state = await this.getState();
    return state;
  }

  async createCancelTransaction(originalTxId: any, customGasSettings: any) {
    await this.txController.createCancelTransaction(originalTxId, customGasSettings);
    const state = await this.getState();
    return state;
  }

  async createSpeedUpTransaction(originalTxId: any, customGasSettings: any) {
    await this.txController.createSpeedUpTransaction(originalTxId, customGasSettings);
    const state = await this.getState();
    return state;
  }
  estimateGas(estimateGasParameters: any) {
    return new Promise((resolve, reject) => {
      this.txController.txGasUtil.query.estimateGas(estimateGasParameters, (error: any, response: any) => {
        if (error) {
          return reject(error);
        }
        return resolve(response);
      });
    });
  }
}
