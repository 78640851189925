// @ts-nocheck
import * as React from "react";
import { IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const SuccessPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <IonPage className="w-full h-screen flex flex-col justify-center items-center p-4 font-roboto">
      <div className="w-full flex justify-center py-4 px-6">
        <span className="font-bold text-2xl">{t("transfer.orderSuccess")}</span>
      </div>
      <div className="w-full flex flex-col justify-center py-4 px-12 text-center">
        <h6 className="text-base leading-4.75 font-normal">{t("transfer.orderSuccessDesc")}</h6>
        <h6 className={`text-base leading-4.75 font-normal ${i18n.language === "ja" ? "mr-4" : "mr-2"}`}>
          {t("transfer.orderSuccessDesc2")}
        </h6>
      </div>
      <div className="w-full flex justify-center mt-12 px-4">
        <button
          className="h-12 w-full font-semibold text-base font-inter rounded-full bg-upbondBlue text-white"
          onClick={() => {
            history.push("/wallet/home");
          }}>
          {t("transfer.backHome")}
        </button>
      </div>
    </IonPage>
  );
};

export default SuccessPage;
