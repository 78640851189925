// @ts-nocheck
import { IonAlert, IonButton, IonCol, IonGrid, IonList, IonLoading, IonRow } from "@ionic/react";
import { getSdkError } from "@walletconnect/utils";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useWeb3 from "hooks/useWeb3";
import { IModalCloseWalletConnect } from "interfaces/actions/IWalletConnect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchClose, walletConnectArrAction } from "shared/actions/walletConnectAction";
import { SUPPORTED_NETWORK_TYPES } from "shared/enums";
import { legacySignClient, walletConnectAction } from "shared/utils/walletconnect/LegactWalletConnectUtil";
import "./styles/index.css";

export default function LegacySessionProposalModal(props: IModalCloseWalletConnect) {
  const { closeTheModalWalletConnect } = props;
  const dispatch = useAppDispatch();
  const walletConnect = useAppSelector((state) => state.walletConnect);
  const wallet = useAppSelector((state) => state.wallet);
  const { accounts } = useWeb3();
  const proposal = walletConnect.data?.legacyProposal;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [getProposalChainId, setProposalChainId] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const [openErrorModal, setOpenError] = useState(false);

  useEffect(() => {
    proposal && proposal.params.length > 0 && proposal?.params[0].chainId && setProposalChainId(proposal.params[0].chainId);
  }, [proposal]);

  // Get required proposal data
  // Hanlde approve action, construct session namespace
  async function onApprove() {
    const filtered = Object.values(SUPPORTED_NETWORK_TYPES).filter((val) => {
      return parseInt(val.chainId.toString()) === parseInt(getProposalChainId.toString());
    });
    if (proposal) {
      const chainId =
        filtered.length > 0
          ? getProposalChainId
          : typeof wallet.networkType.chainId === "string"
          ? parseInt(wallet.networkType.chainId)
          : wallet.networkType.chainId;
      legacySignClient.approveSession({
        accounts: [accounts[0]],
        chainId: chainId
      });
      closeTheModalWalletConnect(false);
      dispatch(walletConnectArrAction.resetDataArrWc());
      dispatch(walletConnectAction.setOpen({ open: false, view: {} as any, data: {} }));
      dispatch(fetchClose());
    }
  }

  // Handle reject action
  function onReject() {
    if (proposal) {
      legacySignClient.rejectSession(getSdkError("USER_REJECTED_METHODS"));
      closeTheModalWalletConnect(false);
      dispatch(walletConnectArrAction.resetDataArrWc());
      dispatch(walletConnectAction.setOpen({ open: false, view: {} as any, data: {} }));
      dispatch(fetchClose());
    }
  }
  const closeErrorModal = async () => {
    legacySignClient.rejectSession(getSdkError("USER_REJECTED_METHODS"));

    dispatch(walletConnectAction.setOpen({ open: false, view: {} as any, data: {} }));
    setOpenError(false);
    setErrorMsg("");
  };
  const closeErrorProposal = () => {
    closeTheModalWalletConnect(false);
    dispatch(walletConnectArrAction.resetDataArrWc());
    dispatch(walletConnectAction.setOpen({ open: false, view: {} as any, data: {} }));
    dispatch(fetchClose());
  };
  return (
    <>
      {loading ? (
        <IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} duration={2000} />
      ) : (
        <>
          {
            openErrorModal && (
              // <IonModal
              //   ref={modal}
              //   showBackdrop={false}
              //   backdropDismiss={false}
              //   className="disconnect-modal"
              //   isOpen={openErrorModal}
              //   trigger="open-modal">
              // <div className="wrapper-disconnect-modal">
              //   <p>{errorMsg}</p>
              //   <IonList className="disconnect-modal-button-list" lines="none">
              //     <IonButton onClick={closeErrorModal}>Close</IonButton>
              //   </IonList>
              // </div>
              <IonAlert
                isOpen={openErrorModal}
                onDidDismiss={closeErrorModal}
                // header="Alert"
                // subHeader="Important message"
                message={errorMsg}
                buttons={[`${t("account.close")}`]}
              />
            ) // </IonModal>
          }
          {proposal && !loading ? (
            <IonGrid id="legacySessionProposalModalGrid" className="font-roboto">
              <p className="text-center font-semibold text-base leading-[19px] text-[#0a0a0a] my-4">{t("notification.confirmRequest")}</p>
              <IonList lines="full">
                <IonRow className="ion-justify-content-around">
                  <>
                    <IonCol size="5">{t("notification.requestFrom")}</IonCol>
                    <IonCol size="5" style={{ fontWeight: "bold" }}>
                      {proposal && walletConnect.data?.legacyProposal?.params[0].peerMeta.url.replace(/(^\w+:|^)\/\//, "")}
                    </IonCol>
                  </>
                </IonRow>
              </IonList>
              <IonRow className="ion-justify-content-around">
                <IonCol size="5">
                  <IonButton shape="round" className="button-reject-session-proposal" onClick={() => onReject()}>
                    {t("notification.reject")}
                  </IonButton>
                </IonCol>
                <IonCol size="5">
                  <IonButton shape="round" color="primary" onClick={() => onApprove()}>
                    {t("settings.connect")}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <IonGrid className="disconnect-modal text-center font-roboto">
              <div>
                <h1 className="text-base font-semibold my-4">{t("settings.connectionFailed")}</h1>
                <p className="font-normal text-sm leading-4 my-4">{t("settings.networkError")}.</p>
                <IonList className="disconnect-modal-button-list" lines="none">
                  <IonButton
                    expand="block"
                    className="disconnect-close font-medium text-[15px] leading-[18px] text-[#4d4082] m-auto flex mb-5 w-[98%]"
                    onClick={() => closeErrorProposal()}>
                    {t("settings.close")}
                  </IonButton>
                </IonList>
              </div>
            </IonGrid>
          )}
        </>
      )}
    </>
  );
}
