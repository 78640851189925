import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchGasSetting } from "shared/actions/gasAction";
import { fetchTransferGas } from "shared/actions/transferAction";
import { estimateGweiPrice } from "shared/utils/etherUtils";
import { GasType } from "features/transfer/types";
import GasModalMb from "features/transfer/components/GasModal/mb";
import GasModalPc from "features/transfer/components/GasModal/pc";
import { ApplyFeeSpeedType } from "features/transfer/types";

interface Props {
  checkType?: number;
}

const GasModal: React.FC<Props> = ({ checkType = 0 }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // const history = useIonRouter();
  const { breakpoint } = useTheme();
  const transfer = useAppSelector((state) => state.transfer);
  const modal = useRef<HTMLIonModalElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [baseFee, setBaseFee] = useState<string>("0");
  const [priorityFee, setPriorityFee] = useState<string>("0");
  const [totalFee, setTotalFee] = useState<string>("0");
  const [maxBaseFee, setMaxBaseFee] = useState<number>(0);
  const [maxPriorityFee, setMaxPriorityFee] = useState<number>(0);
  const [feeSpeed, setFeeSpeed] = useState<GasType>("Normal");
  const [selectedFeeSpeed, setSelectedFeeSpeed] = useState<string>(t("transfer.normal") || "");
  const [estimateTime, setEstimateTime] = useState<string>(`40`);
  const [openEstimatePopHover, setOpenEtimatePopHover] = useState(false);
  const [openMaxBaseFee, setOpenMaxBaseFee] = useState(false);
  const [openPriorityFee, setOpenPriorityFee] = useState(false);
  const [openEstimatePopHoverConfirm, setOpenEstimatePopHoverConfirm] = useState(false);
  const [count, setCount] = useState(0);
  const [modalCount, setModalCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => prevCount + 1);
    }, 3000);

    const intervalModal = setInterval(() => {
      setModalCount((prevCount) => prevCount + 1);
    }, 500);

    return () => {
      clearInterval(intervalModal);
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    let a = 0;
    if (open) {
      a = window.setTimeout(function () {
        setOpenMaxBaseFee(false);
      }, 2000);
    } else {
      clearTimeout(a);
    }
  }, [openMaxBaseFee]);

  useEffect(() => {
    let a = 0;
    if (openEstimatePopHoverConfirm) {
      a = window.setTimeout(function () {
        setOpenEstimatePopHoverConfirm(false);
      }, 2000);
    } else {
      clearTimeout(a);
    }
  }, [openEstimatePopHoverConfirm]);

  useEffect(() => {
    let a = 0;
    if (openEstimatePopHover) {
      a = window.setTimeout(function () {
        setOpenEtimatePopHover(false);
      }, 2000);
    } else {
      clearTimeout(a);
    }
  }, [openEstimatePopHover]);

  useEffect(() => {
    let a = 0;
    if (openPriorityFee) {
      a = window.setTimeout(function () {
        setOpenPriorityFee(false);
      }, 2000);
    } else {
      clearTimeout(a);
    }
  }, [openPriorityFee]);

  useEffect(() => {
    dispatch(fetchTransferGas(transfer.detail?.chain_id || "1"));
  }, [count]);

  useEffect(() => {
    if (modal.current?.className.includes("overlay-hidden") && open) {
      setOpen(false);
    } else if (modal.current?.className.includes("show-modal") && !open) {
      setOpen(true);
    }
  }, [modalCount]);

  const estimateGas = async (feeSpeed: GasType) => {
    const estimateBaseFee = transfer.gas?.estimate.baseFee;
    const calculateEstimateBaseFee = estimateBaseFee !== undefined && estimateBaseFee >= 1 ? estimateBaseFee : 1;
    const priorityFeePerGasAmount =
      feeSpeed === "Instant"
        ? transfer.gas?.gasPrices.instant || 0
        : feeSpeed === "Fast"
        ? transfer.gas?.gasPrices.fast || 0
        : transfer.gas?.gasPrices.standard || 0;
    const baseFeePerGas = estimateGweiPrice(21000, calculateEstimateBaseFee);
    const priorityFeePerGas = estimateGweiPrice(21000, priorityFeePerGasAmount - calculateEstimateBaseFee);
    const totalFee = estimateGweiPrice(21000, priorityFeePerGasAmount < 1 ? 5 : priorityFeePerGasAmount);
    const minimumFee = transfer.gas?.estimate.maxFeePerGas || 0;
    const minimumPriorityFee = transfer.gas?.estimate.maxPriorityFeePerGas || 0;
    const maxBaseFee = minimumFee > priorityFeePerGasAmount ? minimumFee : priorityFeePerGasAmount;
    const calculatedMaxPriorityFee = priorityFeePerGasAmount - calculateEstimateBaseFee;
    const maxPriorityFee = minimumPriorityFee > calculatedMaxPriorityFee ? minimumPriorityFee : calculatedMaxPriorityFee;
    setBaseFee(baseFeePerGas);
    setPriorityFee(priorityFeePerGas);
    setMaxBaseFee(maxBaseFee);
    setMaxPriorityFee(maxPriorityFee * 2);
    setTotalFee(totalFee);
    return {
      totalFee,
      maxBaseFee,
      maxPriorityFee
    };
  };

  const applyFeeSpeed = async (item?: ApplyFeeSpeedType, dismiss = true) => {
    const feeTranslated = feeSpeed === "Instant" ? t("transfer.urgent") : feeSpeed === "Fast" ? t("transfer.fast") : t("transfer.normal");
    setSelectedFeeSpeed(feeTranslated);
    dismiss && modal.current?.dismiss();
    item
      ? dispatch(fetchGasSetting(item))
      : dispatch(
          fetchGasSetting({
            totalFee,
            maxBaseFee,
            maxPriorityFee
          })
        );
  };

  const changeTypeFee = async (type: GasType) => {
    if (type == "Fast") {
      setEstimateTime(`20`);
    } else if (type == "Instant") {
      setEstimateTime(`10`);
    } else {
      setEstimateTime(`40`);
    }
    await estimateGas(type);
    setFeeSpeed(type);
  };

  const init = async () => {
    const estimate = await estimateGas(feeSpeed);
    applyFeeSpeed(estimate, false);
  };

  useEffect(() => {
    if (transfer.gas) init();
    // () => getTickerTokenSymbol(transfer.detail?.chain_id ?? "1");
  }, [transfer.gas]);

  return (
    <>
      {breakpoint < 1024 ? (
        <GasModalMb
          modal={modal}
          open={open}
          setOpen={setOpen}
          baseFee={baseFee}
          openEstimatePopHover={openEstimatePopHover}
          setOpenEtimatePopHover={setOpenEtimatePopHover}
          totalFee={totalFee}
          estimateTime={estimateTime}
          selectedFeeSpeed={selectedFeeSpeed}
          openMaxBaseFee={openMaxBaseFee}
          setOpenMaxBaseFee={setOpenMaxBaseFee}
          openPriorityFee={openPriorityFee}
          setOpenPriorityFee={setOpenPriorityFee}
          priorityFee={priorityFee}
          feeSpeed={feeSpeed}
          changeTypeFee={changeTypeFee}
          openEstimatePopHoverConfirm={openEstimatePopHoverConfirm}
          setOpenEstimatePopHoverConfirm={setOpenEstimatePopHoverConfirm}
          applyFeeSpeed={applyFeeSpeed}
        />
      ) : (
        <GasModalPc
          modal={modal}
          open={open}
          setOpen={setOpen}
          baseFee={baseFee}
          openEstimatePopHover={openEstimatePopHover}
          setOpenEtimatePopHover={setOpenEtimatePopHover}
          totalFee={totalFee}
          estimateTime={estimateTime}
          selectedFeeSpeed={selectedFeeSpeed}
          openMaxBaseFee={openMaxBaseFee}
          setOpenMaxBaseFee={setOpenMaxBaseFee}
          openPriorityFee={openPriorityFee}
          setOpenPriorityFee={setOpenPriorityFee}
          priorityFee={priorityFee}
          feeSpeed={feeSpeed}
          changeTypeFee={changeTypeFee}
          openEstimatePopHoverConfirm={openEstimatePopHoverConfirm}
          setOpenEstimatePopHoverConfirm={setOpenEstimatePopHoverConfirm}
          applyFeeSpeed={applyFeeSpeed}
          checkType={checkType}
        />
      )}
    </>
  );
};

export default GasModal;
