/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createScaffoldMiddleware, mergeMiddleware } from "@toruslabs/openlogin-jrpc";
import { PollingBlockTracker } from "libs/PollingBlockTracker";
// @ts-ignore
import { createInfuraMiddleware } from "eth-json-rpc-infura";
import {
  createBlockCacheMiddleware,
  createBlockRefMiddleware,
  createBlockTrackerInspectorMiddleware,
  createInflightCacheMiddleware,
  createRetryOnEmptyMiddleware,
  providerFromMiddleware
} from "eth-json-rpc-middleware";
import config from "shared/config";
import { NETWORK_TYPE_TO_ID_MAP } from "shared/enums";

/**
 *
 *
 * @export
 * @param {{ network: keyof typeof NETWORK_TYPE_TO_ID_MAP }} { network }
 * @return {*}
 */
export function createInfuraClient({ network }: { network: keyof typeof NETWORK_TYPE_TO_ID_MAP }) {
  const infuraMiddleware = createInfuraMiddleware({ network, projectId: config.infuraKey as string });
  const infuraProvider = providerFromMiddleware(infuraMiddleware);
  const blockTracker = new PollingBlockTracker({ provider: infuraProvider as any });

  const networkMiddleware = mergeMiddleware([
    createNetworkAndChainIdMiddleware({ network }),
    createBlockCacheMiddleware({ blockTracker: blockTracker as any }) as any,
    createInflightCacheMiddleware(),
    createBlockRefMiddleware({ blockTracker: blockTracker as any, provider: infuraProvider }),
    createRetryOnEmptyMiddleware({ blockTracker: blockTracker as any, provider: infuraProvider }),
    createBlockTrackerInspectorMiddleware({ blockTracker: blockTracker as any }),
    createInfuraMiddleware({ network, projectId: config.infuraKey as string })
  ]);
  return { networkMiddleware, blockTracker };
}

/**
 *
 *
 * @export
 * @param {{ network: keyof typeof NETWORK_TYPE_TO_ID_MAP }} { network }
 * @return {*}
 */
export function createNetworkAndChainIdMiddleware({ network }: { network: keyof typeof NETWORK_TYPE_TO_ID_MAP }) {
  if (!NETWORK_TYPE_TO_ID_MAP[network]) {
    throw new Error(`createInfuraClient - unknown network "${network}"`);
  }
  const { chainId, networkId } = NETWORK_TYPE_TO_ID_MAP[network];

  return createScaffoldMiddleware({
    eth_chainId: chainId,
    net_version: networkId
  });
}
