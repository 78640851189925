// @ts-nocheck
import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonInput,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter
} from "@ionic/react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import { Contact } from "interfaces/actions/IContact";
import { IToken } from "interfaces/actions/IToken";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchContact, fetchEditContact } from "shared/actions/contactAction";
import { fetchTransferGas, fetchTransferNft, fetchTransferToken } from "shared/actions/transferAction";
import HomeList from "features/home/components/HomeList";
import AssetContactBarMb from "features/transfer/assets/components/AssetContactBar/mb";
import AssetContactBarPc from "features/transfer/assets/components/AssetContactBar/pc";
import HeaderMainMb from "components/header/mb";
import HeaderMainPc from "components/header/pc";

const SelectTransferPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useIonRouter();
  const balance = useAppSelector((state) => state.balance);
  const transfer = useAppSelector((state) => state.transfer);
  const contact = useAppSelector((state) => state.contact);
  const { breakpoint } = useTheme();

  const [open, setOpen] = useState<boolean>(false);
  const [contactAddress, setContactAddress] = useState<string>("");
  const [contactName, setContactName] = useState<string>("");
  const [selectedContactName, setSelectedContactName] = useState<string>("");
  const [isNewAddress, setIsNewAddress] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const contactAvatar = "https://img.freepik.com/free-vector/hand-drawn-nft-style-ape-illustration_23-2149611030.jpg?w=2000";
  const dataContact: Contact = {
    avatar: contactAvatar,
    address: contactAddress,
    name: contactName
  };

  const selectedNft = async (collectible: IToken, nft: any) => {
    setIsLoading(true);
    dispatch(fetchTransferGas(collectible.chain_id));
    dispatch(fetchTransferToken(collectible));
    dispatch(fetchTransferNft(nft));
    setIsLoading(false);
    history.push(breakpoint < 1024 ? `/transfer/confirm-nft` : `/wallet/tf/confirm-nft`);
  };

  const selectedCoin = async (e: IToken) => {
    setIsLoading(true);
    // await changeEthProvider(e.chain_id);
    dispatch(fetchTransferGas(e.chain_id));
    dispatch(fetchTransferToken(e));
    setIsLoading(false);
    history.push(breakpoint < 1024 ? `/transfer/confirm` : `/wallet/tf/confirm`);
  };
  const selectedToken = async (e: IToken) => {
    setIsLoading(true);
    // await changeEthProvider(e.chain_id);
    dispatch(fetchTransferGas(e.chain_id));
    dispatch(fetchTransferToken(e));
    setIsLoading(false);
    history.push(breakpoint < 1024 ? `/transfer/confirm-token` : `/wallet/tf/confirm-token`);
  };

  const addContact = () => {
    dispatch(fetchContact(dataContact));
  };

  const editContact = () => {
    dispatch(fetchEditContact(dataContact));
  };

  const saveContact = (isNewAddress: boolean) => {
    isNewAddress ? addContact() : editContact();
    setOpen(!open);
  };

  useEffect(() => {
    if (transfer.toAddress === "" || !transfer.toAddress) {
      history.push("/wallet/transfer", "forward", "push");
    }
  }, [transfer.toAddress]);

  useEffect(() => {
    if (contact) {
      setContactAddress(contact.selectedContact?.address || "");
      contact.contacts.filter((item) => {
        if (item.address === contact.selectedContact?.address) {
          setContactName(item.name);
          setSelectedContactName(item.name);
          setIsNewAddress(false);
        }
      });
    }
  }, [contact.contacts]);

  return (
    <>
      <IonPage>
        <div className="flex justify-center items-center">
          <IonLoading cssClass="my-custom-class" isOpen={isLoading} spinner={"circles"} />
        </div>

        {breakpoint < 1024 ? (
          <HeaderMainMb title={t("transfer.selectTransfer")} link="/wallet/transfer" />
        ) : (
          <HeaderMainPc title={t("transfer.selectTransfer")} link="/wallet/transfer" />
        )}

        <IonContent className={breakpoint >= 1024 ? "no-padding bg-base" : "ion-padding"}>
          {breakpoint < 1024 ? (
            <AssetContactBarMb isNewAddress={isNewAddress} open={open} setOpen={setOpen} selectedContactName={selectedContactName} />
          ) : (
            <AssetContactBarPc isNewAddress={isNewAddress} open={open} setOpen={setOpen} selectedContactName={selectedContactName} />
          )}
          <HomeList
            etherInitBalance={balance.etherInit}
            maticInitBalance={balance.maticInit}
            balances={balance.coin}
            tokenBalances={balance.token}
            collectibles={balance.nft}
            page="transfer"
            onCoinClick={selectedCoin}
            onNFTClick={selectedNft}
            onTokenClick={selectedToken}
            balance={balance}
          />
        </IonContent>
      </IonPage>

      <IonModal showBackdrop={true} backdropDismiss={false} className="modal-contact-bar" isOpen={open}>
        <IonToolbar className="pt-2.5">
          <IonTitle>{isNewAddress ? t("transfer.addContact") : t("transfer.editNewContact")}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="dark" onClick={() => setOpen(!open)}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <div>
          <IonList lines="none" className="p-4">
            <IonLabel position="stacked" className="font-roboto">
              {t("transfer.contactName") || "Name"}
            </IonLabel>
            <IonInput
              placeholder={t("transfer.contactName") || "Contact Name"}
              className="w-full rounded-full border border-[#d3d3d3] text-sm !px-3.5 my-2.5"
              value={contactName}
              onIonChange={(e) => {
                const nameData: any = e.target.value;
                setContactName(nameData.toString());
              }}></IonInput>
            <IonLabel position="stacked" className="font-roboto">
              {t("transfer.address") || "Address"}
            </IonLabel>
            <IonInput
              placeholder="0x"
              className="w-full rounded-full border border-[#d3d3d3] text-sm !px-3.5 my-2.5"
              value={contact.selectedContact?.address}
              disabled
              onIonChange={(e) => {
                const addressData: any = e.target.value;
                setContactAddress(addressData.toString());
              }}></IonInput>

            <IonButton
              expand="block"
              className="mt-[18px] mb-[10px]"
              onClick={() => {
                saveContact(isNewAddress);
              }}>
              {t("transfer.save")}
            </IonButton>
          </IonList>
        </div>
      </IonModal>
    </>
  );
};

export default SelectTransferPage;
