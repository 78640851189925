// @ts-nocheck
import { IonButton, IonModal } from "@ionic/react";
import useTheme from "hooks/useTheme";
import { ModalOpenExplorerProps } from "interfaces/ui/IMenu";
import { useTranslation } from "react-i18next";
import { goToExplorer } from "shared/utils/coreUtils";

const ModalOpenExplorer = ({
  openModalExplorer,
  setOpenModalExplorer,
  isAstar,
  data,
  dataMoralis,
  isSeeDetail
}: ModalOpenExplorerProps) => {
  const { t } = useTranslation();
  const { breakpoint } = useTheme();
  return (
    <IonModal
      className={breakpoint < 1024 ? "modal-open-explorer" : "modal-open-explorer-pc"}
      style={{ backgroundColor: isSeeDetail ? "rgba(0,0,0, 0.15)" : null }}
      isOpen={openModalExplorer}>
      {breakpoint > 1024 ? (
        <div className="container">
          <IonButton
            id="button-explorer-pc"
            onClick={() =>
              isAstar
                ? window.open(goToExplorer(data?.explorer, data?.hash), "_blank")
                : window.open(goToExplorer(dataMoralis?.explorer, dataMoralis?.hash), "_blank")
            }>
            {t("account.seeDetails")}
          </IonButton>
          <IonButton onClick={() => setOpenModalExplorer(!openModalExplorer)}>{t("account.close")}</IonButton>
        </div>
      ) : (
        <>
          <IonButton
            id={breakpoint < 1024 ? "button-explorer" : ""}
            size={breakpoint < 1024 ? "large" : "default"}
            color={breakpoint > 1024 ? "primary" : ""}
            onClick={() =>
              isAstar
                ? window.open(goToExplorer(data?.explorer, data?.hash), "_blank")
                : window.open(goToExplorer(dataMoralis?.explorer, dataMoralis?.hash), "_blank")
            }>
            {t("account.seeDetails")}
          </IonButton>
          <IonButton
            size={breakpoint < 1024 ? "large" : "default"}
            id={breakpoint < 1024 ? "button-explorer" : ""}
            class={breakpoint < 1024 ? "cancel-setting" : ""}
            onClick={() => setOpenModalExplorer(!openModalExplorer)}>
            {t("account.close")}
          </IonButton>
        </>
      )}
    </IonModal>
  );
};

export default ModalOpenExplorer;
