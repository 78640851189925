/* eslint-disable no-useless-catch */
import { AnyAction, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { BalanceItems, IChains, ITokenArray } from "interfaces/actions/IToken";
import BalanceService from "services/BalanceServices";
import { listChain } from "shared/config/chains";
import { RootState } from "shared/store";
import balanceSlice from "shared/store/balanceSlice";
import { catchError } from "shared/utils/coreUtils";
import { ethers } from "ethers";
import erc20Abi from "abis/erc20.json";
import moralisServices from "services/MoralisServices";
import config from "shared/config";

export const balanceAction = balanceSlice.actions;
export const fetchBalance =
  (walletAddress: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(balanceAction.reset());
      dispatch(balanceAction.setLoading(true));
      let counter = 0;
      const showTestnetBool = getState().balance.showTestnet;
      let counterIter = 0;

      dispatch(balanceAction.setIndex(0));
      !showTestnetBool
        ? dispatch(
            balanceAction.setLength(
              listChain.filter((chain) => {
                return chain.type === "mainnet";
              }).length
            )
          )
        : dispatch(balanceAction.setLength(listChain.length));
      for (const [i, item] of listChain.entries()) {
        if (showTestnetBool || (!showTestnetBool && item.type === "mainnet")) {
          await calculateBalance(dispatch, getState, item, walletAddress, counterIter, listChain);
          counterIter += 1;
        }

        counter = i;
        // Jangan dihapus
        const totalCount = config.isProduction ? 2 : 3;
        // Moralis fixing di take off dulu
        // TODO: Recomment-out after decision of GO with Moralis
        if (counter == totalCount) {
          // fix this
          // fixNftBalance(dispatch, getState, walletAddress);
        }
      }
    } catch (error) {
      dispatch(balanceAction.setLoading(false));
    }
  };

export const calculateBalance = async (
  dispatch: ThunkDispatch<{ balance: ITokenArray }, unknown, AnyAction>,
  getState: () => { balance: ITokenArray },
  chains: IChains,
  walletAddress: string,
  index: number,
  listChain: IChains[]
) => {
  try {
    const ethMaticNft: any[] = [];
    let response;
    /**
     * MORALIS HANDLER
     */
    await new Promise((resolve) => setTimeout(resolve, 600));
    if (chains.chainName !== "Astar") {
      const getNft: any = await moralisServices.getNft(chains, walletAddress);
      const moralisNativeAndErc20Balance = await moralisServices.getNativeAndErc20(chains, walletAddress);
      if (getNft && moralisNativeAndErc20Balance) {
        const all = [...getNft.items, ...(moralisNativeAndErc20Balance as BalanceItems[])];
        getNft.items = all;
        const newData: any = {
          data: {
            ...getNft
          }
        };
        response = newData;
      }
    } else {
      response = await BalanceService.getAllBalance(chains.chain, walletAddress, false, chains.chainName === "Astar");
    }
    if (response.error) {
      response = await BalanceService.getAllBalance(chains.chain, walletAddress, true);
      const resNft = await BalanceService.getNftBalance(chains.chain, walletAddress);
      if (resNft) {
        ethMaticNft.push(resNft);
      }
    }

    let mapCoin: any = {};
    const mapERC20: BalanceItems[] = [];
    const mapNFT: BalanceItems[] = [];
    if (response && response.data && response.data.items) {
      response.data.items.map(async (item: BalanceItems) => {
        item.logo_url = chains.customLogo;
        item.chain_id = chains.chain;
        item.testnet = chains.type === "testnet";
        if (item.native_token) {
          item.contract_name = chains.customName;
          item.logo_url = chains.customLogo;
          item.contract_address = chains.address;
          mapCoin = item;
        } else if (!item.native_token && item.type !== "nft") {
          const oldERC20 = mapERC20.filter((oldItem) => oldItem["contract_address"] === item.contract_address);
          if (oldERC20.length <= 0) mapERC20.push(item);
          if (!item.contract_name) {
            await fixContract(dispatch, getState, item, chains);
          }
        } else {
          const oldNFT = mapNFT.filter((oldItem) => oldItem["contract_address"] === item.contract_address);
          oldNFT.length <= 0 && mapNFT.push(item);
        }
        return null;
      });
    }

    const filterCoin: BalanceItems[] = await Promise.all(
      getState().balance.coin.map((coin) => {
        if (coin.chain_id === mapCoin.chain_id) return mapCoin;
        else return coin;
      })
    );
    const erc20Mapped = [...getState().balance.token, ...[...mapERC20]];
    const nftMapped = [...getState().balance.nft, ...[...mapNFT]];
    const uniqueNftMapped = nftMapped.filter((v, i, a) => a.findIndex((v2) => v2.contract_address === v.contract_address) === i);
    const uniqueErc20Mapped = erc20Mapped.filter(
      (v, i, a) => a.findIndex((v2) => v2.contract_address === v.contract_address && v2.chain_id === v.chain_id) === i
    );
    const checkBalanceCoin = filterCoin.every((data) => parseInt(data.balance) === 0);

    const tokenErc20 = filterCoin;
    if (tokenErc20 && uniqueErc20Mapped && uniqueNftMapped) {
      dispatch(balanceAction.setCoin(tokenErc20));
      dispatch(balanceAction.setTokenERC20(uniqueErc20Mapped));
      dispatch(balanceAction.setLoading(false));
      dispatch(balanceAction.setIndex(index));
      if (erc20Mapped.length == 0 && checkBalanceCoin && uniqueNftMapped.length == 0 && index === listChain.length) {
        dispatch(balanceAction.setNoAsset(true));
      } else {
        dispatch(balanceAction.setNoAsset(false));
        dispatch(balanceAction.setNFT(uniqueNftMapped));
      }
    }
    return true;
  } catch (error) {
    catchError(error, "rpc error");
    return false;
  }
};

export const fixContract = async (
  dispatch: ThunkDispatch<{ balance: ITokenArray }, unknown, AnyAction>,
  getState: () => { balance: ITokenArray },
  item: BalanceItems,
  chains: IChains
) => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(chains.rpcUrl[0]);

    provider.on("error", (error) => {
      catchError(error, "rpc error");
    });

    const erc20Contract = new ethers.Contract(item.contract_address, erc20Abi.abi, provider.getSigner().provider);
    const name = await erc20Contract.name();
    const symbol = await erc20Contract.symbol();
    const decimals = await erc20Contract.decimals();

    const arrErc20 = [...getState().balance.token];
    const erc20Mapped = arrErc20.findIndex((obj) => obj.contract_address == item.contract_address);
    const updated = { ...arrErc20[erc20Mapped], contract_name: name, contract_decimals: decimals, contract_ticker_symbol: symbol };
    const newArr: BalanceItems[] = [];

    arrErc20.map((item) => {
      if (item.contract_address === updated.contract_address) {
        if (updated.contract_name) newArr.push(updated);
      } else {
        if (item.contract_name) newArr.push(item);
      }
    });

    dispatch(balanceAction.setTokenERC20(newArr));
  } catch (e) {
    catchError(e, "RPC error");
    return false;
  }
};

/**
 * Don't Delete this one
 */

// export const handleNft = async (ethMaticNft: any) => {
//   const mapNewNft: any = []; //NFT METADATA using api : nft_metadata
//   const newACollection: any = []; //collection
//   const redundantNft: any = [];

//   /**
//    * Loops through an array of Ethereum Matic NFTs and their associated data, and creates a new array
//    * with the same data, but with additional properties added to each element. Also retrieves metadata
//    * for each NFT and adds it to a separate array.
//    * @param {Array} ethMaticNft - the array of Ethereum Matic NFTs to process
//    */
//   if (ethMaticNft.length > 0) {
//     for (let index = 0; index < ethMaticNft.length; index++) {
//       const element = ethMaticNft[index];
//       for (let index = 0; index < element?.items?.length; index++) {
//         const element2 = element?.items[index];
//         Object.assign(element2, { chain_id: element.chain });
//         newACollection.push(element2);
//         for (let index = 0; index < element2?.nft_data?.length; index++) {
//           const element3 = element2?.nft_data[index];
//           try {
//             const metadataNft = await BalanceService.getALlNftMetadata(element.chain, element2.contract_address, element3.token_id);
//             // console.log(metadataNft, "@METADATA");
//             mapNewNft.push(metadataNft); //getALLMETADATA
//           } catch (error) {
//             console.log(error, "@err");
//           }
//         }
//       }
//     }
//   }

//   /**
//    * Loops through a collection of NFTs and a map of new NFTs, and adds any new NFTs
//    * to the existing collection. If an NFT already exists in the collection, it is
//    * removed from the new NFT map and added to a redundant NFT array.
//    * @param {Array} mapNewNft - the array of new NFTs to add to the collection
//    * @param {Array} newACollection - the existing collection of NFTs
//    * @param {Array} redundantNft - the array to store any redundant NFTs
//    */
//   if (mapNewNft.length > 0) {
//     for (let index1 = 0; index1 < newACollection.length; index1++) {
//       const element1 = newACollection[index1];
//       for (let index = 0; index < mapNewNft.length; index++) {
//         const element2 = mapNewNft[index];
//         // console.log(element1?.contract_address, element2?.contract_address);
//         if (element1?.contract_address === element2?.contract_address) {
//           element1.nft_data.push(element2.nft_data[0]);
//           const nftWithMetadata = element1.nft_data?.filter((oj: any) => Object.keys(oj).includes("burned"));
//           element1.nft_data = nftWithMetadata;
//           redundantNft.push(element1);
//         }
//       }
//     }
//   }

//   const fixedNft: any = [...new Set(redundantNft)];
//   return fixedNft;
// };

// export const fixNftBalance = async (
//   dispatch: ThunkDispatch<{ balance: ITokenArray }, unknown, AnyAction>,
//   getState: () => { balance: ITokenArray },
//   walletAddress: string
// ) => {
//   const moralisFetchedNFTs: BalanceItems[] = [];
//   const currentFetchedNFTs = getState().balance.nft;
//   const chains = ["eth", "matic"];
//   try {
//     for await (const chain of chains) {
//       // loop custom chain for fetch nft data from Moralis
//       const moralisNft = await moralisServices.getnft(chain, walletAddress);
//       if (moralisNft) {
//         const newNft: BalanceItems[] = await Promise.all(
//           moralisNft.map(async (item: MoralisCollection) => {
//             let metadata = parseJsonString<MoralisMetadata>(item.metadata);
//             if (metadata) {
//               const ipfsUri = "https://ipfs.io/ipfs/";
//               if (metadata.image && typeof metadata.image === "string" && metadata?.image.includes("ipfs://")) {
//                 metadata.image = metadata.image.replace("ipfs://", ipfsUri);
//               }
//             } else {
//               const tokenMetadata = await axios.get(item.token_uri);
//               metadata = tokenMetadata.data as MoralisMetadata;
//             }
//             const supportNFT = ["erc20", "erc721"];
//             if (item.contract_type?.toLowerCase() === "erc1155") {
//               supportNFT.push(item?.contract_type?.toLowerCase());
//             }
//             const userNfts = mergeMoralisToBalanceItems({ chain, item, metadata, supportsErc: supportNFT });
//             return userNfts;
//           })
//         );
//         moralisFetchedNFTs.push(...newNft);
//       }
//     }

//     const newMap: BalanceItems[] = [];
//     let currentIndex: number;
//     await Promise.all(
//       moralisFetchedNFTs.map((moralisNftItem) => {
//         const mapData = newMap.find((val: BalanceItems, index: number) => {
//           currentIndex = index;
//           return moralisNftItem.contract_address === val.contract_address;
//         });
//         if (!mapData) {
//           newMap.push(moralisNftItem);
//         } else {
//           newMap[currentIndex].nft_data = [...newMap[currentIndex].nft_data, ...moralisNftItem.nft_data];
//         }
//       })
//     );

//     const balanceItems: BalanceItems[] = [];
//     for (let index = 0; index < currentFetchedNFTs.length; index++) {
//       const element = { ...currentFetchedNFTs[index] };
//       if (element.nft_data) {
//         const moralisSelected = newMap.filter(
//           (val: any) => val.contract_address.toLowerCase() === element.contract_address.toLowerCase()
//         )[0];
//         if (!element.contract_name && !element.contract_ticker_symbol && moralisSelected) {
//           element.contract_name = moralisSelected.contract_name;
//           element.contract_ticker_symbol = moralisSelected.contract_ticker_symbol;
//         }
//         for (let indexEl = 0; indexEl < element.nft_data.length; indexEl++) {
//           const element2 = element.nft_data[indexEl];

//           if (element2.external_data === null) {
//             if (moralisSelected) {
//               element.nft_data = moralisSelected.nft_data;
//             }
//           }
//         }
//       }
//       balanceItems.push(element);
//     }
//     dispatch(balanceAction.setNFT(balanceItems));
//     dispatch(balanceAction.setLoading(false));
//   } catch (error: any) {
//     console.error(error);
//     dispatch(balanceAction.setLoading(false));
//   }
// };

// export const updateMetadata = async (
//   dispatch: ThunkDispatch<{ balance: ITokenArray }, unknown, AnyAction>,
//   getState: () => { balance: ITokenArray },
//   token_url: string,
//   contract_address: string,
//   token_id: string
// ) => {
//   const fetchTokenUri = await axios.get(token_url);
//   const allNFT = getState().balance.nft;
//   allNFT.map((nft: BalanceItems) => {
//     if (nft.contract_address === contract_address && nft.nft_data) {
//       const updatedData = nft.nft_data.map((detail: any) => {
//         if (detail.token_id === token_id && !detail.external_data) {
//           const newExternalData = { ...detail, external_data: fetchTokenUri.data };
//           return newExternalData;
//         } else {
//           return detail;
//         }
//       });

//       dispatch(balanceAction.setNFT(updatedData));
//     }
//   });
// };

export const setShowTestnet =
  (payload: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    localStorage.setItem("showTestnet", payload.toString());
    dispatch(balanceAction.setShowTestnet(payload));
  };
