import { ObservableStore } from "@metamask/obs-store";
import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/browser";
import { JwtParameters } from "@toruslabs/openlogin-jrpc";
import { isHexStrict } from "@web3auth/base";
import BigNumber from "bignumber.js";
import deepmerge from "deepmerge";
import OpenLoginHandler from "handlers/auth/OpenLoginHandler";
import PopupWithBcHandler from "handlers/stream/PopupBcHandler";
import PopupHandler from "handlers/stream/PopupHandler";
import { handleStatus } from "helpers/initStreamListenerHelper";
import { randomId } from "helpers/randomIdHelper";
import {
  accountTrackerHandler,
  encryptionPublicKeyHandler,
  errorMsgHandler,
  messageManagerHandler,
  personalMessageHandler,
  prefsControllerHandler,
  successMsgHandler,
  tokenRatesControllerHandler,
  transactionControllerHandler,
  typedMessageManagerHandler,
  unapprovedDecryptMsgsHandler
} from "helpers/subscriptionHelper";
import { MuxSubStream, OAuthModalData } from "interfaces/ui/IEmbed";
import torus from "libs/TorusExtended";
import { cloneDeep } from "lodash";
import config from "shared/config";
import { MUX_CHUNK } from "shared/config/mux";
import {
  ACCOUNT_TYPE,
  CHAIN_ID_TO_TYPE_MAP,
  FEATURES_CONFIRM_WINDOW,
  FEATURES_DEFAULT_WALLET_WINDOW,
  FEATURES_PROVIDER_CHANGE_WINDOW,
  RPC,
  SUPPORTED_NETWORK_TYPES,
  TRANSACTION_TYPES
} from "shared/enums";
import { RootState, store } from "shared/store";
import { embedUiSlice } from "shared/store/embedSlice";
import walletSlice, { UpbondUserInfo } from "shared/store/walletSlice";
import {
  catchError,
  getIFrameOriginObject,
  isAddressByChainId,
  isMain,
  padPrivateKey,
  toChecksumAddressByChainId
} from "shared/utils/coreUtils";
import { encryptUserInfo } from "shared/utils/encryptionUtils";
import { fromWei, toBN } from "web3-utils";
import { balanceAction } from "./balanceAction";
import { embedActions } from "./embedAction";
// import { olUserModuleAction } from "./olUserModuleAction";
// import { logout as allDappModuleLogout } from "./olAllDappModuleAction";
import { logout as dappModuleLogout } from "./olDappModuleAction";
// import { olTkeyModuleAction } from "./olTkeyModuleAction";
// import { olPidModuleAction } from "./olPidModuleAction";
export const walletActions = walletSlice.actions;
export const embedUiActions = embedUiSlice.actions;
export type TA<T = void, S = RootState, E = unknown> = ThunkAction<T, S, E, AnyAction>;
export type NetworkType = {
  host: string;
  networkName: string;
  chainId: string | number;
  blockExplorer: string;
  ticker: string;
  logo: string;
  tickerName: string;
  rpcUrl: string;
};

export type Keys = {
  privKey: string;
  accountType: (typeof ACCOUNT_TYPE)[keyof typeof ACCOUNT_TYPE];
  userInfo?: any;
  ethAddress: string;
  metadataNonce?: any;
  seedPhrase?: string;
  jwtToken?: string;
  authServiceAccessToken?: string;
};

export type KeyringPayload = {
  keys: Keys[];
  calledFromEmbed: boolean;
  rehydrate: any;
  postboxAddress: string;
};

export type TargetType = "_blank" | "_self" | "_parent" | "_top" | "framename";

export const initKeyringAsync = async (state: RootState, payload: KeyringPayload): Promise<string[] | undefined> => {
  try {
    const {
      wallet: { wallet }
    } = state;
    const { calledFromEmbed, keys, postboxAddress, rehydrate } = payload;
    const upbondController = torus?.upbondController;

    if (process.env.NODE_ENV === "development") {
      console.info(`[info]: awaiting initKeyring`, {
        keys
      });
    }
    await upbondController?.initKeyring(
      keys.map((x) => x.privKey),
      keys.map((x) => x.ethAddress)
    );
    return Promise.all(
      keys.map((x) => {
        store.dispatch(
          walletActions.setWallet({
            ...wallet,
            [x?.ethAddress]: {
              privateKey: x?.privKey,
              accountType: x?.accountType || ACCOUNT_TYPE.NORMAL,
              seedPhrase: x?.seedPhrase,
              metadataNonceHex: x?.metadataNonce?.toString(16)
            }
          })
        );
        if (process.env.NODE_ENV === "development") {
          console.info(`[info]: return array`, {
            [x?.ethAddress]: {
              privateKey: x?.privKey,
              accountType: x?.accountType || ACCOUNT_TYPE.NORMAL,
              seedPhrase: x?.seedPhrase,
              metadataNonceHex: x?.metadataNonce?.toString(16)
            }
          });
        }
        if (state?.wallet?.userInfo?.email && state?.wallet?.userInfo?.name) {
          if (process.env.NODE_ENV === "development") {
            console.info(`[info]: set ${state?.wallet?.userInfo?.email} for sentry`);
          }
          Sentry.setUser({ email: state?.wallet?.userInfo?.email, username: state?.wallet?.userInfo?.name });
        }
        return upbondController?.prefsController.init({
          address: x.ethAddress,
          calledFromEmbed,
          userInfo: state.wallet.userInfo,
          rehydrate,
          dispatch: store.dispatch,
          jwtToken: x.jwtToken ? x.jwtToken : "",
          accountType: x.accountType || ACCOUNT_TYPE.NORMAL,
          postboxAddress
        });
      })
    ) as Promise<string[]>;
  } catch (error) {
    Sentry.captureException(error);
    console.error(`@ -> error on initKeyring (catch): `, error);
    return undefined;
  }
};

export const setProviderAsync = async ({ network, type }: { network: NetworkType; type?: string }): Promise<boolean | string> => {
  try {
    let isSupportedNetwork = false;
    const { chainId } = network;
    const activeChainId = chainId && (isHexStrict(chainId.toString()) ? chainId : `0x${chainId.toString(16)}`);
    const chainIdConfig = (CHAIN_ID_TO_TYPE_MAP as { [x: string]: { networkId: number; name: string } })[activeChainId];

    if (chainIdConfig) {
      const networkConfig = SUPPORTED_NETWORK_TYPES[chainIdConfig.name];
      network = { ...networkConfig };
    }
    if (SUPPORTED_NETWORK_TYPES[network.host]) {
      network = SUPPORTED_NETWORK_TYPES[network.host as string];
      isSupportedNetwork = true;
    }

    const currentTicker = network.ticker || "ETH";
    store.dispatch(walletActions.setNetworkType(network));

    //? in the future will be used for ** Dynamic network **
    // const currentHost = Object.keys(SUPPORTED_NETWORK_TYPES);
    // if (!currentHost.includes(network.host)) {
    //   store.dispatch(
    //     walletActions.setSupportedNetworkType({
    //       ...store.getState().wallet.supportedNetworks,
    //       [network.host]: {
    //         ...network,
    //         networkName: network.networkName || network.host
    //       }
    //     })
    //   );
    // }
    //? ** end **

    const upbondController = torus?.upbondController;
    if (upbondController && type === RPC && !isSupportedNetwork) {
      return upbondController.setCustomRpc(network.host, network.chainId || 1, currentTicker, network.networkName || "", {
        blockExplorerUrl: network.blockExplorer
      });
    }
    if (upbondController) {
      const { networkController } = upbondController;
      await networkController.setProviderType(network.host, network.rpcUrl, network.ticker, network.networkName);
      store.dispatch(setSelectedCurrency({ selectedCurrency: network.ticker, origin: "home" }));
    }
    return true;
  } catch (error: any) {
    Sentry.captureException(error);
    console.error(`@ -> error on setProvider (catch): `, error);
    return false;
  }
};

export const rehydrateAsync = async () => {
  const {
    wallet: {
      selectedAddress,
      wallet,
      networkType,
      prefs: { networkId, isChainIdLocked },
      userInfo: { verifier }
    }
  } = store.getState();
  try {
    setTimeout(async () => {
      if (!isChainIdLocked) {
        if (SUPPORTED_NETWORK_TYPES[networkType.host]) {
          await setProviderAsync({ network: networkType });
        } else {
          await setProviderAsync({ network: networkType, type: RPC });
        }
      }
      const walletKeys = Object.keys(wallet);
      store.dispatch(subscribeController());
      await initKeyringAsync(store.getState(), {
        calledFromEmbed: false,
        rehydrate: true,
        keys: walletKeys.map((x) => {
          const { privateKey, accountType, seedPhrase } = wallet[x];
          return {
            ethAddress: x,
            privKey: privateKey as string,
            accountType: accountType as (typeof ACCOUNT_TYPE)[keyof typeof ACCOUNT_TYPE],
            jwtToken: undefined,
            seedPhrase: seedPhrase as string
          };
        }),
        postboxAddress: ""
      });
      if (selectedAddress || wallet[selectedAddress]) {
        store.dispatch(walletActions.setSelectedAddress(selectedAddress));
        store.dispatch(walletActions.updateNetworkId(networkId));
        const communicationMux = torus?.communicationMux;
        if (communicationMux) {
          const statusStream = communicationMux.getStream("status") as MuxSubStream;
          handleStatus({ loggedIn: true, rehydrate: true, verifier });
          if (statusStream) statusStream.write({ loggedIn: true, rehydrate: true, verifier });
          store.dispatch(embedUiActions.setLoginState(true));
        }
      }
      store.dispatch(walletActions.setRehydration(true));
    }, 200);
  } catch (error) {
    console.error(`@ -> error on rehydrate async (catch): `, error);

    Sentry.captureException(error);
    store.dispatch(walletActions.setRehydration(false));
  }
};

export const handleLogin =
  (
    payload: OAuthModalData & {
      keys: Keys[];
      userInfo: UpbondUserInfo;
      postboxKey?: string;
      localTransition?: boolean;
      shouldRedirectToConsent?: boolean;
    }
  ): TA =>
  (dispatch, state) => {
    if (!isMain && !payload.localTransition) {
      // const encryptedKeys = crypt(process.env.REACT_APP_CLIENT_ID, JSON.stringify(payload.keys));
      const encryptedKeys = encryptUserInfo(JSON.stringify(payload.keys));
      const params = new URLSearchParams({
        loggedIn: true,
        rehydrate: false,
        state: encryptedKeys,
        verifier: payload.verifier
      } as any).toString();
      window.location.href = payload.dappRedirectUrl + "?" + params;
      return;
    }

    const upbondController = torus?.upbondController;
    const { calledFromEmbed, userInfo, keys, postboxKey } = payload;
    const upUserInfo = userInfo.name == "" && userInfo.email == "" ? keys[0].userInfo : userInfo;

    dispatch(walletActions.setUserInfo(upUserInfo));
    const newKeys = keys;
    newKeys[0].privKey = padPrivateKey(newKeys[0].privKey);
    dispatch(walletActions.setPostboxKey(postboxKey ? postboxKey : newKeys[0].privKey));
    if (newKeys[0]?.authServiceAccessToken) {
      dispatch(walletActions.setAuthServiceAccessToken(newKeys[0].authServiceAccessToken));
    }

    const { verifier } = upUserInfo;
    dispatch(subscribeController());
    try {
      initKeyringAsync(state(), {
        calledFromEmbed,
        keys: newKeys,
        postboxAddress: postboxKey ? postboxKey : newKeys[0].privKey,
        rehydrate: false
      })
        .then(async (selAddr) => {
          const selectedDefaultAddress = (selAddr as string[])[0] || (selAddr as string[])[1];
          const selectedAddress = Object.keys(state().wallet.wallet).includes(selectedDefaultAddress)
            ? selectedDefaultAddress
            : Object.keys(state().wallet.wallet)[0];

          if (!selectedAddress) {
            catchError(new Error("No Accounts available"), `[error][fatal]: user cannot login`);
            throw new Error("No Accounts available");
          }

          dispatch(walletActions.setSelectedAddress(selectedAddress));
          const encryptedKeys = encryptUserInfo(JSON.stringify(newKeys));
          if (payload.calledFromEmbed) {
            upbondController?.setSelectedAddress(selectedAddress);

            const communicationMux = torus.communicationMux;
            if (communicationMux) {
              const oauthStream = communicationMux.getStream("oauth") as MuxSubStream;
              const statusStream = communicationMux.getStream("status") as MuxSubStream;

              if (statusStream && oauthStream && statusStream?.write && oauthStream?.write) {
                statusStream?.write({ loggedIn: true, rehydrate: false, verifier });
                oauthStream?.write({ selectedAddress });
              }

              if (payload.localTransition) {
                return;
              }

              const data = JSON.stringify({
                verifier,
                loggedIn: true,
                rehydrate: false,
                state: payload.calledFromEmbed ? encryptedKeys : ""
              });

              localStorage.setItem("currentKeys", data);
              if (
                state().wallet.consentConfigurations.scope.length > 0 &&
                state().wallet.consentConfigurations.clientId !== "" &&
                payload.shouldRedirectToConsent
              ) {
                if (
                  state().wallet.consentConfigurations.origin === payload.dappRedirectUrl ||
                  `http://${state().wallet.consentConfigurations.origin}` === payload.dappRedirectUrl ||
                  `https://${state().wallet.consentConfigurations.origin}` === payload.dappRedirectUrl
                ) {
                  window.location.href = new URL(window.location.href).origin + "/consent";
                  return;
                }
              }

              const params = new URLSearchParams({
                selectedAddress,
                verifier,
                loggedIn: true,
                rehydrate: false,
                state: payload.calledFromEmbed ? encryptedKeys : ""
              } as any).toString();
              window.location.href = payload.dappRedirectUrl + "?" + params;
              return;
            }

            if (payload.localTransition) {
              return;
            }

            const data = JSON.stringify({
              verifier,
              loggedIn: true,
              rehydrate: false,
              state: payload.calledFromEmbed ? encryptedKeys : ""
            });
            localStorage.setItem("currentKeys", data);

            if (
              state().wallet.consentConfigurations.scope.length > 0 &&
              state().wallet.consentConfigurations.clientId !== "" &&
              payload.shouldRedirectToConsent
            ) {
              if (
                state().wallet.consentConfigurations.origin === payload.dappRedirectUrl ||
                `http://${state().wallet.consentConfigurations.origin}` === payload.dappRedirectUrl ||
                `https://${state().wallet.consentConfigurations.origin}` === payload.dappRedirectUrl
              ) {
                window.location.href = new URL(window.location.href).origin + "/consent";
                return;
              }
            }

            const oauthStream = torus?.communicationMux?.getStream("oauth") as MuxSubStream;
            const statusStream = torus?.communicationMux?.getStream("status") as MuxSubStream;
            if (statusStream && oauthStream && statusStream?.write && oauthStream?.write) {
              statusStream?.write({ loggedIn: true, rehydrate: false, verifier });
              oauthStream?.write({ selectedAddress });
            }

            const params = new URLSearchParams({
              selectedAddress,
              verifier,
              loggedIn: true,
              rehydrate: false,
              state: payload.calledFromEmbed ? encryptedKeys : ""
            } as any).toString();

            window.location.href = payload.dappRedirectUrl + "?" + params;
            return;
          }
        })
        .catch((err) => {
          console.warn("@ error on initKeyringAsync -> ", err);
          if (payload.localTransition) {
            return;
          }
          const encryptedKeys = encryptUserInfo(JSON.stringify(newKeys));
          if (
            state().wallet.consentConfigurations.scope.length > 0 &&
            state().wallet.consentConfigurations.clientId !== "" &&
            payload.shouldRedirectToConsent
          ) {
            if (
              state().wallet.consentConfigurations.origin === payload.dappRedirectUrl ||
              `http://${state().wallet.consentConfigurations.origin}` === payload.dappRedirectUrl ||
              `https://${state().wallet.consentConfigurations.origin}` === payload.dappRedirectUrl
            ) {
              window.location.href = new URL(window.location.href).origin + "/consent";
              return;
            }
          }

          const statusStream = torus?.communicationMux?.getStream("status") as MuxSubStream;
          if (statusStream && statusStream?.write) {
            statusStream?.write({ loggedIn: true, rehydrate: false, verifier });
          }

          const params = new URLSearchParams({
            verifier,
            loggedIn: true,
            rehydrate: false,
            state: payload.calledFromEmbed ? encryptedKeys : ""
          } as any).toString();
          window.location.href = payload.dappRedirectUrl + "?" + params;
        });
    } catch (error: any) {
      Sentry.captureException(error);
      console.error(`@ -> error on handle login (catch): [login failed!]`, error);
      throw new Error(error);
    }
  };

export const subscribeController = (): TA => () => {
  const upbondController = torus?.upbondController;
  if (upbondController) {
    const {
      prefsController,
      accountTracker,
      encryptionPublicKeyManager,
      decryptMessageManager,
      txController,
      typedMessageManager,
      personalMessageManager,
      messageManager,
      tokenRatesController
    } = upbondController;

    messageManager.store.subscribe(messageManagerHandler);
    personalMessageManager.store.subscribe(personalMessageHandler);

    typedMessageManager.store.subscribe(typedMessageManagerHandler);

    txController.store.subscribe(transactionControllerHandler);
    tokenRatesController.store.subscribe(tokenRatesControllerHandler);

    prefsController.successStore.subscribe(successMsgHandler);
    prefsController.errorStore.subscribe(errorMsgHandler);
    prefsController.store.subscribe(prefsControllerHandler);

    accountTracker.store.subscribe(accountTrackerHandler);

    encryptionPublicKeyManager.store.subscribe(encryptionPublicKeyHandler);
    decryptMessageManager.store.subscribe(unapprovedDecryptMsgsHandler);
  }
};

export const showProviderChangePopup = async ({
  override,
  preopenInstanceId,
  network,
  providerChangeStream
}: {
  preopenInstanceId: string;
  override: boolean;
  network: NetworkType;
  providerChangeStream: MuxSubStream;
}): Promise<any> => {
  try {
    const communicationMux = torus.communicationMux;
    if (communicationMux) {
      // eslint-disable-next-line prettier/prettier
      const getCommunicationMux = (type: (typeof MUX_CHUNK)[keyof typeof MUX_CHUNK]) => communicationMux.getStream(type) as MuxSubStream;
      const widgetStream = getCommunicationMux(MUX_CHUNK.WIDGETSTREAM);
      widgetStream.write({
        data: true
      });
    }
    const windowId = randomId();
    const channelName = `torus_channel_${windowId}`;
    const finalUrl = `${config.baseRoute}confirm?integrity=true&instanceId=${windowId}&id=${windowId}`;
    const providerChangeWindow = new PopupWithBcHandler({
      url: finalUrl,
      preopenInstanceId,
      target: "_blank",
      features: FEATURES_CONFIRM_WINDOW,
      channelName,
      usingIframe: true,
      topCalc: 37
    });
    const result = await providerChangeWindow.handleWithHandshake({
      payload: {
        id: windowId,
        origin: getIFrameOriginObject(),
        type: "provider_change",
        msgParams: undefined,
        network: store.getState().wallet.networkType,
        whiteLabel: store.getState().embedState.embedTheme,
        selectedAddress: store.getState().wallet.selectedAddress,
        selectedCurrency: store.getState().wallet.prefs.selectedCurrency,
        networkDetails: store.getState().wallet.prefs.networkDetails,
        payload: {
          override,
          preopenInstanceId,
          network
        },
        currentNetwork: store.getState().wallet.networkType
      }
    });
    const { approve = false } = result as any;
    if (approve) {
      const isProviderSetled = await setProviderAsync({ network, type: RPC });

      if (isProviderSetled) {
        store.dispatch(setLockChainId({ isLock: true }));
        setTimeout(() => {
          providerChangeStream.write({
            name: "provider_change_status",
            data: {
              success: true
            }
          });
        }, 100);
      } else {
        store.dispatch(setLockChainId({ isLock: false }));
        providerChangeStream.write({
          name: "provider_change_status",
          data: {
            success: false,
            err: "Unknown Error"
          }
        });
      }
    } else {
      store.dispatch(setLockChainId({ isLock: false }));
      providerChangeStream.write({
        name: "provider_change_status",
        data: {
          success: false,
          err: "User denied provider change request"
        }
      });
    }
  } catch (error: any) {
    store.dispatch(setLockChainId({ isLock: false }));
    console.error(error, "@error?");
    Sentry.captureException(error);
    providerChangeStream.write({
      name: "provider_change_status",
      data: {
        success: false,
        err: error.message || "user denied provider change request"
      }
    });
  }
};

export const showUserInfoRequestPopup =
  async (
    payload: { message: string; preopenInstanceId: string },
    stream: {
      userInfoStream: MuxSubStream;
    }
  ): Promise<TA> =>
  async (dispatch, state) => {
    const { preopenInstanceId } = payload;
    const { userInfoStream } = stream;

    const handleDeny = () => {
      dispatch(walletActions.setUserInfoAccess(false));
      userInfoStream.write({ name: "user_info_response", data: { payload: {}, approved: false } });
    };

    const handleSuccess = () => {
      dispatch(walletActions.setUserInfoAccess(true));
      const returnObject = state().wallet.userInfo;
      delete returnObject.verifierParams;
      userInfoStream.write({ name: "user_info_response", data: { payload: returnObject, approved: true } });
    };

    try {
      const windowId = randomId();
      const channelName = `user_info_request_channel_${windowId}`;
      const finalUrl = `${config.baseRoute}userinforequest?integrity=true&instanceId=${windowId}`;
      const userInfoRequestWindow = new PopupWithBcHandler({
        url: finalUrl,
        preopenInstanceId,
        target: "_blank",
        features: FEATURES_PROVIDER_CHANGE_WINDOW,
        channelName
      });
      const result = await userInfoRequestWindow.handleWithHandshake({
        payload: {
          origin: getIFrameOriginObject(),
          payload: { ...payload, typeOfLogin: state().wallet.userInfo.typeOfLogin },
          whiteLabel: state().embedState.embedTheme
        }
      });
      const { approve = false } = result as any;
      if (approve) {
        handleSuccess();
      } else {
        handleDeny();
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error(`@ -> error on showUserInfoRequestPopup (catch): `, error);
      handleDeny();
    }
  };

export const triggerEmbed =
  (payload: OAuthModalData): TA =>
  (_, state) => {
    const { preopenInstanceId, verifier, login_hint, calledFromEmbed } = payload;

    // _(setLoading(true));
    _(embedUiActions.setModalState(false));

    const ebdState = state().embedState;
    const currVerifierConfig = ebdState.configuration.loginConfig[verifier];
    const { embedTheme } = ebdState;

    if (!currVerifierConfig) throw new Error(`Invalid verifier config`);
    const { jwtParameters } = currVerifierConfig;
    const mergedJwtParameters = deepmerge<JwtParameters>(
      {
        login_hint
      },
      jwtParameters || {}
    );
    const loginHandler = new OpenLoginHandler({
      verifier,
      redirect_uri: calledFromEmbed
        ? state().embedState.configuration?.directConfig?.redirectUrl || config.redirect_uri
        : config.redirect_uri,
      preopenInstanceId,
      jwtParameters: { ...mergedJwtParameters, domain: mergedJwtParameters.domain || "" },
      skipTKey: ebdState.configuration.skipTKey,
      whiteLabel: embedTheme,
      loginConfigItem: currVerifierConfig,
      embedState: ebdState,
      usingIframe: calledFromEmbed
    });
    loginHandler.handleLoginWindow(state().embedState.configuration?.directConfig?.redirectUrl).then(console.log);
  };

function resetStore(store: ObservableStore<any>, handler: any, initState?: any) {
  if (initState) store.putState(cloneDeep(initState));
  store.unsubscribe(handler);
}

export const logout = (): TA => (dispatch) => {
  dispatch(walletActions.setInitialState());
  dispatch(embedActions.setInitialState());
  dispatch(embedUiActions.setLoginState(false));
  dispatch(embedUiActions.setModalState(false));
  dispatch(embedUiActions.setWidgetState(false));
  dispatch(balanceAction.reset());
  // allDappModuleLogout(store.getState().olUserModule.currentDappClientId);
  dappModuleLogout();
  // dispatch(olUserModuleAction.logout());
  // dispatch(olTkeyModuleAction.logout());
  // dispatch(olPidModuleAction.cleanup());
  const { communicationMux, upbondController } = torus || {};

  if (upbondController) {
    const {
      prefsController,
      accountTracker,
      encryptionPublicKeyManager,
      decryptMessageManager,
      txController,
      typedMessageManager,
      personalMessageManager,
      messageManager
    } = upbondController;

    resetStore(messageManager.store, messageManagerHandler);
    resetStore(personalMessageManager.store, personalMessageHandler);

    resetStore(typedMessageManager.store, typedMessageManagerHandler);

    resetStore(txController.store, transactionControllerHandler);

    resetStore(prefsController.successStore, successMsgHandler);
    resetStore(prefsController.errorStore, errorMsgHandler);
    resetStore(prefsController.store, prefsControllerHandler, { selectedAddress: "" });

    resetStore(accountTracker.store, accountTrackerHandler);

    resetStore(encryptionPublicKeyManager.store, encryptionPublicKeyHandler);
    resetStore(decryptMessageManager.store, unapprovedDecryptMsgsHandler);
  }

  if (communicationMux) {
    const getCommunicationMux = (type: (typeof MUX_CHUNK)[keyof typeof MUX_CHUNK]) => communicationMux.getStream(type) as MuxSubStream;
    const statusStream = getCommunicationMux("status");
    statusStream.write({ loggedIn: false });
    handleStatus({ loggedIn: false });
  }
  const contact = JSON.parse(localStorage.getItem("contacts") || "[]");
  const privateKey = localStorage.getItem("privateKey") || "";
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "[]");
  const loginDetails = JSON.parse(localStorage.getItem("loginDetails") || "[]");
  const provider = JSON.parse(localStorage.getItem("loginDetails") as any) || (null as any);
  // sessionStorage.clear();
  // localStorage.clear();
  localStorage.setItem("contacts", JSON.stringify(contact));
  if (privateKey !== "") {
    localStorage.setItem("privateKey", JSON.stringify(privateKey));
  }

  if (provider !== "") {
    localStorage.setItem("provider", JSON.stringify(provider));
  }

  localStorage.setItem("userInfo", JSON.stringify(userInfo));
  localStorage.setItem("loginDetails", JSON.stringify(loginDetails));
  Sentry.setUser(null);
};

export const setSelectedAddress =
  ({ selectedAddress }: { selectedAddress: string }): TA =>
  (dispatch) => {
    dispatch(walletActions.setSelectedAddress(selectedAddress));
  };

export const setLoading =
  (loading: boolean, loadingText?: string): TA =>
  (dispatch) => {
    dispatch(walletActions.setLoading({ loading, loadingText }));
  };

export const setNewUser =
  (bool: boolean): TA =>
  (dispatch) => {
    dispatch(walletActions.setNewUser(bool));
  };

export const setSuccessMsg =
  ({ msg }: { msg: string }): TA =>
  (dispatch) => {
    dispatch(walletActions.setSuccessMsg(msg));
  };

export const setErrorMsg =
  ({ msg }: { msg: string }): TA =>
  (dispatch) => {
    dispatch(walletActions.setErrorMsg(msg));
  };

export const setUnapprovedEncryptionPublicKeyMsgs =
  ({ unapprovedEncryptionPublicKeyMsgs }: { unapprovedEncryptionPublicKeyMsgs: any }): TA =>
  (dispatch) => {
    dispatch(walletActions.setUnapprovedEncryptionPublicKeyMsgs(unapprovedEncryptionPublicKeyMsgs));
  };

export const setUnapprovedDecryptMsgs =
  ({ unapprovedDecryptMsgs }: { unapprovedDecryptMsgs: any }): TA =>
  (dispatch) => {
    dispatch(walletActions.setUnapprovedDecryptMsgs(unapprovedDecryptMsgs));
  };

export const setWeiBalance =
  ({ account, balance }: { account: string; balance: string | number }): TA =>
  (dispatch) => {
    dispatch(walletActions.setWeiBalance({ account, balance }));
  };

export const updateNetworkDetail =
  ({ networkDetails }: { networkDetails: { [x: string]: any } }): TA =>
  (dispatch) => {
    dispatch(walletActions.updateNetworkDetails(networkDetails));
  };

export const updateNetworkId =
  ({ networkId }: { networkId: number }): TA =>
  (dispatch) => {
    dispatch(walletActions.updateNetworkId(networkId));
  };

export const setUnapprovedMsgs =
  ({ unapprovedMsgs }: { unapprovedMsgs: { [x: string]: any } }): TA =>
  (dispatch) => {
    dispatch(walletActions.setUnapprovedMessage(unapprovedMsgs));
  };

export const setPersonalMessages =
  ({ unapprovedPersonalMsgs }: { unapprovedPersonalMsgs: { [x: string]: any } }): TA =>
  (dispatch) => {
    dispatch(walletActions.setPersonalMessages(unapprovedPersonalMsgs));
  };

export const setLockChainId =
  ({ isLock }: { isLock: boolean }): TA =>
  (dispatch) => {
    dispatch(walletActions.setChainIdLock(isLock));
  };

export const setSelectedCurrency =
  (payload: any): TA =>
  (dispatch) => {
    const { upbondController } = torus || {};
    upbondController?.setCurrentCurrency(payload, (err, data) => {
      if (err) console.error("currency fetch failed");
      else dispatch(walletActions.setSelectedCurrency(data.currentCurrency));
    });
  };

export const setTransactions =
  (transactions: any): TA =>
  (dispatch) => {
    dispatch(walletActions.setTransactions(transactions));
  };

export const setTkeyString =
  (tkey: string): TA =>
  (dispatch) => {
    dispatch(walletActions.setTkeyString(tkey));
  };

export const showWalletWindow = ({ path }: { path: string }) => {
  const finalUrl = `${config.baseRoute}${path}?integrity=true&instanceId=${torus.instanceId}`;
  const walletWindow = new PopupHandler({ url: finalUrl, features: FEATURES_DEFAULT_WALLET_WINDOW, target: "_blank" });
  walletWindow.open(false);
};

export const showWalletOnNewTab = ({ path, target }: { path: string; target: TargetType }) => {
  const finalUrl = `${window.location.origin}${path}?integrity=true&instanceId=${torus.instanceId}`;
  window.open(finalUrl, target)?.focus();
};

export const setOpenModalPurchase =
  (payload: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    dispatch(walletActions.setOpenModalPurchase(payload));
  };

export const currencyMultiplier = (state?: any) => {
  const currencyMultiplierNumber =
    state.wallet.prefs.selectedCurrency !== state.wallet.networkType.ticker
      ? state.wallet.prefs.currencyData[(state.wallet.prefs.selectedCurrency as string).toLowerCase()] || 1
      : 1;
  return new BigNumber(currencyMultiplierNumber);
};

export const updateCalculatedTx =
  (payload: any): TA =>
  (dispatch, state) => {
    const { upbondController } = torus || {};
    const { prefsController } = upbondController || {};
    for (const id in payload) {
      const txOld = payload[id];
      if (txOld.metamaskNetworkId.toString() === state().wallet.prefs.networkId.toString() && parseInt(id) >= 0) {
        const { methodParams, contractParams, txParams, transactionCategory, time, hash, status } = txOld;
        let amountTo: any;
        let amountValue: any;
        let assetName: any;
        let totalAmount: any;
        let finalTo: any;
        let tokenRate: any = 1;
        let type: any;
        let typeName: any;
        let typeImageLink: any;
        let symbol: any;

        if (contractParams.erc1155) {
          [, amountTo, amountValue] = methodParams || [];
          const { name = "", logo } = contractParams;
          const selectedAddressAssets = state().wallet.assets[state().wallet.selectedAddress];
          if (selectedAddressAssets) {
            const contract = selectedAddressAssets.find((x: any) => x.address?.toLowerCase() === txParams.to?.toLowerCase()) || {};
            if (contract) {
              const { name: foundAssetName } =
                (contract.assets || []).find((x: any) => x.tokenId?.toString() === amountValue?.value?.toString()) || {};
              assetName = foundAssetName || "";
              symbol = assetName;
              type = "erc1155";
              typeName = contract.name || name;
              typeImageLink = contract.logo || logo;
              totalAmount = fromWei(toBN(txParams.value || 0));
              finalTo =
                amountTo &&
                isAddressByChainId(amountTo.value, state().wallet.networkType.chainId) &&
                toChecksumAddressByChainId(amountTo.value, state().wallet.prefs.networkId);
            } else {
              tokenRate = 1;
              symbol = state().wallet.networkType.ticker;
              type = "eth";
              typeName = state().wallet.networkType.ticker;
              typeImageLink = "n/a";
              totalAmount = fromWei(toBN(txParams.value || 0));
              finalTo = toChecksumAddressByChainId(txParams.to, state().wallet.prefs.networkId);
            }
          }
        } else if (contractParams.erc721) {
          // Handling cryptokitties
          if (contractParams.isSpecial) {
            [amountTo, amountValue] = methodParams || [];
          } else {
            // Rest of the 721s
            [, amountTo, amountValue] = methodParams || [];
          }
          const { name = "", logo } = contractParams;
          // Get asset name of the 721
          const selectedAddressAssets = state().wallet.assets[state().wallet.selectedAddress];
          if (selectedAddressAssets) {
            const contract = selectedAddressAssets.find((x: any) => x.address?.toLowerCase() === txParams.to?.toLowerCase() || "") || {};
            if (contract) {
              const { name: foundAssetName } =
                (contract.assets || []).find((x: any) => x.tokenId?.toString() === amountValue.value?.toString()) || {};
              assetName = foundAssetName || "";
              symbol = assetName;
              type = "erc721";
              typeName = contract.name || name;
              typeImageLink = contract.logo || logo;
              totalAmount = fromWei(toBN(txParams.value || 0));
              finalTo =
                transactionCategory === TRANSACTION_TYPES.COLLECTIBLE_METHOD_SAFE_TRANSFER_FROM
                  ? amountTo &&
                    isAddressByChainId(amountTo.value, state().wallet.networkType.chainId) &&
                    toChecksumAddressByChainId(amountTo.value, state().wallet.prefs.networkId)
                  : toChecksumAddressByChainId(txParams.to, state().wallet.prefs.networkId);
            } else {
              // there might be a case when user has the asset but it is not present in state().wallet
              // in that case we can record it as a contract interaction transaction.
              tokenRate = 1;
              symbol = state().wallet.networkType.ticker;
              type = "eth";
              typeName = state().wallet.networkType.ticker;
              typeImageLink = "n/a";
              totalAmount = fromWei(toBN(txParams.value || 0));
              finalTo = toChecksumAddressByChainId(txParams.to, state().wallet.prefs.networkId);
            }
          } else {
            tokenRate = 1;
            symbol = state().wallet.networkType.ticker;
            type = "eth";
            typeName = state().wallet.networkType.ticker;
            typeImageLink = "n/a";
            totalAmount = fromWei(toBN(txParams.value || 0));
            finalTo = toChecksumAddressByChainId(txParams.to, state().wallet.prefs.networkId);
          }
        } else if (contractParams.erc20) {
          // ERC20 transfer
          try {
            tokenRate = state().wallet.prefs.tokenRates[txParams.to];
            if (methodParams && Array.isArray(methodParams)) {
              if (
                transactionCategory === TRANSACTION_TYPES.TOKEN_METHOD_TRANSFER_FROM ||
                transactionCategory === TRANSACTION_TYPES.COLLECTIBLE_METHOD_SAFE_TRANSFER_FROM
              ) {
                [, amountTo, amountValue] = methodParams || [];
              } else {
                [amountTo, amountValue] = methodParams || [];
              }
            }
            const { symbol: contractSymbol, name, logo, decimals } = contractParams;

            symbol = contractSymbol;
            type = "erc20";
            typeName = name || "ERC20";
            typeImageLink = logo;
            const bnAmount = new BigNumber(amountValue && amountValue.value ? amountValue.value : txParams.value || 0);
            totalAmount = bnAmount.div(new BigNumber(10).pow(new BigNumber(decimals || 18))).toString();
            finalTo =
              amountTo &&
              isAddressByChainId(amountTo.value, state().wallet.networkType.chainId) &&
              toChecksumAddressByChainId(amountTo.value, state().wallet.prefs.networkId);
          } catch (error: any) {
            Sentry.captureException(error);
            console.error(`@ -> error on updateCalculatedTx (catch): `, error);
            throw new Error(error);
          }
        } else {
          tokenRate = 1;
          symbol = state().wallet.networkType.ticker;
          type = "eth";
          typeName = state().wallet.networkType.ticker;
          typeImageLink = "n/a";
          totalAmount = fromWei(toBN(txParams.value || 0));
          finalTo = toChecksumAddressByChainId(txParams.to, state().wallet.prefs.networkId);
        }

        const txObject = {
          created_at: new Date(time),
          from: toChecksumAddressByChainId(txParams.from, state().wallet.prefs.networkId),
          to: finalTo,
          total_amount: totalAmount,
          gas: txParams.gas,
          gasPrice: txParams.gasPrice,
          symbol,
          nonce: txParams.nonce,
          type,
          type_name: typeName,
          type_image_link: typeImageLink,
          currency_amount: ((currencyMultiplier(state()) as any) * Number.parseFloat(totalAmount) * tokenRate).toString(),
          selected_currency: state().wallet.prefs.selectedCurrency,
          status,
          network: state().wallet.networkType.host,
          transaction_hash: hash,
          transaction_category: transactionCategory,
          is_cancel: txParams.is_cancel
        };

        prefsController?.patchNewTx(txObject, state().wallet.selectedAddress);
      }
    }
  };
