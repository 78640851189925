import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MfaLevelType } from "@toruslabs/openlogin";

import { DappModule } from "interfaces/actions/IOIDappModule";
import { merge } from "lodash-es";
import log from "loglevel";
import { ERROR_MISSING_PARAMS } from "shared/utils/openloginenums";
import { LoginConfigItem, SUPPORTED_KEY_CURVES_TYPE, TouchIDPreferencesType } from "shared/utils/openlogininterface";

const initDappModuleState: { dappModules: Record<string, DappModule> } = {
  dappModules: {}
};
const dappModuleSlice = createSlice({
  name: "olAllDappModule",
  initialState: initDappModuleState,
  reducers: {
    addDappModule(state, action: PayloadAction<DappModule>): void {
      const key = action.payload.clientId;
      state.dappModules = { ...state.dappModules, [key]: action.payload };
    },

    setLoginParams(
      state,
      action: PayloadAction<{
        clientId: string;
        currentLoginProvider: string;
        skipTKey?: boolean;
        redirectUrl?: string;
        getWalletKey?: boolean;
        mfaLevel?: MfaLevelType;
        appState?: string;
        dappShare?: string;
        sessionTime?: number;
        sessionId?: string;
        curve?: string;
        mobileOrigin?: string;
        _sessionNamespace?: string;
      }>
    ): void {
      const {
        clientId,
        skipTKey,
        currentLoginProvider,
        redirectUrl,
        getWalletKey,
        mfaLevel,
        appState,
        dappShare,
        sessionTime,
        sessionId,
        curve,
        mobileOrigin,
        _sessionNamespace
      } = action.payload;
      if (clientId) state.dappModules[clientId].clientId = clientId;
      // This will throw if not valid url
      try {
        if (redirectUrl) state.dappModules[clientId].redirectUrl = new URL(redirectUrl).href;
      } catch (error) {
        log.error(error);
        throw new Error(ERROR_MISSING_PARAMS);
      }
      if (currentLoginProvider) state.dappModules[clientId].currentLoginProvider = currentLoginProvider;
      if (getWalletKey !== undefined) state.dappModules[clientId].getWalletKey = getWalletKey;
      if (mfaLevel) state.dappModules[clientId].mfaLevel = mfaLevel;
      if (appState) state.dappModules[clientId].appState = appState;
      if (dappShare) state.dappModules[clientId].dappShare = dappShare;
      if (sessionTime) state.dappModules[clientId].sessionTime = sessionTime;
      state.dappModules[clientId].sessionId = sessionId || "";
      if (curve) state.dappModules[clientId].curve = curve as SUPPORTED_KEY_CURVES_TYPE;
      if (skipTKey !== undefined) state.dappModules[clientId].skipTkey = skipTKey;
      if (mobileOrigin) state.dappModules[clientId].mobileOrigin = mobileOrigin as string;
      state.dappModules[clientId]._sessionNamespace = _sessionNamespace || "";
    },
    modifyCustomLoginConfig(state, action: PayloadAction<{ cfg: LoginConfigItem; loginProvider: string; clientId: string }>) {
      const { cfg, loginProvider } = action.payload;
      const localConfig: any = { ...state.dappModules[action.payload.clientId].customLoginConfig };
      const currCfg = localConfig[loginProvider];

      if (!currCfg) {
        if (!cfg.verifierSubIdentifier) {
          // prevent verifierSubIdentifier from being overridden later in get loginConfig function.
          localConfig[loginProvider] = { ...cfg, verifierSubIdentifier: "", loginProvider };
        } else {
          localConfig[loginProvider] = { ...cfg, loginProvider };
        }
      } else if (
        Object.prototype.hasOwnProperty.call(cfg, "verifier") &&
        cfg.verifier !== currCfg.verifier &&
        !Object.prototype.hasOwnProperty.call(cfg, "verifierSubIdentifier") &&
        Object.prototype.hasOwnProperty.call(localConfig[loginProvider], "verifierSubIdentifier")
      ) {
        // a custom verifier might be sent without verifierSubIdentifier key
        // in custom config, in that case we should prevent it from getting overriden
        // by default loginProvider verifierSubIdentifier.
        localConfig[loginProvider] = { ...merge(currCfg, cfg), verifierSubIdentifier: "", loginProvider };
      } else {
        localConfig[loginProvider] = { ...merge(currCfg, cfg), loginProvider };
      }

      const finalConfigItem = localConfig[loginProvider];
      if (finalConfigItem && !finalConfigItem.walletVerifier) finalConfigItem.walletVerifier = finalConfigItem.verifier;

      state.dappModules[action.payload.clientId].customLoginConfig = localConfig;
    },
    setTouchIDPreference(state, action: PayloadAction<{ clientId: string; touchIDPreference: TouchIDPreferencesType }>): void {
      state.dappModules[action.payload.clientId].touchIDPreference = action.payload.touchIDPreference;
    },
    updateState(state, action: PayloadAction<{ clientId: string; data: Partial<DappModule> }>): void {
      const {
        touchIDPreference,
        customLoginConfig,
        privateKey,
        clientId,
        redirectUrl,
        currentLoginProvider,
        whiteLabel,
        idToken,
        sessionId,
        _sessionNamespace,
        sessionTime,
        dappShare,
        embedWlRedirectUrl
      } = action.payload.data;
      if (state === undefined) return;
      if (touchIDPreference !== undefined) state.dappModules[action.payload.clientId].touchIDPreference = touchIDPreference;
      if (customLoginConfig !== undefined) state.dappModules[action.payload.clientId].customLoginConfig = customLoginConfig;
      if (privateKey !== undefined) state.dappModules[action.payload.clientId].privateKey = privateKey;
      if (clientId !== undefined) state.dappModules[action.payload.clientId].clientId = clientId;
      if (redirectUrl !== undefined) state.dappModules[action.payload.clientId].redirectUrl = redirectUrl;
      if (currentLoginProvider !== undefined) state.dappModules[action.payload.clientId].currentLoginProvider = currentLoginProvider;
      if (whiteLabel !== undefined) state.dappModules[action.payload.clientId].whiteLabel = whiteLabel;
      if (idToken !== undefined) state.dappModules[action.payload.clientId].idToken = idToken;
      if (sessionId !== undefined) state.dappModules[action.payload.clientId].sessionId = sessionId;
      if (_sessionNamespace !== undefined) state.dappModules[action.payload.clientId]._sessionNamespace = _sessionNamespace;
      if (sessionTime !== undefined) state.dappModules[action.payload.clientId].sessionTime = sessionTime;
      if (dappShare !== undefined) state.dappModules[action.payload.clientId].dappShare = dappShare;
      if (embedWlRedirectUrl !== undefined) state.dappModules[action.payload.clientId].embedWlRedirectUrl = embedWlRedirectUrl;
    }
  }
});

export default dappModuleSlice;
