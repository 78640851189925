// @ts-nocheck
import { IonButton, IonItem, IonLabel, IonList } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { changeArrWc, fetchClose } from "shared/actions/walletConnectAction";
import { approveEIP155Request, rejectEIP155Request } from "shared/utils/walletconnect/EIP155RequestHandlerUtil";
import { getSignParamsMessage } from "shared/utils/walletconnect/HelperUtil";
import { legacySignClient } from "shared/utils/walletconnect/LegactWalletConnectUtil";

import { IModalCloseWalletConnect } from "interfaces/actions/IWalletConnect";
import { useTranslation } from "react-i18next";
import "./styles/index.css";
import useWallet from "hooks/useWallet";

export default function LegacySessionSignModal(props: IModalCloseWalletConnect) {
  const { closeTheModalWalletConnect } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { ethWallet } = useWallet({
    onError(error) {
      console.warn(error);
    }
  });
  const walletConnect = useAppSelector((state) => state.walletConnect);

  // Get request and wallet data from store
  const requestEvent = walletConnect.data?.legacyCallRequestEvent;
  const requestSession = walletConnect.data?.legacyRequestSession;
  if (!requestEvent || !requestSession) {
    return (
      <>
        <IonLabel className="font-semibold text-base leading-[19px] text-[#0a0a0a] flex justify-center p-5 mb-2.5 h-[1px]">
          {t("notification.confirmRequest")}
        </IonLabel>
        <IonItem lines="full" className="modal-confirmation-header">
          <div className="w-full">
            <div className="flex justify-between mb-2.5 w-full h-auto">
              <p className="break-all font-semibold">{t("settings.missingRequestData")}</p>
            </div>
          </div>
        </IonItem>
        <IonList className="py-4 px-[22px] font-normal text-base leading-[19px] text-[#0a0a0a]" lines="none">
          <IonItem className="modal-confirmation-button-wrapper" lines="none">
            <IonButton onClick={() => onReject()} className="modal-confirmation-btn-reject no-padding-no" size="default" shape="round">
              {t("notification.reject")}
            </IonButton>
          </IonItem>
        </IonList>
      </>
    );
  }

  // Get required request data
  const { id, method, params } = requestEvent;

  // Get message, convert it to UTF8 string if it is valid hex
  const cekParamLength = params[0].length > 64 ? params : [params[1], params[0]];
  const message = cekParamLength[0].length <= 66 ? "" : getSignParamsMessage(cekParamLength);
  // Handle approve action (logic varies based on request method)
  async function onApprove() {
    dispatch(changeArrWc(walletConnect));
    closeTheModalWalletConnect(false);
    if (requestEvent && ethWallet) {
      const response = await approveEIP155Request(ethWallet, {
        id,
        topic: "",
        params: {
          request: { method, params },
          chainId: requestSession?.chainId !== null && requestSession?.chainId !== undefined ? requestSession?.chainId.toString() : "137"
        }
      });

      if ("error" in response) {
        legacySignClient.rejectRequest({
          id,
          error: response.error
        });
      } else {
        legacySignClient.approveRequest({
          id,
          result: response.result
        });
      }
    }
    dispatch(fetchClose());
  }

  // Handle reject action
  async function onReject() {
    dispatch(changeArrWc(walletConnect));
    closeTheModalWalletConnect(false);
    dispatch(fetchClose());
    if (requestEvent) {
      const { error } = rejectEIP155Request({
        id,
        topic: "",
        params: {
          request: { method, params },
          chainId: requestSession?.chainId !== null && requestSession?.chainId !== undefined ? requestSession?.chainId.toString() : "137"
        }
      });
      legacySignClient.rejectRequest({
        id,
        error
      });
    }
  }

  return (
    <>
      <IonLabel className="font-semibold text-base leading-[19px] text-[#0a0a0a] flex justify-center p-5 mb-2.5 h-[1px]">
        {t("notification.confirmRequest")}
      </IonLabel>
      <IonItem lines="full" className="modal-confirmation-header">
        <div className="w-full">
          <div className="flex justify-between mb-2.5 w-full h-auto">
            <p>{t("notification.requestFrom")}</p>

            <p className="break-all font-semibold">{requestSession.peerMeta?.url.replace(/(^\w+:|^)\/\//, "")}</p>
          </div>
        </div>
      </IonItem>
      <IonList className="py-4 px-[22px] font-normal text-base leading-[19px] text-[#0a0a0a]" lines="none">
        <IonLabel>{message == "" ? cekParamLength[0].toString() : message}</IonLabel>
        <IonItem className="modal-confirmation-button-wrapper" lines="none">
          <IonButton onClick={() => onReject()} className="modal-confirmation-btn-reject no-padding-no" size="default" shape="round">
            {t("notification.reject")}
          </IonButton>
          <IonButton onClick={() => onApprove()} className="no-padding-no" size="default" shape="round">
            {t("notification.confirm")}
          </IonButton>
        </IonItem>
      </IonList>
    </>
  );
}
