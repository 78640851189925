import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { State } from "interfaces/actions/IWalletConnect";
import { RootState } from "shared/store";
import walletArrConnectSlice from "shared/store/walletConnectArrSlice";
import walletConnectSlice from "shared/store/walletConnectSlice";

export const walletConnectAction = walletConnectSlice.actions;
export const walletConnectArrAction = walletArrConnectSlice.actions;

type TA<T = void, S = RootState, E = unknown> = ThunkAction<T, S, E, AnyAction>;
export const fetchOpen =
  (item: State): TA =>
  async (dispatch) => {
    dispatch(walletConnectAction.setOpen(item));
    // const oldArrWc = arrWc.filter((oldItem) => oldItem["data"]?.proposal?.id === item.data?.proposal?.id);
    // oldArrWc.length <= 0 &&
  };

export const fetchClose = (): TA => async (dispatch) => {
  dispatch(walletConnectAction.setClose(false));
};

export const fetchArrWalletConnect =
  (data: State): TA =>
  async (dispatch, getState) => {
    const arrWc: State[] = [];
    if (data.open === true) {
      if (getState().walletConnectArrData.dataArr.length > 0) {
        const arr_data = getState().walletConnectArrData.dataArr;
        const lastArrDataID = arr_data[arr_data.length - 1].data?.requestEvent?.id;
        const currentDataID = data.data?.requestEvent?.id;
        console.log(currentDataID, lastArrDataID, currentDataID === lastArrDataID);

        let combineStateValue = [...arr_data];
        if (currentDataID !== lastArrDataID) {
          combineStateValue = [...arr_data, data];
        }

        const uniqueStateValue = combineStateValue.filter((v, i, a) => a.indexOf(v) === i);
        dispatch(walletConnectArrAction.setDataArrWc(uniqueStateValue));
      } else {
        arrWc.push(data);
        dispatch(walletConnectArrAction.setDataArrWc(arrWc));
      }
    }
    // dispatch(walletConnectArrAction.setCountArr(getState().walletConnectArrData.dataArr.length));
  };

export const changeArrWc =
  (data: State): TA =>
  async (dispatch, getState) => {
    const indexOfObject = getState().walletConnectArrData.dataArr.filter((object: any) => {
      if (data.data?.requestEvent) {
        return object.data?.requestEvent?.id !== data.data?.requestEvent?.id;
      }
      if (data.data?.legacyCallRequestEvent) {
        return object.data?.legacyCallRequestEvent?.id !== data.data?.legacyCallRequestEvent?.id;
      }
    });
    dispatch(walletConnectArrAction.setDataArrWc(indexOfObject));
    // getState().walletConnectArrData.dataArr.splice(indexOfObject, 1);
  };
