// @ts-nocheck
/* eslint-disable jsx-a11y/alt-text */
// @typescript-eslint/no-unused-vars
import WidgetCard from "components/popups/PopupWidgetCard";
import { useState } from "react";
import { IonCard, IonText } from "@ionic/react";
import { truncateAddress } from "helpers/stringHelper";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { embedActions } from "shared/actions/embedAction";
import useCommunicationStream from "hooks/useCommunicationStream";
import { useEmbedUiDispatch } from "hooks/useEmbedUiDispatch";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import { setOpenModalPurchase, showWalletOnNewTab } from "shared/actions/walletAction";
import DefaultPhotoIcon from "assets/icons/default-photo.svg";

const WalletWidget = () => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const dispatcher = useEmbedUiDispatch();
  const { embedTheme, iFrameConfiguration } = useAppSelector((state) => state.embedState);
  const { userInfo, selectedAddress } = useAppSelector((state) => state.wallet);
  const embedUiState = useAppSelector((state) => state.embedUiState);
  const openModalPurchase = useAppSelector((state) => state.wallet.openModalPurchase);

  const [hover, sethover] = useState("");

  const { openPopup } = useCommunicationStream();

  const getColor = () => {
    const modalcolor = embedTheme?.modalColor === "#fffff";
    const bgcolor = embedTheme?.bgColor === "#4B68AE";
    const bgcolorhover = embedTheme?.bgColorHover === "#214999";
    const textcolor = embedTheme?.textColor === "#f3f3f3";
    const textcolorhover = embedTheme?.textColorHover === "#214999";

    if (modalcolor && bgcolor && bgcolorhover && textcolor && textcolorhover) {
      // this condition is for checking the old default primary color then replaced by the new one
      return {
        modalColor: "#ffffff",
        bg: "#4B68AE",
        bgHover: "#214999",
        text: "#f3f3f3",
        textColorHover: "#ffffff"
      };
    } else {
      return {
        modalColor: embedTheme?.modalColor || "#fafafa",
        bg: embedTheme?.bgColor || "#4B68AE",
        bgHover: embedTheme?.bgColorHover || "#224999",
        text: embedTheme?.textColor || "#f3f3f3",
        textColorHover: embedTheme?.textColorHover || "#f3f3f3"
      };
    }
  };

  const ProfilePicture = () => {
    if (userInfo && userInfo.profileImage) {
      return (
        <img
          alt={userInfo.name}
          src={userInfo.profileImage}
          className="w-10 h-10"
          onError={({ currentTarget }) => {
            currentTarget.src = DefaultPhotoIcon;
            currentTarget.onerror = null;
            return null;
          }}
        />
      );
    } else {
      return <img alt={userInfo.name} src={DefaultPhotoIcon} />;
    }
  };

  return (
    <>
      <button
        className={
          embedUiState.widgetIsOpen && window.innerWidth > 70
            ? "bg-[rgba(0, 0, 0, 0.3)] content-[''] w-full h-full absolute"
            : "bg-none w-full h-full absolute"
        }
        id="embed-widget-button"
        onClick={() => {
          dispatcher("widgetIsOpen", !embedUiState.widgetIsOpen);
          openPopup(!embedUiState.widgetIsOpen, embedUiState.widgetIsOpen);
          if (embedUiState.widgetIsOpen) {
            dispatch(
              embedActions.setIFrameConfig({
                open: false,
                src: `${process.env.REACT_APP_BASE_URL}`,
                origin: "",
                topCalc: 3
              })
            );
          }
        }}></button>
      <WidgetCard>
        <IonCard
          className={`${iFrameConfiguration.open ? "w-[365px]" : "w-[355px]"} p-5 flex flex-col justify-between items-start gap-4`}
          style={{ backgroundColor: getColor().modalColor }}>
          <div className="profile w-full">
            <span className="border-2 border-solid border-black rounded-[84.375px] w-max h-max max-w-[40px] max-h-[40px] overflow-hidden flex justify-center items-center">
              <ProfilePicture />
            </span>
            <span className="flex flex-col ml-2 w-[85%]">
              <IonText className="font-roboto not-italic font-semibold text-sm leading-4 text-black mb-1">{userInfo.email || ""}</IonText>
              <IonText className="font-roboto not-italic font-normal text-sm leading-4">{truncateAddress(selectedAddress || "")}</IonText>
            </span>
          </div>
          <span className="border border-solid border-E4E4E4 flex-none order-none self-stretch grow-0"></span>
          <div className="w-full flex justify-between mb-5">
            <button
              onMouseEnter={() => sethover("openWallet")}
              onMouseLeave={() => sethover("")}
              className="font-inter not-italic font-bold text-xs rounded-[100px] items-center py-2.5 px-3.5"
              style={{
                // backgroundColor: iFrameConfiguration.open ? `red` : getColor().bg,
                backgroundColor: hover === "openWallet" ? getColor().bgHover : getColor().bg,
                color: hover === "openWallet" ? getColor().textColorHover : getColor().text
              }}
              onClick={() => {
                openModalPurchase == true && appDispatch(setOpenModalPurchase(false));
                showWalletOnNewTab({ path: "/wallet/home", target: "_blank" });
              }}>
              {iFrameConfiguration.open ? t("walletWidget.closeWallet") : t("walletWidget.openWallet")}
            </button>
            <button
              onMouseEnter={() => sethover("viewHistory")}
              onMouseLeave={() => sethover("")}
              className="font-inter not-italic font-bold text-xs rounded-[100px] items-center py-2.5 px-3.5"
              style={{
                backgroundColor: hover === "viewHistory" ? getColor().bgHover : getColor().bg,
                color: hover === "viewHistory" ? getColor().textColorHover : getColor().text
              }}
              onClick={() => showWalletOnNewTab({ path: "/wallet/account", target: "_blank" })}>
              {t("walletWidget.viewHistory")}
            </button>
            <button
              onMouseEnter={() => sethover("addCash")}
              onMouseLeave={() => sethover("")}
              className="font-inter not-italic font-bold text-xs rounded-[100px] items-center py-2.5 px-3.5"
              style={{
                backgroundColor: hover === "addCash" ? getColor().bgHover : getColor().bg,
                color: hover === "addCash" ? getColor().textColorHover : getColor().text
              }}
              onClick={() => {
                openModalPurchase == false && appDispatch(setOpenModalPurchase(true));
                showWalletOnNewTab({ path: "/wallet/home", target: "_blank" });
              }}>
              {t("walletWidget.addCash")}
            </button>
          </div>
          <div className="w-full h-10"></div>
        </IonCard>
      </WidgetCard>
    </>
  );
};

export default WalletWidget;
