import useTheme from "hooks/useTheme";
import { useState } from "react";
import { setShowTestnet } from "shared/actions/balanceAction";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { fetchSelectedHistoryChain } from "shared/actions/historyChain";
import NetworkMainMb from "features/settings/components/networkMain/mb";
import NetworkMainPc from "features/settings/components/networkMain/pc";

const NetworkPage = () => {
  const balance = useAppSelector((state) => state.balance);
  const [statusShow, setStatusShow] = useState<boolean>(balance.showTestnet);
  const { breakpoint } = useTheme();

  const dispatch = useAppDispatch();
  const handleChangeNetwork = (val: boolean) => {
    dispatch(setShowTestnet(val));
    setStatusShow(val);
    if (!val) dispatch(fetchSelectedHistoryChain("137"));
  };

  return breakpoint < 1024 ? (
    <NetworkMainMb statusShow={statusShow} handleChangeNetwork={handleChangeNetwork} />
  ) : (
    <NetworkMainPc statusShow={statusShow} handleChangeNetwork={handleChangeNetwork} />
  );
};

export default NetworkPage;
