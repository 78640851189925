// @ts-nocheck
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonInput,
  IonLabel,
  IonPage,
  IonRow
} from "@ionic/react";
import ContactBar from "features/transfer/components/ContactBar";
import GasModal from "features/transfer/components/GasModal";
import { useTranslation } from "react-i18next";
import { ConfirmMainProps } from "features/transfer/confirm/types";
import HeaderMainPc from "components/header/pc";

const ConfirmMainPc = (props: ConfirmMainProps) => {
  const { t } = useTranslation();

  return (
    <IonPage className="p-0">
      <HeaderMainPc title={t("transfer.confirmTransferTitle")} />

      <IonContent className="bg-base p-0">
        <IonGrid className="-mt-5">
          <IonRow>
            <IonCol size="6">
              <IonCard>
                <IonCardContent>
                  <ContactBar />
                  <div className="pb-4">
                    <IonLabel position="stacked">{t("transfer.amountToSend")}</IonLabel>
                    <div className="flex flex-row justify-between items-center py-1 px-4 rounded-custom border border-[#d3d3d3] my-2.5">
                      <IonInput type="text" value={props.amountToSend} onIonChange={(e) => props.amountChanged(e)} />
                    </div>
                  </div>
                  <div>
                    <IonButton
                      expand="block"
                      className="h-12"
                      color={props.isConfirmDisabled ? "medium" : "primary"}
                      onClick={props.proceedTransfer}
                      disabled={props.isConfirmDisabled}>
                      {t("transfer.proceed")}
                    </IonButton>
                  </div>
                  <GasModal />
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonFooter></IonFooter>
    </IonPage>
  );
};

export default ConfirmMainPc;
