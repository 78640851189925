import React, { useState } from "react";
import { useEffect } from "react";
import { safeatob } from "@toruslabs/openlogin-utils";
import { ACCOUNT_TYPE } from "shared/enums";
import { BN } from "ethereumjs-util";
import Torus from "@toruslabs/torus.js";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useDispatch } from "react-redux";
import { handleLogin, Keys, walletActions } from "shared/actions/walletAction";
import { OpenLoginData, UpbondUserInfo } from "shared/store/walletSlice";
import useLoading from "hooks/useLoading";
import { useTranslation } from "react-i18next";
import { catchError, isMain, storageAvailable } from "shared/utils/coreUtils";
import { postV2UpbondAccount } from "services/UserServices";
import { store } from "shared/store";

const EndPage: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedAddress } = useAppSelector((state) => state.wallet);
  const { directConfig } = useAppSelector((state) => state.embedState.configuration);
  const { olDappModule } = useAppSelector((state) => state);
  const { DisplayLoadingNew, setLoading } = useLoading();
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const [value, setValue] = useState(0);

  useEffect(() => {
    const init = async () => {
      try {
        const href = window.location.href;
        const callback = href?.split("#")[1];
        const urlExtracted = new URL(window.location.origin + "?" + callback);
        const data = new URLSearchParams(urlExtracted.search);
        setLoading(true, `${t("login.loggingIn")}`);
        const result = data.get("result") || "";
        let postboxKey;
        const keys = [];
        const allInfo = store.getState().olUserModule?.userInfo;
        const dappModule = store.getState().olDappModule;
        const userInfo = {
          name: allInfo.name, // first + last name
          profileImage: allInfo.profileImage, // image url
          email: allInfo.email,
          verifier: allInfo.aggregateVerifier, // enum like GOOGLE
          verifierId: allInfo.verifierId, // usually email or facebook id
          verifierParams: { verifier_id: allInfo.verifierId }, // general params
          typeOfLogin: allInfo.typeOfLogin
        } as UpbondUserInfo;
        console.log("end, allInfo", JSON.stringify(allInfo));

        let url: any;
        if (!directConfig.enabled) {
          url = new URL(dappModule.redirectUrl as string);
        } else {
          url = new URL(directConfig.redirectUrl as string);
        }
        setValue(0.98);

        // setValue(0.4);
        dispatch(walletActions.setUserInfo(userInfo));
        // setValue(0.43);
        let allowed = false;
        const allowedHostnameEndings = [
          "localhost",
          "tor.us",
          "openlogin.com",
          "localhost:3000",
          "upbond.io",
          "gohey.com",
          "localhost:3002",
          "gohey.jp"
        ];
        allowedHostnameEndings.forEach((hostnameEnding) => {
          if (url.hostname.endsWith(hostnameEnding)) allowed = true;
        });

        // setValue(0.33);

        if (result) {
          const resultParams = JSON.parse(safeatob(result));
          postboxKey = resultParams.oAuthPrivateKey || "";
          const torus = new Torus();
          if (resultParams.privKey) {
            keys.push({
              privKey: `${olDappModule.privateKey}`,
              accountType: ACCOUNT_TYPE.NORMAL,
              userInfo: userInfo,
              authServiceAccessToken: allowed ? allInfo.accessToken : "",
              ethAddress: torus.generateAddressFromPrivKey(new BN(`${olDappModule.privateKey}`, "hex"))
            });
          }
          dispatch(
            walletActions.setOpenLoginData({
              ed25519PrivKey: resultParams.ed25519PrivKey ? resultParams.ed25519PrivKey : "",
              privKey: olDappModule.privateKey || "",
              tKey: resultParams.tKey || "",
              walletKey: resultParams.walletKey || "",
              oAuthPrivateKey: resultParams.oAuthPrivateKey || "",
              tkeyString: resultParams.tKeyString
            } as OpenLoginData)
          );
        }

        // setValue(0.55);
        if (process.env.NODE_ENV === "development")
          console.info("[info] redirect to", JSON.stringify(allInfo), JSON.stringify(directConfig));

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        let redirectUrl = window.location.origin;
        // setValue(0.6);
        if (directConfig.enabled) {
          const tempRedirectUrl = localStorage.getItem("tempRedirectUrl");
          if (directConfig.redirectUrl) redirectUrl = directConfig.redirectUrl;
          else if (tempRedirectUrl) redirectUrl = tempRedirectUrl;
          else redirectUrl = dappModule.redirectUrl as string;
        } else {
          if (dappModule.redirectUrl) redirectUrl = dappModule.redirectUrl as string;
          else redirectUrl = directConfig.redirectUrl;
          if (url.origin === window.location.origin) redirectUrl = `${window.location.origin}/wallet/home`;
        }
        // setValue(0.65);
        const userInfoString = JSON.stringify(userInfo);
        if (!isMain && storageAvailable("localStorage") && isSafari) {
          localStorage.setItem("cachedUserInfo", userInfoString);
        } else {
          sessionStorage.setItem("cachedUserInfo", userInfoString);
        }

        // setValue(0.7);
        const address = keys.length > 0 ? keys[0].ethAddress : "";
        // const authServiceAccessToken = keys.length > 0 && (keys[0].authServiceAccessToken as string);

        // setValue(0.8);
        if (allInfo.accessToken) {
          const loginProvider = allInfo.state?.currentLoginProvider;
          const provider = loginProvider.replace("upbond-", "");
          await postV2UpbondAccount(allInfo.accessToken, {
            wallet_address: address ?? selectedAddress,
            provider
          });
        }

        setValue(0.99);
        appDispatch(
          handleLogin({
            calledFromEmbed: true,
            dappRedirectUrl: redirectUrl,
            isUsingDirect: true,
            keys: keys as Keys[],
            skipDialog: false,
            userInfo,
            verifier: userInfo.verifier,
            login_hint: undefined,
            postboxKey,
            preopenInstanceId: undefined,
            localTransition: false,
            shouldRedirectToConsent: false
          })
        );
        localStorage.removeItem("tempRedirectUrl");
        setValue(1);
        setLoading(false, `${t("login.loggingIn")}`);
      } catch (err) {
        setLoading(false, "Error occured");
        const tempRedirectUrl = localStorage.getItem("tempRedirectUrl");
        if (tempRedirectUrl) window.location.href = tempRedirectUrl;
        window.location.href = `/login`;
        catchError(err, "[error]: End.tsx error");
      }
    };

    init();
  }, []);

  return (
    <div className="content">
      <DisplayLoadingNew value={value} />
    </div>
  );
};
export default EndPage;
