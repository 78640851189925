import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHistoryTransactionArray, IHistoryGroup } from "interfaces/actions/IHistoryTransaction";
// import { BalanceItems } from "../../interfaces/actions/IToken";

const initHistoryState: IHistoryTransactionArray = {
  history: [],
  isHistoryLoading: true,
  state: "LOADING",
  doneCalculate: false
};

const historyTransactionSlice = createSlice({
  name: "history",
  initialState: initHistoryState,
  reducers: {
    setHistory(state, action: PayloadAction<IHistoryGroup[]>) {
      state.history = action.payload;
      state.doneCalculate = true;
    },
    reset(state) {
      state.history = [];
      state.isHistoryLoading = true;
      state.doneCalculate = false;
    },
    setRemoveLoading(state) {
      state.isHistoryLoading = false;
    },
    setLoading(state) {
      state.isHistoryLoading = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action: AnyAction) => action.type.includes("fetch"),
        (state: any) => {
          state.state = "LOADING";
        }
      )
      .addMatcher(
        (action: AnyAction) => action.type.includes("set"),
        (state: any) => {
          state.state = "DONE";
        }
      );
  }
});

export default historyTransactionSlice;
