// @ts-nocheck
import { IonButton, IonIcon } from "@ionic/react";
import ModalCommingSoon from "components/modals/ModalCommingSoon";

import { copy } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "shared/store";
import { copyToClipboard } from "shared/utils/coreUtils";
import More from "assets/icons/More.svg";

const NoAsset: React.FC<any> = () => {
  const [openModalPurchase, setOpenModalPurchase] = useState(false);
  const [_dolarVal, setDolarVal] = useState<number>(0);
  const { selectedAddress: account } = useSelector((state: RootState) => state.wallet);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  // const { breakpoint } = useTheme();
  useEffect(() => {
    let a = 0;
    if (open) {
      a = window.setTimeout(function () {
        setOpen(!open);
      }, 500);
    } else {
      clearTimeout(a);
    }
  }, [open]);

  return (
    <div className="flex flex-col justify-center items-center">
      <ModalCommingSoon isOpen={openModalPurchase} setOpenCommingSoon={() => setOpenModalPurchase(!openModalPurchase)} />

      <div className="flex flex-col justify-between mt-[4.5rem] gap-6">
        <h1 className="text-2xl text-center mt-0 mx-20 leading-tight">
          <strong>{t("home.buyEth")}</strong>
        </h1>
        <div className="flex flex-row justify-between my-0 mx-8">
          <IonButton
            color="primary"
            className="min-w-[85px]"
            onClick={() => {
              setOpenModalPurchase(true), setDolarVal(i18n.language === "ja" ? 5000 : 30);
            }}>
            {i18n.language === "ja" ? "¥5000" : "$30"}
          </IonButton>
          <IonButton
            color="primary"
            className="min-w-[85px]"
            onClick={() => {
              setOpenModalPurchase(true), setDolarVal(i18n.language === "ja" ? 7000 : 50);
            }}>
            {i18n.language === "ja" ? "¥7000" : "$50"}
          </IonButton>
          <IonButton
            color="primary"
            className="min-w-[85px]"
            onClick={() => {
              setOpenModalPurchase(true), setDolarVal(i18n.language === "ja" ? 10000 : 100);
            }}>
            {i18n.language === "ja" ? "¥10000" : "$100"}
          </IonButton>
        </div>
        <IonButton
          shape="round"
          color="primary"
          fill="outline"
          className="text-15 h-[36px] m-auto"
          onClick={() => setOpenModalPurchase(true)}>
          <IonIcon slot="start" src={More} />
          {t("home.otherAmount")}
        </IonButton>
      </div>
      <div className="flex flex-col justify-between mt-[2.7rem] gap-2">
        <h1 className="text-2xl text-center mt-0 mx-20 leading-tight">
          <strong> {t("home.sendEth")}</strong>
        </h1>
        <p className="mx-8 text-center text-gray-text">{t("home.exchange")}</p>
        <IonButton
          id="copied"
          shape="round"
          color="primary"
          className="text-15 h-[36px] w-[180px] m-auto"
          onClick={() => {
            setOpen(!open);
            copyToClipboard(account);
          }}>
          <IonIcon slot="start" icon={copy} />
          {t("home.copyAddress")}
        </IonButton>
        {open && (
          <div className="tooltip">
            <div className="bottom">
              {t("account.copiedToClipboard")}
              <i></i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NoAsset;
