// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonCard,
  IonCardContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { QrCodeIcon, WalletConnectLogo } from "assets/icons";
import HomeHeaderPc from "features/home/components/HomeHeader/pc";
import { setOpenModalPurchase } from "shared/actions/walletAction";
import useTheme from "hooks/useTheme";
import "../style.css";

const ApplicationMainPc: React.FC<any> = ({
  listChain,
  legacySignClient,
  customAlertOptions,
  changeChainId,
  initialChain,
  uri,
  setUri,
  onConnect,
  setOpenDisconnectModal,
  openQrScanner
}) => {
  const { t } = useTranslation();
  const walletConnect = useAppSelector((state) => state.walletConnect);
  const walletConnectArr = useAppSelector((state) => state.walletConnectArrData);
  const [countNotif, setCountNotif] = useState(0);
  const appDispatch = useAppDispatch();
  const { breakpoint } = useTheme();

  useEffect(() => {
    setCountNotif(walletConnectArr.dataArr.length);
    appDispatch(setOpenModalPurchase(false));
  }, [walletConnect.data, walletConnectArr.dataArr]);

  return (
    <IonPage className="application-content">
      {/* <HeaderMainPc title={t("settings.connectToApp")} link="/wallet/settings/init" pending={true} /> */}

      {/* Applications Menu */}
      <IonContent className="bg-base ion-padding">
        <div className="flex flex-row justify-between lg:justify-end items-center lg:p-4 lg:m-0">
          {breakpoint >= 1024 ? <HomeHeaderPc countNotif={countNotif} showAddCash={false} /> : <></>}
        </div>
        <IonGrid className="pl-4">
          <IonRow>
            <IonCol size="6">
              <IonCard class="ion-no-padding m-0">
                <IonCardContent>
                  {walletConnect.connected === true ? (
                    <>
                      {/* CHANGE NETWORK */}
                      {legacySignClient?.version === 1 && legacySignClient?.connected == true && (
                        <>
                          <div className="flex justify-center items-center mb-5 -mt-[10px]">
                            <IonListHeader className="flex justify-around mt-[8.5px] pl-0 lg:mt-0">
                              <IonLabel className="lg:mt-0">{t("settings.currentNetwork")}</IonLabel>
                              <IonSelect
                                interface="alert"
                                interfaceOptions={customAlertOptions}
                                className="min-w-[25%] rounded-full bg-gray-300 text-base mb-0"
                                placeholder={initialChain?.name}
                                okText={t("settings.save") as string}
                                onIonChange={(event) => changeChainId(event)}>
                                {listChain.map((data: any) => (
                                  <IonSelectOption key={data.chain} value={data.chain}>
                                    {data.name}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonListHeader>
                          </div>
                        </>
                      )}

                      <IonList className="application-connected">
                        <IonLabel> {t("settings.youreConnect")}</IonLabel>
                        <div>
                          <IonButton
                            onClick={() => setOpenDisconnectModal(true)}
                            className="w-[100px] h-9 mt-4 font-medium text-[15px] leading-[18px] text-[#4d4082]">
                            {t("settings.disconnect")}
                          </IonButton>
                        </div>
                      </IonList>
                    </>
                  ) : (
                    <>
                      <div className="flex justify-center py-4 px-0 bg-[#fff]">
                        <img src={WalletConnectLogo} alt="Wallet connect logo" />
                      </div>
                      {/* <IonListHeader className="flex justify-around mt-[8.5px] pl-0 lg:mt-0">
                        <IonLabel className="lg:mt-0">{t("settings.regionRelayer")}</IonLabel>
                        <IonSelect
                          interface="alert"
                          interfaceOptions={customAlertOptions}
                          className="min-w-[25%] rounded-full bg-gray-light text-base mb-0"
                          placeholder={initialChain?.name}
                          defaultChecked={true}
                          defaultValue={REGIONALIZED_RELAYER_ENDPOINTS[0].value}
                          okText={t("settings.save") as string}
                          onIonChange={(event) => changeRelayer(event)}
                          value={wallet.relayerRegionURL}>
                          {REGIONALIZED_RELAYER_ENDPOINTS &&
                            REGIONALIZED_RELAYER_ENDPOINTS.map((data) => (
                              <IonSelectOption key={data.value} value={data.value}>
                                {data.label}
                              </IonSelectOption>
                            ))}
                        </IonSelect>
                      </IonListHeader> */}
                      <IonList className="backup-list text-center">
                        <IonLabel color="dark" className="font-semibold text-2xl leading-7">
                          {t("settings.connectVia")}
                        </IonLabel>
                        <IonButton onClick={openQrScanner} className="my-5 mx-0" shape="round" expand="full" color="primary">
                          <IonIcon src={QrCodeIcon} className="mr-2" />
                          {t("settings.qrCode")}
                        </IonButton>
                        <IonLabel>{t("settings.or")}</IonLabel>
                        <IonItem lines="none" className="application-input !p-0 my-5 mx-0 !text-center">
                          <IonInput
                            inputMode="text"
                            placeholder={`${t("settings.pasteLink")}`}
                            value={uri}
                            onIonChange={(e: any) => setUri(e.target.value)}></IonInput>
                        </IonItem>
                        <IonButton
                          className="my-5 mx-0"
                          shape="round"
                          expand="full"
                          color={uri ? "primary" : "secondary"}
                          disabled={uri === "" && true}
                          onClick={() => {
                            onConnect(uri);
                          }}>
                          <IonIcon src={QrCodeIcon} className="mr-2" />
                          {t("settings.connect")}
                        </IonButton>
                      </IonList>
                    </>
                  )}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ApplicationMainPc;
