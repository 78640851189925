// @ts-nocheck
import React from "react";
import { IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { ellipse } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { PendingTransactionContentType } from "features/pendingTransaction/types";

const PendingTransactionContentMb = ({ i, data, openModalSetId }: PendingTransactionContentType) => {
  const { t } = useTranslation();
  return (
    <div className="p-4">
      <IonItem key={i}>
        <IonIcon src={ellipse} className="text-[#ff5e5e]" slot="start"></IonIcon>
        <IonLabel className="font-bold !text-lg leading-4.75 text-0A0A0A" position="stacked">
          {data.view === "LegacySessionProposalModal" && t("pendingTransaction.dataSignatureRequest")}
          {data.view === "LegacySessionSignTypedDataModal" && t("pendingTransaction.personalSignatureRequest")}
          {data.view === "LegacySessionSignModal" && t("pendingTransaction.dataSignatureRequest")}
          {data.view === "LegacySessionSendTransactionModal" && t("pendingTransaction.signTransactionRequest")}
          {data.view === "wallet_switchEthereumChain" && t("pendingTransaction.switchNetworkRequest")}
          {data.view === "SessionAddNetworkModal" && t("pendingTransaction.switchNetworkRequest")}
          {data.view === "SessionSignModal" && t("pendingTransaction.dataSignatureRequest")}
          {data.view === "SessionSignTypedDataModal" && t("pendingTransaction.personalSignatureRequest")}
          {data.view === "SessionSendTransactionModal" && t("pendingTransaction.signTransactionRequest")}
          {data.view === "SessionUnsuportedMethodModal" && t("pendingTransaction.switchNetworkRequest")}
        </IonLabel>

        {data.data?.requestEvent ? (
          <IonButton onClick={() => openModalSetId(data.data?.requestEvent?.id, 2)} size="default" shape="round">
            {t("notification.confirm")}
          </IonButton>
        ) : (
          <IonButton onClick={() => openModalSetId(data.data?.legacyCallRequestEvent?.id, 1)} size="default" shape="round">
            {t("notification.confirm")}
          </IonButton>
        )}
      </IonItem>
    </div>
  );
};

export default PendingTransactionContentMb;
