import { gql } from "@apollo/client/core";

export const UPDATE_DEVICE = gql`
  mutation UpdateDevice($update_device_payload: UpdateDevicePayload!) {
    deviceId: UpdateDevice(payload: $update_device_payload)
  }
`;

export const DELETE_DEVICE = gql`
  mutation deleteDevice($share_index: String!, $device_id: String!) {
    message: deleteDevice(payload: { share_index: $share_index, device_id: $device_id })
  }
`;

export const FETCH_USER_DEVICES = gql`
  query fetchDevice($limit: Int, $offset: Int, $sort_by: String, $order: String, $id: String) {
    devices: fetchUserDevices(limit: $limit, offset: $offset, sort_by: $sort_by, order: $order, id: $id) {
      updated_at
      id
      share_index
      webauthn_enabled
      webauthn_available
      public_address
    }
  }
`;
