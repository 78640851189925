// @ts-nocheck
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage } from "@ionic/react";
import HeaderMainMb from "components/header/mb";
import { useTranslation } from "react-i18next";
import { checkmarkOutline } from "ionicons/icons";

const CurrencyMainMb: React.FC<any> = ({ currencies, handleChangeCurrency }) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <HeaderMainMb title={t("settings.currency")} link="/settings/init" />

      <IonContent className="ion-padding setting-content">
        <IonList className="backup-list px-4">
          <IonItem className="pb-[15px]">
            <IonLabel onClick={() => handleChangeCurrency("USD")}>USD</IonLabel>
            {currencies === "USD" && <IonIcon src={checkmarkOutline} className="text-2xl pr-1" />}
          </IonItem>
          <IonItem className="pb-[15px]">
            <IonLabel onClick={() => handleChangeCurrency("JPY")}>JPY</IonLabel>
            {currencies === "JPY" && <IonIcon src={checkmarkOutline} className="text-2xl pr-1" />}
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default CurrencyMainMb;
