// @ts-nocheck
import { IonPage, IonRouterOutlet } from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import SettingsPage from "pages/settings";
import ApplicationPage from "pages/application";
import BackupPage from "pages/settings/backup";
import CurrencyPage from "pages/settings/currency";
import NetworkPage from "pages/settings/network";
import LanguagePage from "pages/settings/language";
import PermissionDetailPage from "pages/settings/permissionDetail";
import PermissionManagePage from "pages/settings/permissionManage";
import PersonalInformationPage from "pages/settings/personalInformation";
import PersonalInformationEditPage from "pages/settings/personalInformationEdit";

const SettingRoutes = () => (
  <IonPage>
    <IonRouterOutlet>
      <Route exact path="/settings">
        <Redirect to="/settings/init" />
      </Route>
      <Route path="/settings/init" component={SettingsPage} />
      <Route path="/settings/backup" component={BackupPage} />
      <Route path="/settings/personal-information" component={PersonalInformationPage} />
      <Route path="/settings/personal-information-edit" component={PersonalInformationEditPage} />
      <Route path="/settings/network" component={NetworkPage} />
      <Route path="/settings/language" component={LanguagePage} />
      <Route path="/settings/currency" component={CurrencyPage} />
      <Route path="/settings/application" component={ApplicationPage} />
      <Route path="/settings/permission-manage" component={PermissionManagePage} />
      <Route path="/settings/permission-detail" component={PermissionDetailPage} />
      <Route exact path="/wallet/settings">
        <Redirect to="/wallet/settings/init" />
      </Route>
      <Route path="/wallet/settings/init" component={SettingsPage} />
      <Route path="/wallet/settings/backup" component={BackupPage} />
      <Route path="/wallet/settings/personal-information" component={PersonalInformationPage} />
      <Route path="/wallet/settings/personal-information-edit" component={PersonalInformationEditPage} />
      <Route path="/wallet/settings/network" component={NetworkPage} />
      <Route path="/wallet/settings/language" component={LanguagePage} />
      <Route path="/wallet/settings/currency" component={CurrencyPage} />
      <Route path="/wallet/settings/application" component={ApplicationPage} />
      <Route path="/wallet/settings/permission-manage" component={PermissionManagePage} />
      <Route path="/wallet/settings/permission-detail" component={PermissionDetailPage} />
    </IonRouterOutlet>
  </IonPage>
);

export default SettingRoutes;
