// @ts-nocheck
import { IonSpinner } from "@ionic/react";

const SpinnerCenter = () => {
  return (
    <div className="flex justify-center items-center content-center min-h-[250px]">
      <IonSpinner name="dots" />
    </div>
  );
};

export default SpinnerCenter;
