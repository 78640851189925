import { TORUS_METHOD_PREFIX, WALLET_METHOD_PREFIX } from "shared/enums";

export const addInternalMethodPrefix = (method: string) => `${WALLET_METHOD_PREFIX}_${method}`;

export const addTorusMethodPrefix = (method: string) => `${TORUS_METHOD_PREFIX}_${method}`;

export const prettyPrintData = (data: any[]) => {
  let finalString = "";
  Object.keys(data).forEach((x) => {
    finalString = `${finalString}\n${x}: ${data[x as any]}`;
  });
  return finalString;
};

export const isErrorObject = (error: any) => error && error.stack && error.message;

export const genRandonString = (length: number) => {
  const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charLength = chars.length;
  let result = "";
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
};
