import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
/** --------------------------------------------------------------------------------- */
/** ================================= AXIOS ACTIONS ================================= */
/** --------------------------------------------------------------------------------- */

class HttpServices {
  async get<T = any, R = AxiosResponse<T>>(url: string, param?: AxiosRequestConfig): Promise<R> {
    return await axios.get<any, R, any>(url, param);
  }

  async post<T = any, R = AxiosResponse<T>>(url: string, body?: any, headers?: any): Promise<R> {
    return await axios.post<any, R, any>(url, body, headers);
  }
}

const httpServices = new HttpServices();
export default httpServices;
/**
Cara manggilnya:

async function testAwait() {
  const data = await ACTION_GET<{
    name: string;
    email: string;
    phone: string;
    address: string;
  }>("http://localhost:8080/name");
}
 **/
