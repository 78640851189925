// @ts-nocheck
import { IonButton, IonButtons, IonHeader, IonIcon, IonList, IonModal, IonToolbar } from "@ionic/react";
import { parseUri } from "@walletconnect/utils";
import { t } from "i18next";
import { chevronBackOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import Scan from "./Scan";
import { useDispatch } from "react-redux";
import { createLegacySignClient } from "shared/utils/walletconnect/LegactWalletConnectUtil";
import { web3wallet } from "shared/utils/walletconnect/WalletConnectUtil";
import { scannerAction } from "shared/actions/scannerAction";
import ModalWalletConnect from "components/walletConnect/ModalWalletConnect";
import "./style.css";

export interface IScanner {
  openScanner: boolean;
  closeScanner: any;
  platform: string;
  setScanResult: any;
  isScanContact: boolean;
}

const Scanner = (props: IScanner) => {
  const { openScanner, closeScanner, platform, isScanContact } = props;
  const dispatch = useDispatch();
  const [uri, setConnectUri] = useState("");
  const modal = useRef<HTMLIonModalElement>(null);
  const [modalStatus, setModalStatus] = useState(false);

  function handleScan(connectUri: any) {
    setConnectUri(connectUri.data);
  }

  const handleError = (err: any) => {
    console.error(`[debug]: error when reading QR`, err);
  };

  useEffect(() => {
    async function handleConnect() {
      if (uri && isScanContact !== true) {
        try {
          const { version } = parseUri(uri);
          // Route the provided URI to the v1 SignClient if URI version indicates it, else use v2.
          if (version === 1) {
            setModalStatus(true);
            createLegacySignClient(dispatch, { uri });
            closeScanner(false);
          } else {
            setModalStatus(true);
            await web3wallet.pair({ uri });
            closeScanner(false);
          }
        } catch (err: unknown) {
          alert(err);
        } finally {
          setConnectUri("");
        }
      }
      if (uri && isScanContact === true) {
        closeScanner(false);
        dispatch(scannerAction.addFromScanner({ scanResult: uri }));
        setConnectUri("");
      }
    }
    handleConnect();
  }, [uri]);

  return (
    <>
      {/* Connect Session Modal */}
      <IonModal backdropDismiss={false} id="modal-wrapper-wallet-connect" ref={modal} trigger="open-modal" isOpen={modalStatus}>
        <div className="wrapper">
          <IonList lines="none">
            <ModalWalletConnect openModalWalletConnect={modalStatus} closeModalWalletConnect={setModalStatus} />
          </IonList>
        </div>
      </IonModal>
      <IonModal isOpen={openScanner}>
        <IonHeader>
          <IonToolbar className="scanner-header">
            <IonButtons slot="start">
              <IonButton onClick={() => closeScanner(false)}>
                <IonIcon color="light" src={chevronBackOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div id="scanner-content" className="scanner-content">
          <div id="white-content-wrapper">
            <div className="find-code-wrapper">
              <p className="find-code">{t("settings.findCodeToScaner")}</p>
            </div>
          </div>
          <Scan onScanSuccess={handleScan} onScanError={handleError} platform={platform} />
        </div>
      </IonModal>
    </>
  );
};

export default Scanner;
