import { useAppDispatch } from "./useRedux";
import { embedUiActions } from "shared/actions/embedAction";

type args = "isLoggedIn" | "widgetIsOpen" | "modalIsOpen";

export const useEmbedUiDispatch = (): ((type: args, newItem: boolean) => void) => {
  const dispatch = useAppDispatch();
  return (type: args, newItem: boolean) => {
    switch (type) {
      case "isLoggedIn":
        dispatch(embedUiActions.setLoginState(newItem));
        break;
      case "modalIsOpen":
        dispatch(embedUiActions.setModalState(newItem));
        break;
      case "widgetIsOpen":
        dispatch(embedUiActions.setWidgetState(newItem));
        break;
    }
  };
};
