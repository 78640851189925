/* eslint-disable no-useless-catch */
import httpServices from "services/HttpServices";
import { BigNumber, ethers } from "ethers";
import axios from "axios";
import abiDid from "abis/did.json";

export const rpcs = "https://rpc-amoy.polygon.technology";
export type PermissionResponse = {
  statusCode?: number;
  message?: any;
  hash?: string;
};

export type PermissionResult = {
  result: any;
};

export type SignParam = {
  caller: string;
  key: string;
  value: string;
  wait: boolean;
};

export type GetDataParam = {
  permitted: string;
  key: string;
  deadline: number;
  v: number;
  r: string;
  s: string;
};

export type SignKeyParam = {
  caller: string;
  permitted: string;
  key: any;
  deadline: number;
};

export type UploadDid = {
  success: boolean;
  data: any;
};

export type DidBackendResult<T> = {
  data: T;
  metadata: {
    date: {
      iso: string;
      string: string;
      timestamp: number;
    };
  };
  msg: string;
  success: boolean;
};

export type UploadDidParam = {
  _hash: string;
  wallet_address: string;
  wallet_did_address: string;
  data: any;
};

class PermissionServices {
  uri = process.env.REACT_APP_DID_URL;
  uriS3 = process.env.REACT_APP_DID_S3_URL;
  RPC_LIST: any = rpcs.split(",");

  async selfCreate(caller: string): Promise<PermissionResult> {
    const url = `${this.uri}did/selfcreate`;
    const data = {
      caller,
      wait: true
    };
    const response = await httpServices.post<PermissionResult>(url, data);
    return response.data;
  }

  async ownerCreate(caller: string, address: string): Promise<PermissionResult> {
    const url = `${this.uri}did/ownercreate`;
    const data = {
      caller,
      address,
      wait: true
    };
    const response = await httpServices.post<PermissionResult>(url, data);
    return response.data;
  }

  async setMultiData(caller: string, keys: any, values: any, address: string): Promise<PermissionResponse> {
    const url = `${this.uri}did/${address}/setmultidata`;
    const data = {
      caller,
      keys,
      values,
      wait: true
    };
    const response = await httpServices.post<PermissionResponse>(url, data);
    return response.data;
  }

  async setData(data: SignParam, address: string): Promise<PermissionResult> {
    const url = `${this.uri}did/${address}/setdata`;
    const response = await httpServices.post<PermissionResult>(url, data);
    return response.data;
  }

  async getMultiData(
    permitted: string,
    keys: any,
    deadline: number,
    v: number,
    r: string,
    s: string,
    address: string
  ): Promise<PermissionResult> {
    const url = `${this.uri}did/${address}/getmultidata`;
    const data = {
      permitted,
      keys,
      deadline,
      v,
      r,
      s
    };
    const response = await httpServices.post<PermissionResult>(url, data);
    return response.data;
  }

  async getData(data: GetDataParam, address: string): Promise<PermissionResult> {
    const url = `${this.uri}did/${address}/getdata`;
    const response = await httpServices.post<PermissionResult>(url, data);
    return response.data;
  }

  async signMultiKey(data: SignParam, address: string): Promise<PermissionResponse> {
    const url = `${this.uri}did/${address}/signmultikey`;
    const response = await httpServices.post<PermissionResponse>(url, data);
    return response.data;
  }

  async signKey(data: SignKeyParam, address: string): Promise<PermissionResult> {
    const url = `${this.uri}did/${address}/signkey`;
    const response = await httpServices.post<PermissionResult>(url, data);
    return response.data;
  }

  async check(address: string): Promise<PermissionResponse> {
    const url = `${this.uri}did/${address}/check`;
    const response = await httpServices.get<PermissionResponse>(url);
    return response.data;
  }

  async getDid(address: string): Promise<{ did?: string; result?: string }> {
    try {
      const url = `${this.uri}get-did/${address}`;
      const response = await httpServices.get<{ did?: string; result?: string }>(url);
      return response.data;
    } catch (error) {
      return {
        result: undefined,
        did: undefined
      };
    }
  }

  async keccak256ToBytes32(bytes32: any): Promise<PermissionResponse> {
    const url = `${this.uri}keccak256-bytes32`;
    const data = {
      bytes32
    };
    const response = await httpServices.post<PermissionResponse>(url, data);
    return response.data;
  }

  async stringToBytes(val: string): Promise<PermissionResult> {
    const url = `${this.uri}string-to-bytes/${val}`;
    const response = await httpServices.get<PermissionResult>(url);
    return response.data;
  }

  async stringToBytes32(val: string): Promise<PermissionResult> {
    const url = `${this.uri}string-to-bytes32/${val}`;
    const response = await httpServices.get<PermissionResult>(url);
    return response.data;
  }

  async bytesToString(val: string): Promise<PermissionResult> {
    const url = `${this.uri}bytes-to-string/${val}`;
    const response = await httpServices.get<PermissionResult>(url);
    return response.data;
  }

  async bytes32ToString(val: string): Promise<PermissionResult> {
    const url = `${this.uri}bytes32-to-string/${val}`;
    const response = await httpServices.get<PermissionResult>(url);
    return response.data;
  }

  async uploadDid(params: any, web3token: string): Promise<UploadDid> {
    const url = `${this.uriS3}user/upload`;
    const headers = {
      headers: {
        "web3-token": web3token
      }
    };
    const response = await httpServices.post<UploadDid>(url, params, headers);
    return response.data;
  }

  async getDidS3(key: string, web3token: string) {
    const url = `${this.uriS3}user/user-data?key=${key}`;
    const headers = {
      headers: {
        "web3-token": web3token
      }
    };
    const response = await httpServices.get<UploadDid>(url, headers);
    return response.data;
  }

  async getScopedUser(key: string, web3token: string, verifierable: string, presentation: string) {
    const url = `${this.uriS3}dapp/request?user_key=${key}`;
    const headers = {
      headers: {
        "web3-token": web3token,
        "did-verifierable-credentials": verifierable,
        "did-presentation": presentation
      }
    };
    const response = await httpServices.post<UploadDid>(url, {}, headers);
    return response.data;
  }

  async getHash(address: string, web3token: string) {
    const url = `${this.uriS3}user/signed-key?address=${address}`;
    const headers = {
      headers: {
        "web3-token": web3token
      }
    };
    const response = await httpServices.get<UploadDid>(url, headers);
    return response.data;
  }
  // dapp side zone
  async verifyUser({
    client_secret,
    client_id,
    scope,
    host,
    targetAddress
  }: {
    client_secret: string;
    client_id: string;
    scope: string;
    host: string;
    targetAddress: string;
  }): Promise<{
    data: {
      jwt: string;
      presentation: string;
      verified: boolean;
    };
    metadata: {
      date: {
        iso: string;
        string: string;
        timestamp: number;
      };
    };
    msg: string;
    success: boolean;
  }> {
    const url = `${this.uriS3}dapp/verify`;
    try {
      const result = await httpServices.post<any>(url, {
        client_id,
        client_secret,
        scope: scope.toString(),
        client_host: host,
        target_user_address: targetAddress
      });
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async createDid(signer: string, signature: string, queryId: string) {
    const url = `${this.uri}queue/createdid`;
    const data = {
      signer,
      signature,
      queryId
    };
    const response = await httpServices.post<PermissionResult>(url, data);
    return response.data;
  }

  async createDidWithData(signer: string, signature: string, queryId: string, data: any) {
    const url = `${this.uri}queue/createdidwithdata`;
    const dataSet = {
      signer,
      signature,
      queryId,
      data
    };
    const response = await httpServices.post<PermissionResult>(url, dataSet);
    return response.data;
  }

  async signer(signature: string, queryId: string) {
    const url = `${this.uri}did/selfcreate`;
    const data = {
      signature,
      queryId,
      data: {}
    };

    const response = await httpServices.post<PermissionResult>(url, data);
    return response.data;
  }

  async message(queryId: string, data: any) {
    const url = `${this.uri}message`;
    const params = {
      queryId,
      data
    };
    const response = await httpServices.post<PermissionResult>(url, params);
    return response.data;
  }

  async setDataDid(data: { signer: string; signature: string; queryId: string; data: { keys: string[]; values: string[] } }) {
    const url = `${this.uri}queue/setdatadid`;
    const response = await httpServices.post<PermissionResult>(url, data);
    return response.data;
  }

  async setDataDID(caller: string, address: string, keys: string[], values: string[], wait?: boolean): Promise<any> {
    try {
      let waiting = false;
      if (wait !== undefined) {
        waiting = wait;
      }

      const { contract, signer } = await this.initDID(caller, address);
      return await this.writeContract(
        waiting,
        contract,
        "setData(bytes32[],bytes[])",
        this.getAddress(signer.address),
        this.getBigNumber(0),
        keys,
        values
      );
    } catch (e) {
      return null;
    }
  }

  async subscribe(address: string) {
    const url = `${this.uriS3}dapp/subscriber?address=${address}`;
    const response = await httpServices.get<any>(url);
    return response.data;
  }

  async signMultiKeyDID(caller: string, address: string, permitted: string, keys: string[], deadline: number): Promise<any> {
    const { signer } = await this.initDID(caller, address);
    const key = this.keccakBytes32(keys);
    return await this.getSignature(signer, address, permitted, key, deadline);
  }
  async getMaxPriorityFee(): Promise<any> {
    try {
      const { data } = await axios.get("https://gasstation.polygon.technology/v2");

      const maxPriorityFeeInHuman = Math.ceil(data.fast.maxPriorityFee);
      const upMaxPriorityFeeInHuman = Math.ceil(maxPriorityFeeInHuman * 2 + maxPriorityFeeInHuman / 2);
      const maxFeeInHuman = Math.ceil(data.fast.maxFee);
      const upMaxFeeInHuman = Math.ceil(maxFeeInHuman * 2 + maxFeeInHuman / 2);
      const maxFeePriorityInBN = ethers.utils.parseUnits(upMaxPriorityFeeInHuman.toString(), 9);
      const maxFeeInBN = ethers.utils.parseUnits(upMaxFeeInHuman.toString(), 9);

      return {
        maxPriorityFee: maxFeePriorityInBN,
        maxFee: maxFeeInBN
      };
    } catch (error: any) {
      if (error?.reason !== undefined) {
        throw error?.reason;
      } else if (error?.message !== undefined) {
        throw error?.message;
      } else if (error?.code != undefined) {
        throw error?.code;
      } else {
        throw error;
      }
    }
  }

  async estimateContract(sc: any, method: string, from: string, value: BigNumber, ...param: any[]): Promise<any> {
    try {
      const checksumedFrom = this.getAddress(from);

      return await sc.estimateGas[method](...param, {
        from: checksumedFrom,
        value: value
      });
    } catch (error: any) {
      if (error?.reason !== undefined) {
        throw error?.reason;
      } else if (error?.message !== undefined) {
        throw error?.message;
      } else if (error?.code != undefined) {
        throw error?.code;
      } else {
        throw error;
      }
    }
  }

  async writeContract(willWainting: boolean, sc: any, method: string, from: string, value: BigNumber, ...param: any[]): Promise<any> {
    try {
      const checksumedFrom = this.getAddress(from);
      const gas = await Promise.all([this.getMaxPriorityFee(), this.estimateContract(sc, method, from, value, ...param)]);

      const tx = await sc[method](...param, {
        from: checksumedFrom,
        value: value,
        maxPriorityFeePerGas: gas[0].maxPriorityFee,
        maxFeePerGas: gas[0].maxFee,
        gasLimit: gas[1]
      });

      if (willWainting === true) {
        const wait = await tx.wait();

        return wait;
      } else {
        return tx;
      }
    } catch (error: any) {
      if (error?.reason !== undefined) {
        throw error?.reason;
      } else if (error?.message !== undefined) {
        throw error?.message;
      } else if (error?.code != undefined) {
        throw error?.code;
      } else {
        throw error;
      }
    }
  }

  async setDataSingle(caller: string, address: string, key: string, value: string, wait = true) {
    try {
      const data = await this.setSignleDataDID(caller, address, key, value, wait);
      return { result: data };
    } catch (e) {
      return e;
    }
  }

  async setSignleDataDID(caller: string, address: string, key: string, value: string, wait?: boolean): Promise<any> {
    let waiting = false;
    if (wait !== undefined) {
      waiting = wait;
    }

    const { contract, signer } = await this.initDID(caller, address);
    return await this.writeContract(
      waiting,
      contract,
      "setData(bytes32,bytes)",
      this.getAddress(signer.address),
      this.getBigNumber(0),
      key,
      value
    );
  }

  getBigNumber(number: number): BigNumber {
    return ethers.BigNumber.from(number.toString());
  }

  async initDID(caller: string, address: string): Promise<any> {
    const DID = this.getAddress(address);
    const rpc = await this.getRpc();
    const provider = await this.createProvider(rpc);
    const signer = await this.createSigner(provider, caller);
    const contract = await this.createWriteContract(DID, abiDid, signer);

    return { contract, signer };
  }

  async getRpc(): Promise<string> {
    try {
      const alive: any = [];
      const getAlive = await this.testAllRpc();
      getAlive.forEach((result: any) => {
        if (result.ms !== "timeout") {
          alive.push(result);
        }
      });

      if (alive.length === 0) {
        throw "All rpc timeout, please check your internet connection or check your rpc url.";
      } else {
        const newAlive = this.sortByKey(alive, "ms");
        return newAlive[0].rpc;
      }
    } catch (error: any) {
      if (error?.reason !== undefined) {
        throw error?.reason;
      } else if (error?.message !== undefined) {
        throw error?.message;
      } else if (error?.code != undefined) {
        throw error?.code;
      } else {
        throw error;
      }
    }
  }

  sortByKey(array: any[], key: string): any[] {
    return array.sort((a, b) => {
      const x = a[key];
      const y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  async testAllRpc(): Promise<any> {
    const run: any = [];

    this.RPC_LIST.forEach((rpc: any) => {
      run.push(this.testRpc(rpc));
    });

    const value = await Promise.all(run);
    return value;
  }

  async testRpc(rpc: string): Promise<any> {
    const timeout = 3000;

    if (rpc === undefined) {
      throw "Please fill rpc url";
    }

    try {
      const ms = await this.getRpcSpeed(rpc, timeout);
      return this.returnSuccess(rpc, ms);
    } catch (error) {
      return this.returnTimeout(rpc);
    }
  }

  returnSuccess(rpc: string, ms: number): any {
    return { rpc: rpc, ms: ms };
  }

  returnTimeout(rpc: string): any {
    return { rpc: rpc, ms: "timeout" };
  }

  keccakBytes32(bytes32: string[]): string {
    const encoder = new ethers.utils.AbiCoder();
    return ethers.utils.keccak256(encoder.encode(["bytes32[]"], [bytes32]));
  }

  async getRpcSpeed(rpc: string, rto?: number): Promise<any> {
    try {
      const dateStart = new Date().getTime();
      await axios
        .post(
          rpc,
          {
            method: "eth_blockNumber",
            params: [],
            id: 1,
            jsonrpc: "2.0"
          },
          {
            headers: {
              Accept: "application/json"
            },
            timeout: rto
          }
        )
        .catch(() => {
          return "timeout";
        })
        .then((res: any) => {
          const dateEnd = new Date().getTime();

          if (res?.status !== 200) {
            return "timeout";
          }

          if (res?.result === undefined) {
            return "timeout";
          }

          return dateEnd - dateStart;
        });
    } catch (error) {
      return "timeout";
    }
  }

  createProvider(rpc: string): any {
    try {
      return new ethers.providers.JsonRpcProvider(rpc);
    } catch (error: any) {
      if (error?.reason !== undefined) {
        throw error?.reason;
      } else if (error?.message !== undefined) {
        throw error?.message;
      } else if (error?.code != undefined) {
        throw error?.code;
      } else {
        throw error;
      }
    }
  }

  createSigner(provider: any, privateKey: string): any {
    try {
      let pk: string | undefined;

      if (ethers.utils.isBytesLike(privateKey)) {
        pk = privateKey;
      } else {
        pk = `0x${privateKey}`;

        if (!ethers.utils.isBytesLike(pk)) {
          throw `invalid private key : ${pk}`;
        }
      }

      const signer = new ethers.Wallet(pk).connect(provider);

      return signer;
    } catch (error) {
      throw `invalid private key : ${privateKey}`;
    }
  }

  createWriteContract(address: string, abi: any, signer: any): any {
    try {
      if (ethers.utils.isAddress(address)) {
        if (
          signer.address === undefined ||
          signer.address === null ||
          signer.address === "" ||
          signer.address === ethers.constants.AddressZero
        ) {
          throw "invalid signer";
        }

        return new ethers.Contract(address, abi, signer);
      } else {
        throw `invalid smartcontract address : ${address}`;
      }
    } catch (error: any) {
      if (error?.reason !== undefined) {
        throw error?.reason;
      } else if (error?.message !== undefined) {
        throw error?.message;
      } else if (error?.code != undefined) {
        throw error?.code;
      } else {
        throw error;
      }
    }
  }

  getAddress(address: string): string {
    if (ethers.utils.isAddress(address)) {
      return ethers.utils.getAddress(address);
    } else {
      throw `this is not address : ${address}`;
    }
  }

  async getSignature(signer: any, contractaddress: string, permittedaddress: string, key: string, deadline: number): Promise<any> {
    const domain = {
      name: "Upbond DID",
      version: "1",
      chainId: 80002,
      verifyingContract: contractaddress
    };
    const getDataWithPermit = [
      { name: "permitted", type: "address" },
      { name: "dataKey", type: "bytes32" },
      { name: "deadline", type: "uint256" }
    ];
    const types = { getDataWithPermit };
    const message = {
      permitted: permittedaddress,
      dataKey: key,
      deadline: deadline
    };

    const signature = await signer._signTypedData(domain, types, message);
    const tempV = "0x" + signature.slice(130, 132);
    const v = ethers.BigNumber.from(tempV.toString()).toNumber();
    const r = signature.slice(0, 66);
    const s = "0x" + signature.slice(66, 130);
    return { v, r, s };
  }

  async updateDid(params: any, web3token: string, key: string): Promise<UploadDid> {
    const url = `${this.uriS3}user/update?key=${key}`;
    const headers = {
      headers: {
        "web3-token": web3token
      }
    };
    const response = await httpServices.post<UploadDid>(url, params, headers);
    return response.data;
  }

  async getSubscriberByAddress(address: string): Promise<DidBackendResult<{
    data: {
      id: number;
      user_id: number;
      dapp_id: number;
      status: string;
      createdAt: string;
      updatedAt: string | null;
      deletedAt: string | null;
      session_id: string;
      uri_subscriber: string;
      expired_at_ts: string;
      cached_sess: string;
    };
  }> | null> {
    try {
      const subscriberRes = await httpServices.get(`${this.uriS3}dapp/subscriber/get/address?address=${address}`);
      return subscriberRes.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getScopeByClientId(
    clientId: string,
    address?: string
  ): Promise<{
    scopeData: DidBackendResult<
      {
        id: number;
        uid: number | null;
        scope: string;
        dapp_id: string;
        status: string;
        createdAt: string;
        updatedAt: string;
        deletedAt: string;
        required_fields: string;
      }[]
    >;
    subscriberData: DidBackendResult<{
      data: {
        id: number;
        user_id: number;
        dapp_id: number;
        status: string;
        createdAt: string;
        updatedAt: string | null;
        deletedAt: string | null;
        session_id: string;
        uri_subscriber: string;
        expired_at_ts: string;
      };
    }> | null;
  } | null> {
    try {
      const scopeRes = await httpServices.get(`${this.uriS3}dapp/scope/get-by-clientid?clientId=${clientId}`);
      if (address) {
        const subscriberRes = await httpServices.get(`${this.uriS3}dapp/subscriber/get/address?address=${address}`);
        return {
          scopeData: scopeRes.data,
          subscriberData: subscriberRes.data
        };
      }
      return {
        scopeData: scopeRes.data,
        subscriberData: null
      };
    } catch {
      return null;
    }
  }
}

const permissionServices = new PermissionServices();

export default permissionServices;
