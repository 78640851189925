// @ts-nocheck
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Redirect, Route } from "react-router-dom";
import AccountIcon from "assets/icons/Account.svg";
import HomeIcon from "assets/icons/Home.svg";
import TransferIcon from "assets/icons/TransferIcon.svg";
import WalletConnectIcon from "assets/icons/WalletConnect.svg";
import TransferPage from "pages/transfer";
import HomePage from "pages/home";
import AccountPage from "pages/account";
import ApplicationPage from "pages/application";
import DetailNetworkPage from "pages/detailNetwork";
import useTheme from "hooks/useTheme";

const HomeRoutes: React.FC = () => {
  const { t } = useTranslation();
  const { breakpoint } = useTheme();

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/wallet/home" component={HomePage} />
        <Route path="/wallet/transfer" component={TransferPage} />
        <Route path="/wallet/account" component={AccountPage} />
        <Route path="/wallet/application" component={ApplicationPage} />
        <Route exact path="/wallet">
          <Redirect to="/wallet/home" />
        </Route>
        <Route path="/wallet/details" component={DetailNetworkPage} />
      </IonRouterOutlet>

      <IonTabBar slot="bottom" className="tabBottom">
        <IonTabButton tab="tab1" href="/wallet/home">
          <IonIcon src={HomeIcon} size="small" className="gapIcon" />
          <IonLabel>{t("navbar.home")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab2" href="/wallet/transfer">
          <IonIcon icon={TransferIcon} size="small" className="gapIcon" />
          <IonLabel>{t("navbar.transfer")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab3" href="/wallet/account">
          <IonIcon icon={AccountIcon} size="small" className="gapIcon" />
          <IonLabel>{t("navbar.account")}</IonLabel>
        </IonTabButton>
        {breakpoint < 1024 ? (
          <></>
        ) : (
          <IonTabButton tab="tab4" href="/wallet/application">
            <IonIcon icon={WalletConnectIcon} size="small" className="gapIcon" />
            <IonLabel>{t("navbar.walletConnect")}</IonLabel>
          </IonTabButton>
        )}
      </IonTabBar>
    </IonTabs>
  );
};

export default HomeRoutes;
