import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PidModule } from "interfaces/actions/IOIPidModule";

const initPidState: PidModule = {
  _pidStore: {},
  _timestamps: {},
  timeout: 0,
  pidStore: {}
};
const pidSlice = createSlice({
  name: "olPidModule",
  initialState: initPidState,
  reducers: {
    cleanup(state) {
      const tempStore: Record<string, Record<string, unknown>> = {};
      const tempTimestamps: Record<string, number> = {};
      Object.keys(state._pidStore).forEach((key) => {
        if (state._pidStore[key] && state._timestamps[key]) {
          const timestamp = state._timestamps[key];
          if (Date.now() - timestamp < state.timeout) {
            tempStore[key] = state._pidStore[key];
            tempTimestamps[key] = state._timestamps[key];
          }
        }
      });

      state._pidStore = tempStore;
      state._timestamps = tempTimestamps;
    },
    _setPID(state, action: PayloadAction<{ pid: string; data: any }>): void {
      let existingData: Record<string, unknown> = {};
      if (state._pidStore[action.payload.pid]) {
        existingData = state._pidStore[action.payload.pid];
      }
      state._pidStore = {
        ...state._pidStore,
        [action.payload.pid]: { ...JSON.parse(JSON.stringify(existingData)), ...action.payload.data }
      };
      state._timestamps = {
        ...state._timestamps,
        [action.payload.pid]: Date.now()
      };
    }
  }
});

export default pidSlice;
