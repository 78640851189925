// @ts-nocheck
import { walletActions } from "shared/actions/walletAction";
import { useAppDispatch, useAppSelector } from "./useRedux";
import { IonProgressBar } from "@ionic/react";
import "themes/loader.css";

const useLoading = () => {
  const appDispatch = useAppDispatch();
  const { loading: isLoading, loadingText } = useAppSelector((state) => state.wallet);
  const setLoading = (isLoading: boolean, newLoadingText?: string) => {
    appDispatch(walletActions.setLoading({ loading: isLoading, loadingText: newLoadingText }));
  };

  const DisplayLoading = () => (
    <div className="loader-display">
      <h2 style={{ color: "#5c6c7f", fontWeight: "700" }}>
        <b>{loadingText}</b>
      </h2>
      <div className="dots-loader-container">
        <div className="dot-flashing"></div>
      </div>
    </div>
  );

  const check = (value: number) => {
    if (
      !(window.location.pathname.includes("auth") || window.location.pathname.includes("end") || window.location.pathname.includes("start"))
    ) {
      return `${(value * 100).toFixed(0)}%`;
    } else {
      return "";
    }
  };

  const DisplayLoadingNew = ({ value }: { value: number }) => (
    <div className="loader-display text-center">
      <IonProgressBar className="progressBar" value={value}></IonProgressBar>
      <br />
      <br />
      <h2 className="text-[#214999] whitespace-pre">{`${loadingText} ${check(value)}`}</h2>
    </div>
  );

  const SkeletonLoading = ({ type, rows }: { type: string; rows: number }) => {
    const rowblocks: any = [...Array(rows).keys()];

    if (type === "group") {
      return rowblocks.map((row: any) => (
        <div key={row} className="flex items-center gap-2 my-4">
          <div className="skeleton-item w-12 h-8 rounded-sm"></div>
          <div className="skeleton-item h-8 w-full rounded-sm"></div>
        </div>
      ));
    } else {
      return rowblocks.map((row: any) => <div key={row} className="skeleton-item h-8 w-full rounded-sm my-4"></div>);
    }
  };

  return { isLoading, setLoading, DisplayLoading, DisplayLoadingNew, SkeletonLoading };
};

export default useLoading;
