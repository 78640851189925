// @ts-nocheck
import { IonButton, IonItem, IonLabel, IonList } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { changeArrWc, fetchClose } from "shared/actions/walletConnectAction";
import { rejectEIP155Request } from "shared/utils/walletconnect/EIP155RequestHandlerUtil";

import { IModalCloseWalletConnect } from "interfaces/actions/IWalletConnect";
import { useTranslation } from "react-i18next";
import "./styles/index.css";
import { web3wallet } from "shared/utils/walletconnect/WalletConnectUtil";

export default function SessionAddNetworkModal(props: IModalCloseWalletConnect) {
  const { closeTheModalWalletConnect } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // const getFromLocalStorage = JSON.parse(localStorage.getItem("walletconnect") || "{}");
  const walletConnect = useAppSelector((state) => state.walletConnect);
  const requestEvent = walletConnect.data?.requestEvent;
  // Get request and wallet data from store
  if (!requestEvent) {
    return (
      <>
        <IonLabel className="font-semibold text-base leading-[19px] text-[#0a0a0a] flex justify-center p-5 mb-2.5 h-[1px] font-roboto">
          {t("walletConnect.unsupported")}
        </IonLabel>

        <IonList
          className="py-4 px-[22px] font-normal text-base leading-[19px] text-[#0a0a0a] font-roboto"
          lines="none"
          style={{ paddingTop: "0px" }}>
          <p className="my-4 font-roboto">{t("walletConnect.unsupportedDesc")}</p>
          <p className="my-4 font-roboto">{t("walletConnect.unsupportedMsg")}</p>
          <IonItem className="modal-confirmation-button-wrapper ion-no-padding" lines="none">
            <IonButton onClick={() => onReject()} className="no-padding-no" size="default" shape="round" style={{ marginInline: "0px" }}>
              {t("walletConnect.ok")}
            </IonButton>
          </IonItem>
        </IonList>
      </>
    );
  }
  const { topic } = requestEvent;

  // Handle reject action
  async function onReject() {
    dispatch(changeArrWc(walletConnect));
    closeTheModalWalletConnect(false);
    if (requestEvent) {
      const response = rejectEIP155Request(requestEvent);
      await web3wallet.respondSessionRequest({
        topic,
        response
      });
    }
    dispatch(fetchClose());
  }

  return (
    <>
      <IonLabel className="font-semibold text-base leading-[19px] text-[#0a0a0a] flex justify-center p-5 mb-2.5 h-[1px]">
        {t("walletConnect.unsupported")}
      </IonLabel>

      <IonList className="py-4 px-[22px] font-normal text-base leading-[19px] text-[#0a0a0a]" lines="none" style={{ paddingTop: "0px" }}>
        <p className="font-roboto">{t("walletConnect.unsupportedDesc")}</p>
        <p className="font-roboto">{t("walletConnect.unsupportedMsg")}</p>
        <IonItem className="modal-confirmation-button-wrapper ion-no-padding" lines="none">
          <IonButton onClick={() => onReject()} className="no-padding-no" size="default" shape="round" style={{ marginInline: "0px" }}>
            {t("walletConnect.ok")}
          </IonButton>
        </IonItem>
      </IonList>
    </>
  );
}
