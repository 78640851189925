import { RedirectResult } from "@toruslabs/customauth";

import { redirectToDapp } from "./openloginutils";
import { store } from "shared/store";
import { olLoginPerfModuleActions } from "shared/actions/olLoginPerfModuleAction";
import { registerDappModule } from "shared/actions/olAllDappModuleAction";

/**
 * Handles the error from the direct auth flow
 * @param redirectResult - The result of redirection
 * @returns The return parameter determines if the execution should go beyond the handler
 */

export default async function handleCustomAuthError(
  redirectResult: RedirectResult
): Promise<{ canContinue: boolean; displayError?: string }> {
  const { state: localState, error } = redirectResult;
  const currentDappClientId = localState.client as string;
  if (error === "Unsupported method type" && currentDappClientId) {
    registerDappModule(currentDappClientId);
    const currentDappModule = store.getState().olAllDappModule.dappModules[currentDappClientId];
    // This means user has clicked back button in browser and went to OAuth login page
    // we shouldn't entertain this and redirect to home page
    if (
      currentDappModule.privateKey &&
      localState.pid &&
      store.getState().olTKeyModule.tKeyPrivKey &&
      store.getState().olUserModule.keyInfo.privateKey
    ) {
      // re-init loginPerf before redirecting
      store.dispatch(olLoginPerfModuleActions._reinit());
      const { email, aggregateVerifier, name, profileImage, typeOfLogin, verifier, verifierId } = store.getState().olUserModule.userInfo;
      store.dispatch(
        olLoginPerfModuleActions.markRouteAndTime({
          route: "auth",
          isEnd: true
        })
      );
      await redirectToDapp(
        {
          redirectUrl: currentDappModule.redirectUrl,
          popupWindow: localState.popupWindow === "true",
          sessionTime: currentDappModule.sessionTime,
          sessionId: currentDappModule.sessionId,
          _sessionNamespace: currentDappModule._sessionNamespace
        },
        {
          result: {
            privKey: currentDappModule.privateKey,
            tKey: store.getState().olTKeyModule.tKeyPrivKey,
            oAuthPrivateKey: store.getState().olUserModule.keyInfo.privateKey,
            store: {
              idToken: currentDappModule.idToken,
              touchIDPreference: currentDappModule.touchIDPreference,
              appState: currentDappModule.appState,
              email,
              aggregateVerifier,
              name,
              profileImage,
              typeOfLogin,
              verifier,
              verifierId
            }
          },
          pid: localState.pid as string
        }
      );
      return { canContinue: false };
    }
    throw new Error(error);
  } else {
    if (error?.includes("timesigned")) {
      const errorType = "Your system time is incorrect. Please correct your system time and login again";
      return { displayError: errorType, canContinue: false };
    }
    throw new Error(error);
  }
}
