import { BN } from "ethereumjs-util";
import Web3 from "web3";

const web3 = new Web3();
const { sha3 } = web3.utils;

type AnyAbi = { [x: string]: { y: any } }[];

class AbiDecoder {
  state: { savedABIs: never[]; methodIDs: Record<string, any> };
  constructor(abi: AnyAbi) {
    this.state = {
      savedABIs: [],
      methodIDs: {}
    };
    this.addABI(abi);
  }

  getABIs() {
    return this.state.savedABIs;
  }

  addABI(abiArray: AnyAbi) {
    if (Array.isArray(abiArray)) {
      // Iterate new abi to generate method id's
      abiArray.map((abi: any) => {
        if (abi.name) {
          const signature = sha3(`${abi.name}(${(abi.inputs as any).map((input: any) => input.type).join(",")})`);
          if (abi.type === "event") {
            this.state.methodIDs[(signature as string).slice(2)] = abi;
          } else {
            this.state.methodIDs[(signature as string).slice(2, 10)] = abi;
          }
        }
      });

      (this.state as any).savedABIs = [...this.state.savedABIs, ...abiArray];
    } else {
      throw new TypeError(`Expected ABI array, got ${typeof abiArray}`);
    }
  }

  removeABI(abiArray: AnyAbi) {
    if (Array.isArray(abiArray)) {
      // Iterate new abi to generate method id's
      abiArray.map((abi: any) => {
        if (abi.name) {
          const signature = sha3(`${abi.name}(${abi.inputs.map((input: any) => input.type).join(",")})`);
          if (abi.type === "event") {
            if (this.state.methodIDs[(signature as string).slice(2)]) {
              delete this.state.methodIDs[(signature as string).slice(2)];
            }
          } else if (this.state.methodIDs[(signature as string).slice(2, 10)]) {
            delete this.state.methodIDs[(signature as string).slice(2, 10)];
          }
        }
      });
    } else {
      throw new TypeError(`Expected ABI array, got ${typeof abiArray}`);
    }
  }

  getMethodIDs() {
    return this.state.methodIDs;
  }

  decodeMethod(data: any) {
    const methodID = data.slice(2, 10);
    const abiItem = this.state.methodIDs[methodID];
    if (abiItem) {
      const parameters = abiItem.inputs.map((item: any) => item.type);
      const decoded = web3.eth.abi.decodeParameters(parameters, data.slice(10));

      const returnValueData = {
        name: abiItem.name,
        params: []
      };

      for (let i = 0; i < decoded.__length__; i += 1) {
        const parameter = decoded[i];
        let parsedParameter = parameter;
        const isUint = abiItem.inputs[i].type.indexOf("uint") === 0;
        const isInt = abiItem.inputs[i].type.indexOf("int") === 0;
        const isAddress = abiItem.inputs[i].type.indexOf("address") === 0;

        if (isUint || isInt) {
          const isArray = Array.isArray(parameter);

          parsedParameter = isArray ? parameter.map((value) => new BN(value).toString()) : new BN(parameter).toString();
        }

        // Addresses returned by web3 are randomly cased so we need to standardize and lowercase all
        if (isAddress) {
          const isArray = Array.isArray(parameter);

          parsedParameter = isArray ? parameter.map((_) => _.toLowerCase()) : parameter.toLowerCase();
        }

        (returnValueData.params as any).push({
          name: abiItem.inputs[i].name,
          value: parsedParameter,
          type: abiItem.inputs[i].type
        });
      }

      return returnValueData;
    }
  }

  decodeLogs(logs: any) {
    return logs
      .filter((log: any) => log.topics.length > 0)
      .map((logItem: any) => {
        const methodID = logItem.topics[0].slice(2);
        const method = this.state.methodIDs[methodID];
        if (method) {
          const logData = logItem.data;
          const decodedParameters = [] as any[];
          let dataIndex = 0;
          let topicsIndex = 1;

          const dataTypes = [] as any[];
          method.inputs.map((input: any) => {
            if (!input.indexed) {
              dataTypes.push(input.type);
            }
          });

          const decodedData = web3.eth.abi.decodeParameters(dataTypes, logData.slice(2));

          // Loop topic and data to get the params
          method.inputs.map((parameter: any) => {
            const decodedP = {
              name: parameter.name,
              type: parameter.type
            } as any;

            if (parameter.indexed) {
              (decodedP as any).value = logItem.topics[topicsIndex];
              topicsIndex += 1;
            } else {
              (decodedP as any).value = decodedData[dataIndex];
              dataIndex += 1;
            }

            if (parameter.type === "address") {
              decodedP.value = decodedP.value.toLowerCase();
              // 42 because len(0x) + 40
              if (decodedP.value.length > 42) {
                const toRemove = decodedP.value.length - 42;
                const temporary = [...decodedP.value];
                temporary.splice(2, toRemove);
                decodedP.value = temporary.join("");
              }
            }

            if (parameter.type === "uint256" || parameter.type === "uint8" || parameter.type === "int") {
              // ensure to remove leading 0x for hex numbers
              decodedP.value =
                typeof decodedP.value === "string" && decodedP.value.startsWith("0x")
                  ? new BN(decodedP.value.slice(2), 16).toString(10)
                  : new BN(decodedP.value).toString(10);
            }

            decodedParameters.push(decodedP);
          });

          return {
            name: method.name,
            events: decodedParameters,
            address: logItem.address
          };
        }
      });
  }
}

export default AbiDecoder;
