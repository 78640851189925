// @ts-nocheck
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonDatetime,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  useIonRouter
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Logo from "assets/icons/LogoUpbond.svg";
import { useSelector } from "react-redux";
import { RootState } from "shared/store";
import permissionServices from "services/PermissionServices";
import { genRandonString } from "shared/utils/permissionsUtils";
import useWallet from "hooks/useWallet";
import Web3Token from "web3-token";
import { ethers } from "ethers";
import { encryptMessage } from "shared/utils/etherUtils";

const ConsentPage: React.FC = () => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>();
  const [birthday, setBirthday] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [web3Token, setWeb3Token] = useState<string>("");
  const [openDate, setOpenDate] = useState<boolean>(false);
  const { wallet } = useSelector((state: RootState) => state);
  const privKey = wallet.wallet[wallet.selectedAddress].privateKey;
  const { ethWallet } = useWallet({
    onError(error) {
      console.error(error);
    }
  });
  const history = useIonRouter();

  const init = async () => {
    setIsLoading(true);
    setName(wallet.userInfo.name);
    setEmail(wallet.userInfo.email);
    setIsLoading(false);
  };

  const createDid = async () => {
    try {
      const signer = new ethers.Wallet(privKey);
      const newKey = uuidv4();
      const randString = genRandonString(32);
      const byte32Key = await permissionServices.stringToBytes(randString);
      const generatedKey = byte32Key.result.toString();
      const params = {
        user_name: JSON.stringify(encryptMessage(privKey, name)),
        user_birthday: JSON.stringify(encryptMessage(privKey, birthday)),
        user_email: JSON.stringify(encryptMessage(privKey, email)),
        wallet_address: wallet.selectedAddress,
        _hash: generatedKey
      };
      const keyFromS3 = await permissionServices.uploadDid(params, web3Token);
      const dataBytes = await permissionServices.stringToBytes(keyFromS3.data.key);
      const data = {
        keys: [generatedKey],
        values: [dataBytes.result]
      };

      const message = await permissionServices.message(newKey, data);
      const signature = await signer.signMessage(message.result);
      await permissionServices.createDidWithData(wallet.selectedAddress, signature, newKey, data);
      return true;
    } catch (err: any) {
      console.error("Error creating did", err);
      return false;
    }
  };

  const getWeb3Token = async () => {
    if (ethWallet) {
      const web3tkn = await Web3Token.sign(async (msg: string) => {
        return await ethWallet.signMessage(msg);
      }, "7d");
      setWeb3Token(web3tkn);
    }
  };

  const agreeAndLogin = async () => {
    setIsLoading(true);
    await createDid();
    setIsLoading(false);
    history.push("/wallet");
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    getWeb3Token();
  }, [ethWallet]);

  // END DID FUNCTIONALITY
  return (
    <>
      <IonLoading cssClass="my-custom-class" isOpen={isLoading} spinner={"circles"} />
      <IonPage>
        {/* Backup Menu */}

        <IonContent className="!p-4 ps-4 pe-4 consent-content gray">
          <IonRow className="justify-center">
            <IonCol sizeMd="4" sizeSm="6" className="text-center space-y-2">
              <div className="text-center flex justify-center">
                <img src={Logo} alt="upbond-logo" />
              </div>
              <p className="flex leading-4.75 !my-4"> {t("settings.managePermission_subtitle")}</p>
              <IonList className="permission-form-list gray">
                <IonItem className="font-normal text-base text-[#5d5d5d]">
                  <div className="flex justify-between items-center flex-row min-w-full">
                    <div className="flex justify-start items-center flex-row mt-0.5">
                      <IonLabel id="title" position="stacked" className="!text-base !font-bold block !self-center !mb-0">
                        {t("settings.name")}
                      </IonLabel>
                      <div className="bg-E4E4E4 py-0 px-1.5 my-0 mx-1.5 rounded-2.5xl text-sm font-bold text-[#214999]">Required</div>
                    </div>
                    <IonCheckbox className="m-0" slot="end" disabled checked></IonCheckbox>
                  </div>
                  <IonInput
                    placeholder="Enter name"
                    value={name}
                    onIonChange={(e) => setName(e.target.value as string)}
                    className="permission my-2.5 rounded-lg leading-4.75"
                    disabled></IonInput>
                </IonItem>
                {/* <IonItem className="personal-wrapper">
              <div className="flex justify-between items-center flex-row min-w-full">
                <div className="flex justify-start items-end flex-row mt-0.5">
                  <IonLabel id="title" position="stacked">
                    Birthday
                  </IonLabel>
                </div>
                <IonCheckbox slot="end" checked></IonCheckbox>
              </div>
              <IonInput
                placeholder="Enter birthday"
                value={birthday}
                onIonChange={(e) => setBirthday(e.target.value as string)}
                className="permission"></IonInput>
            </IonItem> */}
                <IonItem className="font-normal text-base text-[#5d5d5d] bg-f9f9f9">
                  <div className="flex justify-between items-center flex-row min-w-full">
                    <div className="flex justify-start items-center flex-row mt-0.5">
                      <IonLabel id="title" position="stacked" className="!text-base !font-bold !block !self-center !mb-0">
                        Birthday
                      </IonLabel>
                    </div>
                    <IonCheckbox slot="end" checked></IonCheckbox>
                  </div>
                  <IonInput
                    placeholder="Enter Birthday"
                    onClick={() => setOpenDate(!openDate)}
                    className="permission my-2.5 rounded-lg leading-4.75"
                    // disabled
                    value={new Date().toLocaleDateString()}
                  />
                  <IonModal className="modal-transfer-done" isOpen={openDate} initialBreakpoint={0.6} breakpoints={[0, 0.6, 0.75]}>
                    <IonContent className="consent-content">
                      <IonDatetime
                        value={birthday}
                        prefer-wheel="true"
                        onIonChange={(e) => {
                          if (typeof e.detail.value === "string") setBirthday(e.detail.value);
                        }}
                        presentation="date"
                      />
                      <IonButton
                        shape="round"
                        color="primary"
                        expand="full"
                        onClick={() => {
                          setOpenDate(!openDate);
                        }}>
                        {t("transfer.confirmTransfer")}
                      </IonButton>
                    </IonContent>
                  </IonModal>
                </IonItem>
                {/* <IonItem className="personal-wrapper">
              <div className="flex justify-between items-center flex-row min-w-full">
                <div className="flex justify-start items-end flex-row mt-0.5">
                  <IonLabel id="title" position="stacked">
                    Resident
                  </IonLabel>
                </div>
                <IonCheckbox slot="end" checked></IonCheckbox>
              </div>
              <IonInput
                placeholder="Enter resident"
                value={resident}
                onIonChange={(e) => setResident(e.target.value as string)}
                className="permission"></IonInput>
            </IonItem>
            <IonItem className="personal-wrapper">
              <div className="flex justify-between items-center flex-row min-w-full">
                <div className="flex justify-start items-end flex-row mt-0.5">
                  <IonLabel id="title" position="stacked">
                    KYC
                  </IonLabel>
                </div>
                <IonCheckbox slot="end" checked></IonCheckbox>
              </div>
              <IonInput value={kyc} className="permission" onIonChange={(e) => setKYC(e.target.value as string)} disabled></IonInput>
            </IonItem> */}
                <IonItem className="font-normal text-base text-[#5d5d5d]">
                  <div className="flex justify-between items-center flex-row min-w-full">
                    <div className="flex justify-start items-center flex-row mt-0.5">
                      <IonLabel id="title" position="stacked" className="!text-base !font-bold !block !self-center !mb-0">
                        {t("settings.email")}
                      </IonLabel>
                      <div className="bg-E4E4E4 py-0 px-1.5 my-0 mx-1.5 rounded-2.5xl text-sm font-bold text-[#214999]">Required</div>
                    </div>
                    <IonCheckbox slot="end" disabled checked></IonCheckbox>
                  </div>
                  <IonInput
                    placeholder="Enter email"
                    value={email}
                    onIonChange={(e) => setEmail(e.target.value as string)}
                    className="permission my-2.5 rounded-lg leading-4.75"
                    disabled></IonInput>
                </IonItem>
              </IonList>
              {/* <div className="button-containers"> */}
              <IonButton
                className="my-1 mx-0.5"
                shape="round"
                color="primary"
                expand="block"
                onClick={() => {
                  agreeAndLogin();
                }}>
                {"Agree and log in"}
              </IonButton>
              <IonButton
                className="my-1 mx-0.5"
                shape="round"
                color="secondary"
                expand="block"
                onClick={() => {
                  history.push("/");
                }}>
                {"Cancel"}
              </IonButton>
              {/* </div> */}
              <p className="text-center flex leading-4.75 !my-4">Supported by UPBOND</p>
            </IonCol>
          </IonRow>
        </IonContent>
      </IonPage>
    </>
  );
};

export default ConsentPage;
