// @ts-nocheck
import { IonButton, IonList, IonLoading, IonModal } from "@ionic/react";
import useTheme from "hooks/useTheme";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import permissionServices from "services/PermissionServices";
import { RootState } from "shared/store";
import { genRandonString } from "shared/utils/permissionsUtils";
import useWallet from "hooks/useWallet";
import Web3Token from "web3-token";
import { decryptMessage, encryptMessage } from "shared/utils/etherUtils";
import PersonalInformationEditMainMb from "features/settings/components/personalInformationEditMain/mb";
import PersonalInformationEditMainPc from "features/settings/components/personalInformationEditMain/pc";
import "./style.css";

const PersonalInformationEditPage = () => {
  const { t } = useTranslation();
  const { breakpoint } = useTheme();
  const { userInfo } = useSelector((state: RootState) => state.wallet);
  const [name, setName] = useState<string>(userInfo.name);
  const [birthday, setBirthday] = useState<string>("");
  const [isErrorDid, setErrorDid] = useState<boolean>(false);
  const [errorDidMsg, setErrorDidMsg] = useState<string>("");
  const [isSuccessDid, setSuccessDid] = useState<boolean>(false);
  const [successDidMsg, setSuccessDidMsg] = useState<string>("");
  const [email, setEmail] = useState<string>(userInfo.email);
  const [hash, setHash] = useState<string>("");
  const [s3Key, setS3Key] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [web3Token, setWeb3Token] = useState<string>("");
  const [openDate, setOpenDate] = useState<boolean>(false);

  const { ethWallet } = useWallet();

  const [did, setDid] = useState<string>("");
  const { selectedAddress, wallet } = useSelector((state: RootState) => state.wallet);
  const privKey = `0x${wallet[selectedAddress].privateKey}`;
  //DID FUNCTIONALITY
  const getDid = async () => {
    try {
      setIsLoading(true);
      const get = await permissionServices.getDid(selectedAddress);
      if (get.result) setDid(get.result);
      const getHash = await permissionServices.getHash(selectedAddress, web3Token);
      if (getHash.data._hash) setHash(getHash.data._hash);
      else setIsLoading(false);
    } catch (err: any) {
      const errTranslated = t("consentsForm.getDid");
      setErrorDidMsg(errTranslated);
      setIsLoading(false);
      setErrorDid(true);
      throw err;
    }
  };

  const signKey = async (newKey: string) => {
    try {
      const date = new Date();
      date.setDate(date.getDate() + 7);
      const deadline = Math.floor(date.getTime() / 1000);
      const signKey = await permissionServices.signMultiKeyDID(privKey, did, selectedAddress, [newKey], deadline);
      const getData = await permissionServices.getMultiData(selectedAddress, [newKey], deadline, signKey.v, signKey.r, signKey.s, did);
      const byte32Data = await permissionServices.bytesToString(getData.result);

      if (byte32Data.result) {
        setS3Key(byte32Data.result);
        const userData = await permissionServices.getDidS3(byte32Data.result, web3Token);
        if (userData.data.birthday) {
          const testData = JSON.parse(userData.data.birthday);
          setBirthday(decryptMessage(testData, wallet[selectedAddress].privateKey));
        }
      }

      setIsLoading(false);
    } catch (err: any) {
      const errTranslated = t("consentsForm.signKey");
      setErrorDidMsg(errTranslated);
      setIsLoading(false);
      setErrorDid(true);
      throw err;
    }
  };

  const setData = async () => {
    try {
      setIsLoading(true);
      if (s3Key) {
        const data = {
          user_name: JSON.stringify(encryptMessage(wallet[selectedAddress].privateKey, name)),
          user_birthday: JSON.stringify(encryptMessage(wallet[selectedAddress].privateKey, birthday)),
          user_email: JSON.stringify(encryptMessage(wallet[selectedAddress].privateKey, email)),
          wallet_address: selectedAddress,
          _hash: s3Key
        };
        await permissionServices.updateDid(data, web3Token, s3Key);
        const errTranslated = t("consentsForm.successUpdate");
        setSuccessDidMsg(errTranslated);
        setSuccessDid(true);
      } else {
        const newKey = genRandonString(32);
        const byte32Key = await permissionServices.stringToBytes(newKey);
        const generatedKey = byte32Key.result.toString();
        const data = {
          user_name: JSON.stringify(encryptMessage(wallet[selectedAddress].privateKey, name)),
          user_birthday: JSON.stringify(encryptMessage(wallet[selectedAddress].privateKey, birthday)),
          user_email: JSON.stringify(encryptMessage(wallet[selectedAddress].privateKey, email)),
          wallet_address: selectedAddress,
          _hash: generatedKey
        };
        const keyFromS3 = await permissionServices.uploadDid(data, web3Token);
        const dataBytes = await permissionServices.stringToBytes(keyFromS3.data.key);
        const result = await permissionServices.setDataSingle(privKey, did, generatedKey, dataBytes.result, true);
        if (result) {
          const errTranslated = t("consentsForm.successUpdate");
          setSuccessDidMsg(errTranslated);
          setSuccessDid(true);
        }
      }

      setIsLoading(false);
    } catch (err) {
      const errTranslated = t("consentsForm.failedUpdate");
      setErrorDidMsg(errTranslated);
      setIsLoading(false);
      setErrorDid(true);
      throw err;
    }
  };
  const init = async () => {
    getDid();
    setOpenDate(false);
  };
  const getWeb3Token = async () => {
    if (ethWallet) {
      const signWeb3token = await Web3Token.sign(async (msg: string) => {
        return await ethWallet.signMessage(msg);
      }, "7d");
      setWeb3Token(signWeb3token);
    }
  };

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name);
      setEmail(userInfo.email);
    }
  }, [userInfo]);
  useEffect(() => {
    getWeb3Token();
  }, [ethWallet]);

  useEffect(() => {
    if (web3Token) init();
  }, [web3Token]);

  useEffect(() => {
    if (hash) signKey(hash);
  }, [hash]);

  return (
    <>
      <IonLoading cssClass="my-custom-class" isOpen={isLoading} spinner={"circles"} />

      <IonModal id="modal-error-c" className="modal-gas-confirm" trigger="open-modal" isOpen={isErrorDid}>
        <div className="wrapper ion-padding modal-gas-confirm-wrapper text-center">
          <IonList lines="none" className="ion-text-center ion-padding">
            {errorDidMsg}
          </IonList>
          <IonButton shape="round" color="primary" expand="full" onClick={() => setErrorDid(false)}>
            {t("account.close")}
          </IonButton>
        </div>
      </IonModal>

      <IonModal id="modal-success" className="modal-gas-confirm" trigger="open-modal-success" isOpen={isSuccessDid}>
        <div className="wrapper ion-padding modal-gas-confirm-wrapper text-center">
          <IonList lines="none" className="ion-text-center ion-padding">
            {successDidMsg}
          </IonList>
          <IonButton shape="round" color="primary" expand="full" onClick={() => setSuccessDid(false)}>
            {t("account.ok")}
          </IonButton>
        </div>
      </IonModal>

      {breakpoint < 1024 ? (
        <PersonalInformationEditMainMb
          openDate={openDate}
          setOpenDate={setOpenDate}
          birthday={birthday}
          setBirthday={setBirthday}
          name={name}
          email={email}
          setData={setData}
        />
      ) : (
        <PersonalInformationEditMainPc birthday={birthday} setBirthday={setBirthday} name={name} email={email} />
      )}
    </>
  );
};

export default PersonalInformationEditPage;
