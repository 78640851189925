// @ts-nocheck
import { BroadcastChannel } from "@toruslabs/broadcast-channel";
import { IonSpinner } from "@ionic/react";
import * as React from "react";
import Logo from "assets/images/darklogo.png";
import { useHistory, useLocation } from "react-router";
import { useEffect } from "react";
import { broadcastChannelOptions, handleRedirectParameters } from "shared/utils/coreUtils";

const RedirectMsg: React.FC = () => {
  const search = useLocation().search;
  const history = useHistory();
  const data = new URLSearchParams(search);
  const params: any = new URLSearchParams(data);

  const init = async () => {
    let bc: any;
    try {
      const hash = window.location.hash.slice(1);
      const queryParameters: any = {};
      for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
          queryParameters[key] = params.getAll(key);
        } else {
          queryParameters[key] = params.get(key);
        }
      }

      const { error, instanceParameters, hashParameters } = handleRedirectParameters(hash, queryParameters) as any;
      if (!queryParameters.preopenInstanceId) {
        bc = new BroadcastChannel(`redirect_channel_${instanceParameters.instanceId}`, broadcastChannelOptions);
        bc.addEventListener("message", async (ev: any) => {
          if (ev.success) {
            bc.close();
          } else {
            window.close();
          }
        });
        await bc.postMessage({
          data: {
            instanceParams: instanceParameters,
            hashParams: hashParameters,
            queryParams: queryParameters
          },
          error
        });

        setTimeout(() => {
          window.location.href = window.location.origin + window.location.search + window.location.hash;
        }, 5000);
      } else {
        bc = new BroadcastChannel(`preopen_channel_${queryParameters.preopenInstanceId}`, broadcastChannelOptions);
        bc.addEventListener("message", (ev: any) => {
          const { preopenInstanceId: oldId, payload, message } = ev.data;
          if (oldId === queryParameters.preopenInstanceId && payload?.url) {
            const url = new URL(payload.url);
            // if same origin, use router.push
            if (url.origin === window.location.origin) {
              const matchedRoute = url.pathname.replace(/^\/v\d+\.\d+\.\d+\//, "");
              history.push({
                pathname: matchedRoute,
                search: url.search
              });
            } else {
              window.location.href = payload.url;
            }
          } else if (oldId === queryParameters.preopenInstanceId && message === "setup_complete") {
            bc.postMessage({
              data: {
                preopenInstanceId: queryParameters.preopenInstanceId,
                message: "popup_loaded"
              }
            });
          }
          if (ev.error && ev.error !== "") {
            bc.close();
          }
        });
      }
    } catch (error) {
      if (bc) bc.close();
      window.close();
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen">
        <img src={Logo} alt="upbond-logo" />
        <IonSpinner name="crescent" color="primary" />
        <h2 className="text-2xl font-roboto mt-4.5 mb-2.5">Redirecting...</h2>
      </div>
    </>
  );
};
export default RedirectMsg;
