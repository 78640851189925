import { ObservableStore } from "@metamask/obs-store";
import { SafeEventEmitter } from "@toruslabs/openlogin-jrpc";
import deepmerge from "deepmerge";
import { hashPersonalMessage } from "ethereumjs-util";
import { cloneDeep } from "lodash";
import log from "loglevel";
import Web3 from "web3";

import config from "shared/config";
import ApiHelpers from "helpers/apiHelper";
import {
  ACCOUNT_TYPE,
  ACTIVITY_ACTION_RECEIVE,
  ACTIVITY_ACTION_SEND,
  ACTIVITY_ACTION_TOPUP,
  BADGES_COLLECTIBLE,
  BADGES_TOPUP,
  BADGES_TRANSACTION,
  ERROR_TIME,
  ETHERSCAN_SUPPORTED_NETWORKS,
  SUCCESS_TIME,
  THEME_LIGHT_BLUE_NAME
} from "shared/enums";
import { notifyUser } from "shared/utils/notificationUtils";
import { isErrorObject, prettyPrintData } from "shared/utils/permissionsUtils";
import {
  isMain,
  formatDate,
  formatPastTx,
  formatTime,
  getEthTxStatus,
  getIFrameOrigin,
  storageAvailable,
  waitForMs,
  catchError
} from "shared/utils/coreUtils";
import { providers } from "ethers";
import { setNewUser, walletActions } from "shared/actions/walletAction";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState, store } from "shared/store";
import { ControllerHandlerPayload } from "helpers/subscriptionHelper";

export type TxType = {
  id: string | number;
  transaction_hash: string;
  date: number;
  network: any;
  from: string;
  slicedFrom: string;
  to: string;
  slicedTo: string;
  totalAmount: string | number;
  totalAmountString: string;
  currencyAmount: string;
  currencyAmountString: string;
  amount: string | number;
  ethRate: any;
  status: string;
  etherscanLink: string;
  currencyUsed: any;
  action: string;
};

// By default, poll every 3 minutes
const DEFAULT_INTERVAL = 180 * 1000;
const DEFAULT_BADGES_COMPLETION = {
  [BADGES_COLLECTIBLE]: false,
  [BADGES_TOPUP]: false,
  [BADGES_TRANSACTION]: false
};

let themeGlobal = THEME_LIGHT_BLUE_NAME;
if (storageAvailable("localStorage")) {
  const torusTheme = localStorage.getItem("torus-theme");
  if (torusTheme) {
    themeGlobal = torusTheme;
  }
}

const overwriteMerge = (_: any, sourceArray: any) => sourceArray;

const DEFAULT_ACCOUNT_STATE = {
  selectedCurrency: "USD",
  theme: themeGlobal,
  contacts: [],
  permissions: [],
  badgesCompletion: {},
  jwtToken: "",
  fetchedPastTx: [],
  pastTransactions: [],
  paymentTx: [],
  tKeyOnboardingComplete: true,
  defaultPublicAddress: "",
  accountType: ACCOUNT_TYPE.NORMAL,
  customTokens: [],
  customNfts: []
};

class PreferencesController extends SafeEventEmitter {
  network: any;
  web3: Web3;
  api: ApiHelpers;
  signMessage: (x: any, y: any) => Promise<string>;
  store: ObservableStore<{ selectedAddress: any | string } & { [selectedAddress: symbol]: ControllerHandlerPayload | undefined }>;
  metadataStore: ObservableStore<{ [x: string]: any }>;
  errorStore: ObservableStore<string>;
  successStore: ObservableStore<string>;
  billboardStore: ObservableStore<{ [x: string]: any }>;
  announcementsStore: ObservableStore<{ [x: string]: any }>;
  private _handle: any;
  /**
   *
   * @typedef {Object} PreferencesController
   * @param {Object} opts - Overrides the defaults for the initial state of this.store
   */
  constructor(
    options = {} as {
      network: string | any;
      provider: SafeEventEmitter | providers.Web3Provider | any;
      signMessage: (a: any[], b: any) => Promise<string>;
      interval: number;
    }
  ) {
    super();

    const { network, provider, signMessage } = options;

    this.network = network;
    this.web3 = new Web3(provider);
    this.api = new ApiHelpers();
    this.signMessage = signMessage;

    this.interval = options.interval || DEFAULT_INTERVAL;
    this.store = new ObservableStore({ selectedAddress: "" }); // Account specific object
    this.metadataStore = new ObservableStore({});
    this.errorStore = new ObservableStore("");
    this.successStore = new ObservableStore("");
    this.billboardStore = new ObservableStore({});
    this.announcementsStore = new ObservableStore({});
  }

  headers(address?: string) {
    const selectedAddress = address || this.store.getState().selectedAddress;
    return {
      headers: {
        Authorization: `Bearer ${store.getState().wallet.prefs.addressState?.jwtToken[selectedAddress] || ""}`,
        "Content-Type": "application/json; charset=utf-8"
      }
    };
  }

  state(address?: string) {
    const selectedAddress = address || this.store.getState().selectedAddress;
    if (this.store.getState()[selectedAddress]) {
      return (this.store.getState() as any)[selectedAddress];
    }
    return null;
  }

  /**
   * Initializes address in preferences controller
   *
   * @param   {[String]}  address  Ethereum address
   * @return  {[void]}           void
   */
  async init({
    address,
    jwtToken,
    calledFromEmbed = false,
    userInfo = {},
    rehydrate = false,
    accountType = ACCOUNT_TYPE.NORMAL,
    postboxAddress,
    dispatch
  }: {
    address: string;
    jwtToken: string;
    calledFromEmbed: boolean;
    userInfo: { [x: string]: any };
    rehydrate: boolean;
    // eslint-disable-next-line prettier/prettier
    accountType: (typeof ACCOUNT_TYPE)[keyof typeof ACCOUNT_TYPE];
    postboxAddress: string;
    dispatch: ThunkDispatch<RootState, any, any>;
  }) {
    let response = { token: jwtToken };
    if (this.state(address)) return this.state(address).defaultPublicAddress || address;
    try {
      if (!jwtToken) {
        const messageToSign = await this.getMessageForSigning(address);
        if (!messageToSign.startsWith("Torus Signin")) throw new Error("Cannot sign on invalid message");
        const bufferedMessage = Buffer.from(messageToSign, "utf8");
        const hashedMessage = hashPersonalMessage(bufferedMessage).toString("hex");
        const signedMessage = await this.signMessage(address, hashedMessage);
        response = await this.api.post(
          `${config.api}/auth/verify`,
          {
            public_address: address,
            signed_message: signedMessage
          },
          {},
          { useAPIKey: true }
        );
      }
      const currentState = this.updateStore({ jwtToken: response.token }, address);
      const { verifier, verifierId } = userInfo;
      const user = await this.sync(address);
      let defaultPublicAddress = address;
      if (user?.data) {
        const {
          default_currency: defaultCurrency,
          verifier: storedVerifier,
          verifier_id: storedVerifierId,
          default_public_address
        } = user.data || {};
        store.dispatch(walletActions.setSelectedCurrency({ selectedCurrency: defaultCurrency, origin: "store" }));
        if (!storedVerifier || !storedVerifierId) this.setVerifier(verifier, verifierId, address);
        defaultPublicAddress = default_public_address;
      } else {
        const accountState = (this.store.getState() as any)[postboxAddress as any] || currentState;
        await this.createUser(accountState.selectedCurrency, accountState.theme, verifier, verifierId, accountType, address);
        dispatch(setNewUser(true));
        store.dispatch(walletActions.setSelectedCurrency({ selectedCurrency: accountState.selectedCurrency, origin: "store" }));
      }
      if (!rehydrate) this.storeUserLogin(verifier, verifierId, { calledFromEmbed, rehydrate }, address);

      return defaultPublicAddress;
    } catch (error) {
      console.warn(`[error]: `, error);
      if (this.state() && this.state().selectedAddress) {
        return this.state().selectedAddress;
      }
      return address;
    }
  }

  handleError(error: any) {
    if (isErrorObject(error)) {
      this.errorStore.putState(`Oops, That didn't work. Pls reload and try again. \n${error.message}`);
    } else if (error && typeof error === "string") {
      this.errorStore.putState(error);
    } else if (error && typeof error === "object") {
      const prettyError = prettyPrintData(error);
      const payloadError = prettyError !== "" ? `Error: ${prettyError}` : "Something went wrong. Pls try again";
      this.errorStore.putState(payloadError);
    } else {
      this.errorStore.putState(error || "");
    }
    setTimeout(() => this.errorStore.putState(""), ERROR_TIME);
  }

  handleSuccess(message: string) {
    if (message && typeof message === "string") {
      this.successStore.putState(message);
    } else if (message && typeof message === "object") {
      const prettyMessage = prettyPrintData(message);
      const payloadMessage = prettyMessage !== "" ? `Success: ${prettyMessage}` : "Success";
      this.successStore.putState(payloadMessage);
    } else {
      this.successStore.putState(message || "");
    }
    setTimeout(() => this.successStore.putState(""), SUCCESS_TIME);
  }

  async sync(address: string) {
    try {
      if (!address) return null;
      const user = await this.api.get(`${config.api}/user?fetchTx=false`, this.headers(address), { useAPIKey: true });
      const {
        default_currency: defaultCurrency,
        contacts,
        theme,
        enable_crash_reporter,
        locale,
        permissions,
        public_address,
        tkey_onboarding_complete,
        account_type,
        default_public_address,
        customTokens,
        customNfts
      } = user.data || {};
      let whiteLabelLocale;
      const badgesCompletion = DEFAULT_BADGES_COMPLETION;

      // White Label override
      if (storageAvailable("sessionStorage")) {
        const twl = sessionStorage.getItem("torus-white-label");
        if (twl) {
          const twlParsed = JSON.parse(twl);
          if (twlParsed) {
            whiteLabelLocale = twlParsed?.defaultLanguage;
          }
        }
      }

      this.updateStore(
        {
          contacts,
          theme,
          crashReport: Boolean(enable_crash_reporter),
          selectedCurrency: defaultCurrency,
          locale: whiteLabelLocale || locale,
          permissions,
          badgesCompletion,
          tKeyOnboardingComplete: account_type !== ACCOUNT_TYPE.NORMAL ? true : !!tkey_onboarding_complete,
          accountType: account_type || ACCOUNT_TYPE.NORMAL,
          defaultPublicAddress: default_public_address || public_address,
          customTokens,
          customNfts
        },
        public_address
      );
      return user;
    } catch (error) {
      console.warn(`[error]: preferences.sync and return undefined (catch): `, error);
      return null;
    } finally {
      Promise.all([
        this.api.getWalletOrders({}, this.headers(address).headers).catch((error) => {
          log.warn("unable to fetch wallet orders", error);
        }),
        this.api.getPastOrders({}, this.headers(address).headers).catch((error) => {
          log.warn("unable to fetch past orders", error);
        })
      ])
        .then((data) => {
          const [walletTx, paymentTx] = data;
          if (paymentTx?.data) {
            this.calculatePaymentTx(paymentTx.data, address);
          }
          if (walletTx?.data) {
            this.updateStore({ fetchedPastTx: walletTx.data }, address);
            this.calculatePastTx(walletTx.data, address);
          }
        })
        .catch((error) => console.error(`[error]: promise.all`, error));
    }
  }

  calculatePaymentTx(txs: TxType[], address: string) {
    const accumulator = [];
    for (const x of txs) {
      let action = "";
      const lowerCaseAction = x.action.toLowerCase();
      if (ACTIVITY_ACTION_TOPUP.includes(lowerCaseAction)) action = ACTIVITY_ACTION_TOPUP;
      else if (ACTIVITY_ACTION_SEND.includes(lowerCaseAction)) action = ACTIVITY_ACTION_SEND;
      else if (ACTIVITY_ACTION_RECEIVE.includes(lowerCaseAction)) action = ACTIVITY_ACTION_RECEIVE;

      accumulator.push({
        id: x.id,
        date: new Date(x.date),
        from: x.from,
        slicedFrom: x.slicedFrom,
        action,
        to: x.to,
        slicedTo: x.slicedTo,
        totalAmount: x.totalAmount,
        totalAmountString: x.totalAmountString,
        currencyAmount: x.currencyAmount,
        currencyAmountString: x.currencyAmountString,
        amount: x.amount,
        ethRate: x.ethRate,
        status: x.status.toLowerCase(),
        etherscanLink: x.etherscanLink || "",
        currencyUsed: x.currencyUsed
      });
    }
    this.updateStore({ paymentTx: accumulator }, address);
  }

  updateStore(newPartialState: { [x: string]: any }, address?: string) {
    const selectedAddress = address || this.store.getState().selectedAddress;
    const currentState = () => {
      if (this.state(selectedAddress)) {
        return this.state(selectedAddress);
      }
      return cloneDeep(DEFAULT_ACCOUNT_STATE);
    };
    const mergedState = deepmerge(currentState(), newPartialState, { arrayMerge: overwriteMerge });
    if (selectedAddress !== "") {
      this.store.updateState({
        [selectedAddress]: mergedState
      });
    }
    return mergedState;
  }

  async calculatePastTx(txs: TxType[], address: any) {
    const pastTx = [];
    const pendingTx = [];
    const lowerCaseSelectedAddress = address.toLowerCase();
    for (const x of txs) {
      if (
        x.network === this.network.getNetworkIdentifier() &&
        x.to &&
        x.from &&
        (lowerCaseSelectedAddress === x.from.toLowerCase() || lowerCaseSelectedAddress === x.to.toLowerCase())
      ) {
        if (x.status !== "confirmed") {
          pendingTx.push(x);
        } else {
          const finalObject = formatPastTx(x, lowerCaseSelectedAddress);
          pastTx.push(finalObject);
        }
      }
    }
    const pendingTxPromises = pendingTx.map((x) => getEthTxStatus(x.transaction_hash, this.web3).catch((error) => log.error(error)));
    const resolvedTxStatuses = await Promise.all(pendingTxPromises);
    for (const [index, element] of pendingTx.entries() as any) {
      const finalObject = formatPastTx(element, lowerCaseSelectedAddress);
      finalObject.status = resolvedTxStatuses[index];
      pastTx.push(finalObject);
      if (lowerCaseSelectedAddress === element.from.toLowerCase() && finalObject.status && finalObject.status !== element.status)
        this.patchPastTx({ id: element.id, status: finalObject.status }, address);
    }

    const finalTx = this.cancelTxCalculate(pastTx);
    this.updateStore({ pastTransactions: finalTx }, address);
  }

  cancelTxCalculate(pastTx: any[]) {
    const nonceMap = {};
    for (const x of pastTx) {
      if (!(nonceMap as any)[x.nonce]) (nonceMap as any)[x.nonce] = [x];
      else {
        (nonceMap as any)[x.nonce].push(x);
      }
    }

    for (const [, value] of Object.entries(nonceMap) as any) {
      // has duplicate
      if (value.length > 1) {
        // get latest and mark it as is_cancel
        const latestTxs = value.sort((a: { date: number }, b: { date: number }) => b.date - a.date);
        const latestCancelTx = {
          ...latestTxs[0],
          is_cancel: true
        };
        latestTxs
          .slice(1)
          .forEach(
            (x: {
              hasCancel: boolean;
              status: string;
              cancelDateInitiated: string;
              etherscanLink: string;
              cancelGas: any;
              cancelGasPrice: any;
            }) => {
              x.hasCancel = true;
              x.status = latestCancelTx.status === "confirmed" ? "cancelled" : "cancelling";
              x.cancelDateInitiated = `${formatTime(latestCancelTx.date)} - ${formatDate(latestCancelTx.date)}`;
              x.etherscanLink = latestCancelTx.etherscanLink;
              x.cancelGas = latestCancelTx.gas;
              x.cancelGasPrice = latestCancelTx.gasPrice;
            }
          );
      }
    }

    return pastTx;
  }

  async fetchEtherscanTx(address: any, network: any) {
    try {
      const tx = await this.api.getEtherscanTransactions(
        { selectedAddress: address, selectedNetwork: network },
        this.headers(address).headers
      );
      if (tx?.data) {
        this.emit("addEtherscanTransactions", tx.data, network);
      }
    } catch (error) {
      log.error("unable to fetch etherscan tx", error);
      catchError(error);
    }
  }

  async patchNewTx(tx: any, address: any) {
    const formattedTx = formatPastTx(tx, "");
    const storePastTx = this.state(address).pastTransactions;
    const duplicateIndex = storePastTx.findIndex((x: any) => x.transaction_hash === tx.transaction_hash && x.networkType === tx.network);
    if (tx.status === "submitted" || tx.status === "confirmed") {
      if (duplicateIndex === -1 && tx.status === "submitted") {
        // No duplicate found
        const finalTx = this.cancelTxCalculate([...storePastTx, formattedTx]);
        if (formattedTx.is_cancel) tx.is_cancel = formattedTx.is_cancel;
        if (tx.to) tx.to = tx.to.toLowerCase();
        if (tx.from) tx.from = tx.from.toLowerCase();

        this.updateStore({ pastTransactions: finalTx }, address);
        this.postPastTx(tx, address);
        try {
          notifyUser(formattedTx.etherscanLink);
        } catch (error) {
          console.warn(error);
          // log.error(error);
          // catchError(error);
        }
      } else {
        // avoid overriding is_cancel
        const newFormattedTx = {
          ...formattedTx,
          is_cancel: storePastTx[duplicateIndex] ? storePastTx[duplicateIndex].is_cancel : false
        };
        const newStorePastTx = [...storePastTx];
        newStorePastTx[duplicateIndex] = newFormattedTx;
        this.updateStore({ pastTransactions: this.cancelTxCalculate([...newStorePastTx]) }, address);
      }
    }
  }

  /* istanbul ignore next */
  async postPastTx(tx: any, address: any) {
    try {
      const response = await this.api.post(`${config.api}/transaction`, tx, this.headers(address), { useAPIKey: true });
      log.info("successfully added", response);
    } catch (error) {
      console.warn(error);
      // log.error(error, "unable to insert transaction");
      // catchError(error);
    }
  }

  /* istanbul ignore next */
  recalculatePastTx(address?: any) {
    // This triggers store update which calculates past Tx status for that network
    const selectedAddress = address || this.store.getState().selectedAddress;
    const state = this.state(selectedAddress);
    if (!state?.fetchedPastTx) return;
    this.calculatePastTx(state.fetchedPastTx, selectedAddress);
  }

  refetchEtherscanTx(address?: any) {
    const selectedAddress = address || this.store.getState().selectedAddress;
    if (this.state(selectedAddress)?.jwtToken) {
      const selectedNetwork = this.network.getNetworkIdentifier();
      if (ETHERSCAN_SUPPORTED_NETWORKS.has(selectedNetwork)) {
        this.fetchEtherscanTx(selectedAddress, selectedNetwork);
      }
    }
  }

  /* istanbul ignore next */
  async createUser(selectedCurrency: any, theme: any, verifier: any, verifierId: any, accountType: any, address: any) {
    await this.api.post(
      `${config.api}/user`,
      {
        default_currency: selectedCurrency,
        theme,
        verifier,
        verifierId,
        account_type: accountType
      },
      this.headers(address),
      { useAPIKey: true }
    );
    this.updateStore(
      {
        theme,
        tKeyOnboardingComplete: false,
        accountType: ACCOUNT_TYPE.NORMAL,
        defaultPublicAddress: address
      },
      address
    );
  }

  /* istanbul ignore next */
  storeUserLogin(verifier: any, verifierId: any, payload: any, address: any) {
    let userOrigin = "";
    if (payload && payload.calledFromEmbed) {
      userOrigin = getIFrameOrigin();
    } else userOrigin = window.location.origin;
    if (!payload.rehydrate) {
      const interval = setInterval(() => {
        const urlParameters = new URLSearchParams(window.location.search);
        const referrer = urlParameters.get("referrer") || "";
        if (window.location.href.includes("referrer") && !referrer) return;
        this.api.post(
          `${config.api}/user/recordLogin`,
          {
            hostname: userOrigin,
            verifier,
            verifierId,
            metadata: `referrer:${referrer}`
          },
          this.headers(address),
          { useAPIKey: true }
        );
        clearInterval(interval);
      }, 1000);
    }
  }

  async setUserTheme(payload: any) {
    if (payload === this.state()?.theme) return;
    try {
      await this.api.patch(`${config.api}/user/theme`, { theme: payload }, this.headers(), { useAPIKey: true });
      this.handleSuccess("navBar.snackSuccessTheme");
      this.updateStore({ theme: payload });
    } catch (error) {
      log.error(error);
      this.handleError("navBar.snackFailTheme");
      catchError(error);
    }
  }

  /* istanbul ignore next */
  async setCrashReport(payload: any) {
    if (payload === this.state()?.crashReport) return;
    try {
      await this.api.patch(`${config.api}/user/crashreporter`, { enable_crash_reporter: payload }, this.headers(), { useAPIKey: true });
      if (storageAvailable("localStorage")) {
        localStorage.setItem("torus-enable-crash-reporter", String(payload));
      }
      this.handleSuccess("navBar.snackSuccessCrashReport");
      this.updateStore({ crashReport: payload });
    } catch (error) {
      catchError(error);
      log.error(error);
      this.handleError("navBar.snackFailCrashReport");
    }
  }

  /* istanbul ignore next */
  async setPermissions(payload: any) {
    try {
      const response = await this.api.post(`${config.api}/permissions`, payload, this.headers(), { useAPIKey: true });
      log.info("successfully set permissions", response);
    } catch (error) {
      catchError(error);
      log.error("unable to set permissions", error);
    }
  }

  async setUserLocale(payload: any) {
    if (payload === this.state()?.locale) return;
    try {
      await this.api.patch(`${config.api}/user/locale`, { locale: payload }, this.headers(), { useAPIKey: true });
      this.updateStore({ locale: payload });
      // this.handleSuccess('navBar.snackSuccessLocale')
    } catch (error) {
      catchError(error);
      // this.handleError('navBar.snackFailLocale')
      log.error("unable to set locale", error);
    }
  }

  async setSelectedCurrency(payload: { selectedCurrency: string }) {
    if (!this.state()) return;
    if (payload.selectedCurrency === this.state()?.selectedCurrency) return;
    try {
      await this.api.patch(`${config.api}/user`, { default_currency: payload.selectedCurrency }, this.headers(), { useAPIKey: true });
      this.updateStore({ selectedCurrency: payload.selectedCurrency });
      this.handleSuccess("navBar.snackSuccessCurrency");
    } catch (error) {
      catchError(error);
      log.error(error);
      this.handleError("navBar.snackFailCurrency");
    }
  }

  async setTKeyOnboardingStatus(payload: any, address: string) {
    // This is called before set selected address is assigned
    try {
      await this.api.patch(`${config.api}/user`, { tkey_onboarding_complete: payload }, this.headers(address), { useAPIKey: true });
      this.updateStore({ tKeyOnboardingComplete: payload }, address);
      log.info("successfully updated onboarding status");
    } catch (error) {
      catchError(error);
      log.error(error, "unable to set onboarding status");
    }
  }

  /* istanbul ignore next */
  async setVerifier(verifier: any, verifierId: any, address: any) {
    try {
      const response = await this.api.patch(`${config.api}/user/verifier`, { verifier, verifierId }, this.headers(address), {
        useAPIKey: true
      });
      return response;
    } catch (error) {
      catchError(error);
    }
  }

  async setDefaultPublicAddress(ofAddress: any, address: any) {
    try {
      const response = await this.api.patch(`${config.api}/user`, { default_public_address: address }, this.headers(ofAddress), {
        useAPIKey: true
      });
      this.updateStore({ defaultPublicAddress: address }, ofAddress);
      log.info("successfully updated default public address", response);
    } catch (error) {
      catchError(error);
      log.error("unable to update default public address", error);
    }
  }

  /* istanbul ignore next */
  getEtherScanTokenBalances(address: any) {
    return this.api.get(`${config.api}/tokenbalances`, this.headers(address), { useAPIKey: true });
  }

  async getCovalentTokenBalances(address: any, chainId: any) {
    const api = `https://api.covalenthq.com/v1/${chainId}/address/${address}/balances_v2/?key=${process.env.REACT_APP_COVALENT_CKEY}`;
    // const api = `https://wallet-proxy.dev.upbond.io/covalent/v1/${chainId}/address/${address}/balances_v2/?wallet_address=test_wallet`
    // return this.api.get(`${config.api}/covalent?url=${encodeURIComponent(api)}`, this.headers(), { useAPIKey: true })
    return this.api.get(`${api}`);
  }

  async getCovalentTransaction(address: any, chainId: any, contractAddress: any) {
    const api = `https://api.covalenthq.com/v1/${chainId}/address/${address}/transfers_v2/?quote-currency=USD&format=JSON&contract-address=${contractAddress}&key=${process.env.REACT_APP_COVALENT_CKEY}`;
    // return this.api.get(`${config.api}/covalent?url=${encodeURIComponent(api)}`, this.headers(), { useAPIKey: true })
    return this.api.get(`${api}`);
  }

  async getAllChain() {
    const api = `https://api.covalenthq.com/v1/chains/?quote-currency=USD&format=JSON&key=${process.env.REACT_APP_COVALENT_CKEY}`;
    return this.api.get(`${api}`);
  }

  async getBillboardContents() {
    try {
      const { selectedAddress } = this.store.getState();
      if (!selectedAddress) return;
      const resp = await this.api.get(`${config.api}/billboard`, this.headers(), { useAPIKey: true });
      const events = resp.data.reduce((accumulator: any, event: any) => {
        if (!accumulator[event.callToActionLink]) accumulator[event.callToActionLink] = {};
        accumulator[event.callToActionLink][event.locale] = event;
        return accumulator;
      }, {});

      if (events) this.billboardStore.putState(events);
    } catch (error) {
      catchError(error);
      log.error(error);
    }
  }

  async addContact(payload: any) {
    try {
      const response = await this.api.post(`${config.api}/contact`, payload, this.headers(), { useAPIKey: true });
      this.updateStore({ contacts: [...this.state().contacts, response.data] });
      this.handleSuccess("navBar.snackSuccessContactAdd");
    } catch {
      this.handleError("navBar.snackFailContactAdd");
    }
  }

  async deleteContact(payload: any) {
    try {
      const response = await this.api.remove(`${config.api}/contact/${payload}`, {}, this.headers(), { useAPIKey: true });
      const finalContacts = this.state().contacts.filter((contact: any) => contact.id !== response.data.id);
      this.updateStore({ contacts: finalContacts });
      this.handleSuccess("navBar.snackSuccessContactDelete");
    } catch {
      this.handleError("navBar.snackFailContactDelete");
    }
  }

  async addCustomToken(payload: any) {
    try {
      // payload is { token_address, network, token_symbol, decimals, token_name }
      const response = await this.api.post(`${config.api}/customtoken`, payload, this.headers(), { useAPIKey: true });
      this.updateStore({ customTokens: [...this.state().customTokens, response.data] });
      this.handleSuccess("navBar.snackSuccessCustomTokenAdd");
    } catch {
      this.handleError("navBar.snackFailCustomTokenAdd");
    }
  }

  async deleteCustomToken(payload: any) {
    try {
      // payload is id
      const response = await this.api.remove(`${config.api}/customtoken/${payload}`, {}, this.headers(), { useAPIKey: true });
      const customTokens = this.state().customTokens.filter((x: any) => x.id.toString() !== response.data.id.toString());
      this.updateStore({ customTokens });
      this.handleSuccess("navBar.snackSuccessCustomTokenDelete");
    } catch {
      this.handleError("navBar.snackFailCustomTokenDelete");
    }
  }

  async addCustomNft(payload: any) {
    try {
      // payload is { nft_address, network, nft_name, nft_id, nft_contract_standard, nft_image_link, description, nft_balance }
      const apiPayload = {
        nft_address: payload.nft_address,
        network: payload.network,
        nft_name: payload.nft_name,
        nft_id: payload.nft_id,
        nft_contract_standard: payload.nft_contract_standard
      };
      const response = await this.api.post(`${config.api}/customnft`, apiPayload, this.headers(), { useAPIKey: true });
      const customNft = { ...payload, ...response.data };
      this.updateStore({ customNfts: [...this.state().customNfts, customNft] });
      this.handleSuccess("navBar.snackSuccessCustomNftAdd");
    } catch {
      this.handleError("navBar.snackFailCustomNftAdd");
    }
  }

  /* istanbul ignore next */
  async revokeDiscord(idToken: string) {
    try {
      const resp = await this.api.post(`${config.api}/revoke/discord`, { token: idToken }, this.headers(), { useAPIKey: true });
      log.info(resp);
    } catch (error) {
      catchError(error);
      log.error(error);
    }
  }

  /* istanbul ignore next */
  async patchPastTx(body: any, address: any) {
    try {
      const response = await this.api.patch(`${config.api}/transaction`, body, this.headers(address), { useAPIKey: true });
      log.info("successfully patched", response);
    } catch (error) {
      catchError(error);
      log.error("unable to patch tx", error);
    }
  }

  setSiteMetadata(origin: any, domainMetadata: any) {
    this.metadataStore.updateState({ [origin]: domainMetadata });
  }

  setSelectedAddress(address: any) {
    this.store.updateState({ selectedAddress: address });
    if (!Object.keys(this.store.getState()).includes(address)) return;
  }

  /**
   * @param {number} interval
   */
  set interval(interval: number) {
    if (this._handle) clearInterval(this._handle);
    if (!interval) {
      return;
    }
    if (isMain)
      this._handle = setInterval(() => {
        // call here
        const storeSelectedAddress = this.store.getState().selectedAddress;
        if (!storeSelectedAddress) return;
        if (!this.state(storeSelectedAddress)?.jwtToken) return;
        this.sync(storeSelectedAddress);
      }, interval);
  }

  async setUserBadge(payload: any) {
    const newBadgeCompletion = { ...this.state().badgesCompletion, [payload]: true };
    this.updateStore({ badgesCompletion: newBadgeCompletion });
    try {
      await this.api.patch(`${config.api}/user/badge`, { badge: JSON.stringify(newBadgeCompletion) }, this.headers(), { useAPIKey: true });
    } catch (error) {
      catchError(error);
      log.error("unable to set badge", error);
    }
  }

  async getMessageForSigning(publicAddress: string) {
    try {
      const response = await this.api.post(
        `${config.api}/auth/message`,
        {
          public_address: publicAddress
        },
        {},
        { useAPIKey: true }
      );
      return response.message;
    } catch (error) {
      catchError(error);
      log.error(error);
      return undefined;
    }
  }

  /* istanbul ignore next */
  async getCovalentNfts(api: string) {
    return this.api.get(`${api}`);
  }

  async getNftMetadata(api: string) {
    return this.api.get(`${api}`);
  }

  /* istanbul ignore next */
  async getOpenSeaCollectibles(api: string) {
    return this.api.get(`${api}`);
  }

  /* istanbul ignore next */
  async getTwitterId(payload: any) {
    const userId = await this.api.get(`${config.api}/twitter?screen_name=${payload.nick}`, this.headers(), { useAPIKey: true });
    return `${payload.typeOfLogin.toLowerCase()}|${userId.data.toString()}`;
  }

  /* istanbul ignore next */
  async sendEmail(payload: any) {
    // waiting for tx to get inserted first.
    await waitForMs(2000);
    return this.api.post(`${config.api}/transaction/sendemail`, payload.emailObject, this.headers(), { useAPIKey: true });
  }

  async getAnnouncementsContents() {
    try {
      const { selectedAddress } = this.store.getState();
      if (!selectedAddress) return;
      const resp = await this.api.get(`${config.api}/announcements`, this.headers(), { useAPIKey: true });
      const announcements = resp.data.reduce((accumulator: any, announcement: any) => {
        if (!accumulator[announcement.locale]) accumulator[announcement.locale] = [];
        accumulator[announcement.locale].push(announcement);
        return accumulator;
      }, {});

      if (announcements) this.announcementsStore.putState(announcements);
    } catch (error) {
      catchError(error);
      log.error(error);
    }
  }

  async fetchDappList() {
    if (!this.state()?.jwtToken) {
      // sometimes store does not have the token when this API call is made.
      await waitForMs(3000);
    }
    return this.api.get(`${config.api}/dapps`, this.headers(), { useAPIKey: true });
  }

  hideAnnouncement(payload: any, announcements: any) {
    const { id } = payload;
    const newAnnouncements = Object.keys(announcements).reduce((accumulator, key) => {
      const filtered = announcements[key].filter((x: any) => x.id !== id);
      (accumulator as any)[key] = filtered;
      return accumulator;
    }, {});

    if (newAnnouncements) this.announcementsStore.putState(newAnnouncements);
  }
}

export default PreferencesController;
