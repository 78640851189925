import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TouchIDPreferencesType } from "shared/utils/openlogininterface";
import { getJoinedKey } from "shared/utils/openloginutils";
import { DeviceModule } from "interfaces/actions/IOIDeviceModule";
import { fetchDeviceQuery } from "shared/utils/__generated__/device";

const initDeviceModuleState: DeviceModule = {
  credIdCache: [],
  credIdMapCache: {},
  devicePersistedInfo: {},
  isTouchIDRegistered: false,
  lastLoggedInVerifier: "",
  onboardingMap: {},
  touchIDVerifierIDMap: {},
  verifierIDDeviceIDMap: {},
  verifierToLastLoggedInVerifierID: {},
  walletTKeyOnboardingMap: {}
};
const deviceModuleSlice = createSlice({
  name: "olDeviceModule",
  initialState: initDeviceModuleState,
  reducers: {
    setDevicePersistedInfo(state, action: PayloadAction<{ deviceId: string; info: Partial<fetchDeviceQuery["devices"][0]> }>): void {
      state.devicePersistedInfo = {
        ...state.devicePersistedInfo,
        [action.payload.deviceId]: { ...state.devicePersistedInfo[action.payload.deviceId], ...action.payload.info }
      };
    },
    setVerifierDeviceId(state, action: PayloadAction<{ verifier: string; verifierId: string; deviceId: string }>): void {
      const { verifier, verifierId, deviceId } = action.payload;

      const key = getJoinedKey(verifier, verifierId);
      state.verifierIDDeviceIDMap = { ...state.verifierIDDeviceIDMap, [key]: deviceId };
    },
    clearVerifierDeviceId(state, action: PayloadAction<{ verifier: string; verifierId: string }>): void {
      const { verifier, verifierId } = action.payload;
      state.verifierIDDeviceIDMap = { ...state.verifierIDDeviceIDMap, [getJoinedKey(verifier, verifierId)]: "" };
    },
    setTouchIDRegistered(state): void {
      state.isTouchIDRegistered = true;
    },
    setTouchIDPreferenceState(state, action: PayloadAction<{ verifier: string; verifierId: string; preference: TouchIDPreferencesType }>) {
      const { verifier, verifierId, preference } = action.payload;
      state.touchIDVerifierIDMap = { ...state.touchIDVerifierIDMap, [getJoinedKey(verifier, verifierId)]: preference };
    },
    setTouchIDOnboarding(state, action: PayloadAction<{ verifier: string; verifierId: string; onboarding: boolean }>): void {
      state.onboardingMap = {
        ...state.onboardingMap,
        [getJoinedKey(action.payload.verifier, action.payload.verifierId)]: action.payload.onboarding
      };
    },
    setWalletTKeyOnboarding(state, action: PayloadAction<{ verifier: string; verifierId: string; onboarding: boolean }>): void {
      state.walletTKeyOnboardingMap = {
        ...state.walletTKeyOnboardingMap,
        [getJoinedKey(action.payload.verifier, action.payload.verifierId)]: action.payload.onboarding
      };
    },
    setLastLoggedInVerifier(state, action: PayloadAction<{ verifier: string }>): void {
      state.lastLoggedInVerifier = action.payload.verifier;
    },
    setLastLoggedIn(state, action: PayloadAction<{ verifier: string; verifierId: string }>): void {
      state.verifierToLastLoggedInVerifierID = {
        ...state.verifierToLastLoggedInVerifierID,
        [action.payload.verifier]: action.payload.verifierId
      };
    },
    addCredId(state, action: PayloadAction<{ verifier: string; verifierId: string; credId: string; confirmed: boolean }>): void {
      const { verifier, verifierId, credId, confirmed } = action.payload;
      const joinedKey = getJoinedKey(verifier, verifierId);
      if (!state.credIdMapCache[joinedKey]) {
        state.credIdMapCache[joinedKey] = {
          confirmed: false,
          credIds: []
        };
      }
      if (confirmed) {
        state.credIdMapCache[joinedKey] = {
          confirmed: true,
          credIds: [credId]
        };
      } else if (!state.credIdMapCache[joinedKey].confirmed) {
        if (!state.credIdMapCache[joinedKey].credIds.includes(credId)) {
          state.credIdMapCache[joinedKey].credIds.push(credId);
        }
      } else {
        // if we already have a credId that is confirmed, we ignore
      }
    }
  }
});

export default deviceModuleSlice;
