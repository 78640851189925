// @ts-nocheck
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow
} from "@ionic/react";
import HeaderMainPc from "components/header/pc";
import { useTranslation } from "react-i18next";

const PersonalInformationEditMainPc: React.FC<any> = ({ birthday, setBirthday, name, email }) => {
  const { t } = useTranslation();

  return (
    <IonPage className="ion-no-padding">
      <HeaderMainPc title={t("settings.managePermission_title")} link="/wallet/settings/init" />

      <IonContent className="bg-base ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol size="4">
              <IonCard class="ion-padding card-setting m-0">
                <IonCardContent>
                  <>
                    <p> {t("settings.managePermission_subtitle")}</p>
                    <IonList className="permission-form-list-desk">
                      <IonItem className="personal-wrapper font-normal text-base leading-[19px] text-[#5d5d5d]">
                        <div className="format-label">
                          <div className="format-label-start">
                            <IonLabel id="title" position="stacked">
                              {t("settings.name")}
                            </IonLabel>
                            <div className="required-field">{t("consentsForm.required")}</div>
                          </div>
                          <IonCheckbox slot="end" disabled checked></IonCheckbox>
                        </div>
                        <IonInput placeholder="Enter name" value={name} className="permission" disabled></IonInput>
                      </IonItem>
                      <IonItem className="personal-wrapper font-normal text-base leading-[19px] text-[#5d5d5d]">
                        <div className="format-label">
                          <div className="format-label-start">
                            <IonLabel id="title" position="stacked">
                              Birthday
                            </IonLabel>
                          </div>
                          <IonCheckbox slot="end" checked></IonCheckbox>
                        </div>
                        <IonInput
                          placeholder="Enter birthday"
                          value={new Date(birthday).toString() === "Invalid Date" ? "" : new Date(birthday).toLocaleDateString()}
                          onIonChange={(e) => setBirthday(e.target.value as string)}
                          className="permission"></IonInput>
                      </IonItem>

                      <IonItem className="personal-wrapper font-normal text-base leading-[19px] text-[#5d5d5d]">
                        <div className="format-label">
                          <div className="format-label-start">
                            <IonLabel id="title" position="stacked">
                              {t("settings.email")}
                            </IonLabel>
                            <div className="required-field">{t("consentsForm.required")}</div>
                          </div>
                          <IonCheckbox slot="end" checked disabled></IonCheckbox>
                        </div>
                        <IonInput placeholder="Enter email" value={email} className="permission" disabled></IonInput>
                      </IonItem>
                    </IonList>
                    <div>
                      <IonButton shape="round" color="primary" expand="block">
                        {t("settings.save")}
                      </IonButton>
                    </div>
                  </>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PersonalInformationEditMainPc;
