import { BroadcastChannel } from "@toruslabs/broadcast-channel";
import CustomAuth, {
  AggregateLoginParams,
  HybridAggregateLoginParams,
  LOGIN,
  LOGIN_TYPE,
  randomId,
  SubVerifierDetails,
  TORUS_METHOD
} from "@toruslabs/customauth";
import { get } from "@toruslabs/http-helpers";
import { privateToAddress } from "ethereumjs-util";
import useLoading from "hooks/useLoading";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import log from "loglevel";
import React, { useEffect, useState } from "react";
import { olAllDappModuleAction, registerDappModule } from "shared/actions/olAllDappModuleAction";
import { logout, olDappModuleAction, setPreferencesAndRedirect } from "shared/actions/olDappModuleAction";
import { olDeviceModuleAction, setTouchIDPreference } from "shared/actions/olDeviceModuleAction";
import { addOrUpdateLoginRecord } from "shared/actions/olLoginPerfModuleAction";
import {
  appendTkeyCreationFactor,
  olLoginPerfModuleAction,
  olTkeyModuleAction,
  setDeviceWebAuthnRegistered
} from "shared/actions/olTkeyModuleAction";
import { olUserModuleAction } from "shared/actions/olUserModuleAction";
import handleCustomAuthError from "shared/utils/handleCustomAuthError";
import { loginOAuthV1User, loginOAuthV2User } from "shared/utils/oauthlogin";
import { cleanupOAuth, parseCustomAuthResult } from "shared/utils/openloginauthutil";
import config from "shared/utils/openloginconfig";
import { AUTH_OP, DISCORD_ACCESS_DENIED_ERROR, ERROR_MISSING_PARAMS, WEBAUTHN_DENIAL_ERROR } from "shared/utils/openloginenums";
import {
  CustomAuthResult,
  IAuthServiceResult,
  LoginType,
  TouchIDPreferences,
  UpbondIdToken,
  WebAuthnFlow
} from "shared/utils/openlogininterface";
import { getServerTimeOffset } from "shared/utils/openloginmutation";
import webAuthnLogin from "shared/utils/web3authlogin";
import { registerWebAuthnV1User, registerWebAuthnV2User } from "shared/utils/web3authregister";
import "./style.css";
import { redirectToDapp } from "shared/utils/openloginutils";
import { isJson } from "shared/utils/coreUtils";

export const tabsBroadcast = () => {
  const bc = new BroadcastChannel(`upbond_close_tab`);
  return bc;
};

const AuthPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { olAllDappModule, olTKeyModule, olUserModule } = useAppSelector((state) => state);
  const [value, setValue] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorType, setErrorType] = useState<any>();

  const { DisplayLoadingNew, setLoading } = useLoading();

  const parseCustomAuthArgs = (
    args: unknown,
    method: string,
    hashParameters: Record<string, string>
  ): { typeOfLogin: LOGIN_TYPE; oAuthToken: string } => {
    const oAuthToken = hashParameters.id_token || hashParameters.access_token;
    if (method === TORUS_METHOD.TRIGGER_LOGIN) {
      const methodArgs = args as SubVerifierDetails & { registerOnly?: boolean };
      return { typeOfLogin: methodArgs.typeOfLogin, oAuthToken };
    }
    if (method === TORUS_METHOD.TRIGGER_AGGREGATE_LOGIN) {
      const methodArgs = args as AggregateLoginParams;
      return { typeOfLogin: methodArgs.subVerifierDetailsArray[0].typeOfLogin, oAuthToken };
    }
    if (method === TORUS_METHOD.TRIGGER_AGGREGATE_HYBRID_LOGIN) {
      const methodArgs = args as HybridAggregateLoginParams;
      return { typeOfLogin: methodArgs.singleLogin.typeOfLogin, oAuthToken };
    }
    throw new Error("Unsupported method type");
  };

  const parseIdToken = (token: string, pureJwt = false): UpbondIdToken => {
    if (pureJwt) {
      const json = decodeURIComponent(
        window
          .atob(token)
          .split("")
          .map((c) => {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
          })
          .join("")
      );
      return JSON.parse(json);
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  //check user upbond or torus
  const checkUpbondUser = async (provider: string, result: CustomAuthResult): Promise<boolean> => {
    const { accessToken, idToken } = result.userInfo;
    try {
      if (idToken) {
        try {
          const decodedTokenId = parseIdToken(idToken);
          if (accessToken && decodedTokenId && decodedTokenId.identities) {
            dispatch(
              olUserModuleAction.setAuthServiceUserInfo({
                accessToken,
                sub: decodedTokenId.id
              })
            );
          }

          if (decodedTokenId && decodedTokenId.identities) {
            // Get identities index of provider
            const providerIndex = decodedTokenId.identities.findIndex((p) => p.provider === provider);

            if (
              decodedTokenId &&
              decodedTokenId.identities[providerIndex] &&
              decodedTokenId.identities[providerIndex].is_wallet_registered &&
              decodedTokenId.identities[providerIndex].wallet_version
            ) {
              dispatch(
                olUserModuleAction.setUserWalletData({
                  isRegistered: decodedTokenId.identities[providerIndex].is_wallet_registered as boolean,
                  walletVersion: decodedTokenId.identities[providerIndex].wallet_version as string
                })
              );
              return (decodedTokenId?.identities[providerIndex as number].is_wallet_registered &&
                decodedTokenId?.identities[providerIndex].wallet_version === "2.0.0") as boolean;
            }
            return (decodedTokenId?.is_wallet_registered && decodedTokenId?.wallet_version === "2.0.0") as boolean;
          }
        } catch (error) {
          return false;
        }
      }

      if (accessToken) {
        const upbondUrl = process.env.VUE_APP_LOGIN_DOMAIN;
        const authServiceInfo = await get<IAuthServiceResult>(`${upbondUrl}userinfo`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
        dispatch(
          olUserModuleAction.setAuthServiceUserInfo({
            accessToken,
            sub: authServiceInfo.id as string
          })
        );
        // Get identities index of provider
        const providerIndex = authServiceInfo?.identities?.findIndex((p) => p.provider === provider);
        if (
          authServiceInfo.identities &&
          authServiceInfo.identities[providerIndex as number] &&
          authServiceInfo.identities[providerIndex as number].is_wallet_registered &&
          authServiceInfo.identities[providerIndex as number].wallet_version
        ) {
          dispatch(
            olUserModuleAction.setUserWalletData({
              isRegistered: authServiceInfo.identities[providerIndex as number].is_wallet_registered as boolean,
              walletVersion: authServiceInfo.identities[providerIndex as number].wallet_version as string
            })
          );
        }
        if (
          authServiceInfo.identities &&
          authServiceInfo.identities[providerIndex as number] &&
          authServiceInfo.identities[providerIndex as number].is_wallet_registered &&
          authServiceInfo.identities[providerIndex as number].wallet_version
        ) {
          if (
            authServiceInfo.identities[providerIndex as number].is_wallet_registered === true &&
            authServiceInfo.identities[providerIndex as number].wallet_version === "2.0.0"
          ) {
            return true;
          }
          if (authServiceInfo?.is_wallet_registered && authServiceInfo?.wallet_version === "2.0.0") {
            return true;
          }
          return false;
        }
      }

      return false;
    } catch (error) {
      log.error(error, "@errorWhileCheckUpbondUser");
      return false;
    }
  };

  // all logic from auth
  const init = async () => {
    try {
      setValue(0);
      let customAuth: CustomAuth | undefined = undefined;
      customAuth = new CustomAuth({
        baseUrl: window.location.origin,
        redirectPathName: "auth",
        uxMode: "redirect",
        network: config.torusNetwork,
        enableLogging: config.logLevel !== "error",
        locationReplaceOnRedirect: true,

        // v2
        metadataUrl: config.metadataHost,
        enableOneKey: true,
        // networkUrl: config.networkUrl,
        storageServerUrl: config.storageServerUrl,
        web3AuthClientId: olUserModule.currentDappClientId || (config.openloginDappModuleKey as string)
      });

      console.log(
        "login, @customAuth: ",
        JSON.stringify({
          baseUrl: window.location.origin,
          redirectPathName: "auth",
          uxMode: "redirect",
          network: config.torusNetwork,
          enableLogging: config.logLevel !== "error",
          locationReplaceOnRedirect: true,

          // v2
          metadataUrl: config.metadataHost,
          enableOneKey: true,
          // networkUrl: config.networkUrl,
          storageServerUrl: config.storageServerUrl,
          web3AuthClientId: olUserModule.currentDappClientId || (config.openloginDappModuleKey as string)
        })
      );

      // if coming from lzg (user cancel login)
      let callbackErrorMsg = "";
      let callbackErrorState = "";
      setValue(0.15);

      try {
        const authServiceCallback = window.location.href;
        const callback = authServiceCallback.split("#")[1].split("&");
        callbackErrorMsg = callback[0];
        callbackErrorState = callback[2];
      } catch {
        console.log("@error");
        const query = window.location.search;
        if (query === "") {
          dispatch(olTkeyModuleAction.logout());
          dispatch(olUserModuleAction.logout());
          // this.$router.push({ path: "/" }).catch(() => { });
        }
      }
      setValue(0.26);
      if (callbackErrorMsg.includes("access_denied")) {
        try {
          const decodedCallbackState: any = parseIdToken(callbackErrorState.split("=")[1].split("%")[0], true);
          const cbWl = JSON.parse(decodedCallbackState.whiteLabel);
          const dappUrl = cbWl.dappRedirectUri;
          setTimeout(() => {
            window.location.href = dappUrl;
          }, 2000);
          return;
        } catch (error) {
          log.warn("access_denied from auth service, forcing login");
        }
      }
      setValue(0.33);
      const timeOffset = await getServerTimeOffset();
      customAuth.torus.serverTimeOffset = timeOffset || 0;
      dispatch(olUserModuleAction.setClientTimeOffset({ offset: timeOffset || 0 }));

      const operationName = AUTH_OP;
      // start measuring perf
      window.performance.mark(`${operationName}_start`);
      let customAuthState: Record<string, unknown> = {};
      let customAuthArgs: unknown = {};
      let customAuthHashParams: Record<string, string> | undefined = {};
      let customAuthError = "";
      let customAuthMethod = "";
      let customAuthTypeOfUser = "";
      let popupWindow = false;
      let pid = "";
      setValue(0.42);
      try {
        const oldresult = localStorage.getItem("result");
        const redirectResult =
          oldresult && isJson(oldresult)
            ? JSON.parse(oldresult)
            : await customAuth.getRedirectResult({
                replaceUrl: false,
                clearLoginDetails: false
              });
        setValue(0.55);
        localStorage.setItem("result", JSON.stringify(redirectResult));
        customAuthState = redirectResult.state;
        const { webAuthnFlow, client } = customAuthState as Record<string, string>;
        customAuthArgs = redirectResult.args;
        customAuthHashParams = redirectResult.hashParameters;
        customAuthMethod = redirectResult.method;
        pid = customAuthState.pid as string;
        popupWindow = customAuthState.popupWindow === "true";
        if (!client) throw new Error(ERROR_MISSING_PARAMS);
        console.log("login, 1 ClientID: ", JSON.stringify(client));
        dispatch(olUserModuleAction.setCurrentClientId({ currentDappClientId: client }));
        dispatch(olDappModuleAction.setSessionId());

        registerDappModule(client);
        console.log("login, 2 ClientID: ", JSON.stringify(olUserModule.currentDappClientId));
        const currentDappModule = olAllDappModule.dappModules[olUserModule.currentDappClientId];
        const isUsingDirect = true;
        const dappRedirectUri = (localStorage.getItem("dappRedirectUri") as string) || `${window.location.origin}/end`;

        setValue(0.69);

        // To not mess tkey up
        if (currentDappModule.currentLoginProvider !== customAuthState.currentLoginProvider) {
          console.log("currentDappModule.currentLoginProvider: ", JSON.stringify(currentDappModule));
          console.log("customAuthState.currentLoginProvider: ", JSON.stringify(customAuthState));
          console.error("currentDappModule.currenterrorinProvider: ", JSON.stringify(currentDappModule));
          console.error("customAuthState.errorovider: ", JSON.stringify(customAuthState));
          throw new Error("Invalid login");
        }
        if (redirectResult.error) {
          customAuthError = redirectResult.error;
          const { canContinue, displayError } = await handleCustomAuthError(redirectResult);
          if (displayError) setErrorType(displayError);
          if (!canContinue) return;
        }

        setValue(0.76);
        const customAuthResult = parseCustomAuthResult(redirectResult);
        customAuthTypeOfUser = customAuthResult.typeOfUser;

        const { userInfo, typeOfUser } = customAuthResult;

        // Get login provider (line, google, etc)
        // Login provider MUST BE upbond-xxx
        const provider = customAuthState.currentLoginProvider.split("-")[1];

        let parsedWL = {
          dappRedirectUri
        } as {
          dappRedirectUri: string;
          embedWlRedirectUrl?: string;
        };
        let embedWlRedirectUrl = "";
        const {
          userInfo: {
            state: { whiteLabel }
          }
        } = customAuthResult;
        setValue(0.83);

        dispatch(
          olDappModuleAction.updateState({
            redirectUrl: parsedWL.dappRedirectUri
          } as any)
        );

        if (whiteLabel) {
          try {
            parsedWL = JSON.parse(whiteLabel as string);
            if (parsedWL.embedWlRedirectUrl) {
              embedWlRedirectUrl = parsedWL.embedWlRedirectUrl;
              dispatch(
                olDappModuleAction.updateState({
                  embedWlRedirectUrl
                } as any)
              );

              dispatch(
                olDappModuleAction.updateState({
                  redirectUrl: parsedWL.embedWlRedirectUrl
                } as any)
              );
            }
          } catch {
            parsedWL = {
              dappRedirectUri
            };
          }
        }
        setValue(0.9);

        const tabsBc = tabsBroadcast();
        tabsBc.postMessage({
          data: {
            close: true,
            id: randomId()
          }
        });
        const isUserRegistered = await checkUpbondUser(provider, customAuthResult);
        setValue(0.95);
        if (userInfo.typeOfLogin === LOGIN.WEBAUTHN) {
          if (webAuthnFlow === WebAuthnFlow.REGISTER) {
            if (!customAuthResult.pubKey) {
              throw new Error("register only flow must return an extended pubkey");
            }
            let canContinue = true;
            let displayError = "";
            let oAuthPrivateKey = "";
            let tKey = "";
            // This is webauthn flow of register
            if (customAuthState.keyMode !== "v1") {
              // Do webauthn v2 registration
              ({ canContinue, displayError = "", oAuthPrivateKey = "", tKey = "" } = await registerWebAuthnV2User(customAuthResult));
            } else {
              // Do webauthn v1 registration
              ({ canContinue, displayError = "", oAuthPrivateKey = "", tKey = "" } = await registerWebAuthnV1User(customAuthResult));
            }

            if (displayError) {
              setErrorType(displayError);
              return;
            }
            if (!canContinue) return;

            // Create WebAuthn device metadata
            if (olTKeyModule.settingsPageData.deviceShare.share)
              // make this atomic
              await setDeviceWebAuthnRegistered({
                shareIndex: olTKeyModule.settingsPageData.deviceShare.share.share.shareIndex.toString("hex")
              });

            const userTkeyDataUpdatePromises = [];
            if (olTKeyModule.additionalSecurity) {
              dispatch(olTkeyModuleAction.setAdditionalSecurity(null));
            }
            const { onlyWebAuthn, isEnablingWebauthn, walletKey } = customAuthState as Record<string, string>;
            // Add additional security
            if (olTKeyModule.additionalSecurity && onlyWebAuthn !== "true" && isEnablingWebauthn !== "true") {
              userTkeyDataUpdatePromises.push(appendTkeyCreationFactor({ factor: "webauthn|email", sync: true }));
            } else if (onlyWebAuthn !== "true" && isEnablingWebauthn !== "true") {
              userTkeyDataUpdatePromises.push(appendTkeyCreationFactor({ factor: "webauthn", sync: true }));
            }
            // if user is reenabling webauthn from account screen after login then "isEnablingWebauthn" will be true,
            // it is not required to add or update login record in that case.
            console.log("login, 3 ClientID: ", JSON.stringify(client));
            console.log("login, 1 Verifier: ", JSON.stringify(olUserModule.userInfo.aggregateVerifier + olUserModule.userInfo.verifierId));
            if (isEnablingWebauthn !== "true") {
              userTkeyDataUpdatePromises.push(
                addOrUpdateLoginRecord({
                  walletAddress: walletKey ? privateToAddress(Buffer.from(walletKey.padStart(64, "0"), "hex")).toString("hex") : undefined,
                  verifier: olUserModule.userInfo.aggregateVerifier,
                  verifierId: olUserModule.userInfo.verifierId,
                  loginRoute: "auth",
                  clientId: client,
                  dappUrl: currentDappModule.redirectUrl,
                  isLoginCompleted: true,
                  webauthnEnabled: true,
                  loginType: olTKeyModule.keyMode as LoginType,
                  mobileOrigin: currentDappModule.mobileOrigin,
                  sessionId: currentDappModule.sessionId
                })
              );
            }

            await Promise.all([
              ...userTkeyDataUpdatePromises,

              setTouchIDPreference({
                verifier: olUserModule.userInfo.aggregateVerifier,
                verifierId: olUserModule.userInfo.verifierId,
                preference: TouchIDPreferences.ENABLED
              })
            ]);

            dispatch(olDeviceModuleAction.setTouchIDRegistered());
            console.log("login, 4 ClientID: ", JSON.stringify(olUserModule.currentDappClientId));
            console.log("login, 2 Verifier: ", JSON.stringify(olUserModule.userInfo.aggregateVerifier + olUserModule.userInfo.verifierId));
            dispatch(
              olAllDappModuleAction.setTouchIDPreference({
                clientId: olUserModule.currentDappClientId,
                touchIDPreference: TouchIDPreferences.ENABLED
              })
            );
            dispatch(olDappModuleAction.setTouchIDPreference(TouchIDPreferences.ENABLED));
            if (customAuthState?.instanceId) customAuth.storageHelper.clearLoginDetailsStorage(customAuthState.instanceId as string);

            setPreferencesAndRedirect({
              pid,
              popupWindow,
              redirectUrl: parsedWL.dappRedirectUri || currentDappModule.redirectUrl,
              result: { tKey, oAuthPrivateKey, walletKey },
              verifier: olUserModule.userInfo.aggregateVerifier,
              verifierId: olUserModule.userInfo.verifierId,
              embedWlRedirectUrl,
              disableAlwaysSkip: customAuthState?.disableAlwaysSkip === "true" // TODO: send this flag from setting screen while disabling always skip tkey @lionell
            });
            console.log("login, 3 Verifier: ", JSON.stringify(olUserModule.userInfo.aggregateVerifier + olUserModule.userInfo.verifierId));
          } else {
            // This is webauthn flow of login (aka fast login)
            // typeOfUser has no meaning here
            const {
              canContinue,
              displayError = "",
              oAuthPrivateKey = "",
              tKey = "",
              walletKey = ""
            } = await webAuthnLogin(customAuthResult);
            if (displayError) {
              setErrorType(displayError);
              return;
            }
            if (!canContinue) return;
            if (customAuthState?.instanceId) customAuth.storageHelper.clearLoginDetailsStorage(customAuthState.instanceId as string);
            setPreferencesAndRedirect({
              pid: customAuthState.pid as string,
              popupWindow: customAuthState.popupWindow === "true",
              redirectUrl: parsedWL.dappRedirectUri || currentDappModule.redirectUrl,
              result: { tKey, oAuthPrivateKey, walletKey },
              verifier: olUserModule.userInfo.aggregateVerifier,
              verifierId: olUserModule.userInfo.verifierId,
              embedWlRedirectUrl,
              disableAlwaysSkip: customAuthState?.disableAlwaysSkip === "true" // TODO: send this flag from setting screen while disabling always skip tkey @lionell
            });
            console.log("login, 4 Verifier: ", JSON.stringify(olUserModule.userInfo.aggregateVerifier + olUserModule.userInfo.verifierId));
          }
        } else {
          let canContinue = false;
          let displayError = "";
          let oAuthPrivateKey = "";
          let tKey = "";
          let walletKey = "";
          let tKeyString = "";
          // This is not webauthn flow

          if (typeOfUser === "v2") {
            // Do v2 login
            ({
              canContinue,
              displayError = "",
              oAuthPrivateKey = "",
              tKey = "",
              walletKey = "",
              tKeyString = ""
            } = await loginOAuthV2User(customAuthResult, isUserRegistered));
          } else {
            // Do v1 login
            ({
              canContinue,
              displayError = "",
              oAuthPrivateKey = "",
              tKey = "",
              walletKey = "",
              tKeyString = ""
            } = await loginOAuthV1User(customAuthResult, isUserRegistered));
          }
          if (displayError) {
            setErrorType(displayError);
            return;
          }
          if (!canContinue) return;
          if (customAuthState?.instanceId) customAuth.storageHelper.clearLoginDetailsStorage(customAuthState.instanceId as string);

          setValue(1);
          // @dwiyan -> comment this if you want to debug
          setPreferencesAndRedirect({
            isUsingDirect: isUsingDirect || false,
            dappRedirectUri: parsedWL.dappRedirectUri || window.location.origin,
            pid,
            embedWlRedirectUrl,
            popupWindow,
            redirectUrl: localStorage.getItem("dappRedirectUri") as string,
            result: { tKey, oAuthPrivateKey, walletKey, tKeyString },
            verifier: olUserModule.userInfo.aggregateVerifier,
            verifierId: olUserModule.userInfo.verifierId,
            disableAlwaysSkip: customAuthState?.disableAlwaysSkip === "true" // TODO: send this flag from setting screen while disabling always skip tkey @lionell
          });
          console.log("login, 5 Verifier: ", JSON.stringify(olUserModule.userInfo.aggregateVerifier + olUserModule.userInfo.verifierId));
        }
      } catch (error: unknown) {
        console.log("@err", error);
        try {
          const tokenArgs = parseCustomAuthArgs(customAuthArgs, customAuthMethod, customAuthHashParams || {});
          await cleanupOAuth(tokenArgs.typeOfLogin, tokenArgs.oAuthToken);
        } catch (error2: unknown) {
          log.warn(error2, "unable to clean up direct auth token", customAuthArgs, customAuthMethod, customAuthHashParams);
        }

        registerDappModule(customAuthState.client as string);
        const currentDappModule = olAllDappModule.dappModules[customAuthState.client as string];
        // If direct auth login fails, disable fast login
        console.log("login, 5 ClientID: ", JSON.stringify(customAuthState.client));
        console.log("login, 6 Verifier: ", JSON.stringify(olUserModule.userInfo.aggregateVerifier + olUserModule.userInfo.verifierId));
        if (customAuthState.client) {
          await addOrUpdateLoginRecord({
            verifierId: olUserModule.userInfo.verifierId,
            verifier: olUserModule.userInfo.aggregateVerifier,
            loginRoute: "auth",
            errorStack: (error as Error)?.stack || (error as Error).message || "Something went wrong",
            isLoginCompleted: true,
            clientId: customAuthState.client as string,
            dappUrl: currentDappModule.redirectUrl,
            // In case of error with OAuth, we don't know the login type
            loginType: (olTKeyModule.keyMode as LoginType) || (customAuthTypeOfUser as LoginType) || "v1",
            mobileOrigin: currentDappModule.mobileOrigin,
            sessionId: currentDappModule.sessionId
          });
          dispatch(
            olLoginPerfModuleAction.markRouteAndTime({
              route: "auth",
              isEnd: true
            })
          );
          await logout();
          if (customAuthState?.instanceId) customAuth.storageHelper.clearLoginDetailsStorage(customAuthState.instanceId as string);
          if (
            (error as Error)?.message?.replace("%20", " ").toLowerCase().includes("startrail custom") ||
            (error as Error)?.message?.includes(DISCORD_ACCESS_DENIED_ERROR) ||
            (error as Error)?.message?.includes(WEBAUTHN_DENIAL_ERROR)
          ) {
            await redirectToDapp(
              {
                redirectUrl: currentDappModule.redirectUrl,
                popupWindow: customAuthState.popupWindow === "true",
                sessionTime: currentDappModule.sessionTime,
                sessionId: currentDappModule.sessionId,
                _sessionNamespace: currentDappModule._sessionNamespace
              },
              {
                pid: customAuthState.pid ? (customAuthState.pid as string) : "",
                result: {
                  tKey: "",
                  oAuthPrivateKey: "",
                  tKeyString: "",
                  privKey: "",
                  store: {
                    appState: currentDappModule.appState
                  },
                  error: (error as Error)?.message || "Something went wrong"
                }
              }
            );
            return;
          }
          setErrorType(customAuthError || (error as Error)?.message || "Something went wrong");
        } else {
          if (customAuthState?.instanceId) customAuth.storageHelper.clearLoginDetailsStorage(customAuthState.instanceId as string);
          // user is enabling webauthn from accounts screen and cancelled it during the webauthn login flow
          // we can safely redirect to the openlogin home page.
          if (customAuthState?.isEnablingWebauthn) {
            window.location.replace("/wallet/home");
            return;
          }
          setErrorType(customAuthError || (error as Error)?.message || "Something went wrong");
          if (localStorage.getItem("dappRedirectUri") !== null) {
            const link = localStorage.getItem("dappRedirectUri");
            const errorMsg = customAuthError || (error as Error)?.message || "Something went wrong";
            window.location.href = `${link}#error=${errorMsg}#redirect_uri=${encodeURIComponent(link as string)}`;
            return;
          }
          window.location.replace(`/#error=${customAuthError || (error instanceof Error ? error.message : "Something went wrong")}`);
        }
      }
    } catch (error) {
      console.log("@error", error);
      setLoading(false, "Error occurred");
      window.location.href = `/login`;
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="content">
      <DisplayLoadingNew value={value} />
    </div>
  );
};
export default AuthPage;
