// @ts-nocheck
import { IonButton, IonContent, IonFooter, IonInput, IonLabel, IonPage } from "@ionic/react";
import { ConfirmNftMainProps } from "features/transfer/confirmNft/types";
import { useTranslation } from "react-i18next";
import ContactBar from "features/transfer/components/ContactBar";
import GasModal from "features/transfer/components/GasModal";
import HeaderMainMb from "components/header/mb";

const ConfirmNftMainMb = (props: ConfirmNftMainProps) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <HeaderMainMb title={t("transfer.confirmTransferTitle")} />

      <IonContent className="ion-padding overflow-auto">
        <ContactBar />
        {props.checkType > 0 && (
          <div className="pb-4">
            <IonLabel position="stacked">{t("transfer.amountToSend")}</IonLabel>

            <div className="flex flex-row justify-between items-center py-1 px-4 my-2.5 rounded-custom border border-[#d3d3d3]">
              <IonInput
                type="text"
                value={props.amountToSend}
                onKeyPress={(e) => !/^[\d]+/.test(e.key) && e.preventDefault()}
                onIonChange={(e) => props.amountChanged(e)}
              />
            </div>
          </div>
        )}
      </IonContent>

      <IonFooter>
        <div className="px-4">
          <IonButton
            expand="block"
            className="h-12"
            color={props.isConfirmDisabled ? "medium" : "primary"}
            onClick={props.proceedTransfer}
            disabled={props.isConfirmDisabled}>
            {t("transfer.proceed")}
          </IonButton>
        </div>
        <GasModal />
      </IonFooter>
    </IonPage>
  );
};

export default ConfirmNftMainMb;
