/* eslint-disable @typescript-eslint/ban-ts-comment */
import { JsonRpcRequest } from "@json-rpc-tools/utils";
import { createAsyncMiddleware, createScaffoldMiddleware, mergeMiddleware } from "@toruslabs/openlogin-jrpc";
import { createWalletMiddleware } from "eth-json-rpc-middleware";
import { TRANSACTION_ENVELOPE_TYPES } from "shared/enums";

export default function createMetamaskMiddleware({
  version,
  getAccounts,
  processTransaction,
  processEthSignMessage,
  processTypedMessage,
  processTypedMessageV3,
  processTypedMessageV4,
  processPersonalMessage,
  getPendingNonce,
  getPendingTransactionByHash,
  processEncryptionPublicKey,
  processDecryptMessage
}: any) {
  const metamaskMiddleware = mergeMiddleware([
    createScaffoldMiddleware({
      // staticSubprovider
      eth_syncing: false,
      web3_clientVersion: `Torus/v${version}`
    }),
    // @ts-ignore
    createWalletMiddleware({
      // @ts-ignore
      getAccounts,
      processTransaction,
      // @ts-ignore
      processEthSignMessage,
      // @ts-ignore
      processTypedMessage,
      // @ts-ignore
      processTypedMessageV3,
      // @ts-ignore
      processTypedMessageV4,
      // @ts-ignore
      processPersonalMessage,
      // @ts-ignore
      processEncryptionPublicKey,
      // @ts-ignore
      processDecryptMessage
    }),
    createRequestAccountsMiddleware({ getAccounts }),
    createPendingNonceMiddleware({ getPendingNonce }),
    createPendingTxMiddleware({ getPendingTransactionByHash })
  ]);
  return metamaskMiddleware;
}

export function createPendingNonceMiddleware({ getPendingNonce }: { getPendingNonce: (addr: string) => Promise<any> }) {
  return createAsyncMiddleware(async (request: any, response, next) => {
    if (request.method !== "eth_getTransactionCount") return next();
    const address = request.params[0];
    const blockReference = request.params[1];
    if (blockReference !== "pending") return next();
    response.result = await getPendingNonce(address);
    return undefined;
  });
}

export function createPendingTxMiddleware({ getPendingTransactionByHash }: { getPendingTransactionByHash: (hash: string) => any }) {
  return createAsyncMiddleware(async (request, response, next) => {
    const { method, params } = request;
    if (method !== "eth_getTransactionByHash") return next();

    const [hash] = params as any;
    const txMeta = getPendingTransactionByHash(hash);
    if (!txMeta) {
      return next();
    }
    response.result = formatTxMetaForRpcResult(txMeta);
    return undefined;
  });
}

export function createRequestAccountsMiddleware({
  getAccounts
}: {
  getAccounts: (
    req: JsonRpcRequest<unknown>,
    options?:
      | {
          suppressUnauthorized?: boolean | undefined;
        }
      | undefined
  ) => Promise<string[]>;
}) {
  return createAsyncMiddleware(async (request, response, next) => {
    const { method } = request as any;
    if (method !== "eth_requestAccounts") return next();

    if (!getAccounts) throw new Error("WalletMiddleware - opts.getAccounts not provided");
    const accounts = await getAccounts(request as any);
    response.result = accounts;
    return undefined;
  });
}

export function formatTxMetaForRpcResult(txMeta: any) {
  const { r, s, v, hash, txReceipt, txParams } = txMeta;
  const { to, data, nonce, gas, from, value, gasPrice, accessList, maxFeePerGas, maxPriorityFeePerGas } = txParams;

  const formattedTxMeta = {
    v,
    r,
    s,
    to,
    gas,
    from,
    hash,
    nonce,
    input: data || "0x",
    value: value || "0x0",
    accessList: accessList || null,
    blockHash: txReceipt?.blockHash || null,
    blockNumber: txReceipt?.blockNumber || null,
    transactionIndex: txReceipt?.transactionIndex || null
  } as any;

  if (maxFeePerGas && maxPriorityFeePerGas) {
    formattedTxMeta.maxFeePerGas = maxFeePerGas;
    formattedTxMeta.maxPriorityFeePerGas = maxPriorityFeePerGas;
    formattedTxMeta.type = TRANSACTION_ENVELOPE_TYPES.FEE_MARKET;
  } else {
    formattedTxMeta.gasPrice = gasPrice;
    formattedTxMeta.type = TRANSACTION_ENVELOPE_TYPES.LEGACY;
  }

  return formattedTxMeta;
}
