import * as CryptoJS from "crypto-js";

const dummyIv = process.env.REACT_APP_SECRET_IV as string;

export const crypt = (salt: string = process.env.REACT_APP_CLIENT_ID as string, text: string) => {
  const textToChars = (text: string) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n: number) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code: number) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar as any)
    .map(byteHex as any)
    .join("");
};

export const decrypt = (salt: any = process.env.REACT_APP_CLIENT_ID, encoded: any) => {
  const textToChars = (text: any) => text.split("").map((c: any) => c.charCodeAt(0));
  const applySaltToChar = (code: any) => textToChars(salt).reduce((a: any, b: any) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex: any) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode: any) => String.fromCharCode(charCode))
    .join("");
};

export const encryptUserInfo = (strObj: string, key: string = process.env.REACT_APP_CLIENT_ID as string) => {
  const _iv = CryptoJS.enc.Base64.parse(dummyIv);
  const encryptedData = CryptoJS.AES.encrypt(strObj, key, {
    iv: _iv
  });

  return encryptedData.toString();
};

export const decryptUserInfo = (strData: string, key: string = process.env.REACT_APP_CLIENT_ID as string) => {
  const _iv = CryptoJS.enc.Base64.parse(dummyIv);
  const decryptedData = CryptoJS.AES.decrypt(strData, key, {
    iv: _iv
  });
  return decryptedData.toString(CryptoJS.enc.Utf8);
};
