// @ts-nocheck
import { IonButton, IonCheckbox, IonContent, IonDatetime, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage } from "@ionic/react";
import HeaderMainMb from "components/header/mb";
import { useTranslation } from "react-i18next";

const PersonalInformationEditMainMb: React.FC<any> = ({ openDate, setOpenDate, birthday, setBirthday, name, email, setData }) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <HeaderMainMb title={t("settings.managePermission_title")} link="/settings/init" />

      <IonContent className="ion-padding gray">
        <p> {t("consentsForm.editDataPermissions")}</p>
        <IonList className="permission-form-list gray">
          <IonItem className="personal-wrapper font-normal text-base leading-[19px] text-[#5d5d5d]">
            <div className="format-label">
              <div className="format-label-start">
                <IonLabel id="title" position="stacked">
                  {t("settings.name")}
                </IonLabel>
                <div className="required-field">{t("consentsForm.required")}</div>
              </div>
              <IonCheckbox slot="end" disabled checked></IonCheckbox>
            </div>
            <IonInput placeholder="Enter name" value={name} className="permission" disabled></IonInput>
          </IonItem>
          <IonItem className="personal-wrapper font-normal text-base leading-[19px] text-[#5d5d5d] ion-item-split">
            <div className="format-label">
              <div className="format-label-start">
                <IonLabel id="title" position="stacked">
                  {t("consentsForm.birthday")}
                </IonLabel>
              </div>
              <IonCheckbox slot="end" checked></IonCheckbox>
            </div>
            <IonInput
              placeholder={t("consentsForm.enter") + t("consentsForm.birthday")}
              onClick={() => setOpenDate(!openDate)}
              className="permission"
              // disabled
              value={new Date(birthday).toString() === "Invalid Date" ? "" : new Date(birthday).toLocaleDateString()}
            />
            <IonModal className="modal-transfer-done" isOpen={openDate} initialBreakpoint={0.6} breakpoints={[0, 0.6, 0.75]}>
              <IonContent className="ion-padding">
                <IonDatetime
                  value={birthday}
                  prefer-wheel="true"
                  onIonChange={(e) => {
                    if (typeof e.detail.value === "string") setBirthday(e.detail.value);
                  }}
                  presentation="date"
                />
                <IonButton
                  shape="round"
                  color="primary"
                  expand="full"
                  onClick={() => {
                    setOpenDate(!openDate);
                  }}>
                  {t("transfer.confirmTransfer")}
                </IonButton>
              </IonContent>
            </IonModal>
          </IonItem>

          <IonItem className="personal-wrapper font-normal text-base leading-[19px] text-[#5d5d5d]">
            <div className="format-label">
              <div className="format-label-start">
                <IonLabel id="title" position="stacked">
                  {t("settings.email")}
                </IonLabel>
                <div className="required-field">{t("consentsForm.required")}</div>
              </div>
              <IonCheckbox slot="end" checked disabled></IonCheckbox>
            </div>
            <IonInput placeholder="Enter email" value={email} type="email" className="permission" disabled></IonInput>
          </IonItem>
        </IonList>
        <div className="button-containers">
          <IonButton onClick={() => setData()} shape="round" color="primary" expand="block">
            {t("settings.save")}
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PersonalInformationEditMainMb;
