// @ts-nocheck
/* eslint-disable jsx-a11y/media-has-caption */
import { IonModal, IonContent, IonThumbnail, IonCardTitle, IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { HomeListPopupProps } from "features/home/types";

const HomeListPopupPc = (props: HomeListPopupProps) => {
  const { t } = useTranslation();

  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={() => props.setOpen(false)} trigger="open-modal" className="modal-nft">
      <IonContent className="ion-padding">
        {/* <div
              style={{
                height: 350,
                background: `url(${props.nftData?.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center center"
              }}
            /> */}
        <IonThumbnail className="h-[343px] w-full flex justify-center">
          {props.nftData?.animation_url ? (
            <video className="h-[343px] w-full" autoPlay controls>
              <source src={props.nftData?.animation_url} />
            </video>
          ) : (
            props.nftData?.image && (
              <img
                className="object-contain max-w-fit rounded-2xl h-[343px]"
                alt={props.nftData?.name}
                src={props.nftData?.image && props.nftData.image ? props.nftData.image : window.location.origin + "/assets/noimg.png"}
              />
            )
          )}
        </IonThumbnail>
        {/* <img alt={props.nftData?.name} src={props.nftData?.image} className="nft-img" /> */}
        <IonCardTitle className="text-base text-gray-text text-center mt-5 mx-8">
          {props.nftData?.name && props.nftData?.name.length > 24 ? props.nftData?.name.substring(0, 30) + "..." : props.nftData?.name}
        </IonCardTitle>
        <IonCardTitle className="text-sm font-bold text-gray-text text-center mt-5 mx-8">
          {props.nftData?.collectionName ?? t("home.undefinedCollection")}
        </IonCardTitle>
        {props.nftType !== "erc1155" && (
          <IonButton shape="round" color="primary" expand="full" href={props.nftData?.link} target="_blank">
            {t("account.seeDetails")}
          </IonButton>
        )}
      </IonContent>
    </IonModal>
  );
};

export default HomeListPopupPc;
