// @ts-nocheck
/* eslint-disable jsx-a11y/media-has-caption */
import {
  IonAccordion,
  IonAccordionGroup,
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonThumbnail
} from "@ionic/react";
import { reloadSrc } from "helpers/httpHelpers";
import { HomeListTokenContentProps } from "features/home/types";
import { useTranslation } from "react-i18next";
import { numberParse } from "shared/utils/etherUtils";
import { IToken } from "interfaces/actions/IToken";
import { chevronForwardOutline, chevronUp } from "ionicons/icons";
import useLoading from "hooks/useLoading";
import VideoIcon from "assets/icons/Video.svg";

const HomeListPc: React.FC<any> = ({
  etherInitBalance,
  onTokenClick,
  maticInitBalance,
  balances,
  onCoinClick,
  page,
  balance,
  tokenBalances,
  index,
  length,
  collectibles,
  onNFTClick,
  setBalanceOpen,
  balanceOpen,
  openNFT
}: HomeListTokenContentProps) => {
  const { t } = useTranslation();
  const { SkeletonLoading } = useLoading();

  const checkLoadingState = () => {
    if (index || length) {
      if (index === length - 1) {
        return (
          <div className="mt-12 text-center">
            <p>{t("home.noNftData")}</p>
          </div>
        );
      } else {
        return (
          <div className="mt-4 w-full justify-center">
            <SkeletonLoading type="group" rows={4} />
          </div>
        );
      }
    } else {
      return (
        <div className="mt-12 text-center">
          <p>{t("home.noNftData")}</p>
        </div>
      );
    }
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol sizeLg="6" sizeXl="6" sizeMd="4" sizeSm="4" sizeXs="12" className="ion-no-padding">
          <IonCard className="max-h-[60vh] overflow-y-auto">
            <IonCardContent>
              <IonLabel className="text-black font-bold ">{t("home.token")}</IonLabel>
              <IonList lines="full" className="m-0 ion-padding-top">
                <>
                  {/* Start Ether INIT */}
                  {etherInitBalance && etherInitBalance.balance !== "0" && etherInitBalance.balance !== "" && (
                    <>
                      <IonItem
                        className="p-0"
                        onClick={() => {
                          onTokenClick(etherInitBalance);
                        }}>
                        <IonAvatar slot="start">
                          <IonImg
                            className="border border-black"
                            alt={etherInitBalance.contract_address}
                            src={etherInitBalance.logo_url}
                            onIonError={(e) => reloadSrc(e, etherInitBalance.chain_id)}
                          />
                          <IonImg
                            className="absolute w-[18px] h-[18px] bottom-[12px] !left-0 border border-black"
                            alt={etherInitBalance.contract_address}
                            src={etherInitBalance.logo_url}
                          />
                        </IonAvatar>
                        <IonLabel className="font-bold">{etherInitBalance.contract_name}</IonLabel>
                        <IonLabel slot="end" className="font-bold">
                          {numberParse(etherInitBalance.balance, etherInitBalance.contract_decimals)}
                          {"  "}
                          {etherInitBalance.contract_ticker_symbol}
                        </IonLabel>
                      </IonItem>
                    </>
                  )}

                  {/* End Ether INIT */}

                  {/* Start Matic INIT */}
                  {maticInitBalance && maticInitBalance.balance !== "0" && maticInitBalance.balance !== "" && (
                    <>
                      <IonItem
                        key={maticInitBalance.contract_address}
                        className="p-0"
                        onClick={() => {
                          onTokenClick(maticInitBalance);
                        }}>
                        <IonAvatar slot="start">
                          <IonImg
                            className="border border-black"
                            alt={maticInitBalance.contract_address}
                            src={maticInitBalance.logo_url}
                            onIonError={(e) => reloadSrc(e, maticInitBalance.chain_id)}
                          />
                          <IonImg
                            className="absolute w-[18px] h-[18px] bottom-[12px] !left-0 border border-black"
                            alt={maticInitBalance.contract_address}
                            src={maticInitBalance.logo_url}
                          />
                        </IonAvatar>
                        <IonLabel className="font-bold">{maticInitBalance.contract_name}</IonLabel>
                        <IonLabel slot="end" className="font-bold">
                          {numberParse(maticInitBalance.balance, maticInitBalance.contract_decimals)}
                          {"  "}
                          {maticInitBalance.contract_ticker_symbol}
                        </IonLabel>
                      </IonItem>
                    </>
                  )}
                  {/* End Matic INIT */}

                  {/* Start Erc20 INIT */}
                  {balances.map((el: IToken, i) =>
                    page === "transfer" ? (
                      parseFloat(el.balance) > 0 ? (
                        (el.testnet && !balance.showTestnet) || i > index ? null : (
                          <IonItem
                            key={"breakpoint_balance" + el.contract_address + i}
                            className="p-0"
                            onClick={() => {
                              onCoinClick(el);
                            }}>
                            <IonAvatar slot="start">
                              <IonImg className="border border-black" alt={el.contract_address} src={el.logo_url} />
                              <IonImg
                                className="absolute w-[18px] h-[18px] bottom-[12px] !left-0 border border-black"
                                alt={el.contract_address}
                                src={el.logo_url}
                              />
                            </IonAvatar>
                            <IonLabel className="font-bold">{el.contract_name}</IonLabel>
                            <IonLabel slot="end" className="font-bold">
                              {numberParse(el.balance, el.contract_decimals)}
                              {"  "}
                              {el.contract_ticker_symbol}
                            </IonLabel>
                          </IonItem>
                        )
                      ) : null
                    ) : (el.testnet && !balance.showTestnet) || i > index ? null : (
                      <IonItem
                        key={"breakpoint_balance" + el.contract_address + i}
                        className="p-0"
                        onClick={() => {
                          onCoinClick(el);
                        }}>
                        <IonAvatar slot="start">
                          <IonImg className="border border-black" alt={el.contract_address} src={el.logo_url} />
                          <IonImg
                            className="absolute w-[18px] h-[18px] bottom-[12px] !left-0 border border-black"
                            alt={el.contract_address}
                            src={el.logo_url}
                          />
                        </IonAvatar>
                        <IonLabel className="font-bold">{el.contract_name}</IonLabel>
                        <IonLabel slot="end" className="font-bold">
                          {numberParse(el.balance, el.contract_decimals)}
                          {"  "}
                          {el.contract_ticker_symbol}
                        </IonLabel>
                      </IonItem>
                    )
                  )}
                  {/* End Erc20 INIT */}

                  {/* Start Nft Init */}
                  {balanceOpen &&
                    tokenBalances.map((el, i) =>
                      page === "transfer" ? (
                        parseFloat(el.balance) > 0 ? (
                          el.testnet && !balance.showTestnet ? null : (
                            <IonItem
                              key={"breakpoint_token" + el.contract_address + i}
                              className="p-0"
                              onClick={() => {
                                onTokenClick(el);
                              }}>
                              <IonAvatar slot="start">
                                <IonImg
                                  className="border border-black"
                                  alt={el.contract_address}
                                  src={`https://tokens.1inch.io/${el.contract_address}.png`}
                                  onIonError={(e) => reloadSrc(e, el.chain_id)}
                                />
                                <IonImg
                                  className="absolute w-[18px] h-[18px] bottom-[12px] !left-0 border border-black"
                                  alt={el.contract_address}
                                  src={el.logo_url}
                                />
                              </IonAvatar>
                              <IonLabel className="font-bold">{el.contract_name}</IonLabel>
                              <IonLabel slot="end" className="font-bold">
                                {numberParse(el.balance, el.contract_decimals)}
                                {"  "}
                                {el.contract_ticker_symbol}
                              </IonLabel>
                            </IonItem>
                          )
                        ) : null
                      ) : el.testnet && !balance.showTestnet ? null : (
                        <IonItem
                          key={"breakpoint_token" + el.contract_address + i}
                          className="p-0"
                          onClick={() => {
                            onTokenClick(el);
                          }}>
                          <IonAvatar slot="start">
                            <img
                              onError={(e) => reloadSrc(e, el.chain_id)}
                              src={`https://tokens.1inch.io/${el.contract_address}.png`}
                              alt={el.contract_address}
                              className="border border-black"
                            />
                            <IonImg
                              className="absolute w-[18px] h-[18px] bottom-[12px] !left-0 border border-black"
                              alt={el.contract_address}
                              src={el.logo_url}
                            />
                          </IonAvatar>
                          <IonLabel className="font-bold">{el.contract_name}</IonLabel>
                          <IonLabel slot="end" className="font-bold">
                            {numberParse(el.balance, el.contract_decimals)}
                            {"  "}
                            {el.contract_ticker_symbol}
                          </IonLabel>
                        </IonItem>
                      )
                    )}
                  {/* End Nft Init */}
                </>
              </IonList>

              {index < length - 1 ? (
                <div className="mt-4 w-full justify-center">
                  <SkeletonLoading type="group" rows={1} />
                </div>
              ) : (
                <></>
              )}

              {tokenBalances.length > 0 ? (
                <IonButton
                  shape="round"
                  className="all-home ion-margin-top"
                  onClick={() => {
                    balanceOpen ? setBalanceOpen(false) : setBalanceOpen(true);
                  }}>
                  {balanceOpen ? (
                    <>
                      <IonIcon slot="end" icon={chevronUp} color="primary" />
                      {t("home.less")}
                    </>
                  ) : (
                    <>
                      <IonIcon slot="end" icon={chevronForwardOutline} color="primary" />
                      {t("home.all")}
                    </>
                  )}
                </IonButton>
              ) : (
                <br></br>
              )}
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol sizeLg="6" sizeXl="6" sizeMd="4" sizeSm="4" sizeXs="12" className="ion-no-padding">
          <IonCard
            className="max-h-[60vh] overflow-y-auto"
            style={{ maxHeight: window.location.pathname === "/wallet/tf/assets" ? 395 : "" }}>
            <IonCardContent>
              <IonLabel className="text-black font-bold">{t("home.collectibles")}</IonLabel>
              <IonList>
                <IonAccordionGroup className="ion-padding-top uppercase font-bold">
                  {collectibles.length > 0
                    ? collectibles.map((item: IToken, i: number) =>
                        item.testnet && !balance.showTestnet
                          ? null
                          : item.nft_data && (
                              <IonAccordion key={"breakpoint_collectible" + i} value={item.contract_address + i} className="h-full pt-0">
                                <IonItem slot="header" color="light" className="rounded-none border-b-[1px] border-b-zinc-200" lines="none">
                                  <IonAvatar slot="start">
                                    {item.nft_data.map(
                                      (nft: any, i: number) =>
                                        i === 0 && (
                                          <img
                                            key={i}
                                            className="object-cover h-[36px] w-[36px]"
                                            alt={"NFT"}
                                            src={
                                              nft?.external_data?.thumbnail_url || nft?.external_data?.image
                                                ? nft?.external_data?.thumbnail_url || nft?.external_data?.image
                                                : window.location.origin + "/assets/noimg.png"
                                            }
                                            onError={(e) => {
                                              e.currentTarget.src = window.location.origin + "/assets/noimg.png";
                                            }}
                                          />
                                        )
                                    )}
                                  </IonAvatar>
                                  {item.contract_address === "0x939Cf6D4Dab42e9B64bD3E7251E4BBb212d8ba82".toLocaleLowerCase() ||
                                  item.contract_address === "0x3c9161C06e8EED26aCBEdF57f1233b7a29bD2685".toLocaleLowerCase() ? (
                                    <IonLabel className="font-bold">2023年新入社員証明NFT</IonLabel>
                                  ) : (
                                    <IonLabel className="font-bold">{item.contract_name ?? t("home.undefinedCollection")}</IonLabel>
                                  )}
                                  <IonLabel className="font-bold" slot="end">
                                    {item.nft_data.length}
                                  </IonLabel>
                                </IonItem>
                                <div slot="content" className="shadow-none">
                                  <IonList>
                                    <IonRow className="overflow-y-auto max-h-[500px]">
                                      {item.nft_data.map((nft: any) => (
                                        <IonCol
                                          size="6"
                                          key={"breakpoint_nft_data" + item.contract_address + nft.token_id}
                                          onClick={() => {
                                            page === "transfer" ? onNFTClick(item, nft) : openNFT(item, nft);
                                          }}
                                          className="flex justify-center h-max">
                                          <IonThumbnail className="w-full h-full rounded-2xl overflow-hidden">
                                            {nft?.external_data?.image ? (
                                              <div className="relative w-full">
                                                <img
                                                  className="w-full"
                                                  alt={
                                                    nft?.external_data?.image
                                                      ? nft?.external_data?.image
                                                      : window.location.origin + "/assets/noimg.png"
                                                  }
                                                  src={
                                                    nft?.external_data?.image
                                                      ? nft?.external_data?.image
                                                      : window.location.origin + "/assets/noimg.png"
                                                  }
                                                />
                                                {nft?.external_data?.animation_url && (
                                                  <img
                                                    className={`absolute left-0 ${
                                                      window.innerWidth < 1440 ? "top-[4vh]" : "top-[6vh]"
                                                    } z-10 opacity-100 w-full h-[6vh]`}
                                                    src={VideoIcon}
                                                    alt="video icon"
                                                  />
                                                )}
                                              </div>
                                            ) : (
                                              <video autoPlay muted>
                                                <source src={nft?.external_data?.animation_url} />
                                              </video>
                                            )}
                                            <IonLabel className="absolute lg:w-[96%] xl:w-[97%] h-full flex items-end text-white-background text-base p-3.5 bottom-[5px] z-50 bg-[linear-gradient(180deg,_#FFFFFF00_2%,_#00000036_100%)] rounded-b-2xl">
                                              {nft?.external_data?.name}
                                            </IonLabel>
                                          </IonThumbnail>
                                        </IonCol>
                                      ))}
                                    </IonRow>
                                  </IonList>
                                </div>
                              </IonAccordion>
                            )
                      )
                    : checkLoadingState()}
                </IonAccordionGroup>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default HomeListPc;
