import { getLiveRpc } from "live-evm-rpc";

export const getBestRpc = async (chainId: string | number, arrRpc: string[] = []) => {
  try {
    const rpcUrlsStr = localStorage.getItem("rpcUrls");
    let rpcUrlExist = false;
    let rpcUrl = null;
    if (rpcUrlsStr) {
      rpcUrlExist = true;
      rpcUrl = JSON.parse(rpcUrlsStr);
    }
    if (rpcUrl && rpcUrl[chainId]) {
      if (rpcUrl[chainId]?.expired > new Date().getTime()) {
        return rpcUrl[chainId].url;
      }
    }
    let res = await getLiveRpc(Number(chainId), { rpcs: arrRpc });
    res = res.filter((rpc) => {
      return rpc.chainId === Number(chainId);
    });
    let latestBlock = 0;
    if (!res.length) return arrRpc[0];
    res.forEach((rpc) => {
      if (Number(rpc.lastBlock) > latestBlock) latestBlock = Number(rpc.lastBlock);
    });
    res = res.filter((rpc) => {
      return Number(rpc.lastBlock) === latestBlock;
    });

    res = res.filter((rpc) => {
      if (rpc.ms !== "timeout") return rpc;
    });

    res.sort((a, b) => {
      return (a.ms as number) - (b.ms as number);
    });
    if (rpcUrlExist) {
      rpcUrl = { ...rpcUrl, [chainId]: { url: res[0].url, expired: new Date().getTime() + 3600000 } };
      localStorage.setItem("rpcUrls", JSON.stringify(rpcUrl));
    } else {
      rpcUrl = { [chainId]: { url: res[0].url, expired: new Date().getTime() + 3600000 } };
      localStorage.setItem("rpcUrls", JSON.stringify(rpcUrl));
    }

    return res[0].url;
  } catch (err) {
    console.log(err, "@err filter rpc");
  }
};
