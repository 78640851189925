// @ts-nocheck
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonRow
} from "@ionic/react";
import HeaderMainPc from "components/header/pc";
import { useTranslation } from "react-i18next";

const SettingMainPc: React.FC<any> = ({ handleLogout, presentToast }) => {
  const { t } = useTranslation();

  return (
    <IonPage className="ion-no-padding">
      <HeaderMainPc title={t("settings.title")} link="/wallet/account" />

      <IonContent className="bg-base ion-padding">
        {/* First Menu */}
        <IonGrid>
          <IonRow>
            <IonCol size="4">
              <IonCard class="ion-no-padding m-0">
                <IonCardContent className="p-0">
                  <IonList className="setting-list-desk" lines="none">
                    <IonItem routerLink={"/wallet/settings/backup"}>
                      <IonLabel> {t("settings.backup")}</IonLabel>
                    </IonItem>
                    <IonItemDivider className="h-[1px] min-h-[1px] mx-auto my-0 w-[90%]"></IonItemDivider>
                    <IonItem routerLink={"/wallet/settings/currency"}>
                      <IonLabel> {t("settings.currency")}</IonLabel>
                    </IonItem>
                    <IonItemDivider className="h-[1px] min-h-[1px] mx-auto my-0 w-[90%]"></IonItemDivider>
                    <IonItem routerLink={"/wallet/settings/network"}>
                      <IonLabel> {t("settings.justNetwork")}</IonLabel>
                    </IonItem>
                    <IonItemDivider className="h-[1px] min-h-[1px] mx-auto my-0 w-[90%]"></IonItemDivider>
                    <IonItem routerLink={"/wallet/settings/language"}>
                      <IonLabel> {t("settings.language")}</IonLabel>
                    </IonItem>
                    <IonItemDivider className="h-[1px] min-h-[1px] mx-auto my-0 w-[90%]"></IonItemDivider>
                    <IonItem routerLink={"/wallet/settings/personal-information"}>
                      <IonLabel> {t("settings.personal")}</IonLabel>
                    </IonItem>
                    {/* <IonItemDivider className="h-[1px] min-h-[1px] mx-auto my-0 w-[90%]"></IonItemDivider>
                    <IonItem routerLink={"/wallet/settings/application"}>
                      <IonLabel> {t("settings.application")}</IonLabel>
                    </IonItem> */}
                    <IonItemDivider className="h-[1px] min-h-[1px] mx-auto my-0 w-[90%]"></IonItemDivider>
                    <IonItem onClick={handleLogout} className="cursor-pointer">
                      <IonLabel> {t("settings.logout")}</IonLabel>
                    </IonItem>
                    <IonItemDivider className="h-[1px] min-h-[1px] mx-auto my-0 w-[90%]"></IonItemDivider>
                    <IonItem onClick={() => presentToast("middle")} className="cursor-pointer">
                      <IonLabel> {t("settings.faq")}</IonLabel>
                    </IonItem>
                    <IonItemDivider className="h-[1px] min-h-[1px] mx-auto my-0 w-[90%]"></IonItemDivider>
                    <IonItem onClick={() => window.open("https://www.upbond.io/inquiry-wallet")} className="inquiry menus cursor-pointer">
                      <IonLabel> {t("settings.inquiry")}</IonLabel>
                    </IonItem>

                    {/* Permission Menu */}
                    {/* <IonItem routerLink="/wallet/settings/permission-manage" className="inquiry menus">
                      <IonLabel>{t("settings.managePermission")}</IonLabel>
                    </IonItem> */}
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow className="mt-4">
            <IonCol size="6">
              <IonLabel className="terms">
                <span
                  className="font-bold underline px-[5px] cursor-pointer"
                  onClick={() => window.open("https://upbond.notion.site/UPBOND-Wallet-Terms-of-Service-1b6d7c8f72a94f799c8ab1b82df6c799")}>
                  {t("login.term")}
                </span>
                {t("login.and")}
                <span
                  className="font-bold underline px-[5px] cursor-pointer"
                  onClick={() => window.open("https://upbond.notion.site/UPBOND-Wallet-29b06c6e77d24b6798bf50a6a73fd67b")}>
                  {t("settings.privacy")}
                </span>
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SettingMainPc;
