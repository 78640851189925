/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";

function DesktopInput({ setDisabled, setValue }: any) {
  const [code1, setcode1] = useState<any>("");
  const [code2, setcode2] = useState<any>("");
  const [code3, setcode3] = useState<any>("");
  const [code4, setcode4] = useState<any>("");

  const pin1: any = document.querySelector("#pin-1");
  const pin2: any = document.querySelector("#pin-2");
  const pin3: any = document.querySelector("#pin-3");
  const pin4: any = document.querySelector("#pin-4");

  const arrPin: any = [
    { id: "pin-1", value: code1 },
    { id: "pin-2", value: code2 },
    { id: "pin-3", value: code3 },
    { id: "pin-4", value: code4 }
  ];

  useEffect(() => {
    // const utf_numbers: any = [/\uff10/g, /\uff11/g, /\uff12/g, /\uff13/g, /\uff14/g, /\uff15/g, /\uff16/g, /\uff17/g, /\uff18/g, /\uff19/g];
    const common_numbers: any = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const jp_numbers: any = ["０", "１", "２", "３", "４", "５", "６", "７", "８", "９"];
    const codes = [code1, code2, code3, code4];

    if (codes.includes("")) {
      setDisabled(true);
    } else {
      const fin: any = [];
      codes.forEach((cd: any) => {
        if (common_numbers.includes(cd)) fin.push(cd);
        if (jp_numbers.includes(cd)) fin.push(jp_numbers.findIndex((el: any) => el === cd));
      });

      if (fin.length === 4) {
        setValue(fin.join(""));
        setDisabled(false);
      }
    }
  }, [code1, code2, code3, code4]);

  const handleChange = (e: any) => {
    const en_number = /^[0-9\b]+$/.test(e.target.value);
    const jp_number = /[\uff10-\uff19]+$/.test(e.target.value);
    if (e.target.value.length < 2) {
      if (en_number || jp_number) {
        switch (e.target.id) {
          case "pin-1":
            setcode1(e.target.value);
            break;
          case "pin-2":
            setcode2(e.target.value);
            break;
          case "pin-3":
            setcode3(e.target.value);
            break;
          case "pin-4":
            setcode4(e.target.value);
            break;
        }
      }
    }

    if (e.target.value === "") {
      switch (e.target.id) {
        case "pin-1":
          setcode1(e.target.value);
          break;
        case "pin-2":
          setcode2(e.target.value);
          break;
        case "pin-3":
          setcode3(e.target.value);
          break;
        case "pin-4":
          setcode4(e.target.value);
          break;
      }
    }
  };

  const __keydown = (e: any) => {
    if (!e.target.value && e.code === "Backspace") {
      if (e.target.id === "pin-2") pin1.focus();
      if (e.target.id === "pin-3") pin2.focus();
      if (e.target.id === "pin-4") pin3.focus();
    }
  };

  const __keyup = () => {
    if (pin1 === document.activeElement) {
      pin1.value && pin2.focus();
      setTimeout(() => setcode2(code2), 3);
    }
    if (pin2 === document.activeElement) {
      pin2.value && pin3.focus();
      setTimeout(() => setcode3(code3), 3);
    }
    if (pin3 === document.activeElement) {
      pin3.value && pin4.focus();
      setTimeout(() => setcode4(code4), 3);
    }
  };

  return (
    <div className="my-4 flex justify-around">
      {arrPin.map((inp: any, index: any) => {
        return (
          <input
            key={inp.id}
            id={inp.id}
            type="text"
            inputMode="search"
            maxLength={1}
            value={inp.value}
            onChange={handleChange}
            onKeyDown={__keydown}
            onKeyUp={__keyup}
            className={`w-[90px] h-[80px] text-[32px] text-center p-3 border border-[#d7d7d7] outline-none rounded-lg focus:border-[#4d4082] focus:border-2  otp-field-box--${index}`}
          />
        );
      })}
    </div>
  );
}

export default DesktopInput;
