import { BroadcastChannel } from "@toruslabs/broadcast-channel";
import { UserInfo } from "@web3auth/base";
import { store } from "shared/store";
import { POPUP_CLOSE, POPUP_LOADED, POPUP_RESULT } from "shared/enums";
import { broadcastChannelOptions, padPrivateKey, UserError } from "shared/utils/coreUtils";

import PopupHandler from "./PopupHandler";

export type BcEventResult = {
  data: {
    type: string;
    userInfo: UserInfo;
    keys: { privKey: string }[];
    postboxKey: string;
    error: string;
  };
  error: any;
};

class PopupWithBcHandler extends PopupHandler {
  bc: BroadcastChannel;
  usingIframe: boolean;
  reduxStore: typeof store;
  topCalc: number;
  constructor({
    url,
    target,
    features,
    preopenInstanceId,
    channelName,
    usingIframe = true,
    topCalc = 14
  }: {
    url: string | URL;
    target?: string | undefined;
    features?: string;
    preopenInstanceId?: string;
    channelName: string;
    usingIframe?: boolean;
    topCalc?: number;
  }) {
    super({ url, target, features: features as string, preopenInstanceId, topCalc });
    this.bc = new BroadcastChannel(channelName, broadcastChannelOptions);
    this.usingIframe = usingIframe;
    this.reduxStore = store;
    this.topCalc = topCalc;
  }

  handle(successExtraFn: any): Promise<{
    type: string;
    userInfo: UserInfo;
    keys: { privKey: string }[];
    postboxKey: string;
    error: string;
  }> {
    return new Promise((resolve, reject) => {
      this.once("close", async () => {
        const urlInstance = new URLSearchParams(window.location.search).get("instanceId");
        if (urlInstance && urlInstance !== "") {
          const bc = new BroadcastChannel(`torus_logout_channel_${urlInstance}`, broadcastChannelOptions);
          await bc.postMessage({ data: { type: "logout" } });
          bc.close();
        }

        reject(new UserError("user closed popup"));
      });
      this.bc.addEventListener("message", async (ev: BcEventResult) => {
        try {
          const { error, data } = ev;
          if (error) {
            reject(new Error(error));
            return;
          }

          if (ev.data.type === "popup_result" && ev.data.keys.length === 0) {
            reject(new Error("not existing keys"));
            return;
          }

          data.keys[0].privKey = padPrivateKey(data.keys[0].privKey);
          // if (data.keys[0].privKey.length < 64) data.keys[0].privKey = `0${data.keys[0].privKey}`;
          // if (data.keys[0].privKey.length > 64) data.keys[0].privKey = data.keys[0].privKey.slice(1);

          if (successExtraFn) await successExtraFn.call(this, data);

          resolve(data);
        } catch (error) {
          reject(error);
        } finally {
          this.bc.close();
          this.close();
        }
      });
      return this.open();
    });
  }

  handleWithHandshake({ payload, successExtraFn }: { payload?: any; successExtraFn?: (data: any) => void }) {
    return new Promise((resolve, reject) => {
      this.once("close", () => {
        this.bc.close();
        reject(new UserError("user closed popup"));
      });
      this.bc.addEventListener("message", async (ev: any) => {
        try {
          const { error, data } = ev;
          if (error) {
            reject(new Error(error));
            return;
          }
          const { type = "" } = data || {};
          if (type === POPUP_LOADED) {
            await this.bc.postMessage({
              data: payload
            });
          } else if (type === POPUP_RESULT) {
            if (successExtraFn) await successExtraFn.call(this, data);
            resolve(data);
            this.bc.close();
            this.close();
          } else if (type === POPUP_CLOSE) {
            this.close();
            await this.bc.close();
          }
        } catch (error) {
          reject(error);
          this.bc.close();
          this.close();
        }
      });
      return this.open(this.usingIframe);
    });
  }
}
export default PopupWithBcHandler;
