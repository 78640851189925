import { configureStore } from "@reduxjs/toolkit";
// import { isPwa } from "shared/utils/coreUtils";
import balanceSlice from "./balanceSlice";
import contactSlice from "./contactSlice";
import { embedSlice, embedUiSlice } from "./embedSlice";
import gasSlice from "./gasSlice";
import historyTransactionSlice from "./historyTrasanctionSlice";
import transferSlice from "./transferSlice";
import walletConnectSlice from "./walletConnectSlice";
import walletSlice from "./walletSlice";
import walletConnectArrSlice from "./walletConnectArrSlice";
import scannerSlice from "./scannerSlice";
import permissionSlice from "./permissionSlice";
import historyChainSlice from "./historyChain.slice";
import olDappModuleSlice from "./olDappModuleSlice";
import olDeviceModuleSlice from "./olDeviceModuleSlice";
import olLoginPerfModuleSlice from "./olLoginPerfModuleSlice";
import olPidModuleSlice from "./olPidModuleSlice";
import olTKeyModuleSlice from "./olTKeyModuleSlice";
import olUserModuleSlice from "./olUserModuleSlice";
import olAllDappModuleSlice from "./olAllDappModuleSlice";

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const whitelistState = [
  "walletConnectArrData",
  "wallet",
  "walletConnect",
  "gas",
  "embedState",
  "embedUiState",
  "balance",
  "olPidModule",
  "olUserModule",
  "olDappModule",
  "olDeviceModule",
  "olLoginPerfModule",
  "olTKeyModule",
  "olAllDappModule"
];

const persistedMiddleware: any = ({ getState }: { getState: () => typeof store.getState }) => {
  return (next: any) => (actions: any) => {
    const result = next(actions);
    const obj = {};
    for (const i in whitelistState) {
      if ((getState() as unknown as { [x: string]: [y: any] })[whitelistState[i]]) {
        (obj as any)[whitelistState[i]] = (getState() as any)[whitelistState[i]];
      }
    }
    localStorage.setItem(`upbond-app`, JSON.stringify(obj));
    return result;
  };
};

const reHydrateStore = () => {
  if (localStorage.getItem("upbond-app") !== null) {
    return JSON.parse(localStorage.getItem("upbond-app") ?? "{}"); // re-hydrate the store
  } else if (sessionStorage.getItem(`upbond-app`)) {
    return JSON.parse(sessionStorage.getItem(`upbond-app`) ?? "{}");
  }
  return {};
};

export const store = configureStore({
  reducer: {
    balance: balanceSlice.reducer,
    contact: contactSlice.reducer,
    transfer: transferSlice.reducer,
    history: historyTransactionSlice.reducer,
    gas: gasSlice.reducer,
    embedState: embedSlice.reducer,
    embedUiState: embedUiSlice.reducer,
    walletConnect: walletConnectSlice.reducer,
    wallet: walletSlice.reducer,
    walletConnectArrData: walletConnectArrSlice.reducer,
    scanner: scannerSlice.reducer,
    permissions: permissionSlice.reducer,
    historyChain: historyChainSlice.reducer,
    olDeviceModule: olDeviceModuleSlice.reducer,
    olDappModule: olDappModuleSlice.reducer,
    olLoginPerfModule: olLoginPerfModuleSlice.reducer,
    olPidModule: olPidModuleSlice.reducer,
    olTKeyModule: olTKeyModuleSlice.reducer,
    olUserModule: olUserModuleSlice.reducer,
    olAllDappModule: olAllDappModuleSlice.reducer
  },
  preloadedState: reHydrateStore(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(persistedMiddleware)
});
