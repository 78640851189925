import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initTransfer } from "shared/config/gas";
import { GasPriceWithEstimate, GetTxGasParamsResState } from "interfaces/actions/IGasPrice";
import { IToken, TransactionTemp } from "interfaces/actions/IToken";

const transferSlice = createSlice({
  name: "transfer",
  initialState: initTransfer,
  reducers: {
    setAddress(state, action: PayloadAction<TransactionTemp>) {
      state.toAddress = action.payload.toAddress;
    },
    setToken(state, action: PayloadAction<IToken>) {
      state.detail = action.payload;
    },
    setGas(state, action: PayloadAction<GasPriceWithEstimate>) {
      state.gas = action.payload;
    },
    setAmount(state, action: PayloadAction<TransactionTemp>) {
      state.amount = action.payload.amount;
    },
    setGasEIP1559(state, action: PayloadAction<GetTxGasParamsResState>) {
      state.gasEIP = action.payload;
    },
    setNft(state, action: PayloadAction<any>) {
      state.selectedNft = action.payload;
    },
    setTransferErrorMsg(state, action: PayloadAction<any>) {
      state.errMsg = action.payload;
    }
  }
});

export default transferSlice;
