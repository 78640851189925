/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ComposedStore, ObservableStore } from "@metamask/obs-store";
import { SafeEventEmitterProvider } from "@web3auth/base";
import EventEmitter from "events";
import { PollingBlockTracker } from "libs/PollingBlockTracker";
import { INFURA_PROVIDER_TYPES, LOCALHOST, RPC, SUPPORTED_NETWORK_TYPES } from "shared/enums";
import { createJsonRpcClient } from "providers/JsonRpcProvider";
import Web3 from "web3";
import log from "loglevel";
import { ethers } from "ethers";
import assert from "assert";
// @ts-ignore
import EthQuery from "eth-query";
import { JRPCEngine, JRPCMiddleware, mergeMiddleware } from "@toruslabs/openlogin-jrpc";
import createMetamaskMiddleware from "providers/MetamaskProvider";
import {
  createBlockRefRewriteMiddleware,
  createBlockTrackerInspectorMiddleware,
  createFetchMiddleware,
  providerFromEngine,
  providerFromMiddleware
} from "eth-json-rpc-middleware";
// @ts-ignore
import { createEventEmitterProxy, createSwappableProxy } from "swappable-obj-proxy";
import { createInfuraClient } from "providers/InfuraProvider";
import { store } from "shared/store";
import { getBestRpc } from "shared/utils/filterRpc";

export type ProviderConfig = {
  host?: any;
  networkName?: any;
  chainId?: any;
  blockExplorer?: any;
  ticker?: any;
  logo?: any;
  tickerName?: any;
  rpcUrl?: any;
  nickname?: string;
  type?: any;
  rpcPrefs?: any;
};

const defaultNetworkDetailsState = {
  EIPS: { 1559: undefined }
};
export default class NetworkController extends EventEmitter {
  providerStore: ObservableStore<ProviderConfig>;
  networkStore: ObservableStore<string>;
  networkDetails: ObservableStore<{ EIPS: { [x: number]: any } }>;
  store: ComposedStore<Record<string, Record<string, unknown>>>;
  web3Provider: Web3 | null;
  _provider: SafeEventEmitterProvider | null;
  _blockTracker: PollingBlockTracker | null;
  _providerProxy: SafeEventEmitterProvider | null;
  _blockTrackerProxy: PollingBlockTracker | null;
  _baseProviderParams: { [x: string]: any } | undefined;
  constructor(
    options = {} as {
      provider: ProviderConfig;
      networkDetails?: {
        EIPS: { [x: number]: any };
      };
    }
  ) {
    super();
    this.setMaxListeners(100);
    const providerConfig = options.provider;
    this.providerStore = new ObservableStore(providerConfig);
    this.networkStore = new ObservableStore("loading");
    this.networkDetails = new ObservableStore(
      options.networkDetails || {
        ...defaultNetworkDetailsState
      }
    );
    this.store = new ComposedStore({
      provider: this.providerStore,
      network: this.networkStore as any,
      networkDetails: this.networkDetails
    });

    this.on("networkDidChange", this.lookupNetwork);
    this._provider = null;
    this._blockTracker = null;
    // provider and block tracker proxies - because the network changes
    this._providerProxy = null;
    this._blockTrackerProxy = null;
    this.web3Provider = new Web3();
  }

  getNetworkIdentifier() {
    const { type, rpcUrl } = this.getProviderConfig();
    if (type === "rpc") return rpcUrl;
    return type;
  }

  getProviderConfig() {
    return this.providerStore.getState();
  }

  getNetworkState() {
    return this.networkStore.getState();
  }

  setNetworkState(network: string) {
    this.networkStore.putState(network);
  }

  initializeProvider(providerParameters: { [x: string]: any }) {
    this._baseProviderParams = providerParameters;
    const { chainId, host, nickname, rpcUrl, ticker, type } = this.getProviderConfig();
    let finalType = type || host;
    if (SUPPORTED_NETWORK_TYPES[finalType]) {
      finalType = RPC;
    }
    this._configureProvider({ chainId, host, nickname: nickname || "", rpcUrl, ticker, type: finalType || type });
    this.lookupNetwork();
    return this._providerProxy;
  }

  getProviderAndBlockTracker(): {
    provider: SafeEventEmitterProvider | null;
    blockTracker: PollingBlockTracker | null;
  } {
    const provider = this._provider;
    const blockTracker = this._blockTracker;

    return {
      provider,
      blockTracker
    };
  }

  async getLatestBlock(): Promise<ethers.providers.Block | null> {
    return new Promise((resolve, reject) => {
      const { provider } = this.getProviderAndBlockTracker();
      const ethQuery = new EthQuery(provider);
      const initialNetwork = this.getNetworkState();
      ethQuery.sendAsync({ method: "eth_getBlockByNumber", params: ["latest", false] }, (err: any, block: any) => {
        const currentNetwork = this.getNetworkState();
        if (currentNetwork !== initialNetwork) {
          log.info("network has been changed");
          return resolve({} as any);
        }
        if (err) {
          return reject(err);
        }
        return resolve(block);
      });
    });
  }

  async getEIP1559Compatibility() {
    const { EIPS } = this.networkDetails.getState();
    if (EIPS[1559] !== undefined) {
      return EIPS[1559];
    }
    const _latestBlock = await this.getLatestBlock();
    const supportsEIPS1159 = _latestBlock !== null && _latestBlock.baseFeePerGas !== undefined;
    this.setNetworkEIPSupport(1159, supportsEIPS1159 as boolean);
    return supportsEIPS1159;
  }

  clearNetworkDetails() {
    this.networkDetails.putState({ ...defaultNetworkDetailsState });
  }

  getCurrentChainId() {
    const { type, chainId: configChainId } = this.getProviderConfig();
    return SUPPORTED_NETWORK_TYPES[type]?.chainId || configChainId;
  }

  _switchNetwork(options: ProviderConfig) {
    this.setNetworkState("loading");
    this.clearNetworkDetails();
    this._configureProvider(options);
    this.emit("networkDidChange");
  }

  setProviderConfig(config: ProviderConfig) {
    // this.previousProviderStore.updateState(this.getProviderConfig())
    this.providerStore.updateState(config);
    this._switchNetwork(config);
  }

  async setProviderType(type: string, rpcUrl = "", ticker = "ETH", nickname = "") {
    assert.notStrictEqual(type, RPC, 'NetworkController - cannot call "setProviderType" with type \'rpc\'. use "setRpcTarget"');
    assert.ok(SUPPORTED_NETWORK_TYPES[type] !== undefined, `NetworkController - Unknown rpc type "${type}"`);
    const { chainId, ...rest } = SUPPORTED_NETWORK_TYPES[type];
    const bestRpc = await getBestRpc(chainId, [rpcUrl]);
    const providerConfig = { ...rest, type, rpcUrl: bestRpc, ticker, nickname, chainId };
    this.setProviderConfig(providerConfig);
  }

  lookupNetwork() {
    if (!this._provider) {
      console.warn("NetworkController - lookupNetwork aborted due to missing provider");
      return;
    }
    const chainId = this.getCurrentChainId();
    if (!chainId) {
      console.warn("NetworkController - lookupNetwork aborted due to missing chainId");
      this.setNetworkState("loading");
      // keep network details in sync with network state
      this.clearNetworkDetails();
      return;
    }
    const ethQuery = new EthQuery(this._provider);
    const initialNetwork = this.getNetworkState();

    ethQuery.sendAsync({ method: "net_version" }, (error: unknown, network: string) => {
      const currentNetwork = this.getNetworkState();
      if (initialNetwork === currentNetwork) {
        if (error) {
          this.setNetworkState("loading");
          // keep network details in sync with network state
          this.clearNetworkDetails();
          return;
        }
        this.setNetworkState(network);
        // look up EIP-1559 support
        this.getEIP1559Compatibility();
      }
    });
  }

  verifyNetwork() {
    if (this.isNetworkLoading()) this.lookupNetwork();
  }

  isNetworkLoading() {
    return this.getNetworkState() === "loading";
  }

  setNetworkEIPSupport(EIPNumber: number, isSupported: boolean) {
    this.networkDetails.updateState({
      EIPS: {
        [EIPNumber]: isSupported
      }
    });
  }

  _configureProvider(opts: ProviderConfig) {
    const { chainId, nickname, rpcUrl, ticker, type } = opts;
    const isInfura = INFURA_PROVIDER_TYPES.has(type);
    if (isInfura) {
      this._configureInfuraProvider(opts as any);
    } else if (type === LOCALHOST) {
      this._configureLocalhostProvider();
    } else if (SUPPORTED_NETWORK_TYPES[type] !== undefined) {
      const { chainId: localChainId, rpcUrl: localUrl } = SUPPORTED_NETWORK_TYPES[type];
      this._configureStandardProvider({
        rpcUrl: localUrl,
        chainId: localChainId
      } as any);
    } else if (type === RPC) {
      this._configureStandardProvider({ rpcUrl, chainId, ticker, nickname } as any);
    } else {
      console.warn("Unknown provider type. Will automaticlly switch to amoy network.");
      const { chainId: localChainId, rpcUrl: localUrl } = SUPPORTED_NETWORK_TYPES["amoy"];
      this._configureStandardProvider({
        rpcUrl: localUrl,
        chainId: localChainId
      } as any);
    }
  }

  _configureLocalhostProvider() {
    const networkClient = createLocalhostClient();
    this._setNetworkClient(networkClient);
  }

  _configureInfuraProvider({ type }: { type: string }) {
    const networkClient = createInfuraClient({ network: type as any });
    this._setNetworkClient(networkClient as any);
  }

  _configureStandardProvider(providerType: ProviderConfig) {
    const { rpcUrl, chainId } = providerType;
    const networkClient = createJsonRpcClient({ rpcUrl, chainId });
    // hack to add a 'rpc' network with chainId
    this._setNetworkClient(networkClient);
  }

  async _setNetworkClient({
    networkMiddleware,
    blockTracker
  }: {
    networkMiddleware: JRPCMiddleware<unknown, unknown>;
    blockTracker: PollingBlockTracker;
  }) {
    const metamaskMiddleware = createMetamaskMiddleware(this._baseProviderParams as any);
    const engine = new JRPCEngine();
    engine.push(metamaskMiddleware);
    engine.push(networkMiddleware);
    const provider = providerFromEngine(engine as any);
    this._setProviderAndBlockTracker({ provider: provider as any, blockTracker });
  }

  _setProviderAndBlockTracker({ provider, blockTracker }: { provider: SafeEventEmitterProvider; blockTracker: PollingBlockTracker }) {
    // update or intialize proxies
    if (this._providerProxy) {
      (this._providerProxy as any).setTarget(provider);
    } else {
      this._providerProxy = createSwappableProxy(provider);
    }
    if (this._blockTrackerProxy) {
      (this._blockTrackerProxy as any).setTarget(blockTracker);
    } else {
      this._blockTrackerProxy = createEventEmitterProxy(blockTracker, { eventFilter: "skipInternal" });
    }
    // set new provider and blockTracker
    this._provider = provider;
    provider.setMaxListeners(100);
    this._blockTracker = blockTracker;
  }

  setRpcTarget(rpcUrl: string, chainId: number | string, ticker = "ETH", nickname = "", rpcPrefs = {}) {
    this.setProviderConfig({
      type: RPC,
      rpcUrl,
      chainId,
      ticker,
      nickname,
      rpcPrefs
    });
  }
}

function createLocalhostClient() {
  const rpc = store.getState().wallet.networkType.rpcUrl;
  const fetchMiddleware = createFetchMiddleware({ rpcUrl: rpc });
  const blockProvider = providerFromMiddleware(fetchMiddleware);
  const blockTracker = new PollingBlockTracker({ provider: blockProvider as any, pollingInterval: 1000 });

  const networkMiddleware = mergeMiddleware([
    createBlockRefRewriteMiddleware({ blockTracker: blockTracker as any }) as any,
    createBlockTrackerInspectorMiddleware({ blockTracker: blockTracker as any }),
    fetchMiddleware
  ]);
  return { networkMiddleware, blockTracker };
}
