// @ts-nocheck
import { IonAvatar, IonButton, IonIcon, IonItem, IonLabel, IonList, IonLoading } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { changeArrWc, fetchClose } from "shared/actions/walletConnectAction";
import { approveEIP155Request, rejectEIP155Request } from "shared/utils/walletconnect/EIP155RequestHandlerUtil";
import { legacySignClient } from "shared/utils/walletconnect/LegactWalletConnectUtil";

import { IModalCloseWalletConnect } from "interfaces/actions/IWalletConnect";
import { useTranslation } from "react-i18next";
import "./styles/index.css";
import useWallet from "hooks/useWallet";
import { chevronForwardOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import PreferencesController from "controllers/PreferencesController";
import torus from "libs/TorusExtended";
import { fetchTransferGas } from "shared/actions/transferAction";
import useWeb3 from "hooks/useWeb3";
import useNetwork from "hooks/useNetwork";
import { listChain } from "shared/config/chains";
import { IChains } from "interfaces/actions/IToken";
import { AstarDefault } from "assets/icons";

export default function LegacySessionSwitchNetworkModal(props: IModalCloseWalletConnect) {
  const { closeTheModalWalletConnect } = props;
  const { t } = useTranslation();
  const { accounts } = useWeb3();
  const dispatch = useAppDispatch();
  const currentWeb3Provider = torus.upbondController?.getProviderNetworkState() as any;
  // const getFromLocalStorage = JSON.parse(localStorage.getItem("walletconnect") || "{}");

  const { changeNetwork } = useNetwork();
  const { ethWallet } = useWallet({
    onError(error) {
      console.warn(error);
    }
  });
  const walletConnect = useAppSelector((state) => state.walletConnect);
  // Get request and wallet data from store
  const requestEvent = walletConnect.data?.legacyCallRequestEvent;
  const requestSession = walletConnect.data?.legacyRequestSession;
  const prefController = new PreferencesController();
  const [chainListData, setChainListData] = useState<IChains>();
  const [pairChainListData, setPairChainListData] = useState<IChains>();
  const [notSupportedChain, setNotSupportedChain] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const getChainList = async () => {
    try {
      const chainlist = await prefController.getAllChain();
      if (chainlist && currentWeb3Provider && requestEvent) {
        const walletconnect = localStorage.getItem("walletconnect") as string;
        const setSelectedChain = walletconnect ? JSON.parse(walletconnect).chainId.toString() : currentWeb3Provider.chainId.toString();
        const getChainListData = listChain.filter((x: IChains) => x.chain === setSelectedChain);
        const getPairChainListData = listChain.filter((x: IChains) => parseInt(x.chain) === parseInt(requestEvent?.params[0].chainId));
        if (getChainListData.length > 0 && getPairChainListData.length > 0) {
          setChainListData(getChainListData[0]);
          setPairChainListData(getPairChainListData[0]);
          setNotSupportedChain(false);
        } else {
          setNotSupportedChain(true);
        }
      } else {
        setNotSupportedChain(true);
      }
      setLoading(false);
    } catch (e) {
      setNotSupportedChain(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getChainList();
  }, [requestEvent?.params[0].chainId]);

  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return (
      <>
        <IonLabel className="font-semibold text-base leading-[19px] text-[#0a0a0a] flex justify-center p-5 mb-2.5 h-[1px]">
          {t("walletConnect.unsupported")}
        </IonLabel>

        <IonList className="py-4 px-[22px] font-normal text-base leading-[19px] text-[#0a0a0a]" lines="none" style={{ paddingTop: "0px" }}>
          <p className="font-roboto">{t("walletConnect.unsupportedDesc")}</p>
          <p className="font-roboto">{t("walletConnect.unsupportedMsg")}</p>
          <IonItem className="modal-confirmation-button-wrapper ion-no-padding" lines="none">
            <IonButton onClick={() => onReject()} className="no-padding-no" size="default" shape="round" style={{ marginInline: "0px" }}>
              {t("walletConnect.ok")}
            </IonButton>
          </IonItem>
        </IonList>
      </>
    );
  }

  // Get required request data
  const { id, method, params } = requestEvent;
  async function onApprove() {
    dispatch(changeArrWc(walletConnect));
    dispatch(fetchTransferGas(parseInt(requestEvent?.params[0].chainId).toString()));
    await changeNetwork(requestEvent?.params[0].chainId);
    closeTheModalWalletConnect(false);
    dispatch(fetchClose());
    if (requestEvent && ethWallet) {
      const response = await approveEIP155Request(ethWallet, {
        id,
        topic: "",
        params: {
          request: { method, params },
          chainId: requestSession?.chainId !== null && requestSession?.chainId !== undefined ? requestSession?.chainId.toString() : "137"
        }
      });

      if ("error" in response) {
        legacySignClient.rejectRequest({
          id,
          error: response.error
        });
      } else {
        legacySignClient.approveRequest({
          id,
          result: response.result
        });

        legacySignClient.updateSession({
          accounts: [accounts[0]],
          chainId: parseInt(params[0].chainId)
        });
      }
    }
  }

  // Handle reject action
  async function onReject() {
    dispatch(changeArrWc(walletConnect));
    closeTheModalWalletConnect(false);
    dispatch(fetchClose());
    if (requestEvent) {
      const { error } = rejectEIP155Request({
        id,
        topic: "",
        params: {
          request: { method, params },
          chainId: requestSession?.chainId !== null && requestSession?.chainId !== undefined ? requestSession?.chainId.toString() : "137"
        }
      });
      legacySignClient.rejectRequest({
        id,
        error
      });
    }
  }

  const reloadSrc = (e: any, chain_id: string) => {
    chain_id === "1"
      ? (e.target.src = "https://etherscan.io/images/main/empty-token.png")
      : chain_id === "137" || chain_id === "80002"
      ? (e.target.src = "https://polygonscan.com/images/main/empty-token.png")
      : (e.target.src = AstarDefault);
  };

  return (
    <>
      {loading ? (
        <IonLoading isOpen={loading} />
      ) : !loading && notSupportedChain ? (
        <>
          <IonLabel className="font-semibold text-base leading-[19px] text-[#0a0a0a] flex justify-center p-5 mb-2.5 h-[1px]">
            {t("walletConnect.unsupported")}
          </IonLabel>

          <IonList
            className="py-4 px-[22px] font-normal text-base leading-[19px] text-[#0a0a0a]"
            lines="none"
            style={{ paddingTop: "0px" }}>
            <p className="font-roboto">{t("walletConnect.unsupportedDesc")}</p>
            <p className="font-roboto">{t("walletConnect.unsupportedMsg")}</p>
            <IonItem className="modal-confirmation-button-wrapper ion-no-padding" lines="none">
              <IonButton onClick={() => onReject()} className="no-padding-no" size="default" shape="round" style={{ marginInline: "0px" }}>
                {t("walletConnect.ok")}
              </IonButton>
            </IonItem>
          </IonList>
        </>
      ) : (
        <>
          <IonLabel className="font-semibold text-base leading-[19px] text-[#0a0a0a] flex justify-center p-5 mb-2.5 h-[1px]">
            {t("notification.confirmRequest")}
          </IonLabel>
          <IonItem lines="full" className="modal-confirmation-header">
            <div className="w-full">
              <div className="flex justify-between mb-2.5 w-full h-auto">
                <p>{t("notification.requestFrom")}</p>
                <p className="break-all font-semibold">{requestSession.peerMeta?.url.replace(/(^\w+:|^)\/\//, "")}</p>
              </div>
            </div>
          </IonItem>
          <IonItem lines="full" className="modal-confirmation-token-section-wrapper">
            <p className="text-center mt-0 text-base">{t("notification.allowSwitchNetwork")}</p>
          </IonItem>
          <IonList className="py-4 px-[22px] font-normal text-base leading-[19px] text-[#0a0a0a]" lines="none">
            <div className="modal-confirmation-body-step3">
              <div>
                <IonAvatar className="modal-confirmation-body-step3-img-wrapper">
                  {chainListData && (
                    <img
                      alt={chainListData.name}
                      src={chainListData.customLogo}
                      onError={(e) => reloadSrc(e, chainListData.chain.toString())}
                    />
                  )}
                </IonAvatar>
                {/* <p className="modal-confirmation-body-step3-token">Binance Smart Chain</p> */}
                <p className="modal-confirmation-body-step3-token">{chainListData && chainListData.name}</p>
              </div>
              <div className="modal-confirmation-body-arrow">
                <IonIcon src={chevronForwardOutline} color="dark" size="small" />
              </div>
              <div>
                <IonAvatar className="modal-confirmation-body-step3-img-wrapper">
                  {pairChainListData && (
                    <img
                      alt={pairChainListData.name}
                      src={pairChainListData.customLogo}
                      onError={(e) => reloadSrc(e, pairChainListData.chain.toString())}
                    />
                  )}
                </IonAvatar>
                <p className="modal-confirmation-body-step3-token">{pairChainListData && pairChainListData.name}</p>
              </div>
            </div>
            <IonItem className="modal-confirmation-button-wrapper ion-no-padding" lines="none">
              <IonButton onClick={() => onReject()} className="modal-confirmation-btn-reject no-padding-no" size="default" shape="round">
                {t("notification.reject")}
              </IonButton>
              <IonButton onClick={() => onApprove()} className="no-padding-no" size="default" shape="round">
                {t("notification.confirm")}
              </IonButton>
            </IonItem>
          </IonList>
        </>
      )}
    </>
  );
}
