// @ts-nocheck
import { IonAccordion, IonAccordionGroup, IonButton, IonItem, IonLabel, IonList } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useWallet from "hooks/useWallet";
import { IModalCloseWalletConnect } from "interfaces/actions/IWalletConnect";
import { useTranslation } from "react-i18next";
import { changeArrWc, fetchClose } from "shared/actions/walletConnectAction";
import { approveEIP155Request, rejectEIP155Request } from "shared/utils/walletconnect/EIP155RequestHandlerUtil";
import { getSignTypedDataParamsData } from "shared/utils/walletconnect/HelperUtil";
import { legacySignClient } from "shared/utils/walletconnect/LegactWalletConnectUtil";
import "./styles/index.css";

export default function LegacySessionSignTypedDataModal(props: IModalCloseWalletConnect) {
  const { closeTheModalWalletConnect } = props;
  const { t } = useTranslation();
  // Get request and wallet data from store
  const dispatch = useAppDispatch();
  const { ethWallet } = useWallet({
    onError(error) {
      console.warn(error);
    }
  });
  const walletConnect = useAppSelector((state) => state.walletConnect);
  const requestEvent = walletConnect.data?.legacyCallRequestEvent;
  const getFromLocalStorage = JSON.parse(localStorage.getItem("walletconnect") || "{}");
  const requestSession = walletConnect.data?.legacyRequestSession;

  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return (
      <>
        <IonLabel className="font-semibold text-base leading-[19px] text-[#0a0a0a] flex justify-center p-5 mb-2.5 h-[1px]">
          {t("notification.confirmRequest")}
        </IonLabel>
        <IonItem lines="full" className="modal-confirmation-header">
          <div className="w-full">
            <div className="flex justify-between mb-2.5 w-full h-auto">
              <p className="break-all font-semibold">{t("settings.missingRequestData")}</p>
            </div>
          </div>
        </IonItem>
        <IonList className="py-4 px-[22px] font-normal text-base leading-[19px] text-[#0a0a0a]" lines="none">
          <IonItem className="modal-confirmation-button-wrapper" lines="none">
            <IonButton onClick={() => onReject()} className="modal-confirmation-btn-reject no-padding-no" size="default" shape="round">
              {t("notification.reject")}
            </IonButton>
          </IonItem>
        </IonList>
      </>
    );
  }

  // Get required request data
  const { id, method, params } = requestEvent;

  // Get data
  const data = getSignTypedDataParamsData(params);

  // Handle approve action (logic varies based on request method)
  async function onApprove() {
    dispatch(changeArrWc(walletConnect));
    closeTheModalWalletConnect(false);
    if (requestEvent && ethWallet) {
      dispatch(fetchClose());
      const response = await approveEIP155Request(ethWallet, {
        id,
        topic: "",
        params: {
          request: { method, params },
          chainId: requestSession?.chainId !== null && requestSession?.chainId !== undefined ? requestSession?.chainId.toString() : "137"
        }
      });

      if ("error" in response) {
        legacySignClient.rejectRequest({
          id,
          error: response.error
        });
      } else {
        legacySignClient.approveRequest({
          id,
          result: response.result
        });
      }
    }
  }

  // Handle reject action
  async function onReject() {
    dispatch(changeArrWc(walletConnect));
    closeTheModalWalletConnect(false);
    if (requestEvent) {
      dispatch(fetchClose());
      const { error } = rejectEIP155Request({
        id,
        topic: "",
        params: {
          request: { method, params },
          chainId: requestSession?.chainId !== null && requestSession?.chainId !== undefined ? requestSession?.chainId.toString() : "137"
        }
      });
      legacySignClient.rejectRequest({
        id,
        error
      });
    }
  }

  return (
    <>
      <IonLabel className="font-semibold text-base leading-[19px] text-[#0a0a0a] flex justify-center p-5 mb-2.5 h-[1px]">
        {t("notification.confirmRequest")}
      </IonLabel>
      <IonItem lines="full" className="modal-confirmation-header">
        <div className="w-full">
          <div className="flex justify-between mb-2.5 w-full h-auto">
            <p>{t("notification.requestFrom")}</p>
            <p className="break-all font-semibold">{getFromLocalStorage.peerMeta.url.replace(/(^\w+:|^)\/\//, "")}</p>
          </div>
        </div>
      </IonItem>
      <IonList className="py-4 px-[22px] font-normal text-base leading-[19px] text-[#0a0a0a]" lines="none">
        <IonAccordionGroup>
          <IonAccordion className="!m-2.5" value="first">
            <IonItem slot="header" color="light">
              <IonLabel className="!ml-2.5 capitalize">{Object.keys(data)[0]}</IonLabel>
            </IonItem>
            {data.domain?.salt !== undefined ? (
              <div className="ion-padding types-sign" slot="content">
                {/* <b>EIP712Domain:</b>
                <br /> */}
                <p>{JSON.stringify(data.types)}</p>
                {/* <br />
                <b>ERC20ForwardRequest:</b>
                <br /> */}
                {/* <p>{JSON.stringify(data.types.ERC20ForwardRequest)}</p> */}
              </div>
            ) : (
              <div className="ion-padding types-sign" slot="content">
                <p>{JSON.stringify(data.types)}</p>
                {/* <b>EIP712Domain:</b>
                <br />
                <p>{JSON.stringify(data.types.EIP712Domain)}</p>
                <br />
                <b>GasData:</b>
                <br />
                <p>{JSON.stringify(data.types.GasData)}</p>
                <br />
                <b>RelayData:</b>
                <br />
                <p>{JSON.stringify(data.types.RelayData)}</p>
                <br />
                <b>RelayRequest:</b>
                <br />
                <p>{JSON.stringify(data.types.RelayRequest)}</p> */}
              </div>
            )}
          </IonAccordion>
          <IonAccordion className="!m-2.5" value="second">
            <IonItem slot="header" color="light">
              <IonLabel className="!ml-2.5 capitalize">{Object.keys(data)[1]}</IonLabel>
            </IonItem>
            {data.domain?.salt !== undefined ? (
              <div className="ion-padding types-sign" slot="content">
                <p>salt: {data.domain.salt}</p>
                <p>name: {data.domain.name}</p>
                <p>verifyingContract: {data.domain.verifyingContract}</p>
                <p>version: {data.domain.version}</p>
              </div>
            ) : (
              <div className="ion-padding types-sign" slot="content">
                <p>chainId: {data.domain.chainId}</p>
                <p>name: {data.domain.name}</p>
                <p>verifyingContract: {data.domain.verifyingContract}</p>
                <p>version: {data.domain.version}</p>
              </div>
            )}
          </IonAccordion>
          <IonAccordion className="!m-2.5" value="third">
            <IonItem slot="header" color="light">
              <IonLabel className="!ml-2.5 capitalize">{Object.keys(data)[3]}</IonLabel>
            </IonItem>
            {data.message?.batchId !== undefined ? (
              <div className="ion-padding types-sign" slot="content">
                <p>batchId: {data.message.batchId}</p>
                <p>batchNonce: {data.message.batchNonce}</p>
                <p>data: {data.message.data}</p>
                <p>deadline: {data.message.deadline}</p>
                <p>from: {data.message.from}</p>
                <p>to: {data.message.to}</p>
                <p>token: {data.message.token}</p>
                <p>tokenGasPrice: {data.message.tokenGasPrice}</p>
                <p>txGas: {data.message.txGas}</p>
              </div>
            ) : (
              <div className="ion-padding types-sign" slot="content">
                {/* <b>Encoded Function</b> */}
                <p>{JSON.stringify(data.message)}</p>
                {/* <p>encodedFunction: {data.message.encodedFunction}</p>
                <b>Target</b>
                <p>Target: {data.message.target}</p>
                <b>Gas Data</b>
                <p>baseRelayFee: {data.message.gasData.baseRelayFee}</p>
                <p>gasLimit: {data.message.gasData.gasLimit}</p>
                <p>gasPrice: {data.message.gasData.gasPrice}</p>
                <p>pctRelayFee: {data.message.gasData.pctRelayFee}</p>
                <b>Relay Data</b>
                <p>paymaster: {data.message.relayData.paymaster}</p>
                <p>relayWorker: {data.message.relayData.relayWorker}</p>
                <p>senderAddress: {data.message.relayData.senderAddress}</p>
                <p>senderNonce: {data.message.relayData.senderNonce}</p> */}
              </div>
            )}
          </IonAccordion>
        </IonAccordionGroup>
        <IonItem className="modal-confirmation-button-wrapper" lines="none">
          <IonButton onClick={() => onReject()} className="modal-confirmation-btn-reject no-padding-no" size="default" shape="round">
            {t("notification.reject")}
          </IonButton>
          <IonButton onClick={() => onApprove()} className="no-padding-no" size="default" shape="round">
            {t("notification.confirm")}
          </IonButton>
        </IonItem>
      </IonList>
    </>
  );
}
