// @ts-nocheck
import { IonLabel, IonListHeader, IonSelect, IonSelectOption } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { IChains } from "interfaces/actions/IToken";
import { useTranslation } from "react-i18next";
import { fetchSelectedHistoryChain } from "shared/actions/historyChain";
import { listChain } from "shared/config/chains";

const HeaderHistory = ({ showSelect = true }) => {
  const { t } = useTranslation();
  const customAlertOptions = {
    translucent: true,
    header: t("settings.selectNetwork"),
    cssClass: "network-alert",
    buttons: [t("settings.save")],
    mode: "ios",
    animated: true
  };
  const historyChain = useAppSelector((state) => state.historyChain?.historyChain);
  const balance = useAppSelector((state) => state.balance);

  const dispatch = useAppDispatch();
  return (
    <IonListHeader className="flex justify-around mt-0 lg:mt-[8.5px] ps-0">
      <IonLabel className="mt-0">{t("account.history")}</IonLabel>
      {showSelect && (
        <IonSelect
          interface="alert"
          interfaceOptions={customAlertOptions}
          className="max-w-[26%] min-w-[26%] rounded-full bg-gray-light text-base mb-0 gap-[2px]"
          placeholder="Select Chain"
          okText={t("settings.save") as string}
          value={historyChain?.chain}
          onIonChange={(event) => {
            dispatch(fetchSelectedHistoryChain(event.target.value));
          }}>
          <>
            {listChain.map((x: IChains, i: number) => {
              if (x.type === "testnet" && !balance.showTestnet) {
                return null;
              } else {
                return (
                  <IonSelectOption key={i} value={x.chain}>
                    {x.name}
                  </IonSelectOption>
                );
              }
            })}
          </>
        </IonSelect>
      )}
    </IonListHeader>
  );
};

export default HeaderHistory;
