// @ts-nocheck
import { EIP155_SIGNING_METHODS } from "shared/config/eip155WC";
import { web3wallet } from "shared/utils/walletconnect/WalletConnectUtil";
import { SignClientTypes } from "@walletconnect/types";
import { useCallback, useEffect } from "react";
import { fetchArrWalletConnect, walletConnectAction } from "shared/actions/walletConnectAction";
import { useAppDispatch } from "./useRedux";
import { State } from "interfaces/actions/IWalletConnect";
import { getSdkError } from "@walletconnect/utils";
import { Web3WalletTypes } from "@walletconnect/web3wallet";

export default function useWalletConnectEventsManager(initialized: boolean) {
  const dispatch = useAppDispatch();

  /******************************************************************************
   * 1. Open session proposal modal for confirmation / rejection
   *****************************************************************************/
  const onSessionProposal = useCallback((proposal: SignClientTypes.EventArguments["session_proposal"]) => {
    dispatch(walletConnectAction.setOpen({ open: true, view: "SessionProposalModal", data: { proposal } }));
  }, []);

  /******************************************************************************
   * 2. Open Auth modal for confirmation / rejection
   *****************************************************************************/
  const onAuthRequest = useCallback((request: Web3WalletTypes.AuthRequest) => {
    dispatch(walletConnectAction.setOpen({ open: true, view: "AuthRequestModal", data: { request } }));
  }, []);

  /******************************************************************************
   * 3. Open request handling modal based on method that was used
   *****************************************************************************/
  const multipleDispatch = (dispatch: any, dataDispatch: State) => {
    dispatch(fetchArrWalletConnect(dataDispatch));
    dispatch(walletConnectAction.setOpen(dataDispatch));
  };
  const onSessionRequest = useCallback(async (requestEvent: SignClientTypes.EventArguments["session_request"]) => {
    const { topic, params } = requestEvent;
    const { request } = params;
    const requestSession = web3wallet.engine.signClient.session.get(topic);

    switch (request.method) {
      case EIP155_SIGNING_METHODS.ETH_SIGN:
      case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
        return multipleDispatch(dispatch, {
          open: true,
          view: "SessionSignModal",
          data: { requestEvent, requestSession }
        });

      case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
      case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
      case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4:
        return multipleDispatch(dispatch, {
          open: true,
          view: "SessionSignTypedDataModal",
          data: { requestEvent, requestSession }
        });

      case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
      case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
        return multipleDispatch(dispatch, {
          open: true,
          view: "SessionSendTransactionModal",
          data: { requestEvent, requestSession }
        });

      case EIP155_SIGNING_METHODS.ETH_SIGN_ADD_NETWORK:
        return multipleDispatch(dispatch, {
          open: true,
          view: "SessionAddNetworkModal",
          data: { requestEvent, requestSession }
        });
      default:
        return multipleDispatch(dispatch, {
          open: true,
          view: "SessionUnsuportedMethodModal",
          data: { requestEvent, requestSession }
        });
    }
  }, []);

  /******************************************************************************
   * Set up WalletConnect event listeners
   *****************************************************************************/
  useEffect(() => {
    if (initialized) {
      web3wallet?.on("session_proposal", onSessionProposal);
      web3wallet?.on("session_request", onSessionRequest);
      web3wallet?.on("auth_request", onAuthRequest);

      web3wallet?.on("session_delete", async (data) => {
        const pairing = Object.values(web3wallet.core.pairing.getPairings()).filter((value) => value.topic === data.topic);
        if (pairing.length > 0) {
          await web3wallet.disconnectSession({ topic: pairing[0].topic, reason: getSdkError("USER_DISCONNECTED") });
        }
        dispatch(walletConnectAction.setConnect(false));
        dispatch(walletConnectAction.setOpen({ open: false, view: {} as any, data: {}, connected: false }));
      });
    }
  }, [initialized, onSessionProposal, onSessionRequest]);
}
