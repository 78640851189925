/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction } from "@reduxjs/toolkit";
import { IPermission, IPermissions } from "interfaces/actions/IPermission";
import { RootState } from "shared/store";
import permissionSlice from "shared/store/permissionSlice";

export const permissionAction = permissionSlice.actions;

export const selectPermission =
  (data: IPermission): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(permissionAction.setSelectedPermission(data));
  };

export const deletePermissions =
  (data: IPermission): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { permissions } = getState().permissions;
    const permissionFiltered = permissions.filter((permission: IPermission) => permission.key !== data.key);
    dispatch(permissionAction.setPermissions(permissionFiltered));
    localStorage.setItem("permissionKey", JSON.stringify(permissionFiltered));
  };

export const updatePermissions =
  (data: IPermission): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { permissions } = getState().permissions;
    const permissionFiltered: IPermission[] = [];
    permissions.map((permission: IPermission) => {
      if (permission.origin == data.origin) {
        permissionFiltered.push(data);
      } else {
        permissionFiltered.push(permission);
      }
    });
    localStorage.setItem("permissionKey", JSON.stringify(permissionFiltered));
    dispatch(permissionAction.setPermissions(permissionFiltered));
  };

export const updateDid =
  (data: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(permissionAction.setDid(data));
  };

export const fetchPermissions =
  (data: IPermission): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { permissions } = getState().permissions;
    const permissionMerged = [data, ...permissions];
    dispatch(permissionAction.setPermissions(permissionMerged));
  };
