// @ts-nocheck
import { IonButton, IonIcon } from "@ionic/react";
import WalletConnectButton from "features/home/components/WalletConnectButton";
import PendingComponent from "features/home/components/PendingComponent";
import { setOpenModalPurchase } from "shared/actions/walletAction";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useTranslation } from "react-i18next";
import { addOutline } from "ionicons/icons";

const HomeHeaderMb = () => {
  const { t } = useTranslation();
  const openModalPurchase = useAppSelector((state) => state.wallet.openModalPurchase);
  const appDispatch = useAppDispatch();

  return (
    <>
      <WalletConnectButton />
      <div>
        <IonButton
          shape="round"
          color="primary"
          className="h-[36px]"
          onClick={() => openModalPurchase == false && appDispatch(setOpenModalPurchase(true))}>
          <IonIcon slot="end" icon={addOutline} />
          {t("home.addCash")}
        </IonButton>
        <PendingComponent />
      </div>
    </>
  );
};

export default HomeHeaderMb;
