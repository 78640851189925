import useTheme from "hooks/useTheme";
import { useTranslation } from "react-i18next";

const EmptyTransaction = () => {
  const { t } = useTranslation();
  const { breakpoint } = useTheme();
  return (
    <div className="flex justify-center items-center content-center h-60">
      <span className={`text-base ${breakpoint < 1024 ? "font-semibold" : "font-extrabold text-black"}`}>
        {t("account.noTransactions")}
      </span>
    </div>
  );
};

export default EmptyTransaction;
