// @ts-nocheck
import {
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow
} from "@ionic/react";
import HeaderMainPc from "components/header/pc";
import { useTranslation } from "react-i18next";

const PermissionDetailMainPc: React.FC<any> = ({ selectedPermission }) => {
  const { t } = useTranslation();

  return (
    <IonPage className="ion-no-padding">
      <HeaderMainPc title={t("settings.managePermission_title")} link="/wallet/settings/init" />

      <IonContent className="bg-base ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol size="4">
              <IonCard class="ion-padding card-setting m-0">
                <IonCardContent>
                  <>
                    <p> {t("settings.managePermission_subtitle")}</p>
                    <IonList className="permission-form-list-desk">
                      {selectedPermission.userData &&
                        Object.keys(selectedPermission.userData).map((x, i) => {
                          return (
                            <>
                              <IonItem key={i} className="personal-wrapper font-normal text-base leading-[19px] text-[#5d5d5d]">
                                <div className="format-label">
                                  <div className="format-label-start">
                                    <IonLabel id="title" position="stacked">
                                      {t(`settings.${x}`)}
                                    </IonLabel>
                                    <div className="required-field">{t("consentsForm.required")}</div>
                                  </div>
                                  <IonCheckbox slot="end" disabled checked></IonCheckbox>
                                </div>
                                <IonInput
                                  placeholder="Enter name"
                                  value={selectedPermission.userData[x]}
                                  className="permission"
                                  disabled></IonInput>
                              </IonItem>
                            </>
                          );
                        })}
                    </IonList>
                    {/* <div className="button-containers">
                      <IonButton shape="round" color="primary" expand="block">
                        {t("settings.save")}
                      </IonButton>
                      <IonButton
                        shape="round"
                        color="danger"
                        expand="block"
                        fill="outline"
                        onClick={() => {
                          presentAlert({
                            header: `${t("settings.delete_account")} ?`,
                            message: t("settings.delete_account_confirm"),
                            cssClass: "delete-account",
                            buttons: [
                              {
                                text: t("settings.cancel"),
                                cssClass: "alert-button-cancel"
                              },
                              {
                                text: t("settings.delete"),
                                cssClass: "alert-button-confirm",
                                handler: () => {
                                  deletePermission();
                                }
                              }
                            ]
                          });
                        }}>
                        {t("settings.delete_account")}
                      </IonButton>
                    </div> */}
                  </>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PermissionDetailMainPc;
