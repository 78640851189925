import { MUX_CHUNK } from "shared/config/mux";
import { EventEmitter } from "events";
import { MuxSubStream } from "interfaces/ui/IEmbed";
import torus from "libs/TorusExtended";
import { embedActions } from "shared/actions/embedAction";
import { store } from "shared/store";
import config from "shared/config";
import { FEATURES_DEFAULT_POPUP_WINDOW } from "shared/enums";

import StreamWindow from "./StreamWindow";

class PopupHandler extends EventEmitter {
  url: string;

  target: string;

  window: Window | null | StreamWindow;

  features: string;

  windowTimer: NodeJS.Timer | any;

  iClosedWindow: boolean;

  preopenInstanceId: string | undefined;

  reduxStore: typeof store;

  topCalc: number;

  constructor({
    url,
    target,
    features,
    preopenInstanceId,
    topCalc = 14
  }: {
    url: URL | string;
    target?: string;
    features: string;
    preopenInstanceId?: string;
    topCalc?: number;
  }) {
    super();
    // Add in dapp storage key to all popups as a hash parameter
    const localUrl = url instanceof URL ? url : new URL(url);

    if (config.dappStorageKey) {
      if (localUrl.hash) localUrl.hash += `&dappStorageKey=${config.dappStorageKey}`;
      else localUrl.hash = `#dappStorageKey=${config.dappStorageKey}`;
    }
    this.url = localUrl.href;
    this.target = target || "_top";
    this.features = features || FEATURES_DEFAULT_POPUP_WINDOW;
    this.window = window;
    this.windowTimer = {};
    this.iClosedWindow = false;
    this.preopenInstanceId = preopenInstanceId;
    this.reduxStore = store;
    this.topCalc = topCalc;
    this._setupTimer();
  }

  _setupTimer() {
    this.windowTimer = setInterval(() => {
      if (this.window && this.window.closed) {
        clearInterval(this.windowTimer);
        if (!this.iClosedWindow) {
          this.emit("close");
        }
        this.iClosedWindow = false;
        this.window = null;
      }
      if (this.window === undefined) clearInterval(this.windowTimer);
    }, 500);
  }

  open(usingIframe = false) {
    if (!usingIframe) {
      if (!this.preopenInstanceId && this.window) {
        // try to open a window first
        this.window = window.open(this.url, this.target, this.features);

        if (!this.window) {
          // if it's blocked, open streamwindow
          this.window = new StreamWindow({ url: this.url });
        }
        return Promise.resolve();
      }
      this.window = new StreamWindow({ preopenInstanceId: this.preopenInstanceId });
      return this.window.open(this.url);
    } else {
      return this.reduxStore.dispatch(
        embedActions.setIFrameConfig({
          open: true,
          origin: "localhost",
          src: this.url,
          topCalc: this.topCalc
        })
      );
    }
  }

  close() {
    const { communicationMux } = torus || {};
    this.reduxStore.dispatch(
      embedActions.setIFrameConfig({
        open: false,
        origin: "localhost",
        src: `${process.env.REACT_APP_BASE_URL}`,
        topCalc: 14
      })
    );
    if (communicationMux) {
      const widgetStream = communicationMux.getStream(MUX_CHUNK.WIDGETSTREAM) as MuxSubStream;
      widgetStream.write({
        data: false
      });
    }
    this.iClosedWindow = true;
    if (this.window) this.window.close();
  }
}

export default PopupHandler;
