// @ts-nocheck
import { IonButton, IonImg, IonItem, IonLabel, IonList, IonLoading, useIonLoading } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useWallet from "hooks/useWallet";
import { IModalCloseWalletConnect } from "interfaces/actions/IWalletConnect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { changeArrWc, fetchClose } from "shared/actions/walletConnectAction";
import { GasServices } from "services/GasServices";
import { fromGweiToWeiHex } from "shared/utils/etherUtils";
import { approveEIP155Request, rejectEIP155Request } from "shared/utils/walletconnect/EIP155RequestHandlerUtil";
import { toSmallUnit, truncate } from "shared/utils/walletconnect/HelperUtil";
import { legacySignClient } from "shared/utils/walletconnect/LegactWalletConnectUtil";
import useNetwork from "hooks/useNetwork";
import { fetchTransferGas } from "shared/actions/transferAction";
import { AstarDefault } from "assets/icons";

export default function LegacySessionSendTransactionModal(props: IModalCloseWalletConnect) {
  const { changeNetwork } = useNetwork();
  const { closeTheModalWalletConnect } = props;
  const { t } = useTranslation();
  const balance = useAppSelector((state) => state.balance);

  // Get request and wallet data from store
  const dispatch = useAppDispatch();
  const { ethWallet } = useWallet({
    onError(error) {
      console.warn(error);
    }
  });
  const walletConnect = useAppSelector((state) => state.walletConnect);
  // Get request and wallet data from store
  const requestEvent = walletConnect.data?.legacyCallRequestEvent;
  const requestSession = walletConnect.data?.legacyRequestSession;
  const getFromLocalStorage = JSON.parse(localStorage.getItem("walletconnect") || "{}");
  const getCoinTransfer = balance.coin.filter((x: any) => x.chain_id.toString() === getFromLocalStorage.chainId.toString());
  const [gasPrice, setGasPrice] = useState<number>(0);
  const [gasFee, setGasFee] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [chainId, setChainId] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [gasParams, setGasParams] = useState<any>();
  const [finalGasParams, setFinalGasParams] = useState<any>();
  const [present] = useIonLoading();

  const reloadSrc = (e: any, chain_id: string) => {
    chain_id === "1"
      ? (e.target.src = "https://etherscan.io/images/main/empty-token.png")
      : chain_id === "137" || chain_id === "80001"
      ? (e.target.src = "https://polygonscan.com/images/main/empty-token.png")
      : (e.target.src = AstarDefault);
  };
  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return (
      <>
        <IonLabel className="font-semibold text-base leading-[19px] text-[#0a0a0a] flex justify-center p-5 mb-2.5 h-[1px]">
          {t("notification.confirmRequest")}
        </IonLabel>
        <IonItem lines="full" className="modal-confirmation-header">
          <div className="w-full">
            <div className="flex justify-between mb-2.5 w-full h-auto">
              <p className="break-all font-semibold">{t("settings.missingRequestData")}</p>
            </div>
          </div>
        </IonItem>
        <IonList className="py-4 px-[22px] font-normal text-base leading-[19px] text-[#0a0a0a]" lines="none">
          <IonItem className="modal-confirmation-button-wrapper" lines="none">
            <IonButton onClick={() => onReject()} className="modal-confirmation-btn-reject no-padding-no" size="default" shape="round">
              {t("notification.reject")}
            </IonButton>
          </IonItem>
        </IonList>
      </>
    );
  }

  // Get required proposal data
  const { id, method, params } = requestEvent;

  // Handle approve action
  async function onApprove() {
    dispatch(changeArrWc(walletConnect));
    closeTheModalWalletConnect(false);
    dispatch(fetchClose());
    if (requestEvent && ethWallet) {
      const response = await approveEIP155Request(ethWallet, {
        id,
        topic: "",
        params: {
          request: { method, params: finalGasParams },
          chainId: chainId.toString()
        }
      });

      if ("error" in response) {
        legacySignClient.rejectRequest({
          id,
          error: response.error
        });
      } else {
        legacySignClient.approveRequest({
          id,
          result: response.result
        });
      }
    }
  }

  // Handle reject action
  async function onReject() {
    dispatch(changeArrWc(walletConnect));
    closeTheModalWalletConnect(false);
    dispatch(fetchClose());
    if (requestEvent) {
      const { error } = rejectEIP155Request({
        id,
        topic: "",
        params: {
          request: { method, params },
          chainId: chainId.toString()
        }
      });
      legacySignClient.rejectRequest({
        id,
        error
      });
    }
  }

  const getGasPrice = async (chainId: number) => {
    try {
      const minGas = 5;
      const gasOracle = new GasServices(chainId);
      const gasPricesWithEstimate = await gasOracle.gasPricesWithEstimate();
      let getValueFromGweiToWeiHex: string = fromGweiToWeiHex(gasPricesWithEstimate.gasPrices.fast.toString());
      if (gasPricesWithEstimate.gasPrices.fast < minGas) {
        getValueFromGweiToWeiHex = fromGweiToWeiHex(minGas.toString());
      }
      return getValueFromGweiToWeiHex;
    } catch (err) {
      return null;
    }
  };

  const getGas = async (chainId: number) => {
    let gasPrice: any = await getGasPrice(chainId);
    if (!gasPrice) {
      gasPrice = (await ethWallet?.getGasPrice())?.toString();
    }
    const finalGasParams = gasParams.map((item: any) => {
      const decimal = 18;
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty("gasPrice") && item.gasPrice != 0) {
        gasPrice = item.gasPrice.toString();
      }
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty("gasLimit")) {
        const getGasFee = parseInt(item.gasLimit) * parseInt(gasPrice);
        if (item.value !== undefined) {
          setTotalPrice(toSmallUnit(parseInt(item.gasLimit) * gasPrice, decimal) + toSmallUnit(item.value, decimal));
        } else if (item.value === undefined) {
          setTotalPrice(toSmallUnit(parseInt(item.gasLimit) * gasPrice, decimal));
        }
        setGasFee(getGasFee);
      }
      item.gasPrice = gasPrice;
      return item;
    });
    setGasPrice(gasPrice);
    setFinalGasParams(finalGasParams);
    return true;
  };

  const [error, setError] = useState(false);
  const [errorBalanceMessage, setErrorBalanceMessage] = useState("");
  const getBalanceValidation = async () => {
    if (ethWallet) {
      // const getPriceEtherFormat = parseInt(paramsNoGas[0].gasLimit) * gasPrice + parseInt(paramsNoGas[0].value);
      const getBalance = await ethWallet.getBalance();
      const getConvertedBalance = toSmallUnit(parseInt(getBalance.toString()), 18);
      if (getConvertedBalance < totalPrice) {
        setError(true);
        setErrorBalanceMessage(`${t("transfer.INSUFFICIENT_FUNDS")}`);
      } else {
        setError(false);
        setErrorBalanceMessage("");
      }
    }
  };

  const getChainId = async () => {
    if (requestSession && ethWallet) {
      dispatch(fetchTransferGas(requestSession.chainId.toString()));
      await changeNetwork(requestSession.chainId.toString());
      setChainId(requestSession.chainId);
      await getGas(requestSession.chainId);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    totalPrice && getBalanceValidation();
  }, [totalPrice]);

  useEffect(() => {
    if (ethWallet && gasParams) {
      getChainId();
    }
  }, [gasParams, ethWallet]);

  useEffect(() => {
    if (params) {
      const paramsNoGas = params.map(({ gas: gasLimit, ...rest }) => ({
        gasLimit,
        gasPrice,
        ...rest
      }));
      setGasParams(paramsNoGas);
    }
  }, [params]);

  return (
    <>
      <div className="wrapper">
        <div className="spinner">
          <IonLoading cssClass="my-custom-class" isOpen={isLoading} spinner={"circles"} />
        </div>
        <IonLabel className="font-semibold text-base leading-[19px] text-[#0a0a0a] flex justify-center p-5 mb-2.5 h-[1px]">
          {t("notification.confirmRequest")}
        </IonLabel>
        <IonItem lines="none" className="modal-confirmation-header">
          <div className="w-full">
            <div className="flex justify-between mb-2.5 w-full !h-[45px]">
              <p>{t("notification.requestFrom")}</p>
              <p className="break-all font-semibold">{getFromLocalStorage.peerMeta.url.replace(/(^\w+:|^)\/\//, "")}</p>
            </div>

            <div className="flex justify-between mb-2.5 w-full h-auto">
              <p>{t("notification.transferTo")}</p>
              <p className="break-all font-semibold">{gasParams && truncate(gasParams[0].to, 12)}</p>
            </div>
          </div>
        </IonItem>
        <IonItem className="modal-confirmation-token-section-wrapper">
          <div className="flex items-center p-0 leading-4 text-[#0a0a0a] text-base">
            {/* <IonIcon src={ellipse} className="text-[#ff5e5e]" slot="start"></IonIcon> */}
            <IonImg
              className="w-10 rounded-[100%] mr-[5px] border-solid border-black border-2"
              alt={""}
              src={
                getCoinTransfer[0]?.contract_address
                  ? `https://tokens.1inch.io/${getCoinTransfer[0]?.contract_address}.png`
                  : window.location.origin + "/assets/noimg.png"
              }
              onIonError={(e) => reloadSrc(e, getCoinTransfer[0]?.chain_id)}
            />
            <div>
              <IonLabel className="font-semibold" position="stacked">
                {getCoinTransfer[0]?.contract_name}
              </IonLabel>
              <IonLabel className="token-network" position="stacked">
                {getCoinTransfer[0]?.contract_ticker_symbol}
              </IonLabel>
            </div>
          </div>
        </IonItem>
        <IonList className="py-[2px] px-2.5 font-normal text-base leading-[19px] text-[#0a0a0a]" lines="none">
          <div className="py-[2px] px-2.5 font-normal text-base leading-[19px] text-[#0a0a0a]">
            <div className="flex justify-between max-w-[300px] h-8">
              <p>{t("transfer.amountToSend")}</p>
              <p>
                {gasParams && toSmallUnit(gasParams[0].value ? gasParams[0].value : 0, 18).toFixed(6)}{" "}
                {getCoinTransfer[0].contract_ticker_symbol}
              </p>
            </div>
            <div className="flex justify-between max-w-[300px] h-8">
              <p>{t("transfer.gasFee")}</p>
              <p>
                {/* {toSmallUnit(parseInt(gasParams[0].gasLimit) * gasPrice, 18).toFixed(6)} {getCoinTransfer[0].contract_ticker_symbol} */}
                {toSmallUnit(gasFee, 18).toFixed(6)} {getCoinTransfer[0].contract_ticker_symbol}
              </p>
            </div>
            <div className="flex justify-between max-w-[300px] h-8">
              <p>{t("transfer.total")}</p>
              <p>
                {totalPrice.toFixed(6)} {getCoinTransfer[0].contract_ticker_symbol}
              </p>
            </div>
          </div>
          <br></br>
          {error && (
            <span style={{ color: "red" }} className="ion-padding">
              {errorBalanceMessage}
            </span>
          )}
          <IonItem className="modal-confirmation-button-wrapper" lines="none">
            <IonButton
              onClick={() => {
                onReject(),
                  present({
                    duration: 1000
                  });
              }}
              className="modal-confirmation-btn-reject no-padding-no"
              size="default"
              shape="round">
              {t("notification.reject")}
            </IonButton>
            <IonButton
              className="no-padding-no"
              disabled={error}
              onClick={() => {
                onApprove(),
                  present({
                    duration: 1000
                  });
              }}
              size="default"
              shape="round">
              {t("notification.confirm")}
            </IonButton>
          </IonItem>
        </IonList>
      </div>
    </>
  );
}
