/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction } from "@reduxjs/toolkit";
import { ContactScanner } from "interfaces/actions/IContact";
import { RootState } from "shared/store";
import scannerSlice from "shared/store/scannerSlice";

export const scannerAction = scannerSlice.actions;
export const fetchContactFromScanner =
  (data: ContactScanner): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(scannerAction.addFromScanner(data));
  };
