// @ts-nocheck
import { IonButton, IonList, IonModal } from "@ionic/react";
import { useTranslation } from "react-i18next";

const ModalCommingSoon = ({ isOpen, setOpenCommingSoon }: any) => {
  const { t } = useTranslation();
  return (
    <IonModal id="modal-error-c" trigger="open-modal" isOpen={isOpen}>
      <div className="p-4">
        <IonList lines="none" className="text-center p-4">
          {t(`account.commingSoon`)}
        </IonList>
        <IonButton shape="round" color="primary" expand="full" onClick={() => setOpenCommingSoon(false)}>
          {t("account.close")}
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ModalCommingSoon;
