// @ts-nocheck
import { IonRouterOutlet } from "@ionic/react";
import useTheme from "hooks/useTheme";
import { Redirect, Route, useLocation } from "react-router-dom";

import SplitPanel from "components/splitPanel";
import ConfirmPage from "pages/confirm";
import DataConsentPage from "pages/consent";
import EndPage from "pages/end";
import LoginPage from "pages/login";

import TkeyPage from "pages/login/tkeyInput";
import ConsentPage from "pages/permission";
import EmbedIframePage from "pages/popup";
import RedirectMsg from "pages/redirect";
import StartPage from "pages/start";
import AuthPage from "pages/login/auth";
import BackupPhrasePage from "pages/login/backupPhrase";
import RegisTkeyPage from "pages/login/regisTkeyInput";
import { useEffect } from "react";
import * as analytic from "../shared/utils/ga4";
import HomeRoutes from "./home";
import PendingTransactionRoutes from "./pendingTransaction";
import SettingRoutes from "./settings";
import TransferRoutes from "./transfer";

const RootRoutes = ({ selectedAddress }: { selectedAddress: string }) => {
  const { breakpoint } = useTheme();
  const location = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV === "production") analytic.init();
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "production")
      analytic.sendPageview({
        page_title: window.location.pathname,
        page_path: location.pathname + location.search,
        page_location: window.location.href
      });
  }, [location]);

  return (
    <IonRouterOutlet>
      <Route path="/login" component={LoginPage} />
      <Route path="/auth" component={AuthPage} />
      <Route path="/start" component={StartPage} />
      <Route path="/tkey-input" component={TkeyPage} />
      <Route path="/regis-tkey-input" component={RegisTkeyPage} />
      <Route path="/phrase-input" component={BackupPhrasePage} />
      <Route path="/end" component={EndPage} />
      <Route path="/permission" component={ConsentPage} />
      <Route path="/redirect" component={RedirectMsg} />
      <Route path="/confirm" component={ConfirmPage} />
      <Route path="/consent" component={DataConsentPage} />
      <Route path="/popup" component={EmbedIframePage} />
      <Route path="/" exact>
        <Redirect to="/wallet" />
      </Route>
      {breakpoint < 1024 ? (
        <>
          <Route path="/settings" render={() => (selectedAddress !== "" ? <SettingRoutes /> : <Redirect to="/login" />)} />
          <Route path="/transfer" render={() => (selectedAddress !== "" ? <TransferRoutes /> : <Redirect to="/login" />)} />
          <Route path="/wallet" render={() => (selectedAddress !== "" ? <HomeRoutes /> : <Redirect to="/login" />)} />
        </>
      ) : (
        <Route path="/wallet" render={() => (selectedAddress !== "" ? <SplitPanel /> : <Redirect to="/login" />)} />
      )}
      <Route
        path="/pending-transaction"
        render={() => (selectedAddress !== "" ? <PendingTransactionRoutes /> : <Redirect to="/login" />)}
      />
    </IonRouterOutlet>
  );
};

export default RootRoutes;
