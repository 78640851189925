
// @ts-nocheck
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonModal } from "@ionic/react";
import useTheme from "hooks/useTheme";
import { IPropsModalHistory } from "interfaces/ui/IMenu";
import EmptyTransaction from "features/account/components/EmptyTransaction";
import HeaderHistory from "features/account/components/HeaderHistory";
import ItemHistory from "features/account/components/ItemHistory";
import MoralisItemHistory from "features/account/components/MoralisItemHistory";
import SpinnerCenter from "features/account/components/SpinnerCenter";

const ModalHistory = ({
  closeModal,
  isOpen,
  account,
  handlePresentMoralis,
  moralisHistoryData,
  historyData,
  handlePresent,
  isAstarNetwork,
  popUpSliderLoading,
  getNext10
}: IPropsModalHistory) => {
  const { breakpoint } = useTheme();
  let lastIdx = 0;

  return (
    <>
      {breakpoint < 1024 ? (
        <>
          <IonModal
            onDidDismiss={() => closeModal()}
            isOpen={isOpen}
            trigger="open-modal"
            initialBreakpoint={1}
            breakpoints={[0.75, 1]}
            id="open-modal">
            <IonContent className="overflow-y-auto">
              <IonList lines="full" className="p-4">
                <HeaderHistory />
                <>
                  {popUpSliderLoading ? (
                    <SpinnerCenter />
                  ) : (
                    <>
                      {isAstarNetwork ? (
                        <>
                          {historyData && historyData?.length > 0 ? (
                            historyData?.map((thisHistory, index) => (
                              <ItemHistory
                                key={`history_detail_${index}`}
                                account={account}
                                handlePresent={() => handlePresent(thisHistory, true)}
                                item={thisHistory}
                              />
                            ))
                          ) : (
                            <EmptyTransaction />
                          )}
                        </>
                      ) : (
                        <>
                          {moralisHistoryData && moralisHistoryData?.length > 0 ? (
                            moralisHistoryData?.map((thisHistory, index) => {
                              // setLastIndex(index);
                              lastIdx = index;
                              return (
                                <MoralisItemHistory
                                  key={`history_detail_${index}`}
                                  account={account}
                                  handlePresentMoralis={() => handlePresentMoralis(thisHistory, true)}
                                  item={thisHistory}
                                />
                              );
                            })
                          ) : (
                            <EmptyTransaction />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              </IonList>
              <IonInfiniteScroll
                onIonInfinite={(ev) => {
                  getNext10(lastIdx, ev);
                }}>
                <IonInfiniteScrollContent></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </IonContent>
          </IonModal>
        </>
      ) : (
        <IonModal onDidDismiss={() => closeModal()} isOpen={isOpen} trigger="open-modal" id="open-modal">
          <IonContent className="overflow-y-auto">
            <IonList lines="full" className="p-4 overflow-y-auto">
              <HeaderHistory />
              <>
                {popUpSliderLoading ? (
                  <SpinnerCenter />
                ) : (
                  <>
                    {isAstarNetwork ? (
                      <>
                        {historyData && historyData?.length > 0 ? (
                          historyData?.map((thisHistory, index) => (
                            <ItemHistory
                              key={`history_detail_${index}`}
                              account={account}
                              handlePresent={() => handlePresent(thisHistory, true)}
                              item={thisHistory}
                            />
                          ))
                        ) : (
                          <EmptyTransaction />
                        )}
                      </>
                    ) : (
                      <>
                        {moralisHistoryData && moralisHistoryData?.length > 0 ? (
                          moralisHistoryData?.map((thisHistory, index) => {
                            // setLastIndex(index);
                            lastIdx = index;
                            return (
                              <MoralisItemHistory
                                key={`history_detail_${index}`}
                                account={account}
                                handlePresentMoralis={() => handlePresentMoralis(thisHistory, true)}
                                item={thisHistory}
                              />
                            );
                          })
                        ) : (
                          <EmptyTransaction />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </IonList>
            <IonInfiniteScroll
              onIonInfinite={(ev) => {
                getNext10(lastIdx, ev);
              }}>
              <IonInfiniteScrollContent></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </IonContent>
        </IonModal>
      )}
    </>
  );
};

export default ModalHistory;
