// @ts-nocheck
import {
  getPlatforms,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  useIonRouter
} from "@ionic/react";
import { ethers } from "ethers";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import { Contact } from "interfaces/actions/IContact";
import { scanSharp, searchOutline } from "ionicons/icons";
import Scanner from "features/application/components/scanner";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchSelectedContact } from "shared/actions/contactAction";
import { scannerAction } from "shared/actions/scannerAction";
import { fetchTransferAddress } from "shared/actions/transferAction";
import HeaderMainMb from "components/header/mb";
import HeaderMainPc from "components/header/pc";
import "./style.css";

const TransferPage: React.FC = () => {
  const history = useIonRouter();
  const [list, setList] = useState<any>([]);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [openScan, setOpenScan] = useState(false);
  const [platforms, setPlatforms] = useState("web");
  // @dwiyan -> is this unused?
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scanResult, setScanResult] = useState<string>("");
  const [inputVal, setInputVal] = useState<string>("");
  const [error, setError] = useState("");

  const contact = useAppSelector((state) => state.contact.contacts);
  const scanners = useAppSelector((state) => state.scanner);
  const dispatch = useAppDispatch();
  const { breakpoint } = useTheme();
  const { t } = useTranslation();

  const searchContact = async (e: any) => {
    setIsSearch(!isSearch);
    if (typeof e === "string") {
      const newList = contact;
      const filtered = newList.filter((el: Contact) => Object.values(el).some((value) => value.toLowerCase().includes(e.toLowerCase())));
      filtered.length > 0 ? setList(filtered) : setList([{ address: e, name: e }]);
      e === "" && setList(contact);
    } else {
      setInputVal(e.target.value);
      dispatch(scannerAction.removeFromScanner());
      const newList = contact;
      const filtered = newList.filter((el: Contact) =>
        Object.values(el).some((value) => value.toLowerCase().includes(e.target.value.toLowerCase()))
      );
      filtered.length > 0 ? setList(filtered) : setList([{ address: e.target.value, name: e.target.value }]);
      dispatch(fetchSelectedContact({ address: e.target.value, name: e.target.value, avatar: "" }));
      e.target.value === "" && setList(contact);
    }
  };

  const selectContact = (item: Contact) => {
    const cekEthAddress = ethers.utils.isAddress(item.address) === true;
    if (item.address.substring(0, 2) !== "0x") {
      setError(`${t("notification.first2Character")}`);
    } else if (cekEthAddress === false) {
      setError(`${t("notification.invalidAddress")}`);
    } else {
      dispatch(scannerAction.removeFromScanner());
      dispatch(fetchTransferAddress(item.address));
      dispatch(fetchSelectedContact(item));

      const getchain = localStorage.getItem("chain");
      if (getchain) {
        const chain = JSON.parse(getchain);
        if (chain.type === "cryptocurrency") {
          history.push(breakpoint < 1024 ? `/transfer/confirm-token` : `/wallet/tf/assets`);
        } else {
          history.push(breakpoint < 1024 ? `/transfer/confirm` : `/wallet/tf/assets`);
        }
      } else {
        history.push(breakpoint < 1024 ? `/transfer/assets` : `/wallet/tf/assets`);
      }
    }
  };

  const openQrScanner = () => {
    // const platform = Capacitor.getPlatform();
    const platform = getPlatforms();
    if (platform[0] === "tablet" || platform[0] === "desktop") {
      setPlatforms("web");
    } else {
      setPlatforms("mobile");
    }
    setOpenScan(true);
  };

  useEffect(() => {
    setList(contact);
  }, [contact]);

  useEffect(() => {
    scanners.scanResult &&
      searchContact(
        scanners.scanResult
          .replace("ethereum:", "")
          .replace("@137", "")
          .replace("@80002", "")
          .replace("@1", "")
          .replace("@5", "")
          .replace("@592", "")
          .replace("@56", "")
          .replace("@97", "")
      );
    scanners.scanResult &&
      setInputVal(
        scanners.scanResult
          .replace("ethereum:", "")
          .replace("@137", "")
          .replace("@80002", "")
          .replace("@1", "")
          .replace("@5", "")
          .replace("@592", "")
          .replace("@56", "")
          .replace("@97", "")
      );
  }, [openScan]);

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 5000);
  }, [error]);

  if (breakpoint < 1024) {
    return (
      <IonPage>
        <Scanner
          isScanContact={true}
          setScanResult={setScanResult}
          platform={platforms}
          openScanner={openScan}
          closeScanner={setOpenScan}
        />
        <HeaderMainMb title={t("navbar.transfer")} link="/wallet/home" />

        <IonContent className="ion-padding" fullscreen>
          <div className="flex flex-row justify-between items-center py-1 px-4 rounded-custom border border-slate-300 mb-1.5">
            <IonIcon icon={searchOutline} color="dark" />
            <IonInput
              value={inputVal}
              placeholder={`${t("transfer.transferToPlaceholder")}`}
              onIonChange={searchContact}
              clearInput={true}
              clearOnEdit
            />
            <IonIcon icon={scanSharp} color="dark" onClick={openQrScanner} />
          </div>
          <p className="text-red-500">{error}</p>
          <IonLabel className="font-bold"> {t("transfer.contact")}</IonLabel>
          <IonList lines="full">
            {list.map((el: Contact, i: number) => {
              return (
                <IonItem
                  className="p-0"
                  key={i}
                  onClick={() => {
                    selectContact(el);
                  }}>
                  <IonLabel className="font-bold">
                    <h2>{el.name}</h2>
                    <p className="!text-xs">{el.address}</p>
                  </IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        </IonContent>
      </IonPage>
    );
  } else {
    return (
      <IonPage>
        <Scanner
          isScanContact={true}
          setScanResult={setScanResult}
          platform={platforms}
          openScanner={openScan}
          closeScanner={setOpenScan}
        />
        <HeaderMainPc title={t("navbar.transfer")} link="/wallet/home" />

        <IonContent className="no-padding bg-base" fullscreen>
          <IonGrid>
            <IonRow>
              <IonCol sizeLg="6" sizeXl="6" sizeMd="4" sizeSm="4" sizeXs="12" className="p-0">
                <IonCard className="max-h-[500px] overflow-y-auto">
                  <IonCardContent>
                    <div className="flex flex-row justify-between items-center py-1 px-4 rounded-custom border border-slate-300 mb-1.5">
                      <IonIcon icon={searchOutline} className="pe-4" color="dark" />
                      <IonInput
                        placeholder={`${t("transfer.transferToPlaceholder")}`}
                        onIonChange={searchContact}
                        clearInput
                        inputmode="text"
                        clearOnEdit
                      />
                      <IonIcon icon={scanSharp} color="dark" onClick={openQrScanner} />
                    </div>
                    <p className="text-red-500">{error}</p>
                    <IonLabel className="font-bold"> {t("transfer.contact")}</IonLabel>
                    <IonList lines="full">
                      {list.map((el: Contact, i: number) => {
                        return (
                          <IonItem
                            className="p-0"
                            key={i}
                            onClick={() => {
                              selectContact(el);
                            }}>
                            <IonLabel className="font-bold">
                              <h2>{el.name}</h2>
                              <p className="!text-xs">{el.address}</p>
                            </IonLabel>
                          </IonItem>
                        );
                      })}
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};

export default TransferPage;
