/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  concatSig,
  decrypt,
  getEncryptionPublicKey,
  normalize,
  personalSign,
  signTypedData,
  SignTypedDataVersion
} from "@metamask/eth-sig-util";
import { bufferToHex, ecsign, stripHexPrefix } from "ethereumjs-util";
import Wallet from "ethereumjs-wallet";
import { EventEmitter } from "events";
import log from "loglevel";

const type = "Torus Keyring";

export default class KeyringController extends EventEmitter {
  type: string;
  wallets: Wallet[];
  constructor(options?: any) {
    super();
    this.type = type;
    this.wallets = [];
    this.deserialize(options)
      .then(() => {
        log.info("wallet initialised");
      })
      .catch((error) => log.error("unable to deserialize", error));
  }

  async serialize() {
    const keys = this.wallets.map((x) => this.generatePrivKey(x));
    return keys;
  }

  generatePrivKey(wallet: any) {
    return wallet.getPrivateKey().toString("hex");
  }

  generateWallet(privateKey: any) {
    const stripped = stripHexPrefix(privateKey);
    const buffer = Buffer.from(stripped, "hex");
    const wallet = Wallet.fromPrivateKey(buffer);
    return wallet;
  }

  async deserialize(privateKeys = [] as string[]) {
    const existingKeys = this.wallets.map((x) => this.generatePrivKey(x));
    this.wallets = [...new Set([...existingKeys, ...privateKeys])].map((x) => this.generateWallet(x));
  }

  async addAccount(privKey: any) {
    for (let index = 0; index < this.wallets.length; index += 1) {
      const element = this.generatePrivKey(this.wallets[index]);
      if (element === privKey) throw new Error("Already added");
    }
    // @ts-ignore
    this.wallets.push(this.generateWallet(privKey) as any);
  }

  // Not using
  async addRandomAccounts(n = 1) {
    const newWallets = [];
    for (let i = 0; i < n; i += 1) {
      newWallets.push(Wallet.generate());
    }
    this.wallets = [...this.wallets, ...newWallets] as any;
    const hexWallets = newWallets.map((w) => bufferToHex(w.getAddress()));
    return hexWallets;
  }

  // Not using
  async getAccounts() {
    // @ts-ignore
    return this.wallets.map((w) => bufferToHex(w.getAddress() as any));
  }

  // tx is an instance of the ethereumjs-transaction class.
  // @ts-ignore
  async signTransaction(tx, address) {
    const wallet = this._getWalletForAccount(address);
    // @ts-ignore
    const privKey = wallet.getPrivateKey();
    const signedTx = tx.sign(privKey);
    // Newer versions of Ethereumjs-tx are immutable and return a new tx object
    return signedTx === undefined ? tx : signedTx;
  }

  async signMessage(address: string, data: string) {
    const wallet = this._getWalletForAccount(address);
    const message = stripHexPrefix(data);
    // @ts-ignore
    const privKey = wallet.getPrivateKey();
    const messageSig = ecsign(Buffer.from(message, "hex"), privKey);
    // @ts-ignore
    const rawMessageSig = concatSig(messageSig.v, messageSig.r, messageSig.s);
    return rawMessageSig;
  }

  // For personal_sign, we need to prefix the message:
  async signPersonalMessage(address: string, messageHex: string) {
    const wallet = this._getWalletForAccount(address);
    // @ts-ignore
    const privKey = stripHexPrefix(wallet.getPrivateKeyString());
    const privKeyBuffer = Buffer.from(privKey, "hex");
    const sig = personalSign({ privateKey: privKeyBuffer, data: messageHex });
    return sig;
  }

  // personal_signTypedData, signs data along with the schema
  // @ts-ignore
  async signTypedData(withAccount, typedData, version = "V1") {
    const wallet = this._getWalletForAccount(withAccount);
    // @ts-ignore
    const privKey = wallet.getPrivateKey();
    // @ts-ignore
    return signTypedData({ privateKey: privKey, data: typedData, version: version as SignTypedDataVersion });
  }

  // not using
  // exportAccount should return a hex-encoded private key:
  async exportAccount(address: string) {
    const wallet = this._getWalletForAccount(address);
    return wallet.getPrivateKey().toString("hex");
  }

  // not using
  // @ts-ignore
  removeAccount(address) {
    // @ts-ignore
    if (!this.wallets.map((w) => w.getAddressString().toLowerCase()).includes(address.toLowerCase())) {
      throw new Error(`Address ${address} not found in this keyring`);
    }
    // @ts-ignore
    this.wallets = this.wallets.filter((w) => w.getAddressString().toLowerCase() !== address.toLowerCase());
  }

  signEncryptionPublicKey(address: string) {
    const wallet = this._getWalletForAccount(address);
    const privKey = wallet.getPrivateKey();
    return getEncryptionPublicKey(privKey as any);
  }

  // @ts-ignore
  decryptMessage(data, address) {
    const wallet = this._getWalletForAccount(address);
    const privKey = wallet.getPrivateKey();
    return decrypt({ encryptedData: data, privateKey: privKey as any });
  }

  /* PRIVATE METHODS */
  // @ts-ignore
  _getWalletForAccount(account: string) {
    const address = normalize(account);
    // @ts-ignore
    const wallet = this.wallets.find((w) => w.getAddressString() === address);
    if (!wallet) throw new Error("Torus Keyring - Unable to find matching address.");
    return wallet;
  }
}
// @ts-ignore
KeyringController.type = type;
