import { ApolloClient, ApolloLink, concat, createHttpLink, from, InMemoryCache } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import log from "loglevel";

import config from "./openloginconfig";

let authToken = "";

export function setAuthToken(token: string) {
  authToken = token;
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = authToken;
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null
    }
  });
  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (networkError) log.error(`[Network error]`, networkError);
});

const requestTimeLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    return data;
  });
});

const httpLink = createHttpLink({
  uri: config.openloginBackend,
  credentials: "include",
  fetch
});

// Cache implementation
// currently we are not using any real time data query so default cache conf is fine.
// if any real time data query is added in future, make sure to amend cache policy to get real time data.
const cache = new InMemoryCache();

const client = new ApolloClient({
  link: from([errorLink, requestTimeLink, concat(authMiddleware, httpLink)]),
  cache
});

export { client };
