/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import { ModalQrType } from "interfaces/ui/IMenu";
import { useEffect, useState } from "react";
import { ModalQrMb } from "features/account/components/ModalQr/mb";
import { ModalQrPc } from "features/account/components/ModalQr/pc";

const ModalQr = ({ modal, openModalQRCode, setOpenModalQRCode }: ModalQrType) => {
  const { breakpoint } = useTheme();
  const { selectedAddress: account } = useAppSelector((state) => state.wallet);
  const [openQr, setOpenQr] = useState(false);

  useEffect(() => {
    let a = 0;
    if (openQr) {
      a = window.setTimeout(function () {
        setOpenQr(false);
      }, 500);
    } else {
      clearTimeout(a);
    }
  }, [openQr]);

  return (
    <>
      {breakpoint < 1024 ? (
        <ModalQrMb
          modal={modal}
          openModalQRCode={openModalQRCode}
          setOpenModalQRCode={setOpenModalQRCode}
          setOpenQr={setOpenQr}
          openQr={openQr}
          account={account}
        />
      ) : (
        <ModalQrPc
          modal={modal}
          openModalQRCode={openModalQRCode}
          setOpenModalQRCode={setOpenModalQRCode}
          setOpenQr={setOpenQr}
          openQr={openQr}
          account={account}
        />
      )}
    </>
  );
};

export default ModalQr;
