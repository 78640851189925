import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AuthServiceInfo, KeyInfo, UserInfo, UserModule, UserWalletData, WalletKeyInfo } from "../../interfaces/actions/IOlUserModule";
import { LOGIN } from "@toruslabs/customauth";
import { setAuthToken } from "shared/utils/apollo";

const initOlUserModuleState: UserModule = {
  authServiceInfo: {
    sub: "",
    accessToken: ""
  },
  authToken: "",
  challenge: "",
  currentDappClientId: "",
  keyInfo: {
    typeOfUser: "v1",
    privateKey: "",
    pubKey: {
      pub_key_X: "",
      pub_key_Y: ""
    },
    publicAddress: "",
    metadataNonce: ""
  },
  persistedUserInfo: {},
  userWalletData: {
    isRegistered: false,
    walletVersion: "1.0.0"
  },
  walletKeyInfo: {
    typeOfUser: "v1",
    privateKey: "",
    pubKey: {
      pub_key_X: "",
      pub_key_Y: ""
    },
    publicAddress: "",
    metadataNonce: ""
  },
  userInfo: {
    email: "",
    name: "",
    profileImage: "",
    verifier: "",
    aggregateVerifier: "",
    verifierId: "",
    typeOfLogin: LOGIN.GOOGLE
  },
  clientTimeOffset: 0,
  alwaysSkip: false,
  idToken: "",
  accessToken: ""
};
const olUserModuleSlice = createSlice({
  name: "olUserModule",
  initialState: initOlUserModuleState,
  reducers: {
    setAuthServiceUserInfo(state, action: PayloadAction<AuthServiceInfo>) {
      state.authServiceInfo.accessToken = action.payload.accessToken;
      state.authServiceInfo.sub = action.payload.sub;
    },
    setUserWalletData(state, action: PayloadAction<UserWalletData>) {
      state.userWalletData.isRegistered = action.payload.isRegistered;
      state.userWalletData.walletVersion = action.payload.walletVersion;
    },
    setClientTimeOffset(state, action: PayloadAction<{ offset: number }>) {
      state.clientTimeOffset = action.payload.offset;
    },
    setKeyInfo(state, action: PayloadAction<KeyInfo>) {
      state.keyInfo = action.payload;
    },
    setWalletKeyInfo(state, action: PayloadAction<WalletKeyInfo>) {
      state.walletKeyInfo = action.payload;
    },
    setUserInfo(state, action: PayloadAction<UserInfo>) {
      state.userInfo = action.payload;
    },
    setCurrentClientId(state, action: PayloadAction<{ currentDappClientId: string }>) {
      state.currentDappClientId = action.payload.currentDappClientId;
    },
    setAuthToken(state, action: PayloadAction<{ authToken: string }>) {
      state.authToken = action.payload.authToken;
      setAuthToken(action.payload.authToken);
    },
    setChallenge(state, action: PayloadAction<{ challange: string }>) {
      state.challenge = action.payload.challange;
    },
    resetCurrentClientId(state, action: PayloadAction<{ value: string }>) {
      if (action.payload.value === state.currentDappClientId) state.currentDappClientId = "";
    },
    setAlwaysSkip(state, action: PayloadAction<{ alwaysSkip: boolean }>) {
      state.alwaysSkip = action.payload.alwaysSkip;
    },
    setPersistedUserInfo(state, action: PayloadAction<Record<string, any>>) {
      state.persistedUserInfo = { ...state.persistedUserInfo, ...action.payload.info };
    },
    logout(state) {
      state.userInfo = {
        email: "",
        name: "",
        profileImage: "",
        verifier: "",
        aggregateVerifier: "",
        verifierId: "",
        typeOfLogin: LOGIN.GOOGLE
      };
      state.keyInfo = {
        typeOfUser: "v1",
        privateKey: "",
        pubKey: {
          pub_key_X: "",
          pub_key_Y: ""
        },
        publicAddress: "",
        metadataNonce: ""
      };
      state.walletKeyInfo = {
        typeOfUser: "v1",
        privateKey: "",
        pubKey: {
          pub_key_X: "",
          pub_key_Y: ""
        },
        publicAddress: "",
        metadataNonce: ""
      };
      state.currentDappClientId = "";
      state.authToken = "";
      state.persistedUserInfo = {};
      state.challenge = "";
      state.authServiceInfo = {
        accessToken: "",
        sub: ""
      };
      state.userWalletData = {
        isRegistered: false,
        walletVersion: "1.0.0"
      };
    }
  }
});

export default olUserModuleSlice;
