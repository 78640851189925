/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import contracts from "@metamask/contract-metadata";

export default {
  ...contracts,
  "0xC4C2614E694cF534D407Ee49F8E44D125E4681c4": {
    name: "Chain Games",
    logo: "chain.png",
    erc20: true,
    symbol: "CHAIN",
    decimals: 18
  }
};
