import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialBalanceOrder } from "shared/config/chains";

import { BalanceItems, ITokenArray } from "../../interfaces/actions/IToken";

export const initNativeEtherAndMatic: BalanceItems = {
  balance: "",
  balance_24h: "",
  contract_address: "string",
  contract_decimals: 0,
  contract_name: "",
  contract_ticker_symbol: "",
  last_transferred_at: "",
  logo_url: "",
  native_token: false,
  nft_data: [],
  quote: "",
  quote_24h: "",
  quote_rate: "",
  quote_rate_24h: "",
  supports_erc: [],
  type: "",
  chain_id: ""
};
const initBalanceState: ITokenArray = {
  token: [],
  coin: initialBalanceOrder,
  nft: [],
  noAsset: false,
  etherInit: initNativeEtherAndMatic,
  maticInit: initNativeEtherAndMatic,
  isLoading: true,
  showTestnet: localStorage.getItem("showTestnet") ? (localStorage.getItem("showTestnet") == "true" ? true : false) : false,
  index: 0,
  length: 0
};
const balanceSlice = createSlice({
  name: "balance",
  initialState: initBalanceState,
  reducers: {
    setTokenERC20(state, action: PayloadAction<BalanceItems[]>) {
      state.token = action.payload;
    },
    setCoin(state, action: PayloadAction<BalanceItems[]>) {
      state.coin = action.payload;
    },
    setNFT(state, action: PayloadAction<BalanceItems[]>) {
      state.nft = action.payload;
    },
    setNoAsset(state, action: PayloadAction<boolean>) {
      state.noAsset = action.payload;
    },
    setEtherInit(state, action: PayloadAction<BalanceItems>) {
      state.etherInit = action.payload;
    },
    setMaticInit(state, action: PayloadAction<BalanceItems>) {
      state.maticInit = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    reset(state) {
      state.token = [];
      state.nft = [];
      state.coin = initialBalanceOrder;
      state.noAsset = false;
      state.etherInit = initNativeEtherAndMatic;
      state.maticInit = initNativeEtherAndMatic;
    },
    setShowTestnet(state, action: PayloadAction<boolean>) {
      state.showTestnet = action.payload;
    },
    setIndex(state, action: PayloadAction<number>) {
      state.index = action.payload;
    },
    setLength(state, action: PayloadAction<number>) {
      state.length = action.payload;
    }
  }
});

export default balanceSlice;
