import torus from "libs/TorusExtended";
import { useEffect, useState } from "react";
import { logout } from "shared/actions/walletAction";
import Web3 from "web3";
import { useAppDispatch, useAppSelector } from "./useRedux";

export default function useWeb3() {
  const dispatch = useAppDispatch();
  const { wallet: _wallet } = useAppSelector((state) => state.wallet);

  const [web3, setWeb3] = useState<Web3 | undefined>();
  const [status, setStatus] = useState<"idle" | "loading" | "success" | "error">("idle");
  const [accounts, setAccounts] = useState<string[] | []>([""]);

  useEffect(() => {
    if (torus.web3) {
      const privKey = localStorage.getItem("privateKey") ?? "";
      if (privKey === "") {
        dispatch(logout());
        return;
      }
      setWeb3(torus.web3);
      const _privKey = `0x${privKey}`;
      const pAccount = torus.web3?.eth.accounts.privateKeyToAccount(_privKey);
      torus.web3?.eth.accounts.wallet.add(pAccount as any);
    }
  }, [torus.web3]);

  useEffect(() => {
    const getAccounts = async () => {
      try {
        if (localStorage.getItem("provider") && web3) {
          web3.setProvider(JSON.parse(localStorage.getItem("provider") as any));
          const _accs = await web3?.eth.getAccounts();
          if (_accs) {
            setAccounts(_accs);
            setStatus("success");
          }
        }
      } catch (error) {
        console.error(`error while get accounts: `, error);
        setStatus("error");
      }
    };
    if (web3) {
      getAccounts();
    }
  }, [web3]);

  return {
    status,
    web3,
    accounts
  };
}
