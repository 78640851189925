// @ts-nocheck
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */

import { IonButton, IonContent, IonIcon, IonPage } from "@ionic/react";
import { useRef, useState } from "react";
import { chevronBackOutline } from "ionicons/icons";
import "./style.css";
import { AUTH_FACTOR, TKEY_CONFIRM_BACKUP_OP } from "shared/utils/openloginenums";
import { inputBackupShare } from "shared/actions/olTkeyModuleAction";
import { UpdateLoginRecord, olLoginPerfModuleActions } from "shared/actions/olLoginPerfModuleAction";
import { getJoinedKey } from "shared/utils/openloginutils";
import { setPreferencesAndRedirect } from "shared/actions/olDappModuleAction";
import { store } from "shared/store";
// import CustomAuth from "@toruslabs/customauth";
import { useLocation } from "react-router";
import { PinInputActions, usePinInput } from "components/pin/PinInput";
import { useTranslation } from "react-i18next";

const BackupPhrasePage: React.FC = () => {
  const [value, setValue] = useState<any>("");
  const search = useLocation().search;
  const data = new URLSearchParams(search);
  const [focus, setFocus] = useState<boolean>(false);
  const [indexErr, setIndexErr] = useState<any>([]);
  const [loaded, setLoaded] = useState<boolean>(true);
  const [confirmingShareMnemonic, setConfirmingShareMnemonic] = useState<boolean>(false);
  const [values, setValues] = useState(Array(24).fill(""));
  const actionRef = useRef<PinInputActions>(null);
  const ref = useRef<any>();
  const { t } = useTranslation();
  const { fields } = usePinInput({
    values,
    onChange: (values) => {
      if (values.includes("")) {
        // Setting the error.
        setFocus(false);
        actionRef.current?.focus();
      }
      setValues(values);
    },
    onComplete(value) {
      setValue(value);
      setFocus(true);
    },
    type: "alphanumeric",
    autoFocus: true,
    actionRef,
    length: 15
  });

  const handleClearClick = () => {
    setValues(Array(24).fill(""));
    setIndexErr([]);
    actionRef.current?.focus();
  };

  const confirm = async () => {
    setConfirmingShareMnemonic(true);
    await confirmBackupPhrase(value.split(" ").length > 1 ? value : values.join(" "));
  };

  const confirmBackupPhrase = async (shareMnemonic: string) => {
    const operationName = TKEY_CONFIRM_BACKUP_OP;
    window.performance.mark(`${operationName}_start`);
    try {
      const keys = await inputBackupShare(shareMnemonic.toLowerCase());
      await UpdateLoginRecord({
        isLoginCompleted:
          store.getState().olDeviceModule.onboardingMap[
            getJoinedKey(store.getState().olUserModule.userInfo.aggregateVerifier, store.getState().olUserModule.userInfo.verifierId)
          ] || store.getState().olTKeyModule.keyMode !== "v1",
        mobileOrigin: store.getState().olAllDappModule.dappModules[store.getState().olUserModule.currentDappClientId].mobileOrigin
      });
      store.dispatch(olLoginPerfModuleActions.addAuthFactor(AUTH_FACTOR.BACKUP_PHRASE));
      await onboardingOrRedirect(keys);
    } catch (error: unknown) {
      const numbers = Array.from({ length: 24 }, (_, index) => index);
      setIndexErr(numbers);
      setFocus(false);
    } finally {
      setConfirmingShareMnemonic(false);
    }
  };

  const onboardingOrRedirect = async (
    keys: {
      privKey: string;
    }[]
  ): Promise<void> => {
    redirect(keys);
  };

  const redirect = (
    keys: {
      privKey: string;
    }[]
  ) => {
    // if (data.get("customAuthInstanceId")) customAuth.storageHelper.clearLoginDetailsStorage(data.get("customAuthInstanceId") || "");
    const isUsingDirect = localStorage.getItem("isUsingDirect");
    const dappRedirectUri = localStorage.getItem("dappRedirectUri")
      ? decodeURIComponent(localStorage.getItem("dappRedirectUri") as string)
      : "";

    console.log(`preparing for redirect`);
    setPreferencesAndRedirect({
      popupWindow: Boolean(data.get("popupWindow")) || false,
      pid: data.get("pid") || "",
      embedWlRedirectUrl:
        store.getState().olAllDappModule.dappModules[store.getState().olUserModule.currentDappClientId].embedWlRedirectUrl,
      redirectUrl: store.getState().olAllDappModule.dappModules[store.getState().olUserModule.currentDappClientId].redirectUrl,
      dappRedirectUri:
        dappRedirectUri ||
        store.getState().olAllDappModule.dappModules[store.getState().olUserModule.currentDappClientId]?.usingDirectConfig?.directUrl,
      isUsingDirect: isUsingDirect
        ? isUsingDirect === "true"
        : store.getState().olAllDappModule.dappModules[store.getState().olUserModule.currentDappClientId]?.directConfig?.usingDirect,
      result: {
        tKey: keys[0].privKey,
        oAuthPrivateKey: store.getState().olUserModule.keyInfo.privateKey,
        walletKey: store.getState().olTKeyModule.keyMode === "v1" ? store.getState().olUserModule.walletKeyInfo.privateKey : keys[0].privKey
      },
      verifier: store.getState().olUserModule.userInfo.aggregateVerifier,
      verifierId: store.getState().olUserModule.userInfo.verifierId,
      disableAlwaysSkip: Boolean(data.get("disableAlwaysSkip")) || false
    });
  };

  const goBack = () => {
    setLoaded(false);

    setTimeout(() => {
      history.go(-1);
      setLoaded(true);
    }, 500);
  };

  const checkError = (a: any, indeX: number) => {
    const array = indexErr;
    const regex = /^[^\s][a-zA-Z\s]+$/;
    const index = array.indexOf(indeX);
    if (a && (a.target.value.length === 0 || !regex.test(a.target.value))) {
      array.push(indeX);
      setIndexErr([...new Set(array)]);
    } else if (index > -1) {
      array.splice(index, 1);
      setIndexErr([...new Set(array)]); // 2nd parameter means remove one item only
    }
  };

  return (
    <IonPage
      className={`sm:w-[500px]  w-screen  h-screen sm:h-screen  py-10 pb-4 px-4 flex justify-start sm:justify-center xs:items-center items-start  flex-col sm:flex-row slide-${
        loaded ? "in" : "out"
      }`}
      style={{ margin: "0 auto" }}>
      <IonContent className="w-full  align-middle sm:w-1/4">
        <div className="items-center gap-1 ml-[-10px]">
          <IonButton mode="ios" fill="clear" className="text-black items-center" onClick={goBack}>
            <IonIcon slot="start" mode="ios" icon={chevronBackOutline}></IonIcon>
            <p className="headline self-start mt-2 font-medium"> {t("pin_input.backupPrase_1")}</p>
          </IonButton>
          <p className="self-start   font-poppins font-normal ml-[45px]"> {t("pin_input.backupPrase_2")}</p>
        </div>

        <div className="lg:flex-col lg:flex">
          <div className="pin-input-2 grid grid-cols-2 gap-3" ref={ref}>
            {fields.map((fieldProps, index) => (
              <input
                key={index}
                className={`pin-input__field  h-[40px] text-center text-sm ${focus ? "border-2" : "border"}  `}
                {...fieldProps}
                style={{ borderWidth: focus ? 2 : "", borderColor: focus ? "#4d4082" : indexErr.includes(index) ? "#fb4a61" : "" }}
                maxLength={15}
                minLength={15}
                placeholder={(index + 1).toString()}
                onBlur={(e) => checkError(e, index)}
              />
            ))}
          </div>

          <div className="px-2">
            <button
              className="bg-[#214999] h-[48px] w-full rounded-3xl text-white mt-2 font-bold shadow  px-4 font-poppins"
              onClick={confirm}
              disabled={confirmingShareMnemonic}>
              {confirmingShareMnemonic ? (
                <div className="flex align-items-center ion-justify-content-center">
                  <svg
                    className="inline mr-2 w-8 h-8 text-gray-200 animate-spin fill-[#4B68AE]"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only" style={{ color: "#ffffff" }}>
                    Loading...
                  </span>
                </div>
              ) : (
                t("pin_input.confirm")
              )}
            </button>

            <button
              className="bg-[#e4e4e4]  h-[48px] w-full rounded-3xl shadow  text-[#214999] border-1 my-4  font-bold px-4 font-poppins"
              onClick={handleClearClick}>
              {t("pin_input.backupPrase_3")}
            </button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default BackupPhrasePage;
