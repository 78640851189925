const API_BASE_URL = "https://deep-index.moralis.io/api/v2.2/";

export const Header = () => {
  return {
    headers: {
      Accept: "application/json",
      "X-API-Key": process.env.REACT_APP_MORALIS_WEB3_API_KEY
    }
  };
};

export { API_BASE_URL };
