// @ts-nocheck
import { IonContent, IonPage } from "@ionic/react";
import HeaderMainPc from "components/header/pc";

const DetailNetworkMainPc: React.FC<any> = () => {
  return (
    <IonPage>
      <HeaderMainPc title="" link="/wallet/home" />
      <IonContent className="no-padding bg-base" fullscreen></IonContent>
    </IonPage>
  );
};

export default DetailNetworkMainPc;
