import CustomAuth, { LOGIN_TYPE, SubVerifierDetails } from "@toruslabs/customauth";
import { getPublic, sign, verify } from "@toruslabs/eccrypto";
import { get, post } from "@toruslabs/http-helpers";
import { decryptData, encryptData } from "@toruslabs/metadata-helpers";
import {
  JRPCEngineEndCallback,
  JRPCEngineNextCallback,
  JRPCMiddleware,
  JRPCRequest,
  JRPCResponse,
  SerializableError
} from "@toruslabs/openlogin-jrpc";
import { ExtraLoginOptions } from "@toruslabs/openlogin-utils";
import { ENGINE_MAP } from "bowser";
import log from "loglevel";
import config from "./openloginconfig";
import configs from "shared/config";
import { generateCustomAuthParams, generateWebAuthnCustomAuthParams } from "./openloginutils";
import { LOCAL_STORAGE_KEY, OPENLOGIN_METHOD, WEBAUTHN_LOGIN_PROVIDER } from "./openloginenums";
import {
  keccak,
  keccak256hash,
  LoginConfig,
  LoginConfigItem,
  MfaLevelType,
  OriginData,
  TouchIDPreferences,
  UserData,
  WebAuthnFlow
} from "./openlogininterface";
import { getJoinedKey, isWebAuthnAvailable, redirectToDapp } from "./openloginutils";

import Bowser from "bowser";
import { store } from "shared/store";
import { olDappModuleAction } from "shared/actions/olDappModuleAction";
import { olUserModuleAction } from "shared/actions/olUserModuleAction";
import { olLoginPerfModuleActions } from "shared/actions/olLoginPerfModuleAction";
import { olPidModuleAction } from "shared/actions/olPidModuleAction";
import { isCustomVerifier, loginConfig, logout, olAllDappModuleAction, registerDappModule } from "shared/actions/olAllDappModuleAction";

const browserInfo = Bowser.parse(navigator.userAgent);

/*function detectMob() {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}*/

let customAuth: CustomAuth;

const initSdkProm = new Promise((resolve, reject) => {
  customAuth = new CustomAuth({
    baseUrl: window.location.origin,
    redirectPathName: "auth",
    uxMode: "redirect",
    network: config.torusNetwork,
    enableLogging: config.logLevel !== "error",
    locationReplaceOnRedirect: true,

    // v2
    metadataUrl: config.metadataHost,
    enableOneKey: true,
    storageServerUrl: config.storageServerUrl,
    web3AuthClientId: config.openloginDappModuleKey as string
  });
  customAuth.init({ skipSw: true, skipInit: true }).then(resolve).catch(reject);
});

// #region types

type PIDSetParamRequest = Record<string, unknown> & { pid: string; data: Record<string, unknown> };

type ValidatedJRPCRequest<T> = {
  clientId: string;
  pid?: string;
  sessionId?: string;
  user?: string;
  origin?: string;
  redirect?: boolean;
  popupWindow?: boolean;
  loginConfig: LoginConfig;
  _sessionNamespace?: string;
  localProvider?: boolean;
} & Required<JRPCRequest<T>>;

// #endregion types

// #region method middlewares

export const openlogin_check_3PC_support = (
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): void => {
  let thirdPartyCookieSupport = true;
  // brave
  if ((navigator as unknown as { brave: boolean })?.brave) {
    thirdPartyCookieSupport = false;
  }
  // All webkit & gecko engine instances use itp (intelligent tracking prevention -
  // https://webkit.org/tracking-prevention/#intelligent-tracking-prevention-itp)
  if (browserInfo.engine.name === ENGINE_MAP.WebKit || browserInfo.engine.name === ENGINE_MAP.Gecko) {
    thirdPartyCookieSupport = false;
  }
  if (!config.isStorageAvailable[LOCAL_STORAGE_KEY]) {
    thirdPartyCookieSupport = false;
  }
  res.result = { support3PC: thirdPartyCookieSupport };
  end();
};

export const openlogin_get_data = async (
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): Promise<void> => {
  // validations
  try {
    if (config.torusNetwork === "testnet") {
      // using console log because it shouldn't be affected by loglevel config
      console.log("%c [UPBOND-LOGIN] WARNING! You are on testnet. Please set network: 'mainnet' in production", "color: #FF0000");
    }
    const r = req as ValidatedJRPCRequest<Record<string, string>[]>;
    if (!r.origin) throw new Error(`invalid request origin for method ${req.method}`);
    store.dispatch(olUserModuleAction.setCurrentClientId({ currentDappClientId: r.clientId }));
    registerDappModule(r.clientId);
    const currentDappModule = store.getState().olAllDappModule.dappModules[r.clientId];
    const { sessionId, _sessionNamespace } = r;
    if (sessionId) {
      try {
        const publicKeyHex = getPublic(Buffer.from(sessionId, "hex")).toString("hex");
        const url = new URL(`${config.storageServerUrl}/store/get`);
        url.searchParams.append("key", publicKeyHex);
        if (_sessionNamespace) url.searchParams.append("namespace", _sessionNamespace);
        const encData: { message: string; success: boolean } = await get(url.href);
        if (encData.message) {
          const loginDetails = await decryptData<{ privKey: string; store: Record<string, string>; tKey?: string }>(
            sessionId,
            encData.message
          );
          const { authServiceInfo } = store.getState().olUserModule;
          if (loginDetails.tKey) {
            const tKeyJson = store.getState().olTKeyModule.tKey.toJSON();
            const tKeyString = JSON.stringify(tKeyJson);
            res.result = { ...loginDetails, tKeyString, ...authServiceInfo };
          } else {
            res.result = { ...loginDetails, ...authServiceInfo };
          }
          const { email, name, profileImage, aggregateVerifier, verifier, verifierId, typeOfLogin, idToken, accessToken } =
            loginDetails.store || {};
          store.dispatch(olDappModuleAction.updateState({ privateKey: loginDetails?.privKey }));
          store.dispatch(
            olAllDappModuleAction.updateState({
              clientId: store.getState().olUserModule.currentDappClientId,
              data: { privateKey: loginDetails?.privKey }
            })
          );
          store.dispatch(
            olUserModuleAction.setUserInfo({
              email,
              name,
              profileImage,
              aggregateVerifier,
              verifier,
              verifierId,
              typeOfLogin: typeOfLogin as LOGIN_TYPE,
              idToken,
              accessToken
            })
          );
          end();
          return;
        }
      } catch (error: unknown) {
        log.warn(error, "Session likely expired");
        if ((error as Response).status === 404) {
          await logout(store.getState().olUserModule.currentDappClientId);
          res.result = {
            privKey: "",
            store: {}
          };
          end();
          return;
        }
      }
    }
    const { email, aggregateVerifier, name, profileImage, typeOfLogin, verifier, verifierId } = store.getState().olUserModule.userInfo;
    res.result = {
      privKey: currentDappModule.privateKey,
      store: {
        touchIDPreference: currentDappModule.touchIDPreference,
        email,
        aggregateVerifier,
        name,
        profileImage,
        typeOfLogin,
        verifier,
        verifierId,
        login_name: "upbond_login_1"
      }
    };

    end();
  } catch (error) {
    log.error(error);
    res.error = new SerializableError({ code: -32602, message: (error as Error).message });
    end();
  }
};

export const openlogin_set_pid_data = (
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): void => {
  // validations
  const r = req as ValidatedJRPCRequest<PIDSetParamRequest[]>;
  const params = r.params[0];
  store.dispatch(olPidModuleAction._setPID({ pid: params.pid, data: params.data }));
  res.result = {};
  end();
};

export const openlogin_logout = async (
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): Promise<void> => {
  // validations
  const r = req as ValidatedJRPCRequest<Record<string, unknown>[]>;
  const { redirectUrl } = r.params[0];
  if (!r.user) {
    log.error("user must be present to logout");
    res.result = {
      alreadyRedirecting: true
    };
    if (r.redirect) {
      // reinit loginPerf before redirecting
      // for backward compat
      store.dispatch(olLoginPerfModuleActions._reinit());
      await redirectToDapp(
        {
          redirectUrl: redirectUrl as string,
          popupWindow: !!r.popupWindow,
          sessionTime: 1,
          sessionId: r.sessionId as string,
          _sessionNamespace: r._sessionNamespace as string
        },
        {
          pid: r.pid || "",
          result: {}
        }
      );
    }
    end();
  }
  if (r.clientId) {
    registerDappModule(r.clientId);
    const currentDappModule = store.getState().olAllDappModule.dappModules[r.clientId];
    if (currentDappModule.privateKey) {
      try {
        if (r.user !== getPublic(Buffer.from(currentDappModule.privateKey.padStart(64, "0"), "hex")).toString("hex")) {
          throw new Error("authenticating user does not match dapp key");
        }
      } catch (error: unknown) {
        res.error = new SerializableError({ code: -32603, message: (error as Error).message });
        end();
        return;
      }
    }
    await logout(store.getState().olUserModule.currentDappClientId);

    if (r.sessionId) {
      const privKey = Buffer.from(r.sessionId, "hex");
      const publicKeyHex = getPublic(privKey).toString("hex");
      const encData = await encryptData(r.sessionId, {});
      const signature = (await sign(privKey, keccak256hash(encData))).toString("hex");
      await post(`${config.storageServerUrl}/store/set`, {
        key: publicKeyHex,
        data: encData,
        signature,
        timeout: 1,
        namespace: r._sessionNamespace || undefined
      });
    }
  }
  res.result = {};
  end();
};

export const openlogin_prompt = async (
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): Promise<void> => {
  // validation
  const r = req as ValidatedJRPCRequest<Record<string, unknown>[]>;

  const {
    loginProvider,
    redirectUrl,
    getWalletKey,
    appState,
    _whiteLabelData,
    mfaLevel,
    dappShare,
    sessionTime,
    skipTKey,
    mobileOrigin,
    _sessionNamespace
  } = r.params[0];

  const redirectOrigin = new URL(redirectUrl as string).origin;
  if (redirectOrigin !== r.origin && redirectOrigin !== window.location.origin) throw new Error("redirectUrl is not on origin");

  store.dispatch(olUserModuleAction.setCurrentClientId({ currentDappClientId: r.clientId }));

  registerDappModule(r.clientId);

  store.dispatch(
    olDappModuleAction.setLoginParams({
      clientId: r.clientId,
      currentLoginProvider: loginProvider as string,
      redirectUrl: redirectUrl as string,
      mfaLevel: mfaLevel as MfaLevelType,
      getWalletKey: typeof getWalletKey === "string" ? getWalletKey === "true" : Boolean(getWalletKey),
      appState: appState as string,
      dappShare: dappShare as string,
      sessionTime: sessionTime as number,
      skipTKey: typeof skipTKey === "string" ? skipTKey === "true" : Boolean(skipTKey),
      mobileOrigin: mobileOrigin as string,
      _sessionNamespace: _sessionNamespace as string
    })
  );
  store.dispatch(
    olAllDappModuleAction.setLoginParams({
      clientId: r.clientId,
      currentLoginProvider: loginProvider as string,
      redirectUrl: redirectUrl as string,
      mfaLevel: mfaLevel as MfaLevelType,
      getWalletKey: typeof getWalletKey === "string" ? getWalletKey === "true" : Boolean(getWalletKey),
      appState: appState as string,
      dappShare: dappShare as string,
      sessionTime: sessionTime as number,
      skipTKey: typeof skipTKey === "string" ? skipTKey === "true" : Boolean(skipTKey),
      mobileOrigin: mobileOrigin as string,
      _sessionNamespace: _sessionNamespace as string
    })
  );
  // if (_whiteLabelData) currentDappModule.setWhiteLabel(_whiteLabelData as WhiteLabelParams);
  if (r.loginConfig) {
    Object.keys(r.loginConfig).forEach((y) => {
      store.dispatch(
        olDappModuleAction.modifyCustomLoginConfig({
          loginProvider: y,
          cfg: r.loginConfig[y] as LoginConfigItem
        })
      );
      store.dispatch(
        olAllDappModuleAction.modifyCustomLoginConfig({
          loginProvider: y,
          cfg: r.loginConfig[y] as LoginConfigItem,
          clientId: r.clientId
        })
      );
    });
  }

  const dappUrl = new URL(redirectUrl as string);
  store.dispatch(olDappModuleAction.setSiteMetadata({ name: dappUrl.hostname, url: dappUrl.origin }));
  res.result = {
    alreadyRedirecting: true
  };
  end();
};

// openlogin_login never meaningfully returns values through the JRPCEngine since it redirects
export const openlogin_login = async (
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): Promise<void> => {
  try {
    // validation
    const r = req as ValidatedJRPCRequest<Record<string, unknown>[]>;

    const {
      loginProvider,
      redirectUrl,
      mfaLevel,
      getWalletKey,
      appState,
      _whiteLabelData,
      dappShare,
      sessionTime,
      skipTKey,
      curve,
      mobileOrigin,
      _sessionNamespace
    } = r.params[0];

    // TODO: UNCOMMENT THIS WE NEED THIS CODE
    // if (_whiteLabelData && (_whiteLabelData as { lang: string }).lang) {
    //   loadLanguageAsync((_whiteLabelData as { lang: string }).lang);
    // }

    const { extraLoginOptions } = r.params[0];

    const redirectOrigin = new URL(redirectUrl as string).origin;
    if (redirectOrigin !== r.origin && redirectOrigin !== window.location.origin) throw new Error("redirectUrl is not on origin");

    store.dispatch(olUserModuleAction.setCurrentClientId({ currentDappClientId: r.clientId }));
    store.dispatch(
      olDappModuleAction.setLoginParams({
        clientId: r.clientId,
        currentLoginProvider: loginProvider as string,
        redirectUrl: redirectUrl as string,
        skipTKey: typeof skipTKey === "string" ? skipTKey === "true" : Boolean(skipTKey),
        mfaLevel: mfaLevel as MfaLevelType,
        getWalletKey: typeof getWalletKey === "string" ? getWalletKey === "true" : Boolean(getWalletKey),
        appState: appState as string,
        dappShare: dappShare as string,
        sessionTime: sessionTime as number,
        sessionId: r.sessionId as string,
        curve: curve as string,
        mobileOrigin: mobileOrigin as string,
        _sessionNamespace: _sessionNamespace as string
      })
    );
    registerDappModule(r.clientId);
    const currentDappModule = store.getState().olAllDappModule.dappModules[r.clientId];
    if (_whiteLabelData && (_whiteLabelData as { embedWlRedirectUrl: string }).embedWlRedirectUrl) {
      localStorage.setItem("dappRedirectUri", (_whiteLabelData as { embedWlRedirectUrl: string }).embedWlRedirectUrl);
    } else {
      localStorage.setItem("dappRedirectUri", redirectUrl as string);
    }

    if (r.params[0]?.loginConfig) {
      Object.keys(r.params[0].loginConfig).forEach((y) => {
        store.dispatch(
          olDappModuleAction.modifyCustomLoginConfig({
            loginProvider: y,
            cfg: (r.params[0].loginConfig as any)[y] as LoginConfigItem
          })
        );
        store.dispatch(
          olAllDappModuleAction.modifyCustomLoginConfig({
            loginProvider: y,
            cfg: (r.params[0].loginConfig as any)[y] as LoginConfigItem,
            clientId: r.clientId
          })
        );
      });
    }

    const dappUrl = new URL(redirectUrl as string);
    store.dispatch(olDappModuleAction.setSiteMetadata({ name: dappUrl.hostname, url: dappUrl.origin }));

    if (loginProvider === "unselected") {
      res.result = {
        alreadyRedirecting: true
      };
      end();
      return;
    }

    // fast login
    let currVerifier = configs.loginConfig["upbond-google"]?.verifier;
    if (!currVerifier) {
      currVerifier = "line";
    }

    let lastVerifierId = store.getState().olDeviceModule.verifierToLastLoggedInVerifierID[currVerifier];
    if ((extraLoginOptions as ExtraLoginOptions)?.login_hint) {
      lastVerifierId = (extraLoginOptions as ExtraLoginOptions)?.login_hint || lastVerifierId;
    }

    if (store.getState().olDeviceModule.isTouchIDRegistered) {
      if (lastVerifierId) {
        const touchIDPref = store.getState().olDeviceModule.touchIDVerifierIDMap[getJoinedKey(currVerifier, lastVerifierId)];
        if (touchIDPref === TouchIDPreferences.ENABLED) {
          if (currentDappModule.touchIDPreference === TouchIDPreferences.UNSET) {
            store.dispatch(
              olAllDappModuleAction.setTouchIDPreference({
                clientId: currentDappModule.clientId,
                touchIDPreference: TouchIDPreferences.ENABLED
              })
            );
            store.dispatch(olDappModuleAction.setTouchIDPreference(TouchIDPreferences.ENABLED));
          }
          const available = await isWebAuthnAvailable();
          if (currentDappModule.touchIDPreference === TouchIDPreferences.ENABLED && available) {
            // this.doneLoading = true;
            await initSdkProm;

            let credIdSuggestions: string[];
            const scopedCredIdCache = store.getState().olDeviceModule.credIdMapCache[getJoinedKey(currVerifier, lastVerifierId)];
            if (scopedCredIdCache && scopedCredIdCache.credIds.length > 0) {
              credIdSuggestions = scopedCredIdCache.credIds;
            } else {
              credIdSuggestions = store.getState().olDeviceModule.credIdCache;
            }
            const tempRedirectUrl = localStorage.getItem("tempRedirectUrl");
            const embedRedirectURL = store.getState().embedState.configuration.directConfig.redirectUrl;
            const WhiteLabelParams = { embedWlRedirectUrl: embedRedirectURL || tempRedirectUrl };
            const loginOptions = {
              ...generateWebAuthnCustomAuthParams(config.loginConfig[WEBAUTHN_LOGIN_PROVIDER], {
                client: currentDappModule.clientId,
                currentLoginProvider: currentDappModule.currentLoginProvider,
                credIdCache: JSON.stringify(credIdSuggestions),
                webAuthnFlow: WebAuthnFlow.LOGIN,
                oAuthVerifierId: lastVerifierId,
                oAuthAggregateVerifier: currVerifier,
                popupWindow: (!!r.popupWindow).toString(),
                pid: r.pid || "",
                extraLoginOptions: JSON.stringify(extraLoginOptions || {}),
                whiteLabel: WhiteLabelParams ? JSON.stringify(WhiteLabelParams) : JSON.stringify(currentDappModule.whiteLabel),
                keyMode: store.getState().olTKeyModule.keyMode,
                login_name: "upbond_login_2"
              }),
              registerOnly: false
            };
            type CustomExtraOptions = ExtraLoginOptions & { domain: string };

            loginOptions.jwtParams = { ...loginOptions.jwtParams, ...(extraLoginOptions as CustomExtraOptions) };
            customAuth.torus.enableOneKey = false;
            await customAuth.triggerLogin(loginOptions);

            res.result = {
              alreadyRedirecting: true
            };
            end();
            return;
          }
        }
      }
    }
    // redirect to 3rd party for login
    const localConfig = loginConfig(r.clientId)[
      currentDappModule && currentDappModule.currentLoginProvider ? currentDappModule.currentLoginProvider : "upbond-line"
    ];
    const tempRedirectUrl = localStorage.getItem("tempRedirectUrl");
    const embedRedirectURL = store.getState().embedState.configuration.directConfig.redirectUrl;
    const WhiteLabelParams = { embedWlRedirectUrl: embedRedirectURL || tempRedirectUrl };
    const customAuthArgs = generateCustomAuthParams(
      {
        ...localConfig,
        jwtParameters: {
          ...localConfig.jwtParameters,
          client_id: ((extraLoginOptions as ExtraLoginOptions).clientId as string)
            ? ((extraLoginOptions as ExtraLoginOptions).clientId as string)
            : (localConfig.jwtParameters.clientId as string) ?? (null as unknown)
        }
      },
      {
        login_name: "upbond_login_3",
        client: currentDappModule ? currentDappModule.clientId : r.clientId,
        currentLoginProvider: currentDappModule ? currentDappModule.currentLoginProvider : localConfig.loginProvider,
        popupWindow: (!!r.popupWindow).toString(),
        pid: r.pid || "",
        whiteLabel: WhiteLabelParams ? JSON.stringify(WhiteLabelParams) : JSON.stringify(currentDappModule.whiteLabel),
        keyMode: store.getState().olTKeyModule.keyMode,
        isCustomVerifier: currentDappModule ? isCustomVerifier(currentDappModule.clientId).toString() : "upbond-google-dev-tesnet"
      }
    );

    await initSdkProm;

    // comment this if you want to debug

    await customAuth.triggerLogin({
      ...customAuthArgs
    } as SubVerifierDetails);
    res.result = {
      alreadyRedirecting: true
    };
    end();

    // until here
  } catch (error: unknown) {
    log.error(error);
    res.error = new SerializableError({ code: -32602, message: (error as Error).message });
    end();
  }
};

export const scaffoldMiddlewares = {
  [OPENLOGIN_METHOD.CHECK_3PC_SUPPORT]: openlogin_check_3PC_support,
  [OPENLOGIN_METHOD.GET_DATA]: openlogin_get_data,
  [OPENLOGIN_METHOD.LOGIN]: openlogin_login,
  [OPENLOGIN_METHOD.LOGOUT]: openlogin_logout,
  [OPENLOGIN_METHOD.SET_PID_DATA]: openlogin_set_pid_data,
  [OPENLOGIN_METHOD.PROMPT]: openlogin_prompt
};

// #endregion method middlewares

// #region session middleware

export async function validateUser(clientId: string, user: string, userSig: string, userData: UserData): Promise<void> {
  await verify(
    Buffer.from(user, "hex"),
    Buffer.from(keccak("keccak256").update(JSON.stringify(userData)).digest("hex"), "hex"),
    Buffer.from(userSig, "base64")
  );

  // more than 60s ago
  if (parseInt(userData.timestamp, 10) < Date.now() - 60 * 1000) {
    throw new Error("user data expired");
  }

  if (userData.clientId !== clientId) {
    throw new Error("user did not sign matching clientId");
  }
}

export async function validateRedirect(clientId: string, origin: string, originData: OriginData): Promise<void> {
  const originUrl = new URL(origin);
  if (config.alwaysAllowedHosts.includes(originUrl.hostname)) {
    return;
  }

  const b64OriginSig = originData[origin];
  if (b64OriginSig) {
    await verify(
      Buffer.from(clientId, "base64"),
      Buffer.from(keccak("keccak256").update(origin).digest("hex"), "hex"),
      Buffer.from(b64OriginSig, "base64")
    );
    return;
  }
  throw new Error(
    `could not validate redirect, please whitelist your domain: ${origin} for provided clientId ${clientId} at https://dashboard.web3auth.io`
  );
}

export const sessionMiddleware: JRPCMiddleware<unknown, unknown> = async function a(
  req: JRPCRequest<unknown>,
  res: JRPCResponse<unknown>,
  next: JRPCEngineNextCallback,
  end: JRPCEngineEndCallback
): Promise<void> {
  // validations
  const r = req as ValidatedJRPCRequest<Record<string, unknown>[]>;
  if (r.params && Object.keys(r.params).length > 0) {
    try {
      let param = r.params[0];
      const { _pid } = param;

      // merge params with presetParams based on pid
      if (_pid) {
        const pid = _pid as string;
        const presetParams = store.getState().olPidModule.pidStore[pid];
        param = { ...presetParams, ...param };
      }

      const {
        _user,
        _userSig,
        _userData,
        _origin,
        _originData,
        _loginConfig,
        _clientId,
        _redirect,
        _popupWindow,
        _sessionId,
        _sessionNamespace
      } = param;

      // defaults
      r.redirect = _redirect as boolean;
      r.clientId = _clientId as string;
      r.pid = _pid as string;
      r.user = "";
      r.origin = "";
      r.popupWindow = !!_popupWindow;
      r.loginConfig = _loginConfig as LoginConfig;
      r.sessionId = _sessionId as string;
      r._sessionNamespace = _sessionNamespace as string;
      if (_origin && _originData && r.clientId) {
        // TODO: fetch and merge originData from server based on clientId
        const origin = _origin as string;
        const originData = _originData as OriginData;
        await validateRedirect(r.clientId, origin, originData);
        r.origin = origin;
      }

      if (_user && _userSig && _userData) {
        const user = _user as string;
        const userSig = _userSig as string;
        const userData = _userData as UserData;
        await validateUser(r.clientId, user, userSig, userData);
        r.user = user;
      }
      next((done) => {
        res.result = {
          ...(res.result as Record<string, unknown>),
          _origin,
          _pid,
          _clientId,
          _popupWindow,
          _sessionId,
          _sessionNamespace,
          _usrKeyInfo: {
            key: store.getState().olUserModule.keyInfo,
            wallet: store.getState().olUserModule.walletKeyInfo,
            clientTimeOffset: store.getState().olUserModule.clientTimeOffset,
            keyMode: store.getState().olTKeyModule.keyMode
          }
        };
        return done();
      });
    } catch (error) {
      log.error(error);
      res.error = new SerializableError({ code: -32602, message: (error as Error).message });
      return end();
    }
  }
  return next();
};

// #endregion session middleware
