// @ts-nocheck
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText
} from "@ionic/react";
import HeaderHistory from "features/account/components/HeaderHistory";
import AccountHistoryContent from "features/account/components/AccountHistoryContent";
import HeaderMainMb from "components/header/mb";
import { useTranslation } from "react-i18next";

const DetailNetworkMainMb: React.FC<any> = ({
  historyChain,
  defaultLogo,
  ViewBalance,
  history,
  astarItems,
  handleModal,
  handleModalMoralis,
  isAstar,
  latest10,
  haveMore,
  setOpenModalHistory,
  detailsLoading
}) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <HeaderMainMb title="" link="/wallet/home" />
      <IonContent className="ion-padding" fullscreen>
        <IonItem lines="none" className="item-list">
          <IonAvatar slot="start" className="mr-4">
            <IonImg
              className="border border-black"
              alt={historyChain.chainName}
              src={historyChain.explore ? historyChain?.customLogo : defaultLogo(historyChain.chain)}
            />
            <IonImg className="badge border border-black" alt="tc" src={historyChain?.customLogo} />
          </IonAvatar>
          <IonLabel>
            <IonText className="font-bold">{historyChain?.customName}</IonText>
          </IonLabel>
        </IonItem>

        <IonText className="relative -top-4 left-14 z-10">{ViewBalance()}</IonText>

        <IonGrid className="border border-x-0 border-b-0 border-t-gray-300 px-0">
          <IonRow className="ion-justify-content-between">
            <IonCol className="text-left pl-0">
              <IonButton shape="round" className="w-full" disabled>
                {t("settings.deposit")}
              </IonButton>
            </IonCol>
            <IonCol className="text-center">
              <IonButton shape="round" className="w-full" onClick={() => history.push("/wallet/transfer", "forward", "push")}>
                {t("settings.transfer")}
              </IonButton>
            </IonCol>
            <IonCol className="text-right pr-1">
              <IonButton shape="round" className="w-full" disabled>
                {t("settings.pay")}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* List of Transaction History */}
        <IonList lines="full">
          <HeaderHistory showSelect={false} />
          <AccountHistoryContent
            astarItems={astarItems}
            handleModalAstar={handleModal}
            handleModalMoralis={handleModalMoralis}
            isAstar={isAstar}
            moralisItems={{ items: latest10, allItems: [], haveMore }}
            setOpenModalHistory={setOpenModalHistory}
            detailsLoading={detailsLoading}
          />
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default DetailNetworkMainMb;
