import { store } from "shared/store";
import { STORAGE_TYPE, LOCAL_STORAGE_KEY } from "shared/utils/openloginenums";
import olAllDappModuleSlice from "shared/store/olAllDappModuleSlice";
import { cloneDeep, merge } from "lodash-es";
import config, { openLoginVerifiers } from "shared/utils/openloginconfig";
import { LoginConfig, TouchIDPreferences } from "shared/utils/openlogininterface";

export const olAllDappModuleAction = olAllDappModuleSlice.actions;

export function registerDappModule(clientId: string, storageKey: STORAGE_TYPE = LOCAL_STORAGE_KEY): void {
  if (!clientId && !storageKey) return;
  if (store.getState().olDappModule.clientId !== clientId) return;
  store.dispatch(olAllDappModuleAction.addDappModule(store.getState().olDappModule));
}

export const usingDirectConfig = () => {
  return store.getState().olDappModule.directConfig;
};

export const loginConfig = (clientId: string): LoginConfig => {
  const selectedState = store.getState().olAllDappModule.dappModules[clientId];
  const localLoginConfig = cloneDeep(config.loginConfig);
  const finalConfig =
    selectedState && selectedState.customLoginConfig ? merge(localLoginConfig, selectedState.customLoginConfig) : localLoginConfig;
  return finalConfig;
};

export const canSendDappShare = (): boolean => {
  const { userInfo } = store.getState().olUserModule;
  const { tKeyPrivKey, keyMode } = store.getState().olTKeyModule;
  const { aggregateVerifier, verifier: userVerifier } = userInfo;
  const customVerifier = aggregateVerifier || userVerifier;
  // TODO: Maybe add client whitelist
  // check if tkey exists (!tkeyPrivKey)
  if (!tKeyPrivKey || keyMode === "1/1") {
    return false;
  }
  return !openLoginVerifiers.includes(customVerifier);
};

export const isCustomVerifier = (clientId: string): boolean => {
  const selectedState = store.getState().olAllDappModule.dappModules[clientId];
  const isCustomVerifier = Object.keys(selectedState.customLoginConfig).includes(selectedState.currentLoginProvider);
  return isCustomVerifier;
};

export const logout = async (clientId: string): Promise<void> => {
  store.dispatch(
    olAllDappModuleAction.updateState({
      clientId,
      data: {
        touchIDPreference: TouchIDPreferences.DISABLED,
        customLoginConfig: {},
        privateKey: "",
        clientId: "",
        redirectUrl: store.getState().olDappModule.redirectUrl, // do not clear this, or logout redirects wont work
        currentLoginProvider: "",
        whiteLabel: {},
        sessionId: "",
        sessionTime: 86400,
        dappShare: "",
        idToken: "",
        _sessionNamespace: ""
      }
    })
  );
};
