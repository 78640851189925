import { WhiteLabelData } from "@toruslabs/openlogin-jrpc/dist/types/utils";
import { LOGIN_PROVIDER, OpenloginUserInfo, OPENLOGIN_NETWORK_TYPE } from "@toruslabs/openlogin";

const { REACT_APP_CLIENT_ID, REACT_APP_OPENLOGIN_URL, REACT_APP_TORUS_NETWORK } = process.env;

export const openloginWhitelabel: WhiteLabelData = {
  name: "UPBOND",
  logoLight: "https://app.dev.upbond.io/assets/images/common/UPBOND%E3%83%AD%E3%82%B4new-01.svg",
  logoDark: "https://app.dev.upbond.io/assets/images/common/UPBOND%E3%83%AD%E3%82%B4new-01.svg",
  defaultLanguage: "en",
  dark: false
};

export const initUser: OpenloginUserInfo = {
  email: "",
  aggregateVerifier: "",
  name: "",
  profileImage: "",
  typeOfLogin: LOGIN_PROVIDER.LINE,
  verifier: "",
  oAuthAccessToken: "",
  idToken: "",
  verifierId: "",
  dappShare: ""
};

export const openloginUrl = REACT_APP_OPENLOGIN_URL;
export const openloginUxMode = "redirect";
export const openloginClientId: string = REACT_APP_CLIENT_ID || "";
export const openloginNetwork = (REACT_APP_TORUS_NETWORK || "testnet") as OPENLOGIN_NETWORK_TYPE;
