// @ts-nocheck
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  useIonRouter
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/useRedux";
import HeaderMainPc from "components/header/pc";

const PermissionManageMainPc: React.FC<any> = ({ didData, permissions, selectPermission }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useIonRouter();

  return (
    <IonPage className="ion-no-padding">
      <HeaderMainPc title={t("settings.managePermission")} link="/wallet/settings/init" />

      <IonContent className="bg-base ion-no-padding gray">
        <IonGrid>
          <IonRow>
            <IonCol size="4">
              <IonCard class="ion-padding card-setting m-0">
                <IonCardContent>
                  <>
                    <IonList lines="full" className="permission-list">
                      {permissions && permissions.length > 0 ? (
                        permissions.map((permission: any, i: number) => (
                          <IonItem
                            key={i}
                            onClick={() => {
                              dispatch(selectPermission({ ...permission, userData: didData }));
                              history.push("/wallet/settings/permission-detail");
                            }}>
                            <IonLabel>{permission.uri_subscriber}</IonLabel>
                          </IonItem>
                        ))
                      ) : (
                        <>
                          <IonList className="p-[30px]">{t("consentsForm.connectToDapps")}</IonList>
                        </>
                      )}
                    </IonList>
                  </>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PermissionManageMainPc;
