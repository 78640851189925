import { BroadcastChannel } from "@toruslabs/broadcast-channel";
import {
  createEngineStream,
  createErrorMiddleware,
  createScaffoldMiddleware,
  IGNORE_SUBSTREAM,
  JRPCEngine,
  PostMessageStream,
  setupMultiplex,
  Substream
} from "@toruslabs/openlogin-jrpc";
import log from "loglevel";
import { post } from "@toruslabs/http-helpers";
import { safebtoa } from "@toruslabs/openlogin-utils";
import { getED25519Key } from "@toruslabs/openlogin-ed25519";
import { getPublic, sign } from "@toruslabs/eccrypto";
import { encryptData } from "@toruslabs/metadata-helpers";
import createKeccakHash from "keccak";
import { ec as EC } from "elliptic";
import { scaffoldMiddlewares, sessionMiddleware } from "./openloginmiddleware";
import { STORAGE_TYPE } from "./openloginenums";
import { AggregateLoginParams, SubVerifierDetails, TorusGenericObject } from "@toruslabs/customauth";

import { LoginConfigItem } from "./openlogininterface";
import { privateToAddress, toChecksumAddress } from "ethereumjs-util";
import pump from "pump";

let rpcEngine: JRPCEngine;
let initResolve: (value: unknown) => void;
const initCompletion = new Promise((resolve) => {
  initResolve = resolve;
});

let verifierStream: Substream;
let verifierInitResolve: (value: unknown) => void;
const verifierInitCompletion = new Promise((resolve) => {
  verifierInitResolve = resolve;
});

export function keccak256hash(a: string | Buffer): Buffer {
  return createKeccakHash("keccak256").update(a).digest();
}

export const initSdk = (): void => {
  const rpcStream = new PostMessageStream({
    name: "iframe_rpc",
    target: "embed_rpc",
    targetWindow: window.parent,
    targetOrigin: "*"
  });

  const mux = setupMultiplex(rpcStream);
  const _jrpcStream = mux.getStream("jrpc");
  if (_jrpcStream === IGNORE_SUBSTREAM) {
    throw new Error("jrpc stream should not be ignored");
  }
  const jrpcStream = _jrpcStream as Substream;

  rpcEngine = new JRPCEngine();
  rpcEngine.push(createErrorMiddleware(log));
  rpcEngine.push(sessionMiddleware);
  rpcEngine.push(createScaffoldMiddleware(scaffoldMiddlewares));
  const engineStream = createEngineStream({ engine: rpcEngine });
  pump(engineStream, jrpcStream, engineStream, (error: any): void => {
    log.error(error, `engine disconnected`);
  });
  initResolve(null);
};

export const initModalStream = (): void => {
  const verifierMux = setupMultiplex(
    new PostMessageStream({
      name: "modal_rpc",
      target: "modal_iframe_rpc",
      targetWindow: window.parent,
      targetOrigin: "*"
    })
  );
  const _verifierStream = verifierMux.getStream("verifier");
  if (_verifierStream === IGNORE_SUBSTREAM) {
    throw new Error("jrpc stream should not be ignored");
  }
  verifierStream = _verifierStream as Substream;
  verifierInitResolve(null);
};

export async function getVerifierStream(): Promise<Substream> {
  await verifierInitCompletion;
  return verifierStream;
}

export async function getRPCEngine(): Promise<JRPCEngine> {
  await initCompletion;
  return rpcEngine;
}

export const tabsBroadcast = () => {
  const bc = new BroadcastChannel(`upbond_close_tab`);
  return bc;
};

export function validateInternalHost(urlString: string): boolean {
  try {
    const allowedHostnameEndings = [
      "localhost",
      "tor.us",
      "openlogin.com",
      "localhost:3000",
      "upbond.io",
      "gohey.com",
      "localhost:3002",
      "gohey.jp"
    ];
    const url = new URL(urlString);
    let allowed = false;
    allowedHostnameEndings.forEach((hostnameEnding) => {
      if (url.hostname.endsWith(hostnameEnding)) allowed = true;
    });
    return allowed;
  } catch (error) {
    log.error(error, "could not validate url");
    return false;
  }
}

export async function redirectToDapp(
  {
    redirectUrl,
    popupWindow,
    sessionTime,
    sessionId,
    errorMsg,
    _sessionNamespace
  }: { redirectUrl: string; popupWindow: boolean; sessionTime: number; sessionId: string; _sessionNamespace: string; errorMsg?: string },
  { result, pid }: { result: Record<string, unknown>; pid: string }
): Promise<void> {
  const isInternalHost = validateInternalHost(redirectUrl);
  const finalResult = { ...result };

  if (!isInternalHost) {
    delete finalResult.oAuthPrivateKey;
    delete finalResult.tKey;
    delete finalResult.walletKey;
  }

  if (finalResult.privKey) {
    finalResult.ed25519PrivKey = getED25519Key(result.privKey as string).sk.toString("hex");
  }

  let finalUrl: URL;
  if (sessionId) {
    const privKey = Buffer.from(sessionId, "hex");
    const publicKeyHex = getPublic(privKey).toString("hex");
    const encData = await encryptData(sessionId, finalResult);
    const bufferEncData = keccak256hash(encData);
    const signature = (await sign(privKey, bufferEncData)).toString("hex");
    try {
      await post(`https://broadcast-server.tor.us/store/set`, {
        key: publicKeyHex,
        data: encData,
        signature,
        timeout: sessionTime,
        namespace: _sessionNamespace
      });
    } catch (error) {
      log.error(`Error when set store:`, error);
    }
  }

  if (popupWindow) {
    finalUrl = new URL(`${window.location.origin}/popup-window`);
    const hash = safebtoa(JSON.stringify({ ...finalResult, _nextRedirect: redirectUrl }));
    finalUrl.hash = `result=${hash}&_pid=${pid}`;
    if (sessionId) {
      finalUrl.hash = `${finalUrl.hash}&sessionId=${sessionId}`;
    }

    if (_sessionNamespace) {
      finalUrl.hash = `${finalUrl.hash}&sessionNamespace=${_sessionNamespace}`;
    }

    if (errorMsg) {
      finalUrl.hash += `&errorMsg=${errorMsg}`;
    }
    // allow async ops to finish
    setTimeout(() => {
      window.location.replace(finalUrl.href);
    }, 200);
  } else {
    finalUrl = new URL(redirectUrl);
    const hash = safebtoa(JSON.stringify(finalResult));
    finalUrl.hash = `result=${hash}&_pid=${pid}`;
    if (sessionId) {
      finalUrl.hash = `${finalUrl.hash}&sessionId=${sessionId}`;
    }

    if (_sessionNamespace) {
      finalUrl.hash = `${finalUrl.hash}&sessionNamespace=${_sessionNamespace}`;
    }

    if (errorMsg) {
      finalUrl.hash += `&errorMsg=${errorMsg}`;
    }

    setTimeout(() => {
      window.location.href = finalUrl.href;
    }, 200);
  }
}

export function generateCustomAuthParams(
  localConfig: LoginConfigItem,
  customState: TorusGenericObject = {}
): AggregateLoginParams | SubVerifierDetails {
  return {
    typeOfLogin: localConfig.typeOfLogin,
    verifier: localConfig.verifier,
    clientId: (localConfig.jwtParameters.clientId as string) ? (localConfig.jwtParameters.clientId as string) : localConfig.clientId,
    jwtParams: localConfig.jwtParameters,
    customState
  };
}

export function generateWebAuthnCustomAuthParams(localConfig: LoginConfigItem, customState: TorusGenericObject = {}): SubVerifierDetails {
  return {
    typeOfLogin: localConfig.typeOfLogin,
    verifier: localConfig.verifier,
    clientId: localConfig.clientId,
    jwtParams: localConfig.jwtParameters,
    customState
  };
}

export default function storageAvailable(type: STORAGE_TYPE): boolean {
  let storageExists = false;
  let storageLength = 0;
  let storage: Storage;
  try {
    storage = window[type];
    storageExists = true;
    storageLength = storage.length;
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (error: unknown) {
    const localError = error as { code?: number; name?: string };
    return (
      !!error &&
      // everything except Firefox
      (localError.code === 22 ||
        // Firefox
        localError.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        localError.name === "QuotaExceededError" ||
        // Firefox
        localError.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storageExists &&
      storageLength !== 0
    );
  }
}

export async function isWebAuthnAvailable(): Promise<boolean> {
  if (window.self !== window.top) return false;
  if (!window.PublicKeyCredential) return false;
  const available = await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
  return available;
}

export function getJoinedKey(verifier: string, verifierId: string): string {
  return `${verifier}\x1c${verifierId}`;
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const sanitizeUrl = (url: string): URL => {
  try {
    return new URL(url);
  } catch (error) {
    return new URL("https://example.com");
  }
};

export function getPublicFromPrivateKey(privateKeyHex: string): { X: string; Y: string; address: string } {
  const ec = new EC("secp256k1");
  const keyPair = ec.keyFromPrivate(privateKeyHex, "hex");
  const publicKey = keyPair.getPublic();
  const X = publicKey.getX().toString(16);
  const Y = publicKey.getY().toString(16);
  const ethAddressLower = privateToAddress(Buffer.from(privateKeyHex.padStart(64, "0"), "hex")).toString("hex");
  return { X, Y, address: toChecksumAddress(`0x${ethAddressLower}`) };
}

export function checkIfTrueValue(val: unknown): boolean {
  if (val === "0") return false;
  if (!val) return false;
  return true;
}

export const measurePerformance = (operationName: string): number => {
  try {
    const measureName = `${operationName}_measure`;
    const startMarkName = `${operationName}_start`;
    const endMarkName = `${operationName}_end`;
    window.performance.mark(endMarkName);
    window.performance.measure(measureName, startMarkName, endMarkName);
    const perfEntry = window.performance.getEntriesByName(measureName);
    window.performance.clearMarks(startMarkName);
    window.performance.clearMarks(endMarkName);
    window.performance.clearMeasures(measureName);
    log.debug("time taken", perfEntry[0].duration, operationName);
    return perfEntry[0].duration;
  } catch (error) {
    // error might occur if start marker is not set before calling
    // this function
    log.error("Error in measurePerformance function", error);
    return 0;
  }
};

export const measurePerformanceAndRestart = (operationName: string): number => {
  try {
    const duration = measurePerformance(operationName);
    const startMarkName = `${operationName}_start`;
    window.performance.mark(startMarkName);
    log.debug("time taken and restarted", duration, operationName);
    return duration;
  } catch (error) {
    log.error("Error in measurePerformance function", error);
    return 0;
  }
};
