// @ts-nocheck
import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import ModalCommingSoon from "components/modals/ModalCommingSoon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchBalance } from "shared/actions/balanceAction";
import { setOpenModalPurchase } from "shared/actions/walletAction";
import { IToken } from "interfaces/actions/IToken";
import { fetchTransferGas, fetchTransferToken } from "shared/actions/transferAction";
import HomeHeaderMb from "features/home/components/HomeHeader/mb";
import HomeHeaderPc from "features/home/components/HomeHeader/pc";
import useLoading from "hooks/useLoading";
import historyChainSlice from "shared/store/historyChain.slice";
import HomeList from "features/home/components/HomeList";
import NoAsset from "features/home/components/NoAsset";
// import Banner from "components/banner";
import { listChain } from "shared/config/chains";
import { getBestRpc } from "shared/utils/filterRpc";

export const historyChainAction = historyChainSlice.actions;

const HomePage: React.FC = () => {
  const [countNotif, setCountNotif] = useState(0);
  const { SkeletonLoading } = useLoading();
  const openModalPurchase = useAppSelector((state) => state.wallet.openModalPurchase);
  const balance = useAppSelector((state) => state.balance);
  const noAssetsHome = useAppSelector((state) => state.balance.noAsset);
  const walletConnect = useAppSelector((state) => state.walletConnect);
  const walletConnectArr = useAppSelector((state) => state.walletConnectArrData);
  const selectedAddress = useAppSelector((state) => state.wallet.selectedAddress);
  // const index = useAppSelector((state) => state.balance.index);
  // const length = useAppSelector((state) => state.balance.length);

  const dispatch = useAppDispatch();
  const appDispatch = useAppDispatch();
  const { breakpoint } = useTheme();
  // const { t } = useTranslation();
  const history = useIonRouter();

  useEffect(() => {
    setCountNotif(walletConnectArr.dataArr.length);
    appDispatch(setOpenModalPurchase(false));
  }, [walletConnect.data, walletConnectArr.dataArr]);

  useEffect(() => {
    if (selectedAddress) {
      dispatch(fetchBalance(selectedAddress));
    }
    if (localStorage.getItem("selectedContact")) {
      localStorage.removeItem("selectedContact");
    }
    initGetBestRpc();
  }, []);

  const initGetBestRpc = async () => {
    for (const network of listChain) {
      await getBestRpc(network.chain as string, []);
    }
  };

  const selectedCoin = async (e: IToken) => {
    if (breakpoint < 1024) {
      dispatch(fetchTransferGas(e.chain_id));
      dispatch(fetchTransferToken(e));
      history.push(`/wallet/details`);
    }
  };

  const selectedToken = async (e: IToken) => {
    if (breakpoint < 1024) {
      dispatch(fetchTransferGas(e.chain_id));
      dispatch(fetchTransferToken(e));
      const _chainEnum: any = { "80002": "amoy", "1": "eth", "137": "polygon", "5": "goerli", "592": "Astar", "11155111": "sepolia" };
      const chaindata = {
        name: e.contract_name,
        address: e.contract_address,
        chain: e.chain_id,
        ticker: e.contract_ticker_symbol,
        chainName: _chainEnum[e.chain_id],
        customLogo: e.logo_url,
        customName: e.contract_name,
        customTicker: e.contract_ticker_symbol,
        balance: e.balance,
        rpcUrl: [],
        explore: "",
        addressDefinitions: "",
        resource: "",
        type: e.type
      };
      // console.log(chaindata, e);
      localStorage.setItem("chain", JSON.stringify(chaindata));
      dispatch(historyChainAction.setChain(chaindata));
      history.push(breakpoint < 1024 ? `/wallet/details` : `/wallet/tf/confirm-token`);
    }
  };

  return (
    <>
      <ModalCommingSoon isOpen={openModalPurchase} setOpenCommingSoon={() => appDispatch(setOpenModalPurchase(false))} />
      <IonPage>
        <IonContent className={breakpoint >= 1024 ? "no-padding bg-base" : "ion-padding"} fullscreen>
          <div className="flex flex-row justify-between lg:justify-end items-center lg:p-4 lg:m-0">
            {breakpoint >= 1024 ? <HomeHeaderPc countNotif={countNotif} showAddCash={true} /> : <HomeHeaderMb />}
          </div>

          {/* <Banner title={t("home.notice")} message={t("home.bannerMessage")} /> */}
          {balance.isLoading ? (
            <div className="w-full justify-center mt-3">
              {/* <DisplayLoadingNew value={index / length} /> */}
              {breakpoint < 1024 && <SkeletonLoading type="group" rows={5} />}
            </div>
          ) : (
            <>
              {noAssetsHome === false && balance.isLoading === false ? (
                <HomeList
                  etherInitBalance={balance.etherInit}
                  maticInitBalance={balance.maticInit}
                  balances={balance.coin}
                  index={balance.index}
                  tokenBalances={balance.token}
                  collectibles={balance.nft}
                  balance={balance}
                  onCoinClick={selectedCoin}
                  onTokenClick={selectedToken}
                  length={balance.length}
                />
              ) : (
                <>{noAssetsHome === true && balance.isLoading === false && <NoAsset />}</>
              )}
            </>
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default HomePage;
