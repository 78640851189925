// @ts-nocheck
import { IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from "@ionic/react";

const HeaderMainPc = ({ title, link }: any) => {
  return (
    <IonHeader className="ion-no-border">
      <IonToolbar mode="md" className="bg-base">
        <IonButtons slot="start">
          <IonBackButton mode="md" defaultHref={link || "/wallet/home"}></IonBackButton>
        </IonButtons>
        <IonTitle color="dark" class="font-bold">
          {title}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default HeaderMainPc;
