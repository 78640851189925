import { randomId } from "@toruslabs/openlogin-utils";
import Torus from "@toruslabs/torus.js";
import { hashPersonalMessage } from "ethereumjs-util";
import { onloadHandler, UpbondExtended } from "handlers/auth/OnloadHandler";

// Make this a class. Use ES6 class
class TorusExtended extends Torus {
  instanceId: string;

  constructor() {
    super({ enableOneKey: true });
    this.instanceId = randomId();
  }

  hashMessage(message: string) {
    const bufferedMessage = Buffer.from(message);
    return hashPersonalMessage(bufferedMessage).toString("hex");
  }
}

/* Inialize torus object on load */
const torus = onloadHandler<TorusExtended>(new TorusExtended()) as TorusExtended & Torus & UpbondExtended;

export default torus;
