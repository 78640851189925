// @ts-nocheck
import { IonPage, IonRouterOutlet } from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import PendingTransactionPage from "pages/pendingTransaction";

const PendingTransactionRoutes = () => (
  <IonPage>
    <IonRouterOutlet>
      <Route exact path="/pending-transaction">
        <Redirect to="/pending-transaction/page" />
      </Route>
      <Route path="/pending-transaction/page" component={PendingTransactionPage} />
    </IonRouterOutlet>
  </IonPage>
);

export default PendingTransactionRoutes;
