// @ts-nocheck
import * as React from "react";
import Logo from "assets/images/darklogo.png";
import ConfirmForm from "features/confirm/components/confirmForm";
import { IonSpinner } from "@ionic/react";
import { BroadcastChannel } from "@toruslabs/broadcast-channel";
import { useEffect, useState } from "react";
import { ConfirmModal, TriggerDenyArgs, TriggerSignArgs, triggerDenyFn, triggerSignFn } from "interfaces/ui/IConfirm";
import { POPUP_LOADED, POPUP_RESULT } from "shared/enums";
import { broadcastChannelOptions, searchToObject } from "shared/utils/coreUtils";
import useCommunicationStream from "hooks/useCommunicationStream";
import { useDispatch } from "react-redux";
import { embedActions } from "shared/actions/embedAction";
import { useAppSelector } from "hooks/useRedux";
import { useEmbedUiDispatch } from "hooks/useEmbedUiDispatch";
import { NetworkType } from "shared/actions/walletAction";
import "./style.css";

const ConfirmPage: React.FC = () => {
  const [newType, setType] = useState<boolean>(false);
  const [channel, setChannel] = useState<string>("");
  const [logo, setLogo] = useState<string>("");
  const [currentConfirmModal, setCurrentConfirmModal] = useState<ConfirmModal | null>(null);

  const dispatch = useDispatch();
  const uiDispatch = useEmbedUiDispatch();
  const { openPopup } = useCommunicationStream();
  const widgetIsOpen = useAppSelector((state) => state.embedUiState.widgetIsOpen);

  const triggerDeny: triggerDenyFn = async (id: TriggerDenyArgs) => {
    try {
      const bc = new BroadcastChannel(channel, broadcastChannelOptions);
      await bc.postMessage({ data: { type: POPUP_RESULT, id, txType: newType, approve: false } });
      bc.close();
      window.close();
    } catch (error) {
      console.error(error);
    }
  };

  const triggerSign: triggerSignFn = async ({
    gasPrice,
    gas,
    customNonceValue,
    id,
    maxFeePerGas,
    maxPriorityFeePerGas,
    txEnvelopeType,
    dynamicParams
  }: TriggerSignArgs) => {
    try {
      const bc = new BroadcastChannel(channel, broadcastChannelOptions);
      await bc.postMessage({
        data: {
          type: POPUP_RESULT,
          gasPrice,
          maxFeePerGas,
          maxPriorityFeePerGas,
          txEnvelopeType,
          gas,
          id,
          txType: newType,
          customNonceValue,
          approve: true,
          dynamicParams
        }
      });
      bc.close();
      window.close();
    } catch (error) {
      console.error(error);
    }
  };

  const triggerChangeProvider = async ({
    type,
    currentSelectedNetwork,
    payloadSelectedNetwork,
    approve
  }: {
    type: string;
    currentSelectedNetwork: NetworkType;
    payloadSelectedNetwork: NetworkType;
    approve: boolean;
  }) => {
    try {
      const bc = new BroadcastChannel(channel, broadcastChannelOptions);
      await bc.postMessage({
        data: {
          type: POPUP_RESULT,
          payloadType: type,
          currentSelectedNetwork,
          payloadSelectedNetwork,
          approve
        }
      });
      bc.close();
      window.close();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const init = async () => {
      const data = searchToObject(window.location.search) as any;
      const instanceId = data.instanceId;
      const queryParameterId = data.id;
      setChannel(`torus_channel_${instanceId}`);

      const bc = new BroadcastChannel(`torus_channel_${instanceId}`);
      bc.addEventListener("message", async (ev) => {
        const {
          type,
          msgParams,
          txParams,
          origin,
          balance,
          selectedCurrency,
          selectedAddress,
          whiteLabel,
          currencyData,
          network,
          networkDetails,
          gasFees,
          payload
        } = ev.data || {};

        whiteLabel?.logo ? setLogo(whiteLabel.logo) : setLogo(Logo);

        if (!selectedAddress) return;
        if (type === "provider_change") {
          setType(type);
          setCurrentConfirmModal({
            type,
            msgParams,
            origin,
            balance,
            selectedCurrency,
            whiteLabel,
            currencyData,
            network,
            networkDetails,
            gasFees,
            payload
          } as ConfirmModal);
          bc.close();
          return;
        }
        if (txParams && txParams.id.toString() !== queryParameterId) return;
        setType(type);
        setCurrentConfirmModal({
          type,
          msgParams,
          txParams,
          origin,
          balance,
          selectedCurrency,
          whiteLabel,
          currencyData,
          network,
          networkDetails,
          gasFees
        } as ConfirmModal);
        bc.close();
      });

      bc.postMessage({ data: { type: POPUP_LOADED, id: queryParameterId } });
    };

    init();
    if (!widgetIsOpen) {
      openPopup(false);
      setCurrentConfirmModal(null);
      uiDispatch("widgetIsOpen", false);
      dispatch(
        embedActions.setIFrameConfig({
          open: false,
          src: `${process.env.REACT_APP_BASE_URL}`,
          origin: "",
          topCalc: 3
        })
      );
    }
  }, []);

  return (
    <>
      {!newType ? (
        <div className="flex flex-col justify-center items-center h-screen">
          {logo && <img className="w-[70%] mb-8" src={logo} alt="upbond-logo" />}
          <IonSpinner name="crescent" color="primary" />
          <h2 className="text-2xl font-roboto mt-4.5 mb-2.5">Load confirmation data</h2>
        </div>
      ) : (
        <>
          <button
            className="!absolute !bg-none !z-[999999] right-2.5 top-2.5 text-black"
            onClick={async () => {
              await triggerDeny(2);
            }}>
            <svg
              width="27px"
              height="27px"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              color="#000000"
              viewBox="0 0 24 24"
              strokeWidth="1.8"
              stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          {currentConfirmModal !== null && (
            <ConfirmForm
              currentConfirmModal={currentConfirmModal}
              triggerSign={(__params__: any) => {
                if (__params__ && __params__.type && __params__.type === "provider_change") {
                  triggerChangeProvider(__params__);
                } else {
                  triggerSign(__params__);
                }
              }}
              triggerDeny={triggerDeny}
            />
          )}
        </>
      )}
    </>
  );
};

export default ConfirmPage;
