/* eslint-disable prettier/prettier */
import { useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import { useEffect, useState } from "react";
import { copyToClipboard, padPrivateKey } from "shared/utils/coreUtils";
import ChangeRecoveryCode from "features/settings/backup/components/changeRecoverCode";
import useLoading from "hooks/useLoading";
import { ShareSerializationModule, SHARE_SERIALIZATION_MODULE_NAME } from "@tkey/share-serialization";
import { _init, getTkeyInstance } from "shared/actions/olTkeyModuleAction";
import config from "shared/utils/openloginconfig";
import BackupMainMb from "features/settings/components/backupMain/mb";
import BackupMainPc from "features/settings/components/backupMain/pc";
import "./style.css";

const BackupPage = () => {
  const lcLang = localStorage.getItem("lang");
  const { breakpoint } = useTheme();
  const { setLoading } = useLoading();
  const { privateKey: privKey } = useAppSelector((state) => state.olDappModule);
  const { tKey, settingsPageData } = useAppSelector((state) => state.olTKeyModule);
  const { keyInfo } = useAppSelector((state) => state.olUserModule);
  const [open, setOpen] = useState(false);
  const [openPhrase, setOpenPhrase] = useState(false);
  const [onBlurPhrase, setOnBlurPhrase] = useState(false);
  const [hidePrivKey, setHidePrivKey] = useState(false);
  const [enablePhraseAndPasscode, setEnablePhraseAndPasscode] = useState(true);
  const [openRecoveryBackupMenu, setOpenRecoveryBackupMenu] = useState(false);
  const [phrases, setPhrases] = useState<string[]>([]);

  useEffect(() => {
    tKeySetter();
  }, []);

  const tKeySetter = async () => {
    try {
      await _init({ postboxKey: keyInfo?.privateKey, tKeyJson: undefined, shareStores: [] });
      const localTkey = await getTkeyInstance();
      if (Object.keys(tKey)?.length > 0 && phrases?.length == 0 && settingsPageData?.deviceShare?.share) {
        const shareStore = settingsPageData?.deviceShare?.share;
        const serializeShare = await (localTkey?.modules[SHARE_SERIALIZATION_MODULE_NAME] as ShareSerializationModule)?.serialize(
          shareStore?.share?.share,
          "mnemonic"
        );
        setPhrases(serializeShare ? (serializeShare as string).split(" ") : []);
      }
    } catch (error) {
      console.log("@error", error);
      setEnablePhraseAndPasscode(false);
      console.error(error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (phrases?.length === 0) {
        tKeySetter();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [phrases, tKeySetter]);

  useEffect(() => {
    let a = 0;
    if (open) {
      a = window.setTimeout(function () {
        setOpen(false);
      }, 500);
    } else {
      clearTimeout(a);
    }
  }, [open]);

  useEffect(() => {
    let a = 0;
    if (openPhrase) {
      a = window.setTimeout(function () {
        setOpenPhrase(false);
      }, 500);
    } else {
      clearTimeout(a);
    }
  }, [openPhrase]);

  const inputShare = async () => {
    setOpenRecoveryBackupMenu(true);
  };

  return (
    <>
      <ChangeRecoveryCode openModal={openRecoveryBackupMenu} closeModal={() => setOpenRecoveryBackupMenu(false)} />

      {breakpoint < 1024 ? (
        <BackupMainMb
          phrases={phrases}
          openPhrase={openPhrase}
          setOpenPhrase={setOpenPhrase}
          enablePhraseAndPasscode={enablePhraseAndPasscode}
          setLoading={setLoading}
          inputShare={inputShare}
          onBlurPhrase={onBlurPhrase}
          setOnBlurPhrase={setOnBlurPhrase}
          lcLang={lcLang}
          copyToClipboard={copyToClipboard}
          config={config}
          privKey={privKey}
          hidePrivKey={hidePrivKey}
          setHidePrivKey={setHidePrivKey}
          padPrivateKey={padPrivateKey}
          open={open}
          setOpen={setOpen}
        />
      ) : (
        <BackupMainPc
          phrases={phrases}
          openPhrase={openPhrase}
          setOpenPhrase={setOpenPhrase}
          setOpenRecoveryBackupMenu={setOpenRecoveryBackupMenu}
          enablePhraseAndPasscode={enablePhraseAndPasscode}
          onBlurPhrase={onBlurPhrase}
          setOnBlurPhrase={setOnBlurPhrase}
          lcLang={lcLang}
          copyToClipboard={copyToClipboard}
          config={config}
          privKey={privKey}
          hidePrivKey={hidePrivKey}
          setHidePrivKey={setHidePrivKey}
          padPrivateKey={padPrivateKey}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
};

export default BackupPage;
