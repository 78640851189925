const possibilities = {
  lowerCased: "abcdefghijklmnopqrstuvwxyz",
  capitals: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  numbers: "0123456789",
  special: "~!@#$%^&()_+-={}[];',"
} as any;

export function randomId(len?: number, pattern?: string) {
  if (!len) len = 30;
  if (!pattern) pattern = "aA0";

  let chars = "";

  pattern.split("").forEach((a) => {
    if (!isNaN(parseInt(a))) {
      chars += possibilities.numbers;
    } else if (/[a-z]/.test(a)) {
      chars += possibilities.lowerCased;
    } else if (/[A-Z]/.test(a)) {
      chars += possibilities.capitals;
    } else {
      chars += possibilities.special;
    }
  });

  let result = "";

  for (let i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return result;
}

const MAX = Number.MAX_SAFE_INTEGER;

let idCounter = Math.round(Math.random() * MAX);
function createRandomId() {
  idCounter %= MAX;
  idCounter += 1;
  return idCounter;
}

export default createRandomId;
