// @ts-nocheck
import { IonButton, IonList, IonLoading, IonModal } from "@ionic/react";
import useTheme from "hooks/useTheme";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "hooks/useRedux";
import { selectPermission, updateDid } from "shared/actions/permissionAction";
import { RootState } from "shared/store";
import permissionServices from "services/PermissionServices";
import { useEffect, useState } from "react";
import Web3Token from "web3-token";
import useWallet from "hooks/useWallet";
import { decryptMessage } from "shared/utils/etherUtils";
import PermissionManageMainMb from "features/settings/components/permissionManageMain/mb";
import PermissionManageMainPc from "features/settings/components/permissionManageMain/pc";
import "./style.css";

const PermissionManagePage = () => {
  const { t } = useTranslation();
  const { breakpoint } = useTheme();

  const dispatch = useAppDispatch();
  const [permissions, setListPermission] = useState<any>();
  const [web3Token, setWeb3Token] = useState<string>("");
  const [hash, setHash] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [did, setDid] = useState<string>("");
  const [didData, setDidData] = useState<any>({});

  const [errorDidMsg, setErrorDidMsg] = useState<string>("");
  const [isErrorDid, setErrorDid] = useState<boolean>(false);
  // const { permissions } = useSelector((state: RootState) => state.permissions);
  const { selectedAddress, wallet } = useSelector((state: RootState) => state.wallet);
  const privKey = `0x${wallet[selectedAddress].privateKey}`;
  const { ethWallet } = useWallet();

  const checkPermission = async () => {
    try {
      const subscribe = await permissionServices.subscribe(selectedAddress);
      if (subscribe) setListPermission(subscribe.data);
      const { result } = await permissionServices.getDid(selectedAddress);
      if (result) dispatch(updateDid(result));
    } catch (err: any) {
      setErrorDidMsg("Did not ready yet, please try again");
      setIsLoading(false);
      setErrorDid(true);
      // if (err.response.data.message == "please create DID") {
      //   history.push("/permission");
      // }
      throw err;
    }
  };

  const getDid = async () => {
    try {
      setIsLoading(true);
      const get = await permissionServices.getDid(selectedAddress);
      if (get.result) {
        setDid(get.result);
      }

      const getHash = await permissionServices.getHash(selectedAddress, web3Token);
      if (getHash.data._hash) setHash(getHash.data._hash);
      else setIsLoading(false);
    } catch (err) {
      setErrorDidMsg("Did not ready yet, please try again");
      setIsLoading(false);
      setErrorDid(true);
      throw err;
    }
  };

  const signKey = async (newKey: string) => {
    try {
      const date = new Date();
      date.setDate(date.getDate() + 7);
      const deadline = Math.floor(date.getTime() / 1000);
      const signKey = await permissionServices.signMultiKeyDID(privKey, did, selectedAddress, [newKey], deadline);
      const getData = await permissionServices.getMultiData(selectedAddress, [newKey], deadline, signKey.v, signKey.r, signKey.s, did);
      const byte32Data = await permissionServices.bytesToString(getData.result);
      if (byte32Data.result) {
        const userData = await permissionServices.getDidS3(byte32Data.result, web3Token);
        const testData = userData.data;
        const data: any = {};
        Object.keys(testData).forEach((x) => {
          if (isJson(testData[x])) data[x] = decryptMessage(JSON.parse(testData[x]), wallet[selectedAddress].privateKey);
        });
        setDidData(data);
      }

      setIsLoading(false);
    } catch (err: any) {
      setErrorDidMsg("failed to sign key : " + err.message);
      setIsLoading(false);
      setErrorDid(true);
      throw err;
    }
  };

  const isJson = (data: string) => {
    try {
      JSON.parse(data);
      return true;
    } catch {
      return false;
    }
  };

  useEffect(() => {
    checkPermission();
  }, []);

  const init = async () => {
    getDid();
  };
  const getWeb3Token = async () => {
    try {
      if (ethWallet) {
        const signWeb3token = await Web3Token.sign(async (msg: string) => {
          return await ethWallet.signMessage(msg);
        }, "7d");
        setWeb3Token(signWeb3token);
      }
    } catch (err: any) {
      setErrorDidMsg(err.message);
      setIsLoading(false);
      setErrorDid(true);
    }
  };

  useEffect(() => {
    getWeb3Token();
  }, [ethWallet]);

  useEffect(() => {
    if (web3Token) init();
  }, [web3Token]);

  useEffect(() => {
    if (hash) signKey(hash);
  }, [hash]);

  return (
    <>
      <IonLoading cssClass="my-custom-class" isOpen={isLoading} spinner={"circles"} />

      <IonModal id="modal-error-c" className="modal-gas-confirm" trigger="open-modal" isOpen={isErrorDid}>
        <div className="wrapper ion-padding modal-gas-confirm-wrapper text-center">
          <IonList lines="none" className="ion-text-center ion-padding">
            {errorDidMsg}
          </IonList>
          <IonButton shape="round" color="primary" expand="full" onClick={() => setErrorDid(false)}>
            {t("account.close")}
          </IonButton>
        </div>
      </IonModal>

      {breakpoint < 1024 ? (
        <PermissionManageMainMb didData={didData} permissions={permissions} selectPermission={selectPermission} />
      ) : (
        <PermissionManageMainPc didData={didData} permissions={permissions} selectPermission={selectPermission} />
      )}
    </>
  );
};

export default PermissionManagePage;
