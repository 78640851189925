// @ts-nocheck
/* eslint-disable jsx-a11y/media-has-caption */
import { IonModal, IonContent, IonThumbnail, IonCardTitle, IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { HomeListPopupProps } from "features/home/types";

const HomeListPopupMb = (props: HomeListPopupProps) => {
  const { t } = useTranslation();

  return (
    <IonModal
      isOpen={props.isOpen}
      initialBreakpoint={window.innerHeight < 600 ? 0.92 : window.innerHeight < 700 ? 0.92 : 0.7}
      breakpoints={[0, window.innerHeight < 600 ? 1 : window.innerHeight < 700 ? 0.96 : 0.7]}
      backdropDismiss={props.isOpen}
      backdropBreakpoint={0}
      onDidDismiss={() => props.setOpen(false)}
      trigger="open-modal">
      <IonContent className="ion-padding">
        <IonThumbnail className="h-auto w-auto flex justify-center rounded-2xl overflow-hidden">
          {props.nftData?.animation_url ? (
            <video className="h-[382px]" autoPlay controls>
              <source src={props.nftData?.animation_url} />
            </video>
          ) : (
            props.nftData?.image && (
              <img
                className="object-contain h-[382px]"
                alt={props.nftData?.name}
                src={props.nftData?.image && props.nftData.image ? props.nftData.image : window.location.origin + "/assets/noimg.png"}
              />
            )
          )}
        </IonThumbnail>
        <IonCardTitle className="font-normal text-lg leading-[21px] my-2.5">
          {props.nftData?.name && props.nftData?.name.length > 35 ? props.nftData?.name.substring(0, 35) + "..." : props.nftData?.name}
        </IonCardTitle>
        <IonCardTitle className="font-semibold text-sm leading-4 text-[#3d3d3d] mt-2 mb-6">
          {props.nftData?.collectionName ?? t("home.undefinedCollection")}
        </IonCardTitle>
        {props.nftType !== "erc1155" && (
          <IonButton shape="round" color="primary" expand="full" href={props.nftData?.link} target="_blank">
            {t("account.seeDetails")}
          </IonButton>
        )}
      </IonContent>
    </IonModal>
  );
};

export default HomeListPopupMb;
