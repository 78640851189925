// @ts-nocheck
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonList,
  IonButtons,
  IonButton,
  IonIcon,
  IonLabel,
  IonAvatar,
  IonText,
  useIonRouter
} from "@ionic/react";
import HeaderHistory from "features/account/components/HeaderHistory";
import AccountHistoryContent from "features/account/components/AccountHistoryContent";
import ProfilePicture from "features/account/components/ProfilePicture";
import { UpbondUserInfo } from "shared/store/walletSlice";
import { copyToClipboard } from "shared/utils/coreUtils";
import { useTranslation } from "react-i18next";
import useTheme from "hooks/useTheme";
import Cog from "assets/icons/Cog.svg";
import { addOutline, copyOutline, qrCodeOutline } from "ionicons/icons";
import { TransactionItems } from "interfaces/actions/IHistoryTransaction";
import { MoralisTransactionResult } from "interfaces/actions/moralis/IMoralisHistoryTransaction";
import { IAstarUseState, IMoralisUseState } from "interfaces/libs/IAccountHistory";

type Props = {
  user: UpbondUserInfo;
  account: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  astarItems: IAstarUseState;
  moralisItems: IMoralisUseState;
  handleModal: (data: TransactionItems, isSeeDetail: boolean) => void;
  handleModalMoralis: (data: MoralisTransactionResult, isSeeDetail: boolean) => void;
  isAstar: boolean;
  setOpenModalHistory: (open: boolean) => void;
  setOpenModalPurchase: (open: boolean) => void;
  setOpenModalQRCode: (open: boolean) => void;
  detailsLoading: boolean;
};

const HistoryContentPc = (props: Props) => {
  const { t } = useTranslation();
  const { breakpoint } = useTheme();
  const history = useIonRouter();

  return (
    <IonGrid>
      <IonRow>
        <IonCol sizeLg="6" sizeXl="6" sizeMd="4" sizeSm="4" sizeXs="12" className="no-padding">
          <IonCard className="card-home scroll-y card-history">
            <IonCardContent>
              <IonList lines="full" className="list ion-content-scroll-host scroller">
                <HeaderHistory />
                <AccountHistoryContent
                  astarItems={props.astarItems}
                  handleModalAstar={props.handleModal}
                  handleModalMoralis={props.handleModalMoralis}
                  isAstar={props.isAstar}
                  moralisItems={props.moralisItems}
                  setOpenModalHistory={props.setOpenModalHistory}
                  detailsLoading={props.detailsLoading}
                />
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol sizeLg="6" sizeXl="6" sizeMd="4" sizeSm="4" sizeXs="12" className="no-padding">
          <IonButtons className="buttons-home">
            <IonButton
              slot="end"
              className=" bg-gray-light flex justify-between rounded-[20px] p-[2px] gap-y-[2px] text-black font-bold"
              onClick={() => history.push(breakpoint < 1024 ? "/settings" : "/wallet/settings", "forward", "push")}>
              <IonIcon color="dark" src={Cog} size="small" />
              <IonLabel className="ml-[5%] font-bold">{t("settings.title")}</IonLabel>
            </IonButton>
          </IonButtons>
          <IonCard className="card-home scroll-y mh-80vh">
            <IonCardContent>
              <div className="flex flex-col justify-center">
                <IonAvatar class="min-w-[60px] min-h-[60px] self-center">
                  <ProfilePicture userData={props.user} />
                </IonAvatar>
                <IonText color={"dark"} className="font-bold text-center">
                  <h2>
                    <strong>{props.user?.name}</strong>
                  </h2>
                  <span>{props.account}</span>
                </IonText>
                <div className="flex justify-center">
                  <IonButton
                    shape="round"
                    className="copy-setting font-bold cursor-pointer flex items-center text-sm"
                    onClick={() => {
                      copyToClipboard(props.account);
                      props.setOpen(!props.open);
                    }}>
                    <IonIcon slot="start" icon={copyOutline} />
                    {t("account.copy")}
                  </IonButton>
                  {props.open && (
                    <div className="tooltip-set" style={{ left: localStorage.getItem("lang") === "en" ? -100 : -70 }}>
                      <div className="bottom" style={{ minWidth: localStorage.getItem("lang") === "en" ? 128 : 210 }}>
                        {t("account.copiedToClipboard")}
                        <i></i>
                      </div>
                    </div>
                  )}
                  <IonButton
                    shape="round"
                    className="copy-setting font-bold cursor-pointer flex items-center text-sm"
                    onClick={() => props.setOpenModalQRCode(true)}
                    style={{ marginLeft: 12 }}>
                    <IonIcon slot="start" icon={qrCodeOutline} />
                    {t("account.receive")}
                  </IonButton>
                </div>
                <IonButton
                  shape="round"
                  color="primary"
                  className="btn-cash w-[122px] font-bold h-[36px] p-0 m-auto flex items-center text-sm"
                  onClick={() => props.setOpenModalPurchase(true)}>
                  <IonIcon slot="start" icon={addOutline} />
                  {t("account.add")}
                </IonButton>
              </div>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default HistoryContentPc;
