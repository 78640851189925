// @ts-nocheck
import { IonContent, IonItem, IonLabel, IonList, IonPage } from "@ionic/react";
import HeaderMainMb from "components/header/mb";
import { useTranslation } from "react-i18next";

const PersonalInformationMainMb: React.FC<any> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <HeaderMainMb title={t("settings.personal")} link="/settings/init" />

      <IonContent className="ion-padding setting-content">
        <IonList className="backup-list px-4">
          <IonItem className="personal-wrapper font-normal text-base leading-[19px] text-[#5d5d5d]">
            <IonLabel id="title" position="stacked" className="font-semibold text-base leading-[19px] !text-black">
              {t("settings.name")}
            </IonLabel>
            <IonLabel position="stacked">{user.name}</IonLabel>
          </IonItem>
          <IonItem className="personal-wrapper font-normal text-base leading-[19px] text-[#5d5d5d]">
            <IonLabel id="title" position="stacked" className="font-semibold text-base leading-[19px] !text-black">
              {t("settings.email")}
            </IonLabel>
            <IonLabel position="stacked">{user.email}</IonLabel>
          </IonItem>
        </IonList>

        {/* <div className="mt-6 px-3">
          <IonButton
            expand="block"
            class="h-12"
            color={"primary"}
            onClick={() => history.push("/settings/personal-information-edit", "forward", "push")}>
            {t("settings.edit")}
          </IonButton>
        </div> */}
      </IonContent>
    </IonPage>
  );
};

export default PersonalInformationMainMb;
