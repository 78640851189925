// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from "react";
import { IonContent, IonPage } from "@ionic/react";
import CustomAuth from "@toruslabs/customauth";
import { Chevron, Loader } from "assets/icons/";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useHistory } from "react-router-dom";
import { setPreferencesAndRedirect } from "shared/actions/olDappModuleAction";
import { UpdateLoginRecord, addOrUpdateLoginRecord, olLoginPerfModuleActions } from "shared/actions/olLoginPerfModuleAction";
import { inputPassword, makeShareTransferRequest, olTkeyModuleAction } from "shared/actions/olTkeyModuleAction";
import { store } from "shared/store";
import { AUTH_FACTOR, TKEY_ADD_PASSWORD_OP, TKEY_REQUIRE_MORE_INPUT } from "shared/utils/openloginenums";
import log from "loglevel";

import { LoginType } from "shared/utils/openlogininterface";
import { getJoinedKey } from "shared/utils/openloginutils";
import { PinInputActions, usePinInput } from "react-pin-input-hook";
import config from "shared/utils/openloginconfig";
import { setAuthToken } from "shared/utils/apollo";
import { useTranslation } from "react-i18next";
import useTheme from "hooks/useTheme";
import DesktopInput from "./pc_input";
import "./style.css";

const TkeyPage: React.FC = () => {
  const { breakpoint } = useTheme();
  const { t } = useTranslation();
  const [disable, setDisabled] = React.useState<boolean>(true);
  const history = useHistory();
  const { olUserModule, olDeviceModule, olLoginPerfModule, olAllDappModule } = useAppSelector((state) => state);
  const [popupWindow, setPopupWindow] = React.useState<boolean>(false);
  const [pid, setPid] = React.useState<string>("");
  const [customAuthInstanceId, setCustomAuthInstanceId] = React.useState<string>("");
  const [incorrectPassword, setIncorrectPassword] = React.useState<boolean>(false);
  const [passwordInputLoading, setPasswordInputLoading] = React.useState<boolean>(false);
  const [disableAlwaysSkip, setDisableAlwaysSkip] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<any>("");
  const [focus, setFocus] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [values, setValues] = React.useState(Array(4).fill(""));
  const [error, setError] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const ref = useRef<any>();
  const actionRef = React.useRef<PinInputActions>(null);

  const { fields } = usePinInput({
    values,
    onChange: (values) => {
      // console.log("TEST VALUE", values);
      if (values.includes("")) {
        // Setting the error.
        setDisabled(true);
        setFocus(false);

        // We set the focus on the first empty field if `error: true`
        // was passed as a parameter in `options`.
      }
      setValues(values);
    },

    onComplete(value) {
      setValue(value);
      setFocus(true);
      setDisabled(false);
      ref.current.childNodes.forEach((el: any) => el.blur());
    },
    error,
    actionRef
  });

  const customAuth = new CustomAuth({
    baseUrl: window.location.origin,
    redirectPathName: "regis-tkey-input",
    uxMode: "redirect",
    network: process.env.REACT_APP_TORUS_NETWORK as any,
    enableLogging: true,
    // locationReplaceOnRedirect: true,

    // v2
    metadataUrl: config.metadataHost,
    enableOneKey: true,
    // networkUrl: config.networkUrl
    storageServerUrl: config.storageServerUrl,
    web3AuthClientId: (olUserModule.currentDappClientId as any) || (process.env.REACT_APP_CLIENT_ID as any)
  });

  const confirm = async () => {
    // console.log("LAST GATE:", value);
    if (!confirmLoading) {
      inputPass(value);
    }
  };

  const onboardingOrRedirect = async (
    keys: {
      privKey: string;
    }[]
  ): Promise<void> => {
    redirect(keys);
  };

  const redirect = (
    keys: {
      privKey: string;
    }[]
  ) => {
    if (customAuthInstanceId) customAuth.storageHelper.clearLoginDetailsStorage(customAuthInstanceId);
    const isUsingDirect = localStorage.getItem("isUsingDirect");
    const dappRedirectUri = localStorage.getItem("dappRedirectUri")
      ? decodeURIComponent(localStorage.getItem("dappRedirectUri") as string)
      : "";

    setPreferencesAndRedirect({
      popupWindow: popupWindow,
      pid: pid,
      embedWlRedirectUrl: olAllDappModule.dappModules[olUserModule.currentDappClientId].embedWlRedirectUrl,
      redirectUrl: `${window.location.origin}/end`,
      dappRedirectUri: dappRedirectUri || olAllDappModule.dappModules[olUserModule.currentDappClientId]?.usingDirectConfig?.directUrl,
      isUsingDirect: isUsingDirect
        ? isUsingDirect === "true"
        : olAllDappModule.dappModules[olUserModule.currentDappClientId]?.directConfig?.usingDirect,
      result: {
        tKey: keys[0].privKey,
        oAuthPrivateKey: olUserModule.keyInfo.privateKey,
        walletKey: store.getState().olTKeyModule.keyMode === "v1" ? olUserModule.walletKeyInfo.privateKey : keys[0].privKey
      },
      verifier: olUserModule.userInfo.aggregateVerifier,
      verifierId: olUserModule.userInfo.verifierId,
      disableAlwaysSkip: disableAlwaysSkip
    });
  };

  const listenForShareTransfer = async () => {
    try {
      console.log("listening for share transfer");
      const keys = await makeShareTransferRequest();
      dispatch(olLoginPerfModuleActions.addAuthFactor(AUTH_FACTOR.SHARE_TRANSFER));
      onboardingOrRedirect(keys);
    } catch (error: unknown) {
      log.error(error);
      if ((error as Error).message?.includes(TKEY_REQUIRE_MORE_INPUT)) {
        // userModule.setToastMessage({ message: t("common.toast-require-verification"), type: TOAST_TYPES.WARNING });
        listenForShareTransfer();
      } else {
        await UpdateLoginRecord({
          errorStack: (error as Error)?.stack || (error as Error).message || "Something went wrong",
          mobileOrigin: olAllDappModule.dappModules[olUserModule.currentDappClientId].mobileOrigin
        });
        // userModule.setToastMessage({ message: t("common.toast-share-transfer-fail"), type: TOAST_TYPES.ERROR });
      }
    }
  };

  const inputPass = async (password: string) => {
    const operationName = TKEY_ADD_PASSWORD_OP;
    window.performance.mark(`${operationName}_start`);

    setPasswordInputLoading(true);
    setConfirmLoading(true);

    try {
      dispatch(olTkeyModuleAction.updateState({ keyMode: "2/n" }));
      await addOrUpdateLoginRecord({
        clientId: olAllDappModule.dappModules[olUserModule.currentDappClientId].clientId,
        dappUrl: olAllDappModule.dappModules[olUserModule.currentDappClientId].redirectUrl,
        verifier: olUserModule.userInfo.aggregateVerifier,
        verifierId: olUserModule.userInfo.verifierId,
        loginRoute: "regist-auth",
        loginType: "2/n" as LoginType,
        hasSkippedMfa: false,
        isLoginCompleted: true,
        mfaLevel: "default",
        mobileOrigin: olAllDappModule.dappModules[olUserModule.currentDappClientId].mobileOrigin,
        sessionId: olAllDappModule.dappModules[olUserModule.currentDappClientId].sessionId
      });
      const keys = await inputPassword(password);

      await UpdateLoginRecord({
        isLoginCompleted:
          olDeviceModule.onboardingMap[getJoinedKey(olUserModule.userInfo.aggregateVerifier, olUserModule.userInfo.verifierId)] ||
          store.getState().olTKeyModule.keyMode !== "v1",
        mobileOrigin: olAllDappModule.dappModules[olUserModule.currentDappClientId].mobileOrigin
      });

      olLoginPerfModuleActions.addAuthFactor(AUTH_FACTOR.PASSWORD);
      olLoginPerfModuleActions.markRouteAndTime({
        route: "tkeyInput",
        operation: operationName
      });

      await onboardingOrRedirect(keys);
      setConfirmLoading(false);
    } catch (error: unknown) {
      log.error(`Could not input your password: `, error, {
        currModule: olAllDappModule.dappModules[olUserModule.currentDappClientId],
        tkey: store.getState().olTKeyModule.tKey
      });
      setIncorrectPassword(true);
      setConfirmLoading(false);
    } finally {
      setPasswordInputLoading(false);
      setConfirmLoading(false);
    }
  };

  useEffect(() => {
    setAuthToken(olUserModule.authToken);
  }, []);

  return (
    <IonPage className="sm:w-[500px] w-screen h-full sm:h-screen py-2 px-4 mx-auto flex justify-start sm:justify-center xs:items-center items-start  flex-col sm:flex-row">
      <IonContent className="w-full sm:w-1/4">
        <div className="lg:flex-col lg:flex py-10">
          <h1 className="headline self-start px-4">{t("pin_input.tkey_input_1")}</h1>
          <div className="p-4">
            {breakpoint < 1024 ? (
              <div className="pin-input" ref={ref}>
                {fields.map((fieldProps: any, index: any) => (
                  <input
                    key={index}
                    id={`pin-${index + 1}`}
                    className={`pin-input__field font-poppins font-28 max-w-max w-full h-[80px] text-center otp-field-box--${index} ${
                      focus ? "border-2" : "border"
                    }  border-[#${focus ? "4d4082" : "d7d7d7"}]`}
                    style={{ borderWidth: focus ? 2 : "", borderColor: focus ? "#4d4082" : "" }}
                    maxLength={1}
                    {...fieldProps}
                    placeholder=""
                    onFocus={(e: any) => e.target.select()}
                  />
                ))}
              </div>
            ) : (
              <DesktopInput setDisabled={setDisabled} setValue={setValue} />
            )}
            {incorrectPassword && <p className="text-red-500 font-poppins font-sm mb-4 text-center">{t("pin_input.tkey_input_3")}</p>}
          </div>

          <div className="p-4 -mt-5">
            <button
              id="confirm"
              className="bconfirm-2 h-[48px] w-full rounded-3xl font-poppins flex justify-center items-center "
              onClick={confirm}
              disabled={disable}>
              {!passwordInputLoading ? t("pin_input.confirm") : <img src={Loader} alt="spinner" className="spinner" />}
            </button>

            <div className="flex flex-row  w-full items-center py-4  font-poppins font-normal mt-4 mb-4">
              <hr className={`${localStorage.getItem("lang") === "en" ? "w-[50%]" : "w-[41%] sm:w-[44%]"} border-gray-300 border`} />{" "}
              <span className="min-w-[60px] text-center"> {t("pin_input.or")} </span>
              <hr className={`${localStorage.getItem("lang") === "en" ? "w-[50%]" : "w-[41%] sm:w-[44%]"} border-gray-300 border`} />
            </div>

            <button
              className="bg-[#214999] h-[48px] w-full rounded-3xl text-[#f3f3f3] font-poppins font-semibold flex justify-center items-center"
              onClick={() => history.push("/phrase-input")}>
              <span>{t("pin_input.tkey_input_2")} </span>
              <img src={Chevron} alt="chevron" className="h-[20px] text-white stroke-white" />
            </button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TkeyPage;
