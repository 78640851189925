// @ts-nocheck
import { IonButton, IonIcon } from "@ionic/react";
import Notification from "assets/icons/Notifications.svg";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import { setOpenModalPurchase } from "shared/actions/walletAction";
import useTheme from "hooks/useTheme";

const PendingComponent: React.FC<any> = () => {
  const { breakpoint } = useTheme();
  const [countNotif, setCountNotif] = useState(0);
  const walletConnect = useAppSelector((state) => state.walletConnect);
  const walletConnectArr = useAppSelector((state) => state.walletConnectArrData);
  const appDispatch = useAppDispatch();

  useEffect(() => {
    setCountNotif(walletConnectArr.dataArr.length);
    appDispatch(setOpenModalPurchase(false));
  }, [walletConnectArr.dataArr]);

  const isApplication = window.location.pathname.includes("application");

  return (
    <>
      {countNotif > 0 && walletConnect.connected === true ? (
        <span
          className={`absolute bg-[#fff] border border-solid border-red-500 rounded-full w-4 h-4 text-black text-[10px] flex items-center text-center font-bold justify-center z-50 
            ${breakpoint < 768 ? (isApplication ? "-right-[3px] -top-[2px]" : "right-4 top-4") : "right-4 top-4"}
          `}>
          {countNotif}
        </span>
      ) : null}
      <IonButton routerLink={"/pending-transaction"} routerDirection="forward" color="primary" className="notifications h-[36px]">
        <IonIcon slot="icon-only" src={Notification} className="notificon" />
      </IonButton>
    </>
  );
};

export default PendingComponent;
