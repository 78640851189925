import { gql } from "@apollo/client/core";

export const GET_MESSAGE = gql`
  mutation Message($public_address: String!, $client_time_offset: Float!, $session_nonce: String) {
    res: getMessage(input: { public_address: $public_address, client_time_offset: $client_time_offset, session_nonce: $session_nonce }) {
      message
      time_offset
    }
  }
`;

export const VERIFY_MESSAGE = gql`
  mutation ValidateMessage(
    $public_address: String!
    $signed_message: String!
    $verifier: String!
    $verifier_id: String!
    $network: String!
    $session_nonce: String
  ) {
    token: verifyMessage(
      input: {
        public_address: $public_address
        signed_message: $signed_message
        verifier: $verifier
        verifier_id: $verifier_id
        network: $network
        session_nonce: $session_nonce
      }
    )
  }
`;

export const GET_EXTERNAL_AUTH_TOKEN = gql`
  mutation GetAuthToken(
    $app_public_key: String!
    $client_id: String!
    $timeout: Float
    $curve: String!
    $email: String
    $profile_image: String
    $name: String
    $verifier: String
    $aggregate_verifier: String
    $type_of_login: String
    $verifier_id: String
    $oauth_public_key: String
    $app_signed_message: String
    $session_nonce: String
  ) {
    res: getExternalAuthToken(
      input: {
        app_public_key: $app_public_key
        timeout: $timeout
        client_id: $client_id
        curve: $curve
        email: $email
        name: $name
        type_of_login: $type_of_login
        profile_image: $profile_image
        verifier: $verifier
        aggregate_verifier: $aggregate_verifier
        verifier_id: $verifier_id
        oauth_public_key: $oauth_public_key
        app_signed_message: $app_signed_message
        session_nonce: $session_nonce
      }
    ) {
      token
    }
  }
`;

export const ME = gql`
  query currentUser {
    me
  }
`;

export const GET_SERVER_TIME_OFFSET = gql`
  query getServerTimeOffset($clientTime: Float!) {
    offset: getServerTimeOffset(clientTime: $clientTime)
  }
`;

export const VERIFY_AND_REGISTER = gql`
  mutation verifyMessageAndRegister($verify_register_payload: VerifyAndRegisterPayload!) {
    res: verifyMessageAndRegister(payload: $verify_register_payload) {
      user {
        public_address
        verifier
        verifier_id
        tkey_public_address
        tkey_threshold
        tkey_password
        tkey_backup_emails
        tkey_creation_factor
        theme
        locale
        always_skip_tkey
        v2_wallet_key_enabled
        backup_phrase_setup_at
        backup_phrase_setup_email
      }
      device {
        id
        share_index
        webauthn_enabled
        webauthn_available
        public_address
      }
      userDapp {
        id
        dapp_id
        public_address
        dapp_public_key
      }
      idToken
    }
  }
`;
