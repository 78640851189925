import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginPerfModule } from "interfaces/actions/IOILoginPerfModule";
import { AUTH_FACTOR_TYPE } from "shared/utils/openloginenums";
import { measurePerformance, measurePerformanceAndRestart } from "shared/utils/openloginutils";

const initBalanceState: LoginPerfModule = {
  authFactorsUsed: [],
  currentLoginPath: "",
  dappId: 0,
  lastRoute: "",
  loginRecordId: "",
  totalMfaTimeTaken: 0,
  totalTimeTaken: 0
};
const balanceSlice = createSlice({
  name: "balance",
  initialState: initBalanceState,
  reducers: {
    _reinit(state) {
      state.currentLoginPath = "";
      state.loginRecordId = "";
      state.lastRoute = "";
      state.totalMfaTimeTaken = 0;
    },
    resetDappId(state) {
      state.dappId = 0;
      state.authFactorsUsed = [];
    },
    incrementTotalTime(state, action: PayloadAction<{ timeTaken: number }>) {
      state.totalTimeTaken += action.payload.timeTaken;
    },
    incrementTotalMfaTime(state, action: PayloadAction<{ operationName: string }>) {
      const timeTaken = measurePerformance(action.payload.operationName);
      state.totalTimeTaken += timeTaken;
      state.totalMfaTimeTaken += timeTaken;
    },
    markRouteAndTime(
      state,
      action: PayloadAction<{ route: string; restartPerfMeasurement?: boolean; isEnd?: boolean; operation?: string }>
    ) {
      const { route, operation, restartPerfMeasurement } = action.payload;
      let timeTaken = 0;
      if (restartPerfMeasurement && operation) {
        timeTaken = measurePerformanceAndRestart(operation);
      } else if (operation) {
        timeTaken = measurePerformance(operation);
      }
      if (state.lastRoute !== route) {
        state.currentLoginPath = state.currentLoginPath ? `${state.currentLoginPath}|${route}` : route;
      }
      state.lastRoute = route;
      state.totalTimeTaken += timeTaken;
    },
    addAuthFactor(state, action: PayloadAction<AUTH_FACTOR_TYPE>) {
      if (state.authFactorsUsed.indexOf(action.payload) < 0) state.authFactorsUsed.push(action.payload);
    },
    updateState(state, action: PayloadAction<{ currentLoginPath?: string; loginRecordId?: string; dappId?: number }>) {
      const { currentLoginPath, loginRecordId, dappId } = action.payload;
      if (currentLoginPath) state.currentLoginPath = currentLoginPath;
      if (loginRecordId) state.loginRecordId = loginRecordId;
      if (dappId) state.dappId = dappId;
    }
  }
});

export default balanceSlice;
