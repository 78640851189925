// @ts-nocheck
import { IonButton, IonContent, IonIcon, IonItem, IonLabel, IonModal } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks/useRedux";
import { chevronDown } from "ionicons/icons";
import { ConfirmPopupProps } from "features/transfer/confirm/types";

const ConfirmPopupPc = (props: ConfirmPopupProps) => {
  const { t } = useTranslation();
  const transfer = useAppSelector((state) => state.transfer);
  const contact = useAppSelector((state) => state.contact);

  return (
    <IonModal
      className="modal-transfer-done"
      isOpen={props.openConfirm}
      onDidDismiss={() => {
        props.setOpenConfirm(false);
      }}>
      <IonContent className="ion-padding">
        <IonItem className="shadow-itemListShadow rounded-2xl border-b-0 p-4 mb-3" lines="none">
          <IonLabel className="font-bold ps-4">
            <h1 className="!text-base !font-bold !font-inter">{transfer.detail?.contract_name}</h1>
            <p className="!text-sm !font-medium !font-poppins">
              {props.totalSend.toString().substring(0, 9)} {transfer.detail?.contract_ticker_symbol}
            </p>
          </IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonLabel className="font-bold ps-4">{t("transfer.to")}</IonLabel>
          <IonIcon icon={chevronDown} color="dark" />
        </IonItem>
        <IonItem className="shadow-itemListShadow rounded-2xl border-b-0 p-4 my-3" lines="none">
          <IonLabel className="font-bold ps-4">{contact.selectedContact?.name}</IonLabel>
          {/* <div>{contact.selectedContact?.address}</div> */}
        </IonItem>
        <IonButton
          shape="round"
          color="primary"
          expand="full"
          onClick={() => {
            props.confirmTransaction();
          }}>
          {t("transfer.confirmTransfer")}
        </IonButton>
      </IonContent>
    </IonModal>
  );
};

export default ConfirmPopupPc;
