// @ts-nocheck
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { checkmarkOutline } from "ionicons/icons";
import HeaderMainMb from "components/header/mb";

const NetworkMainMb: React.FC<any> = ({ statusShow, handleChangeNetwork }) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <HeaderMainMb title={t("settings.justNetwork")} link="/settings/init" />

      <IonContent className="ion-padding setting-content">
        <IonList className="backup-list px-4">
          <IonItem className="pb-[15px]">
            <IonLabel onClick={() => handleChangeNetwork(false)}>{t("settings.hideTestnets")}</IonLabel>
            {statusShow === false && <IonIcon src={checkmarkOutline} className="text-2xl pr-1" />}
          </IonItem>

          <IonItem className="pb-[15px]">
            <IonLabel onClick={() => handleChangeNetwork(true)}>{t("settings.showTestnets")}</IonLabel>
            {statusShow === true && <IonIcon src={checkmarkOutline} className="text-2xl pr-1" />}
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default NetworkMainMb;
