import { IUseHistoryImage } from "interfaces/libs/IAccountHistory";
import { AstarDefault } from "assets/icons";
import "themes/loader.css";

const useHistoryImage = ({ displayHistory, moralisItemResult, AstarItemResult }: IUseHistoryImage) => {
  const imageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (moralisItemResult) {
      moralisItemResult.chain_id === "1" || moralisItemResult.chain_id === "11155111"
        ? (event.currentTarget.src = "https://etherscan.io/images/main/empty-token.png")
        : moralisItemResult.chain_id === "137" || moralisItemResult.chain_id === "80002"
        ? (event.currentTarget.src = "https://polygonscan.com/images/main/empty-token.png")
        : (event.currentTarget.src = AstarDefault);
    } else if (AstarItemResult) {
      event.currentTarget.src = AstarDefault;
    }
  };

  const ImageData = () => {
    if (!displayHistory.isNfts) {
      return (
        <img
          alt={displayHistory.caption}
          onError={imageOnErrorHandler}
          src={moralisItemResult ? moralisItemResult.logo_url : AstarItemResult?.logo_url}
          className="w-full h-full max-w-[40px] max-h-[40px] object-cover rounded-full"
        />
      );
    } else {
      return (
        <img
          alt={displayHistory.caption}
          onError={imageOnErrorHandler}
          src={displayHistory.images ? displayHistory.images : window.location.origin + "/assets/noimg.png"}
          className="w-full h-full max-w-[40px] max-h-[40px] object-cover rounded-full"
        />
      );
    }
  };
  return { ImageData };
};

export default useHistoryImage;
