// @ts-nocheck
import { IonPage, IonContent, IonLabel, IonInput, IonFooter, IonButton, IonModal, IonItem, IonIcon } from "@ionic/react";
import ContactBar from "features/transfer/components/ContactBar";
import GasModal from "features/transfer/components/GasModal";
import { useAppSelector } from "hooks/useRedux";
import { chevronDown } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { ConfirmTokenMainProps } from "features/transfer/confirmToken/types";
import HeaderMainMb from "components/header/mb";

const ConfirmTokenMainMb = (props: ConfirmTokenMainProps) => {
  const { t } = useTranslation();
  const transfer = useAppSelector((state) => state.transfer);
  const contact = useAppSelector((state) => state.contact);

  return (
    <>
      <IonPage>
        <HeaderMainMb title={t("transfer.confirmTransferTitle")} />

        <IonContent className="ion-padding">
          <ContactBar />
          <div className="pb-4">
            <IonLabel position="stacked">{t("transfer.amountToSend")}</IonLabel>
            <div className="flex flex-row justify-between items-center py-1 px-4 rounded-custom border border-[#d3d3d3] my-2.5">
              {props.isAndroid ? (
                <IonInput type="text" value={props.amountToSend} onIonChange={(e) => props.amountChanged(e)} />
              ) : (
                <IonInput type="text" value={props.amountToSend} onIonChange={(e) => props.amountChanged(e)} />
              )}
            </div>
          </div>
        </IonContent>

        <IonFooter>
          <div className="px-4">
            <IonButton
              expand="block"
              className="h-12"
              color={props.isConfirmDisabled ? "medium" : "primary"}
              onClick={props.proceedTransfer}
              disabled={props.isConfirmDisabled}>
              {t("transfer.proceed")}
            </IonButton>
          </div>
          <GasModal />
        </IonFooter>
      </IonPage>
      <IonModal
        isOpen={props.openConfirm}
        onDidDismiss={() => {
          props.setOpenConfirm(false);
        }}
        initialBreakpoint={window.innerHeight < 600 ? 0.78 : window.innerHeight < 700 ? 0.67 : 0.62}
        breakpoints={[0, window.innerHeight < 600 ? 0.78 : window.innerHeight < 700 ? 0.67 : 0.62]}>
        <IonContent className="ion-padding flex space-y-4">
          <IonItem className="shadow-itemListShadow rounded-2xl border-b-0 px-3.5 py-4" lines="none">
            <IonLabel className="font-bold ps-4">
              {props.amountToSend} {transfer.detail?.contract_ticker_symbol}
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonLabel className="font-bold ps-4">{t("transfer.to")}</IonLabel>
            <IonIcon icon={chevronDown} color="dark" />
          </IonItem>
          <IonItem className="shadow-itemListShadow rounded-2xl border-b-0 px-3.5 py-4" lines="none">
            <IonLabel className="font-bold ps-4">{contact.selectedContact?.name}</IonLabel>
            {/* <div>{contact.selectedContact?.address}</div> */}
          </IonItem>

          <IonButton
            shape="round"
            color="primary"
            expand="full"
            onClick={() => {
              props.confirmTransaction();
            }}>
            {t("transfer.confirmTransfer")}
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
};

export default ConfirmTokenMainMb;
