import { BN } from "ethereumjs-util";
import { get } from "helpers/httpHelpers";
import { listChain } from "shared/config/chains";
import { decGWEIToHexWEI, hexWEIToDecGWEI } from "shared/utils/conversionUtils";

export function normalizeGWEIDecimalNumbers(n: any) {
  const numberAsWEIHex = decGWEIToHexWEI(n);
  const numberAsGWEI = hexWEIToDecGWEI(numberAsWEIHex);
  return numberAsGWEI;
}

export function getTickerTokenSymbol(val: string) {
  const getNetworkTicker = listChain.filter((data) => {
    if (data.chain == val) {
      return data.ticker;
    }
  });
  // console.log(getNetworkTicker, "@GETNEOTK");
  return getNetworkTicker[0].ticker;
}

export async function fetchGasEstimates(url: any) {
  const estimates: any = await get(url);
  const normalizedEstimates = {
    estimatedBaseFee: normalizeGWEIDecimalNumbers(estimates.estimatedBaseFee),
    low: {
      ...estimates.low,
      suggestedMaxPriorityFeePerGas: normalizeGWEIDecimalNumbers(estimates.low.suggestedMaxPriorityFeePerGas),
      suggestedMaxFeePerGas: normalizeGWEIDecimalNumbers(estimates.low.suggestedMaxFeePerGas)
    },
    medium: {
      ...estimates.medium,
      suggestedMaxPriorityFeePerGas: normalizeGWEIDecimalNumbers(estimates.medium.suggestedMaxPriorityFeePerGas),
      suggestedMaxFeePerGas: normalizeGWEIDecimalNumbers(estimates.medium.suggestedMaxFeePerGas)
    },
    high: {
      ...estimates.high,
      suggestedMaxPriorityFeePerGas: normalizeGWEIDecimalNumbers(estimates.high.suggestedMaxPriorityFeePerGas),
      suggestedMaxFeePerGas: normalizeGWEIDecimalNumbers(estimates.high.suggestedMaxFeePerGas)
    }
  };
  return normalizedEstimates;
}

/**
 * Hit the legacy MetaSwaps gasPrices estimate api and return the low, medium
 * high values from that API.
 */
export async function fetchLegacyGasPriceEstimates(url: any) {
  const result: any = await get(url, {
    referrer: url,
    referrerPolicy: "no-referrer-when-downgrade",
    method: "GET",
    mode: "cors"
  });
  return {
    low: result.SafeGasPrice,
    medium: result.ProposeGasPrice,
    high: result.FastGasPrice
  };
}

export async function fetchEthGasPriceEstimate(ethQuery: any) {
  const gasPrice = await ethQuery.gasPrice();
  return {
    gasPrice: hexWEIToDecGWEI(gasPrice).toString()
  };
}

export function calculateTimeEstimate(maxPriorityFeePerGas: any, maxFeePerGas: any, gasFeeEstimates: any) {
  const { low, medium, high, estimatedBaseFee } = gasFeeEstimates;

  const maxPriorityFeePerGasInWEI = new BN(decGWEIToHexWEI(maxPriorityFeePerGas) as number, 16);
  const maxFeePerGasInWEI = new BN(decGWEIToHexWEI(maxFeePerGas) as number, 16);
  const estimatedBaseFeeInWEI = new BN(decGWEIToHexWEI(estimatedBaseFee) as number, 16);

  const effectiveMaxPriorityFee = BN.min(maxPriorityFeePerGasInWEI, maxFeePerGasInWEI.sub(estimatedBaseFeeInWEI));

  const lowMaxPriorityFeeInWEI = new BN(decGWEIToHexWEI(low.suggestedMaxPriorityFeePerGas) as number, 16);
  const mediumMaxPriorityFeeInWEI = new BN(decGWEIToHexWEI(medium.suggestedMaxPriorityFeePerGas) as number, 16);
  const highMaxPriorityFeeInWEI = new BN(decGWEIToHexWEI(high.suggestedMaxPriorityFeePerGas) as number, 16);

  let lowerTimeBound;
  let upperTimeBound;

  if (effectiveMaxPriorityFee.lt(lowMaxPriorityFeeInWEI)) {
    lowerTimeBound = null;
    upperTimeBound = "unknown";
  } else if (effectiveMaxPriorityFee.gte(lowMaxPriorityFeeInWEI) && effectiveMaxPriorityFee.lt(mediumMaxPriorityFeeInWEI)) {
    lowerTimeBound = low.minWaitTimeEstimate;
    upperTimeBound = low.maxWaitTimeEstimate;
  } else if (effectiveMaxPriorityFee.gte(mediumMaxPriorityFeeInWEI) && effectiveMaxPriorityFee.lt(highMaxPriorityFeeInWEI)) {
    lowerTimeBound = medium.minWaitTimeEstimate;
    upperTimeBound = medium.maxWaitTimeEstimate;
  } else if (effectiveMaxPriorityFee.eq(highMaxPriorityFeeInWEI)) {
    lowerTimeBound = high.minWaitTimeEstimate;
    upperTimeBound = high.maxWaitTimeEstimate;
  } else {
    lowerTimeBound = 0;
    upperTimeBound = high.maxWaitTimeEstimate;
  }

  return {
    lowerTimeBound,
    upperTimeBound
  };
}
