import {
  LOGIN,
  LOGIN_TYPE,
  LoginWindowResponse,
  RedirectResult,
  TORUS_METHOD,
  TorusAggregateLoginResponse,
  TorusHybridAggregateLoginResponse,
  TorusKey,
  TorusKeyPub,
  TorusLoginResponse,
  TorusVerifierResponse
} from "@toruslabs/customauth";
import { getPublicCompressed } from "@toruslabs/eccrypto";
import { post, remove } from "@toruslabs/http-helpers";
import MetadataStorageLayer, { getAndDecryptData } from "@toruslabs/metadata-helpers";
import log from "loglevel";

import config from "./openloginconfig";
import { store } from "shared/store";
import { OAUTH_CREDID_CACHE, OAUTH_USERINFO } from "./openloginenums";
import { CustomAuthResult, TorusUserInfo } from "./openlogininterface";
import { getJoinedKey, getPublicFromPrivateKey, sanitizeUrl } from "./openloginutils";
import { authenticateUserToken, olUserModuleAction } from "shared/actions/olUserModuleAction";
import { olDappModuleAction } from "shared/actions/olDappModuleAction";
import { olDeviceModuleAction } from "shared/actions/olDeviceModuleAction";
import { registerDappModule } from "shared/actions/olAllDappModuleAction";

export function parseCustomAuthResult(redirectResult: RedirectResult): CustomAuthResult {
  const { result, method } = redirectResult;
  let userInfo: TorusVerifierResponse & LoginWindowResponse;
  let publicAddress: string;
  let privateKey: string;
  let metadataNonce: string;
  let pubKey: TorusKeyPub["pubKey"];
  let typeOfUser: TorusKey["typeOfUser"];
  if (method === TORUS_METHOD.TRIGGER_LOGIN) {
    ({ userInfo, publicAddress, privateKey, metadataNonce, pubKey, typeOfUser } = result as TorusLoginResponse);
  } else if (method === TORUS_METHOD.TRIGGER_AGGREGATE_LOGIN) {
    const { userInfo: aggregateUserInfo } = result as TorusAggregateLoginResponse;
    ({ publicAddress, privateKey, metadataNonce, pubKey, typeOfUser } = result as TorusAggregateLoginResponse);
    [userInfo] = aggregateUserInfo;
  } else if (method === TORUS_METHOD.TRIGGER_AGGREGATE_HYBRID_LOGIN) {
    const { singleLogin, aggregateLogins } = result as TorusHybridAggregateLoginResponse;
    ({ userInfo } = singleLogin);
    [{ publicAddress, privateKey, metadataNonce, pubKey, typeOfUser }] = aggregateLogins;
  } else {
    throw new Error("Unsupported method type");
  }
  return {
    publicAddress,
    privateKey: privateKey?.padStart(64, "0") || "",
    metadataNonce,
    userInfo,
    pubKey,
    typeOfUser
  };
}

export function doneAndRedirect(routeName: string, hashParams: Record<string, unknown> = {}): void {
  const hashURL = new URL(window.location.origin);
  Object.keys(hashParams).forEach((key) => {
    hashURL.searchParams.append(key, String(hashParams[key]));
  });
  window.location.replace(`/${routeName}#${hashURL.searchParams.toString()}`);
}

export async function setUserInfoToStore(
  customAuthResult: CustomAuthResult,
  oAuthPrivateKey: string,
  oAuthNonce: string,
  state: Record<string, string>
): Promise<{
  finalKeyInfo: TorusKey;
  finalUserInfo: TorusUserInfo;
  typeOfUser: string;
}> {
  const { typeOfUser } = customAuthResult;
  const { oAuthAggregateVerifier, oAuthVerifierId, walletKey } = state;
  const metadataStorage = new MetadataStorageLayer(config.metadataHost, store.getState().olUserModule.clientTimeOffset);

  const userInfoLocal = ((await getAndDecryptData(metadataStorage, oAuthPrivateKey, OAUTH_USERINFO)) || {}) as TorusUserInfo;
  const finalUserInfo = {
    ...userInfoLocal,
    aggregateVerifier: userInfoLocal.aggregateVerifier ?? oAuthAggregateVerifier ?? "",
    verifierId: userInfoLocal.verifierId ?? oAuthVerifierId ?? ""
  };
  store.dispatch(olUserModuleAction.setUserInfo(finalUserInfo));

  const { X, Y, address } = getPublicFromPrivateKey(oAuthPrivateKey);
  const finalKeyInfo = {
    typeOfUser,
    publicAddress: address,
    privateKey: oAuthPrivateKey,
    pubKey: { pub_key_X: X, pub_key_Y: Y },
    metadataNonce: oAuthNonce
  };
  store.dispatch(olUserModuleAction.setKeyInfo(finalKeyInfo));

  if (walletKey) {
    const { X: walletX, Y: walletY, address: walletAddress } = getPublicFromPrivateKey(walletKey);
    const walletFinalKeyInfo = {
      typeOfUser,
      publicAddress: walletAddress,
      privateKey: walletKey,
      pubKey: { pub_key_X: walletX, pub_key_Y: walletY },
      metadataNonce: ""
    };

    store.dispatch(olUserModuleAction.setWalletKeyInfo(walletFinalKeyInfo));
  }

  return { finalKeyInfo, finalUserInfo, typeOfUser };
}

export async function storeUserInfoToBackend({
  finalKeyInfo,
  finalUserInfo,
  typeOfUser
}: {
  finalKeyInfo: TorusKey;
  finalUserInfo: TorusUserInfo;
  typeOfUser: string;
}): Promise<void> {
  registerDappModule(store.getState().olUserModule.currentDappClientId);
  // const currentDappModule = store.getState().olAllDappModule.dappModules[store.getState().olUserModule.currentDappClientId];
  const currentDeviceId =
    store.getState().olDeviceModule.verifierIDDeviceIDMap[
      getJoinedKey(finalUserInfo.aggregateVerifier || finalUserInfo.verifier, finalUserInfo.verifierId)
    ];

  // if user is logged in using openlogin dashboard
  // or if dapp is using older version of openlogin sdk (<2.1.0) then it will use randomId.
  store.dispatch(olDappModuleAction.setSessionId());
  const finalSessionId = store.getState().olDappModule.sessionId;
  store.dispatch(olDappModuleAction.updateState({ sessionId: finalSessionId } as any));
  const sessionNonce = getPublicCompressed(Buffer.from((finalSessionId as string).padStart(64, "0"), "hex")).toString("hex");
  log.debug("session nonce", sessionNonce);
  const res = await authenticateUserToken({
    public_address: finalKeyInfo.publicAddress,
    private_key: finalKeyInfo.privateKey,
    verifier: finalUserInfo.aggregateVerifier || finalUserInfo.verifier,
    network: config.torusNetwork,
    session_nonce: sessionNonce,
    device_id: currentDeviceId,
    verifier_id: finalUserInfo.verifierId,
    client_id: store.getState().olDappModule.clientId,
    hostname: sanitizeUrl(process.env.BASE_URL + "wallet/home").host,
    user_type: typeOfUser
  });
  if (res) {
    store.dispatch(olUserModuleAction.setPersistedUserInfo(res.user));
    if (res.device) {
      store.dispatch(olDeviceModuleAction.setDevicePersistedInfo({ deviceId: currentDeviceId, info: res.device }));
    }
    if (res.userDapp) {
      store.dispatch(olDappModuleAction.setUserDappPersistedInfo(res.userDapp));
    }
  }
}

export async function cleanupOAuth(typeOfLogin: LOGIN_TYPE, oAuthToken: string): Promise<void> {
  try {
    if (typeOfLogin === LOGIN.DISCORD) {
      await post(
        `${config.apiHost}/revoke/discord`,
        { token: oAuthToken, isLRC: config.isLRC, network: config.torusNetwork },
        { headers: { "Content-Type": "application/json; charset=utf-8" } },
        { useAPIKey: true }
      );
    } else if (typeOfLogin === LOGIN.FACEBOOK) {
      await remove(`https://graph.facebook.com/me/permissions?access_token=${oAuthToken}`);
    }
  } catch (error: unknown) {
    log.error(error);
  }
}

export async function getCredIdList(privateKey: string, verifier: string, verifierId: string): Promise<void> {
  const metadataStorage = new MetadataStorageLayer(config.metadataHost, store.getState().olUserModule.clientTimeOffset);

  const oAuthCredIdList = JSON.parse(
    (await metadataStorage.getMetadata(metadataStorage.generatePubKeyParams(privateKey), OAUTH_CREDID_CACHE)) || "[]"
  );
  // console.log(`oAuthCredIdList: `, oAuthCredIdList);
  if (oAuthCredIdList && Array.isArray(oAuthCredIdList)) {
    const scopedCredIdCache = store.getState().olDeviceModule.credIdMapCache[getJoinedKey(verifier, verifierId)];
    if (!(scopedCredIdCache && scopedCredIdCache.confirmed)) {
      oAuthCredIdList.forEach((credId) => {
        store.dispatch(olDeviceModuleAction.addCredId({ verifier, verifierId, credId, confirmed: false }));
      });
    }
  }
}
