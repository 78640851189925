/* eslint-disable no-case-declarations */
import { EIP155_SIGNING_METHODS } from "shared/config/eip155WC";
import { formatJsonRpcError, formatJsonRpcResult } from "@json-rpc-tools/utils";
import { SignClientTypes } from "@walletconnect/types";
import { getSdkError } from "@walletconnect/utils";
import { getSignParamsMessage, getSignTypedDataParamsData } from "./HelperUtil";

export async function approveEIP155Request(walletProvider: any, requestEvent: SignClientTypes.EventArguments["session_request"]) {
  const { params, id } = requestEvent;
  const { request } = params;
  switch (request.method) {
    case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
    case EIP155_SIGNING_METHODS.ETH_SIGN:
      try {
        const message = getSignParamsMessage(request.params);
        const signedMessage = await walletProvider.signMessage(message);
        return formatJsonRpcResult(id, signedMessage);
      } catch (error: any) {
        console.error(error);
        return formatJsonRpcError(id, error.message);
      }

    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4:
      try {
        const { domain, types, message: data } = getSignTypedDataParamsData(request.params);
        // https://github.com/ethers-io/ethers.js/issues/687#issuecomment-714069471
        delete types.EIP712Domain;
        const signedData = await walletProvider._signTypedData(domain, types, data);
        return formatJsonRpcResult(id, signedData);
      } catch (error: any) {
        console.error(error);
        return formatJsonRpcError(id, error.message);
      }

    case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
      try {
        const nonce = await walletProvider.getTransactionCount();
        const sendTransaction = { ...request.params[0], nonce };
        if (sendTransaction.gas) {
          sendTransaction.gasLimit = sendTransaction.gas;
          delete sendTransaction.gas;
        }
        if (!sendTransaction.gasPrice) {
          sendTransaction.gasPrice = await walletProvider?.provider.getGasPrice();
        }
        const { hash } = await walletProvider.sendTransaction(sendTransaction);
        return formatJsonRpcResult(id, hash);
      } catch (error: any) {
        console.error(error);
        return formatJsonRpcError(id, error.message);
      }

    case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
      try {
        const signTransaction = request.params[0];
        const signature = await walletProvider.signTransaction(signTransaction);
        return formatJsonRpcResult(id, signature);
      } catch (error: any) {
        console.error(error);
        return formatJsonRpcError(id, error.message);
      }

    case EIP155_SIGNING_METHODS.ETH_SIGN_CHANGE_NETWORK:
      try {
        const result = params.chainId;
        return formatJsonRpcResult(id, parseInt(result));
      } catch (error: any) {
        console.error(error);
        return formatJsonRpcError(id, error.message);
      }

    default:
      throw new Error(getSdkError("INVALID_METHOD").message);
  }
}

export function rejectEIP155Request(request: SignClientTypes.EventArguments["session_request"]) {
  const { id } = request;

  return formatJsonRpcError(id, getSdkError("USER_REJECTED_METHODS").message);
}

// @example
// const signingKey = new ethers.utils.SigningKey("0x0fde5df000bd614e93fea5ae989cb31f89f71ed003c62102d1b45a025e0f1717");
// console.log(signingKey, "signingKey");
// const sigParams = await signingKey.signDigest(ethers.utils.arrayify(abc[0]));
// console.log(sigParams, "SIGPARAMS");
// const result = await ethers.utils.joinSignature(sigParams);
// console.log(result, "result sigparam join");
