/* eslint-disable @typescript-eslint/no-explicit-any */
import PopupModal from "components/popups/PopupModal";
import WalletWidget from "components/popups/PopupWidget";
import WidgetButton from "components/popups/PopupWidgetButton";
import { useAppSelector } from "hooks/useRedux";
import React, { useEffect, useState } from "react";

const EmbedIframePage: React.FC = () => {
  const [showWidget, setShowWidget] = useState(false);
  const { widgetIsOpen } = useAppSelector((state) => state.embedUiState);
  const embedState = useAppSelector((state) => state.embedState);
  const { selectedAddress } = useAppSelector((state) => state.wallet);

  useEffect(() => {
    const { widgetConfig } = embedState.configuration;
    const loggedIn = selectedAddress !== "";
    // both
    if (widgetConfig.showAfterLoggedIn && widgetConfig.showBeforeLoggedIn) {
      setShowWidget(true);
    }
    if (!widgetConfig.showAfterLoggedIn && !widgetConfig.showBeforeLoggedIn) {
      setShowWidget(false);
    }
    // before
    if (widgetConfig.showBeforeLoggedIn && !widgetConfig.showAfterLoggedIn && !loggedIn) {
      setShowWidget(true);
    }
    if (widgetConfig.showBeforeLoggedIn && !widgetConfig.showAfterLoggedIn && loggedIn) {
      setShowWidget(false);
    }
    // after
    if (widgetConfig.showAfterLoggedIn && !widgetConfig.showBeforeLoggedIn && !loggedIn) {
      setShowWidget(false);
    }
    if (widgetConfig.showAfterLoggedIn && !widgetConfig.showBeforeLoggedIn && loggedIn) {
      setShowWidget(true);
    }
  }, [embedState.configuration.widgetConfig, selectedAddress]);

  return (
    <>
      {embedState.iFrameConfiguration.open && (
        <iframe
          id="embed-popup"
          src={embedState.iFrameConfiguration.src || `${process.env.REACT_APP_BASE_URL}`}
          name="iframe_wallet_popup"
          height="100%"
          width="100%"
          style={{ top: `${embedState.iFrameConfiguration.topCalc}%` }}
          className="absolute w-[91.7%] xs400:w-[355px] h-[60vh] rounded-xl border-none left-3.75 shadow-default z-[99999] bg-white"
          title="Wallet Iframe"></iframe>
      )}
      <div>
        {showWidget && <WidgetButton />}
        {widgetIsOpen && selectedAddress !== "" && <WalletWidget />}
        {window.innerWidth > 100 && selectedAddress === "" && <PopupModal />}
      </div>
    </>
  );
};

export default EmbedIframePage;
