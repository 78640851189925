import { BroadcastChannel } from "@toruslabs/broadcast-channel";
import {
  createEngineStream,
  createErrorMiddleware,
  createScaffoldMiddleware,
  IGNORE_SUBSTREAM,
  JRPCEngine,
  PostMessageStream,
  setupMultiplex,
  Substream
} from "@toruslabs/openlogin-jrpc";
import log from "loglevel";
import pump from "pump";

import { scaffoldMiddlewares, sessionMiddleware } from "./openloginmiddleware";

let rpcEngine: JRPCEngine;
let initResolve: (value: unknown) => void;

let verifierStream: Substream;
let verifierInitResolve: (value: unknown) => void;
const verifierInitCompletion = new Promise((resolve) => {
  verifierInitResolve = resolve;
});

export const initSdk = (): void => {
  const rpcStream = new PostMessageStream({
    name: "iframe_rpc",
    target: "embed_rpc",
    targetWindow: window.parent,
    targetOrigin: "*"
  });

  const mux = setupMultiplex(rpcStream);
  const _jrpcStream = mux.getStream("jrpc");
  if (_jrpcStream === IGNORE_SUBSTREAM) {
    throw new Error("jrpc stream should not be ignored");
  }
  const jrpcStream = _jrpcStream as Substream;

  rpcEngine = new JRPCEngine();
  rpcEngine.push(createErrorMiddleware(log));
  rpcEngine.push(sessionMiddleware);
  rpcEngine.push(createScaffoldMiddleware(scaffoldMiddlewares));
  const engineStream = createEngineStream({ engine: rpcEngine });
  pump(engineStream, jrpcStream, engineStream, (error: any): void => {
    log.error(error, `engine disconnected`);
  });
  initResolve(null);
};

export const initModalStream = (): void => {
  const verifierMux = setupMultiplex(
    new PostMessageStream({
      name: "modal_rpc",
      target: "modal_iframe_rpc",
      targetWindow: window.parent,
      targetOrigin: "*"
    })
  );
  const _verifierStream = verifierMux.getStream("verifier");
  if (_verifierStream === IGNORE_SUBSTREAM) {
    throw new Error("jrpc stream should not be ignored");
  }
  verifierStream = _verifierStream as Substream;
  verifierInitResolve(null);
};

export async function getVerifierStream(): Promise<Substream> {
  await verifierInitCompletion;
  return verifierStream;
}

export async function getRPCEngine(): Promise<JRPCEngine> {
  const rpcStream = new PostMessageStream({
    name: "iframe_rpc",
    target: "embed_rpc",
    targetWindow: window.parent,
    targetOrigin: "*"
  });

  const mux = setupMultiplex(rpcStream);
  const _jrpcStream = mux.getStream("jrpc");
  if (_jrpcStream === IGNORE_SUBSTREAM) {
    throw new Error("jrpc stream should not be ignored");
  }
  const jrpcStream = _jrpcStream as Substream;

  rpcEngine = new JRPCEngine();
  rpcEngine.push(createErrorMiddleware(log));
  rpcEngine.push(sessionMiddleware);
  rpcEngine.push(createScaffoldMiddleware(scaffoldMiddlewares));
  const engineStream = createEngineStream({ engine: rpcEngine });
  pump(engineStream, jrpcStream, engineStream, (error: any): void => {
    log.error(error, `engine disconnected`);
  });
  return rpcEngine;
}

export const tabsBroadcast = () => {
  const bc = new BroadcastChannel(`upbond_close_tab`);
  return bc;
};
