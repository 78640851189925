import { gql } from "@apollo/client/core";

export const REGISTER_USER = gql`
  mutation registerUser($register_payload: UserRegisterPayload!) {
    res: registerUser(payload: $register_payload)
  }
`;

export const REGISTER_USER_EXTENDED = gql`
  mutation registerUserExtended($register_payload: UserRegisterPayload!) {
    res: registerUserExtended(payload: $register_payload) {
      user {
        public_address
        verifier
        verifier_id
        tkey_public_address
        tkey_threshold
        tkey_password
        tkey_backup_emails
        tkey_creation_factor
        theme
        locale
        always_skip_tkey
        v2_wallet_key_enabled
      }
      device {
        id
        share_index
        webauthn_enabled
        webauthn_available
        public_address
      }
      userDapp {
        id
        dapp_id
        public_address
        dapp_public_key
      }
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation UpdateUser($update_user_payload: UpdateUserPayload!) {
    UpdateUserInfo(payload: $update_user_payload)
  }
`;

export const USER_INFO = gql`
  query fetchUserInfo {
    info: fetchUserInfo {
      public_address
      verifier
      verifier_id
      tkey_public_address
      tkey_threshold
      tkey_password
      tkey_backup_emails
      tkey_creation_factor
      theme
      locale
      always_skip_tkey
      v2_wallet_key_enabled
      backup_phrase_setup_at
      backup_phrase_setup_email
    }
  }
`;

export const USER_UNIQUE_DAPPS = gql`
  query fetchUserUniqueDapps {
    info: fetchUserUniqueDapps {
      dapp {
        id
        hostname
        client_id
        created_at
      }
      dapp_id
      device_id
      is_active
    }
  }
`;

export const USER_DAPPS = gql`
  query fetchUserDapps($dapp_id: Float, $is_active: Boolean) {
    info: fetchUserDapps(dapp_id: $dapp_id, is_active: $is_active) {
      device {
        id
        share_index
        browser
        browser_version
        os
        os_version
        platform
        webauthn_available
        public_address
        webauthn_enabled
        created_at
      }
      is_active
      device_id
      dapp_id
    }
  }
`;
