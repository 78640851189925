// @ts-nocheck
import {
  IonPage,
  IonContent,
  IonLabel,
  IonInput,
  IonFooter,
  IonButton,
  IonModal,
  IonItem,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent
} from "@ionic/react";
import ContactBar from "features/transfer/components/ContactBar";
import GasModal from "features/transfer/components/GasModal";
import { useAppSelector } from "hooks/useRedux";
import { chevronDown } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { ConfirmTokenMainProps } from "features/transfer/confirmToken/types";
import HeaderMainPc from "components/header/pc";

const ConfirmTokenMainPc = (props: ConfirmTokenMainProps) => {
  const { t } = useTranslation();
  const transfer = useAppSelector((state) => state.transfer);
  const contact = useAppSelector((state) => state.contact);

  return (
    <>
      <IonPage className="p-0">
        <HeaderMainPc title={t("transfer.confirmTransferTitle")} />

        <IonContent className="bg-base p-0">
          <IonGrid className="-mt-5">
            <IonRow>
              <IonCol size="6">
                <IonCard>
                  <IonCardContent>
                    <ContactBar />
                    <div className="pb-4">
                      <IonLabel position="stacked">{t("transfer.amountToSend")}</IonLabel>
                      <div className="flex flex-row justify-between items-center py-1 px-4 rounded-custom border border-[#d3d3d3] my-2.5">
                        <IonInput type="text" value={props.amountToSend} onIonChange={(e) => props.amountChanged(e)} />
                      </div>
                    </div>
                    <div>
                      <IonButton
                        expand="block"
                        className="h-12"
                        color={props.isConfirmDisabled ? "medium" : "primary"}
                        onClick={props.proceedTransfer}
                        disabled={props.isConfirmDisabled}>
                        {t("transfer.proceed")}
                      </IonButton>
                    </div>
                    <GasModal />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>

        <IonFooter></IonFooter>
      </IonPage>

      <IonModal
        className="modal-transfer-done"
        isOpen={props.openConfirm}
        onDidDismiss={() => {
          props.setOpenConfirm(false);
        }}>
        <IonContent className="ion-padding">
          <IonItem className="shadow-itemListShadow rounded-2xl border-b-0 p-4 mb-4" lines="none">
            <IonLabel className="font-bold ps-4">
              {props.totalSend} {transfer.detail?.contract_ticker_symbol}
            </IonLabel>
          </IonItem>
          <IonItem lines="none" className="mb-16">
            <IonLabel className="font-bold ps-4">{t("transfer.to")}</IonLabel>
            <IonIcon icon={chevronDown} color="dark" />
          </IonItem>
          <IonItem className="shadow-itemListShadow rounded-2xl border-b-0 p-4 mb-4" lines="none">
            <IonLabel className="font-bold ps-4">{contact.selectedContact?.name}</IonLabel>
          </IonItem>

          <IonButton
            shape="round"
            color="primary"
            expand="full"
            onClick={() => {
              props.confirmTransaction();
            }}>
            {t("transfer.confirmTransfer")}
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
};

export default ConfirmTokenMainPc;
