import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OpenloginSessionManager } from "@toruslabs/openlogin-session-manager";
import { registerUserExtendedMutation } from "shared/utils/__generated__/user";
import { DappModule } from "interfaces/actions/IOIDappModule";
import { LoginConfigItem, MfaLevelType, SUPPORTED_KEY_CURVES_TYPE, TouchIDPreferencesType } from "shared/utils/openlogininterface";
import { ERROR_MISSING_PARAMS } from "shared/utils/openloginenums";
import log from "loglevel";
import { merge } from "lodash-es";

const initDappModuleState: DappModule = {
  touchIDPreference: "",
  customLoginConfig: {},
  privateKey: "",
  clientId: "",
  redirectUrl: "",
  currentLoginProvider: "",
  whiteLabel: {},
  sessionId: "",
  sessionTime: 86400,
  dappShare: "",
  idToken: "",
  _sessionNamespace: "",
  persistedUserdappInfo: "",
  usingDirectConfig: {
    directUrl: "",
    usingDirect: false
  },
  siteMetadata: {
    icon: "",
    name: "",
    url: "",
    date: null
  },
  mobileOrigin: "",
  skipTkey: false,
  curve: "",
  appState: "",
  mfaLevel: "default",
  getWalletKey: false,
  embedWlRedirectUrl: "",
  canSendDappShare: "",
  isCustomVerifier: false,
  directConfig: {
    usingDirect: false,
    directUrl: ""
  },
  skipTKey: false
};
const dappModuleSlice = createSlice({
  name: "olDappModule",
  initialState: initDappModuleState,
  reducers: {
    setSessionId(state) {
      if (!state.sessionId) state.sessionId = OpenloginSessionManager.generateRandomSessionKey();
    },
    setUserDappPersistedInfo(state, action: PayloadAction<Partial<NonNullable<registerUserExtendedMutation["res"]>["userDapp"]>>) {
      state.persistedUserdappInfo = { ...state.persistedUserdappInfo, ...action.payload };
    },
    setClientId(state, action: PayloadAction<{ clientId: string }>): void {
      state.clientId = action.payload.clientId;
    },
    setDirectConf(state, action: PayloadAction<{ usingDirect: boolean; directUrl: string }>) {
      if (action.payload.directUrl && action.payload.usingDirect) {
        state.usingDirectConfig.directUrl = action.payload.directUrl;
        state.usingDirectConfig.usingDirect = action.payload.usingDirect;
      }
    },
    setTouchIDPreference(state, action: PayloadAction<TouchIDPreferencesType>): void {
      state.touchIDPreference = action.payload;
    },
    setSiteMetadata(state, action: PayloadAction<{ icon?: string; name: string; url: string }>): void {
      const { icon, name, url } = action.payload;
      state.siteMetadata = {
        icon: icon || state.siteMetadata.icon || "",
        name: name || state.siteMetadata.name,
        url: url || state.siteMetadata.url,
        date: new Date()
      };
    },
    setLoginParams(
      state,
      action: PayloadAction<{
        clientId: string;
        currentLoginProvider: string;
        skipTKey?: boolean;
        redirectUrl?: string;
        getWalletKey?: boolean;
        mfaLevel?: MfaLevelType;
        appState?: string;
        dappShare?: string;
        sessionTime?: number;
        sessionId?: string;
        curve?: string;
        mobileOrigin?: string;
        _sessionNamespace?: string;
      }>
    ): void {
      const {
        clientId,
        skipTKey,
        currentLoginProvider,
        redirectUrl,
        getWalletKey,
        mfaLevel,
        appState,
        dappShare,
        sessionTime,
        sessionId,
        curve,
        mobileOrigin,
        _sessionNamespace
      } = action.payload;
      if (clientId) state.clientId = clientId;
      // This will throw if not valid url
      try {
        if (redirectUrl) state.redirectUrl = new URL(redirectUrl).href;
      } catch (error) {
        log.error(error);
        throw new Error(ERROR_MISSING_PARAMS);
      }
      if (currentLoginProvider) state.currentLoginProvider = currentLoginProvider;
      if (getWalletKey !== undefined) state.getWalletKey = getWalletKey;
      if (mfaLevel) state.mfaLevel = mfaLevel;
      if (appState) state.appState = appState;
      if (dappShare) state.dappShare = dappShare;
      if (sessionTime) state.sessionTime = sessionTime;
      state.sessionId = sessionId || "";
      if (curve) state.curve = curve as SUPPORTED_KEY_CURVES_TYPE;
      if (skipTKey !== undefined) state.skipTkey = skipTKey;
      if (mobileOrigin) state.mobileOrigin = mobileOrigin as string;
      state._sessionNamespace = _sessionNamespace || "";
    },
    modifyCustomLoginConfig(state, action: PayloadAction<{ cfg: LoginConfigItem; loginProvider: string }>) {
      const { cfg, loginProvider } = action.payload;
      const localConfig: any = { ...state.customLoginConfig };
      const currCfg = localConfig[loginProvider];

      if (!currCfg) {
        if (!cfg.verifierSubIdentifier) {
          // prevent verifierSubIdentifier from being overridden later in get loginConfig function.
          localConfig[loginProvider] = { ...cfg, verifierSubIdentifier: "", loginProvider };
        } else {
          localConfig[loginProvider] = { ...cfg, loginProvider };
        }
      } else if (
        Object.prototype.hasOwnProperty.call(cfg, "verifier") &&
        cfg.verifier !== currCfg.verifier &&
        !Object.prototype.hasOwnProperty.call(cfg, "verifierSubIdentifier") &&
        Object.prototype.hasOwnProperty.call(localConfig[loginProvider], "verifierSubIdentifier")
      ) {
        // a custom verifier might be sent without verifierSubIdentifier key
        // in custom config, in that case we should prevent it from getting overriden
        // by default loginProvider verifierSubIdentifier.
        localConfig[loginProvider] = { ...merge(currCfg, cfg), verifierSubIdentifier: "", loginProvider };
      } else {
        localConfig[loginProvider] = { ...merge(currCfg, cfg), loginProvider };
      }

      const finalConfigItem = localConfig[loginProvider];
      if (finalConfigItem && !finalConfigItem.walletVerifier) finalConfigItem.walletVerifier = finalConfigItem.verifier;

      state.customLoginConfig = localConfig;
    },
    setPrivateKey(state, action: PayloadAction<{ key: string }>): void {
      state.privateKey = action.payload.key;
    },
    updateState(state, action: PayloadAction<Partial<DappModule>>): void {
      const {
        touchIDPreference,
        customLoginConfig,
        privateKey,
        clientId,
        redirectUrl,
        currentLoginProvider,
        whiteLabel,
        idToken,
        sessionId,
        _sessionNamespace,
        sessionTime,
        dappShare,
        embedWlRedirectUrl
      } = action.payload;
      if (state === undefined) return;
      if (touchIDPreference !== undefined) state.touchIDPreference = touchIDPreference;
      if (customLoginConfig !== undefined) state.customLoginConfig = customLoginConfig;
      if (privateKey !== undefined) state.privateKey = privateKey;
      if (clientId !== undefined) state.clientId = clientId;
      if (redirectUrl !== undefined) state.redirectUrl = redirectUrl;
      if (currentLoginProvider !== undefined) state.currentLoginProvider = currentLoginProvider;
      if (whiteLabel !== undefined) state.whiteLabel = whiteLabel;
      if (idToken !== undefined) state.idToken = idToken;
      if (sessionId !== undefined) state.sessionId = sessionId;
      if (_sessionNamespace !== undefined) state._sessionNamespace = _sessionNamespace;
      if (sessionTime !== undefined) state.sessionTime = sessionTime;
      if (dappShare !== undefined) state.dappShare = dappShare;
      if (embedWlRedirectUrl !== undefined) state.embedWlRedirectUrl = embedWlRedirectUrl;
    }
  }
});

export default dappModuleSlice;
