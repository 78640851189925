// @ts-nocheck
import Logo from "assets/icons/sample-button-logo.png";
import { useAppSelector } from "hooks/useRedux";
import { useEmbedUiDispatch } from "hooks/useEmbedUiDispatch";
import { IonFab, IonFabButton, IonImg } from "@ionic/react";
import useCommunicationStream from "hooks/useCommunicationStream";
import { Close } from "@mui/icons-material";
import { useEffect } from "react";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { embedActions } from "shared/actions/embedAction";
import "./styles/widget-button.css";
import torus from "libs/TorusExtended";
import { MuxSubStream } from "interfaces/ui/IEmbed";

const WidgetButton = () => {
  const { loading: isLoading, selectedAddress, userInfo } = useAppSelector((state) => state.wallet);
  const { openPopup } = useCommunicationStream();
  const embedState = useAppSelector((state) => state.embedState);
  const embedUiState = useAppSelector((state) => state.embedUiState);
  const uiDispatch = useEmbedUiDispatch();
  const dispatch = useDispatch();

  const getLogo = () => {
    if (embedState.embedTheme && embedState.embedTheme.buttonLogo && embedState.embedTheme.buttonLogo !== "") {
      return embedState.embedTheme.buttonLogo;
    }
    return Logo;
  };

  const getColor = () => {
    const { embedTheme } = embedState;

    const modalcolor = embedTheme?.modalColor === "#fffff";
    const bgcolor = embedTheme?.bgColor === "#4B68AE";
    const bgcolorhover = embedTheme?.bgColorHover === "#214999";
    const textcolor = embedTheme?.textColor === "#f3f3f3";
    const textcolorhover = embedTheme?.textColorHover === "#214999";

    if (modalcolor && bgcolor && bgcolorhover && textcolor && textcolorhover) {
      // this condition is for checking the old default primary color then replaced by the new one
      return {
        modalColor: "#ffffff",
        bg: "#4B68AE",
        bgHover: "#214999",
        text: "#f3f3f3",
        textColorHover: "#ffffff"
      };
    } else {
      return {
        modalColor: embedTheme?.modalColor || "#fafafa",
        bg: embedTheme?.bgColor || "#4B68AE",
        bgHover: embedTheme?.bgColorHover || "#224999",
        text: embedTheme?.textColor || "#f3f3f3",
        textColorHover: embedTheme?.textColorHover || "#f3f3f3"
      };
    }
  };

  useEffect(() => {
    embedUiState.widgetIsOpen && openPopup(true);
  }, []);

  useEffect(() => {
    if (selectedAddress && selectedAddress !== "" && userInfo.verifier) {
      const mux = torus?.communicationMux;
      if (mux) {
        const streamMux = mux.getStream("status") as MuxSubStream;
        streamMux.write({
          loggedIn: true,
          rehydrate: true,
          verifier: userInfo.verifier
        });
      }
    }
  }, [selectedAddress, userInfo.verifier]);

  return (
    <IonFab className={`${embedState.configuration.buttonPosition} widget-bottom flex justify-center items-center`}>
      {isLoading ? (
        <IonFabButton className="w-15 h-15 shadow-none">Loading...</IonFabButton>
      ) : (
        <IonFabButton
          disabled={embedState.iFrameConfiguration.open}
          style={{
            "--background": getColor().bg,
            "--background-hover": getColor().bgHover,
            "--background-focused": getColor().bgHover,
            "--background-activated": getColor().bgHover
          }}
          id="embed-button"
          className={`w-15 h-15 shadow-none white-bg ${embedState.configuration.buttonPosition}`}
          onClick={() => {
            uiDispatch("widgetIsOpen", !embedUiState.widgetIsOpen);
            openPopup(!embedUiState.widgetIsOpen);
            dispatch(
              embedActions.setIFrameConfig({
                open: false,
                src: `${process.env.REACT_APP_BASE_URL}`,
                origin: "",
                topCalc: 3
              })
            );
          }}>
          {embedUiState.widgetIsOpen ? (
            <IconButton className="rounded-md" sx={{ borderRadius: 6 }}>
              <Close sx={{ color: getColor().text }} />
            </IconButton>
          ) : (
            <IonImg className="w-15 h-15" src={getLogo()} />
          )}
        </IonFabButton>
      )}
    </IonFab>
  );
};

export default WidgetButton;
