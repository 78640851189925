import { TORUS_NETWORK, TORUS_NETWORK_TYPE } from "@toruslabs/constants";
import log, { LogLevelDesc } from "loglevel";

import { LOCAL_STORAGE_KEY, SESSION_STORAGE_KEY, UPBOND_LINE_PROVIDER } from "./openloginenums";
import type { LoginConfig, LoginConfigItem } from "./openlogininterface";
import storageAvailable from "./openloginutils";

const {
  REACT_APP_GOOGLE_VERIFIER,
  REACT_APP_LINE_CLIENT_ID,
  REACT_APP_LINE_VERIFIER,

  REACT_APP_TORUS_NETWORK,
  VUE_APP_TORUS_BUILD_ENV,
  REACT_APP_LOGIN_UPBOND_DOMAIN,
  REACT_APP_GOOGLE_CLIENT_ID,

  REACT_APP_OPENLOGIN_BACKEND,
  REACT_APP_CLIENT_ID,
  VUE_APP_METADATA_HOST,
  VUE_APP_DEVELOPER_DASHBOARD_HOST,
  REACT_APP_TORUS_NETWORK_URLS,
  VUE_APP_SIGNER_HOST
} = process.env;

let logLevel: LogLevelDesc;
switch (VUE_APP_TORUS_BUILD_ENV) {
  case "testing" || "development" || "lrc":
    logLevel = "debug";
    break;
  case "production":
  default:
    logLevel = "error";
    break;
}
log.setLevel(logLevel);

const networkUrls = REACT_APP_TORUS_NETWORK_URLS?.split(",") ?? [];

const getNetworkUrl = () => {
  const rand = Math.floor(Math.random() * networkUrls.length);
  return networkUrls[rand];
};

const config = {
  dummyLabel:
    "glory abandon recycle trim rural unlock husband document weasel scale bag vocal dad future mix enter violin arrive meadow corn winter miracle exercise way",
  developerDashboardHost: VUE_APP_DEVELOPER_DASHBOARD_HOST ?? "https://api.developer.tor.us",
  openloginBackend: REACT_APP_OPENLOGIN_BACKEND ?? "https://api.openlogin.com/graphql",
  openloginDappModuleKey: REACT_APP_CLIENT_ID,
  metadataHost: VUE_APP_METADATA_HOST ?? "https://metadata.tor.us",
  apiHost: "https://api.tor.us",
  tkeyEmailHost: "https://email.tkey.io/send_mail",
  signerHost: VUE_APP_SIGNER_HOST ?? "https://signer.tor.us",
  networkUrl: getNetworkUrl(), // it will be undefined if variable is not
  storageServerUrl: "https://broadcast-server.tor.us",

  torusNetwork: REACT_APP_TORUS_NETWORK as TORUS_NETWORK_TYPE,
  logLevel,
  isLRC: REACT_APP_TORUS_NETWORK === TORUS_NETWORK.TESTNET,
  alwaysAllowedHosts: ["localhost", "127.0.0.1", "sdk.openlogin.com"],

  isStorageAvailable: {
    [LOCAL_STORAGE_KEY]: storageAvailable(LOCAL_STORAGE_KEY),
    [SESSION_STORAGE_KEY]: storageAvailable(SESSION_STORAGE_KEY)
  },

  // key is the login provider
  loginConfig: {
    "upbond-line": {
      name: "LINE",
      description: "LINE",
      typeOfLogin: "line",
      clientId: REACT_APP_LINE_CLIENT_ID,
      logoHover: "",
      logoLight: "",
      logoDark: "",
      showOnModal: true,
      showOnDesktop: true,
      showOnMobile: true,
      mainOption: true,
      priority: 1,
      loginProvider: UPBOND_LINE_PROVIDER,
      walletVerifier: REACT_APP_LINE_VERIFIER,
      verifier: REACT_APP_LINE_VERIFIER,
      buttonDescription: "",
      verifierSubIdentifier: "torus",
      jwtParameters: {
        domain: REACT_APP_LOGIN_UPBOND_DOMAIN,
        clientId: REACT_APP_LINE_CLIENT_ID,
        client_id: REACT_APP_LINE_CLIENT_ID,
        connection: "line",
        scope: "openid email profile offline_access"
      },
      customLogo: "line"
    } as LoginConfigItem,
    "upbond-google": {
      loginProvider: "upbond-google",
      verifier: REACT_APP_GOOGLE_VERIFIER,
      typeOfLogin: "jwt",
      name: "Google",
      description: "Google",
      clientId: REACT_APP_GOOGLE_CLIENT_ID,
      verifierSubIdentifier: "torus",
      logoHover: "",
      logoLight: "",
      logoDark: "",
      showOnModal: true,
      mainOption: true,
      showOnDesktop: true,
      showOnMobile: true,
      jwtParameters: {
        domain: REACT_APP_LOGIN_UPBOND_DOMAIN,
        connection: "google",
        clientId: REACT_APP_GOOGLE_CLIENT_ID,
        client_id: REACT_APP_GOOGLE_CLIENT_ID,
        redirect_uri: `${window.location.origin}/auth`
      },
      buttonDescription: "",
      walletVerifier: REACT_APP_GOOGLE_VERIFIER,
      customLogo: "google"
    } as LoginConfigItem
  } as LoginConfig,
  loginsWithLightLogo: [""]
};

export const openLoginVerifiers: string[] = Object.values(config.loginConfig).reduce((acc: string[], x: any) => {
  acc.push(x.verifier);
  if (x.walletVerifier) acc.push(x.walletVerifier);
  return acc;
}, []);

export default config;
