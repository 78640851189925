import { NetworkType, setLoading, setProviderAsync } from "shared/actions/walletAction";
import { SUPPORTED_NETWORK_TYPES } from "shared/enums";
import { catchError } from "shared/utils/coreUtils";
import { useAppDispatch, useAppSelector } from "./useRedux";

export default function useNetwork() {
  const dispatch = useAppDispatch();
  const {
    networkType: { chainId }
  } = useAppSelector((state) => state.wallet);

  const changeNetwork = async (cid: number | string, type?: string | undefined) => {
    try {
      const filtered = Object.values(SUPPORTED_NETWORK_TYPES).filter((val) => {
        return parseInt(val.chainId.toString()) === parseInt(cid as string);
      });
      if (filtered.length > 0) {
        dispatch(setLoading(true));
        await setProviderAsync({ network: filtered[0] as NetworkType, type });
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      console.error(`@ -> error on changeNetwork (catch): `, error);
      dispatch(setLoading(false));
      catchError(error);
      throw new Error(error);
    }
  };

  return {
    chainId,
    changeNetwork
  };
}
