// @ts-nocheck
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { IonButton, IonList, IonModal, useIonRouter } from "@ionic/react";
import { useAppDispatch } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { logout } from "shared/actions/walletAction";
import SettingMainMb from "features/settings/components/settingMain/mb";
import SettingMainPc from "features/settings/components/settingMain/pc";
import "./style.css";

const SettingsPage: React.FC = () => {
  const nav = useIonRouter();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { breakpoint } = useTheme();
  const { t } = useTranslation();

  const handleLogout = async () => {
    dispatch(logout());
    setTimeout(() => {
      nav.push("/login", "root", "replace");
    }, 2000);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const presentToast = (position?: any) => {
    setOpen(!open);
  };

  return (
    <>
      {breakpoint < 1024 ? (
        <SettingMainMb handleLogout={handleLogout} presentToast={presentToast} />
      ) : (
        <SettingMainPc handleLogout={handleLogout} presentToast={presentToast} />
      )}

      <IonModal id="modal-error-c" className="modal-gas-confirm" trigger="open-modal" isOpen={open}>
        <div className="wrapper ion-padding modal-gas-confirm-wrapper text-center">
          <IonList lines="none" className="ion-text-center ion-padding">
            {t(`account.commingSoon`)}
          </IonList>
          <IonButton shape="round" color="primary" expand="full" onClick={presentToast}>
            {t("account.close")}
          </IonButton>
        </div>
      </IonModal>
    </>
  );
};

export default SettingsPage;
