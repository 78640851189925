// @ts-nocheck
import { IonList, IonModal } from "@ionic/react";
import { useAppSelector } from "hooks/useRedux";
import { IModalWalletConnect } from "interfaces/actions/IWalletConnect";
import { useRef } from "react";
import LegacySessionProposalModal from "./LegacySessionProposalModal";
import LegacySessionSendTransactionModal from "./LegacySessionSendTransactionModal";
import LegacySessionSignModal from "./LegacySessionSignModal";
import LegacySessionSignTypedDataModal from "./LegacySessionSignTypedDataModal";
import LegacySessionSwitchNetworkModal from "./LegacySessionSwitchNetworkModal";
import SessionProposalModal from "./SessionProposalModal";
import SessionSendTransactionModal from "./SessionSendTransactionModal";
import SessionRequestModal from "./SessionSignModal";
import SessionSignTypedDataModal from "./SessionSignTypedDataModal";
import SessionUnsuportedMethodModal from "./SessionUnsuportedMethodModal";
import SessionAddNetworkModal from "./SessionAddNetworkModal";

const ModalWalletConnect = (props: IModalWalletConnect) => {
  const { openModalWalletConnect, closeModalWalletConnect } = props;
  const walletConnect = useAppSelector((state) => state.walletConnect);
  const modal = useRef<HTMLIonModalElement>(null);
  return (
    <IonModal id="modal-wrapper-wallet-connect" backdropDismiss={false} ref={modal} trigger="open-modal" isOpen={openModalWalletConnect}>
      <div className="wrapper">
        <IonList lines="none">
          {walletConnect.view === "SessionProposalModal" && <SessionProposalModal closeTheModalWalletConnect={closeModalWalletConnect} />}
          {walletConnect.view === "SessionSignModal" && <SessionRequestModal closeTheModalWalletConnect={closeModalWalletConnect} />}
          {walletConnect.view === "SessionSignTypedDataModal" && (
            <SessionSignTypedDataModal closeTheModalWalletConnect={closeModalWalletConnect} />
          )}
          {walletConnect.view === "SessionSendTransactionModal" && (
            <SessionSendTransactionModal closeTheModalWalletConnect={closeModalWalletConnect} />
          )}
          {walletConnect.view === "SessionUnsuportedMethodModal" && (
            <SessionUnsuportedMethodModal closeTheModalWalletConnect={closeModalWalletConnect} />
          )}
          {walletConnect.view === "wallet_switchEthereumChain" && (
            <LegacySessionSwitchNetworkModal closeTheModalWalletConnect={closeModalWalletConnect} />
          )}
          {walletConnect.view === "SessionAddNetworkModal" && (
            <SessionAddNetworkModal closeTheModalWalletConnect={closeModalWalletConnect} />
          )}
          {walletConnect.view === "LegacySessionProposalModal" && (
            <LegacySessionProposalModal closeTheModalWalletConnect={closeModalWalletConnect} />
          )}
          {walletConnect.view === "LegacySessionSignModal" && (
            <LegacySessionSignModal closeTheModalWalletConnect={closeModalWalletConnect} />
          )}
          {walletConnect.view === "LegacySessionSignTypedDataModal" && (
            <LegacySessionSignTypedDataModal closeTheModalWalletConnect={closeModalWalletConnect} />
          )}
          {walletConnect.view === "LegacySessionSendTransactionModal" && (
            <LegacySessionSendTransactionModal closeTheModalWalletConnect={closeModalWalletConnect} />
          )}
        </IonList>
      </div>
    </IonModal>
  );
};
export default ModalWalletConnect;
