// @ts-nocheck
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Logo from "assets/icons/LogoUpbond.svg";
import LineIcon from "assets/icons/Line.svg";
import GoogleIcon from "assets/images/Google-Icon.png";
import * as React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
// import { mail } from "ionicons/icons";
// import { useEmbedUiDispatch } from "hooks/useEmbedUiDispatch";
import { IonButton, IonItemDivider, IonLoading, IonText } from "@ionic/react";
import useCommunicationStream from "hooks/useCommunicationStream";
import { triggerEmbed } from "shared/actions/walletAction";
import useTheme from "hooks/useTheme";
import { catchError } from "shared/utils/coreUtils";
import useLoading from "hooks/useLoading";
import { useEmbedUiDispatch } from "hooks/useEmbedUiDispatch";
export type ButtonsConfig = {
  name: string;
  verifier: string;
  bgColor: string;
  textColor: string;
  logo: string;
  description: string;
  text?: string | undefined;
  buttonText?: string | undefined;
};

const PopupModal = () => {
  const [buttons, setButtons] = React.useState<ButtonsConfig[] | []>([]);
  const modalState = useAppSelector((state) => state.embedUiState);
  const embedState = useAppSelector((state) => state.embedState);
  const { setLoading } = useLoading();
  const dispatcher = useAppDispatch();
  const dispatch = useEmbedUiDispatch();

  const { loading: isLoading } = useAppSelector((state) => state.wallet);
  const { openPopup } = useCommunicationStream();
  const { t } = useTranslation();
  const { breakpoint } = useTheme();

  const dynamicValue: { [key: string]: { bg: string; border: string; color: string; text: string } } = {
    "upbond-lineworks": {
      bg: "#ffffff",
      border: "2px solid #000000",
      color: "#000000",
      text: "Lineworks" + t("login.account")
    },
    "upbond-google": {
      bg: "#ffffff",
      border: "2px solid #000000",
      color: "#000000",
      text: "Google" + t("login.account")
    },
    "upbond-line": {
      bg: "#289B2A",
      border: "0px solid #fff",
      color: "#fafafa",
      text: "LINE" + t("login.account")
    },
    "upbond-otp": {
      bg: "#000",
      border: "0px solid #000000",
      color: "#fff",
      text: t("login.email")
    }
  };

  const specialVerifier = ["upbond-client_7c8f622d", "upbond-client_d8db30f1"];

  const getBackground = () => {
    if (
      embedState.embedTheme &&
      embedState.embedTheme.isActive &&
      embedState.embedTheme.modalColor &&
      embedState.embedTheme.modalColor !== ""
    ) {
      return embedState.embedTheme.modalColor;
    }
    return "#fafafa";
  };

  const getLogo = () => {
    if (embedState.embedTheme && embedState.embedTheme.isActive && embedState.embedTheme.logo && embedState.embedTheme.logo !== "") {
      return embedState.embedTheme.logo;
    }
    return Logo;
  };

  React.useEffect(() => {
    const {
      configuration: { loginConfig }
    } = embedState;

    const loginConfArr = Object.values(loginConfig) as any;
    const newButtonsConfig = [];
    for (const i in loginConfArr) {
      const obj = {
        logo: Object.getOwnPropertyNames(loginConfig)[i as any].includes("google") ? GoogleIcon : LineIcon
      } as ButtonsConfig;
      if (loginConfArr[i].buttonBgColor) {
        obj.bgColor = loginConfArr[i].buttonBgColor as string;
      }
      if (loginConfArr[i].buttonTextColor) {
        obj.textColor = loginConfArr[i].buttonTextColor as string;
      }
      if (loginConfArr[i].logo) {
        obj.logo = loginConfArr[i].logo as string;
      }
      obj.name = loginConfArr[i].name;
      obj.text = loginConfArr[i].text;
      obj.buttonText = loginConfArr[i].buttonText;
      obj.verifier = Object.getOwnPropertyNames(loginConfig)[i as any] as string;
      obj.description = loginConfArr[i].description;
      newButtonsConfig.push(obj);
    }
    setButtons(newButtonsConfig as ButtonsConfig[]);
  }, [embedState.configuration.loginConfig]);

  React.useEffect(() => {
    setLoading(false, `${t("login.loggingIn")}`);
  }, []);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) setLoading(false, `${t("login.loggingIn")}`);

  const onTriggerLogin = async (verifier: string) => {
    openPopup(false, false);
    const {
      configuration: {
        directConfig: { redirectUrl, enabled }
      }
    } = embedState;
    try {
      dispatcher(
        triggerEmbed({
          calledFromEmbed: true,
          dappRedirectUrl: redirectUrl,
          isUsingDirect: enabled,
          verifier,
          skipDialog: false,
          breakpoint
        })
      );
    } catch (error: any) {
      console.error(`@ -> error on onTriggerLogin (catch): `, error);
      openPopup(false, false);
      catchError(error);
      throw new Error(error);
    }
  };

  const getColor = () => {
    const { embedTheme } = embedState;

    const modalcolor = embedTheme?.modalColor === "#fffff";
    const bgcolor = embedTheme?.bgColor === "#4B68AE";
    const bgcolorhover = embedTheme?.bgColorHover === "#214999";
    const textcolor = embedTheme?.textColor === "#f3f3f3";
    const textcolorhover = embedTheme?.textColorHover === "#214999";
    const loginGlobalbgcolor = embedTheme?.upbondLogin?.globalBgColor === "#ffffff";
    const loginGlobaltextcolor = embedTheme?.upbondLogin?.globalTextColor === "#4B68AE";

    if (modalcolor && bgcolor && bgcolorhover && textcolor && textcolorhover && loginGlobalbgcolor && loginGlobaltextcolor) {
      // this condition is for checking the old default primary color then replaced by the new one
      return {
        modalColor: "#ffffff",
        bg: "#4B68AE",
        bgHover: "#214999",
        text: "#f3f3f3",
        textColorHover: "#ffffff",
        loginBgColor: "#ffffff",
        loginTextColor: "#000000"
      };
    } else {
      return {
        modalColor: embedTheme?.modalColor || "#fafafa",
        bg: embedTheme?.bgColor || "#4B68AE",
        bgHover: embedTheme?.bgColorHover || "#224999",
        text: embedTheme?.textColor || "#f3f3f3",
        textColorHover: embedTheme?.textColorHover || "#f3f3f3",
        loginBgColor: embedTheme?.upbondLogin?.globalBgColor || "#f3f3f3",
        loginTextColor: embedTheme?.upbondLogin?.globalTextColor || "#000000"
      };
    }
  };

  const dynamicLink = (name: string) => {
    console.log(embedState?.embedTheme);
    if (name === "terms") {
      if (embedState?.embedTheme?.termsLink?.termsCondition) {
        window.open(embedState?.embedTheme?.termsLink?.termsCondition);
      } else {
        window.open("https://upbond.notion.site/UPBOND-Wallet-Terms-of-Service-1b6d7c8f72a94f799c8ab1b82df6c799");
      }
    } else {
      if (embedState?.embedTheme?.termsLink?.privacyPolicy) {
        window.open(embedState?.embedTheme?.termsLink?.privacyPolicy);
      } else {
        window.open("https://www.upbond.io/privacy-policy");
      }
    }
  };

  return (
    <div
      className="w-screen h-screen flex justify-center items-center absolute overflow-y-scroll py-2.5 px-0"
      id="embed-modal"
      style={{
        backgroundColor: getColor().loginBgColor,
        color: getColor().loginTextColor
      }}>
      {modalState.modalIsOpen && (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          className="w-full !h-screen max-w-[500px] xs500:max-w-none flex flex-col justify-evenly xs500:justice-around items-center py-2.5 px-0">
          <div className="w-full absolute top-2.5 flex justify-center">
            <div className="w-full mx-4 flex justify-end">
              <div className="bg-[#E9E9E9] p-2 rounded-full h-10 w-10 justify-center items-center">
                <button
                  onClick={() => {
                    dispatch("modalIsOpen", false);
                    openPopup(false, true);
                  }}
                  style={{
                    backgroundColor: getBackground()
                  }}
                  className="close-button h-6 w-6 rounded-full flex justify-center items-center">
                  <svg
                    width="27px"
                    height="27px"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#000000"
                    viewBox="0 0 24 24"
                    strokeWidth="1.8"
                    stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div
            className="!w-[330px] h-50 min-w-[330px] min-h-50 bg-center bg-contain bg-no-repeat static"
            style={{ backgroundImage: `url(${getLogo()})` }}>
            {/* <img src={getLogo()} alt="upbond-logo" /> */}
          </div>
          <div className="w-full text-center font-normal flex flex-col max-w-[375px] mb-[3%]">
            <h5 className="text-xl font-bold mb-3.5 font-roboto mt-4"> {t("login.title")}</h5>
            <div className="flex justify-center items-center">
              <IonLoading cssClass="my-custom-class" isOpen={isLoading} spinner={"circles"} />
            </div>
            {!isLoading &&
              (buttons.length > 0 ? (
                buttons.map((button, i) => (
                  <React.Fragment key={i}>
                    {button.verifier === "upbond-otp" && buttons.length > 1 && (
                      <IonItemDivider className="h-px bg-E4E4E4 min-h-1 w-full my-6 mx-0"></IonItemDivider>
                    )}
                    <div className="py-0 px-10 flex flex-col items-center justify-center" key={i}>
                      <IonButton
                        expand="full"
                        shape="round"
                        fill="clear"
                        className="text-lg w-full !font-inter font-bold mb-3.5 rounded-2.75xl"
                        style={{
                          backgroundColor:
                            button.bgColor && button.verifier !== "upbond-google" ? button.bgColor : dynamicValue[button.verifier]?.bg,
                          border: dynamicValue[button.verifier]?.border
                        }}
                        onClick={() => onTriggerLogin(button.verifier)}>
                        {/* <IonIcon slot="start" src={button.logo} /> */}
                        {button.description !== "gohey" &&
                          button.verifier !== "upbond-otp" &&
                          !specialVerifier.includes(button.verifier) && (
                            <img
                              src={button.verifier === "upbond-google" ? GoogleIcon : button.logo}
                              alt="logo"
                              className="w-5 h-5 mr-1.5"
                            />
                          )}
                        <IonText
                          className="!font-inter leading-normal"
                          style={{
                            color:
                              button.textColor && button.verifier !== "upbond-google"
                                ? button.textColor
                                : dynamicValue[button.verifier]?.color,
                            fontSize: button.verifier && button.verifier === "upbond-lineworks" && "12px"
                          }}>
                          {button.verifier && button.verifier === "upbond-lineworks" ? (
                            `${button.name}`
                          ) : button.description === "gohey" ? (
                            <span>
                              <p className="text-base font-bold">メールアドレス</p>
                              <p className="text-13">*所長・監督の方</p>
                            </span>
                          ) : (
                            <span>{button?.buttonText ? button?.buttonText : dynamicValue[button.verifier]?.text ?? t("login.email")}</span>
                          )}
                        </IonText>
                      </IonButton>
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <p>No login config, please setup your login config</p>
              ))}
          </div>

          <div className="w-full text-center font-normal flex flex-col justify-center px-4 py-0 mb-[3%] mt-[15%] xs500:mt-0">
            <h5 className="text-base leading-4.75 mb-10 mt-4">
              {t("login.byTerm")}
              <span className="pointer underline text-blue-500 mx-1" onClick={() => dynamicLink("terms")}>
                {t("login.term")}
              </span>
              {t("login.and")}
              <span className="pointer underline text-blue-500 mx-1" onClick={() => dynamicLink("privacy")}>
                {t("login.privacy")}
              </span>
              {t("login.agree")}
            </h5>

            {/* <div className="notice mb-4 text-center p-1">
              10月8日（日）15時〜19時の間は一時的に <br /> 新規ユーザーの登録ができません。
              <br />
              新規NFT取得のユーザーはQRコードをスクリーンショットで保管をしていただき、改めて登録をお願いします。
              <br />
              ご不便おかけしますが、ご理解のほどよろしくお願いします。
            </div> */}
            {/* <button
              onClick={() => window.open("https://www.upbond.io/inquiry-wallet")}
              className="text-base font-bold my-0 mx-auto py-0 px-5 mb-3.75 flex items-center rounded-[50px]"
              style={{
                border: `2px solid ${getColor().bg}`,
                color: getColor().bg,
                backgroundColor: "#fff" || getColor().modalColor
              }}>
              <IonIcon slot="start" icon={mail} style={{ color: getColor().bg }} />
              <p className="my-4 ml-1.25 font-roboto">{t("login.contactUs")}</p>
            </button> */}
            <IonText className="font-bold text-sm font-roboto">Supported By UPBOND</IonText>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default PopupModal;
