// @ts-nocheck
import { IonButton, IonIcon } from "@ionic/react";
import { setOpenModalPurchase } from "shared/actions/walletAction";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useTranslation } from "react-i18next";
import { addOutline } from "ionicons/icons";
import Notification from "assets/icons/Notifications.svg";

type Props = {
  countNotif: number;
  showAddCash: boolean;
};

const HomeHeaderPc = (props: Props) => {
  const { t } = useTranslation();
  const walletConnect = useAppSelector((state) => state.walletConnect);
  const openModalPurchase = useAppSelector((state) => state.wallet.openModalPurchase);
  const appDispatch = useAppDispatch();

  return (
    <>
      {props.showAddCash && (
        <IonButton
          shape="round"
          color="primary"
          className="h-[36px]"
          onClick={() => openModalPurchase == false && appDispatch(setOpenModalPurchase(true))}>
          <IonIcon slot="start" icon={addOutline} />
          {t("home.addCash")}
        </IonButton>
      )}
      {props.countNotif > 0 && walletConnect.connected === true ? (
        <div className="absolute bg-white top-[2%] --right-[2.5%] border border-red-500 rounded-full w-4 h-4 text-10 flex justify-center items-center text-center font-bold z-50">
          {props.countNotif}
        </div>
      ) : null}
      <IonButton routerLink={"/wallet/pending-transaction"} routerDirection="forward" color="primary" className="notifications h-[36px]">
        <IonIcon slot="icon-only" src={Notification} />
      </IonButton>
    </>
  );
};

export default HomeHeaderPc;
