/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { post } from "@toruslabs/http-helpers";
import { RootState, store } from "shared/store";
import olTkeyModuleSlice from "shared/store/olTKeyModuleSlice";
import olLoginPerfModuleSlice from "shared/store/olLoginPerfModuleSlice";
import olUserModuleSlice from "shared/store/olUserModuleSlice";
import { updateUserPersistedInfo } from "shared/actions/olUserModuleAction";
import { ShareSerializationModule } from "@tkey/share-serialization";
import {
  CalculateSettingsPageParams,
  CreateNewTKeyParams,
  KeyMode,
  ModuleShareDescription,
  OnDeviceShare,
  PendingShareTransferRequest,
  SetDeviceWebAuthnRegisteredParams,
  SettingsPageData,
  ShareSerializationEmailShares,
  TKeyAccount,
  TkeyInputParams,
  TorusUserInfo,
  TouchIDPreferences,
  WebAuthnDeviceShares
} from "shared/utils/openlogininterface";
import { isMatch, pickBy } from "lodash-es";
import { deleteDevice, updateDeviceInfo } from "shared/utils/openloginmutation";
import { UpdateUserPayload } from "shared/utils/__generated__/graphql-types";
import { TorusStorageLayer } from "@tkey/storage-layer-torus";
import {
  CHECK_IF_TKEY_EXIST,
  DEVICE_MODULE_KEY,
  PASSWORD_QUESTION,
  SECURITY_QUESTIONS_MODULE_KEY,
  SHARE_SERIALIZATION_MODULE_KEY,
  SHARE_TRANSFER_MODULE_KEY,
  TKEY_REQUIRE_MORE_INPUT,
  TKEY_SHARE_TRANSFER_INTERVAL,
  USER_MODULE_KEY,
  WEBAUTHN_DEVICE_MODULE_KEY,
  WEB_STORAGE_MODULE_KEY
} from "shared/utils/openloginenums";
import config from "shared/utils/openloginconfig";
import { KeyDetails, ShareStore, StringifiedType } from "@tkey/common-types";
import log from "loglevel";
import BN from "bn.js";
import createTKeyInstance from "shared/utils/tkeyFactory";
import { TKeyDefault } from "@tkey/default";
import { capitalizeFirstLetter, formatDate } from "shared/utils/coreUtils";
import { CHROME_EXTENSION_STORAGE_MODULE_KEY, getAllPrivateKeys, getCustomDeviceInfo, parseShares } from "shared/utils/tKeyUtils";
import { SecurityQuestionsModule, SECURITY_QUESTIONS_MODULE_NAME } from "@tkey/security-questions";
import { ShareTransferModule, SHARE_TRANSFER_MODULE_NAME } from "@tkey/share-transfer";
import { WebStorageModule, WEB_STORAGE_MODULE_NAME, storeShareOnLocalStorage } from "@tkey/web-storage";
import CustomAuth, { TorusKey } from "@toruslabs/customauth";
import { privateToAddress } from "ethereumjs-util";
import { Transaction } from "ethers";
import { getJoinedKey } from "shared/utils/openloginutils";
import bowser from "bowser";

import { TorusServiceProvider } from "@tkey/service-provider-torus";

export const olTkeyModuleAction = olTkeyModuleSlice.actions;
export const olLoginPerfModuleAction = olLoginPerfModuleSlice.actions;
export const olUserModuleAction = olUserModuleSlice.actions;
const torusSp = new TorusServiceProvider({
  customAuthArgs: {
    web3AuthClientId: process.env.REACT_APP_CLIENT_ID as any,
    baseUrl: `${window.location.origin}`,
    redirectPathName: "auth",
    enableLogging: true,
    uxMode: "redirect",
    network: process.env.REACT_APP_TORUS_NETWORK as any
  }
});

const storageLayer = new TorusStorageLayer({
  hostUrl: "https://metadata.tor.us",
  enableLogging: false
});
const shareSerializationModule = new ShareSerializationModule();

// Configuration of Modules

// Instantiation of tKeyThreshold
const tKeyThreshold = new TKeyDefault({
  enableLogging: false,
  modules: {
    shareSerialization: shareSerializationModule,
    [SECURITY_QUESTIONS_MODULE_NAME]: new SecurityQuestionsModule(),
    [WEB_STORAGE_MODULE_NAME]: new WebStorageModule(),
    [SHARE_TRANSFER_MODULE_NAME]: new ShareTransferModule()
  },
  serviceProvider: torusSp,
  storageLayer,
  manualSync: true
});
export interface ITkeyModuleState {
  tKey: TKeyDefault;
  postboxKey: string;
  settingsPageData: SettingsPageData;
  parsedShareDescriptions: unknown[];
  keyDetails: KeyDetails;

  requestStatusCheckId?: number;
  error?: string;
  shareTransferRequests?: PendingShareTransferRequest[];
  success?: string;
  currentEncPubKeyX?: string;
  initialized: boolean;
  tKeyPrivKey: string;
  keyMode: KeyMode;
}
export const _init = async ({ postboxKey, importKey, tKeyJson, shareStores, dappShare }: TkeyInputParams): Promise<void> => {
  const { clientTimeOffset = 0 } = store.getState().olUserModule;
  const tKey = await createTKeyInstance({ postboxKey, importKey, tKeyJson, shareStores, dappShare, serverTimeOffset: clientTimeOffset });
  store.dispatch(olTkeyModuleAction.updateState({ postboxKey, tKey, initialized: true } as any));
  await calculateSettingsPageData({ forceCheckOnDeviceShare: !!tKeyJson });
};

export const checkIfTKeyExists = async (postboxKey: BN): Promise<{ success: boolean; shareStore?: ShareStore }> => {
  try {
    if (!postboxKey) return { success: false };
    const { clientTimeOffset = 0 } = store.getState().olUserModule;
    const storageLayer = new TorusStorageLayer({
      hostUrl: config.metadataHost,
      serverTimeOffset: clientTimeOffset
    });
    const operationName = CHECK_IF_TKEY_EXIST;
    // start measuring perf
    window.performance.mark(`${operationName}_start`);
    const metadata = (await storageLayer.getMetadata({ privKey: postboxKey })) as ShareStore;
    var success = Object.keys(metadata).length > 0;
    if ((metadata as any).message) {
      success = false;
    }
    store.dispatch(
      olLoginPerfModuleAction.markRouteAndTime({
        route: "auth",
        operation: operationName
      })
    );
    return { success: success, shareStore: metadata };
  } catch (error) {
    log.error("unable to check for tkey", error);
    return { success: false };
  }
};

export const getTkeyInstance = async (): Promise<TKeyDefault> => {
  const tKey = store.getState().olTKeyModule.tKey;
  let shares: boolean;
  try {
    shares = !!(await (tKey.modules[WEB_STORAGE_MODULE_KEY] as WebStorageModule).getDeviceShare());
  } catch (error) {
    shares = false;
  }
  if (shares) {
    try {
      await (tKey.modules[WEB_STORAGE_MODULE_KEY] as WebStorageModule).inputShareFromWebStorage();
    } catch (error) {
      console.error(error, "@error while awaiting input share from deviceShare");
    }
    tKey.reconstructKey();
  }
  if (store.getState().olTKeyModule.initialized) return tKey;
  return new Promise((resolve) => {
    const unwatch = store.subscribe(() => {
      const state = store.getState();
      if (state.olTKeyModule.tKey) {
        resolve(tKey);
        unwatch();
      }
    });
  });
};

export const calculateSettingsPageData = async (params: CalculateSettingsPageParams = {}) => {
  const localTKey = await getTkeyInstance();
  if (!localTKey.metadata) return;
  const { forceCheckOnDeviceShare = false } = params;
  const onDeviceShare = { available: false } as OnDeviceShare;
  const passwordShare = { available: false };
  const keyDetails = localTKey.getKeyDetails();
  const { shareDescriptions = {}, totalShares, threshold: thresholdShares } = keyDetails;

  const pubPoly = localTKey.metadata.getLatestPublicPolynomial();
  const pubPolyID = pubPoly.getPolynomialID();
  const currentPolynomialShares = Object.keys(localTKey.shares[pubPolyID]);
  const allShareIndexes = localTKey.metadata.getShareIndexesForPolynomial(pubPolyID);

  const availableShareDescriptionIndexes = Object.keys(shareDescriptions);

  const parsedShareDescriptions = availableShareDescriptionIndexes.reduce((acc: ModuleShareDescription[], x: string) => {
    const internalDescriptions = shareDescriptions[x].map((y) => ({ ...JSON.parse(y), shareIndex: x }));
    acc.push(...internalDescriptions);
    return acc;
  }, []);

  parsedShareDescriptions.forEach((x: { available: boolean; shareIndex: string }) => {
    x.available = currentPolynomialShares.includes(x.shareIndex);
  });

  // Exported email share
  const exportedEmailShares = parsedShareDescriptions.filter((x: { module: string }) => x.module === SHARE_SERIALIZATION_MODULE_KEY);
  const emailShares = exportedEmailShares.reduce((acc: ShareSerializationEmailShares, x) => {
    acc[x.shareIndex] = {
      index: x.shareIndex,
      indexShort: x.shareIndex.slice(0, 4),
      email: x.data as string,
      dateAdded: formatDate(x.date as string)
    };
    return acc;
  }, {});

  // Total device shares
  const allDeviceShares = parseShares(
    parsedShareDescriptions.filter((x) => x.module === CHROME_EXTENSION_STORAGE_MODULE_KEY || x.module === WEB_STORAGE_MODULE_KEY)
  );

  // WebAuthn device shares
  const webauthnDeviceShares = parsedShareDescriptions.reduce((acc: WebAuthnDeviceShares, x) => {
    if (x.module === WEBAUTHN_DEVICE_MODULE_KEY) {
      const val = {
        device: x.device as string,
        dateAdded: formatDate(x.dateAdded as string),
        index: x.shareIndex,
        indexShort: x.shareIndex.slice(0, 4)
      };
      if (acc[x.shareIndex]) {
        acc[x.shareIndex].push(val);
      } else {
        acc[x.shareIndex] = [val];
      }
    }
    return acc;
  }, {});
  // For ondevice share
  if (!store.getState().olTKeyModule.settingsPageData?.deviceShare.available || forceCheckOnDeviceShare) {
    try {
      const onDeviceLocalShare = await (localTKey.modules[WEB_STORAGE_MODULE_KEY] as WebStorageModule).getDeviceShare();

      if (onDeviceLocalShare) {
        onDeviceShare.available = true;
        onDeviceShare.share = onDeviceLocalShare;
      }
    } catch {
      onDeviceShare.available = false;
    }
  }

  // password share
  const passwordModules = parsedShareDescriptions.filter((x) => x.module === SECURITY_QUESTIONS_MODULE_KEY);
  passwordShare.available = passwordModules.length > 0;

  // Current threshold
  const threshold = `${thresholdShares}/${totalShares}`;
  const otherShares = allShareIndexes.filter((value) => !availableShareDescriptionIndexes.includes(value) && value !== "1");
  store.dispatch(
    olTkeyModuleAction.updateState({
      settingsPageData: {
        deviceShare: onDeviceShare,
        exportedEmailShares: emailShares,
        allDeviceShares,
        webauthnDeviceShares,
        passwordShare,
        threshold,
        otherShares
      },
      parsedShareDescriptions,
      keyDetails,
      tKey: localTKey
    } as any)
  );
};

export const appendTkeyCreationFactor = async ({ factor, sync }: { factor: string; sync?: boolean }) => {
  if (store.getState().olTKeyModule.creationFactor && !store.getState().olTKeyModule.creationFactor.includes(factor)) {
    const creationFactor =
      factor === "webauthn"
        ? `${factor}|${store.getState().olTKeyModule.creationFactor}`
        : `${store.getState().olTKeyModule.creationFactor}|${factor}`;
    store.dispatch(olTkeyModuleAction.setCreationFactor({ factor: creationFactor }));
  } else {
    store.dispatch(olTkeyModuleAction.setCreationFactor({ factor }));
  }
  if (sync) {
    await updateUserPersistedInfo({ payload: { tkey_creation_factor: store.getState().olTKeyModule.creationFactor } } as any);
  }
};

export const ensureTKeyCreated = async ({ keyInfo, userInfo }: { keyInfo: TorusKey; userInfo: TorusUserInfo }) => {
  if (store.getState().olTKeyModule.keyMode === "v1" || store.getState().olTKeyModule.keyMode === "2/n") return;
  // Create a torus-direct instance to use its utility functions only
  const customAuth = new CustomAuth({
    baseUrl: window.location.origin,
    metadataUrl: config.metadataHost,
    network: config.torusNetwork,
    enableOneKey: true,
    // networkUrl: config.networkUrl,
    storageServerUrl: config.storageServerUrl,
    web3AuthClientId: store.getState().olUserModule.currentDappClientId || (config.openloginDappModuleKey as string)
  });
  await createNewTKey({
    postboxKey: customAuth.getPostboxKeyFrom1OutOf1(keyInfo.privateKey, keyInfo.metadataNonce),
    importKey: keyInfo.privateKey,
    loginProvider: capitalizeFirstLetter(userInfo.typeOfLogin),
    verifierId: userInfo.email || userInfo.verifierId,
    customDeviceInfo: getCustomDeviceInfo(),
    syncLocalTransitions: true
  });
  store.dispatch(olTkeyModuleAction.updateState({ keyMode: "2/n" }));
};

export const createNewTKey = async ({
  postboxKey,
  importKey,
  password,
  backup,
  recoveryEmail,
  loginProvider,
  verifierId,
  syncLocalTransitions,
  customDeviceInfo,
  sub,
  typeOfUser
}: CreateNewTKeyParams): Promise<TKeyAccount[]> => {
  await _init({ postboxKey, importKey, shareStores: [] });
  const { tKey, settingsPageData } = store.getState().olTKeyModule;
  appendTkeyCreationFactor({ factor: "device" });
  if (password) {
    await (tKey.modules[SECURITY_QUESTIONS_MODULE_KEY] as SecurityQuestionsModule).generateNewShareWithSecurityQuestions(
      password,
      PASSWORD_QUESTION
    );

    appendTkeyCreationFactor({ factor: "password" });
  }

  if (sub && typeOfUser === "v1") {
    await (tKey.modules[SECURITY_QUESTIONS_MODULE_KEY] as SecurityQuestionsModule).inputShareFromSecurityQuestions(sub);
  }
  // can't do some operations without key reconstruction
  const privKey = await tKey.reconstructKey(false);
  if (backup) {
    try {
      const { deviceShare } = settingsPageData;
      if (deviceShare.share) {
        // await (tKey.modules[WEB_STORAGE_MODULE_KEY] as WebStorageModule).storeDeviceShareOnFileStorage(
        //   deviceShare.share.share.shareIndex
        // );
      }
    } catch (error) {
      log.error(error);
    }
  }

  if (recoveryEmail) {
    store.dispatch(olTkeyModuleAction.updateState({ tKey } as any)); // update state again
    appendTkeyCreationFactor({ factor: "email" });
    await addRecoveryShare({
      recoveryEmail,
      reCalculate: false,
      loginProvider,
      verifierId,
      syncMetadata: !!syncLocalTransitions,
      updateBackend: false
    });
  }

  const localTKey = await getTkeyInstance();

  await calculateSettingsPageData({ forceCheckOnDeviceShare: true });
  if (customDeviceInfo) {
    await updateDeviceShareDescription({ sync: false, reCalculate: true, customDeviceInfo });
  }
  if (syncLocalTransitions) await localTKey.syncLocalMetadataTransitions();
  // this.startShareTransferRequestListener();
  const localTKeyPostGeneration = await getTkeyInstance();
  const hexKeys = await getAllPrivateKeys(localTKeyPostGeneration, privKey?.secp256k1Key);
  if (hexKeys.length > 0)
    store.dispatch(olTkeyModuleAction.updateState({ tKeyPrivKey: hexKeys[0].privKey, tKey: localTKeyPostGeneration } as any));
  const { walletKeyInfo } = store.getState().olUserModule;

  if (syncLocalTransitions) {
    const userTkeyInfo: UpdateUserPayload = {
      tkey_public_address: privateToAddress(Buffer.from(store.getState().olTKeyModule.tKeyPrivKey.padStart(64, "0"), "hex")).toString(
        "hex"
      ),
      tkey_backup_emails: Object.values(store.getState().olTKeyModule.settingsPageData.exportedEmailShares).length,
      tkey_threshold: store.getState().olTKeyModule.keyDetails.totalShares,
      tkey_creation_factor: store.getState().olTKeyModule.creationFactor,
      tkey_password: !!password || null,
      wallet_public_address: walletKeyInfo.publicAddress
    };
    await updateUserPersistedInfo({ payload: userTkeyInfo } as any);
  }

  return hexKeys;
};

export const updateDeviceShareDescription = async (params: {
  sync: boolean;
  reCalculate: boolean;
  customDeviceInfo: Record<string, string>;
}): Promise<void> => {
  try {
    const { sync, reCalculate, customDeviceInfo } = params;
    const localTKey = await getTkeyInstance();
    const deviceShareIndex = store.getState().olTKeyModule.settingsPageData.deviceShare.share?.share.shareIndex.toString("hex");
    if (!deviceShareIndex) return;
    const deviceShare = store.getState().olTKeyModule.settingsPageData.allDeviceShares.find((x) => x.index === deviceShareIndex);
    if (!deviceShare) return;
    const oldShareDescription: { module: string; userAgent: string; dateAdded: number; customDeviceInfo?: string } = {
      module: deviceShare.module,
      userAgent: deviceShare.userAgent,
      dateAdded: deviceShare.rawDateAdded as number
    };
    if (deviceShare.customDeviceInfo) {
      oldShareDescription.customDeviceInfo = JSON.stringify(deviceShare.customDeviceInfo);
    }
    const stringifiedInfo = JSON.stringify(customDeviceInfo);
    if (stringifiedInfo !== oldShareDescription.customDeviceInfo) {
      const newShareDescription = {
        ...oldShareDescription,
        customDeviceInfo: stringifiedInfo
      };
      await localTKey.updateShareDescription(
        deviceShareIndex,
        JSON.stringify(oldShareDescription),
        JSON.stringify(newShareDescription),
        true
      );
      if (sync) await localTKey.syncLocalMetadataTransitions();
      if (reCalculate) await calculateSettingsPageData();
    }
  } catch (error) {
    // Not important task. can swallow error
    log.error(error);
  }
};

export const exportShare = async (shareIndex: string): Promise<string> => {
  const localTKey = await getTkeyInstance();
  const shareStore = localTKey.outputShareStore(shareIndex);
  const serializedShare = await (localTKey.modules[SHARE_SERIALIZATION_MODULE_KEY] as ShareSerializationModule).serialize(
    shareStore.share.share,
    "mnemonic"
  );
  return serializedShare as string;
};
export const sendRecoveryShareEmail = async (params: {
  recoveryEmail: string;
  shareIndex: string;
  loginProvider: string;
  verifierId: string;
}): Promise<void> => {
  try {
    const { recoveryEmail, shareIndex, loginProvider, verifierId } = params;
    const serializedShare = await exportShare(shareIndex);
    await post(config.tkeyEmailHost, {
      data: serializedShare,
      logo: "/img/openlogin-logo.png",
      name: "Web3Auth",
      email: recoveryEmail,
      baseUrl: window.location.origin,
      typeOfLogin: loginProvider,
      network: config.torusNetwork,
      verifierId
    });
  } catch (error) {
    log.error(error);
  }
};

export const syncMetadataTransitions = async () => {
  try {
    const localTKey = await getTkeyInstance();
    await localTKey.syncLocalMetadataTransitions();
    store.dispatch(olTkeyModuleAction.updateState({ tKey: localTKey } as any));
  } catch (err) {
    log.error("error while syncing metadata transitions", err);
    throw err;
  }
};

export const addRecoveryShare = async (params: {
  recoveryEmail: string;
  reCalculate: boolean;
  loginProvider: string;
  verifierId: string;
  syncMetadata: boolean;
  updateBackend?: boolean; // default is true
}): Promise<void> => {
  try {
    const { recoveryEmail, reCalculate, loginProvider, verifierId, syncMetadata, updateBackend = true } = params;
    const localTKey = await getTkeyInstance();

    const { newShareIndex } = await localTKey.generateNewShare();
    const emailShareDescription = {
      data: recoveryEmail,
      date: new Date(),
      module: "shareSerialization"
    };
    await localTKey.addShareDescription(newShareIndex.toString("hex"), JSON.stringify(emailShareDescription), true);
    if (syncMetadata) await localTKey.syncLocalMetadataTransitions();
    await sendRecoveryShareEmail({ recoveryEmail, shareIndex: newShareIndex.toString("hex"), loginProvider, verifierId });
    if (reCalculate) await calculateSettingsPageData({ forceCheckOnDeviceShare: true });
    if (updateBackend) {
      const userTkeyInfo: UpdateUserPayload = {
        tkey_backup_emails: Object.values(store.getState().olTKeyModule.settingsPageData.exportedEmailShares).length,
        tkey_threshold: store.getState().olTKeyModule.keyDetails.totalShares,
        backup_phrase_setup_at: new Date().toISOString(),
        backup_phrase_setup_email: recoveryEmail
      };
      await localTKey.addShareDescription(newShareIndex.toString("hex"), JSON.stringify(emailShareDescription), true);
      if (syncMetadata) await localTKey.syncLocalMetadataTransitions();
      await sendRecoveryShareEmail({ recoveryEmail, shareIndex: newShareIndex.toString("hex"), loginProvider, verifierId });
      if (reCalculate) await calculateSettingsPageData({ forceCheckOnDeviceShare: true });
      if (updateBackend) {
        const userTkeyInfo: UpdateUserPayload = {
          tkey_backup_emails: Object.values(store.getState().olTKeyModule.settingsPageData.exportedEmailShares).length,
          tkey_threshold: store.getState().olTKeyModule.keyDetails.totalShares,
          backup_phrase_setup_at: new Date().toISOString(),
          backup_phrase_setup_email: recoveryEmail
        };
        await updateUserPersistedInfo({ payload: userTkeyInfo });
      }
    }
  } catch (error) {
    log.error(error);
  }
};

export const setDeviceWebAuthnRegistered = async ({ shareIndex }: SetDeviceWebAuthnRegisteredParams): Promise<void> => {
  const deviceShare = store.getState().olTKeyModule.settingsPageData.allDeviceShares.find((x) => x.index === shareIndex);
  const webauthnDeviceShares = store.getState().olTKeyModule.settingsPageData.webauthnDeviceShares[shareIndex] || [];

  if (!deviceShare) return;
  const webauthnShareAlreadyExists = webauthnDeviceShares.some((x: any) => x.device === deviceShare.osDetails);
  if (webauthnShareAlreadyExists) return;
  const localTKey = await getTkeyInstance();
  await localTKey.addShareDescription(
    shareIndex,
    JSON.stringify({
      module: WEBAUTHN_DEVICE_MODULE_KEY,
      device: deviceShare.osDetails,
      dateAdded: Date.now(),
      userAgent: deviceShare.userAgent
    }),
    true
  );
  await localTKey.syncLocalMetadataTransitions();
  await calculateSettingsPageData({ forceCheckOnDeviceShare: true });
};

export async function getPendingShareTransferRequests(tKey: TKeyDefault): Promise<PendingShareTransferRequest[]> {
  const shareTransferModule = tKey.modules[SHARE_TRANSFER_MODULE_KEY] as ShareTransferModule;
  if (!shareTransferModule) return [];
  const latestShareTransferStore = await shareTransferModule.getShareTransferStore();
  const pendingRequests = Object.keys(latestShareTransferStore).reduce((acc: PendingShareTransferRequest[], x) => {
    const browserDetail = bowser.parse(latestShareTransferStore[x].userAgent);
    const { userIp } = latestShareTransferStore[x];
    if (!latestShareTransferStore[x].encShareInTransit)
      acc.push({ ...latestShareTransferStore[x], browserDetail, userIp, encPubKeyX: x } as PendingShareTransferRequest);
    return acc;
  }, []);
  return pendingRequests;
}

export const startShareTransferRequestListener = async (): Promise<void> => {
  if (store.getState().olTKeyModule.requestStatusCheckId) clearInterval(store.getState().olTKeyModule.requestStatusCheckId);
  if (store.getState().olTKeyModule.keyMode === "v1" || store.getState().olTKeyModule.keyMode === "2/n") {
    if (store.getState().olTKeyModule.tKeyPrivKey) {
      const checkFn = async () => {
        try {
          const internalTKeyInstance = await getTkeyInstance();
          const pendingRequests = await getPendingShareTransferRequests(internalTKeyInstance);
          store.dispatch(olTkeyModuleAction.updateState({ shareTransferRequests: pendingRequests } as any));

          if (Object.keys(pendingRequests).length > 0) {
            clearInterval(store.getState().olTKeyModule.requestStatusCheckId);
          }
        } catch (error) {
          clearInterval(store.getState().olTKeyModule.requestStatusCheckId);
          log.error(error);
        }
      };
      checkFn();
      store.dispatch(
        olTkeyModuleAction.updateState({ requestStatusCheckId: Number(setInterval(checkFn, TKEY_SHARE_TRANSFER_INTERVAL)) } as any)
      );
    } else {
      // throw CoreError.privateKeyUnavailable();
    }
  }
};

export const stopShareTransferRequestListener = (): void => {
  if (store.getState().olTKeyModule.requestStatusCheckId) clearInterval(store.getState().olTKeyModule.requestStatusCheckId);
  store.dispatch(olTkeyModuleAction.updateState({ requestStatusCheckId: 0 } as any));
};

export const makeShareTransferRequest = async (): Promise<TKeyAccount[]> => {
  await _init({ postboxKey: store.getState().olUserModule.keyInfo.privateKey, tKeyJson: undefined, shareStores: [] });
  const localTKey = await getTkeyInstance();

  const shareTransferModule = localTKey.modules[SHARE_TRANSFER_MODULE_KEY] as ShareTransferModule;
  await shareTransferModule.setShareTransferStore({});
  const currentEncPubKeyX = await shareTransferModule.requestNewShare(window.navigator.userAgent, localTKey.getCurrentShareIndexes());
  store.dispatch(olTkeyModuleAction.updateState({ currentEncPubKeyX }));
  await shareTransferModule.startRequestStatusCheck(currentEncPubKeyX, true);

  try {
    const updatedTkey = await localTKey.updateSDK();
    store.dispatch(olTkeyModuleAction.updateState({ currentEncPubKeyX: "", tKey: updatedTkey }));
    const keys = await tryFinishTkeyReconstruction();
    generateAndStoreNewDeviceShare(localTKey);
    return keys;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const inputBackupShare = async (shareMnemonic: string): Promise<TKeyAccount[]> => {
  await _init({ postboxKey: store.getState().olUserModule.keyInfo.privateKey, tKeyJson: undefined, shareStores: [] });
  try {
    const localTKey = await getTkeyInstance();
    // In rehydration we always have privKey
    if (
      store.getState().olTKeyModule.tKeyPrivKey &&
      (store.getState().olTKeyModule.keyMode === "v1" || store.getState().olTKeyModule.keyMode === "2/n")
    ) {
      await localTKey.reconstructKey();
      store.dispatch(olTkeyModuleAction.updateState({ tKey: localTKey } as any));
    }
  } catch (error) {
    log.warn(error, "maybe reconstruct only in certain times");
  }

  const localTKey = await getTkeyInstance();

  const deserializedShare = await (localTKey.modules[SHARE_SERIALIZATION_MODULE_KEY] as ShareSerializationModule).deserialize(
    shareMnemonic,
    "mnemonic"
  );
  await localTKey.inputShare(deserializedShare);
  const keys = await tryFinishTkeyReconstruction();
  await generateAndStoreNewDeviceShare(localTKey);
  return keys;
};

export const inputPassword = async (password: string): Promise<TKeyAccount[]> => {
  try {
    await _init({ postboxKey: store.getState().olUserModule.keyInfo.privateKey, tKeyJson: undefined, shareStores: [] });
    const localTKey = await getTkeyInstance();

    try {
      await (localTKey.modules[SECURITY_QUESTIONS_MODULE_KEY] as SecurityQuestionsModule).inputShareFromSecurityQuestions(password);
    } catch (error) {
      console.error(error, "@error while awaiting input share fro sec question");
    }

    const keys = await tryFinishTkeyReconstruction();
    try {
      await generateAndStoreNewDeviceShare(localTKey);
    } catch (error) {
      console.error(error, "@error while generating new device share");
    }

    return keys;
  } catch (error) {
    log.error(`Error while input password: `, error);
    return [];
  }
};

export const cleanUpShareTransfer = async (): Promise<void> => {
  const localTKey = await getTkeyInstance();
  await (localTKey.modules[SHARE_TRANSFER_MODULE_KEY] as ShareTransferModule).cancelRequestStatusCheck();
  if (store.getState().olTKeyModule.currentEncPubKeyX)
    await (localTKey.modules[SHARE_TRANSFER_MODULE_KEY] as ShareTransferModule).deleteShareTransferStore(
      store.getState().olTKeyModule.currentEncPubKeyX
    );
};

export const changePassword = async (password: string): Promise<void> => {
  const localTKey = await getTkeyInstance();
  await (localTKey.modules[SECURITY_QUESTIONS_MODULE_KEY] as SecurityQuestionsModule).changeSecurityQuestionAndAnswer(
    password,
    PASSWORD_QUESTION
  );
  await localTKey.syncLocalMetadataTransitions();
  await calculateSettingsPageData();
};

export const addPassword = async (password: string): Promise<void> => {
  const localTKey = await getTkeyInstance();

  await (localTKey.modules[SECURITY_QUESTIONS_MODULE_KEY] as SecurityQuestionsModule).generateNewShareWithSecurityQuestions(
    password,
    PASSWORD_QUESTION
  );
  await localTKey.syncLocalMetadataTransitions();
  await calculateSettingsPageData();
  await updateUserPersistedInfo({ payload: { tkey_password: true, tkey_threshold: store.getState().olTKeyModule.keyDetails.totalShares } });
};

export const copyShareUsingIndexAndStoreLocally = async (index: BN): Promise<void> => {
  const localTKey = await getTkeyInstance();
  const outputshareStore = localTKey.outputShareStore(index);
  await (localTKey.modules[WEB_STORAGE_MODULE_KEY] as WebStorageModule).storeDeviceShare(outputshareStore);
  await calculateSettingsPageData({ forceCheckOnDeviceShare: true });
};

export const generateAndStoreNewDeviceShare = async (localTKey: TKeyDefault): Promise<void> => {
  const newShare = await localTKey.generateNewShare();
  const customDeviceInfo = getCustomDeviceInfo();
  await (localTKey.modules[WEB_STORAGE_MODULE_KEY] as WebStorageModule).storeDeviceShare(
    newShare.newShareStores[newShare.newShareIndex.toString("hex")],
    customDeviceInfo
  );

  try {
    await localTKey.syncLocalMetadataTransitions();
  } catch (error) {
    console.log("Error sync local metadata, data is too big");
  }
  store.dispatch(olTkeyModuleAction.updateState({ tKey: localTKey }));
  await calculateSettingsPageData({ forceCheckOnDeviceShare: false });
  const { aggregateVerifier: verifier, verifierId } = store.getState().olUserModule.userInfo;
  const { verifierIDDeviceIDMap, devicePersistedInfo } = store.getState().olDeviceModule;
  const existingDeviceId = verifier && verifierId ? verifierIDDeviceIDMap[getJoinedKey(verifier, verifierId)] : undefined;
  const deviceInfoPayload = {
    device_id: existingDeviceId,
    share_index: newShare.newShareIndex.toString("hex")
  };
  const finalDeviceInfoPayload = pickBy(deviceInfoPayload, (val) => val !== null && val !== undefined && val !== "");
  const infoAlreadyExist = isMatch(devicePersistedInfo[existingDeviceId || ""], finalDeviceInfoPayload);
  if (!infoAlreadyExist) {
    if (existingDeviceId) {
      await updateDeviceInfo(finalDeviceInfoPayload);
    }
  }
};

export const approveShareTransferRequest = async (encPubKeyX: string): Promise<void> => {
  try {
    const localTKey = await getTkeyInstance();
    await (localTKey.modules[SHARE_TRANSFER_MODULE_KEY] as ShareTransferModule).approveRequest(encPubKeyX);
    // await localTKey.syncShareMetadata();
  } catch (error) {
    log.error(error);
  } finally {
    startShareTransferRequestListener();
  }
};

export const denyShareTransferRequest = async (encPubKeyX: string): Promise<void> => {
  try {
    const localTKey = await getTkeyInstance();
    await (localTKey.modules[SHARE_TRANSFER_MODULE_KEY] as ShareTransferModule).deleteShareTransferStore(encPubKeyX);
  } catch (error) {
    log.error(error);
  } finally {
    startShareTransferRequestListener();
  }
};

export const listenForMaximumShares = async (clearAll = true): Promise<void> => {
  const { allDeviceShares } = store.getState().olTKeyModule.settingsPageData;
  const browserClearDevices = allDeviceShares
    .sort((a, b) => {
      if (a.rawDateAdded && b.rawDateAdded) {
        return a.rawDateAdded - b.rawDateAdded;
      }
      return -1;
    })
    .filter((x) => x.module !== "securityQuestions" && x.module !== "shareSerialization");

  if (clearAll) {
    const temp: any[] = new Array(browserClearDevices.length).fill("");
    const res: { success: boolean; index: string }[] = [];
    const allDeleteData: string[] = [];
    const wfDelete = new Promise((resolve) => {
      if (browserClearDevices.length >= 7) {
        const iterResp = temp.reduce(async (prev, curr, idx) => {
          if (idx >= 25) {
            await prev;
            try {
              allDeleteData.push(browserClearDevices[idx].index);
              res.push({
                index: browserClearDevices[idx].index,
                success: true
              });
              return res;
            } catch (error) {
              log.error(error);
              res.push({
                index: browserClearDevices[idx].index,
                success: false
              });
              return res;
            }
          } else {
            res.push({
              index: browserClearDevices[idx].index,
              success: true
            });
            return res;
          }
        }, Promise.resolve());
        resolve(iterResp);
      } else {
        resolve(null);
      }
    });
    await wfDelete;
    // this.deleteBulkShare(allDeleteData);
  }
};

export const deleteShare = async (shareIndex: string): Promise<void> => {
  await _init({ postboxKey: store.getState().olUserModule.keyInfo.privateKey, tKeyJson: undefined, shareStores: [] });
  try {
    const localTKey = await getTkeyInstance();
    // In rehydration we always have privKey
    await localTKey.reconstructKey();
    store.dispatch(olTkeyModuleAction.updateState({ tKey: localTKey } as any));
  } catch (error) {
    log.warn(error, "maybe reconstruct only in certain times");
  }
  const localTKey = await getTkeyInstance();
  window.performance.mark(`delete_start`);
  await localTKey.deleteShare(shareIndex);
  window.performance.mark(`delete_end`);
  window.performance.measure(`delete measurement`, `delete_start`, `delete_end`);
  window.performance.mark(`sync_metadata_start`);
  await localTKey.syncLocalMetadataTransitions();
  window.performance.mark(`sync_metadata_end`);
  window.performance.measure(`sync metadata measurement`, `sync_metadata_start`, `sync_metadata_end`);

  await calculateSettingsPageData({ forceCheckOnDeviceShare: true });
  const exportedSharesArr = Object.values(store.getState().olTKeyModule.settingsPageData.exportedEmailShares);
  await Promise.all([
    deleteDevice(shareIndex, ""),
    updateUserPersistedInfo({
      payload: {
        tkey_backup_emails: exportedSharesArr.length,
        tkey_threshold: store.getState().olTKeyModule.keyDetails.totalShares,
        backup_phrase_setup_at: exportedSharesArr.length ? exportedSharesArr[exportedSharesArr.length - 1].dateAdded : null,
        backup_phrase_setup_email: exportedSharesArr.length ? exportedSharesArr[exportedSharesArr.length - 1].email : null
      }
    })
  ]);
};

export const exportDeviceShare = async (): Promise<string> => {
  const localTKey = await getTkeyInstance();
  const deviceShare = store.getState().olTKeyModule.settingsPageData.deviceShare?.share?.share.share as any;
  if (!deviceShare) return "";
  const serializedShare = await (localTKey.modules[SHARE_SERIALIZATION_MODULE_KEY] as ShareSerializationModule).serialize(
    deviceShare,
    "mnemonic"
  );
  return serializedShare as string;
};

export const rehydrate = async (params: { postboxKey: string; tKeyJson: StringifiedType }): Promise<void> => {
  const { postboxKey, tKeyJson } = params;
  await _init({ postboxKey, tKeyJson, shareStores: [] });
  try {
    const localTKey = await getTkeyInstance();
    // In rehydration we always have privKey
    if (
      store.getState().olTKeyModule.tKeyPrivKey &&
      (store.getState().olTKeyModule.keyMode === "v1" || store.getState().olTKeyModule.keyMode === "2/n")
    ) {
      await localTKey.reconstructKey();
      store.dispatch(olTkeyModuleAction.updateState({ tKey: localTKey } as any));
      if (window.self === window.top) startShareTransferRequestListener();
    }
  } catch (error) {
    log.warn(error, "maybe reconstruct only in certain times");
  }
};

export const login = async (params: {
  postboxKey: string;
  shareStores?: ShareStore[];
  tKeyJson?: TKeyDefault;
  deviceShareStore?: ShareStore;
  dappShare?: string;
}): Promise<TKeyAccount[]> => {
  try {
    const { postboxKey, shareStores, tKeyJson, deviceShareStore, dappShare } = params;
    await _init({ postboxKey, tKeyJson, shareStores: shareStores || [], dappShare });
    const { keyDetails, tKey, parsedShareDescriptions } = store.getState().olTKeyModule;

    const { requiredShares: shareCount } = keyDetails;
    let requiredShares = shareCount;
    const descriptionBuffer = [];
    let currentIndex = 0;

    // window.addEventListener("beforeunload", beforeUnloadHandler);
    while (requiredShares > 0 && currentIndex < parsedShareDescriptions.length) {
      const currentShare = parsedShareDescriptions[currentIndex] as { module: string };
      currentIndex += 1;
      if (currentShare.module === WEB_STORAGE_MODULE_KEY) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await (tKey.modules[WEB_STORAGE_MODULE_KEY] as WebStorageModule).inputShareFromWebStorage();
          requiredShares -= 1;
        } catch (error: any) {
          if (error.message && String(error.message).includes("deleted")) {
            throw new Error(TKEY_REQUIRE_MORE_INPUT);
          }
          log.warn(error, "unable to read share from device. Must be on other device");
          descriptionBuffer.push(currentShare);
        }
      } else if (currentShare.module === SECURITY_QUESTIONS_MODULE_KEY) {
        descriptionBuffer.push(currentShare);
      } else if (currentShare.module === CHROME_EXTENSION_STORAGE_MODULE_KEY) {
        // transfer share from other device
        descriptionBuffer.push(currentShare);
      }
    }

    const hexKeys = await tryFinishTkeyReconstruction();
    // only call this if reconstruction passes
    if (deviceShareStore) {
      try {
        const metadata = tKey.getMetadata();
        const tkeypubx = metadata.pubKey?.x?.toString("hex");

        // Back this up to webauthn
        const finalShareStore = tKey.outputShareStore(deviceShareStore.share.shareIndex.toString("hex"));
        if (finalShareStore.polynomialID !== deviceShareStore.polynomialID) {
          await storeShareOnLocalStorage(finalShareStore, tkeypubx as string);
          await calculateSettingsPageData({ forceCheckOnDeviceShare: true });
        }
      } catch (error) {
        log.warn("share must already be deleted", error);
      }
    }
    return hexKeys;
  } finally {
    // window.removeEventListener("beforeunload", beforeUnloadHandler);
  }
};

export const tryFinishTkeyReconstruction = async (): Promise<TKeyAccount[]> => {
  await calculateSettingsPageData();

  const { keyDetails: newDetails } = store.getState().olTKeyModule;
  // Need input from UI
  if (newDetails.requiredShares > 0) {
    throw new Error(TKEY_REQUIRE_MORE_INPUT);
  } else {
    const { tKey: newTKey } = store.getState().olTKeyModule;
    const privKey = await newTKey.reconstructKey(false);
    await calculateSettingsPageData({ forceCheckOnDeviceShare: true });
    await cleanUpShareTransfer();
    startShareTransferRequestListener();

    const hexKeys = await getAllPrivateKeys(newTKey, privKey?.secp256k1Key);
    if (hexKeys.length > 0) {
      store.dispatch(olTkeyModuleAction.updateState({ tKeyPrivKey: hexKeys[0].privKey } as any));
      const { walletKeyInfo } = store.getState().olUserModule;
      const userTkeyInfo: UpdateUserPayload = {
        tkey_public_address: privateToAddress(Buffer.from(store.getState().olTKeyModule.tKeyPrivKey.padStart(64, "0"), "hex")).toString(
          "hex"
        ),
        tkey_backup_emails: Object.values(store.getState().olTKeyModule.settingsPageData.exportedEmailShares).length,
        tkey_threshold: store.getState().olTKeyModule.keyDetails.totalShares,
        tkey_password: store.getState().olTKeyModule.settingsPageData.passwordShare.available || null,
        wallet_public_address: walletKeyInfo.publicAddress
      };
      await updateUserPersistedInfo({ payload: userTkeyInfo });
    }
    return hexKeys;
  }
};
