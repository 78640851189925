// @ts-nocheck
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { IonAvatar, IonItem, IonLabel, IonText } from "@ionic/react";
import useHistoryImage from "hooks/useHistoryImage";
import { IDisplayHistory } from "interfaces/libs/IAccountHistory";
import { IPropsItemHistory } from "interfaces/ui/IMenu";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { numberParse } from "shared/utils/etherUtils";

const MoralisItemHistory = ({ handlePresentMoralis, item, account }: IPropsItemHistory) => {
  const { t } = useTranslation();
  const [displayHistory, setDisplayHistory] = useState<IDisplayHistory>({
    from: "",
    to: "",
    value: "",
    images: "",
    isNfts: false,
    caption: "",
    isPlus: false,
    symbol: "",
    nativeSymbol: ""
  });
  const { ImageData } = useHistoryImage({ displayHistory: displayHistory, moralisItemResult: item });
  useEffect(() => {
    if (item.contract_type === "ERC20" || item.contract_type === "NATIVE") {
      setDisplayHistory({
        from: item?.from_address === account ? item?.to_address : item?.from_address,
        to: item?.to_address,
        value: numberParse(item?.value, item?.contract_decimal),
        isNfts: false,
        images: item?.logo_url,
        caption: item?.from_address !== account ? t("account.receiveFrom") : t("account.sentTo"),
        isPlus: item?.from_address !== account ? true : false,
        symbol: item?.custom_name, //kalo erc20 symbol dari erc20nya
        nativeSymbol: item?.custom_name
      });
    } else {
      setDisplayHistory({
        from: item?.from_address === account ? item?.to_address : item?.from_address,
        to: item?.to_address,
        value: "",
        isNfts: true,
        images: item?.nftImage,
        caption: item?.from_address !== account ? t("account.receiveFrom") : t("account.sentTo"),
        isPlus: item?.from_address === account ? false : true,
        symbol: item?.nftName,
        nativeSymbol: ""
      });
    }
  }, [item]);

  return (
    <div onClick={handlePresentMoralis} className="mt-4">
      <IonText className="text-gray-400">{item?.block_timestamp.toString()}</IonText>
      <IonItem className="p-0">
        <IonAvatar slot="start">
          <ImageData />
        </IonAvatar>
        <IonLabel>
          <div className="!text-base">
            <>
              {localStorage.getItem("lang") === "en" ? (
                <>
                  {item?.from_address === "0x0000000000000000000000000000000000000000" ? (
                    <>{t("account.receiveFrom")}&nbsp; </>
                  ) : (
                    <>{displayHistory?.caption}&nbsp;</>
                  )}
                  <span>
                    {!displayHistory?.isNfts ? (
                      <strong>{displayHistory.from.substring(0, 10) + "..."}</strong>
                    ) : (
                      <strong>
                        {displayHistory?.from.toLocaleLowerCase() === account?.toLocaleLowerCase()
                          ? displayHistory?.to.substring(0, 10) + "..."
                          : displayHistory?.from.substring(0, 10) + "..."}
                      </strong>
                    )}
                  </span>
                </>
              ) : (
                <>
                  <span>
                    {!displayHistory?.isNfts ? (
                      <strong>{displayHistory?.from.substring(0, 10) + "..."}</strong>
                    ) : (
                      <strong>
                        {displayHistory?.from.toLocaleLowerCase() === account.toLocaleLowerCase()
                          ? displayHistory?.to.substring(0, 10) + "..."
                          : displayHistory?.from.substring(0, 10) + "..."}
                      </strong>
                    )}
                  </span>
                  {item?.from_address === "0x0000000000000000000000000000000000000000" ? (
                    <>&nbsp;{t("account.receiveFrom")} </>
                  ) : (
                    <>&nbsp; {displayHistory?.caption}</>
                  )}
                </>
              )}
            </>
          </div>
          <strong className="text-base">{displayHistory?.symbol}</strong>
        </IonLabel>
        <IonLabel slot="end" className="mb-0 text-base">
          <>{!displayHistory.isNfts && <>{displayHistory?.isPlus ? " + " : " - "}</>}</>
          {displayHistory?.value} {displayHistory?.nativeSymbol}
        </IonLabel>
      </IonItem>
    </div>
  );
};

export default MoralisItemHistory;
