// @ts-nocheck
/* eslint-disable prefer-const */
import { IonImg, IonSegment, IonSegmentButton } from "@ionic/react";
import { ArrowForwardIos } from "@mui/icons-material";
import { Avatar, Stack } from "@mui/material";
import erc20Abi from "abis/erc20.json";
import BrokenNFTImage from "assets/icons/Broken-nft.svg";
import BrokenTokenImage from "assets/icons/BrokenImg.svg";
import { ethers } from "ethers";
import { truncateAddress } from "helpers/stringHelper";
import { useAppSelector } from "hooks/useRedux";
import useWeb3 from "hooks/useWeb3";
import { t } from "i18next";
import { MethodParams } from "interfaces/ui/IConfirm";
import torus from "libs/TorusExtended";
import { useEffect, useState } from "react";
import { NetworkType } from "shared/actions/walletAction";
import { SUPPORTED_NETWORK_TYPES, TRANSACTION_TYPES } from "shared/enums";
import { store } from "shared/store";
import { hexWEIToDecGWEI } from "shared/utils/conversionUtils";
import { estimateGweiPrice } from "shared/utils/etherUtils";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { ChangeNetworkPayload, ConsentPayload, EthSignProps, PopupHandlerProps, PopupProps, TypeRelayData } from "../../types";
import { popupHandlerFrom } from "features/embedPopup/types/popupHandlerEnum";

const getColor = () => {
  const { embedTheme } = store.getState().embedState;

  const modalcolor = embedTheme?.modalColor === "#fffff";
  const bgcolor = embedTheme?.bgColor === "#4B68AE";
  const bgcolorhover = embedTheme?.bgColorHover === "#214999";
  const textcolor = embedTheme?.textColor === "#f3f3f3";
  const textcolorhover = embedTheme?.textColorHover === "#214999";

  if (modalcolor && bgcolor && bgcolorhover && textcolor && textcolorhover) {
    // this condition is for checking the old default primary color then replaced by the new one
    return {
      modalColor: "#ffffff",
      bg: "#4B68AE",
      bgHover: "#214999",
      text: "#f3f3f3",
      textColorHover: "#ffffff"
    };
  } else {
    return {
      modalColor: embedTheme?.modalColor || "#fafafa",
      bg: embedTheme?.bgColor || "#4B68AE",
      bgHover: embedTheme?.bgColorHover || "#224999",
      text: embedTheme?.textColor || "#f3f3f3",
      textColorHover: embedTheme?.textColorHover || "#f3f3f3"
    };
  }
};

const EthSignPopup = (props: EthSignProps): JSX.Element => {
  const { hostname, message, triggerDeny, thewayTriggerSign } = props;
  return (
    <div
      className="scrollbar-hide flex flex-col w-full p-6.25 -mt-5 h-screen overflow-y-scroll relative pb-25"
      style={{ backgroundColor: getColor().modalColor }}>
      <h1 className="text-base leading-4.75 font-bold mt-5 mb-2.5 font-roboto">{t("sign_req_modal.title")}</h1>
      <span className="border border-solid border-E4E4E4 flex-none order-none self-stretch grow-0"></span>
      <h1 className="mt-5 mb-2.5 font-roboto font-bold text-base leading-4.75">{t("notification.requestFrom")}:</h1>
      <span className="w-full max-w-xs py-2.5 px-2 rounded-lg text-upbondBlue font-bold leading-4.75" style={{ color: getColor().bg }}>
        {hostname || "Loading ..."}
      </span>
      <h1 className="mt-5 mb-2.5 font-roboto font-bold text-base leading-4.75">{t("notification.details")}</h1>
      <span className="font-roboto leading-4.75 w-full max-w-xs py-2.5 px-2 rounded-lg text-3D3D3D">{message || "Loading ..."}</span>
      <br />
      {/* <div className="button-wrapper">
        <button style={{ backgroundColor: getColor().text, color: getColor().bg }} onClick={() => triggerDeny(2)}>
          <p>{t("notification.reject")}</p>
        </button>
        <button style={{ marginLeft: "12px", backgroundColor: getColor().bg, color: getColor().text }} onClick={() => thewayTriggerSign()}>
          {t("notification.confirm")}
        </button>
      </div> */}
      <PopupHandler triggerDeny={triggerDeny} thewayTriggerSign={thewayTriggerSign} isInsufficient={false} from={popupHandlerFrom.eth} />
    </div>
  );
};

const PopupHeader = ({
  hostname,
  children,
  to,
  isPersonalSign,
  transactionType,
  tokenName,
  tokenAddr,
  gsnType
}: {
  hostname: string;
  children?: JSX.Element;
  to?: string;
  isPersonalSign?: boolean;
  transactionType?: {
    type: string;
    category: string;
  };
  tokenName?: string;
  tokenAddr?: string;
  gsnType?: string;
}) => {
  const approvalTerms = "By granting permission, you are allowing the following contract to access your funds";
  return (
    <div className="w-full mt-10">
      {transactionType && transactionType.category === TRANSACTION_TYPES.TOKEN_METHOD_APPROVE ? (
        <h1 className="font-roboto not-italic font-semibold text-base text-0A0A0A mt-5 mb-2.5 leading-4.75">{t("sign_req_modal.title")}</h1>
      ) : gsnType ? (
        <h1 className="font-roboto not-italic font-semibold text-base text-0A0A0A mt-5 mb-2.5 leading-4.75">
          {isPersonalSign ? t("sign_personal_modal.title") : t("sign_req_modal.title")}
        </h1>
      ) : (
        <h1 className="font-roboto not-italic font-semibold text-base text-0A0A0A mt-5 mb-2.5 leading-4.75">
          {t("sign_trans_modal.title")}
        </h1>
      )}
      <span className="text-left flex justify-between w-full max-w-xs p-0 rounded-lg text-upbondBlue font-bold">
        <h1 className="w-full font-roboto not-italic font-normal text-base text-0A0A0A mt-5 mb-2.5 leading-4.75">
          {t("notification.requestFrom")}
        </h1>
        <h2
          className="font-roboto not-italic font-semibold text-base text-black mt-4.5 mb-2.5 leading-4.75"
          style={{ color: getColor().bg }}>
          {hostname}
        </h2>
      </span>
      {transactionType && transactionType.category === TRANSACTION_TYPES.TOKEN_METHOD_APPROVE ? (
        <h1 className="font-roboto not-italic font-semibold text-base text-0A0A0A mt-5 mb-2.5 leading-4.75">{`Do you want to approve your ${tokenName} ?`}</h1>
      ) : to || gsnType ? (
        <span className="flex justify-between w-full">
          <h2 className="font-roboto not-italic font-semibold text-base text-black mt-4.5 mb-2.5 leading-4.75">
            {isPersonalSign ? t("notification.requesterAddress") : gsnType ? t("notification.typeVersion") : t("notification.transferTo")}:
            {gsnType ? gsnType : truncateAddress(to || "") || t("notification.noData")}
          </h2>
        </span>
      ) : null}
      {transactionType && transactionType.category === TRANSACTION_TYPES.TOKEN_METHOD_APPROVE && <p>{approvalTerms}</p>}
      {tokenAddr && transactionType && transactionType.category === TRANSACTION_TYPES.TOKEN_METHOD_APPROVE && (
        <Stack direction={"column"} spacing={1} alignItems="center" justifyItems={"center"}>
          <Avatar>{tokenName}</Avatar>
          <p>{truncateAddress(tokenAddr)}</p>
        </Stack>
      )}
      {tokenAddr && <div className="mt-2"></div>}
      {children}
      <div className="mt-2 border border-solid border-E4E4E4"></div>
    </div>
  );
};

const PopupHandler = (props: PopupHandlerProps) => {
  const { triggerDeny, thewayTriggerSign, from } = props;
  const [hover, sethover] = useState("");

  const fromClassReject = {
    eth: "text-upbondBlue bg-E4E4E4 flex justify-center items-center p-2 gap-1 w-38 h-9.5 rounded-[100px] font-bold text-sm",
    other: "text-upbondBlue bg-E4E4E4 flex justify-center items-center py-2 px-0 w-full h-9.5 rounded-[100px] font-bold text-sm"
  };

  const fromClassButton = {
    eth: "flex justify-center items-center p-2 gap-1 w-38 h-9.5 bg-upbondBlue rounded-[100px] font-bold text-white text-sm font-roboto ml-3",
    other:
      "flex justify-center items-center py-2 px-0 w-full h-9.5 bg-upbondBlue rounded-[100px] font-bold text-white text-sm font-roboto ml-3"
  };

  return (
    <div
      className={`flex flex-row ${from === popupHandlerFrom.eth ? "mt-3" : ""} justify-between fixed w-full bottom-0 left-0 p-5 pb-12.5`}
      style={{ display: "flex", justifyContent: "space-between", backgroundColor: getColor().modalColor }}>
      <button
        className={`${from === popupHandlerFrom.eth ? fromClassReject[from] : fromClassReject.other} `}
        onClick={() => triggerDeny(2)}
        style={{ backgroundColor: getColor().text, color: getColor().bg }}>
        <p className="font-roboto">{t("notification.reject")}</p>
      </button>
      <button
        onMouseEnter={() => sethover("confirm")}
        onMouseLeave={() => sethover("")}
        onClick={() => thewayTriggerSign(props.dynamicParams)}
        disabled={props.isInsufficient}
        className={`${from === popupHandlerFrom.eth ? fromClassButton[from] : fromClassButton.other}`}
        style={{
          backgroundColor: hover === "confirm" ? getColor().bgHover : getColor().bg,
          color: hover === "confirm" ? getColor().textColorHover : getColor().text,
          opacity: props.isInsufficient ? 0.6 : 1
        }}>
        {t("notification.confirm")}
      </button>
    </div>
  );
};

const isJson = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch {
    return false;
  }
};

/**
 * di comment sementara, takutnya kepake
 */
// const DataConsentPopoup = (props: PopupProps) => {
//   const { consentConfigurations } = useAppSelector((state) => state.wallet);
//   const { message, triggerDeny, thewayTriggerSign } = props;
//   const embedState = useAppSelector((state) => state.embedState);
//   const [consentData, setConsentData] = useState<any>({});
//   const [popupState, setPopupState] = useState({
//     data: {
//       vc: "",
//       vp: ""
//     },
//     domain: "",
//     expires_in: "",
//     msg: "",
//     scope: [""],
//     type: ""
//   });

//   const isEnglish =
//     (embedState.embedTheme && embedState.embedTheme.lang && embedState.embedTheme?.lang === "en") ||
//     embedState.embedTheme?.lang === "en-US";

//   useEffect(() => {
//     if (message && isJson(message)) {
//       const msgJson = JSON.parse(message);
//       if (isEnglish) {
//         setPopupState(msgJson);
//       } else {
//         const scope = msgJson.scope;

//         // Commented dulu, karna ribet
//         // let translatedScope: string[] = [];
//         // scope.forEach((scopeName: string) => {
//         //   if (scopeName === "email") {
//         //     translatedScope.push("メールアドレス");
//         //   } else if (scopeName === "birthday") {
//         //     translatedScope.push("誕生日");
//         //   } else if (scopeName === "name") {
//         //     translatedScope.push("名前");
//         //   }
//         // });

//         setPopupState({ ...msgJson, scope });
//       }
//     } else if (message) {
//       setPopupState(message as any);
//     }
//   }, [message]);

//   const getLogo = () => {
//     if (embedState.embedTheme && embedState.embedTheme.isActive && embedState.embedTheme.logo && embedState.embedTheme.logo !== "") {
//       return embedState.embedTheme.logo;
//     }
//     return Logo;
//   };

//   const setInputData = (e: any, name: string) => {
//     let newData = consentData;
//     newData[name] = e.target.value;
//     setConsentData(newData);
//   };

//   const submitDidCreation = async () => {
//     thewayTriggerSign(consentData);
//   };

//   return (
//     <div className="data-consent-popup scrollbar-hide">
//       {/* <h1 className="popup-consent-title">App Data Permission</h1> */}
//       <div className="dapps-logos">
//         <img src={getLogo()} alt={popupState && popupState.domain} />
//       </div>
//       <h1>{t("consentsForm.consentPopupMessage")}</h1>
//       {popupState &&
//         popupState.scope &&
//         popupState.scope.map((scopeName) => {
//           const isRequired = consentConfigurations.requiredScope.includes(scopeName);
//           return (
//             <span key={scopeName}>
//               <span className="separator-line"></span>
//               <div className="scope-section">
//                 <div className="scope-wrapper">
//                   <h2>{scopeName && `${scopeName[0].toUpperCase()}${scopeName.slice(1, scopeName.length)}`}</h2>
//                   {isRequired && (
//                     <div className="required-scope">
//                       <h2>{t("consentsForm.required")}</h2>
//                     </div>
//                   )}
//                 </div>
//                 <IonCheckbox name={scopeName} checked={isRequired} disabled={isRequired} />
//               </div>
//               <input
//                 required={isRequired}
//                 onChange={(e) => {
//                   setInputData(e, scopeName);
//                 }}
//                 type="text"
//                 className="scopes-input"
//                 placeholder={`${isEnglish ? "input" : ""}${scopeName}`}
//               />
//             </span>
//           );
//         })}
//       <div className="consent-confirmation-button">
//         <button className="agree-consent" onClick={submitDidCreation}>
//           {t("consentsForm.agreeAndLogin")}
//         </button>
//         <button className="cancel-consent" onClick={() => triggerDeny(2)}>
//           {t("consentsForm.cancel")}
//         </button>
//       </div>
//     </div>
//   );
// };

const PersonalSignPopup = (props: PopupProps) => {
  const { hostname, message, triggerDeny, thewayTriggerSign, data } = props;
  const _data = truncateAddress(data?.msgParams?.msgParams.data as string);
  const [msg, setMsg] = useState<string>("");
  const [isDidCreationRequest, setIsDidCreationRequest] = useState(false);
  const [consentJson, setConsentJson] = useState<ConsentPayload | null>(null);

  useEffect(() => {
    if (message && isJson(message)) {
      const msgJson = JSON.parse(message);
      setMsg(msgJson.msg.toString());
      setConsentJson(msgJson);
    } else if (message) {
      setMsg(message);
    }
  }, [message]);

  useEffect(() => {
    if (consentJson) {
      if (consentJson.type === "did_creation_request") {
        setIsDidCreationRequest(true);
      }
    }
  }, [consentJson]);

  return isDidCreationRequest ? (
    /*<DataConsentPopoup {...props} /> */
    <div></div>
  ) : (
    <div
      className="flex p-5 flex-col w-full -mt-10 h-screen overflow-y-auto relative pb-25"
      style={{ backgroundColor: getColor().modalColor }}>
      <PopupHeader hostname={consentJson ? consentJson.domain : hostname} to={_data} isPersonalSign />
      <p className="font-roboto not-italic font-normal text-base text-0A0A0A my-4 leading-4.75">
        {consentJson ? `${consentJson.domain} wants to access your data` : msg}
      </p>
      {consentJson &&
        consentJson.scope.map((x) => (
          <p className="font-roboto not-italic font-normal text-base text-0A0A0A my-4 mt-0.5 leading-4.75" key={x}>
            - your {x}
          </p>
        ))}
      <PopupHandler
        triggerDeny={triggerDeny}
        thewayTriggerSign={thewayTriggerSign}
        isInsufficient={false}
        from={popupHandlerFrom.personal}
      />
    </div>
  );
};

const DataSignPopup = (props: PopupProps) => {
  const [typedData, setTypedData] = useState<{
    data: string;
    from: string;
    origin: string;
    typedMessages: string;
    version: string;
    id: number;
  }>({
    data: "",
    from: "",
    origin: "",
    typedMessages: "",
    version: "V4",
    id: 0
  });
  const [typedRelayData, setTypedRelayData] = useState<TypeRelayData | undefined>();
  const [rendererData, setRendererData] = useState<
    | {
        id: number;
        title: string;
        data: string;
      }[]
    | []
  >([]);
  const [rendererRelayData, setRendererRelayData] = useState<
    | {
        id: number;
        title: string;
        data: string;
      }[]
    | []
  >([]);
  const [renderType, setRenderType] = useState("message");

  const { hostname, triggerDeny, thewayTriggerSign, data } = props;

  useEffect(() => {
    if (data) {
      setTypedData({
        id: data.msgParams?.id as number,
        data: data.msgParams?.msgParams.data as string,
        from: data.msgParams?.msgParams.from as string,
        origin: data.msgParams?.msgParams.origin as string,
        typedMessages: data.msgParams?.msgParams.typedMessages as string,
        version: (data.msgParams?.msgParams as { version: string }).version as string
      });

      if (data.msgParams?.msgParams.data) {
        try {
          const deepParsing = (): TypeRelayData => JSON.parse(data.msgParams?.msgParams.data as string);
          setTypedRelayData(deepParsing());
        } catch (error) {
          console.warn(error);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (typedRelayData && Object.values(typedRelayData).length > 0) {
      const arr: {
        id: number;
        title: string;
        data: string;
      }[] = [];
      const arrRelayData: {
        id: number;
        title: string;
        data: string;
      }[] = [];

      let index = 0;
      let relayDataIndex = 0;
      for (const property in typedRelayData.message) {
        if (property !== "relayData") {
          const o: {
            id: number;
            title: string;
            data: string;
          } = {
            id: 0,
            data: "",
            title: ""
          };
          const isAddress = Web3.utils.isAddress((typedRelayData.message as any)[property as string]);
          o.data = isAddress
            ? truncateAddress((typedRelayData.message as any)[property as string])
            : (typedRelayData.message as any)[property as string];
          o.title = property;
          o.id = index;
          arr.push(o);
          index++;
        }
      }
      for (const property in typedRelayData.message.relayData) {
        const o: {
          id: number;
          title: string;
          data: string;
        } = {
          id: 0,
          data: "",
          title: ""
        };
        const isAddress = Web3.utils.isAddress((typedRelayData.message.relayData as any)[property as string]);
        o.data = isAddress
          ? truncateAddress((typedRelayData.message.relayData as any)[property as string])
          : (typedRelayData.message.relayData as any)[property as string];
        o.title = property;
        o.id = relayDataIndex;
        arrRelayData.push(o);
        relayDataIndex++;
      }
      setRendererRelayData(arrRelayData);
      setRendererData(arr);
    }
  }, [typedRelayData]);

  const onClickSegment = (e: string) => {
    setRenderType(e);
  };

  return (
    <div className="flex p-5 flex-col w-full -mt-10 h-screen overflow-y-scroll relative pb-25">
      <PopupHeader hostname={hostname} gsnType={typedData.version} />
      <div>
        <IonSegment value={renderType} className="my-2.5">
          <IonSegmentButton value="message" onClick={() => onClickSegment("message")}>
            {t("notification.message")}
          </IonSegmentButton>
          <IonSegmentButton value="relay-data" onClick={() => onClickSegment("relay-data")}>
            {t("notification.relayMsg")}
          </IonSegmentButton>
        </IonSegment>
        <div className="bg-F3F3F3 w-full overflow-scroll h-50 flex flex-col rounded-lg py-1.25">
          {renderType === "message"
            ? rendererData.length > 0 &&
              rendererData.map((x) => (
                <div key={x.id}>
                  <p className="font-bold">{x.title}: </p>
                  <p className="mt-0">{typeof x.data === "string" ? x.data : JSON.stringify(x.data)}</p>
                  <div className="border border-solid border-E4E4E4 flex-none order-none self-stretch grow-0"></div>
                </div>
              ))
            : rendererRelayData.length > 0 &&
              rendererRelayData.map((x) => (
                <div key={x.id}>
                  <p className="mb-px font-bold">{x.title}: </p>
                  <p className="mt-0">{typeof x.data === "string" ? x.data : JSON.stringify(x.data)}</p>
                  <div className="border border-solid border-E4E4E4 flex-none order-none self-stretch grow-0"></div>
                </div>
              ))}
        </div>
      </div>
      <br />
      <PopupHandler triggerDeny={triggerDeny} thewayTriggerSign={thewayTriggerSign} isInsufficient={false} from={popupHandlerFrom.data} />
    </div>
  );
};

const SignTxPopup = (props: PopupProps) => {
  const [renderState, setRenderState] = useState({
    network: {
      chainId: 0,
      host: "",
      networkName: ""
    },
    origin: {
      hostname: "",
      href: ""
    },
    txParams: {
      txDetails: {
        data: "",
        from: "Loading ...",
        to: "Loading ...",
        gas: "Loading ...",
        maxFeePerGas: "Loading ...",
        maxPriorityFeePerGas: "Loading ...",
        value: "Loading ...",
        chainId: 0,
        tokenImg: window.location.origin + "/assets/noimg.png",
        tokenName: "Loading ...",
        totalTransaction: "Loading ..."
      }
    }
  });
  const [tokenInformation, setTokenInformation] = useState<{
    name: string;
    myBalance: string;
    symbol: string;
    decimals: number;
    address: string;
    txValue?: string;
    txTo?: string;
  }>({
    name: "Loading..",
    myBalance: "0",
    symbol: "Loading..",
    decimals: 18,
    address: "0x",
    // for the transfer methods
    txValue: undefined,
    txTo: undefined
  });
  const [isInsufficient, setIsInsufficient] = useState(false);
  const [transactionType, setTransactionType] = useState({
    type: "",
    category: ""
  });
  const [methodParams, setMethodParams] = useState<{
    spender: string;
    value: string;
  }>({
    spender: "",
    value: ""
  });
  const { selectedAddress } = useAppSelector((state) => state.wallet);
  const { hostname, triggerDeny, thewayTriggerSign } = props;
  const { web3 } = useWeb3();

  function truncateAllowance(str: string, n: number) {
    return str.length > n ? str.slice(0, n - 1) + `+${str.length}` : str;
  }

  useEffect(() => {
    let _web3: Web3;
    if (web3) {
      _web3 = web3;
    } else {
      if (torus.web3) {
        _web3 = torus.web3;
      } else {
        throw new Error(`No web3 provider on your dapps`);
      }
    }
    const getErcInformation = async (contractAddr: string) => {
      const erc20Contract = new _web3.eth.Contract(erc20Abi.abi as AbiItem[], contractAddr);
      const name = await erc20Contract.methods.name().call();
      const myBalance = await erc20Contract.methods.balanceOf(selectedAddress).call();
      const symbol = await erc20Contract.methods.symbol().call();
      const decimals = await erc20Contract.methods.decimals().call();

      setTokenInformation((curr) => ({
        ...curr,
        address: contractAddr,
        decimals: parseInt(decimals),
        myBalance: ethers.utils.formatUnits(myBalance.toString(), parseInt(decimals)),
        symbol: symbol as string,
        name: name as string
      }));
    };

    const checkInsufficient = async (totalAmount: bigint) => {
      try {
        if (web3) {
          const _usrBalance = await web3.eth.getBalance(selectedAddress);
          if (BigInt(_usrBalance) > totalAmount) {
            setIsInsufficient(false);
          } else {
            setIsInsufficient(true);
          }
        }
      } catch (error) {
        console.warn(error);
        setIsInsufficient(false);
      }
    };

    const getErc20TxInformation = async (params: MethodParams[]) => {
      try {
        const [to, value] = params;
        const parsedValue = ethers.utils.formatUnits(value.value, tokenInformation.decimals.toString());

        setTokenInformation((current) => ({
          ...current,
          txTo: to.value,
          txValue: parsedValue
        }));
      } catch (error) {
        console.warn(error);
        setIsInsufficient(false);
      }
    };

    if (props.data) {
      const { data } = props;
      setRenderState({
        network: {
          chainId: data.network.chainId,
          host: data.network.host,
          networkName: data.network.networkName
        },
        origin: {
          hostname: data.origin.hostname,
          href: data.origin.href
        },
        txParams: {
          ...data.txParams,
          txDetails: {
            ...renderState.txParams.txDetails,
            chainId: data.txParams?.chainId as number
          }
        }
      });

      if (data.txParams?.transactionCategory) {
        const {
          txParams: { transactionCategory, txParams, contractParams, methodParams }
        } = data;
        if (transactionCategory === "sentEther") {
          // TODO: transaction is sent ether
          // get coinImg
          const filteredChain = Object.values(SUPPORTED_NETWORK_TYPES).filter((val) => {
            return parseInt(val.chainId.toString()) === (data.txParams?.chainId as number);
          });

          const tokenImg = filteredChain[0].logo;
          const tokenTicker = filteredChain[0].ticker;

          const calculateFee = parseFloat(hexWEIToDecGWEI(txParams.maxFeePerGas).toString());
          const maxFeePerGas = calculateFee < 7 ? 7 : calculateFee;
          const newGas: number = parseInt(txParams.gas) * parseInt(maxFeePerGas.toString());
          const gweiGas = ethers.utils.parseUnits(newGas.toString(), "gwei");
          const totalAmount = BigInt(txParams.value) + BigInt(gweiGas.toString());
          checkInsufficient(totalAmount);

          setRenderState((curr) => ({
            ...curr,
            txParams: {
              ...curr.txParams,
              txDetails: {
                ...curr.txParams.txDetails,
                tokenName: tokenTicker,
                tokenImg: tokenImg,
                from: truncateAddress(txParams.from),
                gas: estimateGweiPrice(parseInt(txParams.gas), parseInt(hexWEIToDecGWEI(txParams.maxFeePerGas).toString())),
                maxFeePerGas: hexWEIToDecGWEI(txParams.maxFeePerGas).toString(),
                maxPriorityFeePerGas: hexWEIToDecGWEI(txParams.maxFeePerGas).toString(),
                to: truncateAddress(txParams.to),
                value: ethers.utils.formatEther(BigInt(txParams.value).toString()).toString(),
                totalTransaction: ethers.utils.formatEther(totalAmount.toString()).toString()
              }
            }
          }));
        } else {
          let ercType = "erc20";
          if (contractParams) {
            if (contractParams.erc20) ercType = "erc20";
            if (contractParams.erc1155) ercType = "erc1155";
            if (contractParams.erc721) ercType = "erc721";
          }

          if (ercType === "erc20") {
            setTransactionType({
              category: transactionCategory,
              type: ercType
            });

            if (transactionCategory === TRANSACTION_TYPES.TOKEN_METHOD_APPROVE) {
              getErcInformation(txParams.to);
            }

            if (transactionCategory === TRANSACTION_TYPES.TOKEN_METHOD_TRANSFER) {
              getErcInformation(txParams.to);
              getErc20TxInformation(methodParams);
            }

            const filteredChain = Object.values(SUPPORTED_NETWORK_TYPES).filter((val) => {
              return parseInt(val.chainId.toString()) === (data.txParams?.chainId as number);
            });

            const tokenImg = filteredChain[0].logo;
            const tokenTicker = filteredChain[0].ticker;
            const calculateFee = parseFloat(hexWEIToDecGWEI(txParams.maxFeePerGas).toString());
            const maxFeePerGas = calculateFee < 7 ? 7 : calculateFee;
            const newGas: number = parseInt(txParams.gas) * parseInt(maxFeePerGas.toString());
            const gweiGas = ethers.utils.parseUnits(newGas.toString(), "gwei");
            const totalAmount = BigInt(txParams.value) + BigInt(gweiGas.toString());
            checkInsufficient(totalAmount);
            if (transactionCategory === "approve" && methodParams) {
              if (Array.isArray(methodParams)) {
                setMethodParams({
                  spender: methodParams[0].value,
                  value: ethers.utils.formatUnits(methodParams[1].value, tokenInformation.decimals)
                });
              }
            }

            setRenderState((curr) => ({
              ...curr,
              txParams: {
                ...curr.txParams,
                txDetails: {
                  ...curr.txParams.txDetails,
                  data: txParams.data as string,
                  tokenName: tokenTicker,
                  tokenImg: tokenImg,
                  from: truncateAddress(txParams.from),
                  gas: estimateGweiPrice(parseInt(txParams.gas), parseInt(maxFeePerGas.toString())),
                  maxFeePerGas: maxFeePerGas.toString(),
                  maxPriorityFeePerGas: hexWEIToDecGWEI(txParams.maxPriorityFeePerGas).toString(),
                  to: truncateAddress(txParams.to),
                  value: ethers.utils.formatEther(BigInt(txParams.value).toString()).toString(),
                  totalTransaction: ethers.utils.formatEther(totalAmount.toString()).toString()
                }
              }
            }));
          } else if (ercType === "erc1155") {
            setTransactionType({
              category: transactionCategory,
              type: ercType
            });

            setTokenInformation((curr) => ({
              ...curr,
              address: txParams.to,
              decimals: contractParams.decimals,
              myBalance: "1",
              symbol: contractParams.symbol ?? "NFT",
              name: contractParams.name ?? "NFT",
              txTo: txParams.to,
              txValue: methodParams[3].value
            }));

            const filteredChain = Object.values(SUPPORTED_NETWORK_TYPES).filter((val) => {
              return parseInt(val.chainId.toString()) === (data.txParams?.chainId as number);
            });
            const tokenImg = filteredChain[0].logo;
            const tokenTicker = filteredChain[0].ticker;
            const calculateFee = parseFloat(hexWEIToDecGWEI(txParams.maxFeePerGas).toString());
            const maxFeePerGas = calculateFee < 7 ? 7 : calculateFee;
            const newGas: number = parseInt(txParams.gas) * parseInt(maxFeePerGas.toString());
            const gweiGas = ethers.utils.parseUnits(newGas.toString(), "gwei");
            const totalAmount = BigInt(txParams.value) + BigInt(gweiGas.toString());
            checkInsufficient(totalAmount);

            if (transactionCategory === "approve" && methodParams) {
              if (Array.isArray(methodParams)) {
                setMethodParams({
                  spender: methodParams[0].value,
                  value: ethers.utils.formatUnits(methodParams[1].value, tokenInformation.decimals)
                });
              }
            }

            setRenderState((curr) => ({
              ...curr,
              txParams: {
                ...curr.txParams,
                txDetails: {
                  ...curr.txParams.txDetails,
                  data: txParams.data as string,
                  tokenName: tokenTicker,
                  tokenImg: tokenImg,
                  from: truncateAddress(txParams.from),
                  gas: estimateGweiPrice(parseInt(txParams.gas), parseInt(hexWEIToDecGWEI(txParams.maxFeePerGas).toString())),
                  maxFeePerGas: hexWEIToDecGWEI(txParams.maxFeePerGas).toString(),
                  maxPriorityFeePerGas: hexWEIToDecGWEI(txParams.maxPriorityFeePerGas).toString(),
                  to: truncateAddress(txParams.to),
                  value: ethers.utils.formatEther(BigInt(txParams.value).toString()).toString(),
                  totalTransaction: ethers.utils.formatEther(totalAmount.toString()).toString()
                }
              }
            }));
          } else if (ercType === "erc721") {
            const filteredChain = Object.values(SUPPORTED_NETWORK_TYPES).filter((val) => {
              return parseInt(val.chainId.toString()) === (data.txParams?.chainId as number);
            });
            const tokenImg = filteredChain[0].logo;
            const tokenTicker = filteredChain[0].ticker;
            const calculateFee = parseFloat(hexWEIToDecGWEI(txParams.maxFeePerGas).toString());
            const maxFeePerGas = calculateFee < 7 ? 7 : calculateFee;
            const newGas: number = parseInt(txParams.gas) * parseInt(maxFeePerGas.toString());
            const gweiGas = ethers.utils.parseUnits(newGas.toString(), "gwei");
            const totalAmount = BigInt(txParams.value) + BigInt(gweiGas.toString());
            checkInsufficient(totalAmount);

            setRenderState((curr) => ({
              ...curr,
              txParams: {
                ...curr.txParams,
                txDetails: {
                  ...curr.txParams.txDetails,
                  data: txParams.data as string,
                  tokenName: tokenTicker,
                  tokenImg: tokenImg,
                  from: truncateAddress(txParams.from),
                  gas: estimateGweiPrice(parseInt(txParams.gas), parseInt(hexWEIToDecGWEI(txParams.maxFeePerGas).toString())),
                  maxFeePerGas: hexWEIToDecGWEI(txParams.maxFeePerGas).toString(),
                  maxPriorityFeePerGas: hexWEIToDecGWEI(txParams.maxPriorityFeePerGas).toString(),
                  to: truncateAddress(txParams.to),
                  value: ethers.utils.formatEther(BigInt(txParams.value).toString()).toString(),
                  totalTransaction: ethers.utils.formatEther(totalAmount.toString()).toString()
                }
              }
            }));
            // console.log(`transaction type is: ${ercType}`);
          } else {
            // console.log(`transaction type is: ${ercType}`);
          }
        }
      }
    }
  }, [props.data, web3]);

  return (
    <div
      className="tx-sign scrollbar-hide flex p-5 flex-col w-full -mt-10 h-screen overflow-y-scroll relative pb-[100px]"
      style={{ backgroundColor: getColor().modalColor }}>
      <PopupHeader
        hostname={hostname}
        to={tokenInformation.txTo ? tokenInformation.txTo : renderState.txParams.txDetails.to}
        transactionType={transactionType}
        tokenName={tokenInformation.symbol}
        tokenAddr={tokenInformation.address}>
        <>
          {props.data?.txParams?.contractParams.logo ? (
            <div className="flex justify-between mt-2.5">
              <div className="flex items-center -mt-2.5">
                <span className="rounded-[100px] overflow-hidden w-10 h-10 flex mt-1.25">
                  <img
                    width={"100% !important"}
                    src={props.data.txParams.contractParams.logo}
                    onError={(self) => {
                      self.currentTarget.src = BrokenNFTImage;
                    }}
                    alt="nft"
                  />
                </span>
                <div className="ml-2.5">
                  <h1 className="w-full h-4 font-roboto text-start not-italic font-normal text-sm leading-4 text-black">
                    {props.data.txParams.contractParams.name}
                  </h1>
                  <h2 className="w-full h-4 font-roboto text-start not-italic font-bold text-sm leading-4 text-black -mt-1.75">
                    {tokenInformation.txValue} {props.data.txParams.contractParams.symbol}
                  </h2>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-between mt-2.5">
              <div className="flex items-center -mt-2.5">
                <span className="rounded-[100px] overflow-hidden w-10 h-10 flex mt-1.25">
                  <img
                    src={renderState.txParams.txDetails.tokenImg}
                    alt="token"
                    onError={(self) => {
                      self.currentTarget.src = BrokenTokenImage;
                    }}
                  />
                </span>
                <div className="ml-2.5">
                  <h1 className="w-full h-4 font-roboto text-start not-italic font-semibold text-sm leading-4 text-black">
                    {renderState.txParams.txDetails.tokenName}
                  </h1>
                  <h2 className="w-full h-4 font-roboto text-start not-italic font-normal text-sm leading-4 text-black -mt-1.75">
                    {renderState.network.networkName}
                  </h2>
                </div>
              </div>
            </div>
          )}
        </>
      </PopupHeader>

      <div className="text-left my-2.5">
        {transactionType.category !== TRANSACTION_TYPES.TOKEN_METHOD_APPROVE && (
          <span className="flex justify-between my-2">
            <h1 className="font-roboto not-italic font-normal text-base leading-4.75 text-right text-0A0A0A">
              {t("transfer.amountToSend")}
            </h1>
            {transactionType.category === TRANSACTION_TYPES.TOKEN_METHOD_TRANSFER ? (
              <h1 className="font-roboto not-italic font-normal text-base leading-4.75 text-right text-0A0A0A">
                {tokenInformation.txValue} {tokenInformation.symbol}
              </h1>
            ) : (
              <h1 className="font-roboto not-italic font-normal text-base leading-4.75 text-right text-0A0A0A">
                {renderState.txParams.txDetails.value} {renderState.txParams.txDetails.tokenName}
              </h1>
            )}
          </span>
        )}
        {transactionType.category === TRANSACTION_TYPES.TOKEN_METHOD_APPROVE && (
          <span className="flex justify-between my-2">
            <h1 className="font-roboto not-italic font-normal text-base leading-4.75 text-right text-0A0A0A">{"Approved value"}</h1>
            <h1 className="font-roboto not-italic font-normal text-base leading-4.75 text-right text-0A0A0A">
              {truncateAllowance(methodParams.value, 7)} {tokenInformation.symbol}
            </h1>
          </span>
        )}
        {transactionType.category === TRANSACTION_TYPES.TOKEN_METHOD_APPROVE && (
          <div className="mb-2.5">
            <p>Data</p>
            <div className="bg-F3F3F3 w-full overflow-scroll h-17.5 rounded-lg py-0 px-3.5">
              <p>{renderState.txParams.txDetails.data}</p>
            </div>
          </div>
        )}
        <span className="flex justify-between my-2">
          <h1 className="font-roboto not-italic font-normal text-base leading-4.75 text-right text-0A0A0A">{t("transfer.gasFee")}</h1>
          <h1 className="font-roboto not-italic font-normal text-base leading-4.75 text-right text-0A0A0A">
            {parseFloat(renderState.txParams.txDetails.gas).toFixed(6)} {renderState.txParams.txDetails.tokenName}
          </h1>
        </span>
        <span className="flex justify-between my-2">
          <h1 className="font-roboto not-italic font-normal text-base leading-4.75 text-right text-0A0A0A">{t("transfer.total")}</h1>
          <h1 className="font-roboto not-italic font-normal text-base leading-4.75 text-right text-0A0A0A">
            {parseFloat(renderState.txParams.txDetails.totalTransaction).toFixed(6)} {renderState.txParams.txDetails.tokenName}
          </h1>
        </span>
        {transactionType.category === TRANSACTION_TYPES.TOKEN_METHOD_TRANSFER ||
          (transactionType.category === TRANSACTION_TYPES.COLLECTIBLE_METHOD_SAFE_TRANSFER_FROM && (
            <span className="flex justify-between my-2">
              <h1 className="font-roboto not-italic font-normal text-base leading-4.75 text-right text-0A0A0A">{""}</h1>
              <p className="font-bold">
                {renderState.txParams.txDetails.totalTransaction} {renderState.txParams.txDetails.tokenName}
              </p>
              <p className="font-bold">+</p>
              <p className="font-bold">
                {tokenInformation.txValue} {tokenInformation.symbol}
              </p>
            </span>
          ))}
      </div>
      {isInsufficient && <p className="text-red-600">{t("transfer.amountExceedsBalance")}</p>}
      <PopupHandler
        triggerDeny={triggerDeny}
        thewayTriggerSign={thewayTriggerSign}
        isInsufficient={isInsufficient}
        from={popupHandlerFrom.sign}
      />
    </div>
  );
};

const SwitchNetworkPopup = (props: PopupProps) => {
  const [currentSelectedNetwork, setCurrentSelectedNetwork] = useState<NetworkType | null>(null);
  const [payloadSelectedNetwork, setPayloadSelectedNetwork] = useState<NetworkType | null>(null);
  const { hostname, triggerDeny, thewayTriggerSign, data } = props;

  useEffect(() => {
    if (data?.payload) {
      const payload = data.payload as ChangeNetworkPayload;
      const filteredCurrentNetwork = Object.values(SUPPORTED_NETWORK_TYPES).find(
        (val) => parseInt(val.chainId.toString()) === parseInt(data.network.chainId.toString())
      );
      const filteredPayloadNetwork = Object.values(SUPPORTED_NETWORK_TYPES).find(
        (val) => parseInt(val.chainId.toString()) === parseInt(payload.network.host.toString())
      );
      const currentNetwork = SUPPORTED_NETWORK_TYPES[filteredCurrentNetwork ? filteredCurrentNetwork.host : data.network.host];
      const payloadNetwork = SUPPORTED_NETWORK_TYPES[filteredPayloadNetwork ? filteredPayloadNetwork.host : payload.network.host];
      if (currentNetwork) {
        setCurrentSelectedNetwork(currentNetwork);
      }
      if (payloadNetwork) {
        setPayloadSelectedNetwork(payloadNetwork);
      }
    }
  }, [data]);

  return (
    <div className="flex p-5 flex-col w-full -mt-10 h-screen overflow-y-scroll relative pb-[100px]">
      <PopupHeader hostname={hostname} />
      <p className="text-center font-roboto not-italic font-normal text-base text-0A0A0A">{t("notification.allowSwitchNetwork")}</p>
      {currentSelectedNetwork && payloadSelectedNetwork && (
        <div className="my-3.75 mx-0 flex justify-between">
          <div className="flex flex-col items-center text-center w-[115px]">
            <span className="rounded-[100px] overflow-hidden w-10 h-10 flex">
              <IonImg src={currentSelectedNetwork?.logo} />
            </span>
            <h1 className="font-roboto not-italic font-semibold text-base text-center text-black">{currentSelectedNetwork.networkName}</h1>
          </div>
          <span className="mt-7.5">
            <ArrowForwardIos />
          </span>
          <div className="flex flex-col items-center text-center w-[115px]">
            <span className="rounded-[100px] overflow-hidden w-10 h-10 flex">
              <IonImg src={payloadSelectedNetwork.logo} />
            </span>
            <h1 className="font-roboto not-italic font-semibold text-base text-center text-black">{payloadSelectedNetwork?.networkName}</h1>
          </div>
        </div>
      )}
      <PopupHandler
        triggerDeny={triggerDeny}
        thewayTriggerSign={() =>
          thewayTriggerSign({
            currentSelectedNetwork,
            payloadSelectedNetwork,
            type: "provider_change",
            approve: true
          })
        }
        isInsufficient={false}
      />
    </div>
  );
};

export { DataSignPopup, EthSignPopup, PersonalSignPopup, SignTxPopup, SwitchNetworkPopup };
