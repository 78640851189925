import { SafeEventEmitterProvider } from "@web3auth/base";
import NetworkController from "controllers/NetworkController";
import torus from "libs/TorusExtended";
import { useEffect, useState } from "react";

export default function useProvider() {
  const [controller, setController] = useState<NetworkController | undefined>();
  const [provider, setProvider] = useState<SafeEventEmitterProvider | null>();
  const [status, setStatus] = useState<"idle" | "loading" | "success">("idle");

  useEffect(() => {
    setStatus("loading");
    if (torus.upbondController?.networkController) {
      setController(torus.upbondController.networkController);
    }
  }, [torus]);

  useEffect(() => {
    if (controller) {
      setProvider(controller.getProviderAndBlockTracker().provider);
      setStatus("success");
    }
  }, [controller]);

  return {
    provider,
    status
  };
}
