import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction } from "@reduxjs/toolkit";
import { GasSetting } from "interfaces/actions/IGasPrice";
import { RootState } from "shared/store";
import gasSlice from "shared/store/gasSlice";

export const gasAction = gasSlice.actions;

export const fetchGasSetting =
  (item: GasSetting): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(gasAction.addGasNow(item));
  };
