// @ts-nocheck
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { IonContent, IonFooter, IonLabel, IonItem, IonList, IonPage } from "@ionic/react";
import HeaderMainMb from "components/header/mb";
import { useTranslation } from "react-i18next";

const SettingMainMb: React.FC<any> = ({ handleLogout, presentToast }) => {
  const { t } = useTranslation();

  return (
    <IonPage color="medium">
      <HeaderMainMb title={t("settings.title")} link="/wallet/account" />

      <IonContent id="content-setting-wrapper" className="gray">
        {/* First Menu */}
        <IonList className="setting-list py-[10px] px-4 my-4 mx-0">
          <IonItem routerLink="/settings/backup">
            <IonLabel className="px-[5px] py-[2.4px]"> {t("settings.backup")}</IonLabel>
          </IonItem>
          <IonItem routerLink="/settings/currency">
            <IonLabel className="px-[5px] py-[2.4px]"> {t("settings.currency")}</IonLabel>
          </IonItem>
          <IonItem routerLink="/settings/network">
            <IonLabel className="px-[5px] py-[2.4px]"> {t("settings.justNetwork")}</IonLabel>
          </IonItem>
          <IonItem routerLink="/settings/language">
            <IonLabel className="px-[5px] py-[2.4px]"> {t("settings.language")}</IonLabel>
          </IonItem>
        </IonList>

        {/* Second Menu */}
        <IonList className="setting-list py-[10px] px-4 my-4 mx-0 list-spacing">
          <IonItem routerLink="/settings/personal-information">
            <IonLabel className="px-[5px] py-[2.4px]"> {t("settings.personal")}</IonLabel>
          </IonItem>
          {/* <IonItem routerLink="/settings/application">
            <IonLabel> {t("settings.application")}</IonLabel>
          </IonItem> */}
          <IonItem onClick={handleLogout}>
            <IonLabel className="px-[5px] py-[2.4px]"> {t("settings.logout")}</IonLabel>
          </IonItem>
        </IonList>

        {/* Third Menu */}
        <IonList className="setting-list py-[10px] px-4 my-4 mx-0 list-spacing">
          <IonItem onClick={() => presentToast("middle")}>
            <IonLabel className="px-[5px] py-[2.4px]"> {t("settings.faq")}</IonLabel>
          </IonItem>
          <IonItem onClick={() => window.open("https://www.upbond.io/inquiry-wallet")} className="menus">
            <IonLabel className="px-[5px] py-[2.4px]"> {t("settings.inquiry")}</IonLabel>
          </IonItem>
        </IonList>

        {/* Permission Menu */}
        {/* <IonList className="setting-list py-[10px] px-4 my-4 mx-0 list-spacing">
          <IonItem routerLink="/settings/permission-manage" className="inquiry menus">
            <IonLabel>{t("settings.managePermission")}</IonLabel>
          </IonItem>
        </IonList> */}
      </IonContent>

      <IonFooter className="text-sm mb-6 text-center">
        <IonLabel className="terms">
          <span
            className="font-bold underline px-[5px] cursor-pointer"
            onClick={() => window.open("https://upbond.notion.site/UPBOND-Wallet-Terms-of-Service-1b6d7c8f72a94f799c8ab1b82df6c799")}>
            {t("login.term")}
          </span>
          {t("login.and")}
          <span
            className="font-bold underline px-[5px] cursor-pointer"
            onClick={() => window.open("https://upbond.notion.site/UPBOND-Wallet-29b06c6e77d24b6798bf50a6a73fd67b")}>
            {t("settings.privacy")}
          </span>
        </IonLabel>
      </IonFooter>
    </IonPage>
  );
};

export default SettingMainMb;
