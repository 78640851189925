// @ts-nocheck
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { IonIcon, IonItem, IonList, IonModal } from "@ionic/react";
import { QRCode } from "react-qrcode-logo";
import { copyToClipboard } from "shared/utils/coreUtils";
import { ModalQrTypeMb } from "interfaces/ui/IMenu";
import { close, copyOutline } from "ionicons/icons";
import Logo from "assets/images/upbondlg.jpg";
import { useTranslation } from "react-i18next";

const ModalQrPc = ({ modal, openModalQRCode, setOpenModalQRCode, setOpenQr, openQr, account }: ModalQrTypeMb) => {
  const { t } = useTranslation();
  return (
    <IonModal id="example-modal" backdropDismiss={false} ref={modal} trigger="open-modal" isOpen={openModalQRCode} className="modal-qr">
      <div className="wrapper">
        <IonList lines="none">
          <div style={{ width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
            <IonItem style={{ width: "100%" }}>
              <IonIcon
                onClick={() => {
                  setOpenModalQRCode(false);
                }}
                src={close}
                style={{ cursor: "pointer" }}
                slot="end"></IonIcon>
            </IonItem>
            <div style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>{t("receive.title")}</div>
            <QRCode
              value={account}
              ecLevel={"H"}
              // removeQrCodeBehindLogo={true}
              qrStyle={"dots"}
              eyeRadius={1}
              quietZone={30}
              size={200}
              logoImage={Logo}
              logoWidth={50}
              logoHeight={50}
            />
            <div style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>{t("receive.yourWallet")}</div>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                margin: "10px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              onClick={() => {
                copyToClipboard(account);
                setOpenQr(!openQr);
              }}>
              {account}
              <IonIcon style={{ width: "20px", paddingLeft: "10px" }} slot="start" icon={copyOutline} />
            </div>
          </div>
        </IonList>
        {openQr && (
          <div
            className="tooltip-set-qr"
            style={{
              right: localStorage.getItem("lang") === "ja" ? "-75%" : "-83%",
              top: "-3%"
            }}>
            <div
              className="bottom"
              style={{
                minWidth: localStorage.getItem("lang") === "ja" ? 220 : 128,
                padding: localStorage.getItem("lang") === "ja" ? "6px 13px" : "6px 9px"
              }}>
              {t("account.copiedToClipboard")}
              <i></i>
            </div>
          </div>
        )}
      </div>
    </IonModal>
  );
};

export { ModalQrPc };
