// @ts-nocheck
import { IonButton, IonContent, IonIcon, IonPage, useIonRouter } from "@ionic/react";
import Cog from "assets/icons/Cog.svg";
import ModalHistory from "features/account/components/ModalHistory";
import ModalOpenExplorer from "features/account/components/ModalOpenExplorer";
import ModalQr from "features/account/components/ModalQr";
import ModalCommingSoon from "components/modals/ModalCommingSoon";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import { TransactionItems } from "interfaces/actions/IHistoryTransaction";
import { MoralisTransactionResult } from "interfaces/actions/moralis/IMoralisHistoryTransaction";
import { IAstarUseState, IMoralisUseState } from "interfaces/libs/IAccountHistory";
import { useEffect, useRef, useState } from "react";
import { fetchHistory, historytransactionAction } from "shared/actions/historyTransactionAction";
import { uniqueArray } from "shared/utils/coreUtils";
import HistoryContentMb from "features/account/components/History/mb";
import HistoryContentPc from "features/account/components/History/pc";
import WalletConnectButton from "features/home/components/WalletConnectButton";
import PendingComponent from "features/home/components/PendingComponent";
import "./style.css";
import useProvider from "hooks/useProvider";
import { listChain } from "shared/config/chains";
import { ethers } from "ethers";
import ERC20ABI from "abis/erc20.json";
import { IChains } from "interfaces/actions/IToken";
import { getBestRpc } from "shared/utils/filterRpc";
// import ModalPurchase from "components/purchase/ModalPurchase";

const AccountPage: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [openExplorer, setOpenExplorer] = useState<boolean>(false);
  const [data, setData] = useState<TransactionItems>();
  const [dataMoralis, setDataMoralis] = useState<MoralisTransactionResult>();

  const currentchain = localStorage.getItem("chain");
  const [openModalPurchase, setOpenModalPurchase] = useState<boolean>(false);
  const [openModalHistory, setOpenModalHistory] = useState<boolean>(false);
  const [openModalQRCode, setOpenModalQRCode] = useState<boolean>(false);
  const [isAstar, setIsAstar] = useState<boolean>(false);
  const [astarItems, setAstarItems] = useState<IAstarUseState>({
    haveMore: false,
    items: [] as TransactionItems[],
    allItems: [] as TransactionItems[]
  });
  const [moralisItems, setMoralisItems] = useState<IMoralisUseState>({
    haveMore: false,
    items: [],
    allItems: []
  });
  const [isSeeDetail, setIsSeeDetail] = useState<boolean>(false);
  const [modalHistoryLoading, setModalHistoryLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const stateBalance = useAppSelector((state) => state.balance);
  const { history: stateHistory, isHistoryLoading, doneCalculate } = useAppSelector((state) => state.history);
  const { userInfo: user, selectedAddress: account } = useAppSelector((state) => state.wallet);
  const historyChain = useAppSelector((state) => state.historyChain.historyChain);
  const modal = useRef<HTMLIonModalElement>(null);
  const { breakpoint } = useTheme();
  const history = useIonRouter();
  const { provider } = useProvider();
  const [ether, setEther] = useState<any>(null);
  const [counter, setCounter] = useState(0);
  const [detailsLoading, setDetailsLoading] = useState<boolean>(true);
  const [latest10, setLatest10] = useState<MoralisTransactionResult[]>([]);
  const [haveMore, setHaveMore] = useState<boolean>(false);

  useEffect(() => {
    account && stateBalance && dispatch(fetchHistory(account, historyChain));
  }, [account, stateBalance, historyChain]);

  useEffect(() => {
    if (localStorage.getItem("selectedContact")) {
      localStorage.removeItem("selectedContact");
    }
  }, []);

  useEffect(() => {
    let a = 0;
    if (open) {
      a = window.setTimeout(function () {
        setOpen(false);
      }, 500);
    } else {
      clearTimeout(a);
    }
  }, [open]);

  /* call the `filteredHistory` function when either the `isHistoryLoading` or `doneCalculate`
  state variables change. This is likely used to update the component's display based on
  changes to the history data or calculation results. */
  useEffect(() => {
    const getHistory = async () => {
      setDetailsLoading(true);
      await filteredHistoryLatest10();
      await filteredHistory();
    };
    getHistory();
  }, [currentchain, isHistoryLoading, doneCalculate]);

  useEffect(() => {
    if (provider) {
      listChain.forEach((chain) => {
        if (historyChain.chainName === chain.chainName) {
          setBestProvider(chain);
        }
      });
    }
  }, [provider, historyChain]);

  const setBestProvider = async (chain: IChains) => {
    const bestRpc = await getBestRpc(chain.chain, chain.rpcUrl);
    setEther(new ethers.providers.JsonRpcProvider(bestRpc));
  };

  const accountLowerCase = account ? account.toLowerCase() : "Loading ...";

  const filteredHistory = async () => {
    setCounter(counter + 1);
    // setModalHistoryLoading(true);
    const history = uniqueArray(stateHistory);
    const byChainId =
      history && history.length > 0 ? history.find((groupHistory: any) => groupHistory.chain_id === `${historyChain.chain}`) : [];
    /* checking if a certain chain ID is equal to "592". If it is, it sets some state
    variables related to Astar transactions and dispatches an action to remove loading. If it is
    not, it sets some state variables related to Moralis transactions and dispatches an action to
    remove loading. The state variables include whether there are more transactions to load, a
    subset of the transactions to display, and all of the transactions. */
    if (byChainId?.chain_id === "592") {
      let haveMore = false;
      let items: TransactionItems[] = [];
      let allItems: TransactionItems[] = [];
      if (byChainId && byChainId?.items && byChainId?.items?.length > 0) {
        haveMore = byChainId?.items?.length > 10;
        items = byChainId?.items?.slice(0, 10);
        allItems = byChainId.items;
      }
      setAstarItems({
        haveMore,
        allItems,
        items
      });
      setIsAstar(true);
      if (doneCalculate) {
        // setTimeout(() => {
        //   dispatch(historytransactionAction.setRemoveLoading());
        // }, 500);
      }
      setModalHistoryLoading(false);
    } else {
      let haveMore = false;
      let items: MoralisTransactionResult[] = [];
      let allItems: MoralisTransactionResult[] = [];
      if (byChainId && byChainId?.items && byChainId?.items?.length > 0) {
        haveMore = byChainId?.items?.length > 10;
        items = byChainId?.items?.slice(0, 10);
        allItems = byChainId?.items;
      }
      for (const [idx, ele] of allItems.entries()) {
        try {
          if (ele.input && ele.input !== "0x" && !Number(ele.value)) {
            const res = await ether.getTransactionReceipt(ele.hash);
            if (res) {
              for (const log of res.logs) {
                if (log.topics[0] === "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef") {
                  const abiInter = new ethers.utils.Interface(ERC20ABI.abi);
                  const result = abiInter.decodeEventLog("Transfer", log.data, log.topics);
                  if (result) {
                    ele.to_address = result[1];
                    ele.value = result[2];
                  }
                }
              }
            } else {
              console.log("Transaction receipt not found.");
            }
          }
        } catch (err) {
          console.log(err);
        }
        if (idx % 10 === 0) setMoralisItems({ items, haveMore, allItems });
      }
      setMoralisItems({
        items,
        haveMore,
        allItems
      });
      setIsAstar(false);
      if (doneCalculate) {
        setTimeout(() => {
          dispatch(historytransactionAction.setRemoveLoading());
        }, 500);
      }
      setModalHistoryLoading(false);
    }
  };

  const filteredHistoryLatest10 = async () => {
    const history = uniqueArray(stateHistory);
    const byChainId =
      history && history.length > 0 ? history.find((groupHistory: any) => groupHistory.chain_id === `${historyChain.chain}`) : [];
    /* checking if a certain chain ID is equal to "592". If it is, it sets some state
    variables related to Astar transactions and dispatches an action to remove loading. If it is
    not, it sets some state variables related to Moralis transactions and dispatches an action to
    remove loading. The state variables include whether there are more transactions to load, a
    subset of the transactions to display, and all of the transactions. */
    if (byChainId?.chain_id === "592") {
      let haveMore = false;
      let items: TransactionItems[] = [];
      let allItems: TransactionItems[] = [];
      if (byChainId && byChainId?.items && byChainId?.items?.length > 0) {
        haveMore = byChainId?.items?.length > 10;
        items = byChainId?.items?.slice(0, 10);
        allItems = byChainId.items;
      }
      setAstarItems({
        haveMore,
        allItems,
        items
      });
      setIsAstar(true);
      if (doneCalculate) {
        setTimeout(() => {
          setDetailsLoading(false);
          dispatch(historytransactionAction.setRemoveLoading());
        }, 500);
      }
    } else {
      let haveMore = false;
      let items: MoralisTransactionResult[] = [];
      // const allItems: MoralisTransactionResult[] = [];
      if (byChainId && byChainId?.items && byChainId?.items?.length > 0) {
        haveMore = byChainId?.items?.length > 10;
        items = byChainId?.items?.slice(0, 10);
        // allItems = byChainId?.items;
      }
      setHaveMore(haveMore);
      for (const [_, ele] of items.entries()) {
        try {
          if (ele.input !== "0x") {
            const res = await ether.getTransactionReceipt(ele.hash);
            if (res) {
              for (const log of res.logs) {
                if (log.topics[0] === "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef") {
                  const abiInter = new ethers.utils.Interface(ERC20ABI.abi);
                  const result = abiInter.decodeEventLog("Transfer", log.data, log.topics);
                  if (result) {
                    ele.to_address = result[1];
                    ele.value = result[2];
                  }
                }
              }
            } else {
              console.log("Transaction receipt not found.");
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
      setLatest10(items);
      setIsAstar(false);
      if (doneCalculate) {
        setTimeout(() => {
          setDetailsLoading(false);
          dispatch(historytransactionAction.setRemoveLoading());
        }, 500);
      }
    }
  };

  const getNext10 = async (idx: number, ev: any) => {
    setLatest10(moralisItems.allItems.slice(0, idx + 10));
    setTimeout(() => ev.target.complete(), 500);
  };

  const handleModal = (data: TransactionItems, isSeeDetail: boolean) => {
    setOpenExplorer(!openExplorer);
    setData(data);
    setIsSeeDetail(isSeeDetail);
  };
  const handleModalMoralis = (data: MoralisTransactionResult, isSeeDetail: boolean) => {
    setOpenExplorer(!openExplorer);
    setDataMoralis(data);
    setIsSeeDetail(isSeeDetail);
  };

  return (
    <>
      <IonPage>
        <IonContent className={breakpoint >= 1024 ? "no-padding bg-base" : "ion-padding"} fullscreen>
          {breakpoint < 1024 && (
            <div className="flex flex-row justify-between">
              <WalletConnectButton />
              <div>
                <IonButton
                  onClick={() => history.push(breakpoint < 1024 ? "/settings" : "/wallet/settings", "forward", "push")}
                  color="light"
                  size="large"
                  slot="icon-only"
                  className="cog">
                  <IonIcon src={Cog} />
                </IonButton>
                <PendingComponent />
              </div>
            </div>
          )}

          {/* HISTORY CONTENT MOBILE */}
          {breakpoint < 1024 ? (
            <HistoryContentMb
              user={user}
              account={account}
              open={open}
              setOpen={setOpen}
              astarItems={astarItems}
              handleModal={handleModal}
              handleModalMoralis={handleModalMoralis}
              isAstar={isAstar}
              moralisItems={moralisItems}
              setOpenModalHistory={setOpenModalHistory}
              setOpenModalPurchase={setOpenModalPurchase}
              setOpenModalQRCode={setOpenModalQRCode}
            />
          ) : (
            <>
              {/* HISTORY CONTENT DEKSTOP */}
              <HistoryContentPc
                user={user}
                account={account}
                open={open}
                setOpen={setOpen}
                astarItems={astarItems}
                handleModal={handleModal}
                handleModalMoralis={handleModalMoralis}
                isAstar={isAstar}
                moralisItems={{ items: latest10.slice(0, 9), allItems: [], haveMore }}
                setOpenModalHistory={setOpenModalHistory}
                setOpenModalPurchase={setOpenModalPurchase}
                setOpenModalQRCode={setOpenModalQRCode}
                detailsLoading={detailsLoading}
              />
            </>
          )}
        </IonContent>
      </IonPage>

      {/* QR CODE MODAL */}
      <ModalQr modal={modal} openModalQRCode={openModalQRCode} setOpenModalQRCode={setOpenModalQRCode} />

      {/* Modal All History */}
      <ModalHistory
        popUpSliderLoading={modalHistoryLoading}
        isOpen={openModalHistory}
        closeModal={() => setOpenModalHistory(false)}
        moralisHistoryData={latest10}
        account={accountLowerCase}
        handlePresentMoralis={handleModalMoralis}
        handlePresent={handleModal}
        historyData={astarItems.allItems}
        isAstarNetwork={isAstar}
        getNext10={getNext10}
      />
      {/* <ModalPurchase openModal={openModalPurchase} closeModal={() => setOpenModalPurchase(!openModalPurchase)} /> */}
      {openModalPurchase ? <ModalCommingSoon isOpen={openModalPurchase} setOpenCommingSoon={setOpenModalPurchase} /> : null}

      {/* Open explorer like bscscan, etherscan, astarscan etc */}
      <ModalOpenExplorer
        isSeeDetail={isSeeDetail}
        openModalExplorer={openExplorer}
        setOpenModalExplorer={setOpenExplorer}
        isAstar={isAstar}
        data={data}
        dataMoralis={dataMoralis}
      />
    </>
  );
};

export default AccountPage;
