// @ts-nocheck
/* eslint-disable jsx-a11y/media-has-caption */
import { IonAvatar, IonIcon, IonImg, IonInput, IonItem, IonLabel, useIonRouter } from "@ionic/react";
import { defaultLogo } from "helpers/httpHelpers";
import { useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import { t } from "i18next";
import { chevronForwardOutline, ellipsisHorizontalCircle } from "ionicons/icons";

const ContactBar = ({ checkType = 0 }: { checkType?: number }) => {
  const history = useIonRouter();
  const transfer = useAppSelector((state) => state.transfer);
  const contact = useAppSelector((state) => state.contact);
  const { breakpoint } = useTheme();
  const logoContact = defaultLogo(transfer.detail?.chain_id || "0");
  const handleError = (e: any) => {
    e.target.src = window.location.origin + "/assets/noimg.png";
  };
  return (
    <>
      <div className="flex flex-row justify-between items-center py-1 px-4 rounded-custom border border-slate-300 mb-1.5">
        <>
          <IonInput
            placeholder={t("transfer.transferToPlaceholder") || ""}
            value={t("transfer.transferToPlaceholder") + " " + contact.selectedContact?.name}
            disabled={true}
          />
          <IonIcon icon={ellipsisHorizontalCircle} color="dark" onClick={() => history.push(`/wallet/transfer`)} />
        </>
      </div>
      <IonItem
        className="shadow-itemListShadow rounded-2xl border-l-0 py-1.5 px-4 mb-4"
        lines="none"
        onClick={() => {
          history.goBack();
        }}>
        {transfer.detail?.native_token ? (
          <>
            <IonAvatar>
              <IonImg className="" alt={transfer?.detail?.contract_name} src={transfer?.detail?.logo_url} onError={handleError} />
              <IonImg
                className="absolute bottom-0 left-0 w-[18px] h-[18px]"
                alt={transfer?.detail?.contract_name}
                src={
                  transfer?.detail?.logo_url && transfer?.detail?.logo_url !== ""
                    ? transfer?.detail?.logo_url
                    : window.location.origin + "/assets/noimg.png"
                }
                onError={handleError}
              />
            </IonAvatar>
            <IonLabel className="font-bold ps-4">{transfer.detail?.contract_name}</IonLabel>
            <IonIcon icon={chevronForwardOutline} color="dark" />
          </>
        ) : !transfer.detail?.native_token && transfer.detail?.type === "nft" ? (
          transfer.detail?.nft_data.map((item: any, index: number) => {
            if (index === 0)
              return (
                <div key={index} className="min-w-full flex justify-between items-center">
                  <IonAvatar>
                    <IonImg
                      className="w-[50px] h-[50px]"
                      alt="tokenlogo"
                      src={
                        item?.external_data?.image && item?.external_data?.image !== ""
                          ? item?.external_data?.image
                          : window.location.origin + "/assets/noimg.png"
                      }
                      onError={handleError}
                    />
                  </IonAvatar>
                  <div className="flex flex-col w-[80%]">
                    <IonLabel className="font-bold ps-4">{transfer.selectedNft?.external_data?.name ?? ""}</IonLabel>
                    <IonLabel className="ps-4 !text-sm">{transfer.detail?.contract_name}</IonLabel>
                  </div>
                  <div>
                    <IonIcon icon={chevronForwardOutline} color="dark" />
                  </div>
                </div>
              );
          })
        ) : (
          <>
            <IonAvatar>
              <IonImg className="" alt="tokenlogo" src={logoContact} onError={handleError} />
              <IonImg
                className="absolute bottom-0 left-0 w-[18px] h-[18px]"
                alt={transfer.detail?.contract_name}
                src={transfer.detail?.logo_url}
                onError={handleError}
              />
            </IonAvatar>
            <IonLabel className="font-bold ps-4">{transfer.detail?.contract_name}</IonLabel>
            <IonIcon icon={chevronForwardOutline} color="dark" />
          </>
        )}
      </IonItem>
      {breakpoint < 1024 ? (
        <>
          {transfer.detail?.nft_data &&
            transfer.detail?.nft_data.length > 0 &&
            (transfer.selectedNft?.external_data?.animation_url ? (
              <div
                className={`flex justify-center rounded-custom max-w-[90%] my-0 mx-auto ${
                  checkType > 0 ? "lg:max-w-[50%] xl:max-w-[25%]" : "xl:max-w-[40%]"
                }`}>
                <video autoPlay controls className="min-w-[50px] min-h-[340px] w-full">
                  <source src={transfer.selectedNft?.external_data?.animation_url} />
                </video>
              </div>
            ) : (
              <div
                className={`flex justify-center rounded-custom max-w-[90%] my-0 mx-auto ${
                  checkType > 0 ? "lg:max-w-[50%] xl:max-w-[25%]" : "lg:max-w-[60%] xl:max-w-[40%]"
                }`}>
                <img
                  alt="tokenlogo"
                  className="overflow-hidden rounded-custom min-w-[50px] w-full object-contain"
                  src={transfer.selectedNft?.external_data?.image ?? window.location.origin + "/assets/noimg.png"}
                  onError={handleError}
                />
              </div>
            ))}
        </>
      ) : (
        <>
          {transfer.detail?.nft_data &&
            transfer.detail?.nft_data.length > 0 &&
            (transfer.selectedNft?.external_data?.animation_url ? (
              <div
                className={`flex justify-center rounded-custom max-w-[90%] my-0 mx-auto ${
                  checkType > 0 ? "lg:max-w-[50%] xl:max-w-[25%]" : "xl:max-w-[40%]"
                }`}>
                <video autoPlay controls className="min-w-[50px] min-h-[340px] w-full">
                  <source src={transfer.selectedNft?.external_data.animation_url} />
                </video>
              </div>
            ) : (
              <div
                className={`flex justify-center rounded-custom max-w-[90%] my-0 mx-auto ${
                  checkType > 0 ? "lg:max-w-[50%] xl:max-w-[25%]" : "lg:max-w-[60%] xl:max-w-[40%]"
                }`}>
                <img
                  alt="tokenlogo"
                  className="overflow-hidden rounded-custom min-w-[50px] w-full object-contain"
                  src={transfer.selectedNft?.external_data?.image ?? window.location.origin + "/assets/noimg.png"}
                  onError={handleError}
                />
              </div>
            ))}
        </>
      )}
    </>
  );
};

export default ContactBar;
