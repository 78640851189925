import { gql } from "@apollo/client/core";

export const INITIATE_USER_LOGIN = gql`
  mutation UserLogin($login_payload: UserLoginPayload!) {
    res: addLoginRecord(payload: $login_payload) {
      device_id
      login_record_id
      dapp_id
      user_login_count
    }
  }
`;
export const UPDATE_LOGIN_RECORD = gql`
  mutation UpdateLoginRecord($update_record_login_payload: UpdateLoginState!) {
    message: UpdateLoginRecord(payload: $update_record_login_payload)
  }
`;
