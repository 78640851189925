import { gasConfigs } from "shared/config/gas";
import { GasPriceOracle, GasPriceKey } from "@upbond/upbond-gas-oracle/lib/services";
import { GasPriceWithEstimate, GetTxGasParamsRes } from "interfaces/actions/IGasPrice";

export class GasServices {
  chainId: number;
  constructor(chainId: number) {
    this.chainId = chainId;
  }

  async gasPricesWithEstimate(): Promise<GasPriceWithEstimate> {
    const oracle = new GasPriceOracle(gasConfigs(this.chainId));
    return await oracle.gasPricesWithEstimate();
  }

  async getTxGasParams(type: GasPriceKey): Promise<GetTxGasParamsRes> {
    const oracle = new GasPriceOracle(gasConfigs(this.chainId));
    return await oracle.getTxGasParams({ isLegacy: false, shouldGetMedian: false, legacySpeed: type, bumpPercent: 0 });
  }
}
