// @ts-nocheck
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IonContent, IonIcon, IonLabel, IonList, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane, useIonRouter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Redirect, Route } from "react-router-dom";
import AccountIcon from "assets/icons/Account.svg";
import HomeIcon from "assets/icons/Home.svg";
import TransferIcon from "assets/icons/TransferIcon.svg";
import WalletConnectIcon from "assets/icons/WalletConnect.svg";
import SettingRoutes from "routes/settings";
import TransferRoutes from "routes/transfer";
import TransferPage from "pages/transfer";
import AccountPage from "pages/account";
import PendingTransaction from "pages/pendingTransaction";
import HomePage from "pages/home";
import ApplicationPage from "pages/application";

const SplitPanel: React.FC = () => {
  const { t } = useTranslation();
  const history = useIonRouter();

  return (
    <>
      <IonSplitPane when="sm" contentId="main">
        <IonMenu contentId="main" class="split-panel-menu ">
          <IonContent>
            <IonList lines="none">
              <IonMenuToggle autoHide={false}>
                <div
                  className={window.location.href.includes("/wallet/home") ? "menus-item" : "menus-item-disabled"}
                  onClick={() => history.push("/", "forward", "push")}>
                  <IonIcon src={HomeIcon} className="gapIcon icon-split" />
                  <IonLabel className="weight-700 font-12">{t("navbar.home")}</IonLabel>
                </div>
                <div
                  className={
                    window.location.href.includes("/wallet/transfer") || window.location.href.includes("/wallet/tf")
                      ? "menus-item"
                      : "menus-item-disabled"
                  }
                  onClick={() => history.push("/wallet/transfer", "none", "push")}>
                  <IonIcon src={TransferIcon} className="gapIcon icon-split" />
                  <IonLabel className="weight-700 font-12">{t("navbar.transfer")}</IonLabel>
                </div>
                <div
                  className={
                    window.location.href.includes("/wallet/account") || window.location.href.includes("/wallet/settings")
                      ? "menus-item"
                      : "menus-item-disabled"
                  }
                  onClick={() => history.push("/wallet/account", "none", "push")}>
                  <IonIcon src={AccountIcon} className="gapIcon icon-split" />
                  <IonLabel className="weight-700 font-12">{t("navbar.account")}</IonLabel>
                </div>
                <div
                  className={window.location.href.includes("/wallet/application") ? "menus-item" : "menus-item-disabled"}
                  onClick={() => history.push("/wallet/application", "none", "push")}>
                  <IonIcon src={WalletConnectIcon} className="gapIcon icon-split" />
                  <IonLabel className="weight-700 font-12">{t("navbar.walletConnect")}</IonLabel>
                </div>
              </IonMenuToggle>
            </IonList>
          </IonContent>
        </IonMenu>
        <IonRouterOutlet id="main">
          <Route path="/wallet/home" component={HomePage} />
          <Route path="/wallet/transfer" component={TransferPage} />
          <Route path="/wallet/account" component={AccountPage} />
          <Route path="/wallet/application" component={ApplicationPage} />
          <Route path="/wallet/settings" render={() => <SettingRoutes />} />
          <Route path="/wallet/tf" render={() => <TransferRoutes />} />
          <Route path="/wallet/pending-transaction" component={PendingTransaction} />
          <Route exact path="/wallet">
            <Redirect to="/wallet/home" />
          </Route>
        </IonRouterOutlet>
      </IonSplitPane>
    </>
  );
};

export default SplitPanel;
