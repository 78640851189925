import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React from "react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "./themes/tailwind.css";
import "./themes/variables.css";

/* Theme variables */
import { useAppDispatch } from "hooks/useRedux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, store } from "shared/store";
import { createLegacySignClient } from "shared/utils/walletconnect/LegactWalletConnectUtil";
import { initStream } from "helpers/initStreamListenerHelper";
import useCommunicationStream from "hooks/useCommunicationStream";
import useInitialization from "hooks/useInitialization";
import useWalletConnectEventsManager from "hooks/useWalletConnectEventsManager";
import RootRoutes from "routes";
import { setShowTestnet } from "shared/actions/balanceAction";
import { rehydrate } from "shared/actions/olTkeyModuleAction";
import { isMain } from "shared/utils/coreUtils";
import { initSdk } from "shared/utils/openloginhandler";

setupIonicReact();
if (!isMain) {
  /*
    safari does not support rehydrated async using react hooks, then we need to init the communication mux
    before react rendering all routes & components. This issues is on safari, be aware of this :)
  */
  initStream();
}
const AppRoutes: React.FC = () => {
  const dispatch = useAppDispatch();
  const shared = useCommunicationStream();
  const { selectedAddress } = useSelector((state: RootState) => state.wallet);

  useEffect(() => {
    localStorage.setItem("currency", "USD" as string);
    const showNetwork = localStorage.getItem("showTestnet") ? (localStorage.getItem("showTestnet") == "true" ? true : false) : false;
    dispatch(setShowTestnet(showNetwork));
    shared._setup();
    initSdk();
  }, []);

  useEffect(() => {
    const { olTKeyModule } = store.getState();
    const fetch = async () => {
      if (!olTKeyModule.tKey && !olTKeyModule.initialized) {
        rehydrate({ postboxKey: olTKeyModule.postboxKey, tKeyJson: olTKeyModule.tKey });
      }
    };
    fetch();
  }, []);

  // Step 1 - Initialize wallets and wallet connect client
  const initialized = useInitialization();

  // Step 2 - Once initialized, set up wallet connect event manager
  useWalletConnectEventsManager(initialized);

  // Backwards compatibility only - create a legacy v1 SignClient instance.
  createLegacySignClient(dispatch);

  return <RootRoutes selectedAddress={selectedAddress} />;
};

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <AppRoutes />
    </IonReactRouter>
  </IonApp>
);

export default App;
