// @ts-nocheck
import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPage, IonRow } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { checkmarkOutline } from "ionicons/icons";
import HeaderMainPc from "components/header/pc";

const NetworkMainPc: React.FC<any> = ({ statusShow, handleChangeNetwork }) => {
  const { t } = useTranslation();

  return (
    <IonPage className="ion-no-padding">
      <HeaderMainPc title={t("settings.justNetwork")} link="/wallet/settings/init" />

      <IonContent className="bg-base ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol size="6">
              <IonCard class="ion-no-padding m-0">
                <IonCardContent>
                  <IonList className="backup-list px-4">
                    <IonItem className="pb-[15px]">
                      <IonLabel onClick={() => handleChangeNetwork(false)} className="cursor-pointer">
                        {t("settings.hideTestnets")}
                      </IonLabel>
                      {statusShow === false && <IonIcon src={checkmarkOutline} className="text-2xl pr-1" />}
                    </IonItem>

                    <IonItem className="pb-[15px]">
                      <IonLabel onClick={() => handleChangeNetwork(true)} className="cursor-pointer">
                        {t("settings.showTestnets")}
                      </IonLabel>
                      {statusShow === true && <IonIcon src={checkmarkOutline} className="text-2xl pr-1" />}
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default NetworkMainPc;
