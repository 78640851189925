// @ts-nocheck
import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonInput, IonLabel, IonPage, IonRow } from "@ionic/react";
import { ConfirmNftMainProps } from "features/transfer/confirmNft/types";
import { useTranslation } from "react-i18next";
import ContactBar from "features/transfer/components/ContactBar";
import GasModal from "features/transfer/components/GasModal";
import HeaderMainPc from "components/header/pc";

const ConfirmNftMainPc = (props: ConfirmNftMainProps) => {
  const { t } = useTranslation();

  return (
    <IonPage className="p-0">
      <HeaderMainPc title={t("transfer.confirmTransferTitle")} />

      <IonContent className="bg-base p-0">
        <IonGrid>
          <IonRow>
            <IonCol sizeLg="6" sizeXl="6" sizeMd="4" sizeSm="4" sizeXs="12" className="p-0">
              <IonCard>
                <IonCardContent>
                  <ContactBar checkType={props.checkType} />
                  {props.checkType > 0 && (
                    <div className="pb-4">
                      <IonLabel position="stacked">{t("transfer.amountToSend")}</IonLabel>
                      <div className="flex flex-row justify-between items-center py-1 px-4 my-2.5 rounded-custom border border-[#d3d3d3]">
                        <IonInput
                          type="text"
                          value={props.amountToSend}
                          onKeyPress={(e) => !/^[\d]+/.test(e.key) && e.preventDefault()}
                          onIonChange={(e) => props.amountChanged(e)}
                        />
                      </div>
                    </div>
                  )}

                  <div>
                    <IonButton
                      expand="block"
                      className="h-12"
                      color={props.isConfirmDisabled ? "medium" : "primary"}
                      onClick={props.proceedTransfer}
                      disabled={props.isConfirmDisabled}>
                      {t("transfer.proceed")}
                    </IonButton>
                  </div>
                  <GasModal checkType={props.checkType} />
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ConfirmNftMainPc;
