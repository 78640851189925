import { post } from "@toruslabs/http-helpers";
import { store } from "shared/store";
import olUserModuleSlice from "shared/store/olUserModuleSlice";
import { AuthServicePostResponse } from "shared/utils/openlogininterface";
import { isMatch, pickBy } from "lodash-es";
import { getUserInfo, updateUserInfo, verifyMessageAndRegisterUser } from "shared/utils/openloginmutation";
import { Device, UpdateUserPayload, User, UserDapp } from "shared/utils/__generated__/graphql-types";
import { fetchUserInfoQuery } from "shared/utils/__generated__/user";

export const olUserModuleAction = olUserModuleSlice.actions;

export const authenticateUserToken = async (params: {
  network: string;
  public_address: string;
  private_key: string;
  verifier: string;
  verifier_id: string;
  session_nonce: string;
  device_id: string;
  client_id: string;
  hostname: string;
  user_type: string;
}): Promise<{
  user: User;
  userDapp: UserDapp;
  device: Device;
  idToken: string;
  clientTimeOffset: number;
  messageForSign: string;
} | null> => {
  const { private_key, device_id, client_id, public_address, verifier, verifier_id, network, session_nonce, hostname, user_type } = params;
  const res = await verifyMessageAndRegisterUser(
    {
      public_address,
      verifier_id,
      session_nonce,
      device_id,
      client_id,
      user_type,
      verifier,
      network,
      hostname
    },
    private_key
  );
  const { clientTimeOffset, idToken, messageForSign } = res || {};
  if (clientTimeOffset !== undefined) store.dispatch(olUserModuleAction.setClientTimeOffset({ offset: clientTimeOffset }));
  if (idToken) {
    store.dispatch(olUserModuleAction.setAuthToken({ authToken: idToken }));
  }
  if (messageForSign) {
    store.dispatch(olUserModuleAction.setChallenge({ challange: messageForSign }));
  }
  return res || null;
};

export const fetchUserInfo = async (): Promise<fetchUserInfoQuery["info"] | null> => {
  const authToken = store.getState().olUserModule.authToken;
  if (authToken) {
    store.dispatch(olUserModuleAction.setAuthToken({ authToken }));
    const userDBInfo = await getUserInfo();
    if (userDBInfo) {
      const { always_skip_tkey } = userDBInfo;
      if (always_skip_tkey) store.dispatch(olUserModuleAction.setAlwaysSkip({ alwaysSkip: always_skip_tkey }));
      store.dispatch(olUserModuleAction.setPersistedUserInfo(userDBInfo));
    }
    return userDBInfo;
  }
  return null;
};
export const updateUserPersistedInfo = async (params: { payload: UpdateUserPayload; throwError?: boolean }) => {
  const { payload, throwError } = params;
  const finalPayload = pickBy(payload, (val) => val !== null && val !== undefined && val !== "");
  const infoAlreadyExist = isMatch(store.getState().olUserModule.persistedUserInfo, finalPayload);
  if (!infoAlreadyExist) {
    try {
      await updateUserInfo(payload);
      store.dispatch(olUserModuleAction.setPersistedUserInfo({ ...payload }));
    } catch (error) {
      if (throwError) throw error;
    }
  }
};

export const postV2UpbondAccount = async (data: {
  provider: string;
  is_wallet_registered: boolean;
  wallet_version: string;
  wallet_registered_at: Date;
}): Promise<{ success: boolean; data: AuthServicePostResponse | null; stack: any }> => {
  try {
    const v2Acc = await post<AuthServicePostResponse>(`${process.env.REACT_APP_LOGIN_UPBOND_DOMAIN}/customer-wallet`, data, {
      headers: {
        Authorization: `Bearer ${store.getState().olUserModule.authServiceInfo.accessToken}`
      }
    });
    return {
      success: true,
      data: v2Acc as AuthServicePostResponse,
      stack: null
    };
  } catch (error) {
    return {
      data: null,
      stack: error,
      success: false
    };
  }
};
