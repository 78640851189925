import { TKey } from "@tkey/core";
import { SecurityQuestionsModule } from "@tkey/security-questions";
import { ServiceProviderBase } from "@tkey/service-provider-base";
import { ShareSerializationModule } from "@tkey/share-serialization";
import { ShareTransferModule } from "@tkey/share-transfer";
import { TorusStorageLayer } from "@tkey/storage-layer-torus";
import { WebStorageModule } from "@tkey/web-storage";
import BN from "bn.js";
import log from "loglevel";

export type TkeyInputParams = {
  postboxKey: string;
  importKey?: string;
  tKeyJson?: StringifiedType;
  shareStores: ShareStore[];
  serverTimeOffset?: number;
  dappShare?: string;
};

import config from "./openloginconfig";
import {
  SECURITY_QUESTIONS_MODULE_KEY,
  SHARE_SERIALIZATION_MODULE_KEY,
  SHARE_TRANSFER_MODULE_KEY,
  TKEY_SHARE_TRANSFER_INTERVAL,
  WEB_STORAGE_MODULE_KEY
} from "./openloginenums";
import { ShareStore, StringifiedType } from "@tkey/common-types";

export default async function createTKeyInstance({
  postboxKey,
  importKey,
  tKeyJson,
  shareStores,
  serverTimeOffset = 0,
  dappShare
}: TkeyInputParams): Promise<TKey> {
  if (!postboxKey) return new TKey({ manualSync: true, serverTimeOffset });

  const modules = {
    [SECURITY_QUESTIONS_MODULE_KEY]: new SecurityQuestionsModule(true),
    [WEB_STORAGE_MODULE_KEY]: new WebStorageModule(),
    [SHARE_TRANSFER_MODULE_KEY]: new ShareTransferModule(),
    [SHARE_SERIALIZATION_MODULE_KEY]: new ShareSerializationModule()
  };
  const serviceProvider = new ServiceProviderBase({ postboxKey });
  const storageLayer = new TorusStorageLayer({ hostUrl: config.metadataHost, serverTimeOffset });
  let tKey: TKey;
  if (!tKeyJson) {
    tKey = new TKey({
      serviceProvider,
      storageLayer,
      modules,
      manualSync: true,
      serverTimeOffset
    });
    if (importKey) {
      await tKey.initialize({
        importKey: new BN(importKey, 16),
        delete1OutOf1: true
      });
    } else {
      await tKey.initialize();
    }
    if (shareStores.length > 0) {
      for (let index = 0; index < shareStores.length; index += 1) {
        const element = shareStores[index];
        try {
          // eslint-disable-next-line no-await-in-loop
          await tKey.inputShareStoreSafe(element);
        } catch (err) {
          log.warn(err);
        }
      }
    }
    if (dappShare) {
      try {
        const deserializedShare = await (tKey.modules[SHARE_SERIALIZATION_MODULE_KEY] as ShareSerializationModule).deserialize(
          dappShare,
          "mnemonic"
        );
        await tKey.inputShare(deserializedShare);
      } catch (error) {
        log.warn(error);
        // Swallow error if dapp submits invalid dapp share
      }
    }
  } else {
    try {
      tKey = await TKey.fromJSON(tKeyJson, {
        modules,
        serviceProvider,
        storageLayer,
        serverTimeOffset
      });
      if (tKeyJson.modules) {
        if (tKeyJson.modules[WEB_STORAGE_MODULE_KEY])
          (tKey.modules[WEB_STORAGE_MODULE_KEY] as WebStorageModule).canUseFileStorage =
            tKeyJson.modules[WEB_STORAGE_MODULE_KEY].canUseFileStorage;

        if (tKey.modules[SHARE_TRANSFER_MODULE_KEY])
          (tKey.modules[SHARE_TRANSFER_MODULE_KEY] as ShareTransferModule).setRequestStatusCheckInterval(TKEY_SHARE_TRANSFER_INTERVAL);
      }
    } catch (err) {
      // This will execute in various cases,
      // 1. Init fails because of 1104, 1105
      // 2. sp, sl aren't serializable
      // 3. another device updated cloudMetadata, etc.
      // In such cases, we delete the local transitions and create a new tkey object.
      // This function will fail if another device creates a new account and current device is on /register route.
      const newtKeyJSON = tKeyJson;
      newtKeyJSON.lastFetchedCloudMetadata = undefined;
      newtKeyJSON._localMetadataTransitions = [[], []];
      tKey = await TKey.fromJSON(newtKeyJSON, {
        modules,
        serviceProvider,
        storageLayer,
        serverTimeOffset
      });
    }
  }

  return tKey;
}
