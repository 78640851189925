import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction } from "@reduxjs/toolkit";
import { MUX_CHUNK } from "shared/config/mux";
import { EmbedConfiguration, EmbedTheme, MuxSubStream } from "interfaces/ui/IEmbed";
import torus from "libs/TorusExtended";
import { RootState } from "shared/store";
import { embedSlice, embedUiSlice } from "shared/store/embedSlice";
import PopupWithBcHandler from "handlers/stream/PopupBcHandler";
import PopupHandler from "handlers/stream/PopupHandler";
import config from "shared/config";
import { FEATURES_CONFIRM_WINDOW, FEATURES_DEFAULT_POPUP_WINDOW, MESSAGE_TYPE, TRANSACTION_TYPES } from "shared/enums";
import { getIFrameOriginObject, isMain } from "shared/utils/coreUtils";
import { fromWei, hexToUtf8 } from "web3-utils";

export const embedActions = embedSlice.actions;
export const embedUiActions = embedUiSlice.actions;
type TA<T = void, S = RootState, E = unknown> = ThunkAction<T, S, E, AnyAction>;

export const setConfiguration =
  (param: EmbedConfiguration): TA =>
  (dispatch) => {
    dispatch(embedActions.setConfiguration(param));
  };

export const setEmbedTheme =
  (param: EmbedTheme): TA =>
  (dispatch) => {
    dispatch(embedActions.setTheme(param));
  };

export const setModal =
  (active: boolean): TA =>
  (dispatch) => {
    dispatch(embedUiActions.setModalState(active));
  };

export const setEmbedConfiguration =
  ({ path }: { path: string }): TA =>
  () => {
    const basePath = process.env.REACT_APP_BASE_URL;
    const url = path.includes("tkey") ? `${basePath}${path || ""}` : `${basePath}${path || ""}`;
    const finalUrl = `${url}?integrity=true&instanceId=${torus.instanceId}`;
    const walletFrame = new PopupHandler({ url: finalUrl, features: FEATURES_DEFAULT_POPUP_WINDOW });
    walletFrame.open(true);
  };

export const showPopup =
  ({ payload, request }: { payload: { [x: string]: any }; request: { [x: string]: any } }): TA =>
  async (dispatch, getState) => {
    const isTx = payload && typeof payload === "object";
    const windowId = isTx ? payload.id : payload;
    const channelName = `torus_channel_${windowId}`;
    const finalUrl = `${config.baseRoute}confirm?instanceId=${windowId}&integrity=true&id=${windowId}`;

    const popupPayload = {
      id: windowId,
      origin: getIFrameOriginObject(),
      network: getState().wallet.networkType,
      whiteLabel: getState().embedState.embedTheme,
      selectedAddress: getState().wallet.selectedAddress,
      selectedCurrency: getState().wallet.prefs.selectedCurrency,
      networkDetails: getState().wallet.prefs.networkDetails,
      msgParams: undefined,
      currencyData: getState().wallet.prefs.currencyData
    } as any;

    if (isTx) {
      const txParameters = payload;
      txParameters.userInfo = getState().wallet.userInfo;
      popupPayload.txParams = txParameters;
      popupPayload.type = TRANSACTION_TYPES.STANDARD_TRANSACTION;
    } else {
      const { msgParams, type } = getLatestMessageParameters(getState(), payload);
      popupPayload.msgParams = { msgParams, id: windowId };
      popupPayload.type = type;
    }
    let weiBalance = 0;
    let latestGasFee = {};
    try {
      // polling might delay fetching fee or might have outdated fee, so getting latest fee.
      [weiBalance, latestGasFee] = await Promise.all([0, 0]);
    } catch (error) {
      console.error(`@ -> error on showPopup (catch): `, error);
      handleDeny(windowId, popupPayload.type);
      return;
    }

    popupPayload.balance = fromWei(weiBalance.toString());
    popupPayload.gasFees = latestGasFee;
    if (request.isWalletConnectRequest && isMain) {
      const originObj = { href: "", hostname: "" };
      try {
        const peerMetaURL = new URL(getState().walletConnect.data?.legacyProposal?.params[0].peerMeta.url || "");
        originObj.href = peerMetaURL.href;
        originObj.hostname = peerMetaURL.hostname;
      } catch (error) {
        console.error(`@ -> error on showPopup (catch): `, error);
      }
      popupPayload.origin = originObj;
    } else if (isMain) {
      handleConfirm(getState(), { data: { txType: popupPayload.type, id: popupPayload.id } });
    } else if (popupPayload.type === MESSAGE_TYPE.ETH_SIGN && isCustomSignedMessage(popupPayload.msgParams.msgParams)) {
      handleConfirm(getState(), { data: { txType: popupPayload.type, id: popupPayload.id } });
    } else {
      try {
        const communicationMux = torus.communicationMux;
        if (communicationMux) {
          // eslint-disable-next-line prettier/prettier
          const getCommunicationMux = (type: (typeof MUX_CHUNK)[keyof typeof MUX_CHUNK]) =>
            communicationMux.getStream(type) as MuxSubStream;
          const widgetStream = getCommunicationMux(MUX_CHUNK.WIDGETSTREAM);
          widgetStream.write({
            data: true
          });
        }

        const confirmWindow = new PopupWithBcHandler({
          url: finalUrl,
          target: "_blank",
          features: FEATURES_CONFIRM_WINDOW,
          channelName,
          preopenInstanceId: request.preopenInstanceId,
          usingIframe: true,
          topCalc: 37
        });

        await confirmWindow.handleWithHandshake({
          payload: popupPayload,
          async successExtraFn(result) {
            const { approve = false } = result;
            if (approve) {
              handleConfirm(getState(), { data: result });
              confirmWindow.close();
              await confirmWindow.bc.close();

              return;
            } else {
              handleDeny(popupPayload.id, popupPayload.type);
              confirmWindow.close();
              await confirmWindow.bc.close();

              return;
            }
          }
        });
      } catch (error: any) {
        console.error(`@ -> error on showPopup (catch): `, error);
        console.error("@error", error);
      }
    }
  };

function isCustomSignedMessage(messageParameters: any) {
  const { origin, customPrefix } = messageParameters;
  if (origin && customPrefix === `\u0019${origin} Signed Message:\n`) return true;
  return false;
}

function handleConfirm(state: any, ev: any) {
  const { upbondController } = torus;
  if (upbondController)
    switch (ev.data.txType) {
      case MESSAGE_TYPE.PERSONAL_SIGN: {
        const { msgParams } = state.wallet.prefs.unapprovedPersonalMsgs[ev.data.id];
        const metamaskId = Number.parseInt(ev.data.id, 10);
        const newMsgParams = { ...msgParams, metamaskId };
        upbondController.signPersonalMessage(newMsgParams);

        break;
      }
      case MESSAGE_TYPE.ETH_SIGN: {
        const { msgParams } = state.wallet.prefs.unapprovedMsgs[ev.data.id];
        const metamaskId = Number.parseInt(ev.data.id, 10);
        const newMsgParams = { ...msgParams, metamaskId };
        upbondController.signMessage(newMsgParams);

        break;
      }
      case MESSAGE_TYPE.ETH_SIGN_TYPED_DATA: {
        const { msgParams } = state.wallet.prefs.unapprovedTypedMessages[ev.data.id];
        const metamaskId = Number.parseInt(ev.data.id, 10);
        const newMsgParams = { ...msgParams, metamaskId };
        upbondController.signTypedMessage(newMsgParams);

        break;
      }
      case MESSAGE_TYPE.ETH_GET_ENCRYPTION_PUBLIC_KEY: {
        const msgParams = state.wallet.prefs.unapprovedEncryptionPublicKeyMsgs[ev.data.id];
        const metamaskId = Number.parseInt(ev.data.id, 10);
        const newMsgParams = { ...msgParams, metamaskId };
        upbondController.signEncryptionPublicKey(newMsgParams);

        break;
      }
      case MESSAGE_TYPE.ETH_DECRYPT: {
        const { msgParams } = state.wallet.prefs.unapprovedDecryptMsgs[ev.data.id];
        const metamaskId = Number.parseInt(ev.data.id, 10);
        const newMsgParams = { ...msgParams, metamaskId };
        upbondController.signEthDecrypt(newMsgParams);

        break;
      }
      case TRANSACTION_TYPES.STANDARD_TRANSACTION: {
        const unApprovedTransactions = [];
        for (const id in state.wallet.transactions) {
          if (state.wallet.transactions[id].status === "unapproved") {
            unApprovedTransactions.push(state.wallet.transactions[id]);
          }
        }

        let txMeta = unApprovedTransactions.find((x: any) => x.id === ev.data.id);

        if (ev.data.gasPrice || (ev.data.maxPriorityFeePerGas && ev.data.maxFeePerGas) || ev.data.gas || ev.data.customNonceValue) {
          const txMetaStringify = JSON.stringify(txMeta) || "";
          const newTxMeta: any = txMetaStringify
            ? JSON.parse(txMetaStringify)
            : {
                txParams: {}
              };
          if (ev.data.maxPriorityFeePerGas && ev.data.maxFeePerGas) {
            newTxMeta.txParams.maxPriorityFeePerGas = ev.data.maxPriorityFeePerGas;
            newTxMeta.txParams.maxFeePerGas = ev.data.maxFeePerGas;
          } else if (ev.data.gasPrice) {
            delete newTxMeta.txParams.maxPriorityFeePerGas;
            delete newTxMeta.txParams.maxFeePerGas;
            newTxMeta.txParams.gasPrice = ev.data.gasPrice;
          }
          if (ev.data.txEnvelopeType) {
            newTxMeta.txParams.type = ev.data.txEnvelopeType;
          }
          if (ev.data.dynamicParams) {
            newTxMeta.txParams.dynamicParams = ev.data.dynamicParams;
          }
          if (ev.data.gas) {
            newTxMeta.txParams.gas = ev.data.gas;
          }
          if (ev.data.customNonceValue) {
            newTxMeta.txParams.customNonceValue = ev.data.customNonceValue;
          }
          upbondController.txController.updateTransaction(newTxMeta);
          txMeta = newTxMeta;
        }
        upbondController.updateAndApproveTransaction(txMeta);

        break;
      }
      default: {
        throw new Error("No new transactions.");
      }
    }
}

function handleDeny(id: any, txType: any) {
  const { upbondController } = torus;
  if (upbondController)
    switch (txType) {
      case MESSAGE_TYPE.PERSONAL_SIGN: {
        upbondController.cancelPersonalMessage(Number.parseInt(id, 10));

        break;
      }
      case MESSAGE_TYPE.ETH_SIGN: {
        upbondController.cancelMessage(Number.parseInt(id, 10));

        break;
      }
      case MESSAGE_TYPE.ETH_SIGN_TYPED_DATA: {
        upbondController.cancelTypedMessage(Number.parseInt(id, 10));

        break;
      }
      case TRANSACTION_TYPES.STANDARD_TRANSACTION: {
        upbondController.cancelTransaction(Number.parseInt(id, 10));
        break;
      }
      case MESSAGE_TYPE.ETH_GET_ENCRYPTION_PUBLIC_KEY: {
        upbondController.cancelEncryptionPublicKey(Number.parseInt(id, 10));

        break;
      }
      case MESSAGE_TYPE.ETH_DECRYPT: {
        upbondController.cancelDecryptMessage(Number.parseInt(id, 10));

        break;
      }
      // No default
    }
}

function getLatestMessageParameters(state: RootState, id: any) {
  let message = null;
  let type = "";

  if (state.wallet.prefs.unapprovedMsgs[id]) {
    message = state.wallet.prefs.unapprovedMsgs[id];
    type = MESSAGE_TYPE.ETH_SIGN;
  } else if (state.wallet.prefs.unapprovedPersonalMsgs[id]) {
    message = state.wallet.prefs.unapprovedPersonalMsgs[id];
    type = MESSAGE_TYPE.PERSONAL_SIGN;
  }

  // handle hex-based messages and convert to text
  if (message) {
    let finalMessage;
    try {
      finalMessage = hexToUtf8(message.msgParams.data);
    } catch {
      finalMessage = message.msgParams.data;
    }
    message = {
      ...message,
      msgParams: {
        ...message.msgParams,
        message: finalMessage
      }
    };
  }

  if (state.wallet.prefs.unapprovedTypedMessages[id]) {
    message = state.wallet.prefs.unapprovedTypedMessages[id];
    message = {
      ...message,
      msgParams: {
        ...message.msgParams,
        typedMessages: message.msgParams.data
      }
    };
    type = MESSAGE_TYPE.ETH_SIGN_TYPED_DATA;
  }

  if (state.wallet.prefs.unapprovedEncryptionPublicKeyMsgs[id]) {
    message = state.wallet.prefs.unapprovedEncryptionPublicKeyMsgs[id];
    type = MESSAGE_TYPE.ETH_GET_ENCRYPTION_PUBLIC_KEY;
  }

  if (state.wallet.prefs.unapprovedDecryptMsgs[id]) {
    message = state.wallet.prefs.unapprovedDecryptMsgs[id];
    type = MESSAGE_TYPE.ETH_DECRYPT;
  }

  return message ? { msgParams: message.msgParams, id, type } : {};
}
