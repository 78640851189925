// @ts-nocheck
import { IonPage, IonRouterOutlet } from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import SelectTransferPage from "pages/transfer/assets";
import ConfirmTransferPage from "pages/transfer/confirm";
import ConfirmTransferNftPage from "pages/transfer/confirmNft";
import ConfirmTransferTokenPage from "pages/transfer/confirmToken";
import SuccessPage from "pages/success";

const TransferRoutes = () => (
  <IonPage>
    <IonRouterOutlet>
      <Route exact path="/transfer">
        <Redirect to="/transfer/assets" />
      </Route>
      <Route path="/transfer/assets" component={SelectTransferPage} />
      <Route path="/transfer/confirm" component={ConfirmTransferPage} />
      <Route path="/transfer/confirm-nft" component={ConfirmTransferNftPage} />
      <Route path="/transfer/confirm-token" component={ConfirmTransferTokenPage} />
      <Route path="/transfer/success" component={SuccessPage} />
      <Route exact path="/wallet/tf">
        <Redirect to="/wallet/tf/assets" />
      </Route>
      <Route path="/wallet/tf/assets" component={SelectTransferPage} />
      <Route path="/wallet/tf/confirm" component={ConfirmTransferPage} />
      <Route path="/wallet/tf/confirm-nft" component={ConfirmTransferNftPage} />
      <Route path="/wallet/tf/confirm-token" component={ConfirmTransferTokenPage} />
      <Route path="/wallet/tf/success" component={SuccessPage} />
    </IonRouterOutlet>
  </IonPage>
);

export default TransferRoutes;
