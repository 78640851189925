import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ArrState, State } from "interfaces/actions/IWalletConnect";

const wcArrSlice = createSlice({
  name: "wcarrdata",
  initialState: {
    relayerRegionURL: "",
    dataArr: [],
    countArr: 0
  } as ArrState,
  reducers: {
    setRelayerRegion(state, action) {
      state.relayerRegionURL = action.payload;
    },
    setDataArrWc(state, action: PayloadAction<State[]>) {
      state.dataArr = action.payload;
    },
    resetDataArrWc(state) {
      state.dataArr = [];
    },
    setCountArr(state, action) {
      state.countArr = action.payload;
    }
  }
});

export default wcArrSlice;
