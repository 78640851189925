// @ts-nocheck
import { IonAlert, IonButton, IonCol, IonGrid, IonList, IonLoading, IonRow } from "@ionic/react";
import { SessionTypes } from "@walletconnect/types";
import { getSdkError } from "@walletconnect/utils";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { IChains } from "interfaces/actions/IToken";
import { IModalCloseWalletConnect } from "interfaces/actions/IWalletConnect";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchClose, walletConnectArrAction } from "shared/actions/walletConnectAction";
import { listChain } from "shared/config/chains";
import { walletConnectAction } from "shared/utils/walletconnect/LegactWalletConnectUtil";
import { web3wallet } from "shared/utils/walletconnect/WalletConnectUtil";
import "./styles/index.css";

export default function SessionProposalModal(props: IModalCloseWalletConnect) {
  const { closeTheModalWalletConnect } = props;
  const dispatch = useAppDispatch();
  const walletConnect = useAppSelector((state) => state.walletConnect);
  const wallet = useAppSelector((state) => state.wallet);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [openErrorModal, setOpenError] = useState(false);

  const proposal = walletConnect.data?.proposal;
  const { id, params } = proposal as any;
  const { proposer, requiredNamespaces, relays, optionalNamespaces } = params;
  // Get required proposal data
  // Hanlde approve action, construct session namespace
  async function onApprove() {
    if (proposal) {
      const namespaces: SessionTypes.Namespaces = {};
      const accounts: string[] = [];
      const chains: string[] = [];
      const methods: string[] = ["chainChanged", "accountsChanged", "disconnect", "connect", "message"];
      const events: string[] = [
        "eth_sendTransaction",
        "personal_sign",
        "eth_signTransaction",
        "eth_sign",
        "eth_signTypedData",
        "wallet_switchEthereumChain"
      ];

      const required = Object.keys(requiredNamespaces);
      if (required.length > 0) {
        required.forEach((key) => {
          requiredNamespaces[key].chains?.map((chain: string) => {
            chains.push(chain);
            accounts.push(`${chain}:${wallet.selectedAddress}`);
          });
          listChain.map((chain: IChains) => {
            chains.push(`eip155:${chain.chain}`);
            accounts.push(`eip155:${chain.chain}:${wallet.selectedAddress}`);
          });
          requiredNamespaces[key].methods?.map((method: string) => methods.push(method));
          requiredNamespaces[key].events?.map((event: string) => events.push(event));
          namespaces[key] = {
            accounts,
            chains,
            methods,
            events
          };
        });
      }

      Object.keys(optionalNamespaces).forEach((key) => {
        optionalNamespaces[key].chains?.map((chain: string) => {
          chains.push(chain);
          accounts.push(`${chain}:${wallet.selectedAddress}`);
        });
        optionalNamespaces[key].methods?.map((method: string) => methods.push(method));
        optionalNamespaces[key].events?.map((event: string) => events.push(event));
        namespaces[key] = {
          accounts,
          chains,
          methods,
          events
        };
      });

      listChain.map((chain: IChains) => {
        chains.push(`eip155:${chain.chain}`);
        accounts.push(`eip155:${chain.chain}:${wallet.selectedAddress}`);
      });
      namespaces["eip155"] = {
        accounts,
        chains,
        methods,
        events
      };

      console.log("@namespaces", namespaces);
      await web3wallet.approveSession({
        id,
        relayProtocol: relays[0].protocol,
        namespaces
      });
      dispatch(walletConnectAction.setConnect(true));
    }
    closeTheModalWalletConnect(false);
    dispatch(walletConnectArrAction.resetDataArrWc());
    dispatch(walletConnectAction.setOpen({ open: false, view: {} as any, data: {} }));
    dispatch(fetchClose());
  }

  // Handle reject action
  const onReject = async () => {
    if (proposal) {
      await web3wallet.rejectSession({
        id,
        reason: getSdkError("USER_REJECTED_METHODS")
      });
      closeTheModalWalletConnect(false);
      dispatch(walletConnectArrAction.resetDataArrWc());
      dispatch(walletConnectAction.setOpen({ open: false, view: {} as any, data: {} }));
      dispatch(fetchClose());
    }
  };

  const closeErrorModal = async () => {
    await web3wallet.rejectSession({
      id,
      reason: getSdkError("USER_REJECTED_METHODS")
    });
    dispatch(walletConnectAction.setOpen({ open: false, view: {} as any, data: {} }));
    setOpenError(false);
    setErrorMsg("");
  };
  const closeErrorProposal = () => {
    closeTheModalWalletConnect(false);
    dispatch(walletConnectArrAction.resetDataArrWc());
    dispatch(walletConnectAction.setOpen({ open: false, view: {} as any, data: {} }));
    dispatch(fetchClose());
  };
  return (
    <>
      {loading ? (
        <IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} duration={2000} />
      ) : (
        <>
          {openErrorModal && (
            <IonAlert isOpen={openErrorModal} onDidDismiss={closeErrorModal} message={errorMsg} buttons={[`${t("account.close")}`]} />
          )}
          {proposer && !loading ? (
            <IonGrid id="legacySessionProposalModalGrid" className="font-roboto">
              <p className="text-center font-semibold text-base leading-[19px] text-[#0a0a0a] my-4 font-roboto">
                {t("sign_req_modal.title")}
              </p>
              <IonList lines="full">
                <IonRow className="ion-justify-content-around">
                  <>
                    <IonCol size="5">{t("notification.requestFrom")}</IonCol>
                    <IonCol size="5" style={{ fontWeight: "bold" }}>
                      {proposer && proposer.metadata.url.replace(/(^\w+:|^)\/\//, "")}
                    </IonCol>
                  </>
                </IonRow>
              </IonList>
              <IonRow className="ion-justify-content-around">
                <IonCol size="5">
                  <IonButton shape="round" className="button-reject-session-proposal" onClick={() => onReject()}>
                    {t("notification.reject")}
                  </IonButton>
                </IonCol>
                <IonCol size="5">
                  <IonButton shape="round" color="primary" onClick={() => onApprove()}>
                    {t("settings.connect")}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <IonGrid className="disconnect-modal text-center font-roboto">
              <div>
                {/* <h1 className="text-base font-semibold my-4">{t("settings.connectionFailed")}</h1> */}
                <p className="font-normal text-sm leading-4 my-4">{t("settings.networkError")}.</p>
                <IonList className="disconnect-modal-button-list" lines="none">
                  <IonButton
                    expand="block"
                    className="disconnect-close font-medium text-[15px] leading-[18px] text-[#4d4082] m-auto flex mb-5 w-[98%]"
                    onClick={() => closeErrorProposal()}>
                    {t("settings.close")}
                  </IonButton>
                </IonList>
              </div>
            </IonGrid>
          )}
        </>
      )}
    </>
  );
}
