import LegacySignClient from "@walletconnect/client";
import { IWalletConnectSession } from "@walletconnect/legacy-types";
import { EIP155_SIGNING_METHODS } from "shared/config/eip155WC";
import { State } from "interfaces/actions/IWalletConnect";
import { fetchArrWalletConnect } from "shared/actions/walletConnectAction";
import walletConnectSlice from "shared/store/walletConnectSlice";
export let legacySignClient: LegacySignClient;
export const walletConnectAction = walletConnectSlice.actions;

export function createLegacySignClient(dispatch: any, { uri }: { uri?: string } = {}) {
  // If URI is passed always create a new session,
  // otherwise fall back to cached session if client isn't already instantiated.
  if (uri) {
    deleteCachedLegacySession();
    legacySignClient = new LegacySignClient({ uri });
  } else if (!legacySignClient && getCachedLegacySession()) {
    const session = getCachedLegacySession();
    legacySignClient = new LegacySignClient({ session });
  } else {
    return;
  }

  legacySignClient.on("session_request", (error, payload) => {
    if (error) {
      throw new Error(`legacySignClient > session_request failed: ${error}`);
    }
    dispatch(walletConnectAction.setOpen({ open: true, view: "LegacySessionProposalModal", data: { legacyProposal: payload } }));
  });

  legacySignClient.on("connect", () => {
    dispatch(walletConnectAction.setConnect(true));
  });

  legacySignClient.on("error", (error) => {
    throw new Error(`legacySignClient > on error: ${error}`);
  });

  legacySignClient.on("call_request", (error, payload) => {
    if (error) {
      throw new Error(`legacySignClient > call_request failed: ${error}`);
    }
    onCallRequest(dispatch, payload);
  });

  legacySignClient.on("disconnect", async () => {
    dispatch(walletConnectAction.setOpen({ open: false, view: {} as any, data: {}, connected: false }));
    dispatch(walletConnectAction.setConnect(false));
    legacySignClient.killSession();
    deleteCachedLegacySession();
  });
}

const multipleDispatch = (dispatch: any, dataDispatch: State) => {
  dispatch(fetchArrWalletConnect(dataDispatch));
  dispatch(walletConnectAction.setOpen(dataDispatch));
};

const onCallRequest = async (dispatch: any, payload: { id: number; method: string; params: any[] }) => {
  switch (payload.method) {
    case EIP155_SIGNING_METHODS.ETH_SIGN:
    case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
      return multipleDispatch(dispatch, {
        open: true,
        view: "LegacySessionSignModal",
        data: {
          legacyCallRequestEvent: payload,
          legacyRequestSession: legacySignClient.session
        }
      });

    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4:
      return multipleDispatch(dispatch, {
        open: true,
        view: "LegacySessionSignTypedDataModal",
        data: {
          legacyCallRequestEvent: payload,
          legacyRequestSession: legacySignClient.session
        }
      });

    case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
      return multipleDispatch(dispatch, {
        open: true,
        view: "LegacySessionSendTransactionModal",
        data: {
          legacyCallRequestEvent: payload,
          legacyRequestSession: legacySignClient.session
        }
      });
    case EIP155_SIGNING_METHODS.ETH_SIGN_CHANGE_NETWORK:
      return multipleDispatch(dispatch, {
        open: true,
        view: "wallet_switchEthereumChain",
        data: {
          legacyCallRequestEvent: payload,
          legacyRequestSession: legacySignClient.session
        }
      });

    default:
      alert(`${payload.method} is not supported for WalletConnect v1`);
  }
};

function getCachedLegacySession(): IWalletConnectSession | undefined {
  if (typeof window === "undefined") return;

  const local = window.localStorage ? window.localStorage.getItem("walletconnect") : null;

  let session = null;
  if (local) {
    // eslint-disable-next-line no-useless-catch
    try {
      session = JSON.parse(local);
    } catch (error) {
      throw error;
    }
  }
  return session;
}

export function deleteCachedLegacySession() {
  if (typeof window === "undefined") return;
  window.localStorage.removeItem("walletconnect");
}
