import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactScanner } from "../../interfaces/actions/IContact";

const initScanner: ContactScanner = {
  scanResult: ""
};

const scannerSlice = createSlice({
  name: "scanner",
  initialState: initScanner,
  reducers: {
    addFromScanner(state, action: PayloadAction<ContactScanner>) {
      state.scanResult = action.payload.scanResult;
    },
    removeFromScanner(state) {
      state.scanResult = "";
    }
  }
});

export default scannerSlice;
