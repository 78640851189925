import {
  BalanceResponse,
  OnNftBalanceResponse,
  ItemMetadata,
  DefaultBalanceAstarSubscan,
  SubscanBalanceResponse,
  BluezNftMetadataResponse
} from "interfaces/actions/IToken";
import httpServices from "services/HttpServices";
import { catchError } from "shared/utils/coreUtils";
import { filterGateway } from "shared/utils/raceConditionIpfs";

class BalanceServices {
  /**
   *
   *
   * @param {string} chainId
   * @param {string} walletAddress
   * @return {*}  {Promise<any>}
   */
  ckey = process.env.REACT_APP_COVALENT_CKEY;
  bluezApiKey = process.env.REACT_APP_BLUEZ_API_KEY;

  async getAllBalance(chainId: string, walletAddress: string, error = false, isAstar = false): Promise<BalanceResponse | any> {
    try {
      let url;
      let response;
      if (error) {
        url = `https://api.covalenthq.com/v1/${chainId}/address/${walletAddress.toLowerCase()}/balances_v2/?quote-currency=USD&format=JSON&nft=${
          chainId !== "1" && chainId !== "137" ? true : false
        }&no-nft-fetch=false&key=${this.ckey}`;
        response = await httpServices.get<BalanceResponse>(url);
      } else {
        if (isAstar) {
          const allBalance = await this.getAstarBalance(walletAddress);
          if (!allBalance?.data?.data) {
            const newData: DefaultBalanceAstarSubscan = {
              native: [{ balance: 0 }],
              ERC20: [],
              ERC721: []
            };
            const getDataFromSubscan = await this.getAstarBalanceFromSubscan(walletAddress);
            const contractBalance: { [key: string]: string | number } = {};
            const listContracts = getDataFromSubscan?.data?.data?.map((token: any) => {
              contractBalance[token.contract] = token.balance;
              return token.contract;
            });
            if (listContracts.length) {
              const tokenDetailFromSubscan = await this.getDetailTokensFromSubscan(listContracts);
              tokenDetailFromSubscan?.data?.data?.list?.map((detail: any) => {
                if (detail?.category === "erc20") {
                  newData.ERC20.push({
                    balance: contractBalance[detail?.contract],
                    contract: detail?.contract,
                    decimals: detail?.decimals,
                    unique_id: detail?.name,
                    symbol: detail?.symbol
                  });
                }
                if (detail?.category === "erc721") {
                  newData.ERC721.push({
                    balance: contractBalance[detail?.contract],
                    contract: detail?.contract,
                    decimals: detail?.decimals,
                    unique_id: detail?.name,
                    symbol: detail?.symbol
                  });
                }
              });
            }
            allBalance.data.data = newData;
          }
          const arrItems: any[] = [];
          allBalance?.data?.data?.native?.map((ele: SubscanBalanceResponse) => {
            arrItems.push({
              balance: ele?.balance,
              balance_24h: "",
              chain_id: "592",
              contract_address: "0x1326bf7d66858662b0897f500c45f55e8d0691ab",
              contract_decimal: 18,
              contract_name: "Astar",
              contract_ticker_symbol: "ASTR",
              last_transfered_at: "",
              logo_url: "",
              native_token: true,
              nft_data: [],
              quote: 0,
              quote_24h: 0,
              quote_rate: 0,
              quote_rate_24h: 0,
              supports_erc: null,
              testnet: false,
              type: "cryptocurrency"
            });
          });
          allBalance?.data?.data?.ERC20?.map((ele: SubscanBalanceResponse) => {
            arrItems.push({
              balance: ele?.balance,
              balance_24h: "",
              chain_id: "592",
              contract_address: ele?.contract,
              contract_decimal: ele?.decimals,
              contract_name: ele?.unique_id,
              contract_ticker_symbol: ele?.symbol,
              last_transfered_at: "",
              logo_url: "",
              native_token: false,
              nft_data: [],
              quote: 0,
              quote_24h: 0,
              quote_rate: 0,
              quote_rate_24h: 0,
              supports_erc: ["erc20"],
              testnet: false,
              type: "cryptocurrency"
            });
          });

          let flag = false;
          let pageCounter = 1;
          const data: BluezNftMetadataResponse[] = [];

          while (!flag) {
            const getNftMetadata = await httpServices.get(
              `https://api.bluez.app/api/nft/v3/${this.bluezApiKey}/getNFTsForOwner?owner=${walletAddress}&orderBy=tokenId&pageKey=${pageCounter}&pageSize=100`
            );
            data.push(...getNftMetadata.data.items);

            if (pageCounter < getNftMetadata?.data?.pages) {
              pageCounter += 1;
            } else {
              flag = true;
            }
          }
          const itemMetadata: ItemMetadata = {};
          if (allBalance?.data?.data?.ERC721?.length) {
            for (const [_, item] of allBalance.data.data.ERC721.entries()) {
              itemMetadata[item?.contract] = {
                decimals: item?.decimals,
                unique_id: item?.unique_id,
                symbol: item?.symbol
              };
            }
          }
          let currentNftData: any[] = [];
          if (data?.length) {
            for (const ele of data) {
              currentNftData = [];
              let imageUrl = "";
              if (ele?.image.includes("ipfs://")) {
                const arrUrl = await filterGateway(ele?.image);
                arrUrl.length ? (imageUrl = arrUrl[0]) : ele?.image;
              } else {
                imageUrl = ele?.image;
              }
              currentNftData.push({
                burned: null,
                external_data: {
                  name: ele?.name,
                  description: ele?.description,
                  image: imageUrl,
                  image_256: "",
                  image_512: ""
                },
                original_owner: "",
                owner: null,
                owner_address: ele?.ownerAddress,
                supports_erc: ["erc20", ele.tokenType.toLowerCase()],
                token_balance: "1",
                token_id: ele?.tokenId,
                token_price_wei: null,
                token_quote_rate_eth: null,
                token_url: ele?.tokenUri
              });
              arrItems.push({
                balance: ele?.totalSupply,
                balance_24h: "",
                chain_id: "592",
                contract_address: ele?.contractAddress,
                contract_decimal: itemMetadata[ele?.contractAddress]?.decimals ?? 0,
                contract_name: itemMetadata[ele?.contractAddress]?.unique_id ?? ele?.name,
                contract_ticker_symbol: itemMetadata[ele?.contractAddress]?.symbol ?? ele?.name,
                last_transfered_at: "",
                logo_url: "",
                native_token: false,
                nft_data: currentNftData,
                quote: 0,
                quote_24h: 0,
                quote_rate: 0,
                quote_rate_24h: 0,
                supports_erc: ["erc20", ele.tokenType.toLowerCase()],
                testnet: false,
                type: "nft",
                nftType: ele.tokenType.toLowerCase()
              });
            }
          }
          response = {
            data: {
              data: {
                address: walletAddress,
                chain_id: chainId,
                chain_name: "Astar",
                items: arrItems,
                next_update_at: null,
                pagination: null,
                quote_currency: "USD",
                updated_at: null
              },
              error: false,
              error_code: null,
              error_message: null
            }
          };
        } else {
          url = `https://api.covalenthq.com/v1/${chainId}/address/${walletAddress.toLowerCase()}/balances_v2/?quote-currency=USD&format=JSON&nft=true&no-nft-fetch=false&key=${
            this.ckey
          }`;
          response = await httpServices.get<BalanceResponse>(url);
        }
      }
      return response.data;
    } catch (err: any) {
      catchError(err, "balance error");
      return {
        error: true,
        message: err.message
      };
    }
  }

  async getAstarBalance(address: string): Promise<any> {
    try {
      const url = `https://astar.webapi.subscan.io/api/scan/account/tokens`;
      const res = await httpServices.post<any>(
        url,
        { address },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      return res;
    } catch (err: any) {
      catchError(err, "astar native erc20 balance error");
      return {
        error: true,
        message: err.message
      };
    }
  }

  async getAstarBalanceFromSubscan(address: string): Promise<any> {
    try {
      const url = `https://astar.api.subscan.io/api/scan/evm/account/tokens`;
      const res = await httpServices.post<any>(
        url,
        { address },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      return res;
    } catch (err: any) {
      catchError(err, "astar native erc20 balance error");
      return {
        error: true,
        message: err.message
      };
    }
  }
  async getDetailTokensFromSubscan(contracts: string[]): Promise<any> {
    try {
      const url = `https://astar.api.subscan.io/api/scan/evm/tokens`;
      const res = await httpServices.post<any>(
        url,
        { contracts },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      return res;
    } catch (err: any) {
      catchError(err, "astar detail tokens error");
      return {
        error: true,
        message: err.message
      };
    }
  }

  async getNftBalance(chainId: string, walletAddress: string): Promise<any> {
    try {
      const nftUrl = `https://api.covalenthq.com/v1/${chainId}/address/${walletAddress.toLowerCase()}/balances_nft/?key=${this.ckey}`;
      const responseNft = await httpServices.get<OnNftBalanceResponse>(nftUrl);
      const obj = {
        chain: chainId
      };
      const newNft = Object.assign(responseNft.data.data, obj);
      return newNft;
    } catch (error) {
      catchError(error, "nft error");
      return null;
    }
  }

  async getALlNftMetadata(chainId: string, contractAddress: string, tokenId: string | number) {
    const nftUrl = `https://api.covalenthq.com/v1/${chainId}/tokens/${contractAddress}/nft_metadata/${tokenId}/?key=${this.ckey}`;
    const responseNft = await httpServices.get(nftUrl);
    const obj = {
      chain_id: chainId,
      newNft: true
    };
    const newNft = Object.assign(responseNft.data.data.items[0], obj);
    return newNft;
  }
}

const balanceServices = new BalanceServices();

export default balanceServices;
