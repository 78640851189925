export const MUX_CHUNK = {
  OAUTH: "oauth",
  INITSTREAM: "init_stream",
  WALLETSTREAM: "show_wallet",
  TOPUPSTREAM: "topup",
  LOGINPRIVATEKEYSTREAM: "login_with_private_key",
  WALLETCONNECTSTREAM: "wallet_connect_stream",
  PROVIDERCHANGESTREAM: "provider_change",
  LOGOUT: "logout",
  USERINFOACCESSSTREAM: "user_info_access",
  USERINFOSTREAM: "user_info",
  WIDGETSTREAM: "widget",
  WIDGETVISIBILITYSTREAM: "widget-visibility",
  WIDGETMENUVISIBILITYSTREAM: "menu-visibility",
  CONSENTSTREAM: "consent",
  AUTHSERVICESTREAM: "auth_service",
  DIDLISTENERSTREAM: "did_listener_stream",
  CONSENTDIDSTREAM: "consent_stream",
  CONSENTREQUESTSTREAM: "did_get_consent_data"
};
