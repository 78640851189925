import { ShareStore } from "@tkey/common-types";
import CustomAuth, { TorusKey } from "@toruslabs/customauth";
import MetadataStorageLayer, { encryptAndSetData, setTorusShare } from "@toruslabs/metadata-helpers";
import TorusUtils from "@toruslabs/torus.js";
import { BN } from "bn.js";
import log from "loglevel";

import { store } from "shared/store";
import config from "./openloginconfig";
import {
  GET_WALLET_KEY,
  OAUTH_USERINFO,
  SET_TORUS_SHARE,
  TKEY_LOGIN_OP,
  TKEY_REQUIRE_MORE_INPUT,
  UPDATE_USER_INFO,
  USER_REGISTERATION_OP
} from "./openloginenums";
import {
  CustomAuthResult,
  LoginConfigItem,
  LoginType,
  MFA_LEVELS,
  TorusUserInfo,
  TouchIDPreferences,
  UserType
} from "./openlogininterface";
import { checkIfTrueValue, getJoinedKey, getPublicFromPrivateKey } from "./openloginutils";

import { loginConfig, olAllDappModuleAction } from "shared/actions/olAllDappModuleAction";
import { olDeviceModuleAction } from "shared/actions/olDeviceModuleAction";
import { UpdateLoginRecord, addLoginRecord } from "shared/actions/olLoginPerfModuleAction";
import { checkIfTKeyExists, login, olTkeyModuleAction } from "shared/actions/olTkeyModuleAction";
import { olUserModuleAction, updateUserPersistedInfo } from "shared/actions/olUserModuleAction";
import { cleanupOAuth, doneAndRedirect, getCredIdList, storeUserInfoToBackend } from "./openloginauthutil";
import { olDappModuleAction } from "shared/actions/olDappModuleAction";

export function shouldUserUpgradeTo2OutOfN({ loginCount }: { publicAddress: string; loginCount: number }): "no" | "yes" {
  // TODO: We may want to return more complex value like "optional", "required", "require-password", etc
  // const storage = getStorage(LOCAL_STORAGE_KEY);
  // if (!storage) return "no";
  // const item = storage.getItem(`oneKey:${publicAddress}:isUpgrading`);

  return loginCount % 3 === 0 ? "yes" : "no";
}
export function userIgnored2FA({ publicAddress }: { publicAddress: string }): void {
  // const storage = getStorage(LOCAL_STORAGE_KEY);
  // if (!storage) return;

  // try {
  //   storage.removeItem(`oneKey:${publicAddress}:isUpgrading`);
  // } catch {
  //   // ignore
  // }
  console.log("user ignored 2fa", publicAddress);
}

async function setWalletKeyInfo(customAuthResult: CustomAuthResult, oAuthPrivateKey: string): Promise<void> {
  const currentDappModule = store.getState().olAllDappModule.dappModules[store.getState().olUserModule.currentDappClientId];
  let currentLoginConfig: LoginConfigItem;
  currentLoginConfig = loginConfig(currentDappModule.clientId).line as LoginConfigItem;
  if (loginConfig(currentDappModule.clientId)[currentDappModule.currentLoginProvider] as LoginConfigItem) {
    currentLoginConfig = loginConfig(currentDappModule.clientId)[currentDappModule.currentLoginProvider] as LoginConfigItem;
  }

  const { userInfo, metadataNonce, typeOfUser, publicAddress } = customAuthResult;
  const { state } = userInfo;
  const customAuth = new CustomAuth({
    baseUrl: window.location.origin,
    redirectPathName: "auth",
    uxMode: "redirect",
    network: config.torusNetwork,
    enableLogging: config.logLevel !== "error",
    locationReplaceOnRedirect: true,
    // v2
    metadataUrl: config.metadataHost,
    enableOneKey: true,
    // networkUrl: config.networkUrl,
    storageServerUrl: config.storageServerUrl,
    web3AuthClientId: store.getState().olUserModule.currentDappClientId || (config.openloginDappModuleKey as string)
  });

  let keyMode;
  if (typeOfUser === "v1") keyMode = "v1";
  else if (checkIfTrueValue(metadataNonce)) keyMode = "1/1";
  else keyMode = "2/n";
  if (currentDappModule.getWalletKey && currentLoginConfig.walletVerifier && typeOfUser === "v1") {
    const metadataStorage = new MetadataStorageLayer(config.metadataHost, store.getState().olUserModule.clientTimeOffset);

    if (currentLoginConfig.walletVerifier !== currentLoginConfig.verifier) {
      const operationName = GET_WALLET_KEY;
      window.performance.mark(`${operationName}_start`);
      const walletKey = await customAuth.getTorusKey(
        currentLoginConfig.walletVerifier,
        store.getState().olUserModule.userInfo.verifierId,
        {
          verifier_id: store.getState().olUserModule.userInfo.verifierId
        },
        userInfo.idToken || userInfo.accessToken
      );

      store.dispatch(olUserModuleAction.setWalletKeyInfo(walletKey as any));
    } else {
      const walletKey: TorusKey = {
        privateKey: oAuthPrivateKey,
        publicAddress,
        metadataNonce,
        typeOfUser
      };

      store.dispatch(olUserModuleAction.setWalletKeyInfo(walletKey as any));
    }

    // If you're coming post fast login failure for wallet key
    const { webAuthnPubKeyX, webAuthnPubKeyY, ref } = state || {};
    if (webAuthnPubKeyX && webAuthnPubKeyY && ref) {
      const operationName = SET_TORUS_SHARE;
      window.performance.mark(`${operationName}_start`);
      // dwi
      await setTorusShare(
        metadataStorage,
        {
          pub_key_X: webAuthnPubKeyX,
          pub_key_Y: webAuthnPubKeyY
        },
        ref,
        getJoinedKey(store.getState().olUserModule.userInfo.aggregateVerifier, store.getState().olUserModule.userInfo.verifierId),
        {
          privKey: oAuthPrivateKey,
          walletKey: store.getState().olUserModule.walletKeyInfo.privateKey,
          keyMode,
          nonce: metadataNonce
        }
      );
    }
    const operationName = UPDATE_USER_INFO;
    window.performance.mark(`${operationName}_start`);
    await updateUserPersistedInfo({
      payload: {
        wallet_public_address: store.getState().olUserModule.walletKeyInfo.publicAddress
      }
    });
  }
}

async function checkIfTkeyExists(
  customAuthResult: CustomAuthResult,
  oAuthPrivateKey: string
): Promise<{ success: boolean; shareStore: ShareStore | undefined }> {
  const metadataStorage = new MetadataStorageLayer(config.metadataHost, store.getState().olUserModule.clientTimeOffset);
  const oAuthPrivateKeyBN = new BN(oAuthPrivateKey, "hex");
  const [{ success, shareStore }] = await Promise.all([
    checkIfTKeyExists(oAuthPrivateKeyBN),
    // Store the userinfo in bucket encrypted by privateKey - OAuthPrivateKey
    encryptAndSetData(metadataStorage, oAuthPrivateKeyBN.toString(16, 64), store.getState().olUserModule.userInfo as any, OAUTH_USERINFO),
    // Check for wallet flow
    setWalletKeyInfo(customAuthResult, oAuthPrivateKeyBN.toString(16, 64))
  ]);
  const { userInfo } = customAuthResult;
  await cleanupOAuth(userInfo.typeOfLogin, userInfo.idToken || userInfo.accessToken);
  return { success, shareStore };
}

async function loginOAuthUserWithTkey(
  customAuthResult: CustomAuthResult,
  oAuthPrivateKey: string,
  shareStore: ShareStore,
  userType: UserType
): Promise<{
  canContinue: boolean;
  displayError?: string;
  tKey?: string;
  oAuthPrivateKey?: string;
  walletKey?: string;
  tKeyString?: string;
}> {
  const operationName = TKEY_LOGIN_OP;
  // start measuring perf
  window.performance.mark(`${operationName}_start`);
  // In this block, we are logging in a v1 user
  // OAuthPrivateKey would definitely be used as is.
  // If user already has a nonce, it's fine.. (it's v1 nonce)
  const { userInfo } = customAuthResult;
  const { state } = userInfo;
  const currentDappModule = store.getState().olAllDappModule.dappModules[store.getState().olUserModule.currentDappClientId];
  await getCredIdList(oAuthPrivateKey, store.getState().olUserModule.userInfo.aggregateVerifier, userInfo.verifierId);
  try {
    const keys = await login({ postboxKey: oAuthPrivateKey, shareStores: [shareStore], dappShare: currentDappModule.dappShare });

    // store login with existing device share index
    await UpdateLoginRecord({
      verifierId: store.getState().olUserModule.userInfo.verifierId,
      verifier: store.getState().olUserModule.userInfo.aggregateVerifier,
      isLoginCompleted: true,
      loginType: store.getState().olTKeyModule.keyMode as LoginType,
      mobileOrigin: ""
    });
    if (store.getState().olDeviceModule.isTouchIDRegistered) {
      store.dispatch(olDappModuleAction.setTouchIDPreference(TouchIDPreferences.ENABLED));
      store.dispatch(
        olAllDappModuleAction.setTouchIDPreference({
          clientId: store.getState().olUserModule.currentDappClientId,
          touchIDPreference: TouchIDPreferences.ENABLED
        })
      );
    } else {
      store.dispatch(olDappModuleAction.setTouchIDPreference(TouchIDPreferences.UNSET));
      store.dispatch(
        olAllDappModuleAction.setTouchIDPreference({
          clientId: store.getState().olUserModule.currentDappClientId,
          touchIDPreference: TouchIDPreferences.UNSET
        })
      );
    }
    return {
      canContinue: true,
      oAuthPrivateKey,
      tKey: keys[0].privKey,
      walletKey: userType === "v1" ? store.getState().olUserModule.walletKeyInfo.privateKey : keys[0].privKey
    };
  } catch (e: unknown) {
    // Redirect if tkey login requires more inputs
    if (e instanceof Error && e.message?.includes(TKEY_REQUIRE_MORE_INPUT)) {
      doneAndRedirect("tkey-input", {
        popupWindow: state.popupWindow,
        disableAlwaysSkip: !!state.disableAlwaysSkip,
        pid: state.pid,
        extraLoginOptions: state.extraLoginOptions || "",
        customAuthInstanceId: state?.instanceId as string
      });

      return { canContinue: false };
    }
    log.error(e);
    return { canContinue: false, displayError: (e as Error).message };
  }
}

async function redirectToRegister({
  customAuthResult,
  registrationTkey,
  oAuthPrivateKey,
  walletKey,
  isUserRegistered = false
}: {
  customAuthResult: CustomAuthResult;
  registrationTkey?: string;
  oAuthPrivateKey: string;
  walletKey: string;
  isUserRegistered?: boolean;
}): Promise<{
  canContinue: boolean;
  displayError?: string;
  tKey?: string;
  oAuthPrivateKey?: string;
  walletKey?: string;
  tKeyString?: string;
}> {
  const { userInfo } = customAuthResult;
  const { state } = userInfo;

  const currentDappModule = store.getState().olAllDappModule.dappModules[store.getState().olUserModule.currentDappClientId];
  let currentLoginConfig: LoginConfigItem;
  currentLoginConfig = loginConfig(currentDappModule.clientId).line as LoginConfigItem;
  if (loginConfig(currentDappModule.clientId)[currentDappModule.currentLoginProvider]) {
    currentLoginConfig = loginConfig(currentDappModule.clientId)[currentDappModule.currentLoginProvider] as LoginConfigItem;
  }

  // while creating tkey, user can register webauthn which on redirect, comes to above case
  if (currentDappModule.getWalletKey && currentLoginConfig.walletVerifier && isUserRegistered) {
    // if skipTkey or if the user has already been presented with tkey onboarding screen, we redirect back
    if (
      (store.getState().olDeviceModule.walletTKeyOnboardingMap[
        getJoinedKey(currentLoginConfig.walletVerifier, store.getState().olUserModule.userInfo.verifierId)
      ] &&
        store.getState().olTKeyModule.keyMode === "v1") ||
      currentDappModule.skipTKey
    ) {
      if (currentDappModule.skipTKey) {
        // store login with existing device share index
        await UpdateLoginRecord({
          verifierId: store.getState().olUserModule.userInfo.verifierId,
          verifier: store.getState().olUserModule.userInfo.aggregateVerifier,
          hasSkippedTkey: true,
          isLoginCompleted: true,
          mobileOrigin: currentDappModule.mobileOrigin
        });
      }
      return { canContinue: true, tKey: registrationTkey, oAuthPrivateKey, walletKey };
    }
  }
  if (registrationTkey) {
    store.dispatch(olTkeyModuleAction.updateState({ tKeyPrivKey: registrationTkey } as any));
  }

  store.dispatch(
    olDeviceModuleAction.setTouchIDOnboarding({
      verifier: store.getState().olUserModule.userInfo.aggregateVerifier,
      verifierId: store.getState().olUserModule.userInfo.verifierId,
      onboarding: true
    })
  );
  doneAndRedirect(isUserRegistered ? "Register" : "regis-tkey-input", {
    popupWindow: state.popupWindow,
    pid: state.pid,
    autoSelectBiometrics: "false",
    registerFlow: "true",
    customAuthInstanceId: state?.instanceId as string,
    extraLoginOptions: state.extraLoginOptions || ""
  });
  return { canContinue: false };
}

export async function loginOAuthV1User(
  customAuthResult: CustomAuthResult,
  isUpbondUserRegistered = false
): Promise<{
  canContinue: boolean;
  displayError?: string;
  tKey?: string;
  oAuthPrivateKey?: string;
  walletKey?: string;
  tKeyString?: string;
}> {
  const { privateKey, publicAddress, pubKey, metadataNonce, typeOfUser, userInfo } = customAuthResult;
  const currentDappModule: any = store.getState().olAllDappModule.dappModules[store.getState().olUserModule.currentDappClientId];

  store.dispatch(olTkeyModuleAction.updateState({ keyMode: "v1" } as any));
  // Set correct userinfo
  store.dispatch(
    olUserModuleAction.setUserInfo({
      ...userInfo,
      aggregateVerifier: (userInfo.aggregateVerifier || userInfo.verifier) ?? ""
    })
  );
  store.dispatch(olUserModuleAction.setKeyInfo({ publicAddress, privateKey, pubKey: pubKey as any, metadataNonce, typeOfUser }));

  const operationName = USER_REGISTERATION_OP;
  // start measuring perf
  window.performance.mark(`${operationName}_start`);
  await storeUserInfoToBackend({
    finalKeyInfo: store.getState().olUserModule.keyInfo as TorusKey,
    finalUserInfo: store.getState().olUserModule.userInfo as TorusUserInfo,
    typeOfUser
  });

  const storedUserInfo = store.getState().olUserModule.persistedUserInfo;

  // here we are snapshoting time taken up till now and restarting
  // perf measurement timer for same operationName for auth.
  await addLoginRecord({
    verifier: userInfo.aggregateVerifier || userInfo.verifier,
    verifierId: userInfo.verifierId,
    loginRoute: "auth",
    clientId: currentDappModule.clientId,
    dappUrl: currentDappModule.redirectUrl,
    loginType: (store.getState().olTKeyModule.keyMode as LoginType) || (typeOfUser as LoginType),
    mobileOrigin: currentDappModule.mobileOrigin,
    sessionId: currentDappModule.sessionId
  });

  const { success, shareStore } = await checkIfTkeyExists(customAuthResult, privateKey);
  if (success && shareStore && isUpbondUserRegistered) {
    const alwaysSkip = storedUserInfo?.always_skip_tkey;
    // const currentLoginConfig = currentDappModule.loginConfig[currentDappModule.currentLoginProvider];
    // if alwaysSkip is true and request is from wallet then we should not do tkey login.
    // currently always  skip option is only for wallet flow.
    if (alwaysSkip && currentDappModule.getWalletKey && store.getState().olUserModule.userInfo?.verifier) {
      return {
        canContinue: true,
        oAuthPrivateKey: privateKey,
        tKey: "",
        walletKey: store.getState().olUserModule.walletKeyInfo.privateKey
      };
    }
    return loginOAuthUserWithTkey(customAuthResult, privateKey, shareStore, "v1");
  }
  return redirectToRegister({
    customAuthResult,
    oAuthPrivateKey: privateKey,
    walletKey: store.getState().olUserModule.walletKeyInfo.privateKey,
    isUserRegistered: isUpbondUserRegistered
  });
}

export async function loginOAuthV2User(
  customAuthResult: CustomAuthResult,
  isAuthServiceRegistered?: boolean
): Promise<{
  canContinue: boolean;
  displayError?: string;
  tKey?: string;
  oAuthPrivateKey?: string;
  walletKey?: string;
  tKeyString?: string;
}> {
  // In this block, we are logging in a v2 user
  // OAuthPrivateKey may need checks to subtract nonce.
  // If user already has a nonce, we should subtract it. Else, use it as is.
  const { userInfo, typeOfUser, metadataNonce, pubKey, privateKey } = customAuthResult;
  const currentDappModule = store.getState().olAllDappModule.dappModules[store.getState().olUserModule.currentDappClientId];
  const torusUtils = new TorusUtils({
    network: config.torusNetwork,
    clientId: store.getState().olUserModule.currentDappClientId
  } as any);
  const oAuthPrivateKey = checkIfTrueValue(metadataNonce) ? torusUtils.getPostboxKeyFrom1OutOf1(privateKey, metadataNonce) : privateKey;
  store.dispatch(olTkeyModuleAction.updateState({ keyMode: checkIfTrueValue(metadataNonce) ? "1/1" : "2/n" } as any));

  // Set correct userinfo
  store.dispatch(
    olUserModuleAction.setUserInfo({
      ...userInfo,
      aggregateVerifier: (userInfo.aggregateVerifier || userInfo.verifier) ?? ""
    })
  );
  store.dispatch(
    olUserModuleAction.setKeyInfo({
      publicAddress: getPublicFromPrivateKey(oAuthPrivateKey).address,
      privateKey: oAuthPrivateKey,
      pubKey: pubKey as any,
      metadataNonce,
      typeOfUser
    })
  );

  const operationName = USER_REGISTERATION_OP;
  // start measuring perf
  window.performance.mark(`${operationName}_start`);
  await storeUserInfoToBackend({
    finalKeyInfo: store.getState().olUserModule.keyInfo as TorusKey,
    finalUserInfo: store.getState().olUserModule.userInfo as TorusUserInfo,
    typeOfUser
  });

  const { success, shareStore } = await checkIfTkeyExists(customAuthResult, oAuthPrivateKey);

  const loginCount = await addLoginRecord({
    verifier: userInfo.aggregateVerifier || userInfo.verifier,
    verifierId: userInfo.verifierId,
    loginRoute: "auth",
    clientId: currentDappModule.clientId,
    dappUrl: currentDappModule.redirectUrl,
    loginType: (store.getState().olTKeyModule.keyMode as LoginType) || (typeOfUser as LoginType),
    fetchLoginCount: !success, // if user is not upgraded thn fetch login count
    mobileOrigin: currentDappModule.mobileOrigin,
    sessionId: currentDappModule.sessionId
  });

  // const loginCount = 0;

  if (success && shareStore) {
    return loginOAuthUserWithTkey(customAuthResult, oAuthPrivateKey, shareStore, "v2");
  }
  // tkey doesn't exits for v2 user so reset key mode as '1/1'
  store.dispatch(olTkeyModuleAction.updateState({ keyMode: "1/1" } as any));
  const oneKeyRuleResult = shouldUserUpgradeTo2OutOfN({
    publicAddress: store.getState().olUserModule.keyInfo.publicAddress,
    loginCount
  } as any);

  if (currentDappModule.mfaLevel === MFA_LEVELS.DEFAULT) {
    if (oneKeyRuleResult === "no" && isAuthServiceRegistered) {
      store.dispatch(
        olTkeyModuleAction.updateState({
          tKeyPrivKey: privateKey
        } as any)
      );

      await UpdateLoginRecord({
        verifierId: store.getState().olUserModule.userInfo.verifierId,
        verifier: store.getState().olUserModule.userInfo.aggregateVerifier,
        isLoginCompleted: true,
        mobileOrigin: currentDappModule.mobileOrigin
      });
      // wallet key is tkey in case of v2 because we want one key to be used by user always in v2 flow.
      return { canContinue: true, tKey: privateKey, oAuthPrivateKey, walletKey: privateKey };
    }
    if (oneKeyRuleResult === "yes") {
      // wallet key is tkey in case of v2 because we want one key to be used by user always in v2 flow.
      if (!isAuthServiceRegistered) {
        return redirectToRegister({
          customAuthResult,
          registrationTkey: privateKey,
          oAuthPrivateKey,
          walletKey: privateKey,
          isUserRegistered: isAuthServiceRegistered
        });
      }
      return {
        canContinue: true,
        oAuthPrivateKey,
        tKey: privateKey,
        walletKey: privateKey
      };
    }
    if (!isAuthServiceRegistered) {
      store.dispatch(
        olTkeyModuleAction.updateState({
          tKeyPrivKey: privateKey
        } as any)
      );
      await UpdateLoginRecord({
        verifierId: store.getState().olUserModule.userInfo.verifierId,
        verifier: store.getState().olUserModule.userInfo.aggregateVerifier,
        isLoginCompleted: true,
        mobileOrigin: currentDappModule.mobileOrigin
      });
      return redirectToRegister({
        customAuthResult,
        registrationTkey: privateKey,
        oAuthPrivateKey,
        walletKey: privateKey,
        isUserRegistered: isAuthServiceRegistered
      });
    }
  } else if (currentDappModule.mfaLevel === MFA_LEVELS.OPTIONAL || currentDappModule.mfaLevel === MFA_LEVELS.MANDATORY) {
    // wallet key is tkey in case of v2 because we want one key to be used by user always in v2 flow.
    if (isAuthServiceRegistered) {
      return {
        canContinue: true,
        oAuthPrivateKey,
        tKey: privateKey,
        walletKey: privateKey
      };
    }
    return redirectToRegister({
      customAuthResult,
      registrationTkey: privateKey,
      oAuthPrivateKey,
      walletKey: privateKey,
      isUserRegistered: isAuthServiceRegistered
    });
  } else if (currentDappModule.mfaLevel === MFA_LEVELS.NONE) {
    if (!isAuthServiceRegistered) {
      return redirectToRegister({
        customAuthResult,
        registrationTkey: privateKey,
        oAuthPrivateKey,
        walletKey: privateKey,
        isUserRegistered: isAuthServiceRegistered
      });
    }
    return {
      canContinue: true,
      oAuthPrivateKey,
      tKey: privateKey,
      walletKey: privateKey
    };
  } else {
    throw new Error(`Unknown mfa level: ${currentDappModule.mfaLevel}`);
  }

  return { canContinue: false, displayError: "unhandled rule engine result" };
}
