import { API_BASE_URL, Header } from "helpers/moralisHelper/api";
import { BalanceItems, IChains } from "interfaces/actions/IToken";
import { ResponseCollectionAddress } from "interfaces/actions/moralis/ICollectionMetadata";
import { IErc20, IErc20Metadata } from "interfaces/actions/moralis/IErc20";
import { MetadataValue, RealMetadataNft, ResponseMetadataNft } from "interfaces/actions/moralis/IMetadataNft";
import {
  IResultErc20Moralis,
  ITransferErc20Moralis,
  ITransferNativeMoralis,
  ITransferNftMoralis,
  MoralisTransactionResult,
  MoralisTransactionsResponse,
  ResponseErc20MetadataMoralis
} from "interfaces/actions/moralis/IMoralisHistoryTransaction";
import { catchError } from "shared/utils/coreUtils";
import httpServices from "./HttpServices";
import { filterGateway } from "shared/utils/raceConditionIpfs";
// import useWeb3 from "hooks/useWeb3";
import Web3 from "web3";
import erc721 from "abis/erc721.json";
import erc1155 from "abis/erc1155ContractUri.json";
import { AbiItem } from "web3-utils";
import axios from "axios";
import { getBestRpc } from "shared/utils/filterRpc";
import Contract from "web3-eth-contract";

type Erc20Response = {
  contract_decimal: number;
  chain_ticker: string;
  logo_url: string;
  custom_name: string;
  contract_address: string;
  hash: string;
  transaction_index: string | number;
  from_address: string;
  to_address: string;
  value: string;
  block_timestamp: string | number;
  block_number: string;
  block_hash: string;
  transfer_index: string | number;
  transaction_type: string;
  contract_type: string;
};

type NftResponse = {
  contract_decimal: number;
  chain_ticker: string;
  logo_url: string;
  custom_name: string;
  address: string;
  hash: string;
  transaction_index: string | number;
  from_address: string;
  to_address: string;
  value: string;
  token_address: string;
  token_id: string;
  block_timestamp: string | number;
  block_number: string;
  block_hash: string;
  transfer_index: string | number;
  transaction_type: string;
  amount: string;
  contract_type: string;
  nftImage: string;
  nftName: string;
};

type OtherMetadata = {
  hash: string;
  transaction_index: string | number;
  from_address: string;
  to_address: string;
  value: string;
  token_address: string;
  token_id: string;
  block_timestamp: string | number;
  block_number: string;
  block_hash: string;
  transfer_index: string | number;
  transaction_type: string;
  amount: string;
  contract_type: string;
};

type nftDataInterface = {
  address: string;
  chain_id: number | string;
  items: BalanceItems[];
  next_update_at: string;
  pagination: number | null;
  quote_currency: string;
  updated_at: string;
};

type listTickerInterface = {
  [key: string]: string;
};

class MoralisServices {
  /**
   * This function retrieves NFT data for a given wallet address and chain, and formats it into a
   * specific data interface.
   * @param {IChains} chainData - An object containing information about the blockchain network,
   * including its name and ID.
   * @param {string} walletAddress - The wallet address of the user for whom the NFT balance is being
   * fetched.
   * @returns a Promise that resolves to an object with properties such as `address`, `chain_id`,
   * `items`, `next_update_at`, `pagination`, `quote_currency`, and `updated_at`. The `items` property
   * is an array of `BalanceItems` objects, which contain information about NFT collections and their
   * associated metadata.
   */
  async getNft(chainData: IChains, walletAddress: string): Promise<nftDataInterface | { error: boolean; message: string } | any> {
    try {
      const request = await httpServices.get<ResponseCollectionAddress>(
        `${API_BASE_URL}${walletAddress}/nft?chain=${chainData.chainName}`,
        Header()
      );
      if (request.status === 200) {
        const { result } = request.data;
        const newDataInterface = {
          address: walletAddress,
          chain_id: parseInt(chainData.chain),
          items: <BalanceItems[]>[],
          next_update_at: "",
          pagination: null,
          quote_currency: "USD",
          updated_at: ""
        };
        await Promise.all(
          result.map(async (nftCollection) => {
            if (!nftCollection?.metadata && nftCollection?.token_address && nftCollection?.token_id)
              await this.resyncMetadata(nftCollection.token_address, nftCollection.token_id);
            const moralisNftMetadata = JSON.parse(nftCollection?.metadata);
            let imageUrl = "";
            let animationUrl = "";
            let thumbnailUrl = "";

            let complete = false;
            if (nftCollection?.token_uri) {
              const attempt = [0, 1];
              for (const idx of attempt) {
                try {
                  let url = nftCollection?.token_uri;
                  if (idx === 1) {
                    const urlParts = url.split("/");

                    const refinedUrl = urlParts.slice(0, 5).join("/");

                    url = refinedUrl + "/" + nftCollection?.token_id;
                  }
                  const res = await axios.get(url, { timeout: 5000 });
                  if (res?.data?.image) {
                    const imageUrlArr = await filterGateway(res.data.image);
                    if (imageUrlArr.length) imageUrl = imageUrlArr[0];
                  }

                  if (res?.data?.animation_url) {
                    const videoUrlArr = await filterGateway(res?.data?.animation_url);
                    if (videoUrlArr.length) animationUrl = videoUrlArr[0];
                  }

                  complete = true;
                } catch (error) {
                  console.log(error, "masuk @error");
                }
              }
            }

            const bestRpc = await getBestRpc(chainData.chain, chainData.rpcUrl);
            const web3 = new Web3(typeof bestRpc === "string" ? bestRpc : chainData.rpcUrl[0]);
            const useAbi = nftCollection.contract_type.toLowerCase() === "erc721" ? erc721.abi : erc1155;
            const nftContract = new web3.eth.Contract(useAbi as unknown as AbiItem[], nftCollection?.token_address);
            const tokenUri = await this.getContractUri(nftContract);
            if (tokenUri) {
              const arrImgUrl = await filterGateway(tokenUri);
              const getImgIpfs = await axios.get(arrImgUrl[0]);
              if (getImgIpfs) {
                const getLinkImg = await filterGateway(getImgIpfs.data.image);
                if (getLinkImg?.length) thumbnailUrl = getLinkImg[0];
              }
            }

            if (!complete) {
              if (moralisNftMetadata?.animation_url?.includes("ipfs://")) {
                const arrUrl = await filterGateway(moralisNftMetadata?.animation_url);
                if (arrUrl.length) animationUrl = arrUrl[0];
                const bestRpc = await getBestRpc(chainData.chain, chainData.rpcUrl);
                const web3 = new Web3(typeof bestRpc === "string" ? bestRpc : chainData.rpcUrl[0]);
                const nftContract = new web3.eth.Contract(erc721.abi as unknown as AbiItem[], nftCollection?.token_address);
                const tokenUri = await nftContract.methods.contractURI().call();
                const arrImgUrl = await filterGateway(tokenUri);
                const getImgIpfs = await axios.get(arrImgUrl[0]);
                const getLinkImg = await filterGateway(getImgIpfs.data.image);
                if (getLinkImg.length) imageUrl = getLinkImg[0];
              }
              if (moralisNftMetadata?.image?.includes("ipfs://")) {
                const arrUrl = await filterGateway(moralisNftMetadata?.image);
                if (arrUrl.length) imageUrl = arrUrl[0];
              }
            }

            if (!imageUrl) {
              imageUrl = moralisNftMetadata?.image;
            }
            // }
            const initialCollection = {
              // collection
              contract_decimals: 0,
              contract_name: nftCollection?.name,
              contract_ticker_symbol: nftCollection?.symbol,
              contract_address: nftCollection?.token_address,
              supports_erc: ["erc20", nftCollection?.contract_type.toLocaleLowerCase()],
              logo_url: "",
              last_transferred_at: "",
              native_token: false,
              type: "nft",
              balance: nftCollection?.amount,
              balance_24h: nftCollection?.amount,
              quote_rate: null,
              quote_rate_24h: null,
              quote: null,
              quote_24h: null,
              chain_id: chainData.chain,
              nft_data: <any>[]
            };
            const initialNftMetadata = {
              token_id: nftCollection?.token_id ?? "",
              token_balance: nftCollection?.amount ?? "0",
              token_url: nftCollection?.token_uri ?? "",
              supports_erc: ["erc20", nftCollection?.contract_type && nftCollection?.contract_type?.toLocaleLowerCase()],
              token_price_wei: null,
              token_quote_rate_eth: null,
              original_owner: "",
              external_data: {
                name: moralisNftMetadata ? moralisNftMetadata?.name : "",
                description: moralisNftMetadata ? moralisNftMetadata?.description : "",
                image: imageUrl,
                image_256: "",
                image_512: "",
                image_1024: "",
                animation_url: animationUrl,
                thumbnail_url: thumbnailUrl,
                external_url: moralisNftMetadata ? moralisNftMetadata?.external_url : "",
                attributes: null,
                owner: walletAddress
              },
              owner: null,
              owner_address: walletAddress,
              burned: null
            };
            initialCollection.nft_data.push(initialNftMetadata);
            if (imageUrl) {
              newDataInterface.items.unshift(initialCollection);
            } else {
              newDataInterface.items.push(initialCollection);
            }
          })
        );
        const newMap: BalanceItems[] = [];
        let currentIndex: number;
        await Promise.all(
          newDataInterface.items.map((moralisNftItem) => {
            const mapData = newMap.find((val: BalanceItems, index: number) => {
              currentIndex = index;
              return moralisNftItem.contract_address === val.contract_address;
            });
            if (!mapData) {
              newMap.push(moralisNftItem);
            } else {
              newMap[currentIndex].nft_data = [...newMap[currentIndex].nft_data, ...moralisNftItem.nft_data];
            }
          })
        );
        newDataInterface.items = newMap;
        return newDataInterface;
      }
    } catch (error: any) {
      catchError(error, "balance error");
      if (error.status === 429) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return this.getNft(chainData, walletAddress);
      }
      return {
        error: true,
        message: error.message
      };
    }
  }

  /**
   * This function retrieves the balance of a wallet's native token and ERC20 tokens on a specific
   * blockchain.
   * @param {IChains} chainData - An object containing information about the blockchain network,
   * including its name, address, custom name, ticker, custom logo, and chain ID.
   * @param {string} walletAddress - The wallet address is a string parameter that represents the
   * address of a cryptocurrency wallet.
   * @returns an array of objects of type `BalanceItems[]`, which contains information about the native
   * token and ERC20 tokens held by a wallet address on a specific blockchain. If there is an error, it
   * returns an object with `error` and `message` properties.
   */
  async getNativeAndErc20(chainData: IChains, walletAddress: string): Promise<BalanceItems[] | { error: boolean; message: string } | any> {
    try {
      const request = await httpServices.get<IErc20[]>(`${API_BASE_URL}${walletAddress}/erc20?chain=${chainData.chainName}`, Header());
      const requestNative = await httpServices.get<any>(`${API_BASE_URL}${walletAddress}/balance?chain=${chainData.chainName}`, Header());
      if (request.status === 200 && requestNative.status === 200) {
        const { data } = request;
        const newArr = <BalanceItems[]>[];

        data.map((erc20Data) => {
          const dataInterfaces = {
            balance: erc20Data?.balance ?? "0",
            balance_24h: "",
            contract_address: erc20Data?.token_address ?? "",
            contract_decimals: erc20Data?.decimals ?? 18,
            contract_name: erc20Data?.name ?? "",
            contract_ticker_symbol: erc20Data?.symbol ?? "",
            last_transferred_at: "",
            logo_url: erc20Data?.logo ?? "",
            native_token: false,
            nft_data: [],
            quote: 0,
            quote_24h: 0,
            quote_rate: 0,
            quote_rate_24h: 0,
            supports_erc: ["erc20"],
            type: "cryptocurrency",
            chain_id: chainData.chain
          };
          newArr.push(dataInterfaces);
        });
        const nativeArr = <BalanceItems[]>[];
        const newDataInterfaces: BalanceItems = {
          balance: requestNative?.data?.balance,
          balance_24h: "",
          contract_address: chainData.address,
          contract_decimals: 18,
          contract_name: chainData.customName,
          contract_ticker_symbol: chainData.ticker,
          last_transferred_at: "",
          logo_url: chainData.customLogo,
          native_token: true,
          nft_data: [],
          quote: 0,
          quote_24h: 0,
          quote_rate: 0,
          quote_rate_24h: 0,
          supports_erc: null,
          type: "cryptocurrency",
          chain_id: chainData.chain
        };
        nativeArr.push(newDataInterfaces);
        const concatErc20AndNative = [...newArr, ...nativeArr];
        return concatErc20AndNative as BalanceItems[];
      }
    } catch (error: any) {
      catchError(error, "balance error");
      if (error.status === 429) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return this.getNativeAndErc20(chainData, walletAddress);
      }
      return {
        error: true,
        message: error.message
      };
    }
  }

  /**
   * This function retrieves transaction history for a given wallet address on a specified blockchain
   * network, including ERC20, native, and NFT transfers.
   * @param {IChains} chainData - An object containing information about the blockchain network, such
   * as its name and ticker symbol.
   * @param {string} walletAddress - The wallet address for which the transaction history is being
   * fetched.
   * @returns a Promise that resolves to a MoralisTransactionsResponse object, which contains an array
   * of MoralisTransactionResult objects representing transaction history data for a given wallet
   * address on a given blockchain network.
   */
  async getTransactionHistory(chainData: IChains, walletAddress: string): Promise<MoralisTransactionsResponse> {
    try {
      /**
       * @ERC20_TRANSFER
       */
      const request = await httpServices.get<ITransferErc20Moralis>(
        `${API_BASE_URL}/${walletAddress}/erc20/transfers?chain=${chainData.chainName}&limit=20`,
        Header()
      ); //erc20

      const listTicker: listTickerInterface = {};
      const erc20Request: Erc20Response[] = [];
      const erc20Address: string[] = [];
      const metadataTokenObj: { [key: string]: IErc20Metadata } = {};

      if (request?.data && request?.data?.result) {
        for (const data of request.data.result) {
          erc20Address.push(data?.address);
          const newResponseErc20 = {
            contract_decimal: 18,
            logo_url: "",
            custom_name: "",
            chain_ticker: chainData?.ticker,
            contract_address: data?.address,
            hash: data?.transaction_hash,
            transaction_index: data?.log_index,
            from_address: data?.from_address,
            to_address: data?.to_address,
            value: data?.value,
            block_timestamp: data?.block_timestamp,
            block_number: data?.block_number,
            block_hash: data?.block_hash,
            transfer_index: data?.transaction_index,
            transaction_type: "Single",
            contract_type: "ERC20"
          };
          erc20Request.push(newResponseErc20);
        }
        const erc20Metadata: ResponseErc20MetadataMoralis = await this.getErc20MetadataMoralis(
          [...new Set(erc20Address)],
          chainData?.chainName
        );

        erc20Metadata?.result?.forEach((ele: any) => {
          listTicker[ele?.address] = ele?.symbol;
          metadataTokenObj[ele?.address] = ele;
        });

        erc20Request.forEach((ele) => {
          Object.assign(ele, {
            contract_decimal: Number(metadataTokenObj[ele.contract_address]["decimals"] ?? 18),
            logo_url: metadataTokenObj[ele.contract_address]["logo"] ?? chainData?.customLogo,
            custom_name: metadataTokenObj[ele.contract_address]["symbol"] ?? chainData?.customName
          });
        });
      }
      /**
       * @NATIVE_TRANSFER
       */
      const requestHistoryNativeTransfer = await httpServices.get<ITransferNativeMoralis>(
        `${API_BASE_URL}${walletAddress}?chain=${chainData.chainName}`,
        Header()
      );
      const nativeRequest =
        requestHistoryNativeTransfer?.data && requestHistoryNativeTransfer?.data?.result
          ? await Promise.all(
              requestHistoryNativeTransfer.data.result.map((native) => {
                const newResponseNative = {
                  contract_decimal: 18,
                  chain_ticker: listTicker[native?.to_address],
                  logo_url: chainData.customLogo,
                  custom_name: listTicker[native?.to_address] ?? chainData.ticker,
                  address: walletAddress,
                  hash: native?.hash,
                  nonce: native?.nonce,
                  from_address: native?.from_address,
                  to_address: native?.to_address,
                  value: native?.value,
                  gas: native?.gas,
                  gas_price: native?.gas_price,
                  input: native?.input,
                  receipt_status: native?.receipt_status,
                  block_timestamp: native?.block_timestamp,
                  block_number: native?.block_number,
                  block_hash: native?.block_hash,
                  transfer_index: native?.transaction_index,
                  transaction_type: "Single",
                  contract_type: "NATIVE"
                };
                return newResponseNative;
              })
            )
          : [];

      /**
       * @NFT_TRANSFER
       */

      const requestHistoryTransferNft = await httpServices.get<ITransferNftMoralis>(
        `${API_BASE_URL}${walletAddress}/nft/transfers?chain=${chainData.chainName}&format=decimal&direction=both`,
        Header()
      );

      const nftRequest: NftResponse[] = [];

      let reqHistory: Promise<ResponseMetadataNft>[] = [];
      let arrOtherInfo: OtherMetadata[] = [];
      if (requestHistoryTransferNft?.data && requestHistoryTransferNft?.data?.result) {
        for (const [idx, val] of requestHistoryTransferNft.data.result.entries()) {
          const resMetadata: Promise<ResponseMetadataNft> = this.getNftMetadata(val.token_address, chainData.chainName, val.token_id);
          reqHistory.push(resMetadata);
          arrOtherInfo.push({
            hash: val?.transaction_hash ? val.transaction_hash : "",
            transaction_index: val?.log_index ? val.log_index : "",
            from_address: val?.from_address ? val.from_address : "",
            to_address: val?.to_address ? val.to_address : "",
            value: val?.value ? val.value : "",
            token_address: val?.token_address ? val.token_address : "",
            token_id: val?.token_id ? val.token_id : "",
            block_timestamp: val?.block_timestamp ? val.block_timestamp : "",
            block_number: val?.block_number ? val.block_number : "",
            block_hash: val?.block_hash ? val.block_hash : "",
            transfer_index: val?.transaction_index ? val.transaction_index : "",
            transaction_type: val?.transaction_type ? val.transaction_type : "",
            amount: val?.amount ? val.amount : "",
            contract_type: val?.contract_type ? val.contract_type : ""
          });
          if (reqHistory.length === 20 || idx === requestHistoryTransferNft.data.result.length - 1) {
            const res = await Promise.all(reqHistory);
            for (const [index, resMeta] of res.entries()) {
              const metadataParse: RealMetadataNft =
                resMeta?.result && resMeta.result?.metadata ? JSON.parse(resMeta.result?.metadata) : null;
              let imageUrl = "";
              if (metadataParse?.image?.includes("ipfs://")) {
                const arrUrl = await filterGateway(metadataParse?.image);
                arrUrl.length ? (imageUrl = arrUrl[0]) : "";
              } else {
                imageUrl = metadataParse?.image;
              }
              const newNftResponse = {
                contract_decimal: 18,
                chain_ticker: chainData.ticker,
                logo_url: chainData.customLogo,
                custom_name: chainData.ticker, //TMATIC
                address: walletAddress,
                hash: arrOtherInfo[index].hash,
                transaction_index: arrOtherInfo[index].transaction_index,
                from_address: arrOtherInfo[index].from_address,
                to_address: arrOtherInfo[index].to_address,
                value: arrOtherInfo[index].value,
                token_address: arrOtherInfo[index].token_address,
                token_id: arrOtherInfo[index].token_id,
                block_timestamp: arrOtherInfo[index].block_timestamp,
                block_number: arrOtherInfo[index].block_number,
                block_hash: arrOtherInfo[index].block_hash,
                transfer_index: arrOtherInfo[index].transfer_index,
                transaction_type: arrOtherInfo[index].transaction_type,
                amount: arrOtherInfo[index].amount,
                contract_type: arrOtherInfo[index].contract_type,
                nftImage: imageUrl,
                nftName: metadataParse?.name ? metadataParse?.name : ""
              };
              nftRequest.push(newNftResponse);
            }
            reqHistory = [];
            arrOtherInfo = [];
          }
        }
      }

      const transactionHistoryConcat: any = await Promise.all([...erc20Request, ...nftRequest]);
      const listTrxHash: { [key: string]: boolean } = {};
      // Get trx hash
      transactionHistoryConcat.forEach((ele: MoralisTransactionResult) => {
        if (!listTrxHash[ele?.hash]) {
          listTrxHash[ele?.hash] = true;
        }
      });

      //filter based on existence hash
      nativeRequest.forEach((ele) => {
        if (!listTrxHash[ele?.hash]) {
          transactionHistoryConcat.push(ele);
        }
      });

      return { data: transactionHistoryConcat as MoralisTransactionResult[] };
    } catch (error: any) {
      console.log(error, "@error");
      catchError(error, "history error");
      return {
        data: [],
        error: true,
        error_code: 500,
        error_message: "Indexer Error"
      };
    }
  }

  /**
   * This is an asynchronous function that retrieves metadata for a specific NFT token from a given
   * contract address and chain data.
   * @param {string} contractAddress - The address of the smart contract that the NFT belongs to.
   * @param {string} chainData - The chainData parameter is a string that represents the blockchain
   * network on which the NFT contract is deployed. It is used to specify the network on which the API
   * request should be made.
   * @param {string} tokenId - The unique identifier of a specific NFT (non-fungible token) within a
   * given smart contract.
   * @returns A Promise that resolves to an object containing the metadata of an NFT (non-fungible
   * token) with the specified contract address, chain data, and token ID. If there is an error, the
   * Promise resolves to an object with an error flag and message.
   */
  async getNftMetadata(contractAddress: string, chainData: string, tokenId: string): Promise<ResponseMetadataNft> {
    try {
      const request = await httpServices.get<MetadataValue>(
        `${API_BASE_URL}nft/${contractAddress}/${tokenId}?chain=${chainData}`,
        Header()
      );
      return { result: request.data };
    } catch (error: any) {
      catchError(error, "balance error");
      return {
        result: null,
        error: true,
        error_code: 500,
        error_message: "Indexer Error"
      };
    }
  }

  /**
   * This is an async function that retrieves ERC20 metadata from a Moralis API endpoint given a wallet
   * address, token address, and chain data.
   * @param {string} walletAddress - The wallet address of the user for whom the ERC20 metadata is
   * being fetched.
   * @param {string} token_address - The address of the ERC-20 token for which metadata is being
   * requested.
   * @param {string} chainData - The blockchain network on which the ERC20 token is deployed, such as
   * "eth" for Ethereum or "bsc" for Binance Smart Chain.
   * @returns A Promise that resolves to an object of type ResponseErc20MetadataMoralis, which contains
   * an array of IResultErc20Moralis objects and optional error information.
   */
  async getErc20MetadataMoralis(token_address: string[], chainData: string): Promise<ResponseErc20MetadataMoralis> {
    try {
      let completeUrl = `${API_BASE_URL}erc20/metadata?chain=${chainData}`;
      const queryParams = token_address.map((address, index) => {
        const key = `addresses[${index}]`;
        return `${key}=${encodeURIComponent(address)}`;
      });
      completeUrl += "&" + queryParams.join("&");
      const request = await httpServices.get<IResultErc20Moralis[]>(completeUrl, Header());
      return { result: request.data as IResultErc20Moralis[] };
    } catch (error: any) {
      catchError(error, "balance error");
      return {
        result: [],
        error: true,
        error_code: 500,
        error_message: "Indexer Error"
      };
    }
  }

  async getContractUri(nftContract: Contract) {
    try {
      const data = await nftContract.methods.contractURI().call();
      return data;
    } catch (error) {
      console.log(error, "@getContractUri");
      return null;
    }
  }

  /**
   * This is an async function to resync null metadata.
   * @param {string} token_address - The address of the NFT contract
   * @param {string} token_id - The id of the particular NFT
   * @returns A Promise that resolves to an object of type ResponseErc20MetadataMoralis, which contains
   * an array of IResultErc20Moralis objects and optional error information.
   */
  async resyncMetadata(token_address: string, token_id: string) {
    try {
      const completeUrl = `${API_BASE_URL}nft/${token_address}/${token_id}/metadata/resync`;
      await httpServices.get(completeUrl, Header());
      return true;
    } catch (error: any) {
      catchError(error, "resync nft metadata error");
      return false;
    }
  }
}

const moralisServices = new MoralisServices();

export default moralisServices;
