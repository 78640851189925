import ga4 from "react-ga4";

const TRACKING_ID = process.env.REACT_APP_GA as string;

export const init = () => {
  return ga4.initialize(TRACKING_ID, {
    gaOptions: {
      cookieDomain: "none"
    }
  });
};

export const sendEvent = (name: string) =>
  ga4.event("page_view", {
    label: "WALLET UPBOND",
    action: name,
    category: name
  });

export const TrackGoogleAnalyticsEvent = (category: string, action: string, label: string) => {
  // Send GA4 Event
  ga4.event("click", {
    category: category,
    action: action,
    label: label,
    value: 1
  });
};
export const sendPageview = (data: any) =>
  ga4.send({
    hitType: "pageview",
    data
  });
