import useTheme from "hooks/useTheme";
import { IChains, IToken } from "interfaces/actions/IToken";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { listChain } from "shared/config/chains";
import useLoading from "hooks/useLoading";
import { INFTData, TokenContentProps } from "features/home/types";
import HomeListMb from "features/home/components/HomeList/mb";
import HomeListPopupMb from "features/home/components/HomeList/mb/popup";
import HomeListPc from "features/home/components/HomeList/pc";
import HomeListPopupPc from "features/home/components/HomeList/pc/popup";
import "./style.css";

const HomeList: React.FC<any> = ({
  balances,
  tokenBalances,
  etherInitBalance,
  maticInitBalance,
  index,
  length,
  collectibles,
  page,
  balance,
  onCoinClick,
  onNFTClick,
  onTokenClick
}: TokenContentProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [nftData, setNftData] = useState<INFTData>();
  const [balanceOpen, setBalanceOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { breakpoint } = useTheme();
  const { setLoading } = useLoading();
  const [nftType, setNftType] = useState<string>("");

  useEffect(() => {
    setLoading(false, `${t("transfer.loading")} `);
  }, []);

  const openNFT = (item: IToken, nft: any) => {
    const getChain = listChain.filter((newItem: IChains) => {
      if (item.chain_id === newItem.chain) return newItem;
    });
    const name = nft?.external_data?.name;
    const animation_url = nft?.external_data?.animation_url;
    const image = nft?.external_data?.image ?? window.location.origin + "/assets/noimg.png";
    const thumbnail_url = nft?.external_data?.thumbnail_url ?? window.location.origin + "/assets/noimg.png";
    const link = getChain[0]?.resource + item?.contract_address + "/" + nft?.token_id;
    const collectionName =
      item.contract_address === "0x939Cf6D4Dab42e9B64bD3E7251E4BBb212d8ba82".toLocaleLowerCase() ||
      item.contract_address === "0x3c9161C06e8EED26aCBEdF57f1233b7a29bD2685".toLocaleLowerCase()
        ? "2023年新入社員証明NFT"
        : item?.contract_name;
    setNftData({ name, image, link, collectionName, animation_url, thumbnail_url });
    setNftType(item?.nftType ?? "");
    setOpen(true);
  };

  return (
    <>
      {breakpoint < 1024 ? (
        <HomeListMb
          balances={balances}
          page={page}
          index={index}
          balance={balance}
          onCoinClick={onCoinClick}
          tokenBalances={tokenBalances}
          onTokenClick={onTokenClick}
          collectibles={collectibles}
          onNFTClick={onNFTClick}
          length={length}
          balanceOpen={balanceOpen}
          setBalanceOpen={(val: boolean) => setBalanceOpen(val)}
          openNFT={openNFT}
        />
      ) : (
        <HomeListPc
          etherInitBalance={etherInitBalance}
          onTokenClick={onTokenClick}
          maticInitBalance={maticInitBalance}
          balances={balances}
          onCoinClick={onCoinClick}
          page={page}
          balance={balance}
          tokenBalances={tokenBalances}
          index={index}
          length={length}
          collectibles={collectibles}
          onNFTClick={onNFTClick}
          balanceOpen={balanceOpen}
          setBalanceOpen={(val: boolean) => setBalanceOpen(val)}
          openNFT={openNFT}
        />
      )}

      {breakpoint < 1024 ? (
        <HomeListPopupMb isOpen={isOpen} setOpen={setOpen} nftData={nftData} nftType={nftType} />
      ) : (
        <HomeListPopupPc isOpen={isOpen} setOpen={setOpen} nftData={nftData} nftType={nftType} />
      )}
    </>
  );
};

export default HomeList;
