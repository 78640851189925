import React from "react";
import DefaultPhotoIcon from "assets/icons/default-photo.svg";
import { ProfilePictureProps } from "interfaces/ui/IMenu";

const ProfilePicture = ({ userData }: ProfilePictureProps) => {
  if (userData && userData?.profileImage) {
    return (
      <img
        alt={userData?.name}
        src={userData?.profileImage}
        referrerPolicy="no-referrer"
        onError={({ currentTarget }) => {
          currentTarget.src = DefaultPhotoIcon;
          currentTarget.onerror = null;
          return null;
        }}
      />
    );
  } else {
    return <img alt={userData.name} src={DefaultPhotoIcon} />;
  }
};

export default ProfilePicture;
