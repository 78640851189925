import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State } from "interfaces/actions/IWalletConnect";

const wcModalSlice = createSlice({
  name: "walletconnect",
  initialState: {
    open: false,
    data: {},
    connected: false,
    view: {}
  } as State,
  reducers: {
    setOpen(state, action: PayloadAction<State>) {
      state.view = action.payload.view;
      state.data = action.payload.data;
      state.open = action.payload.open;
    },
    setClose(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
    setConnect(state, action: PayloadAction<boolean>) {
      state.connected = action.payload;
    }
  }
});

export default wcModalSlice;
