/* eslint-disable @typescript-eslint/no-empty-function */
import { TKeyDefault } from "@tkey/default";
import { SecurityQuestionsModule, SECURITY_QUESTIONS_MODULE_NAME } from "@tkey/security-questions";
import { ServiceProviderBase } from "@tkey/service-provider-base";
import { TorusServiceProvider } from "@tkey/service-provider-torus";
import { SHARE_TRANSFER_MODULE_NAME, ShareTransferModule } from "@tkey/share-transfer";
import { TorusStorageLayer } from "@tkey/storage-layer-torus";
import { WEB_STORAGE_MODULE_NAME, WebStorageModule } from "@tkey/web-storage";
import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import BN from "bn.js";
import { Buffer } from "buffer";
import { privateToAddress, toBuffer } from "ethereumjs-util";
import { useHistory } from "react-router-dom";
import { walletActions } from "shared/actions/walletAction";
import { store } from "shared/store";
import Web3 from "web3";

const {
  REACT_APP_LINE_VERIFIER,
  REACT_APP_LINE_CLIENT_ID,
  REACT_APP_LOGIN_UPBOND_DOMAIN,
  REACT_APP_CLIENT_ID,
  REACT_APP_TORUS_NETWORK,
  REACT_APP_GOOGLE_VERIFIER,
  REACT_APP_GOOGLE_CLIENT_ID
} = process.env;
const LOGIN_CONNECTIONS: any = {
  line: {
    typeOfLogin: "line",
    jwtParams: {
      domain: REACT_APP_LOGIN_UPBOND_DOMAIN,
      connection: "line",
      clientId: REACT_APP_LINE_CLIENT_ID,
      scope: "openid email profile offline_access"
    },
    verifier: REACT_APP_LINE_VERIFIER,
    clientId: REACT_APP_LINE_CLIENT_ID
  },
  jwt: {
    typeOfLogin: "jwt",
    jwtParams: {
      domain: REACT_APP_LOGIN_UPBOND_DOMAIN,
      connection: "google",
      clientId: REACT_APP_GOOGLE_CLIENT_ID,
      scope: "openid email profile offline_access"
    },
    verifier: REACT_APP_GOOGLE_VERIFIER,
    clientId: REACT_APP_GOOGLE_CLIENT_ID
  }
};

interface tKeyContextType {
  tKey: any;
  provider: any;
  isLoading: boolean;
  recoverShare: (val: any) => Promise<any>;
  user?: any;
  result?: any;
  chain?: string;
  generateNewShareWithPassword: (val: string) => Promise<any>;
  backupShareRecover: (val: string) => Promise<any>;
  generateMnemonic: (val: string) => Promise<any>;
  resetAccount: () => Promise<any>;
  getAccounts: () => Promise<any>;
  getChainID: () => Promise<any>;
  getBalance: () => Promise<any>;
  getUser: () => Promise<any>;
  changeSecurityQuestionAndAnswer: (val: string) => Promise<any>;
  login: (val: string) => Promise<any>;
}

export const tKeyContext = createContext<tKeyContextType>({
  tKey: null,
  provider: null,
  isLoading: false,
  user: null,
  result: null,
  chain: "80002",
  recoverShare: async () => {},
  generateMnemonic: async () => {},
  getUser: async () => {},
  generateNewShareWithPassword: async () => {},
  backupShareRecover: async () => {},
  changeSecurityQuestionAndAnswer: async () => {},
  getAccounts: async () => {},
  getChainID: async () => {},
  getBalance: async () => {},
  resetAccount: async () => {},
  login: async () => {}
});

export function useTKey() {
  return useContext(tKeyContext);
}

interface tKeyProviderProps {
  children: React.ReactNode;
  chain: string;
}

export const TKeyProvider: React.FC<tKeyProviderProps> = ({ children, chain }) => {
  const [tKey, setTKey] = useState<any>(null);
  const [privateKey, setPrivateKey] = useState<any>(null);
  const [result, setResult] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [provider, setProvider] = useState<any>(null);
  const [oAuthShare, setOAuthShare] = useState<any>();
  const [user, setUser] = useState<any>(null);
  const history = useHistory();
  const isLoading = false;

  useEffect(() => {
    const init = async () => {
      const torusSp = new TorusServiceProvider({
        customAuthArgs: {
          web3AuthClientId: REACT_APP_CLIENT_ID as any,
          baseUrl: `${window.location.origin}`,
          redirectPathName: "auth",
          enableLogging: true,
          uxMode: "redirect",
          network: REACT_APP_TORUS_NETWORK as any
        }
      });
      setTKey(torusSp);
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      // Initialization of Service Provider
      try {
        // Init is required for Redirect Flow but skip fetching sw.js and redirect.html )
        if (tKey) {
          (tKey as any).init({ skipInit: true, skipSw: true });
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [privateKey]);

  const resetAccount = async () => {
    if (!tKey) {
      console.log("tKey not initialized yet");
      return;
    }
    try {
      await tKey.storageLayer.setMetadata({
        privKey: oAuthShare,
        input: { message: "KEY_NOT_FOUND" }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const backupShareRecover = async (value: any) => {
    if (!tKey) {
      console.log("tKey not initialized yet");
      return;
    }
    // swal is just a pretty dialog box

    try {
      await tKey.inputShare(value, "mnemonic"); // 2/2 flow
      const reconstructedKey = await tKey.reconstructKey();
      localStorage.setItem("privateKey", reconstructedKey?.secp256k1Key.toString("hex"));
      setPrivateKey(reconstructedKey?.privKey.toString("hex"));
      const subKeyDerived = (window as any).OpenloginSubkey.subkey(
        reconstructedKey?.privKey.toString("hex").padStart(64, "0"),
        Buffer.from(process.env.REACT_APP_CLIENT_ID as any, "base64")
      );
      localStorage.setItem("subkey", subKeyDerived.padStart(64, "0"));
      const address = await getAddress();
      store.dispatch(walletActions.setSelectedAddress(address));
      localStorage.setItem("walletaddress", address);
      window.location.href = `${window.location.origin}/wallet`;
      return localStorage.getItem("privateKey");
    } catch (error) {
      console.log(error);
    }
  };

  const changeSecurityQuestionAndAnswer = async (value: any) => {
    if (!tKey) {
      console.log("tKey not initialized yet");
      return;
    }
    // swal is just a pretty dialog box
    try {
      await (tKey.modules.securityQuestions as any).changeSecurityQuestionAndAnswer(value, "mypassword?");

      await tKey.getKeyDetails();
      window.location.href = `${window.location.origin}/wallet`;
    } catch (error) {
      console.log("Error", error);
    }
  };

  const generateNewShareWithPassword = async (value: any) => {
    if (!tKey) {
      console.log("tKey not initialized yet");
      return;
    }
    // swal is just a pretty dialog box

    try {
      await (tKey.modules.securityQuestions as any).generateNewShareWithSecurityQuestions(value, "mypassword?");
      const { requiredShares } = tKey.getKeyDetails();
      if (requiredShares <= 0) {
        const reconstructedKey = await tKey.reconstructKey();
        localStorage.setItem("privateKey", reconstructedKey?.secp256k1Key.toString("hex"));
        setPrivateKey(reconstructedKey?.privKey.toString("hex"));
        const subKeyDerived = (window as any).OpenloginSubkey.subkey(
          reconstructedKey?.privKey.toString("hex").padStart(64, "0"),
          Buffer.from(process.env.REACT_APP_CLIENT_ID as any, "base64")
        );
        localStorage.setItem("subkey", subKeyDerived.padStart(64, "0"));
        const address = await getAddress();
        store.dispatch(walletActions.setSelectedAddress(address));
        localStorage.setItem("walletaddress", address);
      }
      const newShare = await tKey.generateNewShare();
      const shareStore = await tKey.outputShareStore(newShare.newShareIndex);
      await (tKey.modules.webStorage as any).storeDeviceShare(shareStore);
      await axios.post(
        `${process.env.REACT_APP_LOGIN_UPBOND_DOMAIN}/customer-wallet`,
        {
          provider: "line",
          is_wallet_registered: true,
          wallet_version: "2.0.0",
          wallet_registered_at: new Date()
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo") as any).accessToken}`
          }
        }
      );
      window.location.href = `${window.location.origin}/wallet`;
    } catch (error) {
      console.log("Error", error);
    }
  };

  const generateMnemonic = async () => {
    if (!tKey) {
      console.log("tKey not initialized yet");
      return;
    }
    try {
      const newShare = await tKey.generateNewShare();
      const mnemonic = await tKey.outputShare(newShare.newShareIndex, "mnemonic");
      return mnemonic;
    } catch (error) {
      console.log(error);
    }
  };

  function hexToBytes(hex: string) {
    const bytes = [];
    for (let i = 0; i < hex.length; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return bytes;
  }

  function padBytesTo32(bytes: any) {
    if (bytes.length >= 32) {
      return bytes.slice(0, 32);
    } else {
      const padding = new Array(32 - bytes.length).fill(0);
      return bytes.concat(padding);
    }
  }

  const parseIdToken = (token: any, pureJwt = false) => {
    if (pureJwt) {
      const json = decodeURIComponent(
        window
          .atob(token)
          .split("")
          .map((c) => {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
          })
          .join("")
      );
      return JSON.parse(json);
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  const checkUpbondUser = async (provider: string, result: any): Promise<boolean> => {
    const { idToken } = result.userInfo;
    try {
      if (idToken) {
        try {
          const decodedTokenId = parseIdToken(idToken);
          if (decodedTokenId && decodedTokenId.identities) {
            // Get identities index of provider
            const providerIndex = decodedTokenId.identities.findIndex((p: any) => p.provider === provider);
            return (decodedTokenId?.identities[providerIndex as number].is_wallet_registered &&
              decodedTokenId?.identities[providerIndex].wallet_version === "2.0.0") as boolean;
          }
          return (decodedTokenId?.is_wallet_registered && decodedTokenId?.wallet_version === "2.0.0") as boolean;
        } catch (error) {
          return false;
        }
      }
      return false;
    } catch (error) {
      console.error(error, "@errorWhileCheckUpbondUser");
      return false;
    }
  };

  async function getAddress() {
    const pkHex = privKeyTo64();
    const privKey = toBuffer(`0x${pkHex}`);
    const wallet = privateToAddress(privKey).toString("hex");
    return `0x${wallet}`;
  }

  function privKeyTo64() {
    let privKey = localStorage.getItem("subkey") as any;
    if (privKey.length < 64) privKey = `0${privKey}`;
    if (privKey.length > 64) privKey = privKey.slice(1);

    return privKey;
  }

  const recoverShare = async (value: any) => {
    if (!tKey) {
      console.log("tKey not initialized yet");
      return;
    }
    try {
      await (tKey.modules.securityQuestions as any).inputShareFromSecurityQuestions(value, "mypassword?"); // 2/2 flow
      const { requiredShares } = tKey.getKeyDetails();
      if (requiredShares <= 0) {
        const reconstructedKey = await tKey.reconstructKey();
        localStorage.setItem("privateKey", reconstructedKey?.secp256k1Key.toString("hex"));
        setPrivateKey(reconstructedKey?.privKey.toString("hex"));
        const subKeyDerived = (window as any).OpenloginSubkey.subkey(
          reconstructedKey?.privKey.toString("hex").padStart(64, "0"),
          Buffer.from(process.env.REACT_APP_CLIENT_ID as any, "base64")
        );
        localStorage.setItem("subkey", subKeyDerived.padStart(64, "0"));
        const address = await getAddress();
        store.dispatch(walletActions.setSelectedAddress(address));
        localStorage.setItem("walletaddress", address);
      }
      const newShare = await tKey.generateNewShare();
      const shareStore = await tKey.outputShareStore(newShare.newShareIndex);
      await (tKey.modules.webStorage as any).storeDeviceShare(shareStore);

      window.location.href = `${window.location.origin}/wallet`;
      return localStorage.getItem("privateKey");
    } catch (error) {
      console.log(error);
    }
  };

  const initializeNewKey = async (torusKey: any | undefined, initializedDetails: any | undefined) => {
    try {
      const loginDetails = JSON.parse(localStorage.getItem("loginDetails") as string);

      const provider = localStorage.getItem("provider") as string;
      const check = await checkUpbondUser(provider, loginDetails);
      const shareDesc = Object.assign({}, initializedDetails.shareDescriptions);
      Object.keys(shareDesc).map((el) => {
        shareDesc[el] = shareDesc[el].map((jl: any) => {
          return JSON.parse(jl);
        });
      });

      try {
        await (torusKey.modules.webStorage as any).inputShareFromWebStorage(); // 2/2 flow
      } catch (e) {
        console.log(e);
      }

      // Checks the requiredShares to reconstruct the tKey,
      // starts from 2 by default and each of the above share reduce it by one.
      const { requiredShares } = torusKey.getKeyDetails();
      if (requiredShares <= 0) {
        const reconstructedKey = await torusKey.reconstructKey();
        localStorage.setItem("privateKey", reconstructedKey?.secp256k1Key.toString("hex"));
        setPrivateKey(reconstructedKey?.privKey.toString("hex"));

        const subKeyDerived = (window as any).OpenloginSubkey.subkey(
          reconstructedKey?.privKey.toString("hex").padStart(64, "0"),
          Buffer.from(process.env.REACT_APP_CLIENT_ID as any, "base64")
        );
        localStorage.setItem("subkey", subKeyDerived.padStart(64, "0"));
        const address = await getAddress();
        store.dispatch(walletActions.setSelectedAddress(address));
        localStorage.setItem("walletaddress", address);

        if (!check) {
          history.replace("/regis-tkey-input");
        }

        return reconstructedKey?.privKey.toString("hex");
      }

      await torusKey?.syncLocalMetadataTransitions();
      return torusKey?.privKey?.toString("hex");
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getUser = async () => {
    try {
      await tKey.init({ skipSW: true });
      const loginDetails = (await (tKey as any)?.directWeb.getRedirectResult({
        replaceUrl: true,
        clearLoginDetails: true
      })) as any;
      console.log(loginDetails);
      if (loginDetails && loginDetails.result && Object.prototype.hasOwnProperty.call(loginDetails.result, "userInfo")) {
        setResult(loginDetails.result);
        setUser((result.result as any).userInfo);
        setOAuthShare((result.result as any).privateKey);
        const inputHex = JSON.stringify((loginDetails.result as any).privateKey).replace(/"/g, "");
        const inputBytes = hexToBytes(inputHex);
        const outputBytes = padBytesTo32(inputBytes);

        // Convert the result back to a hexadecimal string
        const outputHex = outputBytes.map((byte: any) => byte.toString(16).padStart(2, "0")).join("");

        localStorage.setItem("userInfo", JSON.stringify((loginDetails.result as any).userInfo));
        localStorage.setItem("privKey", outputHex);
        localStorage.setItem("loginDetails", JSON.stringify(loginDetails.result));

        const serviceProvider = new ServiceProviderBase({ postboxKey: loginDetails?.result?.privateKey?.padStart(64, "0") }) as any;
        const storageLayer = new TorusStorageLayer({ hostUrl: "https://metadata.tor.us" });
        const tKey = new TKeyDefault({
          serviceProvider,
          storageLayer,
          manualSync: true,
          modules: {
            [SECURITY_QUESTIONS_MODULE_NAME]: new SecurityQuestionsModule(),
            [WEB_STORAGE_MODULE_NAME]: new WebStorageModule(),
            [SHARE_TRANSFER_MODULE_NAME]: new ShareTransferModule()
          }
        });
        tKey.serviceProvider.postboxKey = new BN((loginDetails.result || "").privateKey || "", "hex");

        const details = await tKey.initialize();

        await initializeNewKey(tKey, details);

        history.replace("/wallet");
      }
    } catch (err: any) {
      return new Error(err);
    }
  };

  const getChainID = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const web3 = new Web3(provider);
    await web3.eth.getChainId();
  };

  const getAccounts = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const web3 = new Web3(provider);
    const address = (await web3.eth.getAccounts())[0];
    return address;
  };

  const getBalance = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const web3 = new Web3(provider);
    const address = (await web3.eth.getAccounts())[0];
    const balance = web3.utils.fromWei(
      await web3.eth.getBalance(address) // Balance is in wei
    );
    return balance;
  };

  const login = async (val: string) => {
    if (!tKey) {
      console.log("tKey not initialized");
    }

    localStorage.setItem("provider", val);
    await tKey.init({ skipInit: true });
    const loginConnections = LOGIN_CONNECTIONS[val !== "line" ? "jwt" : val];
    tKey?.directWeb.triggerLogin(loginConnections);
  };

  const contextProvider: tKeyContextType = {
    tKey,
    provider,
    isLoading,
    recoverShare,
    user,
    result,
    chain,
    generateNewShareWithPassword,
    backupShareRecover,
    generateMnemonic,
    resetAccount,
    getAccounts,
    getChainID,
    getBalance,
    getUser,
    changeSecurityQuestionAndAnswer,
    login
  };

  return <tKeyContext.Provider value={contextProvider}>{children}</tKeyContext.Provider>;
};
