/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useAppSelector } from "hooks/useRedux";
import { AccountHistoryContentType } from "interfaces/ui/IMenu";
import { useTranslation } from "react-i18next";
import EmptyTransaction from "features/account/components/EmptyTransaction";
import ItemHistory from "features/account/components/ItemHistory";
import MoralisItemHistory from "features/account/components/MoralisItemHistory";
import SpinnerCenter from "features/account/components/SpinnerCenter";

const AccountHistoryContent = ({
  astarItems,
  isAstar,
  moralisItems,
  handleModalMoralis,
  handleModalAstar,
  setOpenModalHistory,
  detailsLoading
}: AccountHistoryContentType) => {
  const { selectedAddress: account } = useAppSelector((state) => state.wallet);
  const accountLowerCase = account ? account.toLowerCase() : "Loading ...";

  const { t } = useTranslation();

  return (
    <>
      {detailsLoading ? (
        <SpinnerCenter />
      ) : (
        <>
          {!detailsLoading && isAstar ? (
            <>
              {astarItems && astarItems?.items?.length > 0 ? (
                astarItems?.items?.map((thisHistory, index) => (
                  <ItemHistory
                    key={`history_${index}`}
                    account={accountLowerCase}
                    handlePresent={() => handleModalAstar(thisHistory)}
                    item={thisHistory}
                  />
                ))
              ) : (
                <EmptyTransaction />
              )}
              {astarItems && astarItems?.haveMore ? (
                <div className="text-center cursor-pointer text-gray-500 py-3" onClick={() => setOpenModalHistory(true)}>
                  {t("account.seeMore")}
                </div>
              ) : null}
            </>
          ) : (
            <>
              {moralisItems && moralisItems?.items?.length > 0 ? (
                moralisItems?.items?.map((thisHistory, index) => (
                  <MoralisItemHistory
                    key={`history_${index}`}
                    account={accountLowerCase}
                    handlePresentMoralis={() => handleModalMoralis(thisHistory)}
                    item={thisHistory}
                  />
                ))
              ) : (
                <EmptyTransaction />
              )}
              {moralisItems && moralisItems?.haveMore ? (
                <div className="text-center cursor-pointer text-gray-500 py-3" onClick={() => setOpenModalHistory(true)}>
                  {t("account.seeMore")}
                </div>
              ) : null}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AccountHistoryContent;
