import { motion } from "framer-motion";
import useCommunicationStream from "hooks/useCommunicationStream";
import { useEmbedUiDispatch } from "hooks/useEmbedUiDispatch";
import { useAppSelector } from "hooks/useRedux";

const WidgetCard = ({ children }: { children: any }) => {
  const embedState = useAppSelector((state) => state.embedState);
  const uiDispatch = useEmbedUiDispatch();
  const { openPopup } = useCommunicationStream();
  return (
    <motion.div
      id="embed-widget"
      className={`${
        embedState.iFrameConfiguration.open == true
          ? "w-full h-screen flex justify-start items-end"
          : "w-full h-screen flex justify-start items-end"
      }`}
      initial={{ transform: "translate(-40em, 40em)" }}
      animate={{ transform: "translate(0px, 0px)" }}
      transition={{ delay: 0.2 }}
      exit={{ transform: "translate(-20em, 20em)" }}
      onClick={() => {
        uiDispatch("modalIsOpen", false);
        uiDispatch("widgetIsOpen", false);
        openPopup(false, false);
      }}>
      {children}
    </motion.div>
  );
};

export default WidgetCard;
