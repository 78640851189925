// @ts-nocheck
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { IonModal, IonIcon } from "@ionic/react";
import { QRCode } from "react-qrcode-logo";
import { copyToClipboard } from "shared/utils/coreUtils";
import { ModalQrTypeMb } from "interfaces/ui/IMenu";
import { close, copyOutline } from "ionicons/icons";
import Logo from "assets/images/upbondlg.jpg";
import { useTranslation } from "react-i18next";

const ModalQrMb = ({ modal, openModalQRCode, setOpenModalQRCode, setOpenQr, openQr, account }: ModalQrTypeMb) => {
  const { t } = useTranslation();

  return (
    <IonModal
      id="example-modal"
      backdropDismiss={false}
      ref={modal}
      className="modal-mobile ion-padding"
      trigger="open-modal"
      isOpen={openModalQRCode}>
      <div className="wrapper-mobile">
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IonIcon
            onClick={() => {
              setOpenModalQRCode(false);
            }}
            src={close}
            style={{ cursor: "pointer", marginBottom: "10%", alignSelf: "end" }}
            slot="end"></IonIcon>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center"
          }}>
          <div style={{ fontWeight: "bold" }}>{t("receive.title")}</div>
          <QRCode
            value={account}
            ecLevel={"H"}
            // removeQrCodeBehindLogo={true}
            qrStyle={"dots"}
            eyeRadius={1}
            quietZone={30}
            size={150}
            logoImage={Logo}
            logoWidth={32}
            logoHeight={32}
          />
          <div style={{ fontWeight: "bold" }}>{t("receive.yourWallet")}</div>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              margin: "10px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 12
            }}
            onClick={() => {
              copyToClipboard(account);
              setOpenQr(!openQr);
            }}>
            {account.substring(0, 5) + "..." + account.substring(30, account.length)}
            <IonIcon style={{ width: "20px", paddingLeft: "10px" }} slot="start" icon={copyOutline} />
          </div>
          {openQr && (
            <div className="tooltip-set-qr" style={{ right: localStorage.getItem("lang") === "ja" ? 15 : -20 }}>
              <div
                className="bottom"
                style={{
                  minWidth: localStorage.getItem("lang") === "ja" ? 220 : 128,
                  padding: localStorage.getItem("lang") === "ja" ? "6px 13px" : "6px 9px"
                }}>
                {t("account.copiedToClipboard")}
                <i></i>
              </div>
            </div>
          )}
        </div>
      </div>
    </IonModal>
  );
};

export { ModalQrMb };
