// @ts-nocheck
import { IonCheckbox, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage } from "@ionic/react";
import HeaderMainMb from "components/header/mb";
import { useTranslation } from "react-i18next";

const PermissionDetailMainMb: React.FC<any> = ({ selectedPermission }) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <HeaderMainMb title={t("settings.managePermission_title")} link="/settings/init" />

      <IonContent className="ion-padding setting-content">
        <p> {t("settings.managePermission_subtitle")}</p>
        <IonList className="permission-form-list setting-content">
          {selectedPermission.userData &&
            Object.keys(selectedPermission.userData).map((x, i) => {
              return (
                <>
                  <IonItem key={x + i} className="personal-wrapper font-normal text-base leading-[19px] text-[#5d5d5d]">
                    <div className="format-label">
                      <div className="format-label-start">
                        <IonLabel id="title" position="stacked">
                          {t(`settings.${x}`)}
                        </IonLabel>
                        <div className="required-field">{t("consentsForm.required")}</div>
                      </div>
                      <IonCheckbox slot="end" disabled checked></IonCheckbox>
                    </div>
                    <IonInput placeholder="Enter name" value={selectedPermission.userData[x]} className="permission" disabled></IonInput>
                  </IonItem>
                </>
              );
            })}
        </IonList>
        {/* Need discuss for this button */}
        {/* <div className="button-containers">
          <IonButton onClick={() => save()} shape="round" color="primary" expand="block">
            {t("settings.save")}
          </IonButton>
          <IonButton
            shape="round"
            color="danger"
            expand="block"
            fill="outline"
            onClick={() => {
              presentAlert({
                header: `${t("settings.delete_account")} ?`,
                message: t("settings.delete_account_confirm"),
                cssClass: "delete-account",
                buttons: [
                  {
                    text: t("settings.cancel"),
                    cssClass: "alert-button-cancel"
                  },
                  {
                    text: t("settings.delete"),
                    cssClass: "alert-button-confirm",
                    handler: () => {
                      deletePermission();
                    }
                  }
                ]
              });
            }}>
            {t("settings.delete_account")}
          </IonButton>
        </div> */}
      </IonContent>
    </IonPage>
  );
};

export default PermissionDetailMainMb;
