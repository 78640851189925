/* eslint-disable @typescript-eslint/no-unused-vars */
import torus from "libs/TorusExtended";
import BigNumber from "bignumber.js";
import TokenHandler from "handlers/token/TokenHandler";
import {
  CONTRACT_TYPE_ERC1155,
  CONTRACT_TYPE_ERC20,
  CONTRACT_TYPE_ERC721,
  MESSAGE_TYPE,
  TRANSACTION_ENVELOPE_TYPES,
  TRANSACTION_TYPES
} from "shared/enums";
import { fromWei } from "web3-utils";
import { useEffect, useState } from "react";
import { ConfirmModal, selectedCurrency, triggerDenyFn, triggerSignFn } from "interfaces/ui/IConfirm";
import { addressSlicer, getFungibleTokenStandard, significantDigits, toChecksumAddressByChainId } from "shared/utils/coreUtils";
import { DataSignPopup, EthSignPopup, PersonalSignPopup, SignTxPopup, SwitchNetworkPopup } from "features/confirm/components/popup";
import { useAppSelector } from "hooks/useRedux";

const weiInGwei = new BigNumber("10").pow(new BigNumber("9"));
const ConfirmForm: any = ({
  currentConfirmModal,
  triggerSign,
  triggerDeny
}: {
  currentConfirmModal: ConfirmModal;
  triggerSign: triggerSignFn;
  triggerDeny: triggerDenyFn;
}) => {
  const [selectedCurrency, setSelectedCurrency] = useState<selectedCurrency>();
  const [currencyData, setCurrencyData] = useState<any>({});
  const [balance, setBalance] = useState<any>(new BigNumber("0"));
  const [networkDetails, setNetworkDetails] = useState<any>();
  const [gasFees, setGasFees] = useState<any>();
  const [type, setType] = useState<string>();
  const [origin, setOrigin] = useState<{
    hostname: string;
    href: string;
  }>({
    hostname: "",
    href: ""
  });
  const [contractType, setContractType] = useState<string>(CONTRACT_TYPE_ERC20);
  const [assetParams, setAssetParams] = useState<any>();
  const [isSpecialContract, setIsSpecialContract] = useState<boolean>(false);
  const [txParams, setTxParams] = useState<any>({});
  const [txObject, setTxObject] = useState<any>({});
  const [selectedToken, setSelectedToken] = useState<any>();
  const [transactionCategory, setTransactionCategory] = useState<any>();
  const [amountTo, setAmountTo] = useState<number>();
  const [amountValue, setAmountValue] = useState<BigNumber>();
  const [errorMsg, setErrorMsg] = useState<string>();
  const [canShowError, setCanShowError] = useState<boolean>(false);
  const [topUpErrorShow, setTopUpErrorShow] = useState<boolean>(false);

  const [id, setId] = useState<number>(0);
  const [message, setMessage] = useState<string>();
  const [sender, setSender] = useState<string>();
  const [typedMessages, setTypedMessages] = useState<string>();

  const [initialMaxFeePerGas, setInitialMaxFeePerGas] = useState<any>();
  const [initialMaxPriorityFeePerGas, setInitialMaxPriorityFeePerGas] = useState<any>();
  const [activePriorityFee, setActivePriorityFee] = useState<any>(new BigNumber("0"));
  const [currencyRateDate, setCurrencyRateDate] = useState<any>();
  const [receiver, setReceiver] = useState<any>();
  const [dollarValue, setDollarValue] = useState<any>();
  const [balanceUsd, setBalanceUsd] = useState<any>();
  const [gasEstimateDefault, setGasEstimateDefault] = useState<any>();
  const [gasEstimated, setGasEstimate] = useState<any>(new BigNumber("0"));
  const [txData, setTxData] = useState<any>();
  const [txDataParams, setTxDataParams] = useState<any>();
  const [gasCost, setGasCost] = useState<any>();
  const [txFees, setTxFees] = useState<any>();
  const [totalEthCost, setTotalEthCost] = useState<any>();
  const [totalEthCostDisplay, setTotalEthCostDisplay] = useState<any>();
  const [totalUsdCost, setTotalUsdCost] = useState<any>();
  const [value, setValue] = useState<any>(new BigNumber("0"));
  const [gasPriced, setGasPrice] = useState<any>(new BigNumber("10"));
  const [network, setNetwork] = useState<any>();

  const embedUiState = useAppSelector((state) => state.embedUiState);

  const [currencyMultiplier, setCurrencyMultiplier] = useState<any>(1);
  const [nonce, setNonce] = useState<number>(-1);

  const getDate = () => {
    const currentDateTime = new Date();
    let hours = currentDateTime.getHours();
    const minutes = currentDateTime.getMinutes();
    const seconds = currentDateTime.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours %= 12;
    hours = hours || 12;
    return `${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const slicedAddress = (user: string) => {
    return addressSlicer(user) || "0x";
  };

  const amountDisplay = (amount: number) => {
    return significantDigits(amount || new BigNumber("0"));
  };

  const updateConfirmModal = async () => {
    try {
      if (!currentConfirmModal) return;
      const { type, msgParams, txParams, origin, balance, selectedCurrency, currencyData, network, networkDetails, gasFees } =
        currentConfirmModal || {};
      setNetwork(network);
      setSelectedCurrency(selectedCurrency);
      setOrigin(origin);
      setCurrencyData(currencyData);
      const balanceBigNumber = new BigNumber(balance);
      setBalance(balanceBigNumber);
      setNetworkDetails(networkDetails);
      setGasFees(gasFees);
      if (type === MESSAGE_TYPE.WATCH_ASSET) {
        setAssetParams({ ...msgParams?.msgParams.assetParams });
      }
      if (type === MESSAGE_TYPE.ETH_DECRYPT) {
        const { msgParams: { data = "", from = "" } = {}, id = 0 } = msgParams || {};
        setId(id);
        setMessage(data);
        setSender(from);
      } else if (type !== TRANSACTION_TYPES.STANDARD_TRANSACTION) {
        const { msgParams: { message = "", typedMessages = "" } = {}, id = 0 } = msgParams || {};
        let finalTypedMessages = typedMessages;
        try {
          finalTypedMessages = typedMessages && JSON.parse(typedMessages);
        } catch (error) {
          console.error(error);
        }
        setId(id);
        setMessage(message);
        setTypedMessages(finalTypedMessages);
      } else {
        let finalValue = new BigNumber("0");
        const { simulationFails, id, transactionCategory, methodParams, contractParams, txParams: txObject, userInfo } = txParams || {};
        const { value, to, data, from: sender, gas, gasPrice, maxFeePerGas, maxPriorityFeePerGas } = txObject || {};
        const { reason = "" } = simulationFails || {};
        setTxParams({ simulationFails, id, transactionCategory, methodParams, contractParams, txParams: txObject, userInfo });
        setTxObject({ value, to, data, from: sender, gas, gasPrice, maxFeePerGas, maxPriorityFeePerGas });

        if (value) {
          finalValue = new BigNumber(fromWei(value.toString()));
        }
        let txDataParameters = "";
        if (
          contractParams &&
          contractParams.isSpecial &&
          (transactionCategory as string).toLowerCase() === TRANSACTION_TYPES.TOKEN_METHOD_TRANSFER
        ) {
          txDataParameters = methodParams as any;
          setContractType(CONTRACT_TYPE_ERC721);
          setIsSpecialContract(true);
        } else if (contractParams && contractParams.erc1155) {
          txDataParameters = methodParams as any;
          setContractType(CONTRACT_TYPE_ERC1155);
        } else if (contractParams && contractParams.erc721) {
          txDataParameters = methodParams as any;
          setContractType(CONTRACT_TYPE_ERC721);
        } else if (contractParams && contractParams.erc20) {
          txDataParameters = methodParams as any;
          setContractType(CONTRACT_TYPE_ERC20);
        }

        // Get Params from method type ABI
        let amountTo;
        let amountValue;
        if (methodParams && Array.isArray(methodParams)) {
          if (
            transactionCategory === TRANSACTION_TYPES.TOKEN_METHOD_TRANSFER_FROM ||
            transactionCategory === TRANSACTION_TYPES.COLLECTIBLE_METHOD_SAFE_TRANSFER_FROM
          ) {
            [, amountTo, amountValue] = methodParams || [];
          } else [amountTo, amountValue] = methodParams || [];
        }
        const checkSummedTo = toChecksumAddressByChainId(to as string, network.chainId);
        const tokenObject = contractParams as any;
        let decimals = new BigNumber("0");
        if (tokenObject.decimals) {
          decimals = new BigNumber(tokenObject.decimals);
        } else if (!tokenObject.decimals && tokenObject.erc20) {
          const tokenHandler = new TokenHandler({
            ...tokenObject,
            address: checkSummedTo,
            web3: torus.web3
          });
          decimals = new BigNumber(await tokenHandler.getDecimals());
        }
        setId(id as number);
        setSelectedToken(tokenObject.erc20 ? getFungibleTokenStandard(txParams?.chainId) : tokenObject.symbol);
        setTransactionCategory(transactionCategory);
        const gweiGasPrice = new BigNumber((maxFeePerGas as any) || gasPrice, 16).div(weiInGwei);
        // sending to who
        setAmountTo(amountTo ? amountTo.value : checkSummedTo);
        // sending what value
        const setAmtValue = amountValue
          ? new BigNumber(amountValue.value).div(new BigNumber(10).pow(new BigNumber(decimals)))
          : new BigNumber("0");
        setAmountValue(setAmtValue);

        setInitialMaxFeePerGas(new BigNumber(maxFeePerGas || 0, 16).div(weiInGwei));
        const initialMaxPriorityFeePerGas = new BigNumber(maxPriorityFeePerGas || 0, 16).div(weiInGwei);
        setInitialMaxPriorityFeePerGas(initialMaxPriorityFeePerGas);
        setActivePriorityFee(initialMaxPriorityFeePerGas);
        setCurrencyRateDate(getDate());
        setReceiver(amountTo);
        setValue(finalValue); // value of eth sending
        setDollarValue(significantDigits(finalValue.times(currencyMultiplier)));
        setGasPrice(gweiGasPrice); // gas price in gwei
        setBalanceUsd(significantDigits(balanceBigNumber.times(currencyMultiplier))); // in usd
        const gasEstimated = new BigNumber(gas as any, 16); // gas number
        setGasEstimate(gasEstimated);
        setGasEstimateDefault(new BigNumber(gas as any, 16)); // gas number
        setTxData(data); // data hex
        const txDataParams = txDataParameters !== "" ? JSON.stringify(txDataParameters, null, 2) : "";
        setTxDataParams(txDataParams);
        setSender(sender);
        const gasCost = gasEstimated.times(gweiGasPrice).div(new BigNumber("10").pow(new BigNumber("9")));
        setGasCost(gasCost);
        setTxFees(gasCost.times(currencyMultiplier));
        const ethCost = finalValue.plus(gasCost);
        setTotalEthCost(ethCost);
        const gasCostLength = Math.max(significantDigits(gasCost).toString().length, significantDigits(ethCost).toString().length);
        const totalEthCostDisplay = significantDigits(ethCost, false, gasCostLength - 2 < 0 ? 0 : gasCostLength - 2);
        setTotalEthCostDisplay(totalEthCostDisplay);
        const totalUsdCost = significantDigits(ethCost.times(currencyMultiplier));
        setTotalUsdCost(totalUsdCost);

        if (reason) {
          setErrorMsg("reason");
          setCanShowError(true);
          // window.$crisp.push(["do", "chat:show"]);
        }
        if (balanceBigNumber.lt(ethCost) && !canShowError) {
          setErrorMsg("dappTransfer.insufficientFunds");
          setTopUpErrorShow(true);
          // window.$crisp.push(['do', 'chat:show'])
        } else if (errorMsg === "dappTransfer.insufficientFunds") {
          setErrorMsg("");
          setTopUpErrorShow(false);
        }
      }
      setType(type); // type of tx
    } catch (e) {
      console.error(e);
    }
  };

  const thewayTriggerSign = (dynamicParams?: any) => {
    if (dynamicParams && dynamicParams?.type && dynamicParams?.type === "provider_change") {
      triggerSign(dynamicParams);
    }

    const gasPriceHex = `0x${gasPriced.times(weiInGwei).toString(16)}`;
    const gasHex = gasEstimated.eq(new BigNumber("0")) ? undefined : `0x${gasEstimated.toString(16)}`;
    const customNonceValue = nonce >= 0 ? `0x${nonce.toString(16)}` : undefined;

    let gasPriceParams: any = {
      gasPrice: gasPriceHex
    };
    const isEip1559 = networkDetails.EIPS && networkDetails.EIPS["1559"];
    if (isEip1559) {
      const finalMaxPriorityFee = activePriorityFee;
      const finalMaxPriorityFeeHex = `0x${finalMaxPriorityFee.times(new BigNumber(10).pow(new BigNumber(9))).toString(16)}`;
      gasPriceParams = {
        maxFeePerGas: gasPriceHex,
        maxPriorityFeePerGas: finalMaxPriorityFeeHex
      };
    }
    let params: any = {
      id: id,
      gas: gasHex,
      customNonceValue,
      ...gasPriceParams
    };
    if (params.maxPriorityFeePerGas && params.maxFeePerGas) {
      params.txEnvelopeType = TRANSACTION_ENVELOPE_TYPES.FEE_MARKET;
    } else {
      params.txEnvelopeType = TRANSACTION_ENVELOPE_TYPES.LEGACY;
    }

    params = {
      ...params,
      txType: type,
      approve: true
    };

    if (dynamicParams) params = { ...params, dynamicParams };
    triggerSign(params);
  };

  useEffect(() => {
    if (currentConfirmModal) updateConfirmModal();
  }, [currentConfirmModal]);

  return (
    <>
      {type == TRANSACTION_TYPES.SIGN && (
        <EthSignPopup
          hostname={origin.hostname}
          message={message ? message : "Unknown"}
          thewayTriggerSign={thewayTriggerSign}
          triggerDeny={triggerDeny}
          data={currentConfirmModal || {}}
        />
      )}
      {type == TRANSACTION_TYPES.PERSONAL_SIGN && (
        <PersonalSignPopup
          hostname={origin.hostname}
          message={message ? message : "Unknown"}
          thewayTriggerSign={thewayTriggerSign}
          triggerDeny={triggerDeny}
          data={currentConfirmModal || {}}
        />
      )}
      {type == TRANSACTION_TYPES.SIGN_TYPED_DATA && (
        <DataSignPopup
          hostname={origin.hostname}
          message={message ? message : "Unknown"}
          thewayTriggerSign={thewayTriggerSign}
          triggerDeny={triggerDeny}
          data={currentConfirmModal || {}}
        />
      )}
      {type == TRANSACTION_TYPES.STANDARD_TRANSACTION && (
        <SignTxPopup
          hostname={origin.hostname}
          network={network}
          txParam={txParams ? txParams.txParams : null}
          thewayTriggerSign={thewayTriggerSign}
          triggerDeny={triggerDeny}
          data={currentConfirmModal || {}}
        />
      )}
      {type == "provider_change" && (
        <SwitchNetworkPopup
          hostname={origin.hostname}
          message={message ? message : "Unknown"}
          thewayTriggerSign={thewayTriggerSign}
          triggerDeny={triggerDeny}
          data={currentConfirmModal || {}}
        />
      )}
      {/*  @ucup Please add the condition */}
      {/* {type === "..." && ( */}
      {/* <DataConsentPopoup
        hostname={origin.hostname}
        message={message ? message : "Unknown"}
        thewayTriggerSign={thewayTriggerSign}
        triggerDeny={triggerDeny}
        data={currentConfirmModal || {}}
      /> */}
      {/* )} */}
    </>
  );
};

export default ConfirmForm;
