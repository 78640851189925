import { useState } from "react";
import useTheme from "hooks/useTheme";
import CurrencyMainMb from "features/settings/components/currencyMain/mb";
import CurrencyMainPc from "features/settings/components/currencyMain/pc";

const CurrencyPage: React.FC = () => {
  const [currencies, setCurrencies] = useState(localStorage.getItem("currency" || {}));
  const { breakpoint } = useTheme();

  const handleChangeCurrency = (val: string) => {
    localStorage.setItem("currency", val as string);
    setCurrencies(val);
  };

  return breakpoint < 1024 ? (
    <CurrencyMainMb currencies={currencies} handleChangeCurrency={handleChangeCurrency} />
  ) : (
    <CurrencyMainPc currencies={currencies} handleChangeCurrency={handleChangeCurrency} />
  );
};

export default CurrencyPage;
