// @ts-nocheck
import { IonButton, IonList, IonModal, useIonLoading, useIonRouter } from "@ionic/react";
import ERC20ABI from "abis/erc20.json";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import useWallet from "hooks/useWallet";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchTransferAmount } from "shared/actions/transferAction";
import { fromGweiToWeiHex, parseUnit } from "shared/utils/etherUtils";
import ConfirmTokenMainMb from "features/transfer/confirmToken/components/ConfirmTokenMain/mb";
import ConfirmTokenMainPc from "features/transfer/confirmToken/components/ConfirmTokenMain/pc";

const ConfirmTransferTokenPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useIonRouter();
  const transfer = useAppSelector((state) => state.transfer);
  const gas = useAppSelector((state) => state.gas);
  const { breakpoint } = useTheme();

  const { contractSend, changeEthProvider, getDecimal } = useWallet(
    {
      onSuccess() {
        // console.log(`success get wallet`);
      },
      onError(error) {
        console.error(`error while get wallet:`, error);
      }
    },
    false
  );
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [amountToSend, setAmountToSend] = useState<string>("0");
  const [totalSend, setTotalSend] = useState<number>(0);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [present, dismiss] = useIonLoading();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") > -1;
  const amountChanged = (e: any) => {
    let val = e.target.value;
    const expr = /^[0-9.,]*$/;
    if (!expr.test(e.target.value)) return;

    val = val.replace(/,/g, ".");
    if (val.includes(".")) {
      if (!/^\d+(\.\d+)?$/.test(val)) {
        setIsConfirmDisabled(true);
      } else {
        setIsConfirmDisabled(false);
      }
    } else {
      setIsConfirmDisabled(false);
    }

    const setTotal = parseFloat(val) + parseFloat(gas.totalFee);
    setAmountToSend(val);
    setTotalSend(setTotal);
  };
  const proceedTransfer = () => {
    setOpenConfirm(!openConfirm);
    dispatch(fetchTransferAmount(amountToSend.toString()));
  };

  const confirmTransaction = async () => {
    try {
      present({
        message: t("transfer.loading"),
        cssClass: "custom-loading"
      });
      setOpenConfirm(!openConfirm);
      const to = transfer.toAddress || "";
      const decimals = await getDecimal(transfer.detail?.contract_address || "", ERC20ABI.abi);
      const value = parseUnit(amountToSend, decimals.toString());
      const contract = transfer.detail?.contract_address;
      const maxFeePerGas = fromGweiToWeiHex(gas.maxBaseFee.toString());
      const maxPriorityFeePerGas = fromGweiToWeiHex(transfer.gas?.estimate.maxPriorityFeePerGas?.toString() || "0");
      await contractSend({
        contractAddress: contract || "",
        method: "transfer",
        abi: ERC20ABI.abi,
        option: { maxFeePerGas, maxPriorityFeePerGas },
        param: [to.trim(), value]
      });
      dismiss();
      setOpenConfirm(false);
      breakpoint < 1024 ? history.push(`/transfer/success`, "forward", "push") : history.push(`/wallet/tf/success`, "forward", "push");
    } catch (error: any) {
      dismiss();
      if (error.code === "UNPREDICTABLE_GAS_LIMIT" && error.reason === "execution reverted: ERC1155: insufficient balance for transfer")
        setErrorMessage("INSUFFICIENT_FUNDS");
      else {
        setErrorMessage(error.code);
      }
      setOpenErrorModal(true);
    }
  };

  const changeChain = async () => {
    await changeEthProvider(transfer.detail?.chain_id as string);
  };

  useEffect(() => {
    transfer.detail?.chain_id && changeChain();
  }, [transfer.detail?.chain_id]);

  useEffect(() => {
    transfer.amount && setAmountToSend(transfer.amount);
  }, [transfer.amount]);

  useEffect(() => {
    if (!transfer.toAddress) history.push("/wallet/transfer", "forward", "push");
  }, []);

  return (
    <>
      {breakpoint < 1024 ? (
        <ConfirmTokenMainMb
          isAndroid={isAndroid}
          amountToSend={amountToSend}
          amountChanged={amountChanged}
          isConfirmDisabled={isConfirmDisabled}
          proceedTransfer={proceedTransfer}
          openConfirm={openConfirm}
          confirmTransaction={confirmTransaction}
          setOpenConfirm={setOpenConfirm}
        />
      ) : (
        <ConfirmTokenMainPc
          amountToSend={amountToSend}
          amountChanged={amountChanged}
          isConfirmDisabled={isConfirmDisabled}
          proceedTransfer={proceedTransfer}
          openConfirm={openConfirm}
          confirmTransaction={confirmTransaction}
          totalSend={totalSend}
          setOpenConfirm={setOpenConfirm}
        />
      )}

      <IonModal
        id="modal-error"
        className="bg-[#000000_75] shadow-none outline-none radius-none"
        trigger="open-modal"
        isOpen={openErrorModal}>
        <div className="p-4 bg-white rounded-[30px] text-center">
          <IonList lines="none" className="text-center">
            {t("transfer.pleaseTryAgain")}
          </IonList>

          <IonList lines="none" className="text-center p-4">
            {t(`transfer.${errorMessage}`)}
          </IonList>
          <IonButton
            shape="round"
            color="primary"
            expand="full"
            onClick={async () => {
              setOpenErrorModal(false);
              await setTimeout(() => console.log(), 1000);
              if (errorMessage === "INVALID_ARGUMENT") history.push("/wallet/transfer", "forward", "push");
            }}>
            {t("account.ok")}
          </IonButton>
        </div>
      </IonModal>
    </>
  );
};

export default ConfirmTransferTokenPage;
