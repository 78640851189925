/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/useRedux";
import { MUX_CHUNK } from "shared/config/mux";
import { MuxSubStream } from "interfaces/ui/IEmbed";
import { useEmbedUiDispatch } from "./useEmbedUiDispatch";
import torus from "libs/TorusExtended";
import { updateNetworkDetail, updateNetworkId } from "shared/actions/walletAction";
import Torus from "@toruslabs/torus.js";
import { UpbondExtended } from "handlers/auth/OnloadHandler";

export type ChunkStream<T = { [x: string]: any }> = {
  name: string;
  data: T | Record<string, any>;
};

const useCommunicationStream = () => {
  const [controller, setController] = useState<Torus & UpbondExtended>();

  const dispatch = useAppDispatch();
  const embedUIDispatcher = useEmbedUiDispatch();

  useEffect(() => {
    if (torus) {
      setController(torus);

      torus.upbondController?.networkController.networkDetails.subscribe((state) => {
        dispatch(updateNetworkDetail({ networkDetails: state }));
      });

      torus.upbondController?.networkController.networkStore.subscribe((state: any) => {
        dispatch(updateNetworkId({ networkId: state }));
      });
    }
  }, [torus]);

  const _setup = () => {
    console.log(`Setup multiplex ..`);
  };

  const openPopup = (open: boolean, isCancelLogin = false) => {
    if (controller && controller.communicationMux) {
      const { communicationMux } = controller;
      const getCommunicationMux = (type: (typeof MUX_CHUNK)[keyof typeof MUX_CHUNK]) => communicationMux.getStream(type) as MuxSubStream;
      const widgetStream = getCommunicationMux(MUX_CHUNK.WIDGETSTREAM);
      const oAuthStream = getCommunicationMux(MUX_CHUNK.OAUTH);
      widgetStream.write({
        data: open
      });
      embedUIDispatcher("modalIsOpen", open);
      if (isCancelLogin) {
        oAuthStream.write({ err: { message: "User cancelled login" } });
      }
    }
  };

  return {
    openPopup,
    _setup
  };
};

export default useCommunicationStream;
