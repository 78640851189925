import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHistoryChainReducer } from "interfaces/actions/IHistoryTransaction";
import { IChains } from "interfaces/actions/IToken";
import { listChain } from "shared/config/chains";
const initHistoryCHain: IHistoryChainReducer = {
  historyChain:
    localStorage.getItem("chain") && localStorage.getItem("chain") !== undefined
      ? JSON.parse(localStorage.getItem("chain") ?? JSON.stringify(listChain[0]))
      : listChain[0]
};
const historyChainSlice = createSlice({
  name: "historyChain",
  initialState: initHistoryCHain,
  reducers: {
    setChain(state, action: PayloadAction<IChains>) {
      state.historyChain = action.payload;
    }
  }
});

export default historyChainSlice;
