import log from "loglevel";

const { env } = process;
export const setupLogs = () => {
  if (env.NODE_ENV === "development") {
    log.setLevel("DEBUG");
  } else if (env.NODE_ENV === "production") {
    log.setLevel("INFO");
  }
};
