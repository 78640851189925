import { BroadcastChannel } from "@toruslabs/broadcast-channel";
import { randomId } from "@toruslabs/openlogin-utils";
import log from "loglevel";
import { broadcastChannelOptions, fakeStream, getIFrameOrigin } from "shared/utils/coreUtils";
import torus from "libs/TorusExtended";
import { MuxSubStream } from "interfaces/ui/IEmbed";

class StreamWindow {
  preopenInstanceId: any;
  closed: boolean;
  closeHandler: any;
  url: any;
  preopenHandler: any;
  windowStream: MuxSubStream;
  writeInterval: any;

  constructor({ preopenInstanceId, url }: any) {
    this.preopenInstanceId = preopenInstanceId;
    this.closed = false;
    this.windowStream = fakeStream as any;
    if (torus && torus.communicationMux) {
      this.windowStream = (torus.communicationMux.getStream("window") as MuxSubStream) || fakeStream;
    }
    if (!preopenInstanceId) {
      this.preopenInstanceId = randomId();
      if (this.windowStream.on) {
        this.closeHandler = (chunk: any) => {
          if (chunk.name === "opened_window" && this.preopenInstanceId === chunk.data.preopenInstanceId) {
            this.open(url);
            if (this.windowStream.removeListener) this.windowStream.removeListener("data", this.closeHandler);
          }
        };
        this.windowStream.on("data", this.closeHandler);
      }
      this.windowStream.write({
        name: "create_window",
        data: {
          preopenInstanceId: this.preopenInstanceId,
          url
        }
      });
    }
  }

  open(url: any) {
    return new Promise<void>((resolve, reject) => {
      const bc = new BroadcastChannel(`preopen_channel_${this.preopenInstanceId}`, broadcastChannelOptions);

      this.url = url;
      bc.addEventListener("message", (ev) => {
        const { preopenInstanceId: openedId, message } = ev.data;
        if (this.preopenInstanceId === openedId && message === "popup_loaded") {
          if (this.writeInterval) clearInterval(this.writeInterval);
          bc.postMessage({
            data: {
              origin: getIFrameOrigin(),
              payload: { url: this.url },
              preopenInstanceId: this.preopenInstanceId
            }
          })
            .then(() => {
              bc.close();
              resolve();
            })
            .catch((error) => {
              log.error("Failed to communicate via preopen_channel", error);
              bc.close();
              reject(error);
            });
        }
      });
      this.writeInterval = setInterval(() => {
        bc.postMessage({
          data: {
            preopenInstanceId: this.preopenInstanceId,
            message: "setup_complete"
          }
        });
      }, 200);
      this.preopenHandler = (chunk: any) => {
        const { preopenInstanceId, closed } = chunk.data;
        if (preopenInstanceId === this.preopenInstanceId && closed) {
          this.closed = true;
          if (this.windowStream.removeListener) this.windowStream.removeListener("data", this.preopenHandler);
        }
      };
      if (this.windowStream.on) this.windowStream.on("data", this.preopenHandler);
    });
  }

  close() {
    this.windowStream.write({
      preopenInstanceId: this.preopenInstanceId,
      close: true
    });
    if (this.preopenHandler && this.windowStream.removeListener) this.windowStream.removeListener("data", this.preopenHandler);
    if (this.closeHandler && this.windowStream.removeListener) this.windowStream.removeListener("data", this.closeHandler);
  }
}

export default StreamWindow;
