import { mergeMiddleware } from "@toruslabs/openlogin-jrpc";
import { ProviderConfig } from "controllers/NetworkController";
import { PollingBlockTracker } from "libs/PollingBlockTracker";
import {
  createBlockCacheMiddleware,
  createBlockRefRewriteMiddleware,
  createBlockTrackerInspectorMiddleware,
  createFetchMiddleware,
  createInflightCacheMiddleware,
  providerFromMiddleware
} from "eth-json-rpc-middleware";

export type PromiseChainIdMiddleware = (
  req: {
    method: string;
  },
  res: {
    result: string | number;
  },
  next: () => void,
  end: () => void
) => void;

/**
 *
 *
 * @export
 * @param {ProviderConfig} opts
 * @return {*}
 */
export function createJsonRpcClient(opts: ProviderConfig) {
  const { rpcUrl, chainId } = opts;
  const fetchMiddleware = createFetchMiddleware({ rpcUrl });
  const blockProvider = providerFromMiddleware(fetchMiddleware);
  const blockTracker = new PollingBlockTracker({ provider: blockProvider });

  const networkMiddleware = mergeMiddleware([
    createChainIdMiddleware(chainId as number),
    createBlockRefRewriteMiddleware({ blockTracker: blockTracker as any }) as any,
    createBlockCacheMiddleware({ blockTracker: blockTracker as any }) as any,
    createInflightCacheMiddleware(),
    createBlockTrackerInspectorMiddleware({ blockTracker: blockTracker as any }),
    fetchMiddleware
  ]);
  return { networkMiddleware, blockTracker };
}

/**
 *
 *
 * @export
 * @param {number} chainId
 * @return {*}  {PromiseChainIdMiddleware}
 */
export function createChainIdMiddleware(chainId: number): PromiseChainIdMiddleware {
  return (req, res, next, end) => {
    if (req.method === "eth_chainId") {
      res.result = chainId;
      return end();
    }
    return next();
  };
}
