import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TorusKey } from "@toruslabs/customauth";
import { NetworkType } from "shared/actions/walletAction";
import {
  MAINNET_BLOCK_EXPLORER,
  MATIC,
  MATIC_CODE,
  MATIC_DISPLAY_NAME,
  MATIC_URL,
  SUPPORTED_NETWORK_TYPES,
  USER_INFO_REQUEST_APPROVED,
  USER_INFO_REQUEST_NEW,
  USER_INFO_REQUEST_REJECTED
} from "shared/enums";

export type UpbondUserInfo = {
  name: string; // first + last name
  profileImage: string; // image url
  email: string;
  verifier: string; // enum like GOOGLE
  verifierId: string; // usually email or facebook id
  verifierParams: any; // general params
  typeOfLogin: string;
};

export type OpenLoginData = {
  tKey: string;
  walletKey: string;
  privKey: string;
  ed25519PrivKey: string;
  oAuthPrivateKey: string;
  userKeyInfo: {
    key: TorusKey;
    walletKeyInfo: TorusKey;
    clientTimeOffset: number;
  };
  tkeyString: string;
  others: { [x: string]: any };
};

export type WalletState = {
  selectedAddress: string;
  wallet: { [x: string]: any };
  authServiceAccessToken: string | null;
  newUser: boolean;
  loading: boolean;
  prefs: {
    isChainIdLocked: boolean;
    networkDetails: { [x: string]: any };
    networkId: number;
    successMsg: string;
    errorMsg: string;
    weiBalance: {
      account: string;
      balance: string | number;
    };
    unapprovedEncryptionPublicKeyMsgs: { [x: string]: any };
    unapprovedDecryptMsgs: { [x: string]: any };
    unapprovedTypedMessages: { [x: string]: any };
    unapprovedPersonalMsgs: { [x: string]: any };
    unapprovedMsgs: { [x: string]: any };
    tokenRates: { [x: string]: any };
    selectedCurrency: string;
    currencyData: { [x: string]: any };
    addressState?: {
      accountType: string;
      badgesCompletion: { [x: string]: any };
      contracts: any[];
      customNfts: any[];
      customTokens: any[];
      defaultPublicAddress: string;
      fetchedPastTx: any[];
      jwtToken: { [address: string]: string };
      pastTransactions: any[];
      paymentTx: any[];
      permissions: any[];
      selectedCurrency: string;
      tKeyOnboardingComplete: boolean;
      theme: string;
    };
  };
  consentData: { [x: string]: any };
  encryptedConsentData: { [x: string]: any };
  runDidJob: boolean;
  consentConfigurations: {
    clientId: string;
    secretKey: string;
    scope: string[];
    requiredScope: string[];
    isDidDeployed: boolean;
    origin: string;
  };
  userInfo: UpbondUserInfo;
  postboxKey: string;
  networkType: {
    host: string;
    networkName: string;
    chainId: string | number;
    blockExplorer: string;
    ticker: string;
    logo: string;
    tickerName: string;
    rpcUrl: string;
  };
  openLoginData: OpenLoginData;
  supportedNetworks: typeof SUPPORTED_NETWORK_TYPES;
  userInfoAccess: typeof USER_INFO_REQUEST_APPROVED | typeof USER_INFO_REQUEST_NEW | typeof USER_INFO_REQUEST_REJECTED;
  isRehydrationComplete: boolean;
  transactions: [];
  assets: { [x: string]: any };
  loadingText: string;
  selectedNetwork: string;
  openModalPurchase: boolean;
  backupPhraseStatus: "success" | "fail" | "idle";
  backupPhrase: string[];
  relayerRegionURL: string;
};

export const walletInitialState = {
  selectedAddress: "",
  wallet: {},
  newUser: false,
  prefs: {
    isChainIdLocked: false,
    networkDetails: {},
    networkId: 0,
    successMsg: "",
    errorMsg: "",
    weiBalance: {
      account: "",
      balance: "0"
    },
    unapprovedEncryptionPublicKeyMsgs: {},
    unapprovedDecryptMsgs: {},
    unapprovedTypedMessages: {},
    unapprovedPersonalMsgs: {},
    unapprovedMsgs: {},
    selectedCurrency: "USD",
    currencyData: {},
    tokenRates: {},
    addressState: {}
  },
  userInfo: {
    name: "", // first + last name
    profileImage: "", // image url
    email: "",
    verifier: "", // enum like GOOGLE
    verifierId: "", // usually email or facebook id
    verifierParams: {}, // general params
    typeOfLogin: ""
  },
  postboxKey: "",
  networkType: {
    host: MATIC,
    chainId: MATIC_CODE,
    networkName: MATIC_DISPLAY_NAME,
    blockExplorer: MAINNET_BLOCK_EXPLORER,
    rpcUrl: MATIC_URL,
    logo: SUPPORTED_NETWORK_TYPES[MATIC].logo,
    ticker: "Matic",
    tickerName: "Matic"
  },
  supportedNetworks: SUPPORTED_NETWORK_TYPES,
  userInfoAccess: USER_INFO_REQUEST_NEW,
  isRehydrationComplete: false,
  transactions: [],
  assets: {},
  loading: false,
  runDidJob: false,
  loadingText: "Loading ...",
  openLoginData: {
    ed25519PrivKey: "",
    oAuthPrivateKey: "",
    privKey: "",
    tKey: "",
    walletKey: "",
    userKeyInfo: {},
    others: {},
    tkeyString: "{}"
  },
  selectedNetwork: MATIC,
  openModalPurchase: false,
  backupPhraseStatus: "idle",
  backupPhrase: [""],
  consentData: {},
  encryptedConsentData: {},
  consentConfigurations: {
    clientId: "",
    scope: [""],
    secretKey: "",
    requiredScope: [""],
    isDidDeployed: false,
    origin: ""
  },
  authServiceAccessToken: null,
  relayerRegionURL: process.env.REACT_APP_PUBLIC_RELAY_URL
} as WalletState;

const walletSlice = createSlice({
  name: "wallet",
  initialState: walletInitialState,
  reducers: {
    setConsentConfiguration(state, action) {
      state.consentConfigurations.clientId = action.payload.clientId;
      state.consentConfigurations.secretKey = action.payload.secretKey;
      state.consentConfigurations.scope = action.payload.scope;
      state.consentConfigurations.requiredScope = action.payload.requiredScope;
      state.consentConfigurations.origin = action.payload.origin;
    },
    resetConsentConfiguration(state) {
      state.consentConfigurations.clientId = "";
      state.consentConfigurations.secretKey = "";
      state.consentConfigurations.scope = [];
      state.consentConfigurations.requiredScope = [];
    },
    setDidStatus(state, action) {
      state.consentConfigurations.isDidDeployed = action.payload;
    },
    setRunDidJob(state, action) {
      state.runDidJob = action.payload;
    },
    setEncryptedData(state, action) {
      state.encryptedConsentData = action.payload;
    },
    setConsentData(state, action) {
      state.consentData = action.payload;
    },
    setSelectedNetwork(state, action: PayloadAction<string>) {
      state.selectedNetwork = action.payload;
    },
    setSelectedAddress(state, action: PayloadAction<string>) {
      state.selectedAddress = action.payload;
    },
    updateNetworkDetails(state, action) {
      state.prefs.networkDetails = action.payload;
    },
    updateNetworkId(state, action) {
      state.prefs.networkId = action.payload;
    },
    setWeiBalance(state, action) {
      state.prefs.weiBalance = action.payload;
    },
    setUnapprovedEncryptionPublicKeyMsgs(state, action) {
      state.prefs.unapprovedEncryptionPublicKeyMsgs = action.payload;
    },
    setUnapprovedDecryptMsgs(state, action) {
      state.prefs.unapprovedDecryptMsgs = action.payload;
    },
    setSuccessMsg(state, action) {
      state.prefs.successMsg = action.payload;
    },
    setChainIdLock(state, action) {
      state.prefs.isChainIdLocked = action.payload;
    },
    setErrorMsg(state, action) {
      state.prefs.errorMsg = action.payload;
    },
    setWallet(state, action) {
      state.wallet = action.payload;
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    setNewUser(state, action) {
      state.newUser = action.payload;
    },
    setTkeyString(state, action) {
      state.openLoginData.tkeyString = action.payload;
    },
    setNetworkType(state, action: PayloadAction<NetworkType>) {
      state.networkType = action.payload;
    },
    setSupportedNetworkType(state, action) {
      state.supportedNetworks = action.payload;
    },
    setUserInfoAccess(state, action) {
      if (action.payload) {
        state.userInfoAccess = USER_INFO_REQUEST_APPROVED;
      } else {
        state.userInfoAccess = USER_INFO_REQUEST_REJECTED;
      }
    },
    setLoading(
      state,
      action: PayloadAction<{
        loading: boolean;
        loadingText?: string;
      }>
    ) {
      state.loading = action.payload.loading;
      if (action.payload.loadingText) {
        state.loadingText = action.payload.loadingText;
      } else {
        state.loadingText = "Loading ... ";
      }
    },
    setPostboxKey(state, action) {
      state.postboxKey = action.payload;
    },
    setOpenLoginData(state, action) {
      state.openLoginData = action.payload;
      if (action.payload.others) {
        state.openLoginData.others = action.payload.others;
      }
    },
    setRehydration(state, action: PayloadAction<boolean>) {
      state.isRehydrationComplete = action.payload;
    },
    setBackupPhraseStatus(state, action: PayloadAction<{ status: "fail" | "success" | "idle"; backupPhrase: string[] }>) {
      state.backupPhrase = action.payload.backupPhrase;
      state.backupPhraseStatus = action.payload.status;
    },
    setUnapprovedMessage(state, action) {
      state.prefs.unapprovedMsgs = action.payload;
    },
    setPersonalMessages(state, { payload }) {
      state.prefs.unapprovedPersonalMsgs = payload;
    },
    setTypedMessage(state, action) {
      state.prefs.unapprovedTypedMessages = action.payload;
    },
    setTransactions(state, action) {
      state.transactions = action.payload;
    },
    setSelectedCurrency(state, action) {
      state.prefs.selectedCurrency = action.payload.selectedCurrency;
    },
    setInitialState(state) {
      Object.assign(state, walletInitialState);
    },
    setAddressState(
      state,
      action: PayloadAction<{
        accountType: string;
        badgesCompletion: { [x: string]: any };
        contracts: any[];
        customNfts: any[];
        customTokens: any[];
        defaultPublicAddress: string;
        fetchedPastTx: any[];
        jwtToken: { [x: string]: string };
        pastTransactions: any[];
        paymentTx: any[];
        permissions: any[];
        selectedCurrency: string;
        tKeyOnboardingComplete: boolean;
        theme: string;
      }>
    ) {
      state.prefs.addressState = action.payload;
    },
    setOpenModalPurchase(state, action) {
      state.openModalPurchase = action.payload;
    },
    setAuthServiceAccessToken(state, action) {
      state.authServiceAccessToken = action.payload;
    },
    setRelayerRegion(state, action) {
      state.relayerRegionURL = action.payload;
    }
  }
});

export default walletSlice;
