// @ts-nocheck
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import GoogleIcon from "assets/icons/Google.png";
import { ButtonsConfig } from "components/popups/PopupModal";
import { IonButton, IonContent, IonIcon, IonItemDivider, IonText } from "@ionic/react";
import LineIcon from "assets/icons/Line.svg";
import Logo from "assets/icons/LogoUpbond.svg";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { mail } from "ionicons/icons";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { triggerEmbed } from "shared/actions/walletAction";
import useTheme from "hooks/useTheme";
import { embedActions } from "shared/actions/embedAction";
import { listChain } from "shared/config/chains";
import { getBestRpc } from "shared/utils/filterRpc";

const LoginPage: React.FC = () => {
  const [buttons, setButtons] = useState<ButtonsConfig[] | []>([]);
  const [configLoading, setConfigLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const { breakpoint } = useTheme();

  const embedState = useAppSelector((state) => state.embedState);
  const dispatcher = useAppDispatch();

  const dynamicValue: { [key: string]: { bg: string; border: string; color: string; text: string } } = {
    "upbond-google": {
      bg: "#ffffff",
      border: "2px solid #000000",
      color: "#000000",
      text: "Google" + t("login.account")
    },
    "upbond-line": {
      bg: "#289B2A",
      border: "0px solid #fff",
      color: "#fafafa",
      text: "LINE" + t("login.account")
    },
    "upbond-otp": {
      bg: "#000",
      border: "0px solid #000000",
      color: "#fff",
      text: t("login.email")
    }
  };

  useEffect(() => {
    dispatcher(embedActions.resetEmbedConfiguration());
    const {
      configuration: { loginConfig }
    } = embedState;
    const loginConfArr = Object.values(loginConfig);

    const newButtonsConfig = [];
    for (const i in loginConfArr) {
      const obj = {
        bgColor: Object.getOwnPropertyNames(loginConfig)[i].includes("google") ? "#00000" : "#289B2A",
        textColor: "#fafafa",
        logo: Object.getOwnPropertyNames(loginConfig)[i].includes("google") ? GoogleIcon : LineIcon
      } as ButtonsConfig;
      if (loginConfArr[i].buttonBgColor) {
        obj.bgColor = loginConfArr[i].buttonBgColor as string;
      }
      if (loginConfArr[i].buttonTextColor) {
        obj.textColor = loginConfArr[i].buttonTextColor as string;
      }
      if (loginConfArr[i].logo) {
        obj.logo = loginConfArr[i].logo as string;
      }
      obj.name = loginConfArr[i].name;
      obj.verifier = Object.getOwnPropertyNames(loginConfig)[i] as string;
      newButtonsConfig.push(obj);
    }
    setButtons(newButtonsConfig as ButtonsConfig[]);
    if (newButtonsConfig.length > 0) {
      setConfigLoading(false);
    }
  }, [embedState.configuration.loginConfig]);

  const dynamicLink = (name: string) => {
    if (name === "terms") {
      if (embedState?.embedTheme?.termsLink?.termsCondition) {
        window.open(embedState?.embedTheme?.termsLink?.termsCondition);
      } else {
        window.open("https://upbond.notion.site/UPBOND-Wallet-Terms-of-Service-1b6d7c8f72a94f799c8ab1b82df6c799");
      }
    } else {
      if (embedState?.embedTheme?.termsLink?.privacyPolicy) {
        window.open(embedState?.embedTheme?.termsLink?.privacyPolicy);
      } else {
        window.open("https://www.upbond.io/privacy-policy");
      }
    }
  };

  useEffect(() => {
    initGetBestRpc();
  }, []);

  const initGetBestRpc = async () => {
    for (const network of listChain) {
      await getBestRpc(network.chain as string, []);
    }
  };

  const onTriggerLogin = async (verifier: string) => {
    const {
      configuration: {
        directConfig: { redirectUrl, enabled }
      }
    } = embedState;
    try {
      dispatcher(
        triggerEmbed({
          calledFromEmbed: true,
          dappRedirectUrl: redirectUrl,
          isUsingDirect: enabled,
          verifier,
          skipDialog: false,
          breakpoint
        })
      );
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return (
    <IonContent className="ion-padding !p-4 ps-4 pe-4 overflow-hidden" forceOverscroll>
      <div className="flex flex-col justify-center items-center max-w-500 lg:max-w-full my-0 mx-auto">
        <div className="mt-[5%] xs:mt-[20%] lg:mt-[5%] text-center font-normal h-37.5">
          <img src={Logo} alt="upbond-logo" />
        </div>

        <div className="text-center font-normal flex flex-col mx-0 my-[10%] xs:my-[30%] lg:my-[5%] lg:min-w-[30%] min-w-[80%]">
          <h5 className="text-base font-bold mb-[5%] leading-4.75 mt-4"> {t("login.title")}</h5>
          {!configLoading && buttons.length > 0 ? (
            <>
              {buttons.map((button, idx) => {
                return (
                  <div key={idx}>
                    {button.verifier === "upbond-otp" && (
                      <IonItemDivider className="bg-E4E4E4 w-full my-6 mx-0 h-px min-h-1"></IonItemDivider>
                    )}
                    <div key={button.name}>
                      <IonButton
                        expand="full"
                        shape="round"
                        fill="clear"
                        className="rounded-2.75xl h-12.5"
                        style={{
                          backgroundColor: dynamicValue[button.verifier].bg,
                          border: dynamicValue[button.verifier].border
                        }}
                        onClick={() => onTriggerLogin(button.verifier)}>
                        {idx !== 2 && <img src={button.logo} alt="logo" className="w-5 h-5 mr-1.5" />}
                        <IonText
                          className="font-roboto font-semibold text-lg leading-4.5"
                          style={{ color: dynamicValue[button.verifier].color }}>
                          {dynamicValue[button.verifier].text}
                        </IonText>
                      </IonButton>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <p>No login config, please setup your login config</p>
          )}
        </div>
        <div className="text-center font-normal flex flex-col justify-center px-4">
          <h5 className="text-base leading-4.75 mb-10 mt-4">
            {t("login.byTerm")}
            <span className="pointer underline text-blue-500 mx-1" onClick={() => dynamicLink("terms")}>
              {t("login.term")}
            </span>
            {t("login.and")}
            <span className="pointer underline text-blue-500 mx-1" onClick={() => dynamicLink("privacy")}>
              {t("login.privacy")}
            </span>
            {t("login.agree")}
          </h5>
          <IonButton
            onClick={() => window.open("https://www.upbond.io/inquiry-wallet")}
            shape="round"
            color="primary"
            fill="outline"
            size="default"
            className="text-base my-0 mx-auto">
            <IonIcon slot="start" icon={mail} />
            {t("login.contactUs")}
          </IonButton>
        </div>
      </div>
    </IonContent>
  );
};

export default LoginPage;
