import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import { decrypt, encrypt, EthEncryptedData, getEncryptionPublicKey } from "@metamask/eth-sig-util";

const GWEI = 1e9;
const GWEI_PRECISION = 9;

const numberParse = (value: string, decimals: number, fixedDecimal = 6): string =>
  Number(parseFloat(ethers.utils.formatUnits(value, decimals).toString()).toFixed(fixedDecimal)).toString();

const estimateGweiPrice = (gasLimit: number, val: number) => {
  const gas = gasLimit * val;
  return ethers.utils.formatUnits(parseInt(gas.toString()), "gwei");
};

const estimateBigIntGweiPrice = (gasLimit: bigint, val: bigint) => {
  const gas = gasLimit * val;
  return ethers.utils.formatUnits(gas, "gwei");
};

const estimateETHPrice = (gasLimit: string, val: string) => {
  const gas = parseInt(gasLimit) * parseInt(val);
  return ethers.utils.formatEther(gas.toString());
};

const formatEther = (val: number) => {
  return ethers.utils.formatUnits(val, "ether");
};

const estimateGasPrice = (gasLimit: number, val: number) => {
  const gas = gasLimit * val;
  return ethers.utils.formatUnits(parseInt(gas.toString()), "ether");
};

const parseEther = (val: string) => {
  return ethers.utils.parseEther(val);
};

const parseUnit = (val: string, decimals: number) => {
  return ethers.utils.parseUnits(val, decimals);
};

const fromGweiToWeiHex = (value: string) => {
  return fromNumberToHex(new BigNumber(value).multipliedBy(GWEI).decimalPlaces(0));
};

const fromNumberToHex = (amount: BigNumber) => {
  return `0x${new BigNumber(amount).toString(16)}`;
};

const toGwei = (amount: string) => {
  return new BigNumber(amount).multipliedBy(GWEI).decimalPlaces(GWEI_PRECISION);
};

const fromWeiToGwei = (amount: string) => {
  return new BigNumber(amount).dividedBy(GWEI).decimalPlaces(GWEI_PRECISION);
};

const encryptMessage = (privateKey: string, data: string | undefined, version = "x25519-xsalsa20-poly1305") => {
  const publicKey = getEncryptionPublicKey(privateKey);
  return encrypt({
    publicKey,
    data: data,
    version
  });
};
const decryptMessage = (data: EthEncryptedData, privKey: string) => {
  return decrypt({ encryptedData: data, privateKey: privKey });
};

const isJson = (data: string) => {
  try {
    JSON.parse(data);
    return true;
  } catch {
    return false;
  }
};

const decryptEncryptedData = (decryptedData: any, purePk: string, selectedAddress: string, didAddress = "0x", url: string) => {
  const newObj = {
    ...decryptedData
  };
  const date = new Date();
  date.setDate(date.getDate() + 7);
  const deadline = Math.floor(date.getTime() / 1000);

  Object.keys(newObj).forEach(function (key) {
    if (isJson(newObj[key])) {
      if (newObj[key]) newObj[key] = decryptMessage(JSON.parse(newObj[key]), purePk);
    }
  });
  const data = {
    data: newObj,
    deadline,
    origin: url,
    permited: selectedAddress,
    did: didAddress || "0x",
    status: "success"
  };
  return data;
};

export {
  estimateETHPrice,
  numberParse,
  estimateGweiPrice,
  fromGweiToWeiHex,
  toGwei,
  fromWeiToGwei,
  parseEther,
  parseUnit,
  estimateGasPrice,
  formatEther,
  encryptMessage,
  decryptMessage,
  decryptEncryptedData,
  estimateBigIntGweiPrice
};
