// @ts-nocheck
import { IonButton, IonItem, IonLabel, IonList } from "@ionic/react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { changeArrWc, fetchClose } from "shared/actions/walletConnectAction";
import { approveEIP155Request, rejectEIP155Request } from "shared/utils/walletconnect/EIP155RequestHandlerUtil";
import { getSignParamsMessage } from "shared/utils/walletconnect/HelperUtil";

import { IModalCloseWalletConnect } from "interfaces/actions/IWalletConnect";
import { useTranslation } from "react-i18next";
import "./styles/index.css";
import useWallet from "hooks/useWallet";
import { web3wallet } from "shared/utils/walletconnect/WalletConnectUtil";

export default function SessionSignModal(props: IModalCloseWalletConnect) {
  const { closeTheModalWalletConnect } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { ethWallet } = useWallet({
    onError(error) {
      console.warn(error);
    }
  });
  const walletConnect = useAppSelector((state) => state.walletConnect);

  // Get request and wallet data from store
  const requestEvent = walletConnect.data?.requestEvent;
  const requestSession = walletConnect.data?.requestSession;
  if (!requestEvent || !requestSession) {
    return (
      <>
        <IonLabel className="font-semibold text-base leading-[19px] text-[#0a0a0a] flex justify-center p-5 mb-2.5 h-[1px]">
          {t("sign_req_modal.title")}
        </IonLabel>
        <IonItem lines="full" className="modal-confirmation-header">
          <div className="w-full">
            <div className="flex justify-between mb-2.5 w-full h-auto">
              <p className="break-all font-semibold">{t("settings.missingRequestData")}</p>
            </div>
          </div>
        </IonItem>
        <IonList className="py-4 px-[22px] font-normal text-base leading-[19px] text-[#0a0a0a]" lines="none">
          <IonItem className="modal-confirmation-button-wrapper" lines="none">
            <IonButton onClick={() => onReject()} className="modal-confirmation-btn-reject no-padding-no" size="default" shape="round">
              {t("sign_req_modal.reject")}
            </IonButton>
          </IonItem>
        </IonList>
      </>
    );
  }

  // Get required request data
  const { topic, params } = requestEvent;
  const { request } = params;
  const message = getSignParamsMessage(request.params);

  // Handle approve action (logic varies based on request method)
  async function onApprove() {
    dispatch(changeArrWc(walletConnect));
    closeTheModalWalletConnect(false);
    if (requestEvent && ethWallet) {
      const response = await approveEIP155Request(ethWallet, requestEvent);
      await web3wallet.respondSessionRequest({
        topic,
        response
      });
    }

    dispatch(fetchClose());
  }

  // Handle reject action
  async function onReject() {
    dispatch(changeArrWc(walletConnect));
    closeTheModalWalletConnect(false);
    if (requestEvent) {
      const response = rejectEIP155Request(requestEvent);
      await web3wallet.respondSessionRequest({
        topic,
        response
      });
    }
    dispatch(fetchClose());
  }

  return (
    <>
      <IonLabel className="font-semibold text-base leading-[19px] text-[#0a0a0a] flex justify-center p-5 mb-2.5 h-[1px] font-roboto">
        {t("sign_req_modal.title")}
      </IonLabel>
      <IonItem lines="full" className="modal-confirmation-header">
        <div className="w-full my-4 font-roboto">
          <div className="flex justify-between mb-2.5 w-full h-auto">
            <p>{t("notification.requestFrom")}</p>
            <p className="break-all font-semibold">{requestSession.peer.metadata.name}</p>
          </div>
        </div>
      </IonItem>
      <IonList className="py-4 px-[22px] font-normal text-base leading-[19px] text-[#0a0a0a] font-roboto" lines="none">
        <IonLabel>{message}</IonLabel>
        <IonItem className="modal-confirmation-button-wrapper" lines="none">
          <IonButton onClick={() => onReject()} className="modal-confirmation-btn-reject no-padding-no" size="default" shape="round">
            {t("sign_req_modal.reject")}
          </IonButton>
          <IonButton onClick={() => onApprove()} className="no-padding-no" size="default" shape="round">
            {t("sign_req_modal.confirm")}
          </IonButton>
        </IonItem>
      </IonList>
    </>
  );
}
