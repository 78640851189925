import { safebtoa } from "@toruslabs/openlogin-utils";
import { EmbedState } from "interfaces/ui/index";
import { EmbedTheme, JwtParameters, LoginConfigItem } from "interfaces/ui/IEmbed";
import { randomId } from "helpers/randomIdHelper";
import PopupWithBcHandler from "handlers/stream/PopupBcHandler";

/**
 *
 *
 * @class OpenLoginHandler
 */
class OpenLoginHandler {
  /**
   *
   *
   * @memberof OpenLoginHandler
   */
  nonce = randomId();

  /**
   *
   *
   * @type {EmbedState}
   * @memberof OpenLoginHandler
   */
  embedState: EmbedState;

  /**
   *
   *
   * @type {string}
   * @memberof OpenLoginHandler
   */
  verifier: string;

  /**
   *
   *
   * @type {string}
   * @memberof OpenLoginHandler
   */
  preopenInstanceId?: string;

  /**
   *
   *
   * @type {string}
   * @memberof OpenLoginHandler
   */
  redirect_uri: string;

  /**
   *
   *
   * @type {*}
   * @memberof OpenLoginHandler
   */
  jwtParameters: any;

  /**
   *
   *
   * @type {*}
   * @memberof OpenLoginHandler
   */
  skipTKey: any;

  /**
   *
   *
   * @type {*}
   * @memberof OpenLoginHandler
   */
  whiteLabel: any;

  /**
   *
   *
   * @type {LoginConfigItem}
   * @memberof OpenLoginHandler
   */
  loginConfigItem: LoginConfigItem;

  /**
   *
   *
   * @type {boolean}
   * @memberof OpenLoginHandler
   */
  redirectToOpener: boolean;

  /**
   *
   *
   * @type {(URL | string)}
   * @memberof OpenLoginHandler
   */
  finalURL: URL | string;

  /**
   *
   *
   * @type {(boolean | undefined)}
   * @memberof OpenLoginHandler
   */
  usingIframe: boolean | undefined;

  /**
   * Creates an instance of OpenLoginHandler.
   * @param {{
   *     verifier: string;
   *     redirect_uri: string;
   *     preopenInstanceId?: string;
   *     jwtParameters: JwtParameters;
   *     skipTKey: boolean;
   *     whiteLabel?: EmbedTheme;
   *     loginConfigItem: LoginConfigItem;
   *     embedState: EmbedState;
   *   }} {
   *     verifier,
   *     redirect_uri,
   *     preopenInstanceId,
   *     jwtParameters,
   *     skipTKey,
   *     whiteLabel,
   *     loginConfigItem,
   *     embedState
   *   }
   * @memberof OpenLoginHandler
   */
  constructor({
    verifier,
    redirect_uri,
    preopenInstanceId,
    jwtParameters,
    skipTKey,
    whiteLabel,
    loginConfigItem,
    embedState,
    usingIframe
  }: {
    verifier: string;
    redirect_uri: string;
    preopenInstanceId?: string;
    jwtParameters: JwtParameters;
    skipTKey: boolean;
    whiteLabel?: EmbedTheme;
    loginConfigItem: LoginConfigItem;
    embedState: EmbedState;
    usingIframe?: boolean;
  }) {
    this.verifier = verifier;
    this.preopenInstanceId = preopenInstanceId;
    this.redirect_uri = redirect_uri;
    this.jwtParameters = jwtParameters;
    this.skipTKey = skipTKey;
    this.embedState = embedState;
    this.whiteLabel = whiteLabel;
    this.loginConfigItem = loginConfigItem;
    this.redirectToOpener = false;
    this.usingIframe = true;
    if (usingIframe !== undefined) this.usingIframe = usingIframe;
    this.finalURL = new URL(`${window.location.origin}/start`);
    this.setFinalUrl();

    if (!Object.keys(this.embedState.configuration.loginConfig).includes(this.verifier)) {
      throw new Error(`Please provide 'typeOfLogin' on your loginConfig`);
    }
  }

  get state() {
    return encodeURIComponent(
      safebtoa(
        JSON.stringify({
          instanceId: this.nonce,
          verifier: this.verifier,
          redirectToOpener: this.redirectToOpener || false,
          whiteLabel: this.whiteLabel || {},
          loginConfig: Object.keys(this.embedState.configuration.loginConfig).includes(this.verifier)
            ? { [this.loginConfigItem.loginProvider]: this.loginConfigItem }
            : {}
        })
      )
    );
  }

  setFinalUrl() {
    const finalUrl = new URL(`${window.location.origin}/start`);

    finalUrl.searchParams.append("state", this.state);
    finalUrl.searchParams.append("loginProvider", this.loginConfigItem.loginProvider);

    for (const x of Object.keys(this.jwtParameters)) {
      if (this.jwtParameters[x]) finalUrl.searchParams.append(x, this.jwtParameters[x]);
    }

    finalUrl.searchParams.append("skipTKey", this.skipTKey);

    this.finalURL = finalUrl;
  }

  async handleLoginWindow(dappRedirectUri = window.location.origin) {
    const { typeOfLogin, clientId } = this.loginConfigItem;
    if (!this.verifier || !typeOfLogin || !clientId) {
      throw new Error("Invalid params");
    }
    const lang = localStorage.getItem("lang");
    console.log("lang: ", lang);
    const channelName = `redirect_openlogin_channel_${this.nonce}`;

    this.finalURL += `&usingDirect=true&dappRedirectUri=${dappRedirectUri}&lang=${lang}`;
    const verifierWindow = new PopupWithBcHandler({
      channelName,
      url: this.finalURL,
      preopenInstanceId: this.preopenInstanceId as string,
      usingIframe: this.usingIframe
    });
    const result = await verifierWindow.handle(false);

    return result;
  }
}

export default OpenLoginHandler;
