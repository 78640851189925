// @ts-nocheck
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { checkmarkOutline } from "ionicons/icons";
import HeaderMainMb from "components/header/mb";

const LanguageMainMb: React.FC<any> = ({ handleSwitchLang }) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <HeaderMainMb title={t("settings.language")} link="/settings/init" />

      <IonContent className="ion-padding setting-content">
        <IonList className="backup-list px-4">
          <IonItem className="pb-[15px]">
            <IonLabel onClick={() => handleSwitchLang("en")}> {t("settings.english")}</IonLabel>
            {localStorage.getItem("lang") === "en" && <IonIcon src={checkmarkOutline} className="text-2xl pr-1" />}
          </IonItem>
          <IonItem className="pb-[15px]">
            <IonLabel onClick={() => handleSwitchLang("ja")}> {t("settings.japan")}</IonLabel>
            {localStorage.getItem("lang") === "ja" && <IonIcon src={checkmarkOutline} className="text-2xl pr-1" />}
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default LanguageMainMb;
