// @ts-nocheck
import { IonPage, IonContent, IonLabel, IonInput, IonFooter, IonButton } from "@ionic/react";
import ContactBar from "features/transfer/components/ContactBar";
import GasModal from "features/transfer/components/GasModal";
import { useTranslation } from "react-i18next";
import { ConfirmMainProps } from "features/transfer/confirm/types";
import HeaderMainMb from "components/header/mb";

const ConfirmMainMb = (props: ConfirmMainProps) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <HeaderMainMb title={t("transfer.confirmTransferTitle")} />

      <IonContent className="ion-padding">
        <ContactBar />
        <div className="pb-4">
          <IonLabel position="stacked">{t("transfer.amountToSend")}</IonLabel>
          <div className="flex flex-row justify-between items-center py-1 px-4 rounded-custom border border-[#d3d3d3] my-2.5">
            {props.isAndroid ? (
              <IonInput type="text" value={props.amountToSend} onIonChange={(e) => props.amountChanged(e)} />
            ) : (
              <IonInput type="text" value={props.amountToSend} onIonChange={(e) => props.amountChanged(e)} />
            )}
          </div>
        </div>
      </IonContent>

      <IonFooter>
        <div className="px-4">
          <IonButton
            expand="block"
            className="h-12"
            color={props.isConfirmDisabled ? "medium" : "primary"}
            onClick={props.proceedTransfer}
            disabled={props.isConfirmDisabled}>
            {t("transfer.proceed")}
          </IonButton>
        </div>
        <GasModal />
      </IonFooter>
    </IonPage>
  );
};

export default ConfirmMainMb;
