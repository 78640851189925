// @ts-nocheck
import { IonButton, IonCol, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import HeaderMainMb from "components/header/mb";

const BackupMainMb: React.FC<any> = ({
  phrases,
  openPhrase,
  setOpenPhrase,
  enablePhraseAndPasscode,
  setLoading,
  inputShare,
  onBlurPhrase,
  setOnBlurPhrase,
  lcLang,
  copyToClipboard,
  config,
  privKey,
  hidePrivKey,
  setHidePrivKey,
  padPrivateKey,
  open,
  setOpen
}) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <HeaderMainMb title={t("settings.backup")} link="/settings/init" />

      <IonContent className="ion-padding setting-content">
        <IonList className="backup-list px-4">
          {enablePhraseAndPasscode && (
            <IonItem>
              <IonLabel position="stacked">{t("settings.recoveryCode")}</IonLabel>
              <div className="w-full flex bg-[#fff] border border-solid border-gray-300 rounded-[20px] mb-2 pl-2 font-normal text-sm leading-4 text-gray-500">
                <div className="w-[86%]">
                  <IonInput value={"・・・・"} readonly></IonInput>
                </div>
              </div>
              <div className="w-full flex justify-end mb-10">
                <IonButton
                  slot="end"
                  className="font-bold text-sm leading-[22px] my-[2px] -mr-1 px-[5px] h-[30px] w-[80px] tracking-[0.5px]"
                  shape="round"
                  color="secondary"
                  onClick={() => {
                    setLoading(false);
                    inputShare();
                  }}>
                  <span className="text-[#214999] px-1">{t("settings.change")}</span>
                </IonButton>
              </div>
            </IonItem>
          )}

          {enablePhraseAndPasscode && (
            <IonItem>
              <IonLabel position="stacked">{t("settings.backupPrase")}</IonLabel>
              <IonButton
                className="eye-icons fixed right-4 pt-2 removeBgColor"
                onClick={() => {
                  setOnBlurPhrase(!onBlurPhrase);
                }}>
                {onBlurPhrase === false ? <IonIcon color="dark" src={eyeOutline} /> : <IonIcon color="dark" src={eyeOffOutline} />}
              </IonButton>
              {onBlurPhrase && phrases.length > 1 ? (
                <>
                  <IonItem lines="none" className="max-w-full">
                    <IonRow>
                      {phrases.map((data: any, i: any) => (
                        <IonCol size="3" key={i}>
                          <IonLabel
                            className="rounded-md capitalize text-center font-normal !text-xs !text-[#5d5d5d] bg-[#f9f9f9] p-[2px]"
                            position="stacked">
                            {data}
                          </IonLabel>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonItem>

                  {openPhrase && (
                    <div
                      className={`tooltip-bc relative inline-block text-left top-1`}
                      style={{ left: lcLang === "ja" ? `${window.innerWidth - 150}px` : `${window.innerWidth - 110}px` }}>
                      <div
                        className={`bottom absolute top-8 left-[0%] -translate-x-[50%] translate-y-[0] h-auto text-white-background p-2 bg-[#5d5d5d] font-normal text-xs rounded-lg z-50 box-border border border-solid border-[#5d5d5d] shadow-md visible opacity-100 transition-opacity duration-[800ms] ${
                          lcLang === "ja" ? "w-56" : "w-32"
                        }`}>
                        {t("account.copiedToClipboard")}
                        <i className="arrow-icon absolute bottom-full right-[10%] -mr-3 w-6 h-3 overflow-hidden"></i>
                      </div>
                    </div>
                  )}
                  <div className="w-full flex justify-end mb-10">
                    <IonButton
                      slot="end"
                      className={`font-bold text-sm leading-[22px] my-[2px] -mr-1 px-[5px] h-[30px] ${
                        localStorage.getItem("lang") === "ja" ? "w-[80px]" : "w-[60px]"
                      } tracking-[0.5px]`}
                      color="secondary"
                      shape="round"
                      onClick={() => {
                        copyToClipboard(phrases.toString().replaceAll(",", " "));
                        setOpenPhrase(!openPhrase);
                      }}>
                      <span className="text-[#214999] px-1">{t("settings.copy")}</span>
                    </IonButton>
                  </div>
                </>
              ) : (
                <IonItem lines="none" className="max-w-full">
                  <IonRow>
                    {config.dummyLabel.split(" ").map((_: any, i: any) => (
                      <IonCol size="3" key={i} className="bg-center bg-no-repeat bg-cover blur">
                        <IonLabel
                          className="rounded-md capitalize text-center font-normal !text-xs !text-[#5d5d5d] bg-[#f9f9f9] p-[2px]"
                          position="stacked">
                          {"nothing"}
                        </IonLabel>
                      </IonCol>
                    ))}
                  </IonRow>
                </IonItem>
              )}
            </IonItem>
          )}

          <IonItem lines="none" className="privKey">
            <IonLabel position="stacked">{t("settings.privateKey")}</IonLabel>
            <div className="w-full flex bg-[#fff] border border-solid border-gray-300 rounded-[20px] mb-2 pl-2 font-normal text-sm leading-4 text-gray-500">
              <div className="w-[86%]">
                <IonInput value={hidePrivKey === false ? "・・・・・・" : padPrivateKey(privKey)} readonly />
              </div>
              <IonButton className="eye-icon fixed right-4 my-[2px] mx-2 removeBgColor" onClick={() => setHidePrivKey((e: any) => !e)}>
                {hidePrivKey === false ? <IonIcon color="dark" src={eyeOutline} /> : <IonIcon color="dark" src={eyeOffOutline} />}
              </IonButton>
            </div>

            {open && (
              <div
                className={`tooltip-bc relative inline-block text-left top-1`}
                style={{ left: lcLang === "ja" ? `${window.innerWidth - 150}px` : `${window.innerWidth - 110}px` }}>
                <div
                  className={`bottom absolute top-8 left-[0%] -translate-x-[50%] translate-y-[0] h-auto text-white-background p-2 bg-[#5d5d5d] font-normal text-xs rounded-lg z-50 box-border border border-solid border-[#5d5d5d] shadow-md visible opacity-100 transition-opacity duration-[800ms] ${
                    lcLang === "ja" ? "w-56" : "w-32"
                  }`}>
                  {t("account.copiedToClipboard")}
                  <i className="arrow-icon absolute bottom-full right-[10%] -mr-3 w-6 h-3 overflow-hidden"></i>
                </div>
              </div>
            )}

            <div className="w-full flex justify-end mb-10">
              <IonButton
                onClick={() => {
                  copyToClipboard(padPrivateKey(privKey));
                  setOpen(!open);
                }}
                slot="end"
                className={`font-bold text-sm leading-[22px] my-[2px] -mr-1 px-[5px] h-[30px] ${
                  localStorage.getItem("lang") === "ja" ? "w-[80px]" : "w-[60px]"
                } tracking-[0.5px]`}
                color="secondary"
                shape="round">
                <span className="text-[#214999] px-1">{t("settings.copy")}</span>
              </IonButton>
            </div>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default BackupMainMb;
