// @ts-nocheck
import { IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonInput, IonIcon } from "@ionic/react";
import { useAppSelector } from "hooks/useRedux";
import { useTranslation } from "react-i18next";
import { ellipsisHorizontalCircle, personAddOutline } from "ionicons/icons";
import { AssetContactBarProps } from "features/transfer/assets/types";

const AssetContactBarPc = (props: AssetContactBarProps) => {
  const { t } = useTranslation();
  const transfer = useAppSelector((state) => state.transfer);

  return (
    <IonGrid>
      <IonRow>
        <IonCol className="p-0">
          <IonCard>
            <IonCardContent>
              <div className="flex flex-row justify-between items-center py-1 px-4 rounded-custom border border-slate-300 mb-1.5">
                {props.isNewAddress ? (
                  <>
                    <IonInput
                      placeholder={t("transfer.transferToPlaceholder") || ""}
                      value={
                        t("transfer.transferToPlaceholder") +
                        " " +
                        transfer.toAddress?.substring(0, 5) +
                        transfer.toAddress?.substring(transfer.toAddress?.length - 4, transfer.toAddress?.length)
                      }
                      disabled={true}
                    />
                    <IonIcon icon={personAddOutline} color="dark" onClick={() => props.setOpen(!props.open)} />
                  </>
                ) : (
                  <>
                    <IonInput
                      placeholder={t("transfer.transferToPlaceholder") || ""}
                      value={t("transfer.transferToPlaceholder") + " " + props.selectedContactName}
                      disabled={true}
                    />
                    <IonIcon icon={ellipsisHorizontalCircle} color="dark" onClick={() => props.setOpen(!props.open)} />
                  </>
                )}
              </div>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default AssetContactBarPc;
