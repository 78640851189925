import { Web3Wallet, IWeb3Wallet } from "@walletconnect/web3wallet";
import { Core } from "@walletconnect/core";
export let web3wallet: IWeb3Wallet;

export async function createWeb3Wallet(relayerRegionURL: string) {
  const core = new Core({
    projectId: process.env.REACT_APP_PUBLIC_PROJECT_ID,
    relayUrl: relayerRegionURL ?? process.env.REACT_APP_PUBLIC_RELAY_URL
  });
  web3wallet = await Web3Wallet.init({
    core,
    metadata: {
      name: "Upbond",
      description: "Upbond Wallet for WalletConnect",
      url: process.env.BASE_URL ?? process.env.PUBLIC_URL,
      icons: ["https://i.ibb.co/wBmybLc/company-button-logo-sample.png"]
    }
  });

  try {
    const clientId = await web3wallet.engine.signClient.core.crypto.getClientId();
    localStorage.setItem("WALLETCONNECT_CLIENT_ID", clientId);
  } catch (error) {
    console.error("Failed to set WalletConnect clientId in localStorage: ", error);
  }
}

export async function updateSignClientChainId(chainId: string, address: string) {
  console.log("chainId", chainId, address);
  // get most recent session
  const sessions = web3wallet.getActiveSessions();
  if (!sessions) return;
  const namespace = chainId.split(":")[0];
  Object.values(sessions).forEach(async (session) => {
    await web3wallet.updateSession({
      topic: session.topic,
      namespaces: {
        ...session.namespaces,
        [namespace]: {
          ...session.namespaces[namespace],
          chains: [...new Set([chainId].concat(Array.from(session.namespaces[namespace].chains || [])))],
          accounts: [...new Set([`${chainId}:${address}`].concat(Array.from(session.namespaces[namespace].accounts)))]
        }
      }
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const chainChanged = {
      topic: session.topic,
      event: {
        name: "chainChanged",
        data: parseInt(chainId.split(":")[1])
      },
      chainId: chainId
    };

    const accountsChanged = {
      topic: session.topic,
      event: {
        name: "accountsChanged",
        data: [`${chainId}:${address}`]
      },
      chainId
    };
    await web3wallet.emitSessionEvent(chainChanged);
    await web3wallet.emitSessionEvent(accountsChanged);
  });
}
