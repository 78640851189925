import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GasSetting } from "interfaces/actions/IGasPrice";

const initGas: GasSetting = {
  totalFee: "0",
  maxBaseFee: 0,
  maxPriorityFee: 0
};
const gasSlice = createSlice({
  name: "gas",
  initialState: initGas,
  reducers: {
    addGasNow(state, action: PayloadAction<GasSetting>) {
      state.totalFee = action.payload.totalFee;
      state.maxBaseFee = action.payload.maxBaseFee;
      state.maxPriorityFee = action.payload.maxPriorityFee;
    }
  }
});

export default gasSlice;
