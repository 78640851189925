import axios from "axios";

const gateway = [
  "https://ipfs.io/ipfs/{hash}",
  "https://cloudflare-ipfs.com/ipfs/{hash}",
  "https://{hash}.ipfs.dweb.link",
  "https://{hash}.ipfs.nftstorage.link"
];

export const filterGateway = async (urlIpfs: string) => {
  try {
    const listLoop: any = [];
    const fulfilledArr: any = [];
    const getHash = urlIpfs.replace("ipfs://", "");

    gateway.forEach(async (item) => {
      const dataHash = getHash.split("/");
      let url = item.replace("{hash}", dataHash[0]);
      if (dataHash[1]) url = url + "/" + dataHash[1];

      listLoop.push(
        axios({
          method: "head",
          url: url,
          timeout: 2000
        })
      );
    });

    const result: any = await Promise.allSettled(listLoop);
    result.forEach((ele: any) => {
      if (ele?.status.toLowerCase() === "fulfilled") fulfilledArr.push(ele["value"]["config"]["url"]);
    });
    return fulfilledArr;
  } catch (error) {
    console.log(error);
  }
};
