import { store } from "shared/store";
import olDeviceModuleSlice from "shared/store/olDeviceModuleSlice";
import { TouchIDPreferences, TouchIDPreferencesType } from "shared/utils/openlogininterface";
import { isMatch, pickBy } from "lodash-es";
import { updateDeviceInfo } from "shared/utils/openloginmutation";
import { getJoinedKey } from "shared/utils/openloginutils";

export const olDeviceModuleAction = olDeviceModuleSlice.actions;

export const setTouchIDPreference = async (params: {
  verifier: string;
  verifierId: string;
  preference: TouchIDPreferencesType;
}): Promise<void> => {
  const { verifier, verifierId, preference } = params;
  store.dispatch(olDeviceModuleAction.setTouchIDPreferenceState(params));
  const deviceId = store.getState().olDeviceModule.verifierIDDeviceIDMap[getJoinedKey(verifier, verifierId)];
  if (deviceId) {
    const isEnabled = preference === TouchIDPreferences.ENABLED;
    const isSet = preference !== TouchIDPreferences.UNSET;
    const deviceInfoPayload = { device_id: deviceId, webauthn_enabled: isEnabled, webauthn_available: isSet };
    const finalDeviceInfoPayload = pickBy(deviceInfoPayload, (val) => val !== null && val !== undefined && val !== "");

    const infoAlreadyExist = isMatch(store.getState().olDeviceModule.devicePersistedInfo[deviceId], finalDeviceInfoPayload);
    if (!infoAlreadyExist) {
      const returnedDeviceId = await updateDeviceInfo(finalDeviceInfoPayload);
      if (returnedDeviceId) {
        store.dispatch(olDeviceModuleAction.setDevicePersistedInfo({ deviceId: returnedDeviceId, info: { ...finalDeviceInfoPayload } }));
      }
    }
  }
};
