import { BasePostMessageStream, ObjectMultiplex, PostMessageStream } from "@toruslabs/openlogin-jrpc";
import pump from "pump";

type UpbondCommunicationMuxData = {
  iframe: string;
  communicationMux: ObjectMultiplex;
  metamaskMux: ObjectMultiplex;
  jrpcMux: ObjectMultiplex;
};

/**
 *
 *
 * @return {*}
 */
const getIFrameOrigin = () => {
  const originHref = window.location.ancestorOrigins?.length > 0 ? window.location.ancestorOrigins[0] : document.referrer;
  if (!originHref) return originHref;
  const url = new URL(originHref);
  return url.origin;
};

const setupInternalMux = (connectionStream: BasePostMessageStream) => {
  const mux = new ObjectMultiplex();
  pump(connectionStream, mux, connectionStream, (error) => {
    if (error) console.error(error, "connection pumping error");
  });
  mux.getStream = function getStream(name) {
    if (this._substreams[name]) {
      return this._substreams[name];
    }
    return this.createStream(name);
  };
  return mux;
};

/**
 *
 *
 * @return {*}  {UpbondCommunicationMuxData}
 */
const preloadCommunicationMux = (): UpbondCommunicationMuxData => {
  const iframe = getIFrameOrigin();

  const metamaskStream = new BasePostMessageStream({
    name: "iframe_metamask",
    target: "embed_metamask",
    targetWindow: window.parent,
    targetOrigin: iframe
  });

  const communicationStream = new BasePostMessageStream({
    name: "iframe_comm",
    target: "embed_comm",
    targetWindow: window.parent,
    targetOrigin: iframe
  });

  const rpcStream = new PostMessageStream({
    name: "iframe_rpc",
    target: "embed_rpc",
    targetWindow: window.parent,
    targetOrigin: "*"
  });

  const metamaskMux = setupInternalMux(metamaskStream);
  const communicationMux = setupInternalMux(communicationStream);
  const jrpcMux = setupInternalMux(rpcStream);

  return {
    iframe,
    communicationMux,
    metamaskMux,
    jrpcMux
  };
};

export { getIFrameOrigin, preloadCommunicationMux };
export type { UpbondCommunicationMuxData };
