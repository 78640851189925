import { ShareStore, StringifiedType } from "@tkey/common-types";
import { ShareRequest } from "@tkey/share-transfer";
import { Auth0ClientOptions, LOGIN_TYPE, LoginWindowResponse, TorusKey, TorusKeyPub, TorusVerifierResponse } from "@toruslabs/customauth";
import { Parser } from "bowser";
import keccakLib from "keccak";
import createKeccakHash from "keccak";

export const keccak = keccakLib;

export type CreateNewTKeyParams = {
  postboxKey: string;
  importKey?: string;
  password?: string;
  backup?: boolean;
  recoveryEmail?: string;
  loginProvider: string;
  verifierId: string;
  syncLocalTransitions?: boolean;
  customDeviceInfo?: Record<string, string>;
  sub?: string;
  typeOfUser?: TorusKey["typeOfUser"];
};

export type ResponsePayload = {
  response?: string;
  rejected?: string;
};

export type ModuleShareDescription = { [x: string]: string | boolean | number; available: boolean; shareIndex: string; module: string };

export type DeviceShareDescription = {
  index: string;
  indexShort: string;
  osDetails: string;
  icon?: string;
  title: string;
  dateAdded: string;
  module: string;
  userAgent: string;
  rawDateAdded?: number;
  customDeviceInfo?: Record<string, string>;
};

export type AllDeviceShares = DeviceShareDescription[];

export type OnDeviceShare = {
  available: boolean;
  share?: ShareStore;
};

export type EmailShareDescription = { email: string; index: string; indexShort: string; dateAdded: string };
export type WebAuthnShareDescription = {
  device: string;
  index: string;
  indexShort: string;
  dateAdded: string;
};

export type ShareSerializationEmailShares = {
  [index: string]: EmailShareDescription;
};

export type PasswordShare = {
  available: boolean;
};

export type OtherShares = string[];

export type WebAuthnDeviceShares = {
  [index: string]: WebAuthnShareDescription[];
};

export type SettingsPageData = {
  deviceShare: OnDeviceShare;
  allDeviceShares: AllDeviceShares;
  exportedEmailShares: ShareSerializationEmailShares;
  otherShares: OtherShares;
  webauthnDeviceShares: WebAuthnDeviceShares; // { [shareIndex]: "Device name" }
  passwordShare: PasswordShare;
  threshold: string;
};

export type PendingShareTransferRequest = {
  userIp?: string;
  browserDetail: Parser.ParsedResult;
  encPubKeyX: string;
} & ShareRequest;

export interface LoginConfigItem {
  loginProvider: string;
  verifier: string;
  typeOfLogin: LOGIN_TYPE;
  name: string;
  description: string;
  clientId: string;
  verifierSubIdentifier: string;
  logoHover: string;
  logoLight: string;
  logoDark: string;
  showOnModal: boolean;
  mainOption: boolean;
  showOnDesktop: boolean;
  showOnMobile: boolean;
  // For torus only
  buttonDescription: string;
  jwtParameters: Auth0ClientOptions;
  walletVerifier?: string;
  customLogo?: string;
}

export interface LoginConfig {
  [key: string]: LoginConfigItem;
}

export interface TKeyAccount {
  privKey: string;
}

export interface CalculateSettingsPageParams {
  forceCheckOnDeviceShare?: boolean;
}

export type KeyMode = "v1" | "1/1" | "2/n";

export type UserType = "v1" | "v2";

export type LoginType = KeyMode;

export type CustomAuthResult = {
  publicAddress: string;
  privateKey: string;
  metadataNonce: string;
  userInfo: TorusVerifierResponse & LoginWindowResponse;
  typeOfUser: UserType;
} & TorusKeyPub;

export interface IAuthServiceIdentities {
  provider: string;
  user_id: string;
  connection: string;
  isSocial: true;
  user: {
    userId: string;
    displayName: string;
    pictureUrl: string;
  };
  is_wallet_registered?: boolean;
  wallet_version?: string;
  wallet_registered_at?: string | number;
}

export interface IAuthServiceResult {
  identities?: IAuthServiceIdentities[];
  tags: [];
  name: string;
  email: string;
  picture: string;
  id: string | number;
  line_id: string;
  updated_at: string;
  is_wallet_registered?: boolean;
  wallet_version?: string;
  wallet_registered_at?: string | number;
  sub: string;
}

export interface SetDeviceWebAuthnRegisteredParams {
  shareIndex: string;
}

export type AuthServiceUserInfo = {
  sub: string;
  accessToken: string;
};

export type TorusUserInfo = {
  email: string;
  name: string;
  profileImage: string;
  aggregateVerifier: string;
  verifier: string;
  verifierId: string;
  typeOfLogin: LOGIN_TYPE;
  idToken?: string;
  accessToken?: string;
  tkeyJson?: string;
};

export type TkeyInputParams = {
  postboxKey: string;
  importKey?: string;
  tKeyJson?: StringifiedType;
  shareStores: ShareStore[];
  serverTimeOffset?: number;
  dappShare?: string;
};

export type ShareRequestInfo = {
  shortIndex: string;
  timestamp: string;
  browserName: string;
  platformType: string;
  encPubKeyX: string;
  userIp?: string;
};

export const MFA_LEVELS = {
  DEFAULT: "default",
  OPTIONAL: "optional",
  MANDATORY: "mandatory",
  NONE: "none"
} as const;

export type MfaLevelType = (typeof MFA_LEVELS)[keyof typeof MFA_LEVELS];

export const SUPPORTED_KEY_CURVES = {
  SECP256K1: "secp256k1",
  ED25519: "ed25519"
} as const;

export type SUPPORTED_KEY_CURVES_TYPE = (typeof SUPPORTED_KEY_CURVES)[keyof typeof SUPPORTED_KEY_CURVES];

export const TouchIDPreferences = {
  UNSET: "unset",
  ENABLED: "enabled",
  DISABLED: "disabled"
} as const;

export type TouchIDPreferencesType = (typeof TouchIDPreferences)[keyof typeof TouchIDPreferences];

export const WebAuthnFlow = {
  REGISTER: "register",
  LOGIN: "login"
};

export type WebAuthnFlowType = (typeof WebAuthnFlow)[keyof typeof WebAuthnFlow];

export type ToastMessage = {
  type?: string;
  message: string;
};

export type LoginRecordUpdate = {
  login_record_id: string;
  error_stack?: string;
  has_skipped_tkey?: boolean;
  login_route: string;
  time_taken: number;
};

export type UserUpdatePayload = {
  tkey_public_address?: string;
  tkey_threshold?: number;
  tkey_password?: boolean;
  tkey_backup_emails?: number;
  tkey_creation_factor?: string;
  theme?: string;
  locale?: string;
  always_skip_tkey?: boolean;
};

export type DeviceUpdatePayload = {
  device_id?: string;
  webauthn_enabled?: boolean;
  webauthn_available?: boolean;
  share_index?: string;
};

export type UserLoginPayload = {
  client_id: string;
  hostname: string;
  webauthn_available: boolean;
  time_taken: number;
  share_index?: string;
  error_stack?: string;
  webauthn_enabled?: boolean;
  login_route: string;
  is_fast_login?: boolean;
  has_skipped_tkey?: boolean;
  browser: string;
  browser_version: string;
  os: string;
  os_version: string;
  platform: string;
  origin?: string;
  login_type?: any;
  fetch_login_count?: any;
  mfa_level?: any;
  factors_used?: any;
  mobile_origin?: any;
  wallet_public_address?: any;
  session_pub_key?: any;
  device_id?: any;
};

export type UserLoginUpdatePayload = {
  login_record_id: string;
  time_taken: number;
  login_route: string;
  error_stack?: string;
  has_skipped_tkey?: boolean;
  browser?: string;
  browser_version?: string;
  os?: string;
  os_version?: string;
  platform?: string;
};

export type UserRegisterPayload = {
  client_id: string;
  hostname: string;
  verifier: string;
  verifier_id: string;
  tkey_public_address?: string;
  tkey_threshold?: number;
  tkey_password?: boolean;
  tkey_backup_emails?: number;
  tkey_creation_factor?: string;
  theme?: string;
  locale?: string;
};

export type EmbedWLColors = {
  bg: string;
  text: string;
  bgHover: string;
  textHover: string;
  globalBgColor: string;
  globalTextColor: string;
};

export interface EmbedTheme {
  logo?: string;
  isActive?: boolean;
  topupHide?: boolean;
  featuredBillboardHide?: boolean;
  name?: string;
  modalLogo?: string;
  buttonLogo?: string;
  modalColor?: string;
  primaryColor?: string;
  textColor?: string;
  bgColor?: string;
  bgColorHover?: string;
  textColorHover?: string;
  upbondLogin?: {
    globalBgColor: string;
    globalTextColor: string;
  };
}

export type WhiteLabelParams = {
  name?: string;
  url?: string;
  logoLight?: string;
  logoDark?: string;
  defaultLanguage?: string;
  dark?: boolean;
  theme?: any;
  usingDirect?: boolean;
  dappRedirectUri?: string;
  lang?: string;
};

export type AppListItem = {
  dappId?: number;
  clientId: string;
  appName: string;
  appUrl: string;
  details: string;
  image: string;
  walletChains?: Array<string>;
};

export type AuthServiceIdentities = {
  provider: string;
  user_id: string;
  connection: string;
  isSocial: true;
  user: {
    userId: string;
    displayName: string;
    pictureUrl: string;
  };
  is_wallet_registered?: boolean;
  wallet_version?: string;
  wallet_registered_at?: string | number;
};

export type AuthServicePostResponse = {
  message: string;
  data: {
    otp_logs?: [];
    device_tokens?: [];
    tags?: [];
    identities?: AuthServiceIdentities[];
    custom_fields?: [];
    _id: string;
    line_id: string;
    name: string;
    picture: string;
    email: string;
    id: string;
    register_client_id: string;
    is_wallet_registered: false;
    wallet_version: string;
    wallet_registered_at: string | null;
    created_at: string;
    updated_at: string;
    __v: number;
    last_login: string;
    update_count: number;
  };
};

export type IdTokenResponse = {
  sub: string;
  auth_time: number;
  nonce: string;
  c_hash: string;
  s_hash: string;
  aud: string;
  exp: number;
  iat: number;
  iss: string;
};

export type UpbondIdToken = IdTokenResponse & {
  otp_logs?: [];
  device_tokens?: [];
  tags?: [];
  identities?: AuthServiceIdentities[];
  custom_fields?: [];
  _id: string;
  line_id: string;
  name: string;
  picture: string;
  email: string;
  id: string;
  register_client_id: string;
  is_wallet_registered: false;
  wallet_version: string;
  wallet_registered_at: string | null;
  created_at: string;
  updated_at: string;
  __v: number;
  last_login: string;
  update_count: number;
};

export type OriginData = {
  [P in string]: string;
};

export type UserData = {
  [P in string]: string;
};

export function keccak256(str: string): string {
  let input: string | Buffer = str;
  if (typeof str === "string" && str.slice(0, 2) === "0x" && str.length === 66) {
    input = Buffer.from(str.slice(2), "hex");
  }
  const data = `0x${keccak("keccak256").update(input).digest("hex").padStart(64, "0")}`;
  return data;
}

export function keccak256hash(a: string | Buffer): Buffer {
  return createKeccakHash("keccak256").update(a).digest();
}
