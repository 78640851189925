import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { randomId, safeatob } from "@toruslabs/openlogin-utils";
import { useEffect } from "react";
import useLoading from "hooks/useLoading";
import { useTranslation } from "react-i18next";
import i18n, { checkLanguage } from "i18n";
import { walletActions } from "shared/actions/walletAction";
import { useDispatch } from "react-redux";
import { embedActions } from "shared/actions/embedAction";
import { useAppSelector } from "hooks/useRedux";
import { EmbedTheme } from "interfaces/ui/IEmbed";
// import { catchError } from "shared/utils/coreUtils";
import { getRPCEngine } from "shared/utils/hendler";
import { redirectToDapp } from "shared/utils/openloginutils";

import "./style.css";
import { olPidModuleAction } from "shared/actions/olPidModuleAction";
import config from "shared/config";
import { openloginClientId } from "shared/config/openlogin";
import { exportDeviceShare, olLoginPerfModuleAction } from "shared/actions/olTkeyModuleAction";
import { JRPCRequest } from "@toruslabs/openlogin-jrpc";
import { DappModule } from "interfaces/actions/IOIDappModule";

export const tabsBroadcast = () => {
  const bc = new BroadcastChannel(`upbond_close_tab`);
  return bc;
};
const isLocalStoragePermitted = () => {
  try {
    localStorage.setItem("key", "key");
    const key = localStorage.getItem("key");
    if (key) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

const StartPage: React.FC = () => {
  const [readyToRender, setReadyToRender] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const { olAllDappModule, olUserModule } = useAppSelector((state) => state);

  const search = useLocation().search;
  const data = new URLSearchParams(search);
  const dispatch = useDispatch();
  const history = useHistory();

  const { DisplayLoadingNew, setLoading } = useLoading();
  const { t } = useTranslation();
  const { configuration } = useAppSelector((state) => state.embedState);
  const init = async () => {
    try {
      setReadyToRender(true);
      dispatch(walletActions.setInitialState());
      localStorage.removeItem("result");

      // dispatch(walletActions.resetConsentConfiguration());
      setLoading(true, `${t("login.loggingIn")}`);
      const pid = randomId().toString();
      let lang = data.get("lang") || "en";
      const loginProvider = data.get("loginProvider");
      // const skipTKey = data.get("skipTKey");
      const state = data.get("state") || "";
      const stateParams = JSON.parse(safeatob(state));
      // const isUsingDirect = data.get("usingDirect") || false;
      const dappRedirectUri = data.get("dappRedirectUri") || "";

      setValue(0.1);
      dispatch(
        olPidModuleAction._setPID({
          pid,
          data: {
            _clientId: openloginClientId,
            loginProvider,
            redirectUrl: `${config.baseUrl}/end`,
            extraLoginOptions: {
              login_hint: ""
            }
          }
        })
      );

      if (isLocalStoragePermitted() && dappRedirectUri) {
        localStorage.setItem("tempRedirectUrl", dappRedirectUri);
      } else {
        if (dappRedirectUri) {
          sessionStorage.setItem("tempRedirectUrl", dappRedirectUri);
        }
      }
      setValue(0.17);
      if (lang && lang !== "null") {
        if (lang !== localStorage.getItem("lang")) {
          localStorage.setItem("lang", lang);
          i18n.changeLanguage(lang);
        }
      } else {
        lang = checkLanguage();
      }
      setValue(0.25);
      const { whiteLabel, loginConfig } = stateParams;
      // const consentConfig = whiteLabel?.consentConfiguration;
      setValue(0.33);

      // //change this with current did
      // if (consentConfig) {
      //   const scopeData = await permissionServices.getScopeByClientId(consentConfig.clientId);
      //   if (scopeData && scopeData?.scopeData) {
      //     dispatch(
      //       walletActions.setConsentConfiguration({
      //         clientId: consentConfig.clientId,
      //         scope: scopeData.scopeData.data[0].scope.split(","),
      //         secretKey: consentConfig.secretKey,
      //         requiredScope: scopeData.scopeData.data[0].required_fields.split(","),
      //         origin: consentConfig.origin
      //       })
      //     );
      //   } else {
      //     catchError(`Throttle refresh on /start`);
      //     setReadyToRender(false);
      //     setLoading(false, "Error occurred");
      //     const dappRedirectUri = data.get("dappRedirectUri") || "";
      //     if (dappRedirectUri && dappRedirectUri !== "") {
      //       window.location.href = dappRedirectUri;
      //     } else if (localStorage.getItem("tempRedirectUrl")) {
      //       window.location.href = localStorage.getItem("tempRedirectUrl") as string;
      //     } else {
      //       history.push("/");
      //     }
      //   }
      // }

      setValue(0.46);
      if (whiteLabel) {
        dispatch(
          embedActions.setTheme({
            ...(whiteLabel as EmbedTheme)
          })
        );
      }
      setValue(0.54);

      if (dappRedirectUri) {
        dispatch(
          embedActions.setConfiguration({
            ...configuration,
            directConfig: {
              enabled: true,
              redirectUrl: dappRedirectUri
            }
          })
        );
      }

      setValue(0.67);
      let params: Record<string, unknown> = {};
      const presetParams = {
        extraLoginOptions: { login_hint: "" },
        loginProvider,
        redirectUrl: `${window.location.origin}/end`,
        loginConfig,
        _clientId: openloginClientId
      };
      console.log("start, presetParams: ", JSON.stringify(presetParams));

      params = { ...params, ...presetParams };

      const rpcEngine = await getRPCEngine();
      // convert to request (w/ redirect details) and handle via RPCEngine
      params._redirect = true;
      const method = "openlogin_login";
      const jrpcReq: JRPCRequest<unknown> = {
        method: method as string,
        params: [params]
      };

      const jrpcRes = await rpcEngine.handle<unknown, Record<string, unknown>>(jrpcReq);
      let redirectResult: Record<string, unknown> = {};
      let canRedirect = true;
      let currentDappModule: DappModule | null = null;

      if (jrpcRes.error) {
        redirectResult = { ...redirectResult, error: jrpcRes.error };
      }
      if (jrpcRes.result) {
        if (jrpcRes.result.alreadyRedirecting) {
          canRedirect = false;
        } else if (!jrpcRes.result._clientId) {
          canRedirect = false;
        } else {
          currentDappModule = olAllDappModule.dappModules[jrpcRes.result._clientId as string];
          if (!currentDappModule) {
            canRedirect = false;
          } else if (!currentDappModule.redirectUrl) {
            canRedirect = false;
          }
        }
        const result = jrpcRes.result as Record<string, unknown>;
        redirectResult = { ...redirectResult, ...result };
      }

      if (canRedirect && currentDappModule !== null) {
        // reinit loginPerf before redirecting
        dispatch(olLoginPerfModuleAction._reinit());
        const {
          email,
          aggregateVerifier,
          name,
          profileImage,
          typeOfLogin,
          verifier,
          verifierId,
          idToken: oAuthIdToken,
          accessToken: oAuthAccessToken
        } = olUserModule.userInfo as any;
        console.log("start, userInfo: ", JSON.stringify(olUserModule.userInfo));
        let dappShare: any = "";
        if (currentDappModule.canSendDappShare) dappShare = await exportDeviceShare();
        dispatch(
          olLoginPerfModuleAction.markRouteAndTime({
            route: "start",
            isEnd: true
          })
        );
        setValue(0.74);
        await redirectToDapp(
          {
            redirectUrl: currentDappModule.redirectUrl,
            popupWindow: !!redirectResult._popupWindow,
            sessionTime: currentDappModule.sessionTime,
            sessionId: redirectResult._sessionId as string,
            _sessionNamespace: currentDappModule._sessionNamespace
          },
          {
            result: {
              redirectResult,
              store: {
                oAuthIdToken: currentDappModule.isCustomVerifier ? oAuthIdToken : "", // only send original id token for custom verifiers
                oAuthAccessToken: currentDappModule.isCustomVerifier ? oAuthAccessToken : "",
                idToken: currentDappModule.idToken,
                touchIDPreference: currentDappModule.touchIDPreference,
                appState: currentDappModule.appState,
                email,
                aggregateVerifier,
                name,
                profileImage,
                typeOfLogin,
                verifier,
                verifierId,
                dappShare
              },
              appState: currentDappModule.appState
            },
            pid: pid as string
          }
        );
      }
      if (!jrpcRes.result && !jrpcRes.error) {
        throw new Error("no result or error on jrpc response");
      }

      setValue(0.8);
      const tabsBc = tabsBroadcast();
      tabsBc.onmessage = (ev: any) => {
        // @dwiyan -> start need to close logic
        if (ev.data.data.close) {
          const isiPad = navigator.userAgent.match(/iPad/i) != null;
          const isiPhone = navigator.userAgent.match(/iPhone/i) != null;
          if (isiPad || isiPhone) {
            setTimeout(() => {
              try {
                window.open("", "_self")?.close();
              } catch (error) {
                console.error(error);
              }
            }, 300);
          } else {
            window.close();
          }
          tabsBc.close();
        }
      };
      setValue(1);
    } catch (error) {
      setReadyToRender(false);
      setLoading(false, "Error occurred");
      console.error(error, "something went wrong");
      const dappRedirectUri = data.get("dappRedirectUri") || "";
      if (dappRedirectUri && dappRedirectUri !== "") {
        window.location.href = dappRedirectUri;
      } else if (localStorage.getItem("tempRedirectUrl")) {
        window.location.href = localStorage.getItem("tempRedirectUrl") as string;
      } else {
        history.push("/");
      }
    }
  };

  useEffect(() => {
    if (!readyToRender) {
      init();
    }
  }, []);

  return (
    <div className="content">
      <DisplayLoadingNew value={value} />
    </div>
  );
};
export default StartPage;
