import { post } from "@toruslabs/http-helpers";
import { AuthServicePostResponse } from "interfaces/actions/IUser";

export const postV2UpbondAccount = async (
  accessToken: string,
  data: {
    provider: string;
    wallet_address: string;
  }
): Promise<{ success: boolean; data: AuthServicePostResponse | null; stack: any }> => {
  try {
    const v2Acc = await post<AuthServicePostResponse>(`${process.env.REACT_APP_LOGIN_UPBOND_DOMAIN}/customer-wallet`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return {
      success: true,
      data: v2Acc as AuthServicePostResponse,
      stack: null
    };
  } catch (error) {
    return {
      data: null,
      stack: error,
      success: false
    };
  }
};
