import { useEffect, useState } from "react";

export default function useTheme(intitialWidth = window.innerWidth) {
  const [breakpoint, setBreakPoint] = useState(intitialWidth);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    if (0 < windowSize.width && windowSize.width < 1024) {
      setBreakPoint(0);
    }

    if (1024 < windowSize.width && windowSize.width < 1920) {
      setBreakPoint(1024);
    }
    if (windowSize.width >= 1920) {
      setBreakPoint(1920);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize.width]);

  useEffect(() => {
    handleResize();
  }, []);

  return {
    breakpoint
  };
}
