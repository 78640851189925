// @ts-nocheck
import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonItem, IonLabel, IonList, IonPage, IonRow } from "@ionic/react";
import HeaderMainPc from "components/header/pc";
import { useTranslation } from "react-i18next";

const PersonalInformationMainPc: React.FC<any> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <IonPage className="ion-no-padding">
      <HeaderMainPc title={t("settings.personal")} link="/wallet/settings/init" />

      <IonContent className="bg-base ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol size="6">
              <IonCard class="ion-no-padding m-0">
                <IonCardContent>
                  <IonList className="backup-list px-4">
                    <IonItem className="personal-wrapper font-normal text-base leading-[19px] text-[#5d5d5d]">
                      <IonLabel id="title" position="stacked" className="font-semibold text-base leading-[19px] !text-black">
                        {t("settings.name")}
                      </IonLabel>
                      <IonLabel position="stacked">{user.name}</IonLabel>
                    </IonItem>
                    <IonItem className="personal-wrapper font-normal text-base leading-[19px] text-[#5d5d5d]">
                      <IonLabel id="title" position="stacked" className="font-semibold text-base leading-[19px] !text-black">
                        {t("settings.email")}
                      </IonLabel>
                      <IonLabel position="stacked">{user.email}</IonLabel>
                    </IonItem>
                    {/* <div className="mt-6">
                      <IonButton
                        expand="block"
                        class="h-12"
                        color={"primary"}
                        onClick={() => history.push("/wallet/settings/personal-information-edit", "forward", "push")}>
                        {t("settings.edit")}
                      </IonButton>
                    </div> */}
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PersonalInformationMainPc;
