import { ObservableStore } from "@metamask/obs-store";

class ComposableObservableStore extends ObservableStore<any> {
  config: any;

  constructor(initState?: any, config?: any) {
    super(initState);
    this.updateStructure(config);
  }

  updateStructure(config: any) {
    this.config = config;
    this.removeAllListeners();
    for (const key in config) {
      config[key].subscribe((state: any) => {
        this.updateState({ [key]: state });
      });
    }
  }

  getFlatState() {
    let flatState = {};
    for (const key in this.config) {
      flatState = { ...flatState, ...this.config[key].getState() };
    }
    return flatState;
  }
}

export default ComposableObservableStore;
