// @ts-nocheck
import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks/useRedux";
import { QrCodeIcon, WalletConnectLogo } from "assets/icons";
import HeaderMainMb from "components/header/mb";

const ApplicationMainMb: React.FC<any> = ({
  listChain,
  legacySignClient,
  customAlertOptions,
  changeChainId,
  initialChain,
  uri,
  setUri,
  onConnect,
  setOpenDisconnectModal,
  openQrScanner
}) => {
  const { t } = useTranslation();
  const walletConnect = useAppSelector((state) => state.walletConnect);

  return (
    <IonPage color="medium">
      <HeaderMainMb title={t("settings.connectToApp")} pending={true} />

      {/* Applications Menu */}
      <IonContent className="ion-padding">
        {walletConnect.connected === true ? (
          <>
            {/* CHANGE NETWORK */}
            {legacySignClient?.version === 1 && legacySignClient?.connected == true && (
              <>
                <div className="flex justify-center items-center mb-5 -mt-[10px]">
                  <IonListHeader className="mt-[8.5px] pl-0 flex justify-around">
                    <IonLabel>{t("settings.currentNetwork")}</IonLabel>
                    <IonSelect
                      interface="alert"
                      interfaceOptions={customAlertOptions}
                      className="min-w-[25%] rounded-full bg-gray-300 text-base mb-0"
                      placeholder={initialChain?.name}
                      okText={t("settings.save") as string}
                      onIonChange={(event) => changeChainId(event)}>
                      {listChain &&
                        listChain.map((data: any) => (
                          <IonSelectOption key={data.chain} value={data.chain}>
                            {data.name}
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                  </IonListHeader>
                </div>
              </>
            )}

            <IonList className="application-connected ion-padding">
              <IonLabel className="font-semibold text-xl leading-6 text-black">{t("settings.youreConnect")}</IonLabel>
              <div>
                <IonButton
                  className="w-[100px] h-9 mt-4 font-medium text-[15px] leading-[18px] text-[#4d4082]"
                  onClick={() => setOpenDisconnectModal(true)}>
                  {t("settings.disconnect")}
                </IonButton>
              </div>
            </IonList>
          </>
        ) : (
          <>
            <div className="flex justify-center py-4 px-0 bg-[#fff]">
              <img src={WalletConnectLogo} alt="Wallet connect logo" />
            </div>
            {/* <IonListHeader className="flex justify-around mt-[8.5px] pl-0 lg:mt-0">
              <IonLabel className="lg:mt-0">{t("settings.regionRelayer")}</IonLabel>
              <IonSelect
                interface="alert"
                interfaceOptions={customAlertOptions}
                className="min-w-[25%] rounded-full bg-gray-light text-base mb-0"
                placeholder={initialChain?.name}
                defaultChecked={true}
                defaultValue={REGIONALIZED_RELAYER_ENDPOINTS[0].value}
                okText={t("settings.save") as string}
                onIonChange={(event) => changeRelayer(event)}
                value={wallet.relayerRegionURL}>
                {REGIONALIZED_RELAYER_ENDPOINTS &&
                  REGIONALIZED_RELAYER_ENDPOINTS.map((data) => (
                    <IonSelectOption key={data.value} value={data.value}>
                      {data.label}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonListHeader> */}
            <IonList className="backup-list text-center">
              <IonLabel color="dark" className="font-semibold text-2xl leading-7">
                {t("settings.connectVia")}
              </IonLabel>
              <IonItem lines="none" className="application-input !p-0 my-5 mx-0 !text-center">
                <IonInput
                  inputMode="text"
                  placeholder={`${t("settings.pasteLink")}`}
                  value={uri}
                  onIonChange={(e: any) => setUri(e.target.value)}></IonInput>
              </IonItem>
              <IonButton
                className="my-5 mx-0"
                shape="round"
                expand="full"
                color={uri ? "primary" : "secondary"}
                disabled={uri === "" && true}
                onClick={() => {
                  onConnect(uri);
                }}>
                <IonIcon src={QrCodeIcon} className="mr-2" />
                {t("settings.connect")}
              </IonButton>

              <IonLabel>{t("settings.or")}</IonLabel>
              <IonButton onClick={openQrScanner} className="my-5 mx-0" shape="round" expand="full" color="primary">
                <IonIcon src={QrCodeIcon} className="mr-2" />
                {t("settings.qrCode")}
              </IonButton>
            </IonList>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ApplicationMainMb;
