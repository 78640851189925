import { ethers } from "ethers";
import { ISendTransaction } from "interfaces/actions/IWeb3Auth";
import { ContractType } from "interfaces/actions/IToken";
import torus from "libs/TorusExtended";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { logout, setLoading } from "shared/actions/walletAction";
import Web3 from "web3";
import useProvider from "./useProvider";
import { useAppDispatch, useAppSelector } from "./useRedux";
import { listChain } from "shared/config/chains";
import { catchError } from "shared/utils/coreUtils";
import { getBestRpc } from "shared/utils/filterRpc";

/**
 * NOTE !!!
 *
 * PLEASE TELL TO ANOTHER TEAM IF YOU ANY CHANGE IN THIS FILE !!!
 */

export type WalletTypes = {
  onError?: (error: any) => void;
  onSuccess?: (wallet?: ethers.Wallet) => void;
};

export default function useWallet(
  { onError, onSuccess }: WalletTypes = {
    onError(error) {
      console.error(error);
    }
  },
  ownProvider = true
) {
  const [wallet, setWallet] = useState<ethers.Wallet | null>(null);
  const [ethWallet, setEthWallet] = useState<ethers.Wallet | null>(null);
  const [web3Controller, setWeb3Controller] = useState<Web3 | undefined>();
  // DONT REMOVE
  const { selectedNetwork, supportedNetworks } = useAppSelector((state) => state.wallet);
  const { wallet: _wallet, selectedAddress } = useAppSelector((state) => state.wallet);
  const stateChainId = useAppSelector((state) => state.transfer.detail?.chain_id);
  const { provider } = useProvider();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    if (torus.web3) {
      setWeb3Controller(torus.web3);
    }
  }, [torus.web3]);

  useEffect(() => {
    stateChainId && changeEthProvider(stateChainId);
  }, [stateChainId]);

  const initializeWallet = () => {
    try {
      setIsLoading(true);
      const privKey = _wallet[selectedAddress].privateKey;
      const _privKey = `0x${privKey}`;
      //** Don't Delete need make sure first */
      const currentProvider = supportedNetworks[selectedNetwork].rpcUrl;
      const provider = new ethers.providers.JsonRpcProvider(currentProvider);
      const ethersWallet = new ethers.Wallet(_privKey, provider);
      // ** Don't Delete */
      // const provider = new ethers.providers.Web3Provider(torus.web3?.givenProvider);
      // const ethersWallet = new ethers.Wallet(_privKey, provider);
      setEthWallet(ethersWallet);
      if (onSuccess) {
        onSuccess(ethersWallet);
      }
      setIsLoading(false);
    } catch (error: any) {
      catchError(error);
      setIsLoading(false);
      if (onError) {
        onError(error);
      }
      console.error(`@ -> error on initializeWallet (catch): `, error);
      throw new Error(error);
    }
  };
  useEffect(() => {
    ownProvider && initializeWallet();
    stateChainId && changeEthProvider(stateChainId);
  }, []);

  useEffect(() => {
    ownProvider && initializeWallet();
  }, [selectedNetwork]);

  useEffect(() => {
    if (Object.keys(_wallet).length > 0) {
      const privKey = _wallet[selectedAddress].privateKey;
      if (privKey === "" && onError) {
        onError(`private key is not provided`);
        dispatch(logout());
        return;
      }
      const _privKey = `0x${privKey}`;
      try {
        if (web3Controller?.eth.accounts.wallet.length === 0) {
          const pAccount = torus.web3?.eth.accounts.privateKeyToAccount(_privKey);
          torus.web3?.eth.accounts.wallet.add(pAccount as any);
        }
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        catchError(error);
        if (onError) {
          onError(error);
          console.error(`@ -> error on useEffect useWallet (catch): `, error);
        }
      }
    } else {
      if (onError) {
        onError(`private key is not provided`);
      }
      dispatch(logout());
      return;
    }
  }, [provider]);

  const setIsLoading = (loading: boolean) => {
    dispatch(setLoading(loading, t("transfer.loading") as any));
  };

  const sendTransaction = async ({ params, to, value }: ISendTransaction, resend = true): Promise<any> => {
    try {
      setIsLoading(true);
      if (!wallet) return;
      const nonce = await wallet.getTransactionCount();
      const tx = await wallet.sendTransaction({
        from: selectedAddress,
        to,
        value: value as any,
        nonce,
        ...params
      });

      tx.wait();
      setIsLoading(false);
      return tx;
    } catch (error: any) {
      catchError(error);
      try {
        if (resend) {
          return await resendSendTransaction({ params, to, value });
        } else {
          setIsLoading(false);
          throw error;
        }
      } catch (err: any) {
        throw error.code;
      }
    }
  };

  const resendSendTransaction = async ({ params, to, value }: ISendTransaction): Promise<any> => {
    // eslint-disable-next-line no-useless-catch
    try {
      const newParams = { gasLimit: params.gasLimit, gasPrice: await wallet?.provider.getGasPrice() };
      return await sendTransaction({ params: newParams, to, value }, false);
    } catch (err: any) {
      throw err;
    }
  };

  const resendContractSend = async (data: ContractType) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const newData = { ...data, option: { gasPrice: await wallet?.provider.getGasPrice() } };
      return await contractSend(newData, false);
    } catch (err: any) {
      throw err;
    }
  };

  const changeEthProvider = async (chain: string) => {
    const customProvider = listChain.filter((item: any) => {
      if (item.chain == chain) return item.rpcUrl;
    });
    const privKey = _wallet[selectedAddress].privateKey;
    const _privKey = `0x${privKey}`;
    const raceConnection = await Promise.race(
      customProvider[0].rpcUrl.map(async (item) => {
        try {
          const bestRpc = await getBestRpc(customProvider[0].chain, [item]);
          const provider = new ethers.providers.JsonRpcProvider(bestRpc);
          const blockNum = await provider.getBlockNumber();
          if (blockNum) return provider;
        } catch (error: any) {
          console.error(error);
        }
      })
    );
    const newProvider = new ethers.Wallet(_privKey, raceConnection);
    setWallet(newProvider);
    return newProvider;
  };

  const contractSend = async (data: ContractType, resend = true): Promise<any> => {
    try {
      if (!wallet) return;
      const contract = new ethers.Contract(data.contractAddress, data.abi, wallet);
      const gasLimit = await contract.estimateGas[data.method](...data.param);
      const tx = await contract[data.method](...data.param, { ...data.option, gasLimit });
      return tx;
    } catch (error: any) {
      try {
        if (resend) {
          return await resendContractSend(data);
        } else {
          setIsLoading(false);
          throw error;
        }
      } catch (err: any) {
        throw error;
      }
    }
  };

  const getDecimal = async (tokenContract: string, abi: any): Promise<any> => {
    try {
      if (!wallet) return;
      const contract = new ethers.Contract(tokenContract, abi, wallet);
      const result = await contract.decimals();
      return result;
    } catch (error: any) {
      return 18;
    }
  };

  return {
    wallet,
    ethWallet,
    changeEthProvider,
    sendTransaction,
    web3Controller,
    contractSend,
    getDecimal
  };
}
