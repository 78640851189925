import {
  setErrorMsg,
  setPersonalMessages,
  setSelectedAddress,
  setSuccessMsg,
  setUnapprovedDecryptMsgs,
  setUnapprovedEncryptionPublicKeyMsgs,
  setUnapprovedMsgs,
  setWeiBalance,
  updateCalculatedTx,
  setTransactions,
  walletActions
} from "shared/actions/walletAction";
import { store } from "shared/store";

export type ControllerHandlerPayload = {
  accountType: string;
  badgesCompletion: { [x: string]: any };
  contracts: any[];
  customNfts: any[];
  customTokens: any[];
  defaultPublicAddress: string;
  fetchedPastTx: any[];
  jwtToken: string;
  pastTransactions: any[];
  paymentTx: any[];
  permissions: any[];
  selectedCurrency: string;
  tKeyOnboardingComplete: boolean;
  theme: string;
};

/**
 *
 *
 * @export
 * @param {({ selectedAddress: string | any; [x: string]: ControllerHandlerPayload | undefined })} state
 */
export function prefsControllerHandler(state: { selectedAddress: string | any; [x: string]: ControllerHandlerPayload | undefined }) {
  const { selectedAddress } = state;
  store.dispatch(setSelectedAddress({ selectedAddress }));
  if (state[selectedAddress]) {
    const addressState = state[selectedAddress] as ControllerHandlerPayload;
    const jwtToken = {
      [selectedAddress]: addressState.jwtToken
    };
    store.dispatch(
      walletActions.setAddressState({
        ...addressState,
        jwtToken
      })
    );
  }
}

/**
 *
 *
 * @export
 * @param {({ accounts: { address: string; balance: string | number }[] })} { accounts }
 */
export function accountTrackerHandler({ accounts }: { accounts: { address: string; balance: string | number }[] }) {
  if (accounts) {
    for (const key in accounts) {
      if (Object.prototype.hasOwnProperty.call(accounts, key)) {
        const account = accounts[key];
        if (account.address) store.dispatch(setWeiBalance({ account: account.address, balance: account.balance }));
      }
    }
  }
}

export function tokenRatesControllerHandler({ contractExchangeRates }: any) {
  if (contractExchangeRates) {
    // TODO:
  }
}

/**
 *
 *
 * @export
 * @param {*} { transactions }
 */
export function transactionControllerHandler({ transactions }: any) {
  if (transactions) {
    const updatedTransactions = [];
    for (const id in transactions) {
      if (transactions[id]) {
        updatedTransactions.push(transactions[id]);
      }
    }
    store.dispatch(setTransactions(updatedTransactions));
    store.dispatch(updateCalculatedTx(updatedTransactions));
  }
}

/**
 *
 *
 * @export
 * @param {{
 *   unapprovedEncryptionPublicKeyMsgs: { [key: string]: any };
 * }} {
 *   unapprovedEncryptionPublicKeyMsgs
 * }
 */
export function encryptionPublicKeyHandler({
  unapprovedEncryptionPublicKeyMsgs
}: {
  unapprovedEncryptionPublicKeyMsgs: { [key: string]: any };
}) {
  store.dispatch(setUnapprovedEncryptionPublicKeyMsgs({ unapprovedEncryptionPublicKeyMsgs }));
}

/**
 *
 *
 * @export
 * @param {{ unapprovedDecryptMsgs: { [key: string]: any } }} { unapprovedDecryptMsgs }
 */
export function unapprovedDecryptMsgsHandler({ unapprovedDecryptMsgs }: { unapprovedDecryptMsgs: { [key: string]: any } }) {
  store.dispatch(setUnapprovedDecryptMsgs({ unapprovedDecryptMsgs }));
}

/**
 *
 *
 * @export
 * @param {string} msg
 */
export function successMsgHandler(msg: string) {
  store.dispatch(setSuccessMsg({ msg }));
}

/**
 *
 *
 * @export
 * @param {string} msg
 */
export function errorMsgHandler(msg: string) {
  store.dispatch(setErrorMsg({ msg }));
}

/**
 *
 *
 * @export
 * @param {{ unapprovedMsgs: { [x: string]: any } }} { unapprovedMsgs }
 */
export function messageManagerHandler({ unapprovedMsgs }: { unapprovedMsgs: { [x: string]: any } }) {
  store.dispatch(setUnapprovedMsgs({ unapprovedMsgs: unapprovedMsgs }));
}

/**
 *
 *
 * @export
 * @param {{ unapprovedPersonalMsgs: { [x: string]: any } }} { unapprovedPersonalMsgs }
 */
export function personalMessageHandler({ unapprovedPersonalMsgs }: { unapprovedPersonalMsgs: { [x: string]: any } }) {
  store.dispatch(setPersonalMessages({ unapprovedPersonalMsgs }));
}

/**
 *
 *
 * @export
 * @param {{ unapprovedTypedMessages: { [x: string]: any } }} { unapprovedTypedMessages }
 */
export function typedMessageManagerHandler({ unapprovedTypedMessages }: { unapprovedTypedMessages: { [x: string]: any } }) {
  store.dispatch(walletActions.setTypedMessage(unapprovedTypedMessages));
}
