import { GasOracleOptions, GasPricesWithEstimateInput } from "interfaces/actions/IGasPrice";
import { IChains, TransactionTemp } from "interfaces/actions/IToken";
import { initialBalanceOrder, listChain } from "./chains";

export const gasConfigs = (chainId: number): GasOracleOptions | undefined => {
  const gasConfig: any = listChain.filter((item: IChains) => {
    if (item.gasConfig?.chainId === chainId) return item.gasConfig;
  });
  return gasConfig.length > 0 ? gasConfig[0].gasConfig : undefined;
};

export const fallbackGasInput: GasPricesWithEstimateInput = {
  fallbackGasPrices: {
    gasPrices: {
      instant: 28,
      fast: 22,
      standard: 17,
      low: 11
    },
    estimated: {
      baseFee: 0,
      maxFeePerGas: 20,
      maxPriorityFeePerGas: 3
    }
  }
};

export const initTransfer: TransactionTemp = {
  toAddress: "",
  amount: "",
  gas: {
    estimate: {
      maxFeePerGas: 0,
      baseFee: 0,
      maxPriorityFeePerGas: 0
    },
    gasPrices: {
      instant: 0,
      fast: 0,
      standard: 0,
      low: 0
    }
  },
  gasEIP: {
    instant: {
      maxFeePerGas: "0",
      maxPriorityFeePerGas: "0"
    },
    fast: {
      maxFeePerGas: "0",
      maxPriorityFeePerGas: "0"
    },
    standard: {
      maxFeePerGas: "0",
      maxPriorityFeePerGas: "0"
    }
  },
  detail: initialBalanceOrder[0],
  errMsg: ""
};
