import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPermission } from "interfaces/actions/IPermission";

const permissions = localStorage.getItem("permissionKey");
const permissionSlice = createSlice({
  name: "permission",
  initialState: {
    permissions: permissions ? JSON.parse(permissions) : null,
    selectedPermission: {} as any,
    did: ""
  },
  reducers: {
    setPermissions(state, action: PayloadAction<IPermission[]>) {
      state.permissions = action.payload;
    },
    setSelectedPermission(state, action: PayloadAction<IPermission>) {
      state.selectedPermission = action.payload;
    },
    setDid(state, action: PayloadAction<string>) {
      state.did = action.payload;
    }
  }
});

export default permissionSlice;
