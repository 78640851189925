import log from "loglevel";

function createLoggerMiddleware(options: any = null) {
  if (options) console.log(options);
  return function loggerMiddleware(request: any, response: any, next: any) {
    next((callback: any) => {
      if (response.error) {
        log.warn("Error in RPC response:\n", response);
      }
      if (request.isMetamaskInternal) return;
      callback();
    });
  };
}

export default createLoggerMiddleware;
