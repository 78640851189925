import { TKey } from "@tkey/core";
import BN from "bn.js";
import { AllDeviceShares, ModuleShareDescription } from "libs/TkeyManagement";
import bowser from "bowser";
import { capitalizeFirstLetter } from "shared/utils/coreUtils";

export const CHROME_EXTENSION_STORAGE_MODULE_KEY = "chromeExtensionStorage";

export function formatDate(date: string | Date): string {
  if (!date) return "";
  const toFormat = date instanceof Date ? date : new Date(date);
  const day = toFormat.getDate().toString().padStart(2, "0");
  const month = (toFormat.getMonth() + 1).toString().padStart(2, "0");
  const year = toFormat.getFullYear().toString().substring(2);
  return `${day}/${month}/${year}, ${toFormat.toLocaleString(undefined, { timeStyle: "short", hour12: false })}`;
}

export async function getAllPrivateKeys(
  newTKey: TKey,
  privKey: BN
): Promise<
  {
    privKey: string;
  }[]
> {
  const hexKeys = [];
  if (privKey) {
    hexKeys.push({ privKey: privKey.toString("hex", 64) });
    console.info("privKeys are available");
  }
  return hexKeys;
}

export function parseShares(parsedShareDescriptions: ModuleShareDescription[]): AllDeviceShares {
  return parsedShareDescriptions.reduce((acc: AllDeviceShares, x: unknown) => {
    const y = x as ModuleShareDescription & {
      userAgent: string;
      dateAdded: string;
      date: string;
      customDeviceInfo?: string;
    };
    if (y.userAgent && y.shareIndex !== "1") {
      const browserInfo = bowser.parse(y.userAgent);
      const browserName = y.module === CHROME_EXTENSION_STORAGE_MODULE_KEY ? "Chrome Extension" : `${browserInfo.browser.name}`;
      let title = `${browserName}${y.module === CHROME_EXTENSION_STORAGE_MODULE_KEY ? "" : ` ${`V${browserInfo.browser.version}`}`}`;
      const customDeviceInfo = y.customDeviceInfo ? JSON.parse(y.customDeviceInfo) : null;
      if (customDeviceInfo && customDeviceInfo.browser) {
        title = customDeviceInfo.browser;
      }
      y.dateFormatted = formatDate(y.dateAdded || y.date);
      acc.push({
        customDeviceInfo,
        rawDateAdded: parseInt(y.dateAdded, 10),
        osDetails: `${browserInfo.os.name || ""}, ${capitalizeFirstLetter(browserInfo.platform.type || "")}`.trim(),
        title,
        dateAdded: y.dateFormatted,
        module: y.module,
        index: y.shareIndex,
        userAgent: y.userAgent,
        indexShort: y.shareIndex.slice(0, 4)
      });
    }
    return acc;
  }, [] as AllDeviceShares);
}

export function getCustomDeviceInfo(): Record<string, string> | undefined {
  if ((navigator as unknown as { brave: boolean })?.brave) {
    return {
      browser: "Brave"
    };
  }
  return undefined;
}
