import { concatSig } from "@metamask/eth-sig-util";
import nacl from "@toruslabs/tweetnacl-js";
import { ecsign, hashPersonalMessage, intToBuffer, stripHexPrefix } from "ethereumjs-util";

import { SUPPORTED_KEY_CURVES, SUPPORTED_KEY_CURVES_TYPE } from "./openlogininterface";

export const hashMessage = (message: string): Buffer => {
  const bufferedMessage = Buffer.from(message, "utf8");
  const el = hashPersonalMessage(bufferedMessage);
  return el;
};

export const signMessage = (privateKey: string, data: string, type: SUPPORTED_KEY_CURVES_TYPE = SUPPORTED_KEY_CURVES.SECP256K1): string => {
  if (type === SUPPORTED_KEY_CURVES.SECP256K1) {
    const privKey = Buffer.from(privateKey.padStart(64, "0"), "hex");
    const message = stripHexPrefix(data);
    const msgSig = ecsign(Buffer.from(message, "hex"), privKey);
    const rawMsgSig = concatSig(intToBuffer(msgSig.v), msgSig.r, msgSig.s);
    return rawMsgSig;
  }
  if (type === SUPPORTED_KEY_CURVES.ED25519) {
    return Buffer.from(nacl.sign.detached(Buffer.from(data, "utf-8"), Buffer.from(privateKey, "hex"))).toString("hex");
  }
  throw new Error(`Unsupported curve type: ${type}`);
};
