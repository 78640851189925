// @ts-nocheck
import { IonContent, IonItem, IonLabel, IonList, IonPage, useIonRouter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks/useRedux";
import HeaderMainMb from "components/header/mb";

const PermissionManageMainMb: React.FC<any> = ({ didData, permissions, selectPermission }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useIonRouter();

  return (
    <IonPage>
      <HeaderMainMb title={t("settings.managePermission")} link="/settings/init" />

      <IonContent className="ion-no-padding gray">
        {permissions && permissions.length > 0 ? (
          <IonList lines="full" className="permission-list">
            {permissions.map((permission: any, i: number) => (
              <IonItem
                key={i}
                onClick={() => {
                  dispatch(selectPermission({ ...permission, userData: didData }));
                  history.push("/settings/permission-detail");
                }}>
                <IonLabel>{permission.uri_subscriber}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        ) : (
          <>
            <IonList className="p-[30px]">{t("consentsForm.connectToDapps")}</IonList>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default PermissionManageMainMb;
