// @ts-nocheck
import { IonContent, IonImg, IonPage } from "@ionic/react";
import { NoPendingIcon } from "assets/icons";
import HeaderMainMb from "components/header/mb";
import HeaderMainPc from "components/header/pc";
import PendingTransactionContentMb from "features/pendingTransaction/components/Content/mb";
import PendingTransactionContentPc from "features/pendingTransaction/components/Content/pc";
import ModalWalletConnect from "components/walletConnect/ModalWalletConnect";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import useTheme from "hooks/useTheme";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchOpen } from "shared/actions/walletConnectAction";

const PendingTransactionPage = () => {
  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation();
  const { breakpoint } = useTheme();

  const dispatch = useAppDispatch();

  const walletConnect = useAppSelector((state) => state.walletConnect);
  const walletConnectArr = useAppSelector((state) => state.walletConnectArrData);

  const openModalSetId = (id: number | undefined, version: number) => {
    let checkedData: any = [];
    if (version === 1) {
      checkedData = walletConnectArr.dataArr.filter((x) => x.data?.legacyCallRequestEvent?.id === id);
    } else if (version === 2) {
      checkedData = walletConnectArr.dataArr.filter((x) => x.data?.requestEvent?.id === id);
    }
    dispatch(fetchOpen(checkedData[0]));
    setOpenModal(true);
  };

  return (
    <>
      <ModalWalletConnect openModalWalletConnect={openModal} closeModalWalletConnect={setOpenModal} />
      {walletConnectArr.dataArr.length > 0 && walletConnect.connected === true ? (
        <IonPage className={breakpoint >= 1024 ? "ion-no-padding" : ""}>
          {breakpoint < 1024 ? <HeaderMainMb title={t("notification.title")} /> : <HeaderMainPc title={t("notification.title")} />}

          <IonContent id="pending-transaction-content" className={breakpoint >= 1024 ? "bg-base ion-no-padding" : ""}>
            {walletConnectArr.dataArr.map((data, i) => (
              <div key={i}>
                {breakpoint >= 1024 ? (
                  <PendingTransactionContentPc i={i} data={data} openModalSetId={openModalSetId} />
                ) : (
                  <PendingTransactionContentMb i={i} data={data} openModalSetId={openModalSetId} />
                )}
              </div>
            ))}
          </IonContent>
        </IonPage>
      ) : (
        <IonPage className="lg:p-0">
          {breakpoint < 1024 ? <HeaderMainMb title={t("notification.title")} /> : <HeaderMainPc title={t("notification.title")} />}

          <IonContent className={breakpoint >= 1024 ? "bg-base ion-no-padding" : ""}>
            <div className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 m-0 text-center text-0A0A0A leading-4.75">
              <IonImg className="flex my-0 mx-auto w-[75px]" src={NoPendingIcon} />
              <p className="p-4">{t("pendingTransaction.noPendingTransaction")}</p>
            </div>
          </IonContent>
        </IonPage>
      )}
    </>
  );
};

export default PendingTransactionPage;
