/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction } from "@reduxjs/toolkit";
import { IToken } from "interfaces/actions/IToken";
import { RootState } from "shared/store";
import transferSlice from "shared/store/transferSlice";
import { GasServices } from "services/GasServices";

export const transferAction = transferSlice.actions;

export const fetchTransferAddress =
  (item: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(transferAction.setAddress({ toAddress: item }));
  };

export const fetchTransferToken =
  (item: IToken): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(transferAction.setToken(item));
  };

export const fetchTransferNft =
  (item: IToken): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(transferAction.setNft(item));
  };

export const fetchTransferGas =
  (chainId: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const gasOracle = new GasServices(parseInt(chainId || "1"));
    const gasPricesWithEstimate = await gasOracle.gasPricesWithEstimate();
    dispatch(transferAction.setGas(gasPricesWithEstimate));
  };

export const fetchGasEIP1559 =
  (item: IToken): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const gasOracle = new GasServices(parseInt(item.chain_id || "1"));

    const getTxGasInstant = await gasOracle.getTxGasParams("instant");
    const getTxGasFast = await gasOracle.getTxGasParams("fast");
    const getTxGasNormal = await gasOracle.getTxGasParams("standard");
    dispatch(transferAction.setGasEIP1559({ instant: getTxGasInstant, fast: getTxGasFast, standard: getTxGasNormal }));
  };

export const fetchTransferAmount =
  (item: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(transferAction.setAmount({ amount: item }));
  };

export const setTransferErrorMsg =
  (msg: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    dispatch(transferAction.setTransferErrorMsg(msg));
  };
