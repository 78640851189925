import { store } from "shared/store";
import olLoginPerfModuleSlice from "shared/store/olLoginPerfModuleSlice";
import { LoginType, UserLoginPayload } from "shared/utils/openlogininterface";
import { isMatch, pickBy } from "lodash-es";
import { addUserLoginDetails, fetchDeviceById, updateDeviceInfo, updateUserLoginDetails } from "shared/utils/openloginmutation";
import { UpdateLoginState } from "shared/utils/__generated__/graphql-types";
import { getCustomDeviceInfo } from "shared/utils/tKeyUtils";
import { getJoinedKey, sanitizeUrl } from "shared/utils/openloginutils";
import bowser from "bowser";
import { getPublic } from "@toruslabs/eccrypto";
export const olLoginPerfModuleActions = olLoginPerfModuleSlice.actions;

export const addLoginRecord = async (params: {
  clientId: string;
  dappUrl: string;
  loginRoute: string;
  sessionId: string;
  walletAddress?: string;
  verifierId?: string;
  verifier?: string;
  webauthnEnabled?: boolean;
  errorStack?: string;
  hasSkippedTkey?: boolean;
  isWebauthnLogin?: boolean;
  shareIndex?: string;
  isLoginCompleted?: boolean;
  loginType: LoginType;
  fetchLoginCount?: boolean;
  mfaLevel?: string;
  mobileOrigin?: string;
}): Promise<number> => {
  const {
    verifier,
    verifierId,
    walletAddress,
    errorStack,
    hasSkippedTkey,
    loginRoute,
    dappUrl,
    clientId,
    isLoginCompleted,
    webauthnEnabled,
    isWebauthnLogin,
    shareIndex,
    loginType,
    fetchLoginCount,
    mfaLevel,
    mobileOrigin,
    sessionId
  } = params;
  const { authToken } = store.getState().olUserModule;
  if (!authToken) {
    return 0;
  }

  if (!sessionId) {
    return 0;
  }

  if (clientId && dappUrl && loginRoute) {
    const webauthnAvailable =
      window.PublicKeyCredential && (await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable());
    const hostname = sanitizeUrl(dappUrl).host;
    const browser = bowser.getParser(window.navigator.userAgent);
    const specialBrowser = getCustomDeviceInfo();
    const payload: UserLoginPayload = {
      client_id: clientId,
      hostname,
      webauthn_available: webauthnAvailable,
      login_route: store.getState().olLoginPerfModule.currentLoginPath || loginRoute,
      time_taken: store.getState().olLoginPerfModule.totalTimeTaken,
      os: browser.getOSName(),
      os_version: browser.getOSVersion(),
      browser: specialBrowser?.browser || browser.getBrowserName(),
      browser_version: browser.getBrowserVersion(),
      platform: browser.getPlatform().type || "desktop",
      login_type: loginType,
      fetch_login_count: fetchLoginCount,
      mfa_level: mfaLevel || "",
      factors_used: store.getState().olLoginPerfModule.authFactorsUsed.join("|"),
      mobile_origin: mobileOrigin
    };

    const deviceShareIndex =
      store.getState().olTKeyModule.settingsPageData?.deviceShare?.share?.share?.shareIndex?.toString("hex") || shareIndex || undefined;

    if (deviceShareIndex) payload.share_index = deviceShareIndex;
    const existingDeviceId =
      verifier && verifierId ? store.getState().olDeviceModule.verifierIDDeviceIDMap[getJoinedKey(verifier, verifierId)] : undefined;

    // if some existing device id found for current verifier,
    // then search for share index if not exist.
    if (!payload.share_index && existingDeviceId) {
      const existingDevice =
        store.getState().olDeviceModule.devicePersistedInfo[existingDeviceId] || (await fetchDeviceById(existingDeviceId));
      if (existingDevice && existingDevice.share_index) payload.share_index = existingDevice.share_index;
    }
    payload.wallet_public_address = walletAddress;
    payload.error_stack = errorStack;
    payload.webauthn_enabled = !!webauthnEnabled;
    payload.is_fast_login = isWebauthnLogin;
    payload.has_skipped_tkey = hasSkippedTkey;
    payload.session_pub_key = getPublic(Buffer.from(sessionId, "hex")).toString("hex");

    if (loginType === "1/1" && existingDeviceId) {
      payload.device_id = existingDeviceId;
    }

    const { loginRecordId, deviceId, dappId, loginCount } = await addUserLoginDetails(payload);
    if (loginRecordId && deviceId && dappId) {
      store.dispatch(olLoginPerfModuleActions.updateState({ loginRecordId, dappId } as any));
      // in case of error verifier and verifier id might not be passed but we have recorded
      // the error irrespective of that above.
      // if (verifier && verifierId) store.dispatch(olDeviceModuleAction.setVerifierDeviceId({ verifier, verifierId, deviceId }));
    }
    if (isLoginCompleted || hasSkippedTkey) {
      store.dispatch(olLoginPerfModuleActions._reinit());
    }
    return loginCount || 0;
  }
  return 0;
};

export const updateDeviceIndex = async ({
  verifier,
  verifierId,
  deviceShareIndex
}: {
  verifier: string;
  verifierId: string;
  deviceShareIndex: string;
}) => {
  const { verifierIDDeviceIDMap, devicePersistedInfo } = store.getState().olDeviceModule;
  const existingDeviceId = verifier && verifierId ? verifierIDDeviceIDMap[getJoinedKey(verifier, verifierId)] : undefined;
  const payload = {
    device_id: existingDeviceId,
    share_index: deviceShareIndex
  };
  const finalPayload = pickBy(payload, (val) => val !== null && val !== undefined && val !== "");

  const infoAlreadyExist = isMatch(devicePersistedInfo[existingDeviceId || ""], finalPayload);
  if (!infoAlreadyExist) {
    const finalDeviceId = await updateDeviceInfo(payload);
    if (finalDeviceId) {
      // store.dispatch(olDeviceModuleAction.setVerifierDeviceId({ verifier, verifierId, deviceId: finalDeviceId }));
      // store.dispatch(olDeviceModuleAction.setDevicePersistedInfo({ deviceId: finalDeviceId, info: { ...payload } }));
    }
  }
};

export const UpdateLoginRecord = async (params: {
  verifierId?: string;
  verifier?: string;
  shareIndex?: string;
  errorStack?: string;
  hasSkippedTkey?: boolean;
  isLoginCompleted?: boolean;
  loginType?: LoginType;
  hasSkippedMfa?: boolean;
  mfaLevel?: string;
  mobileOrigin?: string;
}): Promise<void> => {
  const { authToken } = store.getState().olUserModule;
  if (!authToken) {
    return;
  }

  // if (!store.getState().olLoginPerfModule.loginRecordId || !store.getState().olLoginPerfModule.dappId) {
  //   log.error("loginRecordId doesn't exist, create a login record first.");
  //   return;
  // }
  const {
    shareIndex,
    verifier,
    verifierId,
    hasSkippedTkey,
    isLoginCompleted,
    hasSkippedMfa,
    errorStack,
    loginType,
    mfaLevel,
    mobileOrigin
  } = params;
  const walletAddress = store.getState().olUserModule.walletKeyInfo.publicAddress;

  const payload: UpdateLoginState = {
    login_record_id: store.getState().olLoginPerfModule.loginRecordId,
    login_route: store.getState().olLoginPerfModule.currentLoginPath,
    time_taken: store.getState().olLoginPerfModule.totalTimeTaken,
    wallet_public_address: walletAddress,
    login_type: loginType as LoginType,
    has_skipped_mfa: hasSkippedMfa,
    has_skipped_tkey: hasSkippedTkey,
    error_stack: errorStack,
    mfa_level: mfaLevel || "",
    factors_used: store.getState().olLoginPerfModule.authFactorsUsed.join("|"),
    mobile_origin: mobileOrigin
  };
  if (isLoginCompleted || hasSkippedTkey || hasSkippedMfa) {
    updateUserLoginDetails(payload);
  }
  const deviceShareIndex =
    shareIndex || store.getState().olTKeyModule.settingsPageData?.deviceShare?.share?.share?.shareIndex?.toString("hex");
  if (verifier && verifierId && deviceShareIndex) {
    await updateDeviceIndex({ verifier, verifierId, deviceShareIndex });
  }
  if (isLoginCompleted || hasSkippedTkey || hasSkippedMfa) {
    store.dispatch(olLoginPerfModuleActions._reinit());
  }
};

export const addOrUpdateLoginRecord = async (params: {
  clientId: string;
  dappUrl: string;
  loginRoute: string;
  loginType: LoginType;
  verifierId?: string;
  verifier?: string;
  webauthnEnabled?: boolean;
  errorStack?: string;
  hasSkippedTkey?: boolean;
  isWebauthnLogin?: boolean;
  shareIndex?: string;
  isLoginCompleted?: boolean;
  walletAddress?: string;
  hasSkippedMfa?: boolean;
  mfaLevel?: string;
  mobileOrigin?: string;
  sessionId: string;
}): Promise<void> => {
  const { authToken } = store.getState().olUserModule;
  if (!authToken) {
    return;
  }

  const {
    errorStack,
    hasSkippedTkey,
    loginRoute,
    dappUrl,
    clientId,
    isLoginCompleted,
    webauthnEnabled,
    isWebauthnLogin,
    shareIndex,
    verifierId,
    verifier,
    loginType,
    hasSkippedMfa,
    mfaLevel,
    mobileOrigin,
    sessionId
  } = params;
  if (store.getState().olLoginPerfModule.loginRecordId) {
    UpdateLoginRecord({
      shareIndex,
      verifierId,
      verifier,
      errorStack,
      hasSkippedTkey,
      isLoginCompleted,
      loginType,
      hasSkippedMfa,
      mfaLevel,
      mobileOrigin
    });
  } else {
    addLoginRecord({
      clientId,
      verifierId,
      verifier,
      dappUrl,
      loginRoute,
      webauthnEnabled,
      errorStack,
      hasSkippedTkey,
      isWebauthnLogin,
      shareIndex,
      isLoginCompleted,
      loginType,
      mfaLevel,
      mobileOrigin,
      sessionId
    });
  }
};
