import useTheme from "hooks/useTheme";
import { useSelector } from "react-redux";
import { RootState } from "shared/store";
import PermissionDetailMainMb from "features/settings/components/permissionDetailMain/mb";
import PermissionDetailMainPc from "features/settings/components/permissionDetailMain/pc";
import "./style.css";

const PermissionDetailPage = () => {
  const { breakpoint } = useTheme();
  const { selectedPermission } = useSelector((state: RootState) => state.permissions);

  return breakpoint < 1024 ? (
    <PermissionDetailMainMb selectedPermission={selectedPermission} />
  ) : (
    <PermissionDetailMainPc selectedPermission={selectedPermission} />
  );
};

export default PermissionDetailPage;
