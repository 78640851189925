const {
  REACT_APP_HIDE_TOPUP,
  REACT_APP_INFURA_KEY,
  REACT_APP_ETH_TRANSFER_ONLY,
  REACT_APP_UPBOND_WEB3AUTH_CLIENT_ID,
  REACT_APP_UPBOND_WEB3AUTH_LINE_VERIFIER,
  REACT_APP_UPBOND_IDENTITY_CLIENT_ID,
  REACT_APP_UPBOND_IDENTITY_DOMAIN,
  REACT_APP_UPBOND_TORUS_NETWORK,
  REACT_APP_BASE_URL,
  REACT_APP_TORUS_BUILD_ENV
} = process.env;

const buildEnv = REACT_APP_TORUS_BUILD_ENV;
const isProduction = buildEnv === "production";

const baseUrl = window.location.origin;

const baseRoute = REACT_APP_BASE_URL;

const redirectURI = `${baseUrl}/redirect`;

const { hash } = window.location;
const hashUrl = new URL(`${baseUrl}?${hash.slice(1)}`);
const dappStorageKey = hashUrl.searchParams.get("dappStorageKey");

const appVersion = process.env.REACT_APP_TORUS_BUILD_VERSION;

const rampApiKey = "dw9fe8drpzmdfuks79ub5hvmqzuyjbme4kwkwkqf";

// no reddit for binance.tor.us

// In Modal, show 6 by default (view more)
export default {
  buildEnv,
  isProduction,
  baseUrl,
  baseRoute,
  appVersion,
  tkeyEmailHost: "https://email.tkey.io/send_mail",
  commonApiHost: "https://common-api.tor.us",
  metadataHost: "https://metadata.tor.us",
  api: "https://api.tor.us",
  infuraKey: REACT_APP_INFURA_KEY,

  upbondWeb3AuthClientId: REACT_APP_UPBOND_WEB3AUTH_CLIENT_ID,
  upbondWeb3AuthLineVerifier: REACT_APP_UPBOND_WEB3AUTH_LINE_VERIFIER,
  upbondIdentityClientId: REACT_APP_UPBOND_IDENTITY_CLIENT_ID,
  upbondIdentityDomain: REACT_APP_UPBOND_IDENTITY_DOMAIN,
  upbondTorusNetwork: REACT_APP_UPBOND_TORUS_NETWORK,

  hideTopup: REACT_APP_HIDE_TOPUP === "true",
  ethTransferOnly: REACT_APP_ETH_TRANSFER_ONLY === "true",

  dappStorageKey,

  simplexApiHost: "https://simplex-api.tor.us",
  wyreApiHost: "https://wyre-api.tor.us",
  rampApiHost: "https://ramp-network-api.tor.us",
  mercuryoApiHost: "https://mercuryo-api.tor.us",
  transakApiHost: "https://transak-api.tor.us",

  wyreHost: "https://pay.sendwyre.com/purchase",
  wyreAccountId: "AC_RUQMPNP7QQY",

  rampHost: "https://widget-instant.ramp.network",
  rampApiQuoteHost: `https://api-instant.ramp.network/api/host-api/quote?hostApiKey=${rampApiKey}`,
  rampAPIKEY: rampApiKey,

  mercuryoHost: "https://exchange.mercuryo.io",
  mercuryoLiveAPIKEY: "8e531c49-2f64-4e7e-b1d4-16aa4958c291",
  mercuryoTestHost: "https://sandbox-exchange.mrcr.io",
  mercuryoTestAPIKEY: "45fb9cb6-608e-44fe-a1cf-9c59de4a9e8d",

  transakHost: "https://global.transak.com",
  transakApiQuoteHost: "https://api.transak.com/api/v2",
  transakLiveAPIKEY: "0ae336e4-1968-4ec3-b817-625f6810a7d2",
  transakTestHost: "https://staging-global.transak.com",
  transakTestApiQuoteHost: "https://staging-api.transak.com/api/v2",
  transakTestAPIKEY: "e5adb5e3-b30c-4fa8-85ea-adcbadc98198",

  redirect_uri: redirectURI,
  supportedCurrencies: ["USD", "AUD", "BTC", "CAD", "DAI", "ETC", "EUR", "GBP", "HKD", "IDR", "INR", "JPY", "PHP", "RUB", "SGD", "UAH"],
  additionalCurrencies: ["ANT", "BAT", "DASH", "DGD", "GNO", "LTC", "QTUM", "REP", "SAI", "XEM", "XLM", "XMR", "XRP", "ZEC"],
  logosUrl: "https://app.upbond.io/assets/images/common/UPBOND%E3%83%AD%E3%82%B4new-01.svg",
  noImg: window.location.origin + "/assets/noimg.png",

  // key is the verifier
  loginConfig: {
    "upbond-line": {
      loginProvider: "upbond-line",
      verifier: process.env.REACT_APP_LINE_VERIFIER as string,
      typeOfLogin: "line",
      name: "LINE",
      description: "UPBOND LINE",
      clientId: process.env.REACT_APP_LINE_CLIENT_ID as string,
      mainOption: true,
      jwtParameters: {
        domain: process.env.REACT_APP_LOGIN_UPBOND_DOMAIN as string,
        client_id: process.env.REACT_APP_LINE_CLIENT_ID,
        clientId: process.env.REACT_APP_LINE_CLIENT_ID,
        connection: "line"
      }
    },
    "upbond-google": {
      loginProvider: "upbond-google",
      verifier: process.env.REACT_APP_GOOGLE_VERIFIER as string,
      typeOfLogin: "jwt",
      name: "Google",
      description: "UPBOND GOOGLE",
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
      jwtParameters: {
        domain: process.env.REACT_APP_LOGIN_UPBOND_DOMAIN as string,
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        connection: "google"
      }
    }
  } as any
};
