// @ts-nocheck
import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPage, IonRow } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { checkmarkOutline } from "ionicons/icons";
import HeaderMainPc from "components/header/pc";

const CurrencyMainPc: React.FC<any> = ({ currencies, handleChangeCurrency }) => {
  const { t } = useTranslation();

  return (
    <IonPage className="ion-no-padding">
      <HeaderMainPc title={t("settings.currency")} link="/wallet/settings/init" />

      <IonContent className="bg-base ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol size="6">
              <IonCard class="ion-no-padding m-0">
                <IonCardContent>
                  <IonList className="backup-list px-4">
                    <IonItem className="pb-[15px]">
                      <IonLabel onClick={() => handleChangeCurrency("USD")} className="cursor-pointer">
                        USD
                      </IonLabel>
                      {currencies === "USD" && <IonIcon src={checkmarkOutline} className="text-2xl pr-1" />}
                    </IonItem>
                    <IonItem className="pb-[15px]">
                      <IonLabel onClick={() => handleChangeCurrency("JPY")} className="cursor-pointer">
                        JPY
                      </IonLabel>
                      {currencies === "JPY" && <IonIcon src={checkmarkOutline} className="text-2xl pr-1" />}
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CurrencyMainPc;
