/* eslint-disable no-dupe-else-if */
import {
  AstarHistoryTransactionResult,
  CovalentNftMetadataItems,
  CovalentNftMetadataResponse,
  TransactionData
} from "interfaces/actions/IHistoryTransaction";
import { TransactionItems, IAstarItems } from "interfaces/actions/IHistoryTransaction";
import { LogsEvent } from "interfaces/actions/IHistoryTransaction";
import httpServices from "services/HttpServices";
import { catchError } from "shared/utils/coreUtils";
import { filterGateway } from "shared/utils/raceConditionIpfs";

class HistoryService {
  /**
   * This function retrieves transaction history for a given wallet address on a specified blockchain
   * and categorizes the transactions into different types such as native, ERC20, ERC721, and not
   * standard transactions.
   * @param {IChains} chain - An object containing information about the blockchain network, such as
   * its name and ID.
   * @param {string} walletAddress - The wallet address for which the transaction history is being
   * fetched.
   * @returns a Promise that resolves to an array of TransactionItems or undefined.
   */

  async getTransactionHistory(walletAddress: string): Promise<IAstarItems[] | undefined> {
    try {
      // const url = `https://api.covalenthq.com/v1/${
      //   chain.chain
      // }/address/${walletAddress.toLowerCase()}/transactions_v2/?quote-currency=USD&format=JSON&block-signed-at-asc=false&no-logs=false&key=${
      //   process.env.REACT_APP_COVALENT_CKEY
      // }`;
      let listHash = {};
      let totalTrx: IAstarItems[] = [];

      const [erc20Trx, erc20ListHash, err20] = await this.getErc20Transaction(walletAddress);
      if (err20) throw "error getting erc20 astar";
      listHash = erc20ListHash;
      totalTrx = totalTrx.concat(erc20Trx);
      const [erc721Trx, erc721ListHash, err721] = await this.getErc721Transaction(walletAddress, listHash);
      if (err721) throw "error getting erc721 astar";
      listHash = erc721ListHash;
      totalTrx = totalTrx.concat(erc721Trx);
      const nativeTrx = await this.getNativeTransaction(walletAddress, listHash);
      totalTrx = totalTrx.concat(nativeTrx);
      return totalTrx;
    } catch (error) {
      catchError(error, "balance error");
      return [];
    }
  }

  async promiseWaterfall(tasks: any) {
    let result = null;
    for (const task of tasks) {
      result = await task(result); // Pass the result of the previous task to the next task
    }
    return result;
  }

  async getErc20Transaction(walletAddress: string): Promise<AstarHistoryTransactionResult> {
    try {
      const url = `https://astar.webapi.subscan.io/api/scan/evm/token/transfer`;
      const checkHashUrl = `https://astar.webapi.subscan.io/api/scan/check_hash`;
      const getDetailUrl = `https://astar.webapi.subscan.io/api/scan/evm/transaction`;

      const response = await httpServices.post<any>(url, {
        address: walletAddress,
        category: "erc20",
        row: 100
      });

      const listHash: { [key: string]: boolean } = {};
      const erc20Arr: any[] = [];
      const erc20Obj: { [key: string]: IAstarItems[] } = {};

      if (response.status === 200) {
        const { data } = response;

        if (data?.data?.count) {
          const checkHash = async () => {
            const arrExt = await Promise.all(
              data.data.list.map(async (trx: any) => {
                const item = {
                  address: walletAddress,
                  block_hash: null,
                  chain_ticker: trx?.symbol,
                  contract_decimal: trx?.decimals ?? 18,
                  contract_type: "Erc20",
                  custom_name: trx?.symbol,
                  from_address: trx?.from_display?.address,
                  hash: trx?.hash,
                  transferErc20Value: trx?.value,
                  to_address: trx?.to_display?.address,
                  token_address: trx?.contract,
                  transaction_type: "Single",
                  typeTx: "Erc20",
                  value: trx?.value,
                  block_number: "",
                  block_timestamp: "",
                  gas: "",
                  gas_price: "",
                  input: ""
                };

                const respCheckHash = await httpServices.post<any>(checkHashUrl, {
                  hash: trx?.hash
                });

                listHash[trx?.hash] = true;
                if (erc20Obj[trx?.hash] === undefined) {
                  erc20Obj[trx?.hash] = [item];
                } else {
                  erc20Obj[trx?.hash].push(item);
                }

                return respCheckHash;
              })
            );

            return arrExt.filter(Boolean);
          };

          const getDetail = async (prevData: any[]) => {
            const arrDetail = await Promise.all(
              prevData.map(async (ext) => {
                const respDetail = await httpServices.post<any>(getDetailUrl, {
                  extrinsic_index: ext.data.data.extrinsic_index
                });
                return respDetail;
              })
            );

            return arrDetail;
          };

          const assignAndPush = async (prevData: any[]) => {
            const arrDetail = await Promise.all(
              prevData.map(async (item) => {
                erc20Obj[item?.data?.data?.hash].map((ele) => {
                  ele["block_number"] = item?.data?.data?.block_num;
                  ele["block_timestamp"] = item?.data?.data?.block_timestamp;
                  ele["gas"] = item?.data?.data?.gas_used;
                  ele["gas_price"] = item?.data?.data?.gas_price;
                  ele["input"] = item?.data?.data?.input_data;
                  erc20Arr.push(ele);
                });
                erc20Obj[item?.data?.data?.hash] = [];
              })
            );

            return arrDetail;
          };

          const tasks = [checkHash, getDetail, assignAndPush];

          await this.promiseWaterfall(tasks);
        }
      }
      return [erc20Arr, listHash, false];
    } catch (err) {
      console.log(err, "Err");
      catchError(err, "erc20 trx");
      return [[], {}, true];
    }
  }

  async getErc721Transaction(walletAddress: string, listHash: { [key: string]: boolean }): Promise<AstarHistoryTransactionResult> {
    try {
      const bluezApiKey = process.env.REACT_APP_BLUEZ_API_KEY;
      const url = `https://astar.webapi.subscan.io/api/scan/evm/token/transfer`;
      const checkHashUrl = `https://astar.webapi.subscan.io/api/scan/check_hash`;
      const getDetailUrl = `https://astar.webapi.subscan.io/api/scan/evm/transaction`;
      const getMetadataUrl = `https://api.bluez.app/api/nft/v3/${bluezApiKey}/getNFTMetadata?`;

      const response = await httpServices.post<any>(url, {
        address: walletAddress,
        category: "erc721",
        row: 100
      });

      const erc721Arr: any[] = [];
      const erc721Obj: { [key: string]: IAstarItems[] } = {};

      if (response.status === 200) {
        const { data } = response;

        if (data?.data?.count) {
          const checkHash = async () => {
            const arrExt = await Promise.all(
              data.data.list.map(async (trx: any) => {
                if (listHash[trx?.hash]) return;
                const item = {
                  address: walletAddress,
                  block_hash: null,
                  chain_ticker: trx?.symbol,
                  contract_decimal: trx?.decimals ?? 18,
                  contract_type: "Erc721",
                  custom_name: trx?.symbol,
                  contract_address: trx?.contract,
                  from_address: trx?.from_display?.address,
                  hash: trx?.hash,
                  nftName: trx?.name,
                  transferErc20Value: trx?.value,
                  to_address: trx?.to_display?.address,
                  token_address: trx?.contract,
                  token_id: trx?.token_id,
                  transaction_type: "Single",
                  typeTx: "Erc721",
                  value: trx?.value,
                  block_number: "",
                  block_timestamp: "",
                  gas: "",
                  gas_price: "",
                  input: ""
                };

                const respCheckHash = await httpServices.post<any>(checkHashUrl, {
                  hash: trx?.hash
                });

                listHash[trx?.hash] = true;
                if (erc721Obj[trx?.hash] === undefined) {
                  erc721Obj[trx?.hash] = [item];
                } else {
                  erc721Obj[trx?.hash].push(item);
                }

                return respCheckHash;
              })
            );

            return arrExt.filter(Boolean);
          };

          const getDetail = async (prevData: any[]) => {
            const arrDetail = await Promise.all(
              prevData.map(async (ext) => {
                const respDetail = await httpServices.post<any>(getDetailUrl, {
                  extrinsic_index: ext.data.data.extrinsic_index
                });
                return respDetail;
              })
            );

            return arrDetail;
          };

          const assignAndPush = async (prevData: any[]) => {
            await Promise.all(
              prevData.map(async (item) => {
                erc721Obj[item?.data?.data?.hash].map((ele) => {
                  ele["block_number"] = item?.data?.data?.block_num;
                  ele["block_timestamp"] = item?.data?.data?.block_timestamp;
                  ele["gas"] = item?.data?.data?.gas_used;
                  ele["gas_price"] = item?.data?.data?.gas_price;
                  ele["input"] = item?.data?.data?.input_data;
                  erc721Arr.push(ele);
                });
                erc721Obj[item?.data?.data?.hash] = [];
              })
            );
          };

          const getNftMetadata = async () => {
            const allMetadata = await Promise.all(
              erc721Arr.map(async (item) => {
                const respMetadata = await httpServices.get<any>(
                  getMetadataUrl + `contractAddress=${item?.contract_address}&tokenId=${item?.token_id}`
                );
                let imageUrl = "";
                if (respMetadata?.data?.image.includes("ipfs://")) {
                  const arrUrl = await filterGateway(respMetadata?.data?.image);
                  arrUrl.length ? (imageUrl = arrUrl[0]) : respMetadata?.data?.image;
                } else {
                  imageUrl = respMetadata?.data?.image;
                }
                item["nftLogo"] = imageUrl;
                return respMetadata;
              })
            );
            return allMetadata;
          };

          const tasks = [checkHash, getDetail, assignAndPush, getNftMetadata];

          await this.promiseWaterfall(tasks);
        }
      }
      return [erc721Arr, listHash, false];
    } catch (err) {
      console.log(err, "Err");
      catchError(err, "erc721 trx");
      return [[], {}, true];
    }
  }

  async getNativeTransaction(walletAddress: string, listHash: { [key: string]: boolean }): Promise<IAstarItems[]> {
    try {
      const url = `https://astar.webapi.subscan.io/api/scan/evm/v2/transactions`;
      const checkHashUrl = `https://astar.webapi.subscan.io/api/scan/check_hash`;
      const getDetailUrl = `https://astar.webapi.subscan.io/api/scan/evm/transaction`;

      const response = await httpServices.post<any>(url, {
        address: walletAddress,
        page: 0,
        row: 100
      });

      const nativeArr: any[] = [];
      const nativeObj: { [key: string]: IAstarItems[] } = {};

      if (response.status === 200) {
        const { data } = response;

        if (data?.data?.count) {
          const checkHash = async () => {
            const arrExt = await Promise.all(
              data.data.list.map(async (trx: any) => {
                if (listHash[trx?.hash]) return;
                const item = {
                  address: walletAddress,
                  block_hash: null,
                  chain_ticker: trx?.symbol,
                  contract_type: "native",
                  custom_name: trx?.symbol,
                  from_address: trx?.from_display?.address,
                  hash: trx?.hash,
                  transferErc20Value: trx?.value,
                  to_address: trx?.to_display?.address,
                  token_address: trx?.contract,
                  transaction_type: "Single",
                  typeTx: "native",
                  value: trx?.value,
                  block_number: "",
                  block_timestamp: "",
                  gas: "",
                  gas_price: "",
                  input: ""
                };

                const respCheckHash = await httpServices.post<any>(checkHashUrl, {
                  hash: trx?.hash
                });

                listHash[trx?.hash] = true;
                if (nativeObj[trx?.hash] === undefined) {
                  nativeObj[trx?.hash] = [item];
                } else {
                  nativeObj[trx?.hash].push(item);
                }

                return respCheckHash;
              })
            );

            return arrExt.filter(Boolean);
          };

          const getDetail = async (prevData: any[]) => {
            const arrDetail = await Promise.all(
              prevData.map(async (ext) => {
                const respDetail = await httpServices.post<any>(getDetailUrl, {
                  extrinsic_index: ext.data.data.extrinsic_index
                });
                return respDetail;
              })
            );

            return arrDetail;
          };

          const assignAndPush = async (prevData: any[]) => {
            const arrDetail = await Promise.all(
              prevData.map(async (item) => {
                nativeObj[item?.data?.data?.hash].map((ele) => {
                  ele["block_number"] = item?.data?.data?.block_num;
                  ele["block_timestamp"] = item?.data?.data?.block_timestamp;
                  ele["gas"] = item?.data?.data?.gas_used;
                  ele["gas_price"] = item?.data?.data?.gas_price;
                  ele["input"] = item?.data?.data?.input_data;
                  nativeArr.push(ele);
                });
                nativeObj[item?.data?.data?.hash] = [];
              })
            );

            return arrDetail;
          };

          const tasks = [checkHash, getDetail, assignAndPush];

          await this.promiseWaterfall(tasks);
        }
      }
      return nativeArr;
    } catch (err) {
      console.log(err, "Err");
      catchError(err, "erc721 trx");
      return [];
    }
  }

  /**
   * This is an async function that retrieves metadata for a specific NFT token from the Covalent API.
   * @param {string} contract - The contract address of the NFT.
   * @param {string} tokenId - The unique identifier of a specific NFT (non-fungible token) within a
   * given smart contract.
   * @returns a Promise that resolves to an array of CovalentNftMetadataItems or undefined.
   */
  async getNftMetadata(contract: string, tokenId: string): Promise<CovalentNftMetadataItems[] | undefined> {
    try {
      const url = `https://api.covalenthq.com/v1/${80001}/tokens/${contract}/nft_metadata/${tokenId}/?key=${
        process.env.REACT_APP_COVALENT_CKEY
      }`;
      const response = await httpServices.get<CovalentNftMetadataResponse>(url);
      if (response.status === 200) {
        if (response?.data?.data) {
          const { items } = response.data.data;
          return items;
        }
      }
    } catch (error) {
      catchError(error, "balance error");
    }
  }

  /**
   * This function checks for ERC721 tokens within ERC20 transactions and adds metadata to the
   * transaction data if found.
   * @param {TransactionData} arr - The input parameter `arr` is an object of type `TransactionData`.
   * @returns a Promise that resolves to an array of TransactionItems objects with additional
   * properties such as nftName, nftLogo, and typeTx.
   */
  async checkErc721OnErc20(arr: TransactionData) {
    let newLogsArr: LogsEvent[] = [];
    arr?.items?.map(async (val: TransactionItems) => {
      /* `Promise.all()` method to asynchronously iterate over an array of
      `LogsEvent` objects (`val.log_events`). For each `LogsEvent` object, it checks if the
      `decoded.signature` property matches the string "Transfer(indexed address from, indexed
      address to, uint256 value)". If it does, it filters the `val.log_events` array to find any
      other `LogsEvent` objects with a `decoded.signature` property matching the string
      "Transfer(indexed address from, indexed address to, indexed uint256 tokenId)". It then
      concatenates the filtered array with a */
      await Promise.all(
        val.log_events.map(async (mapLog: LogsEvent) => {
          if (mapLog?.decoded?.signature === "Transfer(indexed address from, indexed address to, uint256 value)") {
            const filteredLogs = val?.log_events?.filter((filterLog: LogsEvent) => {
              if (filterLog?.decoded?.signature === "Transfer(indexed address from, indexed address to, indexed uint256 tokenId)") {
                return val;
              }
            });
            const newArr = await Promise.all([...newLogsArr, ...filteredLogs]);
            newLogsArr = newArr;
          }
        })
      );
    });
    if (newLogsArr && arr.items) {
      const newVal: TransactionItems[] = [];
      for await (const iterator1 of arr.items) {
        for await (const iterator2 of newLogsArr) {
          if (iterator1.tx_hash === iterator2.tx_hash) {
            const nftMetadata = await this.getNftMetadata(iterator2.sender_address, iterator2.decoded.params[2].value);
            if (nftMetadata) {
              nftMetadata[0].nft_data.map((v) => {
                if (v.token_id === iterator2.decoded.params[2].value) {
                  const newTxWithNftMetadata = {
                    ...iterator1,
                    isNewValue: true,
                    nftName: v.external_data.name ? v.external_data.name : "",
                    nftLogo: v.external_data.image ? v.external_data.image : "",
                    typeTx: "Erc721"
                  };
                  newTxWithNftMetadata["from_address"] = iterator2.sender_address;
                  newVal.push(newTxWithNftMetadata);
                }
              });
            }
          }
        }
      }
      // console.log(newVal, "@@NEWVAL");
      return newVal;
    }
  }

  /**
   * This function checks for ERC721 transactions in an array of transaction data and adds metadata to
   * them if available.
   * @param {TransactionData} arr - TransactionData object containing information about a transaction,
   * including its items and log events.
   * @returns an array of objects of type `TransactionItems` with additional properties `isNewValue`,
   * `nftName`, `nftLogo`, and `typeTx` added to each object. The function filters the input array
   * `arr` for items that have a specific `Transfer` event signature and then retrieves additional
   * metadata for each item using the `getNftMetadata` function. The retrieved
   */
  async checkErc721Transaction(arr: TransactionData) {
    /* filters an array of `TransactionItems` objects based on a condition.
    The `filter` method is used to iterate over the `arr.items` array and
    return a new array of items that satisfy the condition. */
    let fixedErc721: TransactionItems[] | undefined = [];
    fixedErc721 = arr?.items?.filter((txErc721: TransactionItems) =>
      txErc721.log_events.some((val: LogsEvent) => {
        if (val.decoded.signature === "Transfer(indexed address from, indexed address to, indexed uint256 tokenId)") {
          return Object.assign(txErc721, { tokenId: val?.decoded?.params[2]?.value, contract: val?.sender_address });
        } else {
          return false;
        }
      })
    );
    const erc721WithMetadata: TransactionItems[] = [];
    if (fixedErc721) {
      for await (const iterator of fixedErc721) {
        if (iterator?.contract && iterator?.tokenId) {
          const nftMetadata = await this.getNftMetadata(iterator?.contract, iterator?.tokenId);
          if (nftMetadata) {
            nftMetadata[0].nft_data.map((v) => {
              if (v.token_id === iterator.tokenId) {
                const newTxWithNftMetadata = {
                  ...iterator,
                  isNewValue: true,
                  nftName: v.external_data?.name ? v.external_data?.name : "",
                  nftLogo: v.external_data?.image ? v.external_data?.image : "",
                  typeTx: "Erc721"
                };
                erc721WithMetadata.push(newTxWithNftMetadata);
              }
            });
          }
        }
      }
    }

    return erc721WithMetadata;
  }

  /**
   * DON'T DELETE FOR ASTAR 1155 HISTORY TRANSACTION
   */
  /**
   * This function filters and returns an array of transaction items that involve ERC1155 token
   * transfers.
   * @param {TransactionData} arr - TransactionData object containing information about a transaction,
   * including its items
   * @returns an array of TransactionItems objects that contain log events related to ERC1155
   * transfers.
   */
  // async checkErc1155Transaction(arr: TransactionData) {
  //   const newArr1155: TransactionItems[] = [];
  //   arr.items?.map((txErc1155: TransactionItems) => {
  //     if (txErc1155.log_events) {
  //       txErc1155.log_events.filter((val: LogsEvent) => {
  //         if (
  //           val.decoded.signature ===
  //             "TransferSingle(indexed address operator, indexed address from, indexed address to, uint256 id, uint256 value)" ||
  //           val.decoded.signature ===
  //             "TransferBatch(indexed address operator, indexed address from, indexed address to, uint256[] ids, uint256[] values)"
  //         ) {
  //           newArr1155.push(txErc1155);
  //         }
  //       });
  //     }
  //   });
  //   return newArr1155;
  // }

  /**
   * The function checks for ERC20 transactions in an array of transaction data and returns a filtered
   * array with additional information about the transactions.
   * @param {TransactionData} arr - An object containing transaction data, including an array of items.
   * @param {TransactionItems[]} pair - An array of TransactionItems objects representing a set of
   * transactions to compare against the items in the arr parameter.
   * @returns The function `checkErc20Transaction` returns an array of filtered and modified transaction
   * items that involve ERC20 token transfers.
   */
  async checkErc20Transaction(arr: TransactionData, pair: TransactionItems[]) {
    const newArr = [];
    if (arr.items && pair) {
      for await (const iterator of arr.items) {
        for await (const iterator2 of pair) {
          if (iterator?.tx_hash !== iterator2?.tx_hash) {
            newArr.push(iterator);
          }
        }
      }
    }
    const fixedErc20Tx = newArr?.filter((txErc721: TransactionItems) =>
      txErc721?.log_events?.some((val: LogsEvent) => {
        if (val.decoded.signature === "Transfer(indexed address from, indexed address to, uint256 value)") {
          txErc721["from_address"] = val?.decoded.params[0]?.value;
          txErc721["to_address"] = val?.decoded.params[1]?.value;
          txErc721["custom_name"] = val?.sender_contract_ticker_symbol;
          txErc721["contract_name"] = val?.sender_contract_ticker_symbol;
          return Object.assign(txErc721, {
            isNewValue: true,
            typeTx: "Erc20",
            transferErc20Value: val.decoded.params[2].value
          });
        }
      })
    );
    return fixedErc20Tx;
  }

  /**
   * This function checks for non-standard transactions in an array of transaction items.
   * @param {TransactionItems[]} arr - An array of TransactionItems objects.
   * @param {TransactionData} arr2 - TransactionData object that contains an array of TransactionItems
   * called "items".
   * @returns an array of objects that are present in `arr2.items` but not in `arr`, with additional
   * properties `isNewValue` and `typeTx` added to each object. However, the function is using
   * `filter()` method which will only return an array of objects that pass the condition inside the
   * filter callback function. In this case, the callback function is only returning the objects
   */
  async checkNotStandartTransaction(arr: TransactionItems[], arr2: TransactionData) {
    if (arr2.items) {
      /* filters the items in the `arr2` array based on whether they exist in the `arr`
      array or not. If an item in `arr2` does not exist in `arr`, it
      is assigned two new properties `isNewValue` and `typeTx` using `Object.assign()`. The
      `isNewValue` property is set to `true` and the `typeTx` property is set to `"NotStandart"`.
      The filtered array is then returned. */
      return arr2?.items?.filter((fl) => {
        if (arr?.indexOf(fl) === -1) {
          return Object.assign(fl, { isNewValue: true, typeTx: "NotStandart" });
        }
      });
    }
  }
}

const historyServices = new HistoryService();

export default historyServices;
