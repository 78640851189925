// @ts-nocheck
import { IonButton, IonIcon } from "@ionic/react";
import WC from "assets/images/walletconnect.svg";
import useTheme from "hooks/useTheme";

const WalletConnectButton: React.FC<any> = () => {
  const { breakpoint } = useTheme();
  return (
    <>
      <IonButton
        color="light"
        slot="icon-only"
        routerLink={breakpoint < 1024 ? "/settings/application" : "/wallet/settings/application"}
        className="p-0 border border-blue-wallet-connect wc">
        <IonIcon src={WC} size="large" />
      </IonButton>
    </>
  );
};

export default WalletConnectButton;
