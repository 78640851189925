// @ts-nocheck
import { IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from "@ionic/react";
import PendingComponent from "features/home/components/PendingComponent";

const HeaderMainMb = ({ title, link, pending }: any) => {
  return (
    <IonHeader className="ion-no-border">
      <IonToolbar mode="md" className={pending ? "flex flex-row gap-4 items-center justify-between" : ""}>
        <>
          <IonButtons slot="start">
            <IonBackButton mode="md" defaultHref={link || "/wallet/home"}></IonBackButton>
          </IonButtons>
          <IonTitle color="dark" class="font-bold pl-0 text-lg">
            {title}
          </IonTitle>
        </>
        {pending && (
          <IonButtons slot="end" className="mr-4">
            <PendingComponent />
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};

export default HeaderMainMb;
